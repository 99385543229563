import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../../button/button.module';
import { ControlTextAreaComponent } from './textarea.component';

@NgModule({
  declarations: [ControlTextAreaComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ButtonModule],
  exports: [ControlTextAreaComponent],
})
export class ControlTextAreaModule {}
