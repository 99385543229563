<article [zipBusy]="busy" class="centered-base_page">
  <div class="centered-base_page__content">
    <aside class="panel" *ngIf="config?.sidebar?.sections">
      <section *ngIf="loaded">
        <ng-container *ngFor="let section of config?.sidebar?.sections">
          <div class="section" [ngSwitch]="section.format">
            <header class="sidebar_header">
              <h6 role="heading" aria-level="1">{{ section.label }}</h6>
            </header>
            <section
              class="patient-detail__aside__attrs"
              *ngSwitchCase="'attribute-list'"
            >
              <div *ngFor="let attribute of section.attributes">
                <model-attribute
                  *ngIf="
                    (!attribute.dataAsLabel && !attribute.showIfTrue) ||
                    (attribute.showIfTrue && checkShowOnTrueValues(attribute))
                  "
                  [context]="context"
                  [config]="attribute"
                  direction="column"
                ></model-attribute>
                <div *ngIf="attribute.dataAsLabel">
                  <label>{{ getValue(context, attribute) }}</label>
                </div>
                <div *ngFor="let nestedAttr of attribute.additionalAttrs">
                  <model-attribute
                    [context]="context"
                    [config]="nestedAttr"
                  ></model-attribute>
                </div>
              </div>
            </section>
          </div>
        </ng-container>
      </section>
    </aside>
    <div class="main-container">
      <div class="provisioner">
        <div
          *ngIf="config?.header && state === 'edit'"
          class="column-header"
          role="heading"
          aria-level="1"
        >
          {{ config?.header }}
        </div>
        <div
          *ngIf="config?.reviewHeader && state === 'review'"
          class="column-header"
          role="heading"
          aria-level="1"
        >
          {{ config?.reviewHeader }}
        </div>
        <div
          *ngIf="config?.confirmationHeader && state === 'confirm'"
          class="column-header"
          role="heading"
          aria-level="1"
        >
          {{ config?.confirmationHeader }}
        </div>
        <div
          *ngIf="config?.subheader && state === 'edit' && apiResponse === ''"
          class="column-subheader"
        >
          {{ config?.subheader }}
        </div>
        <div
          *ngIf="config?.reviewSubheader && state === 'review'"
          class="column-subheader"
        >
          {{ config?.reviewSubheader }}
        </div>
        <div
          *ngIf="config?.confirmationMessage && state === 'confirm'"
          class="column-subheader"
        >
          {{ config?.confirmationMessage }}
        </div>
        <div
          *ngIf="
            (state === 'confirm' || state === 'review') &&
            config?.outOfNetwork?.messageBanner &&
            config?.outOfNetwork?.isOutOfNetworkEnabled &&
            isAnyProviderOutOfNetwork()
          "
          class="messageBanner"
        >
          <message-banner
            [config]="config?.outOfNetwork?.messageBanner"
          ></message-banner>
        </div>
        <div
          *ngIf="
            config?.ClaimRuleValidationMessage &&
            state === 'edit' &&
            apiResponse === 'rejected' &&
            response.validation?.has_error
          "
          class="isa_info"
        >
          <i class="fa fa-info-circle"></i>
          {{ config?.ClaimRuleValidationMessage }}
          <ul>
            <li *ngFor="let message of response.validation.errors">
              {{ message.display_error_message }}
            </li>
          </ul>
        </div>
        <div class="badge-container" *ngIf="state === 'confirm'">
          <div class="badge-spacer">
            <badge [config]="config.badge">
              <div class="badge-body">
                <div class="badge-icon badge-text__center">
                  <i class="icon icon--product icon--large">care</i>
                </div>
                <div class="badge-header">
                  <p class="t-bold card-header badge-text__center">
                    {{ response?.id || noSpace }}
                  </p>
                </div>
                <div class="badge-subheader badge-text__center">
                  <p class="card-subheader">Transaction ID</p>
                </div>
              </div>
            </badge>
          </div>
          <div class="badge-spacer">
            <badge [config]="config.badge">
              <div class="badge-body">
                <div class="badge-icon badge-text__center">
                  <i class="icon icon--product icon--large">clipboard</i>
                </div>
                <div class="badge-header">
                  <p class="t-bold card-header badge-text__center">
                    {{ response?.status || 'Completed' }}
                  </p>
                </div>
                <div class="badge-subheader badge-text__center">
                  <p class="card-subheader">Transaction Status</p>
                </div>
              </div>
            </badge>
          </div>
          <div class="badge-spacer">
            <badge [config]="config.badge">
              <div class="badge-body">
                <div class="badge-icon badge-text__center">
                  <i class="icon icon--product icon--large">profile_product</i>
                </div>
                <div class="badge-header">
                  <p class="t-bold card-header badge-text__center">
                    {{ context?.member?.name?.full_name || noSpace }}
                  </p>
                </div>
                <div class="badge-subheader badge-text__center">
                  <p class="card-subheader">Patient</p>
                </div>
              </div>
            </badge>
          </div>
        </div>
        <div *ngIf="state === 'confirm'" class="button-container">
          <zip-button
            [config]="config.printButton"
            (zipButtonClicked)="print($event)"
          ></zip-button>
        </div>
        <!-- Start of generate card using object -->
        <div class="column-container">
          <ng-container *ngIf="config?.cards">
            <div *ngFor="let key of _.keys(config['cards'])">
              <card
                id="{{ config['cards'][key].id }}"
                class="cardholder card item skinny-badge"
                *ngIf="config['cards'][key].type !== 'dynamicCard'"
              >
                <div title class="card-header card-edit">
                  <div class="left">
                    {{ config['cards'][key].header }}
                  </div>

                  <div
                    *ngIf="state === 'review'"
                    class="right"
                    (click)="scrollTo(config['cards'][key].id)"
                  >
                    <i class="icon icon--medium">edit</i>
                  </div>
                </div>
                <div *ngIf="config['cards'][key]?.subheader" subheader>
                  <div class="t-data address">
                    {{ config['cards'][key]?.subheader }}
                  </div>
                </div>
                <div body>
                  <div class="title-border"></div>
                  <ng-container
                    *ngFor="
                      let control of _.keys(config['cards'][key]?.controls)
                    "
                  >
                    <div
                      [style.width]="
                        controls.get(
                          (config['cards'][key]?.controls)[control]?.prop
                        )?.width
                          ? controls.get(
                              (config['cards'][key]?.controls)[control]?.prop
                            ).width
                          : '100%'
                      "
                    >
                      <div [hidden]="state !== 'edit'">
                        <ng-container
                          [ngSwitch]="
                            (config['cards'][key]?.controls)[control]?.type
                          "
                        >
                          <flyout-dropdown
                            *ngSwitchCase="'flyout'"
                            [group]="formGroup"
                            [outOfNetwork]="config.outOfNetwork"
                            [config]="(config['cards'][key]?.controls)[control]"
                            [configNoSpace]="config?.noSpace"
                          ></flyout-dropdown>
                          <flyout-diagnosis
                            *ngSwitchCase="'flyout-diagnosis'"
                            [group]="formGroup"
                            [config]="(config['cards'][key]?.controls)[control]"
                            [state]="state"
                            (diagnosisDetails)="
                              selectedDiagnosisDetails($event)
                            "
                          ></flyout-diagnosis>
                          <flyout-popup
                            *ngSwitchCase="'popup-billing-provider'"
                            [group]="formGroup"
                            [config]="(config['cards'][key]?.controls)[control]"
                            [control]="config"
                            [state]="state"
                            [outOfNetwork]="config.outOfNetwork"
                            (getFlyoutValue)="getFlyoutValueFromPopup($event)"
                          ></flyout-popup>
                          <ng-container *ngSwitchCase="'dynamicDropDown'">
                            <form-control
                              *ngIf="
                                (config['cards'][key]?.controls)[control]
                                  ?.ifThisProp &&
                                config['cards'][key]?.controls[
                                  control
                                ]?.hasTheseValues?.includes(
                                  formGroup.get(
                                    (config['cards'][key]?.controls)[control]
                                      ?.ifThisProp
                                  ).value
                                )
                              "
                              [group]="formGroup"
                              [config]="
                                (config['cards'][key]?.controls)[control]
                                  ?.formConfig
                              "
                              [control]="
                                formGroup.get(
                                  (config['cards'][key]?.controls)[control]
                                    ?.formConfig.prop
                                )
                              "
                            ></form-control>
                          </ng-container>
                          <ng-container *ngSwitchCase="'hidden'"></ng-container>
                          <form-control
                            *ngSwitchDefault
                            [group]="formGroup"
                            [config]="(config['cards'][key]?.controls)[control]"
                            [control]="
                              formGroup.get(
                                (config['cards'][key]?.controls)[control]?.prop
                              )
                            "
                          ></form-control>
                          <div *ngSwitchCase="'referral-documents'">
                            <file-uploader
                              class="EntryFormCard__FileUploader"
                              *ngIf="state !== 'confirm'"
                              [restrictions]="
                                ((config?.cards)[key]?.controls)[control]
                                  ?.restriction ||
                                defaultFileUploaderRestrictions
                              "
                              [showCloseIcon]="
                                ((config?.cards)[key]?.controls)[control]
                                  ?.showCloseIcon ?? true
                              "
                              [disabled]="
                                !formGroup.get('document_type')?.value
                              "
                              [errorMessageConfig]="
                                ((config?.cards)[key]?.controls)[control]
                                  ?.errorMessage
                              "
                              [config]="
                                getDefaultUploadDocumentConfig(
                                  ((config?.cards)[key]?.controls)[control]
                                )
                              "
                              [fileAttachment]="documentContext"
                              [progress]="
                                documentUploadService.uploadProgress$ | async
                              "
                              [uploadErrorMessage]="
                                documentUploadService.uploadError$ | async
                              "
                              (fileUploaded)="fileUploaded($event)"
                              (fileRemoved)="removeFile($event)"
                            >
                            </file-uploader>
                          </div>
                        </ng-container>
                      </div>
                      <div class="review-box" *ngIf="state !== 'edit'">
                        <div
                          [ngSwitch]="
                            (config['cards'][key]?.controls)[control]?.type
                          "
                        >
                          <div *ngSwitchCase="'hidden'"></div>
                          <div *ngSwitchCase="'dropdown'">
                            <p class="t-data t-bold">
                              {{
                                controls.get(
                                  (config['cards'][key]?.controls)[control]
                                    ?.prop
                                )?.label
                              }}
                            </p>
                            {{
                              getDropdownDisplayValue(
                                (config['cards'][key]?.controls)[control]?.prop
                              )
                            }}
                          </div>
                          <div *ngSwitchCase="'dynamicDropDown'">
                            <div
                              *ngIf="
                                config['cards'][key]?.controls[
                                  control
                                ]?.hasTheseValues?.includes(
                                  formGroup.get(
                                    (config['cards'][key]?.controls)[control]
                                      ?.ifThisProp
                                  ).value
                                )
                              "
                            >
                              <p class="t-data t-bold">
                                {{
                                  controls.get(
                                    (config['cards'][key]?.controls)[control]
                                      ?.prop
                                  )?.label
                                }}
                              </p>
                              {{
                                getDropdownDisplayValue(
                                  (config['cards'][key]?.controls)[control]
                                    ?.prop
                                )
                              }}
                            </div>
                          </div>
                          <div *ngSwitchCase="'toggle'">
                            <p class="t-data t-bold">
                              {{
                                controls.get(
                                  (config['cards'][key]?.controls)[control]
                                    ?.prop
                                )?.label
                              }}
                            </p>
                            {{
                              getDropdownDisplayValue(
                                (config['cards'][key]?.controls)[control]?.prop
                              )
                            }}
                          </div>
                          <div *ngSwitchCase="'flyout-diagnosis'">
                            <p class="t-data t-bold">
                              {{
                                controls.get(
                                  (config['cards'][key]?.controls)[control]
                                    ?.prop
                                )?.label
                              }}
                            </p>
                            <div class="diagnosis-table">
                              <table class="generic_procedure-diagosis-table">
                                <tr
                                  class="generic_procedure-diagosis-table_row"
                                  *ngFor="
                                    let item of diagnosisDetails;
                                    let indexOfelement = index
                                  "
                                >
                                  <td>
                                    <b>{{ item.row.code }}</b>
                                  </td>
                                  <td>
                                    <div class="diagnosis-table-item-entry">
                                      <div>
                                        {{
                                          item.row.short_description ||
                                            item.row.description
                                        }}
                                      </div>
                                      <div
                                        class="isPrimaryPill"
                                        *ngIf="item.row.is_primary === true"
                                      >
                                        Primary
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <div *ngSwitchCase="'flyout'" class="root">
                            <p class="t-data t-bold">
                              {{
                                controls.get(
                                  (config['cards'][key]?.controls)[control]
                                    ?.prop
                                )?.label
                              }}
                            </p>
                            <div
                              *ngIf="
                                controls.get(
                                  (config['cards'][key]?.controls)[control]
                                    ?.prop
                                )?.prop === 'rendering_provider_id'
                              "
                              class="left-flyout"
                            >
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.name &&
                                  formGroup.get(
                                    (config['cards'][key]?.controls)[control]
                                      ?.prop
                                  )?.value
                                "
                                class="form-control__label"
                              >
                                <p class="t-data t-bold">
                                  {{
                                    (formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    )?.value)['name']['full_name'] || noSpace
                                  }}
                                </p>
                              </label>
                            </div>
                            <div
                              *ngIf="
                                controls.get(
                                  (config['cards'][key]?.controls)[control]
                                    ?.prop
                                )?.prop === 'servicing_facility_location_id'
                              "
                              class="left-flyout"
                            >
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.name &&
                                  formGroup.get(
                                    (config['cards'][key]?.controls)[control]
                                      ?.prop
                                  )?.value
                                "
                                class="form-control__label"
                              >
                                <p class="t-data t-bold">
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    )?.value?.name || noSpace
                                  }}
                                </p>
                              </label>
                              <p
                                *ngIf="showOutOfNetworkStatusPill(key, control)"
                                class="OutOfNetwork__StatusPill Pending"
                              >
                                {{ config?.outOfNetwork?.outOfNetworkLabel }}
                              </p>
                            </div>

                            <div
                              *ngIf="
                                controls.get(
                                  (config['cards'][key]?.controls)[control]
                                    ?.prop
                                )?.prop === 'servicing_facility_location_id'
                              "
                              class="right-flyout"
                            >
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.address
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">Address:</p>
                                <p
                                  *ngIf="
                                    !formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['address']
                                  "
                                >
                                  {{ noSpace }}
                                </p>
                                <div
                                  class="address"
                                  *ngIf="
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['address']
                                  "
                                >
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['address']['street_name_1']
                                  }}<br />
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['address']['street_name_2']
                                  }}<br />
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['address']['city_name']
                                  }},
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['address']['state']
                                  }}
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['address']['zip_code']
                                  }}
                                </div>
                              </label>
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.group_npi
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">Group NPI:</p>
                                <p>
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['group_npi'] || noSpace
                                  }}
                                </p>
                              </label>
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.id_number
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">ID Number:</p>
                                <p>
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['number'] || noSpace
                                  }}
                                </p>
                              </label>
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.specialty
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">
                                  Primary Specialty:
                                </p>
                                <p
                                  *ngIf="
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['specialties'].length
                                  "
                                >
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['specialties'][0]['name']
                                  }}
                                </p>
                                <p
                                  *ngIf="
                                    !formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['specialties']?.length
                                  "
                                >
                                  {{ noSpace }}
                                </p>
                              </label>
                            </div>
                            <div
                              *ngIf="
                                controls.get(
                                  (config['cards'][key]?.controls)[control]
                                    ?.prop
                                )?.prop === 'rendering_provider_id'
                              "
                              class="right-flyout"
                            >
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.address
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">Address:</p>
                                <p
                                  *ngIf="
                                    !formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['provider_locations']?.length
                                  "
                                >
                                  {{ noSpace }}
                                </p>
                                <div
                                  class="address"
                                  *ngIf="
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['provider_locations'].length
                                  "
                                >
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['provider_locations'][0]['address'][
                                      'street_name_1'
                                    ]
                                  }}<br />
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['provider_locations'][0]['address'][
                                      'street_name_2'
                                    ]
                                  }}<br />
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['provider_locations'][0]['address'][
                                      'city_name'
                                    ]
                                  }},
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['provider_locations'][0]['address'][
                                      'state'
                                    ]
                                  }}
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['provider_locations'][0]['address'][
                                      'zip_code'
                                    ]
                                  }}
                                </div>
                              </label>
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.group_npi
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">NPI:</p>
                                <p>
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['npi'] || noSpace
                                  }}
                                </p>
                              </label>
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.id_number
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">ID Number:</p>
                                <p>
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['number'] || noSpace
                                  }}
                                </p>
                              </label>
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.specialty
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">
                                  Primary Specialty:
                                </p>
                                <p
                                  *ngIf="
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['specialties'].length
                                  "
                                >
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['specialties'][0]['name']
                                  }}
                                </p>
                                <p
                                  *ngIf="
                                    !formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['specialties']?.length
                                  "
                                >
                                  {{ noSpace }}
                                </p>
                              </label>
                            </div>
                          </div>
                          <div
                            *ngSwitchCase="'popup-billing-provider'"
                            class="root"
                          >
                            <p class="t-data t-bold">
                              {{
                                controls.get(
                                  (config['cards'][key]?.controls)[control]
                                    ?.prop
                                )?.label
                              }}
                            </p>
                            <div
                              *ngIf="
                                getFlyoutValue === 'flyoutOnBillingProvider'
                              "
                              class="left-flyout"
                            >
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.name &&
                                  formGroup.get(
                                    (config['cards'][key]?.controls)[control]
                                      ?.prop
                                  )?.value
                                "
                                class="form-control__label"
                              >
                                <p class="t-data t-bold">
                                  {{
                                    (formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    )?.value)['name']['full_name'] || noSpace
                                  }}
                                </p>
                              </label>
                              <p
                                *ngIf="showOutOfNetworkStatusPill(key, control)"
                                class="OutOfNetwork__StatusPill Pending"
                              >
                                {{ config?.outOfNetwork?.outOfNetworkLabel }}
                              </p>
                            </div>
                            <div
                              *ngIf="
                                getFlyoutValue === 'flyoutOnBillingFacility'
                              "
                              class="left-flyout"
                            >
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.name &&
                                  formGroup.get(
                                    (config['cards'][key]?.controls)[control]
                                      ?.prop
                                  )?.value
                                "
                                class="form-control__label"
                              >
                                <p class="t-data t-bold">
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    )?.value?.name || noSpace
                                  }}
                                </p>
                              </label>
                              <p
                                *ngIf="showOutOfNetworkStatusPill(key, control)"
                                class="OutOfNetwork__StatusPill Pending"
                              >
                                {{ config?.outOfNetwork?.outOfNetworkLabel }}
                              </p>
                            </div>

                            <div
                              *ngIf="
                                getFlyoutValue === 'flyoutOnBillingFacility'
                              "
                              class="right-flyout"
                            >
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.address
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">Address:</p>
                                <p
                                  *ngIf="
                                    !formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['address']
                                  "
                                >
                                  {{ noSpace }}
                                </p>
                                <div
                                  class="address"
                                  *ngIf="
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['address']
                                  "
                                >
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['address']['street_name_1']
                                  }}<br />
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['address']['street_name_2']
                                  }}<br />
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['address']['city_name']
                                  }},
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['address']['state']
                                  }}
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['address']['zip_code']
                                  }}
                                </div>
                              </label>
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.group_npi
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">Group NPI:</p>
                                <p>
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['group_npi'] || noSpace
                                  }}
                                </p>
                              </label>
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.id_number
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">ID Number:</p>
                                <p>
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['number'] || noSpace
                                  }}
                                </p>
                              </label>
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.specialty
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">
                                  Primary Specialty:
                                </p>
                                <p
                                  *ngIf="
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['specialties'].length
                                  "
                                >
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['specialties'][0]['name']
                                  }}
                                </p>
                                <p
                                  *ngIf="
                                    !formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['specialties']?.length
                                  "
                                >
                                  {{ noSpace }}
                                </p>
                              </label>
                            </div>
                            <div
                              *ngIf="
                                getFlyoutValue === 'flyoutOnBillingProvider'
                              "
                              class="right-flyout"
                            >
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.address
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">Address:</p>
                                <p
                                  *ngIf="
                                    !formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['provider_locations']?.length;
                                    else multilocation
                                  "
                                >
                                  {{ noSpace }}
                                </p>
                                <ng-template #multilocation>
                                  <p
                                    *ngIf="
                                      !formGroup.get(
                                        (config['cards'][key]?.controls)[
                                          control
                                        ]?.prop
                                      ).value['provider_locations'][0][
                                        'facility_location'
                                      ]['facility']['facility_locations']
                                        ?.length
                                    "
                                  >
                                    {{ noSpace }}
                                  </p>
                                  <ng-container
                                    *ngFor="
                                      let multiLocation of formGroup.get(
                                        (config['cards'][key]?.controls)[
                                          control
                                        ]?.prop
                                      ).value['provider_locations'][0][
                                        'facility_location'
                                      ]['facility']['facility_locations']
                                    "
                                  >
                                    <div
                                      class="address"
                                      *ngIf="
                                        multiLocation.address &&
                                        multiLocation.address.id ===
                                          formGroup.get('multi_location').value
                                      "
                                    >
                                      {{ multiLocation.address['street_name_1']
                                      }}<br />
                                      {{ multiLocation.address['street_name_2']
                                      }}<br />
                                      {{ multiLocation.address['city_name'] }},
                                      {{ multiLocation.address['state'] }}
                                      {{ multiLocation.address['zip_code'] }}
                                    </div>
                                  </ng-container>
                                </ng-template>
                              </label>
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.group_npi
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">NPI:</p>
                                <p>
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['npi'] || noSpace
                                  }}
                                </p>
                              </label>
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.id_number
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">ID Number:</p>
                                <p>
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['number'] || noSpace
                                  }}
                                </p>
                              </label>
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.specialty
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">
                                  Primary Specialty:
                                </p>
                                <p
                                  *ngIf="
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['specialties'].length
                                  "
                                >
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['specialties'][0]['name']
                                  }}
                                </p>
                                <p
                                  *ngIf="
                                    !formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['specialties']?.length
                                  "
                                >
                                  {{ noSpace }}
                                </p>
                              </label>
                              <label
                                *ngIf="
                                  (config['cards'][key]?.controls)[control]
                                    ?.displayFields.phone
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">
                                  Phone Number:
                                </p>
                                <p>
                                  {{
                                    formGroup.get(
                                      (config['cards'][key]?.controls)[control]
                                        ?.prop
                                    ).value['phone'] || noSpace
                                  }}
                                </p>
                              </label>
                            </div>
                          </div>
                          <div *ngSwitchCase="'referral-documents'">
                            <div
                              class="generic-card-summary_one-line-value"
                              *ngIf="state !== 'confirm'"
                            ></div>
                            <div
                              *ngFor="
                                let document of documentUploadService.uploadedFiles;
                                let idx = index
                              "
                              class="documents"
                            >
                              <div class="document">
                                <div class="document--icon">
                                  <i class="icon icon--medium">pdf</i>
                                </div>
                                <div class="document--name">
                                  <p class="t-bold document--name__main">
                                    {{ document.file_name }}
                                  </p>
                                  <p class="document--name__file">
                                    {{ document.file_name }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngSwitchDefault>
                            <p class="t-data t-bold">
                              {{
                                controls.get(
                                  (config['cards'][key]?.controls)[control]
                                    ?.prop
                                )?.label
                              }}
                            </p>
                            {{
                              getDefaultReviewBox(
                                (config['cards'][key]?.controls)[control]
                              ) || noSpace
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </card>

              <ng-container
                *ngIf="
                  config['cards'][key].type === 'dynamicCard' &&
                  cardToLoadValues(config['cards'][key])
                "
              >
                <div *ngFor="let data of cardArray; let pointIndex = index">
                  <service-details
                    [config]="data.card_to_load"
                    [group]="data.group_to_load"
                    [dynamicGroupArray]="parentArray[pointIndex]"
                    [controls]="data.controls_to_load"
                    [state]="state"
                    [configNoSpace]="config?.noSpace"
                    (updatedState)="getState($event)"
                    (switchTriggered)="switchTriggered($event)"
                    [index]="pointIndex"
                    [outOfNetwork]="config?.outOfNetwork"
                  >
                    <div
                      *ngIf="state === 'edit' && pointIndex !== 0"
                      class="right"
                      (click)="deleteCard(pointIndex)"
                    >
                      <i class="icon icon--medium">close</i>
                    </div>
                    <div
                      *ngIf="checkLimit(config['cards'][key].addButton.limit)"
                      btn
                    >
                      <zip-button
                        *ngIf="
                          state === 'edit' &&
                          config['cards'][key]['addButton']['isButtonEnabled']
                        "
                        class="centered-base_page__always_on_top__centered-button"
                        [config]="data.card_to_load?.addButton"
                        (zipButtonClicked)="
                          addCard(config['cards'][key].addButton.addCardFor)
                        "
                      >
                      </zip-button>
                    </div>
                  </service-details>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <!-- End of generate card using object -->
      </div>
    </div>
  </div>
  <div class="centered-base_page__always_on_top">
    <zip-button
      *ngIf="state === 'edit'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonCancel"
      (zipButtonClicked)="cancel($event)"
    >
    </zip-button>
    <zip-button
      *ngIf="state === 'confirm'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonReturn"
      (zipButtonClicked)="cancel($event)"
    >
    </zip-button>
    <zip-button
      *ngIf="state === 'edit'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonReview"
      (zipButtonClicked)="review($event)"
    >
    </zip-button>
    <zip-button
      *ngIf="state === 'review'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonBack"
      (zipButtonClicked)="back($event)"
    >
    </zip-button>
    <zip-button
      *ngIf="state === 'review'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonSubmit"
      (zipButtonClicked)="submit($event)"
    >
    </zip-button>
  </div>
</article>

<modal
  #errorFlyout
  *ngIf="error"
  [config]="config?.errorModal"
  [condition]="error"
  (cancel)="toggleError()"
>
  <div body>{{ config?.errorModal.message }}</div>
</modal>
