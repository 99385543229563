@for (config of configs; track config; let i = $index) {
  <ng-container *ngIf="!config.hidden">
    <div
      class="FormGroup__control form--controls"
      [formGroup]="form"
      [style.grid-area]="config.grid | setGridPosition"
      [@fade]
      [@slideUp]
      [@.disabled]="!animate"
    >
      <ng-container *ngIf="!config.customFormElement; else customFormElement">
        <ng-container *ngIf="config.type === 'group'; else formControl">
          <label *ngIf="config.label" class="form-control__label">
            <p class="t-bold">{{ config.label }}</p>
          </label>

          <div class="FormGroup--nested">
            <form-group
              [style.display]="config.columns ? 'grid' : 'block'"
              [style.align-items]="'end'"
              [style.grid-template-columns]="formatGridColumns(config.columns)"
              [configs]="config.controls"
              [form]="form.get(config.prop)"
              [direction]="direction"
            >
            </form-group>
          </div>
        </ng-container>

        <ng-template #formControl>
          <form-control
            *ngIf="!config.hideControl"
            (linkClick)="linkClicked.emit({ config: config, event: $event })"
            (iconClick)="iconClicked.emit({ config: config, event: $event })"
            (selected)="selected.emit({ config: config, event: $event })"
            (iconRightClicked)="handleIconRightClicked()"
            (inputChanged)="
              inputChanged.emit({ config: config, event: $event })
            "
            (typeaheadInputCleared)="
              typeaheadClearClicked.emit({ config: config, event: $event })
            "
            (fileUploaded)="
              fileUploaded.emit({ config: config, event: $event })
            "
            (fileRemoved)="fileRemoved.emit({ config: config, event: $event })"
            (pendingAttachments)="
              pendingAttachments.emit({ config: config, event: $event })
            "
            [direction]="direction"
            [config]="config"
            [group]="form"
            [control]="form.controls[config.prop]"
            [disableEndpointOptions]="disableEndpointOptions"
            [selectedIndex]="getSelectedIndex(i)"
            [customEndpoint]="customEndpoint"
            [formControlOptionsParams]="formControlOptionsParams"
            [fileUploadInputs]="fileUploadInputs"
          >
          </form-control>
        </ng-template>
      </ng-container>
    </div>

    <ng-template #customFormElement>
      <ng-container [ngSwitch]="config.type">
        <form-group-array
          *ngSwitchCase="'formGroupArray'"
          [group]="form"
          [config]="config"
          [context]="context"
        >
        </form-group-array>

        <optional-date
          *ngSwitchCase="'optionalDate'"
          [direction]="direction"
          [config]="config"
          [group]="form"
        >
        </optional-date>

        <conditional-toggle
          *ngSwitchCase="'conditionalToggle'"
          [formControlName]="config.prop"
          [parentForm]="form"
          [config]="config"
        >
          <form-group
            [configs]="config.controls"
            [form]="form"
            [ngClass]="{ 'conditional-grid': config.isGrid }"
          >
          </form-group>
        </conditional-toggle>

        <checkbox-matrix
          *ngSwitchCase="'checkboxMatrix'"
          [config]="config"
          [context]="context"
          [formGroup]="form"
        >
        </checkbox-matrix>

        <multi-address
          *ngSwitchCase="'multiAddress'"
          [config]="config"
          [context]="context"
          [formGroup]="form"
        >
        </multi-address>

        <custom-form-element
          *ngSwitchDefault
          (formCreated)="handleFormCreated($event, config)"
          [registeredComponents]="registeredComponents"
          [direction]="direction"
          [config]="config"
          [form]="form"
        >
        </custom-form-element>
      </ng-container>
    </ng-template>
  </ng-container>
}

<ng-container *ngIf="clear">
  <zip-button
    [config]="clear"
    (zipButtonClicked)="clearForm()"
    [ariaDescription]="clear.isAriaDescriptionDisabled ? null : clear.content"
  >
  </zip-button>
</ng-container>

<ng-container *ngIf="submit">
  <zip-button
    [config]="submit"
    [disabled]="form.invalid"
    (zipButtonClicked)="submitForm()"
    [ariaDescription]="submit.isAriaDescriptionDisabled ? null : submit.content"
  >
  </zip-button>
</ng-container>
