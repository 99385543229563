import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { APIService } from '@zipari/web-services';
import {
  ProvisionerService,
  TreeNode,
} from '../../provisioner/provisioner.service';

@Component({
  styleUrls: ['./viewer-provisioning.component.scss'],
  selector: 'viewer-provisioning',
  templateUrl: './viewer-provisioning.component.html',
})
export class ViewerProvisioningComponent implements OnInit {
  @Input() config: any;
  @Input() viewMode = false;
  @Input() ignoreAPI = false;
  api_call: Subscription;
  loaded = false;
  error = false;
  results: TreeNode[] = [];
  currentPage = 1;
  pageLength = 10;
  totalResultsCount = 0;

  constructor(
    private api: APIService,
    private router: Router,
    public provisioner: ProvisionerService,
  ) {}

  get children() {
    if (this.results?.length) {
      return this.results;
    }

    return this.provisioner.getTreeNode().children;
  }

  get paginatorConfig() {
    const resultsPerPage = this.pageLength;
    const startingPageNumber: number =
      this.currentPage * resultsPerPage - resultsPerPage + 1;
    const endingPageNumber: number =
      startingPageNumber <= this.totalResultsCount - resultsPerPage
        ? startingPageNumber + resultsPerPage - 1
        : this.totalResultsCount;
    const paginationConfig = {
      rowsPerPage: resultsPerPage,
      showingStart: startingPageNumber,
      showingEnd: endingPageNumber,
      currentPage: this.currentPage,
      totalPages: Math.ceil(this.totalResultsCount / resultsPerPage),
      totalResults: this.totalResultsCount,
    };

    return paginationConfig;
  }

  ngOnInit(): void {
    if (this.config?.apiEndpoint && !this.ignoreAPI) {
      if (this.config.pageSize) this.pageLength = this.config.pageSize;
      this.updateResults();
    } else {
      this.loaded = true;
    }
  }

  setBackgroundColor(index: number) {
    if (index % 2 !== 0) {
      return 'unset';
    } else return 'var(--gray--inactive)';
  }

  updateResults(page = 1) {
    let url: string = this.config?.apiEndpoint;

    if (url.includes('${user_name}')) {
      url = url.replace('${user_name}', this.provisioner.getUserId());
    }
    url += `?page=${page}&page_size=${this.pageLength}`;
    this.api_call = this.api.get(url).subscribe(
      (res: any) => {
        this.results = res.results;
        this.totalResultsCount = res['count'];
        if (!this.results.length) this.error = true;
        this.loaded = true;
      },
      (err: any) => {
        this.error = true;
      },
    );
  }

  goToPage(page: any) {
    this.currentPage = page;
    this.updateResults(page);
  }

  provision() {
    this.router.navigate([
      `provider-portal/provider-admin/user-provisioning/edit/${this.provisioner.getUserId()}`,
    ]);
  }

  ngOndestroy(): void {
    if (this.api_call) {
      this.api_call.unsubscribe();
    }
  }
}
