<!-- The table that contains the list. The majority of the view is contained here. -->
<section
  [zipBusy]="busy"
  class="layout--list"
  [ngClass]="{
    'layout--list--injected': view === listComponentViews.injected,
    'layout--list--standalone': view === listComponentViews.standalone,
  }"
  *ngIf="!!pageConfigs"
>
  <header *ngIf="pageConfigs.header?.headerText" class="layout--list__header">
    <div class="layout--list__header--main">
      <h4 class="t-bold" role="heading" aria-level="1">
        {{ pageConfigs.header.headerText }}
      </h4>
    </div>
  </header>

  <section class="layout--list__content set-relative">
    <div *ngIf="pageConfigs.headerButton" class="header-button">
      <zip-button
        [config]="pageConfigs.headerButton"
        (click)="handleListButtonClick(pageConfigs.headerButton)"
      >
      </zip-button>
    </div>
    <div class="table-left">
      <zip-table
        [data]="data"
        [options]="pageConfigs.table"
        (buttonClicked)="tableButtonClicked($event)"
        (deleteClicked)="deleteClicked($event)"
        (cellClicked)="cellClick($event)"
        (headerButtonClicked)="headerButtonClicked.emit($event)"
        (dataChanged)="onDataChanged($event)"
      >
      </zip-table>
    </div>
  </section>
</section>

<!-- Add row modal that pops up when you click add new [thing]. -->
<section
  class="modal__mask"
  @fade
  *ngIf="!!showAddRowModal || !!showRowDetailModal"
></section>

<add-or-edit-modal
  *ngIf="pageConfigs?.addRow && showAddRowModal"
  [viewProfile]="true"
  [config]="pageConfigs.addRow"
  [mainConfig]="pageConfigs.addRow"
  [successResponse]="addSuccessResponse"
  [errorResponse]="addErrorResponse"
  (cancel)="onCancelClick()"
  (continue)="onViewProfile($event)"
  (save)="onSaveNewUserClick($event)"
  (saveSuccess)="saveSuccess.emit($event)"
  (fileMetaData)="fileMetaData.emit($event)"
>
</add-or-edit-modal>

<add-or-edit-modal
  #delete_flyout
  *ngIf="deleteConfig && showDeleteModal"
  [openAccordionIdx]="openAccordionIdx"
  [viewProfile]="true"
  [currentSelectedRows]="selectedRows"
  [config]="deleteConfig"
  [data]="data"
  (cancel)="onCancelClick()"
  (save)="onDeleteSubmit($event)"
>
</add-or-edit-modal>

<!-- Row detail modal, not what pops up when you add a row. -->
<section
  class="modal custom--modal modal--panel"
  @slideRight
  *ngIf="!!showRowDetailModal"
>
  <header class="modal__header">
    <p class="t-data t-bold">{{ pageConfigs.rowDetail.header }}</p>
  </header>

  <ng-container *ngIf="detailModal; else defaultDetailModal">
    <ng-container *ngTemplateOutlet="detailModal"> </ng-container>
  </ng-container>

  <footer class="modal__footer">
    <zip-button level="low" content="Exit" (click)="showRowDetailModal = false">
    </zip-button>

    <zip-button level="high" content="View Profile" (click)="onViewProfile()">
    </zip-button>
  </footer>
</section>

<ng-template #defaultDetailModal>
  <section class="modal__body">
    <h4 class="t-bold">
      {{ activeDetailRow.first_name }} {{ activeDetailRow.last_name }}
    </h4>

    <h6 class="t-alt">ID #{{ activeDetailRow.id }}</h6>
    <div class="model-attributes">
      <div
        class="model-attribute"
        *ngFor="let attribute of pageConfigs.rowDetail.attributes"
      >
        <label class="model-attribute__label">
          <p class="t-data t-bold">{{ attribute.label }}</p>
        </label>

        <div class="model-attribute__value">
          <p class="t-data">{{ activeDetailRow[attribute.prop] }}</p>
        </div>
      </div>
    </div>
  </section>
</ng-template>
