import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { camelCase } from '@zipari/shared-util-common';
import { APIList } from '@zipari/design-system';
import { APIService } from '@zipari/web-services';
import ClaimLine from '../../../../shared/models/shared/ClaimLine.model';

@Injectable({
  providedIn: 'root',
})
export default class ClaimAppealDataService {
  public claimLinesList: ClaimLine[];

  constructor(private apiService: APIService) {}

  getClaimLines(claimId: string): Observable<ClaimLine[]> {
    return this.apiService
      .get(`api/provider-portal/claims/${claimId}/lines`)
      .pipe(
        map(
          (response: APIList<ClaimLine>) =>
            (this.claimLinesList = camelCase(response.results)),
        ),
        catchError(() => of([])),
      );
  }
}
