<card class="cardholder card item skinny-badge">
  <div title class="card-header card-edit">
    <div class="left">
      {{ config.header }}
    </div>
    <div *ngIf="state === 'review'" class="right" (click)="scrollTo(config.id)">
      <i class="icon icon--medium">edit</i>
    </div>
    <ng-content select=".right"></ng-content>
  </div>
  <div class="left" *ngIf="config?.subheader" subheader>
    {{ config?.subheader }}
  </div>
  <div body>
    <div class="title-border"></div>
    <div *ngFor="let control of _.keys(config['controls'])">
      <div *ngIf="!config['controls'][control]?.dyanmicControl">
        <div [ngSwitch]="config['controls'][control]?.type">
          <div *ngIf="state === 'edit'">
            <div *ngSwitchCase="'flyout'">
              <flyout-dropdown
                [group]="group"
                [config]="config['controls'][control]"
                [configNoSpace]="noSpace"
                [outOfNetwork]="outOfNetwork"
              ></flyout-dropdown>
            </div>
            <div *ngSwitchCase="'date'">
              <form-control
                [group]="group"
                [config]="config['controls'][control]"
                [control]="group.get(config['controls'][control].prop)"
              >
              </form-control>
            </div>
            <div *ngSwitchCase="'dropdown'">
              <form-control
                [group]="group"
                [config]="config['controls'][control]"
                [control]="group.get(config['controls'][control].prop)"
              >
              </form-control>
            </div>
            <div *ngSwitchCase="'switch'">
              <form-control
                [group]="group"
                [config]="config['controls'][control]"
                [control]="group.get(config['controls'][control].prop)"
                (click)="switchTriggered.emit(index)"
              >
              </form-control>
            </div>
            <div *ngSwitchCase="'text'">
              <form-control
                [group]="group"
                [config]="config['controls'][control]"
                [control]="group.get(config['controls'][control].prop)"
              >
              </form-control>
            </div>
            <!-- there is something wrong with ngSwitchDefault  -->
            <div *ngSwitchDefault>
              <form-control
                [group]="group"
                [config]="config['controls'][control]"
                [control]="group.get(config['controls'][control].prop)"
              >
              </form-control>
            </div>
            <ng-content select="[btn]"></ng-content>
          </div>
          <div class="review-box" *ngIf="state !== 'edit'">
            <p class="t-data t-bold">
              {{ config['controls'][control]?.label }}
            </p>
            <div [ngSwitch]="config['controls'][control]?.type">
              <div *ngSwitchCase="'dropdown'">
                {{
                  getDropdownDisplayValue(
                    config['controls'][control]?.prop,
                    group
                  )
                }}
              </div>
              <div *ngSwitchCase="'flyout'" class="root">
                <div
                  class="left-flyout"
                  *ngIf="config['controls'][control]?.prop === 'procedure_id'"
                >
                  <label class="form-control__label">
                    <p class="t-data t-bold">
                      {{
                        group.get(config['controls'][control]?.prop)?.value
                          ?.code
                      }}
                    </p>
                  </label>
                </div>

                <div
                  class="left-flyout"
                  *ngIf="
                    config['controls'][control]?.prop ===
                    'rendering_provider_id'
                  "
                >
                  <label
                    *ngIf="
                      config['controls'][control]?.displayFields.name &&
                      group.get(config['controls'][control]?.prop)?.value
                    "
                    class="form-control__label"
                  >
                    <p class="t-data t-bold">
                      {{
                        (group.get(config['controls'][control]?.prop)?.value)[
                          'name'
                        ]?.full_name || noSpace
                      }}
                    </p>
                  </label>
                  <p
                    *ngIf="showOutOfNetworkStatusPill(control)"
                    class="OutOfNetwork__StatusPill Pending"
                  >
                    {{ outOfNetwork?.outOfNetworkLabel }}
                  </p>
                </div>
                <div
                  class="left-flyout"
                  *ngIf="
                    config['controls'][control]?.prop ===
                    'servicing_facility_id'
                  "
                >
                  <label
                    *ngIf="
                      config['controls'][control]?.displayFields.name &&
                      group.get(config['controls'][control]?.prop)?.value
                    "
                    class="form-control__label"
                  >
                    <p class="t-data t-bold">
                      {{
                        (group.get(config['controls'][control]?.prop)?.value)[
                          'name'
                        ] || noSpace
                      }}
                    </p>
                  </label>
                  <p
                    *ngIf="showOutOfNetworkStatusPill(control)"
                    class="OutOfNetwork__StatusPill Pending"
                  >
                    {{ outOfNetwork?.outOfNetworkLabel }}
                  </p>
                </div>
                <!-- load this from config -->
                <div
                  class="right-flyout"
                  *ngIf="config['controls'][control]?.prop === 'procedure_id'"
                >
                  <label class="form-control__label">
                    <p>
                      {{
                        group.get(config['controls'][control]?.prop).value
                          .description || noSpace
                      }}
                    </p>
                  </label>
                </div>
                <div
                  class="right-flyout"
                  *ngIf="
                    config['controls'][control]?.prop ===
                    'rendering_provider_id'
                  "
                >
                  <label
                    *ngIf="config['controls'][control]?.displayFields.address"
                    class="form-control__label"
                  >
                    <p class="spacing t-data t-bold">Address:</p>
                    <p
                      *ngIf="
                        !group.get(config['controls'][control]?.prop).value[
                          'provider_locations'
                        ]?.length
                      "
                    >
                      {{ noSpace }}
                    </p>
                    <div
                      class="address"
                      *ngIf="
                        group.get(config['controls'][control]?.prop).value[
                          'provider_locations'
                        ]?.length
                      "
                    >
                      {{
                        group.get(config['controls'][control]?.prop).value[
                          'provider_locations'
                        ][0]['address']['street_name_1']
                      }}<br />
                      {{
                        group.get(config['controls'][control]?.prop).value[
                          'provider_locations'
                        ][0]['address']['street_name_2']
                      }}<br />
                      {{
                        group.get(config['controls'][control]?.prop).value[
                          'provider_locations'
                        ][0]['address']['city_name']
                      }},
                      {{
                        group.get(config['controls'][control]?.prop).value[
                          'provider_locations'
                        ][0]['address']['state']
                      }}
                      {{
                        group.get(config['controls'][control]?.prop).value[
                          'provider_locations'
                        ][0]['address']['zip_code']
                      }}
                    </div>
                  </label>
                  <label
                    *ngIf="config['controls'][control]?.displayFields.group_npi"
                    class="form-control__label"
                  >
                    <p class="spacing t-data t-bold">Group NPI:</p>
                    <p>
                      {{
                        group.get(config['controls'][control]?.prop).value[
                          'npi'
                        ] || noSpace
                      }}
                    </p>
                  </label>
                  <label
                    *ngIf="config['controls'][control]?.displayFields.id_number"
                    class="form-control__label"
                  >
                    <p class="spacing t-data t-bold">ID Number:</p>
                    <p>
                      {{
                        group.get(config['controls'][control]?.prop).value[
                          'number'
                        ] || noSpace
                      }}
                    </p>
                  </label>
                  <label
                    *ngIf="config['controls'][control]?.displayFields.specialty"
                    class="form-control__label"
                  >
                    <p class="spacing t-data t-bold">Primary Specialty:</p>
                    <p
                      *ngIf="
                        group.get(config['controls'][control]?.prop).value[
                          'primary_specialty'
                        ]
                      "
                    >
                      {{
                        group.get(config['controls'][control]?.prop).value[
                          'primary_specialty'
                        ]
                      }}
                    </p>
                    <p
                      *ngIf="
                        !group.get(config['controls'][control]?.prop).value[
                          'primary_specialty'
                        ]
                      "
                    >
                      {{ noSpace }}
                    </p>
                  </label>
                </div>
                <div
                  class="right-flyout"
                  *ngIf="
                    config['controls'][control]?.prop ===
                    'servicing_facility_id'
                  "
                >
                  <label
                    *ngIf="config['controls'][control]?.displayFields.address"
                    class="form-control__label"
                  >
                    <p class="spacing t-data t-bold">Address:</p>
                    <p
                      *ngIf="
                        !group.get(config['controls'][control]?.prop).value[
                          'address'
                        ]
                      "
                    >
                      {{ noSpace }}
                    </p>
                    <div
                      class="address"
                      *ngIf="
                        group.get(config['controls'][control]?.prop).value[
                          'address'
                        ]
                      "
                    >
                      {{
                        group.get(config['controls'][control]?.prop).value[
                          'address'
                        ]['street_name_1']
                      }}<br />
                      {{
                        group.get(config['controls'][control]?.prop).value[
                          'address'
                        ]['street_name_2']
                      }}<br />
                      {{
                        group.get(config['controls'][control]?.prop).value[
                          'address'
                        ]['city_name']
                      }},
                      {{
                        group.get(config['controls'][control]?.prop).value[
                          'address'
                        ]['state']
                      }}
                      {{
                        group.get(config['controls'][control]?.prop).value[
                          'address'
                        ]['zip_code']
                      }}
                    </div>
                  </label>
                  <label
                    *ngIf="config['controls'][control]?.displayFields.group_npi"
                    class="form-control__label"
                  >
                    <p class="spacing t-data t-bold">Group NPI:</p>
                    <p>
                      {{
                        group.get(config['controls'][control]?.prop).value[
                          'group_npi'
                        ] || noSpace
                      }}
                    </p>
                  </label>
                  <label
                    *ngIf="config['controls'][control]?.displayFields.id_number"
                    class="form-control__label"
                  >
                    <p class="spacing t-data t-bold">ID Number:</p>
                    <p>
                      {{
                        group.get(config['controls'][control]?.prop).value[
                          'number'
                        ] || noSpace
                      }}
                    </p>
                  </label>
                  <label
                    *ngIf="config['controls'][control]?.displayFields.specialty"
                    class="form-control__label"
                  >
                    <p class="spacing t-data t-bold">Primary Specialty:</p>
                    <p
                      *ngIf="
                        group.get(config['controls'][control]?.prop).value[
                          'specialties'
                        ].length
                      "
                    >
                      {{
                        group.get(config['controls'][control]?.prop).value[
                          'specialties'
                        ][0]['name']
                      }}
                    </p>
                    <p
                      *ngIf="
                        !group.get(config['controls'][control]?.prop).value[
                          'specialties'
                        ].length
                      "
                    >
                      {{ noSpace }}
                    </p>
                  </label>
                </div>

                <!-- load this from config -->
              </div>
              <div *ngSwitchCase="'toggle'">
                <p class="t-data t-bold">
                  {{ control.get(config['controls'][control]?.prop)?.label }}
                </p>
                {{
                  getDropdownDisplayValue(
                    config['controls'][control]?.prop,
                    group
                  )
                }}
              </div>
              <div *ngSwitchDefault>
                {{
                  getDefaultReviewBox(config['controls'][control], group) ||
                    noSpace
                }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="config['controls'][control]?.dyanmicControl">
        <div class="side-controls-label">
          <span class="t-data t-bold side-controls-label-diag"
            >Diagnosis Pointer</span
          >
          <span class="t-data t-bold side-controls-label-priority"
            >Priority</span
          >
        </div>

        <div
          *ngFor="let newGroup of dynamicGroupArray; let pointIndex = index"
          class="dynamic-controls"
        >
          <div *ngIf="state === 'edit'" class="side-controls">
            <form-control
              [group]="newGroup"
              [config]="(config['controls'][control]?.dyanmicControl)[0]"
              class="form-container"
              [control]="
                newGroup.get(
                  (config['controls'][control]?.dyanmicControl)[0].prop
                )
              "
            >
            </form-control>

            <form-control
              [group]="newGroup"
              [config]="(config['controls'][control]?.dyanmicControl)[1]"
              class="form-container"
              [control]="
                newGroup.get(
                  (config['controls'][control]?.dyanmicControl)[1].prop
                )
              "
            >
            </form-control>

            <span
              *ngIf="this.dynamicGroupArray.length > 1"
              (click)="deleteArrayItem(pointIndex)"
              class="material-icons mat-spaced-icon"
            >
              remove_circle_outline
            </span>
          </div>
          <span
            *ngIf="state === 'edit'"
            (click)="addControl(config['controls'])"
            class="material-icons mat-spaced-icon"
          >
            add_circle_outline
          </span>
          <div
            class="review-box"
            *ngIf="state !== 'edit'"
            class="side-controls-label-review"
          >
            <p class="t-data t-bold">
              {{ config['controls'][control]?.label }}
            </p>
            <span class="side-controls-label-diag-review">
              {{
                getDropdownDisplayValue(
                  (config['controls'][control]?.dyanmicControl)[0].prop,
                  newGroup
                )
              }}
            </span>
            <span class="side-controls-label-priority">
              {{
                getDropdownDisplayValue(
                  (config['controls'][control]?.dyanmicControl)[1].prop,
                  newGroup
                )
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</card>
