/* eslint-disable @typescript-eslint/no-empty-function */
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AdditionalDetailsModalConfig } from './site-locations-attestation/site-locations-attestation.model';

export class ProviderUpdateToolService {
  formGroups: Map<any, UntypedFormGroup> = new Map<any, UntypedFormGroup>(); // column_id map
  contextMap: Map<any, any> = new Map<any, any>();
  initMap: Map<any, any> = new Map<any, any>();
  public setFormGroup(key: string, form: any) {
    this.formGroups.set(key, form);
  }

  public getFormGroup(key: string) {
    return this.formGroups.get(key);
  }

  public setContextData(key: string, context: any) {
    this.contextMap.set(key, context);
  }

  public getContextData(key: string) {
    return this.contextMap.get(key);
  }

  public setInitState(key: string, context: any) {
    this.initMap.set(key, context);
  }

  public getInitState(key: string) {
    return this.initMap.get(key);
  }

  phoneConditionallyRequiredValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }

    if (formControl.parent.get('phone_number').value) {
      return Validators.required(formControl);
    }

    return null;
  }

  public getValidators(validatorType: string) {
    const validators: any[] = [];

    if (!validatorType) return validators;
    switch (validatorType) {
      case 'phone':
        // validators.push(Validators.email)
        validators.push(Validators.pattern(/^[1-9][0-9]{9,9}$/));
        break;
      case 'taxonomy':
        // [a-zA-Z0-9]{10}
        validators.push(Validators.pattern(/^[a-zA-Z0-9]{10}$/));
        break;
      case 'required':
        // [a-zA-Z0-9]{10}
        validators.push(Validators.required);
        break;
      default:
        break;
    }

    return validators;
  }

  public showModal(
    additionalDetailsModalConfig: AdditionalDetailsModalConfig,
  ): boolean {
    const { enableEffectiveDate, enableNotes, enableChangeInOwnership } =
      additionalDetailsModalConfig || {};

    return (
      additionalDetailsModalConfig?.additionalDetailsForm &&
      (enableEffectiveDate || enableNotes || enableChangeInOwnership)
    );
  }
}
