/* eslint-disable no-underscore-dangle */
/* eslint-disable array-callback-return */

import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TableComponent } from '@zipari/design-system';
import { cloneObject, getValue } from '@zipari/web-utils';
import { APIService } from '@zipari/web-services';
import {
  identity,
  isEmpty,
  isEqual,
  pickBy,
  isUndefined,
  omitBy,
} from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { GenericServices } from '../../../../shared/services/generic.service';
import { ConfigService } from '../../../../../shared/services';
import { ProviderUpdateToolService } from '../../provider-update-tool.service';
import { AnalyticsService } from '../../../../../shared/services/analytics.service';
import {
  deaProp,
  StateDeaContext,
  StateDeaFormGroupArrayProp,
  StateDeaPayload,
} from '../add-new/manage-practitioner-add.constant';

import { TableCellClickEvent } from '../../../../../shared/models/shared/CellClickEvent.model';
import { AdditionalDetailsModalConfig } from '../../site-locations-attestation/site-locations-attestation.model';
import {
  deaNumberProp,
  PractitionerDetailsContextKeys,
  providerNumbersProp,
} from './manage-practitioner-detail.constant';
import Specialty from '../../../../../shared/models/shared/Specialty.model';

@Component({
  styleUrls: ['./manage-practitioner-detail.component.scss'],
  selector: 'manage-practitioner-detail',
  templateUrl: './manage-practitioner-detail.component.html',
})
export class ManagePractitionerDetailComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @ViewChild(TableComponent) table: TableComponent;

  showModal = false;
  provider_details_call: Subscription;
  config: any;
  id: any;
  error = false;
  context: any;
  loaded: boolean;
  editModal: boolean;
  deleteState: 'edit' | 'review' = 'edit';
  addState: 'add' | 'review' | 'confirm' = 'add';
  associateModal = false;
  selectedTab = 0;
  openAccordionIdx = 0;
  itemsToDelete: any[] = [];
  terminateFlyoutFields = new UntypedFormGroup({});
  editFields = new UntypedFormGroup({});
  associateFields: any[] = [];
  editResponse: any;
  currentTab = 0;
  selectedRows: any[] = [];
  tableReady = false;
  associateFlyoutReady = false;
  payloadSnapshot: any = {};
  payload: any = {};
  payloadExit: any = {};
  addResponse: any;
  termResponse: any;
  showAdditionalDetailsModal = false;
  additionalDetailsForm: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    private route: ActivatedRoute,
    private api: APIService,
    public router: Router,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    public configService: ConfigService,
    public mp: ProviderUpdateToolService,
    public genericServices: GenericServices,
    public analyticsService: AnalyticsService,
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.genericServices.showFooter$.next(false);
    });
    this.config = {
      ...this.configService.getPageConfig('practitioner-detail'),
    };
    this.id = this.route.snapshot.params.id;
    this.context = {};

    if (this.config?.tabs?.tabConfigs[1]?.table?.endpoint) {
      this.config.tabs.tabConfigs[1].table.endpoint += `?is_terminated=false&provider_id=${this.id}`;
      this.tableReady = true;
    }
    if (this.config?.associateFlyout?.table?.endpoint) {
      this.config.associateFlyout.table.endpoint += `?not_associated_with_provider_id=${this.id}`;
      this.associateFlyoutReady = true;
    }

    const provider_details: Observable<any> = this.api.get(
      this.setParameter(this.config.endpoints.provider_details, 'id', this.id),
    );

    this.provider_details_call = provider_details.subscribe((response: any) => {
      this.context = cloneObject(response);
      const languages: any = [];

      response.languages.map((item: any) => {
        languages.push(item.name);
      });
      this.context.languages = languages;
      const specialties: any = [];

      response.specialties.map((item: any) => {
        specialties.push(item.name);
      });
      this.context.specialties = specialties;
      const taxonomy_codes: any = [];

      response.taxonomy_codes.map((item: any) => {
        taxonomy_codes.push(item);
      });
      this.context.taxonomy_codes = taxonomy_codes;
      const degrees: any = [];

      response.degrees.map((item: any) => {
        degrees.push(item.degree_name);
      });
      this.context.degrees = degrees;

      this.updateProfileFields(response.provider_numbers);

      const editArrayFields = {};
      const _context = {};
      const _state = {};

      for (const formfield of this.config?.tabs?.tabConfigs[0]?.editFlyout
        .formFields) {
        const formFields = { ...formfield };

        if (response.provider_numbers) {
          formfield.details.controls = formFields.details.controls.filter(
            (control: any) => control.prop !== deaProp,
          );
        } else {
          formfield.additionalDetails.controls =
            formFields.additionalDetails?.controls.filter(
              (control: any) => control.prop !== StateDeaFormGroupArrayProp,
            );
        }

        if (formfield.additionalDetails?.controls) {
          for (const control of formfield.additionalDetails.controls) {
            if (control.type === 'formGroupArray') {
              const group = new UntypedFormGroup({});

              editArrayFields[control.prop] = this.fb.array([group]);

              _context[control.prop] = this.updateStateDeaFormContext(response);
              _state[control.prop] = false;
            } else {
              editArrayFields[control.prop] = this.fb.array([
                this.fb.group({
                  selection: ['', this.mp.getValidators(control.validatorType)],
                }),
              ]);
              if (control.defaultProp) {
                _context[control.prop] = getValue(
                  response,
                  control.defaultProp,
                );
                _state[control.prop] = false;
              }
            }
          }
        }
        if (formfield.details?.controls) {
          for (const control of formfield.details.controls) {
            if (control.apiEndpoint) {
              this.genericServices.subscriptions.push(
                this.api.get(control.apiEndpoint).subscribe((resp: any) => {
                  this.setControlProps(control);
                  if (resp) {
                    control.options = this.populateDropdown(
                      new Array<any>(),
                      resp.results,
                      control.dropdownDisplayProp,
                    );
                  }
                }),
              );
            }
            if (
              control.defaultProp ||
              control.defaultStartProp ||
              control.defaultEndProp
            ) {
              this.setControlProps(control);
            }
          }
        }
      }
      this.config?.tabs?.tabConfigs[1]?.delete_modal.controls.forEach(
        (element: any) => {
          if (element.prop === 'deactivation_date') {
            element['default'] = moment(new Date()).format('YYYY-MM-DD');
          }
        },
      );
      this.mp.setFormGroup('editDetails', this.fb.group(editArrayFields));
      this.mp.setContextData('editDetails', _context);
      this.mp.setInitState('editDetails', _state);
      this.loaded = true;

      this.buildExitPayload(response);
    });
    this.genericServices.subscriptions.push(this.provider_details_call);

    for (const control of this.config?.associateFlyout.controls) {
      if (control.apiEndpoint) {
        this.genericServices.subscriptions.push(
          this.api.get(control.apiEndpoint).subscribe((resp: any) => {
            if (resp) {
              control.options = this.populateDropdown(
                new Array<any>(),
                resp.results,
                control.dropdownDisplayProp,
              );
            }
          }),
        );
      }
      if (
        control.defaultProp ||
        control.defaultStartProp ||
        control.defaultEndProp
      ) {
        this.setControlProps(control);
      }
    }

    this.analyticsService.sendEvent(this.config.endpoints.cx_event_key);
    this.filterProfileSectionAttributes();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  // TODO: Abtract this out once the site-location part is done as well
  // The idea is to have redundant stuff across the VERY SIMILAR components
  // Then use the redundancy to facilitate refactor
  updateProfileFields(responseProviderNumbers) {
    if (responseProviderNumbers) {
      const providerNumbers = [];

      responseProviderNumbers.map((item: any) =>
        providerNumbers.push(`${item.state_code}  ${item.number}`),
      );
      this.context.provider_numbers = providerNumbers;
      this.config.tabs.tabConfigs[0].sections[1].fields =
        this.config?.tabs?.tabConfigs[0].sections[1].fields.filter(
          (item: any) => item.prop !== deaNumberProp,
        );
    } else {
      this.config.tabs.tabConfigs[0].sections[1].fields =
        this.config?.tabs?.tabConfigs[0].sections[1].fields.filter(
          (item: any) => item.prop !== providerNumbersProp,
        );
    }
  }

  buildExitPayload(response) {
    const payload: any = {
      name: {
        middle_name: this.context.name.middle_name,
        last_name: this.context.name.last_name,
        first_name: this.context.name.first_name,
      },
      ssn: this.context.ssn,
      birth_date: this.context.birth_date,
      gender: this.context.gender,
      languages: this.context.languages,
      npi: this.context.npi,
      degrees: this.context.degrees,
      specialties: this.context.specialties,
      taxonomy_codes: this.context.taxonomy_codes,
      prefix: this.context.name.prefix,
      dea_number: this.context.dea_number,
      effective_date: this.context.effective_date,
      license_number: this.context.license_number,
      number: this.context.number,
      provider_numbers: response.provider_numbers?.map((item: any) => ({
        ...item,
        id: '',
      })),
    };

    this.payload = pickBy(cloneObject(payload), identity);
    this.payloadSnapshot = pickBy(cloneObject(payload), identity);
  }

  setControlProps(control: any) {
    if (control.defaultProp) {
      const defaultValue = getValue(this.context, control.defaultProp);

      control['default'] = defaultValue;
    }
    if (control.defaultStartProp) {
      const defaultStartValue = getValue(
        this.context,
        control.defaultStartProp,
      );

      control['start'] = defaultStartValue;
    }
    if (control.defaultEndProp) {
      const defaultEndValue = getValue(this.context, control.defaultEndProp);

      control['end'] = defaultEndValue;
    }
  }

  getAssociatedFields(index: number) {
    if (!this.associateFields[index]) {
      this.associateFields[index] = new UntypedFormGroup({});
    }

    return this.associateFields[index];
  }

  getAssociatedFieldValue(index: number) {
    return this.associateFields[index]?.value;
  }

  populateDropdown(options: any[], results: any[] = [], prop: string) {
    for (const item of results) {
      options.push({
        label: this.getObjectProperty(item, prop),
        value: item,
      });
    }

    return options;
  }

  setParameter(url: string, field: string, value: string) {
    return url.replace(`\${${field}}`, value);
  }

  back(): void {
    this.router.navigate(['provider-portal/practitioners/']);
  }

  handleTabSelected(event: any) {
    this.currentTab = event;
  }

  setHeaderCSS(headerEditIcon: boolean) {
    return {
      ['isCursor']: headerEditIcon,
    };
  }

  openEditModalIfNeeded(event, headerEditIcon: boolean, idx: number): void {
    if (headerEditIcon) this.openEditModal(idx);
    else {
      event.stopPropagation();
    }
  }

  openEditModal(idx: number): void {
    this.editModal = true;
    this.openAccordionIdx = idx;
  }

  remove(index: number) {
    if (index > -1) {
      this.itemsToDelete.splice(index, 1);
    }
  }

  toggleEdit() {
    this.editModal = false;
  }

  handleModalCancel() {
    this.showModal = !this.showModal;
  }

  getObjectProperty(obj, keys) {
    if (!keys || !obj) return;
    if (obj?.specialties?.length) {
      const _specialities: Specialty[] = [];

      if (obj.hasOwnProperty('facility_locations')) {
        obj.facility_locations.specialties?.forEach((element, i) => {
          _specialities[i] = element;
        });
        obj.facility_locations.specialites = _specialities;
      }
    }

    return keys.split('.').reduce(function (result, key) {
      if (!result) return;

      return result[key];
    }, obj);
  }

  deleteClicked(event: any[]): void {
    this.terminateFlyoutFields = new UntypedFormGroup({});
    this.deleteState = 'edit';
    if (this.config?.tabs?.tabConfigs[1]?.delete_modal && event.length > 0) {
      this.itemsToDelete = event;
      this.showModal = !this.showModal;
    }
  }

  toggleError() {
    this.error = !this.error;
  }

  resetAssociatedFields() {
    this.associateFields = [];
  }

  toggleAssociate() {
    this.resetAssociatedFields();
    this.associateModal = !this.associateModal;
  }

  navigateToDetail(event: TableCellClickEvent): void {
    if (event.context?.row?.id) {
      this.router.navigate([
        `/provider-portal/site-locations/detail/${event.context.row.facility_location_id}`,
      ]);
    }
  }

  submitDelete() {
    if (!this.terminateFlyoutFields.valid || !this.itemsToDelete?.length) {
      return true;
    }
    if (this.terminateFlyoutFields.valid) {
      if (this.terminateFlyoutFields?.value) {
        const facility_location_providers: any[] = [];

        this.itemsToDelete.forEach((item: any) => {
          const _item: any = {};

          _item['termination_date'] =
            this.terminateFlyoutFields?.value.deactivation_date;
          _item['last_updated_notes'] = this.terminateFlyoutFields?.value.notes;
          _item['id'] = item.row.id;
          _item['provider_id'] = item.row.provider_id;
          _item['facility_location_id'] = item.row.facility_location_id;
          facility_location_providers.push(_item);
        });
        const payload: any = {
          should_fail_on_error: false,
          operation_type: 'update',
          facility_location_providers: facility_location_providers,
        };

        this.genericServices.subscriptions.push(
          this.api.post(this.config.endpoints.batch, payload).subscribe(
            (res: any) => {
              this.termResponse = res;
              this.table?.unselectAllRows();
              this.table?.refresh();
              this.deleteState = 'review';
              this.analyticsService.sendEvent(
                this.config?.tabs?.tabConfigs[1]?.delete_modal?.cx_event_key,
              );
            },
            () => {
              this.handleModalCancel();
              this.toggleError();
            },
          ),
        );
      }
    }
  }

  get additionalDetailsModalConfig() {
    return this.config?.tabs?.tabConfigs[this.currentTab]
      ?.additionalDetailsModal;
  }

  filterAdditionalDetails(additionalDetails: any[], prop: string): any[] {
    return additionalDetails?.filter((control: any) => control.prop !== prop);
  }

  filterProfileSectionAttributes(): void {
    this.config?.tabs?.tabConfigs[0].sections.map((element: any) => {
      if (!this.additionalDetailsModalConfig?.enableNotes) {
        element.fields = this.filterAdditionalDetails(
          element.fields,
          PractitionerDetailsContextKeys.changeRequestNotes,
        );
      }
      if (!this.additionalDetailsModalConfig?.enableEffectiveDate) {
        element.fields = this.filterAdditionalDetails(
          element.fields,
          PractitionerDetailsContextKeys.changeRequestEffectiveDate,
        );
      }

      return element;
    });
  }

  editSubmit(additionalDetailsModalConfig: AdditionalDetailsModalConfig): void {
    if (additionalDetailsModalConfig?.additionalDetailsForm) {
      if (
        additionalDetailsModalConfig.enableEffectiveDate ||
        additionalDetailsModalConfig.enableNotes
      ) {
        this.showAdditionalDetailsModal = true;
        this.editModal = false;
      } else {
        this.submit();
      }
    } else {
      this.submit();
    }
  }

  submit() {
    this.showAdditionalDetailsModal = false;
    // update payload
    if (this.isDisabled()) return true;
    this.updatePayload();
    // compare payload to snapshot and find modified keys
    if (this.comparePayloads()) {
      // payload massage
      if (this.payloadExit['prefix']) {
        if (this.payloadExit['name']) {
          this.payloadExit['name']['prefix'] = this.payloadExit['prefix'];
        } else {
          this.payloadExit['name'] = {};
          this.payloadExit['name']['prefix'] = this.payloadExit['prefix'];
        }
        delete this.payloadExit['prefix'];
      }
      this.payloadExit['id'] = this.id;
      this.genericServices.subscriptions.push(
        this.api
          .patch(
            this.setParameter(
              this.config.endpoints.provider_details,
              'id',
              this.id,
            ),
            this.payloadExit,
          )
          .subscribe(
            (res: any) => {
              // window.location.reload();
              this.editResponse = res;
              this.ngOnInit();
              this.toggleEdit();
              if (this.additionalDetailsForm) {
                this.additionalDetailsForm.reset();
              }
            },
            () => {
              this.toggleError();
              this.toggleEdit();
            },
          ),
      );
    }
  }

  updatePayload() {
    const simpleValues: any = this.editFields?.value;
    const complexValues: any = this.mp.getFormGroup('editDetails')?.value;

    let payload: any = {
      name: {
        middle_name: simpleValues?.middle_name,
        last_name: simpleValues?.last_name,
        first_name: simpleValues?.first_name,
      },
      ssn: simpleValues?.ssn,
      birth_date: simpleValues?.birth_date,
      gender: simpleValues?.gender,
      languages: complexValues?.languages
        ? complexValues.languages
            .map((item: any) => {
              if (item.selection) return item.selection;
            })
            .filter(this.notEmpty)
        : null,
      npi: simpleValues?.npi,
      degrees: complexValues?.degrees
        ? complexValues.degrees
            .map((item: any) => {
              if (item.selection) return item.selection;
            })
            .filter(this.notEmpty)
        : null,
      specialties: complexValues?.specialties
        ? complexValues.specialties
            .map((item: any) => {
              if (item.selection) return item.selection;
            })
            .filter(this.notEmpty)
        : null,
      taxonomy_codes: complexValues?.taxonomy_codes
        ? complexValues.taxonomy_codes
            .map((item: any) => {
              if (item.selection) return item.selection;
            })
            .filter(this.notEmpty)
        : null,
      dea_number: simpleValues?.dea,
      effective_date: simpleValues?.effective_date,
      prefix: simpleValues?.prefix,
      license_number: simpleValues?.license_number,
      number: simpleValues?.number,
      change_request_effective_date:
        this.additionalDetailsForm?.value?.effective_date,
      change_request_notes: this.additionalDetailsForm?.value?.notes,
    };

    if (complexValues?.StateDeaFormGroupArray) {
      payload[providerNumbersProp] = this.setProviderNumbers(complexValues);
    }
    payload = JSON.parse(JSON.stringify(payload));
    const _cleanedObject = omitBy(payload, isUndefined);

    this.payload = _cleanedObject;
  }

  comparePayloads(): boolean {
    const compareObject = cloneObject(this.payload);
    let returnBool = false;

    if (this.payload.languages) {
      compareObject.languages = this.payload.languages.map(
        (item: any) => item.name,
      );
    }
    if (this.payload.specialties) {
      compareObject.specialties = this.payload.specialties.map(
        (item: any) => item.name,
      );
    }
    if (this.payload.degrees) {
      compareObject.degrees = this.payload.degrees.map(
        (item: any) => item.degree_name,
      );
    }
    if (this.payload.taxonomy) {
      compareObject.taxonomy = this.payload.taxonomy.map(
        (item: any) => item.name,
      );
    }
    if (compareObject.name && !compareObject.name.middle_name) {
      // odd exception case b/c of combo of ugly data + requirements
      compareObject.name.middle_name = this.payloadSnapshot.name?.middle_name;
      // this.payload.name.middle_name = this.payloadSnapshot.name.middle_name;
    }
    this.payloadExit = {};
    for (const key in compareObject) {
      const compareFrom = compareObject[key];
      const copyFrom = this.payload[key];
      const compareTo = this.payloadSnapshot[key];

      if (!isEqual(compareFrom, compareTo)) {
        this.payloadExit[key] = copyFrom;
        returnBool = true;
      }
    }

    return returnBool;
  }

  associateSite() {
    this.addState = 'add';
    this.selectedRows = [];
    this.toggleAssociate();

    this.analyticsService.sendEvent(this.config.headerButton.cx_event_key);
  }

  isEmptyArray(array: any) {
    if (Array.isArray(array) && array.length === 0) {
      return false;
    }

    return true;
  }

  notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined && !isEmpty(value);
  }

  handleSelectedRows(rows: any): void {
    this.selectedRows = rows;
  }

  reviewAssociated(): void {
    if (this.hasAssociateSelections()) return;
    if (this.selectedRows.length === 0) {
      this.toggleError();

      return;
    }
    this.addState = 'review';
  }

  hasAssociateSelections() {
    if (this.selectedRows.length) {
      return false;
    }

    return true;
  }

  checkAssociated() {
    let value = true;

    this.associateFields.forEach((item, index) => {
      // eslint-disable-next-line no-empty
      if (!item.valid) {
        value = false;
        const controls = item.controls;

        Object.keys(controls).forEach((c: any) => controls[c].markAsTouched());
      }
    });

    return value;
  }

  submitAddAssociated() {
    if (!this.checkAssociated()) return;
    const facility_location_providers: any[] = [];

    this.selectedRows.forEach((row, index) => {
      const rowFields: any = this.getAssociatedFieldValue(index);
      const _payload: any = {
        facility_location_id: row?.row?.id,
        provider_id: this.id,
        accepting_new_patients: rowFields?.accepting_new_patients
          ? true
          : false,
        can_display_in_directory: rowFields?.display_in_directory
          ? true
          : false,
        specialties: [rowFields?.specialty],
      };

      if (rowFields?.effective_date) {
        _payload['effective_date'] = rowFields.effective_date;
      }
      if (rowFields?.notes) _payload['last_updated_notes'] = rowFields.notes;
      facility_location_providers.push(_payload);
    });
    const payload: any = {
      should_fail_on_error: false,
      operation_type: 'create',
      facility_location_providers: facility_location_providers,
    };

    this.genericServices.subscriptions.push(
      this.api.post(this.config.endpoints.batch, payload).subscribe(
        (res: any) => {
          this.addState = 'confirm';
          this.addResponse = res;
          this.table?.refresh();
        },
        () => {
          this.toggleAssociate();
          this.toggleError();
        },
      ),
    );
  }

  removeAssociated(index: number) {
    if (index > -1) {
      this.selectedRows.splice(index, 1);
    }
  }

  isDisabled() {
    this.updatePayload();
    if (!this.comparePayloads()) return true; // payload hasn't changed, disable button
    const simpleValues: any = this.editFields;
    const complexValues: any = this.mp.getFormGroup('editDetails');
    let dontShow = false;

    dontShow = this.setDisabledValidations(simpleValues, dontShow);
    dontShow = this.setDisabledValidations(complexValues, dontShow);
    if (dontShow) return true; // if payload has changed, but validation fails, disable button

    return false; // otherwise, enable button;
  }

  setDisabledValidations(control: any, returnBool: boolean): boolean {
    if (!control?.valid) {
      const controls = control?.controls;

      if (controls) {
        Object.keys(controls).forEach((c: any) => {
          if (!controls[c].valid && !controls[c].pristine) {
            if (controls[c].controls) {
              Object.keys(controls[c].controls).forEach((cc: any) => {
                returnBool = this.setDisabledValidations(
                  controls[c].controls[cc],
                  returnBool,
                );
              });
            } else {
              controls[c].markAsTouched();
            }
          }
        });
      }

      return true;
    }

    return returnBool;
  }

  isAssociateDisabled() {
    let dontShow = false;

    dontShow = this.setAssociateDisabledValidations(
      this.associateFields,
      dontShow,
    );
    // dontShow = this.setDisabledValidations(locationValues, dontShow);
    if (dontShow) return true;

    return false; // otherwise, enable button;
  }

  setAssociateDisabledValidations(control: any, returnBool: boolean): boolean {
    control.forEach((item) => {
      if (!item.valid) {
        const controls = item.controls;

        if (controls) {
          Object.keys(controls).forEach((c: any) => {
            if (!controls[c].valid) {
              if (controls[c].controls) {
                Object.keys(controls[c].controls).forEach((cc: any) => {
                  returnBool = this.setDisabledValidations(
                    controls[c].controls[cc],
                    returnBool,
                  );
                });
              } else {
                if (!controls[c].pristine) {
                  controls[c].markAsTouched();
                }
                returnBool = true;
              }
            }
          });
        }
      }
    });

    return returnBool;
  }

  cancelEdit(): void {
    this.showAdditionalDetailsModal = false;
    this.editModal = true;
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.genericServices.showFooter$.next(true);
    });
  }

  updateStateDeaFormContext(details): StateDeaContext[] {
    const stateDeaContext = details?.provider_numbers?.map((value: any) => ({
      state: value.state_code,
      deaGroup: {
        number: value.number,
      },
    }));

    return stateDeaContext ? stateDeaContext : [];
  }

  setProviderNumbers(formValue: any): StateDeaPayload[] {
    return formValue.StateDeaFormGroupArray.filter(
      (group: any) => group.state,
    ).map((group: any) => ({
      id: '',
      number: group.deaGroup?.number,
      state_code: group.state,
      state_id: null,
      state_id_type: 'DEA',
    }));
  }
}
