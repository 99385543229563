import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WindowService } from '@zipari/web-services';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { ConfigService } from '../../../shared/services';
import { GenericServices } from '../../shared/services/generic.service';
import { DocumentCenterService } from './document-center.service';
import { DocumentsTabConfig } from './documentsConfig.model';

@Component({
  selector: 'document-center',
  templateUrl: './document-center.component.html',
  styleUrls: ['./document-center.component.scss'],
})
export class DocumentCenterComponent implements OnInit {
  config;
  filters;
  formGroup: UntypedFormGroup;
  context: any = {};

  activeTab: number;
  activeTabObj: DocumentsTabConfig;
  tabLabels: string[] = [];
  tabsConfig: DocumentsTabConfig[] = [];
  constructor(
    private configService: ConfigService,
    private router: Router,
    private documentCenterService: DocumentCenterService,
    private route: ActivatedRoute,
    public analyticsService: AnalyticsService,
    private genericServices: GenericServices,
    private window: WindowService,
  ) {}

  ngOnInit() {
    this.config = this.configService.getPageConfig<any>('documents');
    this.context['documents'] = [];
    this.setTabLabels();
    this.setCurrentTab();
  }

  setTabLabels(): void {
    Object.keys(
      this.genericServices.sortObjectsByPriority(this.config.tabs),
    ).map((tab: any) => {
      this.tabLabels.push(this.config.tabs[tab].label);
      this.tabsConfig.push(this.config.tabs[tab]);
    });
  }

  tabSelected(ev): void {
    this.activeTab = ev;
    this.router
      .navigate([this.tabsConfig[ev]?.url], { relativeTo: this.route })
      .then((status: boolean) => {
        this.setCurrentTab();
      });
  }

  private setCurrentTab() {
    const targetRoute = this.documentCenterService.getTargetRoute(this.route);
    // When document doesn't have form tab it would break so by default select first available tab
    let currentTabIndex = this.tabsConfig.indexOf(
      this.tabsConfig.find((target: any) => target.prop === targetRoute),
    );

    if (!currentTabIndex || currentTabIndex === -1) {
      currentTabIndex = 0;
      // update url
      const newUrl = location.pathname.replace(
        /\/[^\/]*$/,
        `/${this.tabsConfig[currentTabIndex].url}`,
      );

      this.window.nativeWindow.history.replaceState({}, '', `${newUrl}`);
    }
    this.activeTab = currentTabIndex;
    this.activeTabObj = this.tabsConfig[currentTabIndex];
    this.documentCenterService.activeTabObj = this.activeTabObj;
    if (this.activeTabObj?.cx_event_key) {
      this.analyticsService.sendEvent(this.activeTabObj.cx_event_key);
    }
    this.documentCenterService.tabsConfig =
      this.config[this.activeTabObj.targetConfigKey];
  }
}
