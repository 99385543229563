<div [zipBusy]="busy">
  <div class="row header-row">
    <div class="row-item row-header">
      <p class="t-bold t-data row-header">
        {{ config?.title }}
      </p>
    </div>
  </div>
  <div
    *ngFor="let result of results"
    [ngClass]="{
      row: config.direction === 'row',
      column: config.direction === 'column',
    }"
  >
    <div
      *ngFor="let item of columns"
      class="row-item"
      [ngClass]="{
        item: config.direction === 'row',
        'column-item': config.direction === 'column',
      }"
    >
      <p
        class="t-data header-text"
        [ngClass]="{
          'custom-link': item.format === 'LINK',
          'custom-circle': item.format === 'CIRCLE',
          'custom-text': item.format === 'TEXT',
        }"
        (click)="setActiveRow(result, item)"
      >
        {{ getObjectProperty(result, item?.prop) || noSpace }}
      </p>
    </div>
  </div>
  <div class="row" *ngIf="error">
    <div class="row-item item">
      <p class="t-alt header-text">
        {{ config.errorMessage || 'No Results.' }}
      </p>
    </div>
  </div>
  <footer
    class="dashboard-card__footer"
    [ngClass]="{
      'dashboard-card__footer--justify': config?.footer?.left?.content,
    }"
    *ngIf="config?.footer?.left?.content || config?.footer?.right?.content"
  >
    <ng-container *ngIf="config?.footer?.left?.content">
      <zip-button
        [config]="config?.footer?.left"
        (zipButtonClicked)="buttonClicked(config?.footer?.left?.route)"
      >
      </zip-button>
    </ng-container>

    <ng-container *ngIf="config?.footer?.right?.content">
      <zip-button
        [config]="config?.footer?.right"
        (zipButtonClicked)="buttonClicked(config?.footer?.right?.route)"
      >
      </zip-button>
    </ng-container>
  </footer>
</div>
