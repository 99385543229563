import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormattingService, TableComponent } from '@zipari/design-system';
import { getValue } from '@zipari/web-utils';
import { Subscription } from 'rxjs';
import { listComponentViews } from '../../../shared/templates/list/list.constants';

import { CX_CALLS } from '../../../shared/constants/cx-calls.constant';
import { ProviderGroup } from '../../../shared/models/shared/ProviderGroup.model';
import { ConfigService } from '../../../shared/services';
import { ZipEndpointService } from '../../../shared/services/zip-endpoint.service';
import {
  LIST_SERVICE,
  ListService,
  ListServiceInterface,
} from '../../../shared/templates/list/list.service';
import { DummyDataService } from '../../shared/dummy-data/dummy-data.service';
import { GenericServices } from '../../shared/services/generic.service';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { TableCellClickEvent } from '../../../shared/models/shared/CellClickEvent.model';

@Component({
  selector: 'provider-groups',
  templateUrl: './provider-groups.component.html',
  styleUrls: ['./provider-groups.component.scss'],
})
export class ProviderGroupsComponent implements OnInit, OnDestroy {
  @Input() detailModal: TemplateRef<any>;
  @Input() view: listComponentViews = listComponentViews.standalone;
  @Input() pageName: string;

  @Output() cellClicked = new EventEmitter();
  @Output() dataChanged = new EventEmitter();
  @Output() headerButtonClicked = new EventEmitter();
  @Output() saveSuccess = new EventEmitter();
  @Output() fileMetaData = new EventEmitter();
  @ViewChild(TableComponent) table: TableComponent;

  pageConfigs;
  showAddRowModal: boolean;
  showRowDetailModal: boolean;
  activeDetailRow: any;
  listComponentViews = listComponentViews;
  selectedRows: any;
  deleteConfig: any;
  openAccordionIdx = -1; // opens all accordians
  showDeleteModal = false;
  busy: Subscription;
  addSuccessResponse;
  addErrorResponse;
  data;

  constructor(
    public zipEndpointService: ZipEndpointService,
    private renderer: Renderer2,
    public genericListService: ListService,
    public genericServices: GenericServices,
    @Inject(LIST_SERVICE) private listService: ListServiceInterface,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private dummyData: DummyDataService,
    private formattingService: FormattingService,
    public analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.pageName = this.pageName
      ? this.pageName
      : this.route.snapshot.data['pageName'];
    this.pageConfigs = this.configService.getPageConfig(this.pageName);
    if (this.pageConfigs?.disable_header) {
      setTimeout(() => {
        this.genericServices.showFooter$.next(false);
      });
    }

    this.defaultTableOptions();
    this.setDefaultFilter();
  }

  defaultTableOptions(): void {
    // default spinner to all tables that are in list component
    if (!this.pageConfigs.table.hasOwnProperty('showSpinner')) {
      this.pageConfigs.table.showSpinner = true;
    }
  }

  /*
        ERHO: TEMPORARY: refactor this BS
     */
  setDefaultFilter(): void {
    let formattedDate: string;
    const target = this.pageConfigs.table.filters.find((item: any) =>
      item.hasOwnProperty('default'),
    );
    const currDate: Date = new Date();

    if (target) {
      if (target.default === 'CURRENT_DATE_UNFORMATTED') {
        // getMonth() index starts from 0 so for correct month we need to add 1
        formattedDate = `${currDate.getFullYear()}-${
          currDate.getMonth() + 1
        }-${currDate.getDate()}`;
        this.pageConfigs.table.endpoint += `?${target.prop}=${formattedDate}`;
      }
    }
  }

  /*
   * Route from Header Button
   */
  handleListButtonClick(config): void {
    if (config.isRelative) {
      this.router.navigate([config.route], { relativeTo: this.route });
    } else {
      this.router.navigate([config.route]);
    }
  }

  tableButtonClicked(event): void {
    event.stopPropagation();
    const relevantButtonClickConfig = getValue(
      this.pageConfigs,
      `buttonClick.${event.target?.innerText}`,
    );

    if (relevantButtonClickConfig) {
      if (relevantButtonClickConfig.route) {
        this.router.navigate([relevantButtonClickConfig.route]);
      }
    } else this.showAddRowModal = true;
  }

  onDataChanged(event): void {
    this.table.ztService?.unselectAllRows();
    this.dataChanged.emit(event);
    if (event.array) {
      event?.array.forEach((group: ProviderGroup) => {
        group.is_active = group.is_active ? 'Active' : 'Inactive';
      });
    }
  }

  onCancelClick(): void {
    this.cancelAddRow();
    this.showDeleteModal = false;
  }

  cancelAddRow() {
    this.addSuccessResponse = null;
    this.addErrorResponse = null;
    this.showAddRowModal = false;
    this.showRowDetailModal = false;
  }

  onViewProfile(id = this.activeDetailRow.id) {
    this.showRowDetailModal = false;
    this.router.navigate([id], { relativeTo: this.route });
  }

  onSaveNewUserClick(formValue: any): void {
    this.listService
      .addToList(this.pageConfigs.endpoint, formValue.formGroup)
      .subscribe(
        (response: any) => {
          this.addSuccessResponse = response;
          // Refresh table to get newly added row
          this.table.refresh(false);
        },
        (error: any) => {
          this.addErrorResponse = error;
        },
      );
  }

  onDeleteSubmit(event: any): void {
    if (event?.key) {
      this.showDeleteModal = false;
      this.table.refresh(true);
    }
  }

  cellClick(event: TableCellClickEvent): void {
    this.cellClicked.next(event);
    this.activeDetailRow = event.context?.row;

    // store the detail in a service
    if (this.pageConfigs.detailPageName) {
      this.zipEndpointService.setDetail(
        event.context.row,
        this.pageConfigs.detailPageName,
      );
    }

    if (this.pageConfigs.showDetailModal) {
      this.showRowDetailModal = true;
    } else if (this.pageConfigs.customListAction) {
      // isolated custom functionality for when a cell is clicked on table in list component
      switch (this.pageConfigs.customListAction.type) {
        case 'checkApplicationForSubmittedWorkflow':
          const result =
            this.genericListService.handleCheckingApplicationForUnfinishedWorkflow(
              this.configService,
              this.router,
              this.route,
              event,
              this.pageConfigs.customListAction.statuses,
            );

          if (result) {
            result.then();

            return;
          }
          break;
      }

      this.defaultRoute(null, event.context?.col?.cxCapture);
    } else if (event.context?.col?.formatOptions) {
      this.defaultRoute(
        event.context.col.formatOptions,
        event.context?.col?.cxCapture,
      );
    }
  }

  defaultRoute(config?, eventKey?): void {
    let uniqueIdentifierKey: string;
    let targetRoute: string;
    let isRelative: boolean;

    if (config && config.isRelative) {
      isRelative = config.isRelative;
    }

    if (config && config.uniqueIdentifier) {
      uniqueIdentifierKey = config.uniqueIdentifier || 'id';
      targetRoute = config.targetRoute;
    } else {
      /*
       *   ERHO:
       *  TODO: deprecate this and have all cell routes in individual configs
       */
      // usually the key we will use is just `id` however there are choice times when a unique identifier
      // needs to be dynamic
      uniqueIdentifierKey = this.pageConfigs.uniqueIdentifier || 'id';
    }
    // once we know what key to look for... we need to get the unique identifier
    const uniqueIdentifier = getValue(
      this.activeDetailRow,
      uniqueIdentifierKey,
    );

    // navigate to this specific detail page
    // this.router.navigate([uniqueIdentifier], { relativeTo: this.route });
    this.routeToDetail(uniqueIdentifier, targetRoute, isRelative, eventKey);
  }

  routeToDetail(
    uniqueIdentifier?: string | number,
    targetRoute?: string,
    isRelative?: boolean,
    eventKey?,
  ): void {
    if (eventKey?.event_key) {
      this.analyticsService.sendEvent(CX_CALLS[eventKey.event_key]?.event_key);
    }

    if (isRelative) {
      this.router.navigate([targetRoute, uniqueIdentifier], {
        relativeTo: this.route,
      });
    } else {
      this.router.navigate([targetRoute, uniqueIdentifier]);
    }
  }

  onClosed = () => {
    this.showAddRowModal = false;
  };

  setTableBodyHeight() {
    if (this.table) {
      const bodyTop =
        this.table.tableBody.nativeElement.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const paddingBottom = 80;

      this.renderer.setStyle(
        this.table.tableBody.nativeElement,
        'height',
        `${windowHeight - bodyTop - paddingBottom}px`,
      );
    }
  }

  deleteClicked(event: any[]): void {
    if (this.pageConfigs.delete_panel && event.length > 0) {
      this.selectedRows = event;
      if (!this.deleteConfig) {
        this.deleteConfig = {};
        this.deleteConfig.workflow = this.pageConfigs.delete_panel;
      }
      this.showDeleteModal = true;
    }
  }

  ngOnDestroy() {
    if (this.pageConfigs?.disable_header) {
      setTimeout(() => {
        this.genericServices.showFooter$.next(true);
      });
    }
  }
}
