import { Location } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  AbstractControl,
  UntypedFormArray,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from '@zipari/web-services';
import { FormControlService } from '@zipari/design-system';
import { getValue } from '@zipari/web-utils';
import { forkJoin, Observable } from 'rxjs';
import { isEmpty } from 'lodash';
import * as _ from 'lodash';

import { GenericServices } from '../../shared/services/generic.service';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { ApiListResponse } from '../../../shared/models/shared/ApiListResponse.model';
import Member from '../../../shared/models/shared/Member.model';
import Policy from '../../../shared/models/shared/Policy.model';
import { defaultFileUploaderRestrictions } from '../../../shared/constants/file-uploader.constant';
import { UploadDocumentConfig } from '../../../shared/models/shared/FileUploader.model';
import {
  AuthService,
  ConfigService,
  FormattingService,
} from '../../../shared/services';
import { CX_CALLS } from '../../../shared/constants/cx-calls.constant';
import { ProviderUpdateToolService } from '../provider-update-tool/provider-update-tool.service';
import { objectToArray } from '../../../shared/utilities/object';

import {
  claimSubmissionConstants,
  ProvidersKeys,
} from './claim-submission.constant';
import { DocumentUploadService } from '../claim-appeal/services/document-upload.service';
import { getDefaultFileUploaderConfig } from '../../../shared/helpers/file-uploader.helper';

@Component({
  styleUrls: ['./claim-submission.component.scss'],
  selector: 'claim-submission',
  templateUrl: './claim-submission.component.html',
})
export class ClaimSubmissionComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
  id: any;
  config: any = {};
  context: { member: Member; coverage?: any } = { member: null };
  state: 'edit' | 'review' | 'confirm' = 'edit';
  formGroup = new UntypedFormGroup({});
  controls: Map<any, any> = new Map<any, any>();
  payload: any = {};
  risks: any[] = [];
  busy: any[] = [];
  response: any;
  error = false;
  nonDependentEndpoints: string[];
  nonDependentEndpointStatus: boolean[] = [];
  loaded = false;
  noSpace = 'n/a';
  diagnosisDetails: any;
  reviewServiceType = false;
  fileTooBig = false;
  hasFile: any = null;
  documentsAreEditable = true;
  cardArray = [];
  controlArray = [];
  getAuthDetails: any = {};
  parentArray: any = [];
  getFlyoutValue = '';
  apiResponse = '';
  obj;
  _: any = _;
  user: any;
  subscriberId: number;
  documentContext: UntypedFormArray;
  defaultFileUploaderRestrictions = defaultFileUploaderRestrictions;

  constructor(
    private route: ActivatedRoute,
    public configService: ConfigService,
    public genericServices: GenericServices,
    private formControlService: FormControlService,
    public router: Router,
    private api: APIService,
    private cdr: ChangeDetectorRef,
    private location: Location,
    private el: ElementRef,
    public mp: ProviderUpdateToolService,
    public authService: AuthService,
    public analyticsService: AnalyticsService,
    private formattingService: FormattingService,
    public documentUploadService: DocumentUploadService,
  ) {}

  ngOnInit(): void {
    this.user = this.authService.loggedInUser;
    setTimeout(() => {
      this.genericServices.showFooter$?.next(false);
    });
    this.id = this.route.snapshot.params.id;
    this.config = this.configService.getPageConfig('claim-submission');
    this.noSpace = this.config?.noSpace;
    this.refactorClaimConfig();
    this.buildPanel();
    this.buildForms();
  }

  ngAfterViewInit() {
    this.formGroup.get('claim_sub_type')?.disable();

    this.formGroup
      .get('claim_type')
      ?.valueChanges.subscribe((changedValueOrIndex: any) => {
        this.formGroup.get('claim_sub_type').enable();
      });

    if (this.documentUploadService.fileAttachments?.length > 0) {
      this.setFilesFromAPI(this.documentUploadService.fileAttachments);
    }
  }

  refactorClaimConfig() {
    if (this.config && this.config?.cards) {
      for (const cardKey of _.keys(this.config?.cards)) {
        for (const controlKey of _.keys(
          this.config?.cards[cardKey]?.controls,
        )) {
          this.config.cards[cardKey].controls = Object.keys(
            this.config?.cards[cardKey]?.controls,
          )
            .sort((a, b) =>
              this.config?.cards[cardKey]?.controls[a]['controlOrder'] >
              this.config?.cards[cardKey]?.controls[b]['controlOrder']
                ? 1
                : -1,
            )
            .reduce((result, key) => {
              result[key] = this.config?.cards[cardKey].controls[key];

              return result;
            }, {});
          for (const [key, value] of Object.entries(
            this.config?.cards[cardKey]?.controls,
          )) {
            if (
              key.indexOf(controlKey) !== -1 &&
              key.indexOf('flyout') !== -1 &&
              controlKey.indexOf('flyout') === -1
            ) {
              if (key === 'control-billing-provider-flyoutOnBillingProvider') {
                // To move flyout object inside associate control
                Object.assign(
                  this.config?.cards[cardKey]?.controls[controlKey],
                  { flyoutOnBillingProvider: value },
                );
                // To convert flyout table columns and filter object into array
                this.config.cards[cardKey].controls[
                  controlKey
                ].flyoutOnBillingProvider.table.columns = objectToArray(
                  value['table'].columns,
                );
                this.config.cards[cardKey].controls[
                  controlKey
                ].flyoutOnBillingProvider.table.filters = objectToArray(
                  value['table'].filters,
                );
              } else if (
                key === 'control-billing-provider-flyoutOnBillingFacility'
              ) {
                // To move flyout object inside associate control
                Object.assign(
                  this.config?.cards[cardKey]?.controls[controlKey],
                  { flyoutOnBillingFacility: value },
                );
                // To convert flyout table columns and filter object into array
                this.config.cards[cardKey].controls[
                  controlKey
                ].flyoutOnBillingFacility.table.columns = objectToArray(
                  value['table'].columns,
                );
                this.config.cards[cardKey].controls[
                  controlKey
                ].flyoutOnBillingFacility.table.filters = objectToArray(
                  value['table'].filters,
                );
              } else {
                // To move flyout object inside associate control
                Object.assign(
                  this.config?.cards[cardKey]?.controls[controlKey],
                  { flyout: value },
                );
                // To convert flyout table columns and filter object into array
                this.config.cards[cardKey].controls[
                  controlKey
                ].flyout.table.columns = objectToArray(value['table'].columns);
                this.config.cards[cardKey].controls[
                  controlKey
                ].flyout.table.filters = objectToArray(value['table'].filters);
              }
              delete this.config?.cards[cardKey]?.controls[key];
            }
          }
        }
      }
      this.config.cards = Object.keys(this.config?.cards)
        .sort((a, b) =>
          this.config?.cards[a]['cardOrder'] >
          this.config?.cards[b]['cardOrder']
            ? 1
            : -1,
        )
        .reduce((result, key) => {
          result[key] = this.config?.cards[key];

          return result;
        }, {});
    }
  }

  buildPanel(): void {
    const memberApi: Observable<Member> = this.api.get(
      this.setMemberId(this.config?.endpoints?.member),
    );
    const coverageApi: Observable<ApiListResponse<Policy>> = this.api.get(
      this.setMemberId(this.config?.endpoints?.coverage),
    );

    this.busy.push(
      forkJoin([memberApi, coverageApi]).subscribe(([member, coverage]) => {
        this.context.member = member;
        this.subscriberId = member.id;
        this.context.coverage = coverage.results[0]?.product_coverages[0];
        if (
          this.context?.member?.additional_fields
            ?.enrolled_medicare_savings_program
        ) {
          this.context.member.additional_fields.enrolled_medicare_savings_program =
            ' ';
        }
        if (this.context.coverage) {
          this.context.coverage.effective_date = this.formatDate(
            this.context.coverage.effective_date,
          );
          this.context.coverage.termination_date = this.formatDate(
            this.context.coverage.termination_date,
          );
        }
        this.loaded = true;
      }),
    );
  }

  formatDate(date: string): string {
    return this.formattingService.restructureValueBasedOnFormat(date, {
      format: 'DATE',
    });
  }

  setMemberId(target: string): string {
    if (target === null || target === undefined) return '';

    return target.replace('${member_number}', this.id);
  }

  switchTriggered(index) {
    const tempCardArr = [...this.cardArray];

    this.cardArray = [];
    tempCardArr.map((item, i) => {
      if (i !== index) {
        item?.group_to_load?.get('is_primary')?.setValue(false);
      }
      this.cardArray.push({ ...item });
    });
  }

  checkShowOnTrueValues(attr): boolean {
    let targetDataExists: boolean;
    let value: string | number;

    if (attr.showIfTrue) {
      value = this.getValue(this.context, attr);
      targetDataExists = value.toString().length > 0;
    } else return false;

    return targetDataExists;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  getValue(data: object, attr, val?: boolean): string | number {
    const value: string | number = val
      ? getValue(data, attr.value)
      : getValue(data, attr.prop);

    return value;
  }

  getBusy() {
    let returnVal: any = this.busy[0];

    this.busy.forEach((item: any) => {
      returnVal = returnVal || item;
    });

    return returnVal;
  }

  setAuthNumber(target: string, id: string): string {
    if (target === null || target === undefined) return '';

    return target.replace('${auth_number}', id);
  }

  prefillAuthData(param, dynaCardType) {
    if (param?.services?.length > 1) {
      for (let i = 1; i < param.services.length; i++) {
        this.addCard(dynaCardType);
      }
    }
    if (this.config && this.config?.cards) {
      for (const key in this.config?.cards) {
        for (const control in this.config?.cards[key].controls) {
          if (
            this.config?.cards[key].controls[control].apiProp &&
            !this.config?.cards[key].controls[control].apiProp?.includes(
              'service',
            )
          ) {
            const getKeyValue = this.getObjectProperty(
              param,
              this.config?.cards[key].controls[control].apiProp,
            );

            if (getKeyValue) {
              this.formGroup.controls[
                this.config?.cards[key].controls[control].prop
              ]?.setValue(getKeyValue);
            }
          }
          if (
            this.config?.cards[key].controls[control].apiProp &&
            this.config?.cards[key].controls[control].apiProp?.includes(
              'service',
            )
          ) {
            if (param.services[0]) {
              const apiProp = this.config?.cards[key].controls[control].apiProp
                .split('.')
                .slice(-1)[0];
              const getKeyValue = this.getObjectProperty(
                param.services[0],
                apiProp,
              );

              if (this.config?.cards[key].type !== 'dynamicCard') {
                this.formGroup?.controls[
                  this.config?.cards[key]?.controls[control]?.prop
                ]?.setValue(getKeyValue);
              } else {
                this.cardArray[0]?.group_to_load?.controls[
                  this.config?.cards[key]?.controls[control]?.prop
                ]?.setValue(getKeyValue);
              }
            }
            if (param?.services?.length > 1) {
              for (let p = 1; p < param.services.length; p++) {
                if (param.services[p]) {
                  const apiProp = this.config?.cards[key].controls[
                    control
                  ].apiProp
                    .split('.')
                    .slice(-1)[0];
                  const getKeyValue = this.getObjectProperty(
                    param.services[p],
                    apiProp,
                  );

                  for (let q = 1; q < this.cardArray.length; q++) {
                    if (
                      this.cardArray[q]?.group_to_load?.controls[
                        this.config?.cards[key]?.controls[control]?.prop
                      ]
                    ) {
                      this.cardArray[q].group_to_load.controls[
                        this.config?.cards[key].controls[control].prop
                      ].setValue(getKeyValue);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  buildForms() {
    if (this.config && this.config?.cards) {
      for (const key in this.config?.cards) {
        if (this.config?.cards[key].type !== 'dynamicCard') {
          for (const control in this.config?.cards[key].controls) {
            if (
              this.config?.cards[key].controls[control].type ===
              'dynamicDropDown'
            ) {
              this.formGroup
                .get(this.config?.cards[key].controls[control].ifThisProp)
                ?.valueChanges.subscribe((val: any) => {
                  if (
                    this.config?.cards[key].controls[
                      control
                    ].hasTheseValues?.includes(val)
                  ) {
                    this.controls.set(
                      this.config?.cards[key].controls[control].prop,
                      this.config?.cards[key].controls[control].formConfig,
                    );
                  }
                });
              const new_control: AbstractControl = new UntypedFormControl(
                '',
                [],
              );
              const newFormControl = Object.assign(
                this.config?.cards[key].controls[control].formConfig,
                {
                  control: new_control,
                },
              );

              this.formControlService.addControlToFormGroup(
                this.formGroup,
                newFormControl,
              );
            } else {
              this.controls.set(
                this.config?.cards[key].controls[control].prop,
                this.config?.cards[key].controls[control],
              );
              const new_control: AbstractControl = new UntypedFormControl(
                '',
                [],
              );
              const newFormControl = Object.assign(
                this.config?.cards[key].controls[control],
                {
                  control: new_control,
                },
              );

              this.formControlService.addControlToFormGroup(
                this.formGroup,
                newFormControl,
              );
            }
            this.loadDataForControl(this.config?.cards[key].controls[control]);

            // On changing authorization number prefetch all auth details and display
            if (
              this.config?.cards[key].controls[control].prop ===
              'authorization_number'
            ) {
              this.formGroup
                .get('authorization_number')
                ?.valueChanges.subscribe((selectedAuthNumber: any) => {
                  this.busy.push(
                    this.api
                      .get(
                        this.setAuthNumber(
                          this.config?.cards[key].controls[control]
                            .apiEndpointDetails,
                          selectedAuthNumber.id,
                        ),
                      )
                      .subscribe((resp: any) => {
                        this.getAuthDetails = resp;
                        this.prefillAuthData(
                          this.getAuthDetails,
                          this.formGroup.controls['claim_sub_type'].value,
                        );
                      }),
                  );
                });
            }

            // set default option for state
            if (
              this.config?.cards[key].controls[control].prop === 'state_code'
            ) {
              this.formGroup.controls['state_code'].setValue(
                this.config?.cards[key].controls[control].setDefaultOption
                  .value,
              );
            }

            // set diagnosis pointer options based on selection of diagnosis
            if (
              this.config?.cards[key].controls[control].prop === 'diagnosis'
            ) {
              let diagnosisOptions: any = [];

              this.formGroup
                .get('diagnosis')
                ?.valueChanges.subscribe((values: any) => {
                  diagnosisOptions = [];

                  if (this.diagnosisDetails) {
                    for (const diagOptins of this.diagnosisDetails) {
                      diagnosisOptions.push({
                        label: diagOptins.row.description,
                        value: diagOptins.row.id,
                      });
                    }
                  }

                  if (this.config?.cards) {
                    Object.keys(this.config?.cards).forEach(
                      (inner_key: any) => {
                        Object.keys(
                          this.config?.cards[inner_key].controls,
                        ).forEach((key1: any) => {
                          const dcs =
                            this.config?.cards[inner_key].controls[key1]
                              .dyanmicControl;

                          if (dcs) {
                            const inner_control: any = this.config?.cards[
                              inner_key
                            ].controls[key1].dyanmicControl.find(
                              (dControls: any) =>
                                dControls.prop === 'diagnosis_pointer',
                            );

                            if (inner_control) {
                              inner_control.options = diagnosisOptions;
                            }
                          }
                        });
                      },
                    );
                  }
                });

              if (this.config?.cards) {
                Object.keys(this.config?.cards).forEach((inner_key: any) => {
                  Object.keys(this.config?.cards[inner_key].controls).forEach(
                    (key1: any) => {
                      const dcs =
                        this.config?.cards[inner_key].controls[key1]
                          .dyanmicControl;

                      if (dcs) {
                        const inner_control: any = this.config?.cards[
                          inner_key
                        ].controls[key1].dyanmicControl.find(
                          (dControls: any) =>
                            dControls.prop === 'diagnosis_pointer',
                        );

                        if (inner_control) {
                          inner_control.options = diagnosisOptions;
                        }
                      }
                    },
                  );
                });
              }
            }
          }
        } else if (this.config?.cards[key].type === 'dynamicCard') {
          this.formGroup
            .get(this.config?.cards[key].ifThisProp)
            ?.valueChanges.subscribe((val: any) => {
              if (this.config?.cards[key].loadCardFor === val) {
                this.cardArray = [];
                this.controlArray = [];
                const dynamicArray = [];
                const dynamicControls: Map<any, any> = new Map<any, any>();
                const dynamicFormGroup = new UntypedFormGroup({});
                const dynamicControlGroup = new UntypedFormGroup({});

                for (const control in this.config?.cards[key].controls) {
                  if (
                    this.config?.cards[key].controls[control].dyanmicControl
                  ) {
                    for (const dyanControls of this.config?.cards[key].controls[
                      control
                    ].dyanmicControl) {
                      const new_control: AbstractControl =
                        new UntypedFormControl('', []);
                      const newFormControl = Object.assign(dyanControls, {
                        control: new_control,
                      });

                      this.formControlService.addControlToFormGroup(
                        dynamicControlGroup,
                        newFormControl,
                      );
                      this.loadDataForControl(dyanControls);
                      dynamicControls.set(dyanControls.prop, dyanControls);
                    }
                  } else {
                    const new_control: AbstractControl = new UntypedFormControl(
                      '',
                      [],
                    );
                    const newFormControl = Object.assign(
                      this.config?.cards[key].controls[control],
                      {
                        control: new_control,
                      },
                    );

                    this.formControlService.addControlToFormGroup(
                      dynamicFormGroup,
                      newFormControl,
                    );
                    this.loadDataForControl(
                      this.config?.cards[key].controls[control],
                    );
                    dynamicControls.set(
                      this.config?.cards[key].controls[control].prop,
                      this.config?.cards[key].controls[control],
                    );
                  }
                }
                dynamicArray.push(dynamicControlGroup);
                this.parentArray.push(dynamicArray);
                this.cardArray.push({
                  card_to_load: this.config?.cards[key],
                  group_to_load: dynamicFormGroup,
                  controls_to_load: dynamicControls,
                  duplicate_group_to_load: dynamicArray,
                });
              }
            });
        }
      }
    }
  }

  addCard(id: any) {
    if (this.config && this.config?.cards) {
      for (const key in this.config?.cards) {
        if (this.config?.cards[key]?.loadCardFor === id) {
          const dynamicControls: Map<any, any> = new Map<any, any>();
          const dynamicFormGroup = new UntypedFormGroup({});
          const dynamicControlGroup = new UntypedFormGroup({});
          const dynamicArray = [];

          for (const control in this.config?.cards[key].controls) {
            if (this.config?.cards[key].controls[control].dyanmicControl) {
              for (const dyanControls of this.config?.cards[key].controls[
                control
              ].dyanmicControl) {
                const new_control: AbstractControl = new UntypedFormControl(
                  '',
                  [],
                );
                const newFormControl = Object.assign(dyanControls, {
                  control: new_control,
                });

                this.formControlService.addControlToFormGroup(
                  dynamicControlGroup,
                  newFormControl,
                );
                dynamicControls.set(dyanControls.prop, dyanControls);
              }
            } else {
              const new_control: AbstractControl = new UntypedFormControl(
                '',
                [],
              );
              const newFormControl = Object.assign(
                this.config?.cards[key].controls[control],
                {
                  control: new_control,
                },
              );

              this.formControlService.addControlToFormGroup(
                dynamicFormGroup,
                newFormControl,
              );
              dynamicControls.set(
                this.config?.cards[key].controls[control].prop,
                this.config?.cards[key].controls[control],
              );
            }
          }
          dynamicArray.push(dynamicControlGroup);
          this.parentArray.push(dynamicArray);
          this.cardArray.push({
            card_to_load: this.config?.cards[key],
            group_to_load: dynamicFormGroup,
            controls_to_load: dynamicControls,
            duplicate_group_to_load: dynamicArray,
          });
        }
      }
    }
  }

  cardToLoadValues(card): boolean {
    let targetDataExists: boolean;

    if (card.loadCardFor === this.formGroup.get(card.ifThisProp).value) {
      targetDataExists = this.cardArray.length > 0;
    } else return false;

    return targetDataExists;
  }
  loadDataForControl(control) {
    const endpoint =
      this.config?.outOfNetwork?.isOutOfNetworkEnabled &&
      control.outOfNetworkApiEndpoint
        ? control.outOfNetworkApiEndpoint
        : control.apiEndpoint;

    if (!endpoint) return false;

    this.getDataForControl(endpoint, control);
  }

  getDataForControl(endpoint: string, control: any) {
    this.busy.push(
      this.api.get(this.setMemberId(endpoint)).subscribe(
        (resp: any) => {
          if (control.optionFormat) {
            let obj;
            const dropdownArray = [];

            resp.results.map((item, idx) => {
              let thing = '';

              control.optionFormat.map((format: any) => {
                thing += `${getValue(item, format)}`;
              });

              obj = {
                label: thing,
                value: idx,
              };

              const optionObject = Object.assign(obj, item);

              dropdownArray.push(obj);
            });
            control.options = this.populateDropdown(
              new Array<any>(),
              dropdownArray,
              control.dropdownDisplayProp,
            );
          } else {
            control.options = this.populateDropdown(
              new Array<any>(),
              resp.results,
              control.dropdownDisplayProp,
            );
          }
        },
        (error: any) => {
          this.error = false;
        },
      ),
    );
  }

  checkLimit(limit): boolean {
    if (limit) {
      if (limit + 1 === this.cardArray.length) return false;
      else return true;
    } else {
      return true;
    }
  }

  deleteCard(index) {
    this.cardArray.splice(index, 1);
    this.parentArray.splice(index, 1);
  }

  getState(updateVal) {
    this.state = updateVal;
  }

  print(event): void {
    window.print();
  }

  cancel(event: any): void {
    if (this.genericServices.previousURL.includes('provider-portal')) {
      this.location.back();
    }
  }

  selectedDiagnosisDetails(event) {
    this.diagnosisDetails = event;
  }

  getFlyoutValueFromPopup(value) {
    this.getFlyoutValue = value;
  }

  getCXValue(cxKey: string, cxAttribute = null) {
    const cxArray = [];

    this.cardArray.map((key: any) =>
      cxAttribute
        ? cxArray.push(key.group_to_load.value[cxKey][cxAttribute])
        : key.group_to_load.value[cxKey]
          ? cxArray.push(key.group_to_load.value[cxKey])
          : '',
    );

    return cxArray.length > 0 ? cxArray : '';
  }

  getDuplicateGroupCXValue(cxKey: string, cxAttribute = null) {
    const cxArray = [];

    this.cardArray.map((key: any) =>
      cxAttribute
        ? cxArray.push(key.duplicate_group_to_load[0].value[cxKey][cxAttribute])
        : key.duplicate_group_to_load[0].value[cxKey]
          ? cxArray.push(key.duplicate_group_to_load[0].value[cxKey])
          : '',
    );

    return cxArray.length > 0 ? cxArray : '';
  }

  scrollTo(event: any): void {
    this.back(null);

    const dictionary_attribute = {
      claim_type: this.formGroup.value?.claim_type,
      claim_sub_type: this.formGroup.value?.claim_sub_type,
      place_of_service: this.getCXValue(
        claimSubmissionConstants.place_of_service,
        claimSubmissionConstants.code,
      ),
      unit_type: this.getCXValue(claimSubmissionConstants.unit_type),
      priority: this.getDuplicateGroupCXValue(
        claimSubmissionConstants.priority,
      ),
      requested_units: this.getCXValue(
        claimSubmissionConstants.service_unit_quantity,
      ),
      diagnosis_code: this.formGroup.value?.diagnosis?.map(
        (element: any) => element?.row?.code,
      ),
      diagnosis_description: this.formGroup.value?.diagnosis?.map(
        (element: any) => element?.row?.description,
      ),
      procedure_code: this.getCXValue(
        claimSubmissionConstants.procedure_id,
        claimSubmissionConstants.code,
      ),
      procedure_description: this.getCXValue(
        claimSubmissionConstants.procedure_id,
        claimSubmissionConstants.description,
      ),
    };

    this.analyticsService.sendEvent(
      CX_CALLS.pp_claim_entry_edited.event_key,
      dictionary_attribute,
    );

    setTimeout(() => {
      const itemToScrollTo = document.getElementById(event);
      // null check to ensure that the element actually exists

      if (itemToScrollTo) {
        itemToScrollTo.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  populateDropdown(options: any[], results: any[], prop: string) {
    for (const item of results) {
      options.push({
        label: this.getObjectProperty(item, prop),
        value: item,
      });
    }

    return options;
  }

  getObjectProperty(obj, key) {
    return key.split('.').reduce(function (result, inner_key) {
      if (!result) return;
      if (result[inner_key] !== null) return result[inner_key];
      else {
        result[inner_key] = null;

        return null;
      }
    }, obj);
  }

  getDropdownDisplayValue(prop: string): string {
    const options: any[] = this.controls.get(prop).options;

    if (options) {
      const option: any = options.find(
        (item: any) => item.value === this.formGroup.get(prop).value,
      );

      if (option) return option.label;
    }

    return this.noSpace;
  }

  back(event: any): void {
    if (this.state === 'review') this.state = 'edit';
  }

  checkValidation(event): boolean {
    let lengthFlag = false;

    for (let i = 0; i < this.cardArray.length; i++) {
      if (this.formGroup.valid && this.cardArray[i]['group_to_load'].valid) {
        if (i !== this.cardArray.length - 1) continue;
        lengthFlag = true;
      } else {
        this.formGroup.markAllAsTouched();
        this.cardArray[i]['group_to_load'].markAllAsTouched();
        lengthFlag = false;
        setTimeout(() => {
          this.genericServices.scrollToFirstInvalidControl(this.el);
        });
      }
    }

    return lengthFlag;
  }

  review(event: any): void {
    if (this.checkValidation(event)) {
      if (this.state === 'edit') this.state = 'review';
      window.scrollTo(0, 0);
      this.analyticsService.sendEvent(
        CX_CALLS.pp_claim_entry_reviewed.event_key,
      );
    } else {
      this.formGroup.markAllAsTouched();
      this.genericServices.scrollToFirstInvalidControl(this.el);
    }
  }

  setFieldIfExists(obj: any, field_name: string) {
    if (obj && (typeof obj === 'number' || !isEmpty(obj))) {
      this.payload[field_name] = obj;
    }
  }

  setField(obj: any, field_name: string) {
    this.payload[field_name] = obj;
  }

  setNestedFieldIfExists(obj: any, outter_field: string, inner_field: string) {
    if (obj && !isEmpty(obj)) {
      if (!this.payload[outter_field]) this.payload[outter_field] = {};
      this.payload[outter_field][inner_field] = obj;
    }
  }

  setDiagnosis() {
    const diagnosis: any[] = [];

    this.diagnosisDetails?.forEach((element: any) => {
      diagnosis.push({ id: element.row.id, code: element.row.code });
    });

    return diagnosis;
  }

  setNotes() {
    return [
      {
        note_content: this.formGroup.value?.notes,
        note_type: 'additional_note',
      },
    ];
  }

  setProcedures(procedure_obj) {
    return [{ id: procedure_obj.id }];
  }

  setProvider(provider_obj, provider_type) {
    const provider = {
      id: provider_obj.id,
      facility_location_id: provider_obj.provider_id,
      provider_type: provider_type,
    };

    if (this.config?.outOfNetwork.isOutOfNetworkEnabled) {
      provider['network_status'] = provider_obj?.member_network?.network_status;
    }

    return [provider];
  }

  setDuplicateControlData(dynamic_control_array) {
    const diagnoses_information: any[] = [];

    dynamic_control_array.forEach((element: any) => {
      diagnoses_information.push({
        diagnosis: { id: element.value.diagnosis_pointer },
        priority: element.value.priority,
      });
    });

    return diagnoses_information;
  }

  setFacilityInformation() {
    const servicingFacilityLocation =
      this.formGroup.value?.servicing_facility_location_id;
    const facilityInformation = {
      facility_id: servicingFacilityLocation?.facility_id,
      facility_location_id: servicingFacilityLocation?.id,
      admission_date: this.formGroup.value?.admit_date,
      discharge_date: this.formGroup.value?.discharge_date,
    };

    if (this.config?.outOfNetwork.isOutOfNetworkEnabled) {
      facilityInformation['network_status'] =
        servicingFacilityLocation?.member_network?.network_status;
    }

    return facilityInformation;
  }

  setBillingFacilityLocation(provider_obj) {
    if (this.getFlyoutValue === 'flyoutOnBillingFacility') {
      return provider_obj.id.toString();
    }

    return null;
  }

  setBillingProviderLocation(provider_obj, provider_type) {
    if (this.getFlyoutValue === 'flyoutOnBillingProvider') {
      const billingProvider = {
        id: provider_obj.id,
        provider_type: provider_type,
      };

      if (this.config?.outOfNetwork.isOutOfNetworkEnabled) {
        billingProvider['network_status'] =
          provider_obj?.member_network?.network_status;
      }

      return [billingProvider];
    }

    return [];
  }

  setAccidentInformation() {
    if (
      this.formGroup.value?.patient_condition_related_to === 'auto_accident'
    ) {
      return {
        accident_type: this.formGroup.value?.patient_condition_related_to,
        state_code: this.formGroup.value?.state_code,
      };
    } else if (
      this.formGroup.value?.patient_condition_related_to === 'employment'
    ) {
      return {
        accident_type: this.formGroup.value?.patient_condition_related_to,
      };
    }
  }

  setPatientDetails() {
    return {
      id: this.subscriberId,
      subscriber_number: this.subscriberId,
    };
  }
  buildPayload() {
    const name = {
      full_name: this.formGroup.value?.submitted_by_name,
    };
    const phone = [{ phone_number: this.formGroup.value?.submitted_by_phone }];
    const contact_information = {
      name: name,
      phone_numbers: phone,
      email: this.formGroup.value?.submitted_by_email,
    };

    // create claim line object of dynamic card
    const serviceArray: any[] = [];

    if (this.cardArray.length > 0) {
      const startIndex = 0;

      for (let i = startIndex; i < this.cardArray.length; i++) {
        const obj = {
          procedures: this.setProcedures(
            this.cardArray[i].group_to_load.value.procedure_id,
          ),
          providers: this.setProvider(
            this.cardArray[i].group_to_load.value.rendering_provider_id,
            'rendering',
          ),
          diagnoses_information: this.setDuplicateControlData(
            this.cardArray[i].duplicate_group_to_load,
          ),
          place_of_service_code:
            this.cardArray[i].group_to_load.value.place_of_service.code,
          billed_amount: this.cardArray[i].group_to_load.value.charge_amount,
          service_unit_type: this.cardArray[i].group_to_load.value.unit_type,
          service_unit_quantity:
            this.cardArray[i].group_to_load.value.service_unit_quantity,
          service_from_date:
            this.cardArray[i].group_to_load.value.service_from_date,
          service_to_date:
            this.cardArray[i].group_to_load.value.service_to_date,
          type_of_service_description:
            this.cardArray[i].group_to_load.value?.procedure_id?.description,
          line_number:
            this.cardArray[i].group_to_load.value?.procedure_id?.value,
        };

        serviceArray.push(obj);
      }
    }

    // Create claim detail object
    const claimDetailObject: any = {
      claim_type: this.formGroup.value?.claim_type,
      claim_subtype: this.formGroup.value?.claim_sub_type,
      total_billed_amount: this.formGroup.value?.total_charge,
      remarks: this.formGroup.value?.remarks,
      facility_information: this.setFacilityInformation(),
      billing_facility_location_id: this.setBillingFacilityLocation(
        this.formGroup.value?.billing_provider_location,
      ),
      providers: this.setBillingProviderLocation(
        this.formGroup.value?.billing_provider_location,
        'billing',
      ),
      diagnoses: this.setDiagnosis(),
      patient_accident_information: this.setAccidentInformation(),
      prior_authorization_number: this.formGroup.value?.authorization_number
        ?.number
        ? this.formGroup.value?.authorization_number?.number
        : null,
      document_ids: this.getDocumentIds(),
      patient: this.setPatientDetails(),
    };

    this.payload = {};
    this.setFieldIfExists(
      { name: this.formGroup.value?.submitted_by_name },
      'submitted_by_signature',
    );
    this.setField(
      contact_information ? contact_information : {},
      'contact_information',
    );
    this.setField(serviceArray ? serviceArray : [], 'claim_lines');
    this.setField(claimDetailObject ? claimDetailObject : {}, 'claim_detail');
    this.setFieldIfExists(
      new Date().toISOString().split('T')[0],
      'submission_date',
    );
    this.setFieldIfExists('submitted', 'submission_status');
    this.setFieldIfExists('Zipari', 'source');
    this.setFieldIfExists(
      this.user?.app_user_data?.user_name,
      'submitted_by_username',
    );
  }

  submit(event: any): void {
    this.buildPayload();

    const filteredPayload = this.pruneEmpty(this.payload);
    const dictionary_attribute = {
      claim_type: this.formGroup.value?.claim_type,
      claim_sub_type: this.formGroup.value?.claim_sub_type,
      place_of_service: this.getCXValue(
        claimSubmissionConstants.place_of_service,
        claimSubmissionConstants.code,
      ),
      unit_type: this.getCXValue(claimSubmissionConstants.unit_type),
      priority: this.getDuplicateGroupCXValue(
        claimSubmissionConstants.priority,
      ),
      requested_units: this.getCXValue(
        claimSubmissionConstants.service_unit_quantity,
      ),
      diagnosis_code: this.formGroup.value?.diagnosis?.map(
        (element: any) => element?.row?.code,
      ),
      diagnosis_description: this.formGroup.value?.diagnosis?.map(
        (element: any) => element?.row?.description,
      ),
      procedure_code: this.getCXValue(
        claimSubmissionConstants.procedure_id,
        claimSubmissionConstants.code,
      ),
      procedure_description: this.getCXValue(
        claimSubmissionConstants.procedure_id,
        claimSubmissionConstants.description,
      ),
    };

    this.busy.push(
      this.api.post(this.config.endpoints.submitApi, filteredPayload).subscribe(
        (resp: any) => {
          this.response = resp;
          if (this.response.submission_status === 'rejected') {
            this.state = 'edit';
            this.apiResponse = this.response.submission_status;
          } else if (
            this.response.submission_status === 'accepted' &&
            this.state === 'review'
          ) {
            this.state = 'confirm';
            window.scrollTo(0, 0);
          } else {
            this.toggleError();
          }
          this.analyticsService.sendEvent(
            CX_CALLS.pp_claim_entry_completed.event_key,
            dictionary_attribute,
          );
        },
        (err: any) => {
          this.toggleError();
        },
      ),
    );
  }

  pruneEmpty(obj) {
    return (function prune(current) {
      _.forOwn(current, function (value, key) {
        if (
          _.isUndefined(value) ||
          _.isNull(value) ||
          _.isNaN(value) ||
          (_.isString(value) && _.isEmpty(value)) ||
          (_.isObject(value) && _.isEmpty(prune(value)) && key !== 'providers')
        ) {
          delete current[key];
        }
      });
      // remove any leftover undefined values from the delete
      // operation on an array
      if (_.isArray(current)) _.pull(current, undefined);

      return current;
    })(_.cloneDeep(obj)); // Do not modify the original object, create a clone instead
  }

  getDefaultReviewBox(control: any) {
    if (control.type === 'date') {
      if (
        this.formGroup.get(control.prop) &&
        this.formGroup.get(control.prop).value
      ) {
        const value: any = this.formGroup.get(control.prop).value;
        const dateObj = new Date(`${value}T00:00:00`);

        return new Intl.DateTimeFormat('en-US').format(dateObj);
      } else {
        return this.noSpace;
      }
    }

    return this.formGroup.get(control.prop)
      ? this.formGroup.get(control.prop).value
        ? this.formGroup.get(control.prop).value
        : this.noSpace
      : this.noSpace;
  }

  toggleError() {
    this.error = !this.error;
  }

  showOutOfNetworkStatusPill(key: string, controlKey: string): boolean {
    const isInNetworkProp = this.config?.cards[key]?.controls[controlKey]?.prop;
    const isInNetworkValue =
      this.formGroup.get(isInNetworkProp)?.value?.member_network?.is_in_network;

    return (
      this.config?.outOfNetwork?.isOutOfNetworkEnabled &&
      isInNetworkValue === false
    );
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.genericServices.showFooter$?.next(true);
    });
    this.documentUploadService.uploadedFiles = [];
    this.documentUploadService.uploadAttachmentSubscription?.unsubscribe();
  }

  isAnyProviderOutOfNetwork(): boolean {
    const outOfNetworkProvider = ProvidersKeys.find((provider: string) => {
      const renderringOutOfNetworkProvider = this.cardArray.some(
        (cardItem: any) =>
          cardItem?.group_to_load?.value[provider]?.member_network
            ?.is_in_network === false,
      );

      return (
        renderringOutOfNetworkProvider ||
        this.formGroup.value[provider]?.member_network?.is_in_network === false
      );
    });

    return !!outOfNetworkProvider;
  }

  fileUploaded(fileList: File[]): void {
    const { documentUploadType, claimDocument, controlDocument } =
      claimSubmissionConstants;
    const documentType = this.formGroup?.get(documentUploadType)?.value;
    const uploadDocumentConfig =
      this.config.cards[claimDocument].controls[controlDocument];
    const documentUploadUrl =
      uploadDocumentConfig?.documentUploadUrl ||
      uploadDocumentConfig?.documentCreate;

    if (documentUploadUrl) {
      this.documentUploadService.uploadFile(
        fileList,
        documentUploadUrl,
        documentType?.value,
      );
    }
  }

  removeFile(fileId: number): void {
    this.documentUploadService.removeFile(fileId);
    this.documentUploadService.uploadCompleted$.next(false);
  }

  public setFilesFromAPI(filesList: File[]): void {
    this.documentContext = new UntypedFormArray(
      filesList.map((file: File) => new UntypedFormControl(file)),
    );
  }

  public getDocumentIds(): number[] {
    return (
      this.documentUploadService.uploadedFiles.map(
        (uploadDocument) => uploadDocument.id,
      ) || []
    );
  }

  public getDefaultUploadDocumentConfig(
    uploadDocumentConfig: UploadDocumentConfig,
  ): UploadDocumentConfig {
    return getDefaultFileUploaderConfig(uploadDocumentConfig);
  }
}
