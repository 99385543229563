<article [zipBusy]="busy" class="centered-base_page">
  <div class="centered-base_page__breadcrumbs">
    <div class="centered-base_page__breadcrumbs_crumb">
      <p [ngClass]="{ 't-bold': state === 'manage' }" class="t-data">
        {{ config?.breadcrumbs?.editStep }}
      </p>
      <i class="centered-base_page__breadcrumbs_crumb__arrow icon icon--medium"
        >chevron_right</i
      >
    </div>
    <div class="centered-base_page__breadcrumbs_crumb">
      <p [ngClass]="{ 't-bold': state === 'review' }" class="t-data">
        {{ config?.breadcrumbs?.reviewStep }}
      </p>
      <i class="centered-base_page__breadcrumbs_crumb__arrow icon icon--medium"
        >chevron_right</i
      >
    </div>
    <div class="centered-base_page__breadcrumbs_crumb">
      <p [ngClass]="{ 't-bold': state === 'confirm' }" class="t-data">
        {{ config?.breadcrumbs?.confirmStep }}
      </p>
    </div>
  </div>

  <div class="centered-base_page__content" [ngSwitch]="state">
    <provisioner
      *ngSwitchCase="'manage'"
      [bootStrapId]="id"
      [config]="config?.content?.provisioner"
    >
    </provisioner>
    <view-provisioning
      *ngSwitchCase="'review'"
      [state]="state"
      [config]="config?.content"
    >
    </view-provisioning>
    <view-provisioning
      *ngSwitchCase="'confirm'"
      [state]="state"
      [config]="config?.content"
    >
    </view-provisioning>
  </div>
  <div class="centered-base_page__always_on_top" [ngSwitch]="state">
    <zip-button
      #cancel
      *ngSwitchCase="'manage'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonCancel"
      (zipButtonClicked)="
        cancelButtonClicked(config?.footer?.buttons?.buttonCancel?.content)
      "
    >
    </zip-button>
    <zip-button
      #cancel
      *ngSwitchCase="'confirm'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonReturn"
      (zipButtonClicked)="
        cancelButtonClicked(config?.footer?.buttons?.buttonReturn?.content)
      "
    >
    </zip-button>
    <zip-button
      #previous
      *ngSwitchCase="'review'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonPrevious"
      (zipButtonClicked)="
        previousButtonClicked(config?.footer?.buttons?.buttonPrevious?.content)
      "
    >
    </zip-button>
    <zip-button
      #continue
      *ngSwitchCase="'manage'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonContinue"
      (zipButtonClicked)="
        continueButtonClicked(config?.footer?.buttons?.buttonContinue?.content)
      "
    >
    </zip-button>
    <zip-button
      #save
      *ngSwitchCase="'review'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonSave"
      (zipButtonClicked)="
        saveButtonClicked(config?.footer?.buttons?.buttonSave?.content)
      "
    >
    </zip-button>
  </div>
</article>
<modal
  [config]="config?.continueModal"
  [condition]="showModal"
  (cancel)="handleModalCancel()"
>
  <div body>{{ config?.continueModal.message }}</div>
</modal>

<modal
  [config]="config?.errorModal"
  [condition]="error"
  (cancel)="handleModalCancel()"
>
  <div body>{{ config?.errorModal.message }}</div>
</modal>
