import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import SuspendUserService from '../../services/suspend-user.service';
import SuspendUserEventService from '../../services/suspend-user-event.service';
import {
  CheckListItem,
  ReviewSuspendUserConfig,
} from '../../manage-portal-users-details.model';
import { SuspendUserConstants } from '../../manage-portal-users-details.constant';
import ActivateUserService from '../../services/activate-user.service';
@Component({
  selector: 'review-suspend-user',
  templateUrl: './review-suspend-user.component.html',
  styleUrls: ['./review-suspend-user.component.scss'],
})
export class ReviewSuspendUserComponent implements OnInit, OnChanges {
  @Input() config: ReviewSuspendUserConfig;
  @Output() editDetail: EventEmitter<string> = new EventEmitter();

  constructor(
    public suspendUserService: SuspendUserService,
    private suspendUserEventService: SuspendUserEventService,
    public activateUserService: ActivateUserService,
  ) {}

  ngOnInit(): void {
    this.emitDynamicButtonConfig();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config.currentValue !== changes.config.previousValue) {
      this.emitDynamicButtonConfig();
    }
  }

  emitDynamicButtonConfig(): void {
    if (this.config) {
      this.suspendUserEventService.emitDynamicButtonConfig(
        this.config.nextButton,
      );
    }
  }

  getDisplayItems(key: string): CheckListItem[] {
    return key === SuspendUserConstants.selectGroups
      ? this.suspendUserService.selectedGroups.length > 0
        ? this.suspendUserService.selectedGroups
        : this.activateUserService.selectedGroups
      : this.suspendUserService.selectedTaxIds;
  }
}
