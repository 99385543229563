import { Component, EventEmitter, Input, Output } from '@angular/core';

import { HeaderButtonType } from './header-row.model';
import { ColumnConfig } from '../../../models/column.model';
import { TableConfig } from '../../../models/table.model';
import { TableService } from '../../../services/table.service';

@Component({
  selector: 'table-header-row',
  templateUrl: './header-row.component.html',
  styleUrls: ['./header-row.component.scss'],
})
export class HeaderRowComponent {
  @Input() columns: ColumnConfig[];
  @Input() external: boolean;
  @Input() options: TableConfig;
  @Input() detailView;
  @Input() allExpanded;
  @Input() multiExpandedAllowed;
  @Input() grouping;
  @Input() dataExists;

  @Output() expandAllRows: EventEmitter<any> = new EventEmitter<any>();
  @Output() sortEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() editClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() headerButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() customMethod: EventEmitter<any> = new EventEmitter();

  constructor(public ztService: TableService) {}

  /** emits to the zip table component to initiate sorting
   * @param $event - the click event that initiated this sort
   * */
  fireSortEvent($event) {
    this.customEventMethod({ event: $event, context: this });
    this.sortEvent.emit($event);
  }

  markAllRowsAsSelected(event) {
    event.target.checked
      ? this.ztService.markAllRowsAsSelected()
      : this.ztService.unselectAllRows();
  }

  checkHeaderButton(event: any): boolean {
    /* Only hide the button if it is explicitly disabled */
    const button = `${event}Disabled`;
    if (this.ztService.zipTableOptions.selection.editHeaderConfig?.[button]) {
      return false;
    }

    return true;
  }

  customEventMethod({ event: event, context: context }) {
    // if a `customMethod` has been set on the component, emit event on that method.
    if (this.customMethod.observers.length) {
      // passing `event`and component `context` so can access component data in customMethod.
      this.customMethod.emit({ event: event, context: context });
    }
  }

  checkDeleteText(text: string) {
    if (this.ztService.zipTableOptions.selection.editHeaderConfig?.deleteText) {
      return this.ztService.zipTableOptions.selection.editHeaderConfig
        .deleteText;
    }

    return text;
  }

  get HeaderButtonType() {
    return HeaderButtonType;
  }

  getCSSClasses(column: ColumnConfig) {
    const customCSSClass = column.customOptions?.['customCSSClass'];

    return {
      tooltip: column.tooltip,
      [customCSSClass]: customCSSClass,
    };
  }
}
