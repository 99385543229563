import { Component, Input } from '@angular/core';
import { TableConfig } from '../../../models/table.model';
import { ColumnConfig } from './../../../models/column.model';
import { getValue } from '../../../../../shared/utils/get-value';

@Component({
  selector: 'table-summary-row',
  templateUrl: './summary-row.component.html',
  styleUrls: ['./summary-row.component.scss'],
})
export class SummaryRowComponent {
  @Input() columns: ColumnConfig[];
  @Input() data: any;
  @Input() options: TableConfig;

  getTotalValue(items, column) {
    return items.reduce((accumulator, currentItem) => {
      const currentValue = getValue(currentItem, column?.prop);

      if (column?.format === 'CURRENCY') {
        return currentValue ? accumulator + currentValue : accumulator;
      }

      return '--';
    }, 0);
  }
}
