import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../../shared/services';
import { GenericServices } from '../../shared/services/generic.service';
import { ProviderAdminListConfig } from './provider-admins.model';

@Component({
  selector: 'provider-admins',
  templateUrl: './provider-admins.component.html',
  styleUrls: ['./provider-admins.component.scss'],
})
export class ProviderAdminsComponent implements OnInit {
  config: ProviderAdminListConfig;

  constructor(
    public configService: ConfigService,
    public genericServices: GenericServices,
  ) {}

  ngOnInit(): void {
    this.genericServices.showFooter$.next(false);
    const providerAdminConfig =
      this.configService.getPageConfig('provider-admins');

    this.config = providerAdminConfig
      ? providerAdminConfig['providerAdminList']
      : {};
  }
}
