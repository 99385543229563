import { Injectable } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { WindowService, APIService } from '@zipari/web-services';
import { ConfigService } from '../../../shared/services';

@Injectable({
  providedIn: 'root',
})
export class DocumentCenterService {
  tabsConfig: any;
  activeTabObj: any;
  constructor(
    private api: APIService,
    private configService: ConfigService,
    private window: WindowService,
  ) {}

  getTargetRoute(route: ActivatedRoute): UrlSegment | undefined {
    const segments = route.snapshot?.['_routerState']?.url.split('/');

    segments?.shift(); // removes empty segment to match previous urlSegment array

    if (segments?.length >= 3) {
      return segments[2];
    } else {
      this.window.nativeWindow.location.assign(
        `${this.window.nativeWindow.location.origin}/${this.configService.appRoute}/dashboard`,
      );
    }
  }
}
