import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import {
  SquidexAssetsFolderResponse,
  SquidexAssetsFolderResponseItem,
  SquidexContentFlatResponse,
  SquidexDataService,
} from '@zipari/shared-data-access-squidex';
import * as moment from 'moment';
import { AuthService } from '../shared/services';

@Injectable({
  providedIn: 'root',
})
export class ProviderPortalService {
  constructor(
    private squidexDataService: SquidexDataService,
    private http: HttpClient,
    public authService: AuthService,
  ) {}

  public getSquidexContent = (contentEndpoint: string): Observable<object> => {
    const url = this.squidexDataService.buildUrl(
      `/api/content/{app}/${contentEndpoint}`,
    );

    return this.http.get<SquidexContentFlatResponse>(url).pipe(
      take(1),
      map((content: SquidexContentFlatResponse) => content.items[0].data),
      map((data: any) => data),
      catchError((e) => of(e)),
    );
  };

  public getSquidexDocuments = (
    documentType: string,
    searchString: string,
    pageSize: number,
    skipItem?: number,
  ): Observable<any> => {
    const foldersUrl = this.squidexDataService.buildUrl(
      `/api/apps/{app}/assets/folders`,
    );

    return this.http.get<SquidexAssetsFolderResponse>(foldersUrl).pipe(
      map((content: SquidexAssetsFolderResponse) =>
        content.items?.find(
          (folder: SquidexAssetsFolderResponseItem) =>
            folder.folderName === documentType,
        ),
      ),
      mergeMap((folder: SquidexAssetsFolderResponseItem) => {
        const userId = this.authService.loggedInUser?.app_user_data?.id;
        let filters = `$filter=tags eq '${userId}'`;

        if (searchString) {
          const filterByFileName = `contains(fileName,'${searchString}')`;
          const filterByDate = `metadata/effective_date eq '${moment(
            new Date(searchString),
          ).format('YYYY-MM-DD')}'`;
          const filterByYear = `metadata/year eq ${parseInt(searchString, 10)}`;

          filters = `${filters} and (${filterByFileName} or ${filterByYear} or ${filterByDate})`;
        }
        const top = `$top=${pageSize}`;
        const skip = `$skip=${skipItem}`;
        const assetsUrl = this.squidexDataService.buildUrl(
          `/api/apps/{app}/assets/?parentId=${folder?.id}&${filters}&${top}&${skip}`,
        );

        return this.http.get<SquidexAssetsFolderResponse>(assetsUrl);
      }),
      catchError((error) => of(error)),
    );
  };
}
