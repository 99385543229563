import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardConfig } from './card.constants';

@Component({
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  imports: [CommonModule],
  standalone: true,
})
export class CardComponent implements OnInit {
  @Input() config: CardConfig;

  /** @deprecated */
  @Input() hideHeader: boolean;

  /** @deprecated */
  @Input() stretch: boolean;

  /** @deprecated */
  @Input() static: boolean;

  ngOnInit() {
    this.config = new CardConfig(
      Object.assign(
        {
          hideHeader: this.hideHeader,
          stretch: this.stretch,
          static: this.static,
        },
        this.config || {},
      ),
    );
  }
}
