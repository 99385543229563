import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ProgressBarFormattingService } from '../../../../shared/services/progress-bar-formatting.service';
import { PatientDetailService } from '../patient-detail.service';
import {
  AccumulatorTabConfig,
  SectionConfig,
} from '../patientDetailConfig.model';

@Component({
  selector: 'accumulator',
  templateUrl: './accumulators.component.html',
  styleUrls: ['./accumulators.component.scss'],
})
export class AccumulatorsComponent implements OnInit {
  config: AccumulatorTabConfig;
  benefits: any[];
  progressBarsformatted = false;
  formGroup: UntypedFormGroup;

  constructor(
    private patientDetailService: PatientDetailService,
    private progressBarFormat: ProgressBarFormattingService,
  ) {}

  ngOnInit(): void {
    this.benefits = this.patientDetailService.context.benefits;
    this.config = this.patientDetailService?.tabsConfig;
    if (!this.progressBarsformatted) {
      this.formatProgressBar();
    }
    this.createFormControl();
  }

  createFormControl(): void {
    if (this.config) {
      const control = new UntypedFormControl('', []);
      const newFormControl = Object.assign(this.config?.filters, {
        control: control,
      });

      this.formGroup = new UntypedFormGroup({});
      this.formGroup.addControl(newFormControl.prop, control);

      if (this.config.filters.value) {
        this.formGroup
          .get(newFormControl.prop)
          .setValue(this.config?.filters.value);
      }
    }
  }

  formatProgressBar(): void {
    this.config?.sections.map((section: SectionConfig) => {
      if (section.format === 'progressBar' && this.benefits) {
        this.progressBarFormat.sortData(this.benefits);
        section.progressBars = this.progressBarFormat.formatProgressBarConfig(
          section.progressBars,
          this.benefits,
          section.progressBarData,
          section.uniqueSectionKeyValue,
        );
        this.progressBarsformatted = true;
      }
    });
  }
}
