import { Component, Input, OnInit } from '@angular/core';

import linkifyStr from 'linkifyjs/string';

import { Subscription } from 'rxjs';

import { APIService } from '@zipari/web-services';
import { ProviderUpdateToolService } from '../../../provider-update-tool/provider-update-tool.service';

@Component({
  selector: 'flyout-dropdown',
  templateUrl: './flyout-dropdown.component.html',
  styleUrls: ['./flyout-dropdown.component.scss'],
})
export class FlyoutDropdownComponent implements OnInit {
  @Input() config: any;
  @Input() group: any;
  showModal = false;
  selectedItem: any = null;
  busy: Subscription;
  loaded = false;
  noSpace = 'n/a';

  constructor(
    public mp: ProviderUpdateToolService,
    private api: APIService,
  ) {}

  ngOnInit() {
    if (this.config?.subheader) {
      const options = {
        /* … */
      };

      this.config.subheader = linkifyStr(this.config.subheader, options);
    }
    if (this.config?.noSpace) this.noSpace = this.config?.noSpace;
    if (this.config?.flyout?.table?.filters) {
      const filters: any[] = this.config?.flyout?.table?.filters;
      let hasNoEndpoints = true;

      for (const filter of filters) {
        if (filter.type === 'select' && filter.apiEndpoint) {
          hasNoEndpoints = false;
          this.busy = this.api
            .get(filter.apiEndpoint)
            .subscribe((resp: any) => {
              filter.options = this.populateDropdown(
                resp,
                filter.displayProp,
                filter.jsonValue,
              );
              this.loaded = true;
            });
        }
      }
      if (hasNoEndpoints) this.loaded = true;
    }
  }

  populateDropdown(resp: any, labelProp: string, valueProp: string) {
    const options = [];

    resp.results?.forEach(function (record, index) {
      if (valueProp) {
        options.push({
          label: record[labelProp],
          value: record[valueProp],
        });
      } else {
        options.push({
          label: record[labelProp],
          value: record,
        });
      }
    });

    return options;
  }

  toggleFlyout() {
    this.showModal = !this.showModal;
    this.selectedItem = null;
  }

  remove() {
    this.group.get(this.config.prop)?.reset();
  }

  selectedRadioRow(event: any) {
    this.selectedItem = event;
  }

  add() {
    this.group.get(this.config.prop)?.setValue(this.selectedItem);
    this.toggleFlyout();
  }
}
