import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormControlValidatorsService } from '@zipari/design-system';
import { getValue } from '@zipari/web-utils';
import { tap } from 'rxjs';
import { ApiListResponse } from '../../../../../shared/models/shared/ApiListResponse.model';
import { APIService } from '@zipari/web-services';
import {
  deaProp,
  StateDeaContext,
  StateDeaFormGroupArrayProp,
} from '../../practitioners/add-new/manage-practitioner-add.constant';

import { ProviderUpdateToolService } from '../../provider-update-tool.service';
import { SITELOCATION_CONSTANTS } from '../../site-locations-attestation/site-location-attestation.constant';
import {
  EditPractitionerPayload,
  PractitionerDetails,
  ProviderNumber,
  ReviewPractitionersConfig,
  SiteLocationsEditFormConfiguration,
} from '../../site-locations-attestation/site-locations-attestation.model';
import {
  populateDropdown,
  setControlProps,
  PractitionerPayload,
} from './site-locations.helper';

@Injectable({
  providedIn: 'root',
})
export class PractitionersEditService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    public providerUpdateToolService: ProviderUpdateToolService,
    private validatorService: FormControlValidatorsService,
    private apiService: APIService,
  ) {}

  updateEditPractitionerControls(
    config: ReviewPractitionersConfig,
    practitionerDetails: PractitionerDetails,
  ) {
    const practitionerProfileSections = config.editPractitioner?.sections;

    for (const [_, value] of Object.entries(practitionerProfileSections)) {
      if (practitionerDetails.providerNumbers) {
        value.details.controls = value?.details.controls.filter(
          (control: SiteLocationsEditFormConfiguration) =>
            control.prop !== deaProp,
        );
      } else {
        value.additionalDetails.controls =
          value.additionalDetails?.controls.filter(
            (control: SiteLocationsEditFormConfiguration) =>
              control.prop !== StateDeaFormGroupArrayProp,
          );
      }

      value.details.controls?.forEach(
        (control: SiteLocationsEditFormConfiguration) => {
          if (control.apiEndpoint) {
            this.apiService
              .get(control.apiEndpoint)
              .pipe(
                tap((controlDropdownData: ApiListResponse<unknown>) => {
                  control = setControlProps(control, practitionerDetails);
                  if (controlDropdownData) {
                    control.options = populateDropdown(
                      controlDropdownData.results,
                      control.dropdownDisplayProp,
                    );
                  }
                }),
              )
              .subscribe();
          }
          if (
            control.defaultProp ||
            control.defaultStartProp ||
            control.defaultEndProp
          ) {
            control = setControlProps(control, practitionerDetails);
          }
        },
      );
    }
    this.updateEditPractitionerArrayControls(config, practitionerDetails);

    return config;
  }

  updateStateDeaFormContext(details: PractitionerDetails): StateDeaContext[] {
    return details.providerNumbers.map((value: ProviderNumber) => ({
      state: value.stateCode,
      deaGroup: {
        number: value.number,
      },
    }));
  }

  setEditPractitionerPayload(additionalDetailsForm): EditPractitionerPayload {
    const editFormValue = this.providerUpdateToolService.getFormGroup(
      SITELOCATION_CONSTANTS.editPractitionerForm,
    )?.value;

    return editFormValue
      ? PractitionerPayload(editFormValue, additionalDetailsForm)
      : {};
  }

  updateEditPractitionerArrayControls(
    config: ReviewPractitionersConfig,
    practitionerDetails: PractitionerDetails,
  ) {
    const editArrayFields = {};
    const arrayControlContext = {};
    const arrayControlState = {};
    const practitionerProfileSections = config.editPractitioner?.sections;

    for (const [_, value] of Object.entries(practitionerProfileSections)) {
      value.additionalDetails?.controls?.forEach((control) => {
        const group = new UntypedFormGroup({});

        if (control.type === SITELOCATION_CONSTANTS.formGroupArrayControl) {
          editArrayFields[control.prop] = this.formBuilder.array([group]);
          arrayControlContext[control.prop] =
            this.updateStateDeaFormContext(practitionerDetails);
          arrayControlState[control.prop] = false;
        } else {
          const validators = this.validatorService.getFormControlValidators(
            control,
            group,
          );

          editArrayFields[control.prop] = this.formBuilder.array([
            this.formBuilder.group({
              selection: ['', { validators }],
            }),
          ]);
          if (control.defaultProp) {
            arrayControlContext[control.prop] = getValue(
              practitionerDetails,
              control.defaultProp,
            );
            arrayControlState[control.prop] = false;
          }
        }
      });
    }
    this.providerUpdateToolService.setFormGroup(
      SITELOCATION_CONSTANTS.editPractitionerForm,
      this.formBuilder.group(editArrayFields),
    );
    this.providerUpdateToolService.setContextData(
      SITELOCATION_CONSTANTS.editPractitionerForm,
      arrayControlContext,
    );
    this.providerUpdateToolService.setInitState(
      SITELOCATION_CONSTANTS.editPractitionerForm,
      arrayControlState,
    );
  }
}
