import { Injectable } from '@angular/core';
import { ButtonSubnavItem } from '@zipari/shared-ds-util-button';
import { ClaimDetailConfig } from './ClaimDetailConfig.model';
import { CLAIM_APPEAL } from './claim-detail.constant';

@Injectable({
  providedIn: 'root',
})
export class ClaimDetailToggleFeatureService {
  removeClaimAppealsTabConfig(config: ClaimDetailConfig): void {
    delete config?.tabs?.claimAppeals;
  }

  removeClaimAppealsMessageConfig(config: ClaimDetailConfig): void {
    delete config?.existingAppealsModal;
  }

  removeClaimAppealsTabDetailConfig(config: ClaimDetailConfig): void {
    delete config?.claimAppealsTab;
  }

  removeActionButtonConfig(config: ClaimDetailConfig): void {
    const subMenuItems: ButtonSubnavItem[] =
      config?.actionButton?.subnav?.items || [];
    const indexOfReconsideration = subMenuItems.findIndex(
      (claimOption: ButtonSubnavItem) =>
        claimOption.label?.toUpperCase() ===
        CLAIM_APPEAL.RECONSIDERATION.toUpperCase(),
    );

    if (indexOfReconsideration !== -1) {
      subMenuItems.splice(indexOfReconsideration, 1);
    }

    const indexOfDetermination = subMenuItems.findIndex(
      (claimOption: ButtonSubnavItem) =>
        claimOption.label?.toUpperCase() ===
        CLAIM_APPEAL.REDETERMINATION.toUpperCase(),
    );

    if (indexOfDetermination !== -1) {
      subMenuItems.splice(indexOfDetermination, 1);
    }
  }
}
