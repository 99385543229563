/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disablebak @zipari/no-assigning-over-temp-variables-in-list-operators */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormattingService } from '../../../shared/services/formatting.service';
import { getValue } from '../../../shared/utils/get-value';
import { cloneObject, deepCompare } from '../../../shared/utils/object';
import { stringBuilder } from '../../../shared/utils/string-builder';
import {
  max_column_width_default,
  SelectionTypes,
  SetFilterValues,
} from '../components/zip-table.constants';
import { formatTypes } from '@zipari/shared-ds-util-format';
import {
  ColumnConfig,
  ConditionalAction,
  columnFormats,
} from '../models/column.model';
import {
  DiagnosisConfig,
  DiagnosisRowConfig,
  TableConfig,
  TablePagedData,
} from '../models/table.model';
import { Prompt } from './../models/table.model';
import { sortParam } from './table.service.constant';
import { checkInputsForText } from '../../../design-system.helper';

/* eslint-disable no-underscore-dangle */
/* eslint-disable guard-for-in */

const DECIMAL = 10;

@Injectable()
export class TableService {
  tableInit = false;
  zipTableOptions: TableConfig;
  allLocalData;
  public currentPage;
  httpClient: HttpClient;
  internalFilteredDataCount;
  expandedGroups = {};
  tableWrapper;
  queryParamsSub;
  currentQueryParams;
  currentFilters;

  selectedRowSubject = new Subject();
  selectedRadioRowSubject = new Subject();

  prevEndpoint;
  columnProps;
  detailedProps;
  loading = false;
  dataChanged: Subject<any> = new Subject();
  rowSelected: Subject<any> = new Subject();

  queryChanged: Subject<any> = new Subject();
  filtersChanged: Subject<object[]> = new Subject();
  prompt: Prompt;
  showPrompt = false;
  apiErrors;
  public currentTableData: Record<string, unknown>;
  public selectedTableRow = [];
  preFillRadio: DiagnosisRowConfig;

  public setTableFilterValues$: BehaviorSubject<SetFilterValues> =
    new BehaviorSubject(null);
  setFilterValues$ = this.setTableFilterValues$.asObservable();

  constructor(
    public route: ActivatedRoute,
    public http: HttpClient,
    public formattingService: FormattingService,
  ) {
    this.httpClient = http;
    this.queryParamsSub = this.route.queryParams.subscribe((params) => {
      this.currentQueryParams = params;
      this.filterData();
    });
  }

  public get currentData() {
    return this.currentTableData;
  }

  public set currentData(data: any) {
    this.currentTableData = data;
    this.dataChanged.next(data);
  }

  public get selectedRow() {
    return this.selectedTableRow;
  }

  public set selectedRow(selectedRow: any) {
    this.selectedTableRow = selectedRow;
    this.selectedRowSubject.next(selectedRow);
  }

  public get groupingEnabled() {
    return getValue(this.zipTableOptions, 'grouping.enabled');
  }

  public get detailEnabled() {
    return getValue(this.zipTableOptions, 'detail.enabled');
  }

  public get groupedData() {
    const finalGroupedData = [];
    const tempGroupedData = {};

    // get the configuration for grouping
    const groupConfig = getValue(this.zipTableOptions, 'grouping');

    if (this.currentData) {
      // group together the current data keyed by the prop
      // do the rollup of the value specified as long as the value they gave is relevant
      this.currentData.forEach((row) => {
        const groupVal = getValue(row, groupConfig.prop);

        if (!tempGroupedData[groupVal]) {
          tempGroupedData[groupVal] = {
            rows: [],
            value: groupVal,
            rollups: {},
          };
        }

        if (groupConfig.rollup) {
          for (const rollupKey in groupConfig.rollup) {
            if (!rollupKey) {
              continue;
            }
            if (
              tempGroupedData[groupVal]['rollups'][rollupKey] === null ||
              tempGroupedData[groupVal]['rollups'][rollupKey] === undefined
            ) {
              tempGroupedData[groupVal]['rollups'][rollupKey] = 0;
            }
            try {
              let num;

              if (typeof getValue(row, rollupKey) === 'number') {
                num = getValue(row, rollupKey);
              } else {
                num = Number.parseFloat(getValue(row, rollupKey));
              }
              tempGroupedData[groupVal]['rollups'][rollupKey] += num;
            } catch (err) {
              console.error(err);
            }
          }
        }

        tempGroupedData[groupVal].rows.push(row);
      });

      for (const key in tempGroupedData) {
        if (!key) {
          continue;
        }
        const groupHeaderRow: any = {};

        this.zipTableOptions.columns.forEach((column) => {
          let currVal = '';

          if (column.prop === groupConfig.prop) {
            currVal = tempGroupedData[tempGroupedData[key].value].value;
          } else if (groupConfig.rollup[column.prop]) {
            currVal =
              tempGroupedData[tempGroupedData[key].value]['rollups'][
                column.prop
              ];
          }
          groupHeaderRow[column.prop] = currVal;
        });

        const finalGroupObject = {
          groupRow: groupHeaderRow,
          display: tempGroupedData[key].value,
          rollup: tempGroupedData[key].rollup,
          rows: tempGroupedData[key].rows,
        };

        const groupDirection = getValue(this.zipTableOptions, 'grouping.sort');

        if (groupDirection === 'asc') {
          finalGroupedData.push(finalGroupObject);
        } else if (groupDirection === 'desc') {
          finalGroupedData.unshift(finalGroupObject);
        }
      }
    }

    return finalGroupedData;
  }

  public get getCurrentPageCount() {
    if (
      this.zipTableOptions.endpoint ||
      (this.zipTableOptions.paging.count && this.zipTableOptions.paginatedData)
    ) {
      return (
        this.zipTableOptions.paging.count ||
        (this.allLocalData && this.allLocalData.length)
      );
    } else {
      return (
        this.internalFilteredDataCount ||
        (this.allLocalData && this.allLocalData.length)
      );
    }
  }

  public get allowedColumns() {
    if (this.tableInit) {
      const width = getValue(this.tableWrapper, 'nativeElement.clientWidth');

      const maxWidthPerColumn =
        this.zipTableOptions.maxColumnWidth || max_column_width_default;
      const howManyColumns = this.zipTableOptions.columns.length;

      if (width > maxWidthPerColumn * howManyColumns) {
        return howManyColumns > this.zipTableOptions.detail.minColumns
          ? howManyColumns
          : this.zipTableOptions.detail.minColumns;
      } else {
        const calculatedRows = Math.floor(width / maxWidthPerColumn);

        return calculatedRows > this.zipTableOptions.detail.minColumns
          ? calculatedRows
          : this.zipTableOptions.detail.minColumns;
      }
    }
  }

  public get allRowsExpanded() {
    let rows;
    let groups;

    if (this.currentData) {
      const expandedRows = this.currentData.filter((row) => row.expanded);

      rows = expandedRows.length === this.currentData.length;
    } else {
      return null;
    }

    if (this.groupingEnabled) {
      groups =
        this.groupedData.length === Object.keys(this.expandedGroups).length;
    } else {
      groups = true;
    }

    return rows && groups;
  }

  public get detailColumns() {
    this.zipTableOptions.columns = this.zipTableOptions.columns.filter(
      (column) => !column.isHidden,
    );
    let columns: ColumnConfig[] = this.detailEnabled
      ? this.handleColumnPriority(
          this.zipTableOptions.columns,
          'detailed',
          this.allowedColumns,
        )
      : [];

    if (this.groupingEnabled) {
      const groupProp = this.zipTableOptions.grouping.prop;

      if (
        (this.zipTableOptions.grouping.showInDetail &&
          this.detailedProps &&
          !this.detailedProps[groupProp]) ||
        (!this.zipTableOptions.grouping.showInDetail &&
          this.detailedProps &&
          this.detailedProps[this.zipTableOptions.grouping.prop])
      ) {
        columns = columns.filter(
          (column: ColumnConfig) => column.prop !== groupProp,
        );
      }
    }

    return columns;
  }

  public get columns() {
    this.zipTableOptions.columns = this.zipTableOptions.columns.filter(
      (column) => !column.isHidden,
    );
    let columns: ColumnConfig[] = this.detailEnabled
      ? this.handleColumnPriority(
          this.zipTableOptions.columns,
          'columns',
          this.allowedColumns,
        )
      : this.zipTableOptions.columns;

    if (this.groupingEnabled && this.zipTableOptions.grouping.showInDetail) {
      const groupProp = this.zipTableOptions.grouping.prop;

      if (this.columnProps && this.columnProps[groupProp]) {
        const groupedCol = this.zipTableOptions.columns.filter(
          (column: ColumnConfig) => column.prop === groupProp,
        );

        columns = columns.filter(
          (column: ColumnConfig) => column.prop !== groupProp,
        );

        return [].concat(groupedCol, columns);
      }
    }

    return columns;
  }

  public get dataExists() {
    return (
      (this.currentData && this.currentData.length > 0) ||
      (this.groupingEnabled && this.groupedData.length > 0)
    );
  }

  public get showFilters() {
    if (!this.zipTableOptions) {
      return null;
    }

    return (
      this.zipTableOptions.nonQueryFilters &&
      this.zipTableOptions.nonQueryFilters.length > 0
    );
  }

  public get showExport() {
    return this.zipTableOptions?.exportConfig?.enabled || null;
  }

  public get showButton() {
    if (!this.zipTableOptions) {
      return null;
    }

    return (
      getValue(this.zipTableOptions, 'button.enabled') ||
      getValue(this.zipTableOptions, 'button.content') ||
      getValue(this.zipTableOptions, 'button.icon')
    );
  }

  public get allRowsAreSelected() {
    // since 10 items can be selected on a different page, we must iterate to check
    const checker = (arr, target) =>
      (target || []).every((v) => arr.includes(v));

    return checker(
      this.selectedRow.map((item) => item.row),
      this.currentData,
    );
  }

  /** basic call to retrieve initial data or update the current data based on what was passed into the table
   *  once we take care of keeping the data if its local... handle getting the data utilizing 'setData'
   * @param data - if relevant, the data that was provided to the zip-table
   * */
  retrieveData(init, data?) {
    if (!this.zipTableOptions?.endpoint && data) {
      this.allLocalData = data;
    }

    if (!this.zipTableOptions?.paginatedData) {
      this.filterData();
    }

    this.setData();
  }

  refreshExternalData() {
    this.setData(1, true);
  }

  toggleExpandingGrouping(group) {
    if (!group) {
      return;
    }

    if (this.expandedGroups[group.display]) {
      delete this.expandedGroups[group.display];
    } else {
      this.expandedGroups[group.display] = true;
    }
  }

  public expandRow(ind) {
    this.currentData[ind].expanded = !this.currentData[ind].expanded;

    return this.allRowsExpanded;
  }

  /** Normal functionality is toggling the expanded value unless a specific value is passed in */
  handleExpandingAllRows(specificVal?) {
    // we have to store the result of the getter so that once we do the mapping to one of the items
    // the getter doesnt get called again and give a different value
    const rowsExpanded = this.allRowsExpanded;

    // handle passing in a specific value
    const shouldExpand =
      specificVal === false || specificVal ? specificVal : !rowsExpanded;

    // important that we don't use a map here because we need to make sure we preserve the object equality
    // if we were to make a map, the two objects would then be different and
    // then we wouldn't be able to know the same object is still selected
    this.currentData.forEach((row, ind) => {
      this.currentData[ind].expanded = shouldExpand;
    });

    if (this.groupingEnabled) {
      this.groupedData.forEach((item) => {
        if (shouldExpand) {
          this.expandedGroups[item.display] = true;
        } else {
          delete this.expandedGroups[item.display];
        }
      });
    }

    // once were done doing the expanding, we have to make sure we call the getter so that the value updates itself
    // everywhere
    this.allRowsExpanded;
  }

  /** Handles converting the raw options provided to the table into the full options.
   * This includes defaulting any of the values not provided in the configuration to the component.
   * @param options - raw options provided to the table
   * */
  setupOptions(options) {
    if (options) {
      this.zipTableOptions = new TableConfig(options);
      this.currentQueryParams = this.route.snapshot.queryParams;
    }

    // LOOK AWAY:
    // WE HAVE TO STOP A CHANGE DETECTION PROBLEM AND THIS IS NEEDED
    setTimeout(() => {
      this.tableInit = true;
    }, 0);
  }

  /** helper function to handle setting the current page
   * @param page - if relevant, the new page to go to
   * */
  setPage(page?: TablePagedData) {
    Object.assign(this.zipTableOptions.paging, page || {});

    const queryObject = this.buildQueryParamsObject(
      Object.assign({}, this.zipTableOptions.paging, page || {}),
      this.zipTableOptions.filterValues,
      this.zipTableOptions.sorts,
    );

    this.emitQueryData(queryObject);

    this.setData(page);
  }

  /** helper function to handle setting the sorting values
   * @param column {ColumnConfig} - which column to sort on
   * @param order - which direction to sort the provided column by
   * */
  sortData(column: ColumnConfig, order: 'asc' | 'desc') {
    this.zipTableOptions.sorts = {};
    this.zipTableOptions.sorts[column.sortByProp || column.prop] = order;
    if (this.zipTableOptions.setToFirstPageOnSort) {
      this.refreshExternalData();
      this.zipTableOptions.paging.pageIndex = 0;
    } else {
      this.setData();
    }
  }

  /** helper function to handle setting the filter values
   * @param filters - the filter object... if a user has put a value into the filter there will be a value prop on
   *     the individual filter
   * */
  filterData(filters?) {
    if (filters) {
      this.currentFilters = filters;
      this.filtersChanged.next(filters);
    }
    if (this.zipTableOptions) {
      this.zipTableOptions.paging.pageIndex = 0;
      this.zipTableOptions.filterValues = {};
      this.zipTableOptions.replaceValues = {};
      if (this.currentFilters) {
        this.currentFilters.forEach((filter) => {
          if (
            typeof filter.value !== 'string' ||
            (typeof filter.value === 'string' && filter.value !== '')
          ) {
            if (filter.replaceValue) {
              if (filter.value === null || filter.value === 'null') {
                filter.value = filter.nullReplace;
              }
              this.zipTableOptions.replaceValues[filter.prop] = filter.value;
            } else {
              this.zipTableOptions.filterValues[filter.prop] = filter.value;
            }
          }
        });
      }

      if (
        this.zipTableOptions.queryFilters &&
        this.zipTableOptions.queryFilters.length > 0
      ) {
        this.zipTableOptions.queryFilters.forEach((filter) => {
          const obj = {};

          obj[filter.prop] = this.currentQueryParams[filter.prop];
          if (
            this.currentQueryParams[filter.prop] !== null &&
            this.currentQueryParams[filter.prop] !== undefined
          ) {
            Object.assign(this.zipTableOptions.filterValues, obj);
          }
        });
      }
      this.setData();
    }
  }

  /** helper function to handle whether or not to handle the data by using the endpoint or by using custom filter/sort functionality
   * @param page - if relevant, provide a new page to be used further down
   * */
  setData(page?, breakCache = false) {
    // this means that we are calling things for the first time so see whether or not the table needs to do some
    // initial adjustments before the first call
    if (!this.currentData || !this.tableInit) {
      const sortConfig = getValue(this.zipTableOptions, 'sorting.init');
      const filterConfig = getValue(this.zipTableOptions, 'filters.init');

      if (filterConfig) {
        this.zipTableOptions.filterValues = filterConfig;
      }
      if (sortConfig) {
        this.zipTableOptions.sorts = sortConfig;
      }
    }
    // if we have an endpoint then get the data externally, otherwise handle everything internally
    if (this.zipTableOptions) {
      if (this.zipTableOptions.endpoint) {
        this.getExternalData(page, breakCache);
      } else {
        this.getInternalData(page);
      }
    }
  }

  /** handle getting internal data including filtering, sorting, and then paging
   * @param pageChanges - if relevant, provide a new page to be used
   * */
  getInternalData(pageChanges?) {
    let newObj = cloneObject(this.allLocalData || []);
    const [checkedObj] = checkInputsForText([newObj]);

    newObj = checkedObj;
    let isThisDataInit;

    if (!this.currentData) {
      isThisDataInit = true;
    }

    // filter all of the data for relevant filters
    newObj = this.filterInternal(newObj || []);

    // sort all of the data for relevant sorting
    newObj = this.sortInternal(newObj || []);

    // update the page data based on the new page
    this.zipTableOptions.paging = Object.assign(
      {},
      this.zipTableOptions.paging,
      pageChanges || {},
    );

    const firstIndex = this.zipTableOptions.paging.pageSize
      ? this.zipTableOptions.paging.pageIndex *
        this.zipTableOptions.paging.pageSize
      : 0;
    const secondIndex =
      this.zipTableOptions.paging.pageSize || this.allLocalData.length;

    if (this.zipTableOptions.paginatedData) {
      this.currentData = cloneObject(newObj || []).map((row, ind) => {
        row['index'] = ind;

        return row;
      });
    } else {
      // create the data that should be displayed for this page
      this.currentData = cloneObject(newObj || [])
        .splice(firstIndex, secondIndex)
        .map((row, ind) => {
          row['index'] = ind;

          return row;
        });
    }

    if (isThisDataInit) {
      this.checkIfTableShouldBeExpandedOnOpen();
      this.checkIfTableShouldPreselectAResult();
    }
    // LOOK AWAY:
    // WE HAVE TO STOP A CHANGE DETECTION PROBLEM AND THIS IS NEEDED
    setTimeout(() => {
      this.selectedRowSubject.next(void 0);
    });
    setTimeout(() => {
      this.selectedRadioRowSubject.next(void 0);
    });
  }

  /** handle getting external data. Setup the url properly including filtering, sorting, and then paging.
   * @param pageChanges - if relevant, provide a new page to be used
   * */
  getExternalData(pageChanges?, breakCache = false): any {
    // build the query string based on all of the available data
    const queryObject = this.buildQueryParamsObject(
      Object.assign({}, this.zipTableOptions.paging, pageChanges || {}),
      this.zipTableOptions.filterValues,
      this.zipTableOptions.sorts,
    );

    let queryString = this.zipTableOptions.endpoint;

    queryString = this.buildEndpointWithReplacements(
      queryString,
      this.zipTableOptions.replaceValues || {},
    );
    queryString += queryString.indexOf('?') > -1 ? '&' : '?';
    queryString += this.buildQueryParamsString(queryObject);

    // attempts to stop the zip table from recalling the same endpoint multiple times without a change in the url
    // this is like a debouncer for the zip table
    let makeCall = true;

    if (this.zipTableOptions.endpointCache) {
      if (this.prevEndpoint === queryString && !breakCache) {
        makeCall = false;
      }
      this.prevEndpoint = queryString;
    }
    if (makeCall) {
      // set the table as busy and then get data from the external source
      this.loading = true;
      const restMethod = this.zipTableOptions.restMethod
        ? this.zipTableOptions.restMethod
        : 'get';
      const requestBody = this.zipTableOptions.requestBody
        ? this.zipTableOptions.requestBody
        : {};

      this.httpClient[restMethod]<any>(queryString, requestBody)
        .pipe(
          map((data) => new TablePagedData(data, this.zipTableOptions.paging)),
        )
        .subscribe(
          (pagedData: TablePagedData) => {
            this.zipTableOptions.paging.count = pagedData.page.count;
            if (this.zipTableOptions.endpoint) {
              this.zipTableOptions.exportConfig.exportLink =
                this.zipTableOptions.exportConfig.exportLink ||
                `${queryString}&format=csv`;
            }

            let isThisDataInit;

            if (!this.currentData) {
              isThisDataInit = true;
            }
            this.currentData = (pagedData.data || []).map((row, ind) => {
              row['index'] = ind;

              return row;
            });
            if (isThisDataInit) {
              this.checkIfTableShouldPreselectAResult();
              this.checkIfTableShouldBeExpandedOnOpen();
            }
            this.loading = false;
          },
          (error: any) => {
            console.error(error);
            this.apiErrors = error.error?.errors;
            if (this.zipTableOptions.clearDataOnError) {
              this.currentData = [];
              this.zipTableOptions.paging.count = 0;
            }
            this.loading = false;
          },
        );
    }
  }

  emitQueryData(queryObject) {
    this.queryChanged.next(queryObject);
  }

  // makes sure that columns with greater priority get kept during a resizing of a table

  /** In cases where we can't do a local export because there are too many items, then add the async flag and handle the export */
  getAsyncExport(): any {
    const restMethod = this.zipTableOptions.exportConfig.restMethod || 'get';
    const queryParams =
      this.zipTableOptions.exportConfig['queryParams'] ?? '&async=true';
    const endpoint = `${this.zipTableOptions.exportConfig.exportLink}${queryParams}`;
    const body = this.zipTableOptions.exportConfig['requestBody']
      ? this.zipTableOptions.exportConfig.requestBody
      : {};
    const formatResponse = this.zipTableOptions.exportConfig['formatResponse']
      ? this.zipTableOptions.exportConfig.formatResponse
      : false;
    const options = this.zipTableOptions.exportConfig['options']
      ? this.zipTableOptions.exportConfig.options
      : {};

    if (options && options.headers) {
      const headersFormatted = new HttpHeaders();
      const keys = Object.keys(options.headers);

      keys.forEach((key) => {
        headersFormatted.set(key, options.headers[key]);
      });
      options.headers = headersFormatted;
    }

    return this.httpClient[restMethod]<any>(endpoint, body, options).subscribe(
      (res) => {
        const contentHeader =
          res.headers && res.headers.get('content-disposition');
        const contentHeaderStr = contentHeader ? contentHeader.toString() : '';
        const query = 'filename=';
        const index = contentHeaderStr.indexOf(query);
        const filename =
          index !== -1
            ? contentHeaderStr.slice(index + query.length).replace(/"/g, '')
            : null;

        if (formatResponse) {
          const formatted = this.formatCSVResponse(res);

          this.downloadCSV(formatted, filename);
        }
      },
    );
  }

  formatCSVResponse(data) {
    // Replace quote/space/quote with line breaks, split, format header row without quotes, rejoin with \r\n
    const csvStr = data.toString();
    const arr = csvStr.replace(/" "/g, '"\n"').split('\n');

    arr[0] = arr[0].replace(/"/g, '');

    return arr.join('\r\n');
  }

  /** handle exporting local data */
  getSynchronousExport() {
    // If exporting with UI formatting, then use local CSV creation in order to restructure value
    const exportWithFormatting = getValue(
      this.zipTableOptions,
      'exportConfig.exportWithFormatting',
    );
    const callApi =
      !exportWithFormatting &&
      (this.zipTableOptions.endpoint ||
        (getValue(this, 'zipTableOptions.exportConfig.exportLink') &&
          !this.zipTableOptions.exportConfig.useLocalCsvDownload));

    if (callApi) {
      window.location.assign(this.zipTableOptions.exportConfig.exportLink);
    } else {
      // go through and loop through all of the data and create the csv string
      let csv = this.currentData.map((row) => {
        // clean each row by turning it into a string and mapping null values to an empty string
        const cleanedUpRow = this.zipTableOptions.columns.map((item) => {
          // use getValue to handle potentially nested props, eg: client.broker
          const tempVal = getValue(row, item.prop);
          // if the original format config was an array of formats, reinstate it to format the value

          if (item['originalFormat']) {
            item.format = item['originalFormat'];
          }

          let formattedVal;

          if (item.prop === 'status' || item.prop === item.statusProp) {
            formattedVal = this.formattingService.restructureValueBasedOnFormat(
              tempVal,
              { format: 'SNAKE_CASE_TO_TITLE' },
            );
          } else {
            formattedVal = item.format
              ? this.formattingService.restructureValueBasedOnFormat(
                  tempVal,
                  item,
                )
              : tempVal;
          }
          // if value is array then format with line breaks so the commas don't break the CSV
          if (Array.isArray(formattedVal)) {
            formattedVal = `"${formattedVal.join().replace(',', '\n')}"`;
          }

          // only stringify objects
          if (typeof formattedVal === 'object' && formattedVal !== null) {
            formattedVal = JSON.stringify(formattedVal, (key, value) =>
              value === null ? '' : value,
            );
          }

          return `${formattedVal}`;
        });

        return cleanedUpRow.join(',');
      });

      // add the header row by parsing through the columns
      csv.unshift(
        this.zipTableOptions.columns
          .map((item, ind) => {
            // extra logic to change an id field to "identifier"
            // because excel has some weird logic that makes it think its a different type of file
            if (ind === 0 && item.name.toLowerCase() === 'id') {
              return ` ${item.name}`;
            } else {
              // send back the name as the column name...
              // if a name wasnt provided then send the prop
              return item.name || item.prop;
            }
          })
          .join(','),
      );

      // just formatting logic for the csv itself
      csv = csv.join('\r\n');

      this.downloadCSV(csv, null);
    }
  }

  downloadCSV(data, filename) {
    if (!filename) {
      // setup a unique file name
      const date = new Date();
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth() + 1;
      const day = date.getUTCDate();
      const hours = date.getUTCHours();
      const min = date.getUTCMinutes();
      const sec = date.getUTCSeconds();

      filename = `${year}-${month}-${day}_${hours}-${min}-${sec}.csv`;
    }

    // convert the csv string we created into a blog and  give it the correct content type to be used  during
    // download
    const blob = new Blob([data], { type: 'text/csv;charset=UTF-8;' });

    // if the msSaveBlob function is there then use that
    if ('msSaveBlob' in navigator) {
      // IE 10+
      const saveBlobFunction = navigator.msSaveBlob as (
        blob: Blob,
        fileName: string,
      ) => null;

      saveBlobFunction(blob, filename);
    } else {
      // otherwise try to download the blob with the anchor tag's download attribute
      const link = document.createElement('a');

      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        // if that doesn't exist then just assign the current window to the blob so that it forces a
        // download. this is the safest way to do it cross browser
        window.location.assign(URL.createObjectURL(blob));
      }
    }
  }

  /** handle creation of the query string from queryObject returned from buildQueryParamsObject */
  public buildQueryParamsString(queryObject) {
    let paramString = '';

    for (const queryKey in queryObject) {
      if (typeof queryObject[queryKey] === 'object') {
        for (const innerKey in queryObject[queryKey]) {
          paramString += `${queryKey}_${innerKey}=${queryObject[queryKey][innerKey]}&`;
        }
      } else {
        paramString += `${queryKey}=${queryObject[queryKey]}&`;
      }
    }

    return paramString.slice(0, -1);
  }

  handleParamsPageSize(params, configuredPageSize) {
    const updatedParams = { ...params };

    if (configuredPageSize) {
      updatedParams['page_size'] = this.zipTableOptions.paging.pageSize;
    }

    return updatedParams;
  }

  handleParamsPageSort(params, sorts: object) {
    const updatedParams = { ...params };

    if (sorts && Object.keys(sorts).length > 0) {
      let sortString = '';

      for (const sort in sorts) {
        if (sortParam in Object.keys(sorts) && sorts[sort] !== null) {
          sortString += sorts[sort] === 'asc' ? '' : '-';
          sortString += sort;
        }
      }
      updatedParams['ordering'] = sortString;
    }

    return updatedParams;
  }

  handleParamsPageFilters(params, filters) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length > 0) {
      // Adding filter params
      for (const filter in filters) {
        updatedParams[filter] = filters[filter];
      }
    }

    return updatedParams;
  }

  /** handle creation of the query params object based on the current page, filters, and sorting
   * @param page - representation of the current page that the table is on
   * @param filters - current filters keyed by prop name with the value of the filter
   * @param sorts - current sorts keyed by prop name with the value being the direction of the sort
   */
  public buildQueryParamsObject(page, filters: any, sorts: any) {
    const paramObject = {};
    // Adding pagination params
    const djangoPageNumber = (this.zipTableOptions.paging.pageIndex || 0) + 1;

    paramObject['page'] = djangoPageNumber;
    const paramObjectWithPageSize = this.handleParamsPageSize(
      paramObject,
      this.zipTableOptions.paging.pageSize,
    );
    const paramObjectWithSort = this.handleParamsPageSort(
      paramObjectWithPageSize,
      sorts,
    );
    const paramObjectWithFilters = this.handleParamsPageFilters(
      paramObjectWithSort,
      filters,
    );

    return paramObjectWithFilters;
  }

  public buildEndpointWithReplacements(queryString: string, replacements: any) {
    if (!(Object.keys(replacements).length === 0)) {
      const pattern: any = /[^${}]*(?=\})/g;
      const toReplace: string[] = queryString.match(pattern);

      toReplace.forEach((item) => {
        if (item.length > 0) {
          queryString = queryString.replace(
            new RegExp(`\\\${${item}}`, 'gi'),
            replacements[item],
          );
        }
      });
    }

    return queryString;
  }

  /** utility function for the table to be able to render a columns value including any pipes that should be used for formatting */
  public getValueIncludingFormat(data, column) {
    // set up relevant variables
    let value;

    // if a text is provided then just use the text as the value
    // otherwise find the value based on the prop provided..
    // then use the pipe to get the new value
    // also do a second pipe if relevant
    if (column.format && column.format === columnFormats.STATUS) {
      const dataFromContext = getValue(data, column.mockProp || column.prop);

      value = {
        label: this.formattingService.restructureValueBasedOnFormat(
          dataFromContext,
          { format: 'SNAKE_CASE_TO_TITLE' },
        ),
        value: dataFromContext,
        status: 'expired',
      };

      Object.keys(column.statuses).forEach((status) => {
        const arr = column.statuses[status];

        column.statuses[status].forEach((obj) => {
          if (obj['value'] === dataFromContext) {
            value = {
              ...obj,
              status,
            };
          }
        });
      });
    } else if (column.format) {
      const dataFromContext = getValue(data, column.mockProp || column.prop);

      // allows for cascading formats in an array, i.e. ['CURRENCY', 'LINK'] to produce a link formatted as currency
      if (Array.isArray(column.format)) {
        const originalFormat = column.format;

        value = dataFromContext;
        column.format.forEach((f) => {
          column.format = f;
          value = this.formattingService.restructureValueBasedOnFormat(
            value,
            column,
          );
        });

        column.format = originalFormat;
      } else {
        // multi address arrays probably shouldn't be handled like this and we prob have a way to solve this with config, but it has
        // been added so we must live with it now since we don't know where it could be used now...
        // buuuuut if the format type is not a list and the object is an array of objs with addresses, format the addresses into a string
        let formattedMultiAddresses: string;

        if (
          column.format !== formatTypes.LIST &&
          Array.isArray(dataFromContext)
        ) {
          formattedMultiAddresses = dataFromContext
            .filter((initialValue: any) => initialValue.address)
            .reduce(
              (previousValue: string, currentValue: any) =>
                (previousValue = `${previousValue} ${this.formattingService.restructureValueBasedOnFormat(
                  currentValue.address,
                  column,
                )}<br/><br/>`),
              '',
            );
        }

        // set the val to the formatted address string, fallback to reg formatting service val formatting
        value =
          formattedMultiAddresses ||
          this.formattingService.restructureValueBasedOnFormat(
            dataFromContext,
            column,
          );
      }

      value = this.formattingService.handleFallbackProps(
        column,
        value,
        (currentFallbackProp) => {
          const dataFromContextFromFallback: any = getValue(
            data,
            currentFallbackProp,
          );

          return this.formattingService.restructureValueBasedOnFormat(
            dataFromContextFromFallback,
            column,
          );
        },
      );
    } else if (column.text) {
      value = column.text;
    } else {
      value = getValue(data, column.mockProp || column.prop);
      if (column.maxCharacterLimit) {
        value =
          value.length > column.maxCharacterLimit
            ? `${value.substring(0, column.maxCharacterLimit)}...`
            : value;
      }
      value = this.formattingService.handleFallbackProps(
        column,
        value,
        (currentFallbackProp) => getValue(data, currentFallbackProp),
      );
    }

    if (column.mockLabel) {
      value = stringBuilder(column.mockLabel, { value: value });
    }

    if (!value) {
      value =
        column?.format === 'LIST'
          ? [column.noValueMessage]
          : column.noValueMessage;
    }

    return value;
  }

  checkIfRowSelected(row) {
    return this.selectedRow.find((selectedRow, ind) =>
      deepCompare(selectedRow.row, row, { expanded: true }),
    );
  }

  findSelectedRowIndex(row) {
    return this.selectedRow.findIndex((selectedRow, ind) =>
      deepCompare(selectedRow.row, row, { expanded: true }),
    );
  }

  unselectAllRows() {
    // if unselecting by page, we have to selectively unclear
    if (this.zipTableOptions?.selection.limitSelectAllToPage) {
      (this.currentData || []).forEach((row, ind) => {
        this.selectedRow = this.selectedRow.filter(
          (item) => item.row.id !== row.id,
        );
      });
    } else {
      this.selectedRow = [];
    }
  }

  markAllRowsAsSelected() {
    // skip clearing of selections if we're loading by page
    if (!this.zipTableOptions.selection.limitSelectAllToPage) {
      this.selectedRow = [];
    }

    (this.currentData || []).forEach((row, ind) => {
      // if adding by page, only add if it isn't already selected
      if (
        !(
          this.zipTableOptions.selection.limitSelectAllToPage &&
          this.selectedRow.find((item) => item.row.id === row.id)
        )
      ) {
        this.selectedRow = [...this.selectedRow, ...[{ row, ind }]];
      }
    });
    this.selectedRowSubject.next(void 0);
  }

  markRowsAsPreSelected(data: DiagnosisConfig[]) {
    if (Array.isArray(data)) {
      data.forEach((obj) => {
        if ('is_primary' in obj.row && obj.row.is_primary) {
          this.preFillRadio = obj.row;
        }
      });

      data.forEach((obj, ind) => {
        const row = obj.row;

        if (!this.selectedRow.find((item) => item.row.id === row.id)) {
          this.selectedRow = [...this.selectedRow, ...[{ row, ind }]];
        }
      });
      this.selectedRowSubject.next(void 0);
    }
  }

  handleRowSelection(row, ind = null) {
    if (
      this.zipTableOptions.selection.enabled &&
      !this.zipTableOptions.selection.multiple
    ) {
      if (this.selectedRow && this.checkIfRowSelected(row)) {
        this.selectedRow.splice(this.findSelectedRowIndex(row), 1);

        return SelectionTypes.deselected;
      } else {
        this.selectedRow.push({ row, ind });

        return SelectionTypes.selected;
      }
    } else if (
      this.zipTableOptions.selection.enabled &&
      this.zipTableOptions.selection.multiple
    ) {
      if (this.selectedRow && this.checkIfRowSelected(row)) {
        this.selectedRow = [];

        return SelectionTypes.deselected;
      } else {
        this.selectedRow.push({ row, ind });

        return SelectionTypes.selected;
      }
    }

    this.selectedRowSubject.next(void 0);
  }

  public setFilters(setFilters: SetFilterValues) {
    this.setTableFilterValues$.next(setFilters);
  }

  private checkIfTableShouldPreselectAResult() {
    const selectionConfig = getValue(this.zipTableOptions, 'selection.init');

    if (selectionConfig) {
      const keys = Object.keys(selectionConfig);

      // check whether all of the criteria to look for are already in the url
      if (
        keys.filter((key) => this.route.snapshot.queryParams[key]).length > 0
      ) {
        // check to see which pieces of data meet the selection criteria
        let firstInd;
        let firstData;

        // loop through all the current data and try to find pieces of data that match to be selectable...
        // and select the first one that we find
        for (let i = 0; i < this.currentData.length; i++) {
          const data = this.currentData[i];
          const selectable =
            keys.filter((key) => {
              const initVal = getValue(data, key);
              const value =
                typeof initVal === 'number' ? initVal.toString() : initVal;
              const whetherOrNotThisDataHasExpectedQueryPropValues =
                value === this.route.snapshot.queryParams[key];

              if (whetherOrNotThisDataHasExpectedQueryPropValues) {
                firstInd = i;
                firstData = data;
              }

              return whetherOrNotThisDataHasExpectedQueryPropValues;
            }).length === keys.length;

          if (selectable) {
            break;
          }
        }

        // since we have all of the data set this item as selecteds
        if (firstData) {
          const selectedData = { row: firstData, ind: firstInd };

          this.rowSelected.next(selectedData);
        }
      }
    }
  }

  private checkIfTableShouldBeExpandedOnOpen() {
    const startOpen = getValue(this.zipTableOptions, 'detail.init.startOpen');

    if (typeof startOpen === 'boolean') {
      this.handleExpandingAllRows(startOpen);
    } else if (typeof startOpen === 'number') {
      if (this.groupingEnabled) {
        this.toggleExpandingGrouping(this.groupedData[startOpen]);
      } else {
        this.expandRow(startOpen);
      }
    }
  }

  /** local function to handle all of the filtering for when there is local data being passed in
   * @param data - the data to filter
   */
  private filterInternal(data) {
    if (this.zipTableOptions.filterValues && this.zipTableOptions.filters) {
      const keys = Object.keys(this.zipTableOptions.filterValues);

      if (keys.length > 0) {
        data = data.filter((indData) => {
          let shouldKeep = false;

          // using a for loop here so that we can break out if we have a hit that doesn't match
          for (let i = 0; i < keys.length; i++) {
            let numberSuccess = false;
            const key = keys[i];
            let val = '';
            let filterVal = '';

            if (
              this.zipTableOptions.nonQueryFilters[i].isSearchCaseInSensitive
            ) {
              val = getValue(indData, key).toLowerCase();
              filterVal = this.zipTableOptions.filterValues[key].toLowerCase();
            } else {
              val = getValue(indData, key);
              filterVal = this.zipTableOptions.filterValues[key];
            }
            // attempt to parse the string into a number
            let numberVersionOfStr;

            try {
              let numberVersionOfVal;

              if (typeof val === 'string') {
                numberVersionOfVal = Number.parseInt(val, DECIMAL);
              } else {
                numberVersionOfVal = val;
              }

              numberVersionOfStr = Number.parseInt(filterVal, DECIMAL);
              if (numberVersionOfVal === numberVersionOfStr) {
                shouldKeep = true;
                numberSuccess = true;
              }
            } catch (err) {
              console.error(err);
            }

            if (!numberSuccess) {
              if (
                (typeof val === 'string' && val.indexOf(filterVal) >= 0) ||
                (typeof val === 'number' && val === filterVal)
              ) {
                shouldKeep = true;
              } else {
                shouldKeep = false;
                break;
              }
            }
          }

          return shouldKeep;
        });
      }
    }

    this.internalFilteredDataCount = data.length;

    return data;
  }

  /** local function to handle all of the sorting for when there is local data being passed in
   * @param data - the data to sort
   */
  private sortInternal(data) {
    if (this.zipTableOptions.sorts) {
      const sortKeys = Object.keys(this.zipTableOptions.sorts);

      data.sort((a, b) => {
        const key = sortKeys[0];
        const valueA = getValue(a, key);
        const valueB = getValue(b, key);

        if (
          (!valueA || !valueB) &&
          (typeof valueA === 'string' || typeof valueB === 'string')
        ) {
          // property doesn't exist on either object
          return 0;
        }
        const varA = typeof valueA === 'string' ? valueA.toUpperCase() : valueA;
        const varB = typeof valueB === 'string' ? valueB.toUpperCase() : valueB;

        let comparison = 0;

        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }

        return this.zipTableOptions.sorts[key] === 'desc'
          ? comparison * -1
          : comparison;
      });
    }

    return data;
  }

  // IMPORTANT: THIS WORKS FOR BOTH DETAILED COLUMNS AND NORMAL COLUMNS SO PAY CLOSE ATTENTION BEFORE CHANGING THIS
  private handleColumnPriority(columns, type, allowedColumns) {
    let colCounter = 0;
    let detailedCounter = 0;

    // reset the column props and detailed props
    this.detailedProps = {};
    this.columnProps = {};

    // sort the columns by priority first and then by their original index
    // then filter out by the type that was provided to the function (detailed or column)
    // then resort by the original index so that the user doesnt see strange behavior
    return columns
      .sort((col, col2) => {
        // sort by priority and then by original index
        if (col.priority < col2.priority) {
          return 1;
        } else if (col.priority > col2.priority) {
          return -1;
        } else {
          if (col.originalIndex > col2.originalIndex) {
            return 1;
          } else if (col.originalIndex < col2.originalIndex) {
            return -1;
          } else {
            return 0;
          }
        }
      })
      .filter((col) => {
        // filter out the appropriate columns
        if (colCounter < allowedColumns) {
          colCounter++;

          const isColumn = type === 'columns';

          if (isColumn) {
            this.columnProps[col.prop] = true;
          }

          return isColumn;
        } else {
          detailedCounter++;

          const isDetailed = type === 'detailed';

          if (isDetailed) {
            this.detailedProps[col.prop] = true;
          }

          return isDetailed;
        }
      })
      .sort((col1, col2) => {
        // sort back by the original index
        if (col1.originalIndex > col2.originalIndex) {
          return 1;
        } else if (col1.originalIndex < col2.originalIndex) {
          return -1;
        } else {
          return 0;
        }
      });
  }

  showConditionalActions(
    action: ConditionalAction | string,
    row: Record<string, string>,
  ): boolean {
    return (
      typeof action === 'string' ||
      !action.conditionProp ||
      action.conditionValues.includes(row[action.conditionProp])
    );
  }
}
