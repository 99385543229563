import { ObjectStatus } from '@zipari/shared-ds-util-status';
import { PatientDetailConfig } from './patientDetailConfig.model';

export const getPolicyStatusLabel = (
  status: string,
  config: PatientDetailConfig,
): string => {
  const statusValue = status?.toLowerCase();
  const allStatuses = Object.values(config?.statuses || {}).flatMap(
    (statusArray) => (statusArray as ObjectStatus[]) || [],
  );
  const matchingStatus = allStatuses.find(
    (status: ObjectStatus) => status.value === statusValue,
  );

  return matchingStatus ? matchingStatus.label : status;
};
