import { createReducer, on } from '@ngrx/store';
import {
  NavigationLink,
  ToolbarConfiguration,
  ToolbarDropdownOption,
} from '@zipari/shared-ds-util-navigation';
import { cloneObject } from '../../../shared/utils/object';
import * as actions from './ngrx-navigation.actions';

export interface NavigationState {
  links: NavigationLink[];
  icons: NavigationLink[];
  openItem: NavigationLink;
  activeItem: NavigationLink;
  isMobileMenuOpen: boolean;
  logoUrl: string;
  toolbarConfig: ToolbarConfiguration;
  activeToolbarDropdownOption: ToolbarDropdownOption;
  showCoBrandLogo: boolean;
  isLogoUrlExternal: boolean;
}

export const initialNavigationState: NavigationState = {
  links: undefined,
  icons: undefined,
  openItem: undefined,
  isMobileMenuOpen: false,
  activeItem: undefined,
  logoUrl: undefined,
  toolbarConfig: undefined,
  activeToolbarDropdownOption: undefined,
  showCoBrandLogo: false,
  isLogoUrlExternal: false,
};

const navigationReducer = createReducer(
  initialNavigationState,

  on(actions.getNavbarConfiguration, (state, action) => ({
    ...state,
    links: action.config?.links,
    icons: action.config?.icons,
    logoUrl: action.config?.logoUrl,
    showCoBrandLogo: action.config?.showCoBrandLogo,
    hideToolbar: action.config.hideToolbar,
    languagesSwitcher: action.config.languagesSwitcher,
    isLogoUrlExternal: action.config?.isLogoUrlExternal,
  })),

  on(actions.toggleNavbarMenu, (state, action) => ({
    ...state,
    openItem:
      action.link === state.openItem || !action.link?.items
        ? undefined
        : action.link,
  })),

  on(actions.toggleMobileMenu, (state, action) => ({
    ...state,
    isMobileMenuOpen: action.isOpen,
  })),

  on(actions.setActiveNavLink, (state, action) => ({
    ...state,
    activeItem: action.link,
  })),

  on(actions.getToolbarConfiguration, (state, action) => ({
    ...state,
    toolbarConfig: action.payload.config,
  })),

  on(actions.setActiveToolbarOption, (state, action) => ({
    ...state,
    toolbarConfig: {
      ...state.toolbarConfig,
      selectedOption: action.payload.option,
    },
  })),

  on(actions.getUnreadCountSuccess, (state, action) => {
    const icons = cloneObject(state.icons);

    icons[action.payload.index].count = action.payload.response.unread_count;

    return {
      ...state,
      icons,
    };
  }),

  on(actions.getUnreadCountError, (state, action) => ({
    ...state,
    error: action.error,
  })),

  on(actions.logout, () => initialNavigationState),
);

export function getNavigationReducer(state, action) {
  return navigationReducer(state, action);
}
