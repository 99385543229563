<ng-container
  *ngIf="
    !claimId || hasReturnedFromReviewPage || (claimDetail$ | async);
    else loading
  "
>
  <card
    class="EntryFormCard"
    [config]="{ hideSubHeader: true }"
    *ngFor="let card of config?.cards | keyvalue"
    id="{{ card.key }}"
    [ngStyle]="{ order: card.value.position }"
  >
    <div title>
      <h6 class="t-bold" [innerHTML]="card.value.title"></h6>
      <p [innerHTML]="card.value.subHeader"></p>
    </div>
    <div body class="EntryFormCard__Body">
      <p
        class="EntryFormCard__Text t-bold"
        *ngIf="card.value?.redeterminationQuestion; let redeterminationQuestion"
        [innerHTML]="redeterminationQuestion"
      ></p>

      <ng-container *ngFor="let control of card.value.form?.controls">
        <form-control
          [ngStyle]="{ 'grid-area': control.grid }"
          *ngIf="appealForm && !control?.['provider-information']"
          [group]="appealForm"
          [config]="control"
          [control]="appealForm?.get(control.prop)"
        ></form-control>

        <message-banner
          class="EntryFormCard__MessageBanner"
          *ngIf="
            control.prop === 'dateOfInitialDetermination' &&
            shouldShowInitialDateWarningMsg
          "
          [config]="initialDateWarningMsgConfig"
        ></message-banner>

        <file-uploader
          class="EntryFormCard__FileUploader"
          *ngIf="card.key === 'additionalDocumentation'"
          [restrictions]="card.value?.restriction"
          [showCloseIcon]="card.value?.showCloseIcon"
          [disabled]="!appealForm?.get('documentType')?.value"
          [errorMessageConfig]="card.value?.errorMessage"
          [config]="card.value"
          [fileAttachment]="documentContext"
          [progress]="documentUploadService.uploadProgress$ | async"
          [uploadErrorMessage]="documentUploadService.uploadError$ | async"
          (fileUploaded)="fileUploaded($event)"
          (fileRemoved)="removeFile($event)"
        >
        </file-uploader>

        <ng-container
          *ngIf="
            card.key === 'billingProviderInformation' &&
            control?.['provider-information']
          "
        >
          <site-location-and-practitioner
            class="EntryFormCard__BillingProvider"
            [group]="appealForm"
            [config]="control"
            [providerSelectionModalConfig]="providerSelectionModalConfig"
          ></site-location-and-practitioner>
        </ng-container>
      </ng-container>

      <p
        class="t-data"
        class="EntryFormCard__CardDescription"
        *ngIf="card.value.cardDescription"
        [innerHtml]="card.value.cardDescription"
      ></p>
    </div>
  </card>
</ng-container>

<ng-template #loading>
  <spinner size="medium" flexCenter="true"></spinner>
</ng-template>

<api-error-message-modal
  [modalConfig]="errorModal?.modalConfig"
  [buttonConfig]="errorModal?.submitButton"
  [showModal]="showErrorModal"
  [errorMessage]="errorMessage"
  (submitButtonClicked)="errorModalClicked()"
></api-error-message-modal>
