import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneObject } from '@zipari/web-utils';
import { CXCaptureService } from '@zipari/web-services';
import posthog from 'posthog-js';

import {
  CXCaptureConfig,
  GAConfig,
  PHConfig,
  ProductPrefixes,
  ProviderPortal,
  validCXEvents,
  validGAEvents,
  workflowContext,
} from '../constants/analytics';
import { CX_CALLS } from '../constants/cx-calls.constant';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { GoogleAnalyticsService } from './google-analytics.service';

const { version } = require('../../../../../../package.json');

export class UTM_PARAMS {
  utm_source?: string = '';
  utm_medium?: string = '';
  utm_campaign?: string = '';
  utm_term?: string = '';
  utm_content?: string = '';

  constructor(params) {
    const keys = Object.keys(this);

    keys.forEach((key: any) => {
      if (params[key]) {
        this[key] = params[key];
      } else {
        delete this[key];
      }
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  CX_Config: any;
  GA_Config: GAConfig;
  PH_Config: PHConfig;
  workflowContext: workflowContext;

  constructor(
    public googleAnalyticsService: GoogleAnalyticsService,
    public configService: ConfigService,
    public authService: AuthService,
    public http: HttpClient,
    public cx: CXCaptureService,
  ) {
    this.iniConfig();
  }

  public iniConfig() {
    const globalConfig = this.configService.getPageConfig<any>('global');

    if (globalConfig?.analytics) {
      if (
        globalConfig.analytics.cx_capture &&
        globalConfig.analytics.cx_capture.captureCXEvents &&
        this.checkForCXCaptureConfigs(globalConfig.analytics.cx_capture)
      ) {
        this.CX_Config = globalConfig.analytics.cx_capture;
        this.cx.initialize(
          this.CX_Config,
          { endpoint: this.CX_Config.endpoint },
          {
            cx_calls: CX_CALLS,
            source_system_name: this.CX_Config.source_system_name,
            tenant_name: this.CX_Config.tenant_name,
          },
          { name: ProviderPortal, version },
          this.authService.user?.id,
        );
      } else {
        console.warn('No CX Capture configuration provided');
      }

      this.initPosthog(globalConfig);

      if (globalConfig.analytics.google_analytics) {
        this.GA_Config = globalConfig.analytics.google_analytics;
      } else {
        console.warn('No Google analytics configuration provided');
      }
    } else {
      console.warn('No analytics configuration provided');
    }
  }

  initPosthog(globalConfig): void {
    if (
      globalConfig.analytics.postHog_capture &&
      globalConfig.analytics.postHog_capture.capturePosthogEvents
    ) {
      this.PH_Config = globalConfig.analytics.postHog_capture;
      posthog.init(this.PH_Config.apiKey, {
        api_host: this.PH_Config.apiHost,
        cross_subdomain_cookie: false,
      });
    } else {
      console.warn('No Posthog analytics configuration provided');
    }
  }

  public checkForCXCaptureConfigs(config: CXCaptureConfig) {
    const newConfig = new CXCaptureConfig(config);
    const validConfigs = Object.keys(newConfig);

    let valid = true;

    validConfigs.forEach((key: any) => {
      if (!config[key]) {
        console.warn(`${key} not provided in config`);

        valid = false;
      }
    });

    return valid;
  }

  public formatBrowserContext() {
    const context = {};

    context['timestamp'] = new Date().toISOString();

    // attempt to retrieve timezone
    try {
      context['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (err) {
      // intentionally swallow the timezone catch if we can't retrieve the timezone
    }

    context['page'] = Object.assign({
      url: window.location.href,
    });

    context['app'] = {
      name: this.configService.app,
      version: version,
    };

    return context;
  }

  public formatBody(
    name: validCXEvents | validGAEvents,
    dictionary_attributes: any,
  ) {
    let result = {};

    try {
      const authentication_flag = !!this.authService.loggedInUser;

      const consumer_id = this.getConsumerId();

      const session_id = this.workflowContext
        ? this.workflowContext.web_session_id
        : null;
      const source_system_name = this.CX_Config?.source_system_name;
      const context = this.formatBrowserContext();

      result = {
        name: `${this.determineProductPrefix(this.configService.app)}${name}`,
        source_system_name,
        authentication_flag,
        dictionary_attributes,
      };

      // only provide the cohort id if we have one
      if (consumer_id) {
        result['consumer_id'] = consumer_id;
      }

      if (context) {
        result['context'] = context;
      }

      if (session_id) {
        result['session_id'] = session_id;
      }
    } catch (err) {
      console.error(err);
    }

    return result;
  }

  public sendCXEvent(event_key: string, dictionary_attributes: any) {
    this.cx.sendEvent(event_key, dictionary_attributes);
  }

  public sendGAEvent(key: string, dictionary_attributes) {
    const body = {
      ...dictionary_attributes,
      context: this.formatBrowserContext(),
    };

    // handles mapping key to a custom google analytics key required by client
    const mappedKey =
      this.GA_Config?.mapping && this.GA_Config?.mapping[key]
        ? this.GA_Config?.mapping[key]
        : key;

    this.googleAnalyticsService.emitGAEvent(mappedKey, body);
  }

  public sendPostHogEvent(event_key: string, attributes: any): void {
    posthog.capture(event_key, attributes);
  }

  public sendEvent(key: string, dictionary_attributes: any = {}) {
    if (this.CX_Config) {
      this.sendCXEvent(key, dictionary_attributes);
    }

    if (this.GA_Config) {
      try {
        if (typeof ga !== 'undefined' || typeof gtag !== 'undefined') {
          this.sendGAEvent(key, dictionary_attributes);
        }
      } catch (error) {
        throw new Error(
          'Trying to call google analytics without ga available. Please provide google analytics tag',
        );
      }
    }

    if (this.PH_Config) {
      this.sendPostHogEvent(key, dictionary_attributes);
    }
  }

  public setWorkflowContext(context: workflowContext) {
    this.workflowContext = context;
  }

  retrieveUTMParamsFromUrl(params = {}): UTM_PARAMS {
    return new UTM_PARAMS(cloneObject(params));
  }

  getConsumerId(): string {
    const authentication_flag = !!this.authService.loggedInUser;

    let consumer_id;

    if (authentication_flag) {
      consumer_id =
        this.authService.loggedInUser.app_user_data.broker_id ||
        this.authService.loggedInUser.app_user_data.member_id ||
        this.authService.loggedInUser.app_user_data.user_name ||
        null;
    } else {
      consumer_id = this.workflowContext
        ? this.workflowContext.webuser_id
        : null;
    }

    return consumer_id;
  }

  /** A product prefix is used to indicate to cx capture team which product is being used...
   * our code generically utilizes these keys so that we do not have to concern ourselves
   * with adding the product prefix within our code */
  private determineProductPrefix(product) {
    let productPrefix = '';

    if (ProductPrefixes[product]) {
      productPrefix = ProductPrefixes[product];
    } else {
      console.warn('No configuration for product');
    }

    return productPrefix;
  }
}
