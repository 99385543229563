export interface ObjectStatuses {
  success?: ObjectStatus[];
  pending?: ObjectStatus[];
  error?: ObjectStatus[];
  info?: ObjectStatus[];
  expired?: ObjectStatus[];
  active?: ObjectStatus[];
  preactive?: ObjectStatus[];
  terminated?: ObjectStatus[];
  current?: ObjectStatus[];
}

/* eslint-disable @typescript-eslint/naming-convention */
export enum StatusType {
  // eslint-disable
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  PENDING = 'pending',
  EXPIRED = 'expired',
  ACTIVE = 'active',
  TERMINATED = 'terminated',
  PREACTIVE = 'preactive',
  CURRENT = 'current',
}

/* eslint-enable @typescript-eslint/naming-convention */
export interface Status {
  label: string;
  matchColor?: string;
  statuses?: ObjectStatuses;
  capitalized?: boolean;
  hideBackground?: boolean;
  fitContent?: boolean;
  whiteSpaceNormal?: boolean;
}

export interface ObjectStatus {
  label: string;
  value: string;
}
