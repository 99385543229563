import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  NotificationConfig,
  NotificationModel,
} from '../notifications.constant';
import { NotificationsService } from '../notifications.service';
import { accordion } from '../../../animations';
import { CX_CALLS } from '../../../constants/cx-calls.constant';
import { AnalyticsService } from '../../../services/analytics.service';
import { productIconLigatures } from '../../../icons/product-icon';

@Component({
  selector: 'all-notifications',
  templateUrl: './all-notifications.component.html',
  styleUrls: ['../notifications.component.scss'],
  animations: [accordion],
})
export class AllNotificationsComponent implements OnInit {
  @Input() notifications: NotificationModel[];
  @Input() config: NotificationConfig;
  @Input() icon: any;

  productIconLigatures = productIconLigatures;
  showTimeStamp: boolean;

  constructor(
    private notificationsService: NotificationsService,
    public router: Router,
    public analyticsService: AnalyticsService,
  ) {}

  ngOnInit() {
    this.showTimeStamp = this.config?.showTimeStamp ?? true;
  }

  toggle(notification: NotificationModel) {
    notification.open = !notification.open;

    if (!notification.is_read && notification.open) {
      this.notificationsService.registerNotificationAsRead(notification.id);
      notification.is_read = true;
      this.notificationsService.notifications = this.notifications;
    }
    this.fireNotificationReadCXEvent(notification);
  }

  setNotificationIcon(notification: NotificationModel) {
    const subtypes = notification.tags;

    for (const tag of subtypes) {
      if (this.icon[tag]) {
        return this.icon[tag];
      }
    }
  }

  linkClicked(notification: NotificationModel) {
    this.fireNotificationReadCXEvent(notification);
    if (notification.url.includes('http')) {
      window.open(notification.url, '_blank');
    } else {
      this.router.navigate([notification.url]);
    }
  }

  fireNotificationReadCXEvent(notification: NotificationModel): void {
    const dictionaryAttributes = {
      notification_id: notification.id,
      date: notification.created_at,
      is_read: notification.is_read,
    };

    this.analyticsService.sendEvent(
      CX_CALLS.pp_notification_center_read.event_key,
      dictionaryAttributes,
    );
  }
}
