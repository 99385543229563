import { HttpClient } from '@angular/common/http';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControlService } from '@zipari/design-system';
import { cloneObject, getValue } from '@zipari/web-utils';
import {
  AllControlsConfiguration,
  FormControlOptionConfiguration,
} from '@zipari/shared-ds-util-form';
import { ModelAttributeConfig } from '@zipari/shared-ds-util-configuration';
import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { WindowService, APIService, APIResponse } from '@zipari/web-services';
import { Observable, Subscription } from 'rxjs';
import { isEqual } from 'lodash';
import * as _ from 'lodash';

import {
  camelToSnakeCase,
  camelCase,
} from '../../../shared/utilities/case-conversion.utils';
import { ErrorModel, ZipError } from '../../../shared/models/Errors.model';
import {
  PortalUser,
  PreferenceOption,
  UserPreference,
  UserPreferencePayload,
} from '../../../shared/models/shared/UserPayloadModels/PortalUser.model';
import {
  Group,
  UserEditPayload,
} from '../../../shared/models/shared/UserPayloadModels/UserEditModel.model';
import {
  AuthService,
  ConfigService,
  FormattingService,
} from '../../../shared/services';
import { ProvisionerService } from '../user-provisioning/provisioner/provisioner.service';
import { stateOptions } from '../../../shared/constants/state.constants';
import {
  AuthConfig,
  AuthIDP,
  GlobalConfig,
  UserRole,
  ProviderPortalManageUserDetails,
} from '../../../app.constants';
import { SiteLocationEditFormFileds } from '../provider-update-tool/site-locations-attestation/site-locations-attestation.model';
import { getObjectProperty } from '../../../shared/utilities/get-object-property.utils';
import { createUserConstants } from '../manage-portal-users-create-user/manage-portal-users-create-user.constants';
import { PROVIDER_PORTAL_URLS } from '../../../app-urls.constant';
import { ProviderGroup } from '../../../shared/models/shared/ProviderGroup.model';
import { User } from '../../../shared/services/auth.service';
import {
  SuspendedStatus,
  ManagePortalUsersDetailConstant,
  UserStatus,
  UserSuspensionType,
  preferencePropName,
  preferenceTypeShortName,
  preference_group,
  stateProps,
  userProfilePageName,
} from './manage-portal-users-details.constant';
import { FormatRequestPayloadService } from './format-request-payload.service';
import { ManagePortalUsersDetailHttpService } from './manage-portal-users-detail.http.service';
import SuspendUserService from './services/suspend-user.service';
import { SuspendUserDataService } from './services/suspend-user-data.service';
import { authCardData } from '../../../shared/modules/authentication/auth-card/auth-card.constant';

@Component({
  selector: 'manage-portal-users-detail',
  templateUrl: './manage-portal-users-detail.component.html',
  styleUrls: ['./manage-portal-users-detail.component.scss'],
})
export class ManagePortalUsersDetailComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  config: any;
  idpId: string | number;
  userInformation: PortalUser;
  busy: Subscription;
  dataLoaded: boolean;
  role: string;
  showSuspendUserModal: boolean;
  showActivateUserModal: boolean;
  activeModal;
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  userStatus: string;
  showEditError: boolean;
  editErrors: ZipError[];
  actionBtn: any;
  selectedTab = 0;
  currentTab = 0;
  editModal: boolean;
  openAccordionIdx = 0;
  editFields = new UntypedFormGroup({});
  navigationLink: string;
  user: User;
  setUserPref: UserPreferencePayload[] = [];
  hasControlChange = false;
  showSuspensionTypeModal: boolean;
  suspensionTypeModalForm: UntypedFormGroup = new UntypedFormGroup({});
  globalConfig: GlobalConfig;
  authConfig: AuthConfig;
  backButtonConfig: ButtonConfig;
  userGroups: Group[];
  pageName: string;

  constructor(
    private configService: ConfigService,
    private route: ActivatedRoute,
    private api: APIService,
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private formatService: FormattingService,
    private provisioner: ProvisionerService,
    private formControlService: FormControlService,
    private cdr: ChangeDetectorRef,
    private formatRequestPayloadService: FormatRequestPayloadService,
    private managePortalUsersDetailHttpService: ManagePortalUsersDetailHttpService,
    private suspendUserService: SuspendUserService,
    private suspendUserDataService: SuspendUserDataService,
    private windowService: WindowService,
  ) {}

  get isProfileInfoEditable(): boolean {
    return this.config?.tabs?.tabConfigs[0]?.sections[0]?.showHeaderEditIcon;
  }

  ngOnInit(): void {
    this.pageName = this.route.snapshot.data.pageName;
    this.config = this.configService.getPageConfig(this.pageName);
    this.globalConfig = this.configService.getPageConfig('global');
    this.authConfig = this.configService.getPageConfig('auth');
    if (this.config?.actionButton) {
      this.actionBtn = structuredClone(this.config.actionButton);
      this.actionBtn.subnav.items = [];
    }

    this.idpId = this.route.snapshot.params.idp_id;
    if (!this.idpId) this.idpId = this.authService.getIdp_id();
    this.provisioner.setUserId(this.idpId);
    if (this.route.snapshot.data.pageName === 'user-profile') {
      this.getLoggedInUser();
    } else {
      this.getUserDetails();
    }
    // this.buildForm();

    this.user = this.authService.loggedInUser;
    this.navigationLink = localStorage.getItem(
      ManagePortalUsersDetailConstant.userNaviagteTo,
    );

    this.updateNavigationLink();

    // setting state options from constant
    this.config?.tabs?.tabConfigs[0]?.editFlyout?.formFields.forEach(
      (formField: SiteLocationEditFormFileds) => {
        formField?.controls &&
          formField.controls?.forEach((control: AllControlsConfiguration) => {
            if (
              control.prop === stateProps.practiceInformationState ||
              control.prop === stateProps.state
            ) {
              control.options = stateOptions;
            }
          });
      },
    );
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  updateBackButtonConfig(button: ButtonConfig) {
    this.backButtonConfig = button;
  }

  updateNavigationLink() {
    if (this.navigationLink === ProviderPortalManageUserDetails.portalUser) {
      this.updateBackButtonConfig(this.config?.backButton);
    } else if (
      this.navigationLink ===
      ProviderPortalManageUserDetails.providerSearchAdmin
    ) {
      this.updateBackButtonConfig(this.config?.backButtonForSearchAdmin);
    } else {
      this.updateBackButtonConfig(this.config?.backButtonInProfileCase);
    }
  }

  updateFormFieldsControl(): void {
    const firstIndex = '0';
    const editControls =
      this.config?.tabs?.tabConfigs[firstIndex]?.editFlyout?.formFields[
        firstIndex
      ]?.controls;
    const attributeFields =
      this.config?.tabs?.tabConfigs[firstIndex]?.sections[firstIndex]?.fields;

    if (
      this.isMultipleGroupsSelectionEnabled(true) &&
      this.userStatus !== SuspendedStatus
    ) {
      editControls &&
        (this.config.tabs.tabConfigs[firstIndex].editFlyout.formFields[
          firstIndex
        ].controls = editControls.filter(
          (control: AllControlsConfiguration) =>
            control.prop !== createUserConstants.groups,
        ));

      attributeFields &&
        (this.config.tabs.tabConfigs[firstIndex].sections[firstIndex].fields =
          attributeFields.filter(
            (attributes: ModelAttributeConfig) =>
              !attributes.prop?.includes(createUserConstants.groups),
          ));
    } else {
      editControls &&
        (this.config.tabs.tabConfigs[firstIndex].editFlyout.formFields[
          firstIndex
        ].controls = editControls.filter(
          (control: AllControlsConfiguration) =>
            control.prop !== createUserConstants.multipleGroup,
        ));

      attributeFields &&
        (this.config.tabs.tabConfigs[firstIndex].sections[firstIndex].fields =
          attributeFields.filter(
            (attributes: ModelAttributeConfig) =>
              attributes.prop !== createUserConstants.groupName,
          ));
    }
  }

  isMultipleGroupsSelectionEnabled(skipIdpCheck = false): boolean {
    return (
      (this.authConfig?.idp === AuthIDP.auth0 || skipIdpCheck) &&
      this.globalConfig?.toggleFeatures?.enableMultiGroupManagement
    );
  }

  public saveEditModal(): void {
    let submitObject = _.cloneDeep(this.editFields?.value);

    if (
      this.isMultipleGroupsSelectionEnabled(true) &&
      this.editFields.value?.multiple_group
    ) {
      const multipleGroups = this.editFields.value.multiple_group.map(
        (item: object) => ({
          id: item[createUserConstants.row]?.id,
        }),
      );

      submitObject[createUserConstants.multipleGroup] = _.unionBy(
        multipleGroups,
        this.userGroups,
        createUserConstants.id,
      );
    } else if (this.editFields.value?.groups) {
      submitObject[createUserConstants.groups] = [
        { id: this.editFields.value?.groups },
      ];
    }

    const payload: UserEditPayload =
      this.formatRequestPayloadService.getEditUserProfilePayload(
        submitObject,
        this.isMultipleGroupsSelectionEnabled(true),
        this.authConfig?.idp,
      );

    this.userInformation = camelCase(this.userInformation);
    submitObject = camelCase(submitObject);
    this.setUserPreferences(this.userInformation.userPreferences, submitObject);
    if (this.setUserPref.length > 0) {
      payload['user_preferences'] = this.setUserPref;
    }
    this.api
      .patch(`api/provider-portal/admin/users/${this.idpId}/`, payload)
      .subscribe(
        (resp: PortalUser) => {
          this.userInformation = resp;
          this.toggleEdit();
          this.getUserDetails();
        },
        (err: ErrorModel) => {
          this.showEditError = true;
          this.editErrors = err.error?.errors;
          this.activeModal = this.config?.errorModal;
        },
      );
  }

  public setUserPreferences(
    userPreference: UserPreference,
    submitValues: PortalUser,
  ) {
    if (userPreference && submitValues) {
      userPreference.forEach((preferences: UserPreference) => {
        switch (preferences?.preferenceTypes[0]?.shortName) {
          case preferenceTypeShortName.preferredContactMethod:
            if (submitValues.preferredContactMethod) {
              this.setPreference(preferences, [
                submitValues.preferredContactMethod,
              ]);
            }
            break;
          case preferenceTypeShortName.bestTimeToContact:
            if (submitValues.bestContactTime) {
              this.setPreference(preferences, [submitValues.bestContactTime]);
            }
            break;
          case preferenceTypeShortName.languagesSpoken:
            if (
              submitValues.languageSpoken &&
              submitValues.languageSpoken
                .filter((languages: any) => languages)
                .map(Number).length > 0
            ) {
              this.setPreference(
                preferences,
                submitValues.languageSpoken
                  .filter((languages: any) => languages)
                  .map(Number),
              );
            }
            break;
          case preferenceTypeShortName.preferredLanguage:
            if (submitValues.preferredLanguage) {
              this.setPreference(preferences, [submitValues.preferredLanguage]);
            }
            break;
          default:
            break;
        }
      });
    }
  }

  closeModal(): void {
    this.showEditError = false;
    this.activeModal = null;
  }

  public setPreferenceOptions(
    preferenceOptions: PreferenceOption[],
    propName: string,
  ) {
    if (this.config?.tabs?.tabConfigs[0]?.editFlyout.formFields[0].controls) {
      for (const control of this.config?.tabs?.tabConfigs[0]?.editFlyout
        .formFields[0].controls) {
        if (control.prop === propName) {
          control.options = this.setOptions(preferenceOptions, propName);
        }
      }
    }
  }

  public setOptions(preferenceOptions: PreferenceOption[], propName: string) {
    if (propName === preferencePropName.languageSpoken) {
      return preferenceOptions.map((item: any) => ({
        label: item.shortName,
        value: item.id.toString(),
      }));
    } else {
      return preferenceOptions.map((item: any) => ({
        label: item.shortName,
        value: item.id,
      }));
    }
  }

  getName(
    preferenceOptions: PreferenceOption[],
    preferredOptions: number | string[],
    multiType: string,
  ): string[] {
    const getType = { multi: 'multi', single: 'single' };

    if (multiType === getType.multi && Array.isArray(preferredOptions)) {
      return preferenceOptions
        .filter((preferenceOption: any) =>
          preferredOptions.some(
            (preferredOption: any) =>
              preferenceOption.id.toString() === preferredOption,
          ),
        )
        .map((value: any) => value.shortName);
    } else if (multiType === getType.single) {
      return preferenceOptions
        .filter(
          (preferenceOption: any) => preferenceOption.id === preferredOptions,
        )
        .map((value: any) => value.shortName);
    }
  }

  openEditModal(target?: any, idx?: number): void {
    this.editModal = true;
    if (idx || idx === 0) {
      this.openAccordionIdx = idx;
    }
  }

  public getUserPreferences(userPreference: UserPreference): void {
    if (
      userPreference?.preferenceGroup ===
      preference_group.ProviderCommunicationPreferences
    ) {
      userPreference.preferenceTypes.forEach((preferenceType: any) => {
        switch (preferenceType.shortName) {
          case preferenceTypeShortName.preferredContactMethod:
            // set userpreference options
            this.setPreferenceOptions(
              userPreference.preferenceOptions,
              preferencePropName.preferredContactMethod,
            );
            this.userInformation.preferredContactMethod =
              preferenceType.userSelectedOptionIds[0];
            const prefContactMethodName = this.getName(
              userPreference.preferenceOptions,
              this.userInformation.preferredContactMethod,
              'single',
            );

            this.userInformation.preferredContactMethodName =
              prefContactMethodName;
            break;
          case preferenceTypeShortName.bestTimeToContact:
            // set userpreference options
            this.setPreferenceOptions(
              userPreference.preferenceOptions,
              preferencePropName.bestContactTime,
            );
            this.userInformation.bestContactTime =
              preferenceType.userSelectedOptionIds[0];
            const bestContactTimeName = this.getName(
              userPreference.preferenceOptions,
              this.userInformation.bestContactTime,
              'single',
            );

            this.userInformation.bestContactTimeName = bestContactTimeName;
            break;
          default:
            break;
        }
      });
    } else if (
      userPreference?.preferenceGroup ===
      preference_group.ProviderLanguagePreference
    ) {
      userPreference.preferenceTypes.forEach((preferenceType: any) => {
        switch (preferenceType.shortName) {
          case preferenceTypeShortName.languagesSpoken:
            // set userpreference options
            this.setPreferenceOptions(
              userPreference.preferenceOptions,
              preferencePropName.languageSpoken,
            );
            const selectedSpokenLang = [];

            userPreference.preferenceOptions.forEach((value: any) => {
              if (preferenceType.userSelectedOptionIds.includes(value.id)) {
                selectedSpokenLang.push(value.id.toString());
              } else selectedSpokenLang.push(null);
            });
            this.userInformation.languageSpoken = selectedSpokenLang;
            const languageSpokenName = this.getName(
              userPreference.preferenceOptions,
              this.userInformation.languageSpoken,
              'multi',
            );

            this.userInformation.languageSpokenName = languageSpokenName;
            break;
          case preferenceTypeShortName.preferredLanguage:
            // set userpreference options
            this.setPreferenceOptions(
              userPreference.preferenceOptions,
              preferencePropName.preferredLanguage,
            );
            this.userInformation.preferredLanguage =
              preferenceType.userSelectedOptionIds[0];
            const preferredLanguageName = this.getName(
              userPreference.preferenceOptions,
              this.userInformation.preferredLanguage,
              'single',
            );

            this.userInformation.preferredLanguageName = preferredLanguageName;
            break;
          default:
            break;
        }
      });
    }
  }

  getUserDetails(): void | any {
    const portalUser: Observable<PortalUser> = this.api.get(
      this.setUserId(this.config?.endpoints?.user),
    );

    this.busy = portalUser.subscribe((user: PortalUser) => {
      const userDetail = camelCase(user);

      this.userInformation = cloneObject(userDetail);
      this.config = this.formatService.formatConfigValueAsTemplate(
        this.config,
        this.userInformation,
      );
      this.role = this.route.snapshot.data.role;
      // creating new address object to set address in user profile section
      this.userInformation.userAddress = {
        address1: this.userInformation.address1,
        address2: this.userInformation.address2,
        city: this.userInformation.city,
        state: this.userInformation.state,
        zipCode: this.userInformation.zipCode,
      };
      this.provisioner.setUserFirstName(this.userInformation.firstName);
      this.provisioner.setUserLastName(this.userInformation.lastName);
      this.userStatus = user?.status?.toLowerCase();
      this.updateFormFieldsControl();

      // refactoring user contact/communication preference
      if (this.userInformation.userPreferences) {
        this.userInformation.userPreferences.forEach((userPreference: any) => {
          this.getUserPreferences(userPreference);
        });
      }
      this.userInformation = camelToSnakeCase(this.userInformation);
      if (this.shouldFilterUserGroups()) {
        this.updateUserGroups();
      }
      this.buildForm();
      if (this.config.actionButton) {
        this.setActionConfig();
      } else {
        this.dataLoaded = true;
      }
    });
  }

  shouldFilterUserGroups(): boolean {
    return (
      this.isMultipleGroupsSelectionEnabled() &&
      this.isProviderAdmin() &&
      this.pageName !== userProfilePageName
    );
  }

  updateUserGroups(): void {
    this.userGroups = this.userInformation.groups.map(
      (group: ProviderGroup) => ({ id: group.id }),
    );
    this.userInformation.groups = this.userInformation.groups.filter(
      (group: ProviderGroup) =>
        !!this.user?.app_user_data.groups?.find(
          (loggedInUserGroup) => loggedInUserGroup.id === group.id,
        ),
    );
    this.userInformation[createUserConstants.groupName] =
      this.userInformation.groups
        .map((group: ProviderGroup) => group.name)
        .join(',');
  }

  cancelConfirmModal(): void {
    this.showSuspendUserModal = this.showActivateUserModal = false;
  }

  onFormGroupValueChange(): void {
    const initialFormValue = structuredClone(this.editFields.value);

    this.editFields.valueChanges.subscribe((value) => {
      this.hasControlChange = Object.keys(this.editFields.value).some(
        (key) => !isEqual(value[key], initialFormValue[key]),
      );
    });
  }

  submitModal(): void {
    this.userInformation = camelCase(this.userInformation);
    if (this.activeModal?.endpoint) {
      const endpoint: string = this.activeModal.endpoint.replace(
        '{user}',
        this.userInformation.userName,
      );

      this.api.post(endpoint, null).subscribe(
        (resp: any) => {
          this.windowService.nativeWindow.location.reload();
          this.getUserDetails();
        },
        (err: any) => {
          // TODO: do the needful
        },
      );
    }
  }

  submitSuspendUserModal(): void {
    if (this.config.showSuspendUserModal.endpoint) {
      const endpoint: string =
        this.config.showSuspendUserModal.endpoint.replace(
          '{user}',
          this.userInformation.id,
        );

      this.api.post(endpoint, null).subscribe(
        (resp: any) => {
          this.windowService.nativeWindow.location.reload();
          this.getUserDetails();
        },
        (err: any) => {
          // TODO: do the needful
        },
      );
    }
  }

  submitActivateUserModal(): void {
    if (this.config.showActivateUserModal.endpoint) {
      const endpoint: string =
        this.config.showActivateUserModal.endpoint.replace(
          '{user}',
          this.userInformation.id,
        );

      this.api.post(endpoint, null).subscribe(
        (resp: any) => {
          this.windowService.nativeWindow.location.reload();
          this.getUserDetails();
        },
        (err: any) => {
          // TODO: do the needful
        },
      );
    }
  }

  buildForm(): void {
    if (this.config?.tabs?.tabConfigs[0]?.editFlyout?.formFields) {
      Object?.values(
        this.config.tabs.tabConfigs[0].editFlyout.formFields,
      ).forEach((formField: any) => {
        Object?.values(formField?.controls).forEach((formControl: any) => {
          if (
            formControl?.type === createUserConstants.dropdown &&
            this.config[createUserConstants.dropdownsApiPropKey] &&
            this.config[createUserConstants.dropdownsApiPropKey][
              formControl?.prop
            ]
          ) {
            this.populateDropdownsFromApi(formControl);
          } else {
            this.prePopulateFormControlValue(formControl);
          }
        });
      });
    }
    this.onFormGroupValueChange();
    this.hasControlChange = false;
  }

  prePopulateFormControlValue(formControl: AllControlsConfiguration): void {
    const control = new UntypedFormControl('', []);
    const newFormControl = { ...formControl, control: control };

    this.formControlService.addControlToFormGroup(
      this.editFields,
      newFormControl,
    );
    const getKeyValue = getObjectProperty(
      this.userInformation,
      formControl?.default,
    );

    if (
      getKeyValue &&
      formControl?.prop === createUserConstants.multipleGroup
    ) {
      const updatedGetKeyValue = getKeyValue.map((group: Group) => ({
        row: group,
      }));

      this.editFields?.get(formControl?.prop)?.patchValue(updatedGetKeyValue);
    } else if (getKeyValue) {
      this.editFields?.get(formControl?.prop)?.patchValue(getKeyValue);
    } else {
      this.editFields?.get(formControl?.prop)?.patchValue(null);
    }
  }

  populateDropdownsFromApi(controlConfig: AllControlsConfiguration): void {
    const apiConfigObject =
      this.config[createUserConstants.dropdownsApiPropKey][controlConfig?.prop];
    const dropdownEndpoint: string = apiConfigObject.apiEndpoint;
    const labelProp: string = apiConfigObject.labelProp;
    const valueProp: string = apiConfigObject.valueProp;
    const newOptions: FormControlOptionConfiguration[] = [];

    this.managePortalUsersDetailHttpService
      .getDropdownList(dropdownEndpoint)
      .subscribe((resp: APIResponse<Group>) => {
        resp.results?.forEach((group: Group) => {
          newOptions.push({
            label: group[labelProp],
            value: group[valueProp],
          });
        });
        controlConfig.options = newOptions;
        const control = new UntypedFormControl('', []);
        const newFormControl = { ...controlConfig, control: control };

        this.formControlService.addControlToFormGroup(
          this.editFields,
          newFormControl,
        );
        const getKeyValue = getObjectProperty(
          this.userInformation,
          controlConfig?.default,
        );

        if (getKeyValue) {
          this.editFields.get(controlConfig.prop)?.setValue(getKeyValue[0]?.id);
        }
        if (this.userStatus === SuspendedStatus) {
          this.editFields.get('groups')?.disable();
        }
      });
  }

  /*
   * ERHO: Refactor this eventually to pull from a library of available button options
   */
  setActionConfig(): void {
    if (this.actionBtn?.subnav?.items.length > 0) {
      this.actionBtn.subnav.items = [];
    }
    const listItems: any[] = this.config?.actionButton?.subnav?.items;
    const resendVerEmail = 0;
    const suspendUser = 1;
    const activateUser = 2;

    switch (this.userInformation?.status?.toLowerCase()) {
      case 'active':
        this.actionBtn.subnav.items.push(listItems[suspendUser]);
        break;
      case 'suspended':
        this.actionBtn.subnav.items.push(listItems[activateUser]);
        break;
      case 'in progress':
        break;
      case 'pending':
        this.actionBtn.subnav.items.push(listItems[resendVerEmail]);
        break;
    }
    this.dataLoaded = true;
  }

  // backwards and forward compatible (per requirements)
  // everyone will retrieve from idp_id
  // TODO: refactor the octa flow out of this component, its no longer needed
  getLoggedInUser(): void {
    this.userInformation = this.authService.user;
    this.userInformation = camelCase(this.userInformation);
    if (this.userInformation?.idpId) {
      this.idpId = this.userInformation?.idpId;
      this.getUserDetails();
    } else {
      this.dataLoaded = true;
    }
  }

  setUserId(target: string): string {
    return (
      target?.replace('${idp_id}', <string>this.idpId) ||
      authCardData.emptyString
    );
  }

  routeBack(): void {
    const {
      userNaviagteTo,
      providerPortal,
      managePortalUsers,
      providerAdmins,
      manageAccessRequests,
    } = ManagePortalUsersDetailConstant;
    const baseUrl = `${providerPortal}/${this.role}/`;

    const route =
      this.navigationLink === ProviderPortalManageUserDetails.portalUser
        ? `${baseUrl}${managePortalUsers}`
        : this.navigationLink ===
            ProviderPortalManageUserDetails.providerSearchAdmin
          ? `${baseUrl}${providerAdmins}`
          : `${baseUrl}${manageAccessRequests}`;

    this.navigateToListPage(route);

    localStorage.removeItem(userNaviagteTo);
  }

  navigateToListPage(navigationLink: string): void {
    this.router.navigate([navigationLink]);
  }

  navigateSubNav(event): void {
    if (
      this.isMultipleGroupsSelectionEnabled(true) &&
      event.action.targetModal === UserStatus.suspend
    ) {
      if (this.isPayerAdmin()) {
        this.showSuspensionTypeModal = true;
      } else {
        this.suspendUserService.suspensionType = UserSuspensionType.PARTIALLY;
        this.suspendUserDataService.getGroupsList(
          this.setUserId(
            this.config?.suspendUserAccountModal?.userGroupsEndpoint,
          ),
        );
        this.router.navigate([PROVIDER_PORTAL_URLS.URLS.SUSPEND_USER], {
          relativeTo: this.route,
        });
      }
    } else if (
      !!this.isMultipleGroupsSelectionEnabled() &&
      event.action.targetModal === UserStatus.activate
    ) {
      this.router.navigate([PROVIDER_PORTAL_URLS.URLS.ACTIVATE_USER], {
        relativeTo: this.route,
      });
    } else {
      if (event.relative) {
        this.router.navigate([event.route], { relativeTo: this.route });
      }
      if (event.action) {
        if (event.action.openModal) {
          if (event.action.targetModal === 'suspend') {
            this.showSuspendUserModal = !this.showSuspendUserModal;
            this.activeModal = this.showSuspendUserModal
              ? this.config?.suspendUserModal
              : null;
          } else if (event.action.targetModal === 'activate') {
            this.showActivateUserModal = !this.showActivateUserModal;
            this.activeModal = this.showActivateUserModal
              ? this.config?.activateUserModal
              : null;
          }
        } else {
          if (event.action.endpoint) {
            const endpoint: string = event.action.endpoint.replace(
              '{target}',
              this.userInformation[event.action.targetString],
            );
            const payload: {} = {};

            this.http.post(endpoint, payload).subscribe();
          }
        }
      }
    }
  }

  getValue(data: object, attr): string | number {
    const value: string | number = getValue(data, attr.prop);

    return value;
  }

  handleTabSelected(event: any): void {
    this.currentTab = event;
  }

  openEditModalIfNeeded(
    event,
    showHeaderEditIcon: boolean,
    target,
    idx: number,
  ): void {
    if (showHeaderEditIcon) this.openEditModal(target, idx);
    else {
      event.stopPropagation();
    }
  }

  isProviderAdmin(): boolean {
    return this.authService.userRole === UserRole.providerAdmin;
  }

  isPayerAdmin(): boolean {
    return this.authService.userRole === UserRole.payerAdmin;
  }

  isUserInfoEditable(index: number): boolean {
    let status = false;
    const editStatus =
      this.globalConfig?.toggleFeatures?.editProfileInformationPermission;
    const adminStatus =
      (editStatus?.enabledForPayerAdmin && this.isPayerAdmin()) ||
      (editStatus?.enabledForProviderAdmin && this.isProviderAdmin());

    index === 0
      ? (status = !!(this.isProfileInfoEditable && adminStatus))
      : (status = true);

    return status;
  }

  toggleEdit(): void {
    this.editModal = !this.editModal;
  }

  suspensionTypeSelected(): void {
    this.showSuspensionTypeModal = false;
    this.suspendUserService.suspensionType =
      this.suspensionTypeModalForm?.value?.suspensionType;
    this.suspendUserDataService.getGroupsList(
      this.setUserId(this.config?.suspendUserAccountModal?.userGroupsEndpoint),
    );
    this.router.navigate([PROVIDER_PORTAL_URLS.URLS.SUSPEND_USER], {
      relativeTo: this.route,
    });
  }

  closeSuspensionTypeModal(): void {
    this.showSuspensionTypeModal = false;
  }

  ngOnDestroy() {
    this.provisioner.clearUserId();
  }

  private setPreference(
    preferenceType: UserPreference,
    userSelectedValues: number[],
  ): void {
    const preference = {
      id: preferenceType.id,
      user_id: preferenceType.userId,
      preference_group: preferenceType.preferenceGroup,
      partner_name: preferenceType.partnerName,
      partner_terms_disclosures: preferenceType.partnerTermsDisclosure,
      preference_types: [
        {
          id: preferenceType.preferenceTypes[0].id,
          detail_text: preferenceType.preferenceTypes[0].detailText,
          priority_number: preferenceType.preferenceTypes[0].priorityNumber,
          user_selected_option_ids: userSelectedValues,
          available_option_ids:
            preferenceType.preferenceTypes[0].availableOptionIds,
        },
      ],
    };

    this.setUserPref.push(preference);
  }
}
