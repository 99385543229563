import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  ModalConfig,
  ModalHeader,
  ModalTypes,
} from '@zipari/shared-ds-util-modal';
import { getValue, stringBuilder } from '@zipari/web-utils';
import { GlobalConfig } from '../../../app.constants';
import { ConfigService, IdleService } from '../../services';
import { TimeoutWarningModalConfig } from './timeout-modal.constants';

@Component({
  selector: 'timeout-modal',
  templateUrl: './timeout-modal.component.html',
  styleUrls: ['./timeout-modal.component.scss'],
})
export class TimeoutModalComponent implements OnInit, OnDestroy {
  config: TimeoutWarningModalConfig;

  modalConfig = new ModalConfig({
    type: ModalTypes.popup,
    header: new ModalHeader({
      showClose: false,
    }),
  });

  constructor(
    private configService: ConfigService,
    public idleService: IdleService,
  ) {}

  ngOnInit() {
    this.setConfig();
  }

  ngOnDestroy() {
    this.idleService.stopTimeout();
  }

  setConfig() {
    const globalConfig =
      this.configService.getPageConfig<GlobalConfig>('global');
    const timeoutConfig = getValue(globalConfig, 'timeout.timeoutWarningModal');

    this.config = new TimeoutWarningModalConfig(timeoutConfig);
    this.modalConfig.header.title = this.config.title;
  }

  handleCancel() {
    this.idleService.resetTimeout();
  }

  handleLogout() {
    this.idleService.handleLogout();
  }

  formatCountdown(seconds: number): string {
    if (!seconds) return '0';

    const dateStr = new Date(seconds * 1000).toISOString();
    const timeStr = dateStr.substr(11, 8); // HH:mm:ss

    return timeStr.replace(/^0(?:0:0?)?/, '');
  }

  public get modalBody(): string {
    const formattedCountdown = this.formatCountdown(this.countdown);

    return stringBuilder(this.config.body, {
      countdown: `<strong>${formattedCountdown}</strong>`,
    });
  }

  public get countdown(): number {
    return this.idleService.timeoutCountdown;
  }

  public get isIdling(): boolean {
    return this.idleService.isIdling;
  }
}
