import { Component, OnChanges, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { controlTypes } from '@zipari/shared-ds-util-form';
import { FormControlValidatorsService } from '../shared/validators/validators.service';
import { DateRangeConfig } from './date-range.models';

@Component({
  selector: 'control-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class ControlDateRangeComponent implements OnChanges {
  @Input() config: DateRangeConfig;
  @Input() group: UntypedFormGroup;

  dateProps: string[];
  dateRangeConfig: { [x: string]: DateRangeConfig };
  dateRangeFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  formGroupName: string;

  constructor(private validatorService: FormControlValidatorsService) {}

  ngOnChanges() {
    const {
      prop,
      placeholder,
      iconRight,
      start_date_validators,
      end_date_validators,
      min,
      max,
      fallbackMask,
    } = this.config;
    const startPropName = this.config.startProp || 'start';
    const endPropName = this.config.endProp || 'end';

    this.dateProps = [startPropName, endPropName];
    this.formGroupName = prop;
    this.dateRangeConfig = {
      [startPropName]: {
        iconRight: iconRight,
        min,
        max,
        fallbackMask,
        prop: startPropName,
        type: controlTypes.date,
        validators: start_date_validators,
      },
      [endPropName]: {
        iconRight: iconRight,
        min,
        max,
        fallbackMask,
        prop: endPropName,
        type: controlTypes.date,
        validators: end_date_validators,
      },
    };
    if (placeholder) {
      // the placeholder is set in the text control
      this.dateRangeConfig[startPropName].placeholder = placeholder;
      this.dateRangeConfig[endPropName].placeholder = placeholder;
    }

    const startDateValidators =
      this.validatorService.getFormControlValidators(
        this.dateRangeConfig[startPropName],
      ) || [];
    const endDateValidators =
      this.validatorService.getFormControlValidators(
        this.dateRangeConfig[endPropName],
      ) || [];

    this.dateRangeFormGroup.addControl(
      endPropName,
      new UntypedFormControl('', {
        validators: endDateValidators,
        updateOn: 'blur',
      }),
    );
    this.dateRangeFormGroup.addControl(
      startPropName,
      new UntypedFormControl('', {
        validators: [...startDateValidators],
        updateOn: 'blur',
      }),
    );
    this.group.removeControl(prop);
    this.group.addControl(prop, this.dateRangeFormGroup);
  }

  /**
   * Updates the validators for the other date control when one changes
   * @param event
   */
  updateValidators(event: MatDatepickerInputEvent<Date>) {
    const startPropName = this.config.startProp || 'start';
    const endPropName = this.config.endProp || 'end';
    const propName =
      event.targetElement.id === startPropName ? endPropName : startPropName;

    this.dateRangeFormGroup.get(propName).updateValueAndValidity();
  }
}
