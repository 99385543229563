import { Component, OnInit } from '@angular/core';
import { APIService } from '@zipari/web-services';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

import { AuthService, ConfigService } from '../../../shared/services';
import { GenericServices } from '../../shared/services/generic.service';
@Component({
  selector: 'dashboard',
  templateUrl: './new-dashboard.component.html',
  styleUrls: ['./new-dashboard.component.scss'],
})
export class NewDashboardComponent implements OnInit {
  config: any;
  busy: Subscription;
  error = false;
  response: any;
  _: any = _;

  constructor(
    private api: APIService,
    private configService: ConfigService,
    public genericServices: GenericServices,
    public authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.config = this.configService.getPageConfig('new-dashboard');
    this.getNotification(this.config);
  }

  getNotification(config) {
    if (config?.notification?.getNotification?.endpoint) {
      this.busy = this.api
        .get(config?.notification?.getNotification?.endpoint)
        .subscribe(
          (resp: any) => {
            const res = resp['results'];

            this.response = res.filter((obj: any) => obj.is_read === false);
          },
          (err: any) => {
            // TODO: do the needdful
          },
        );
    }
  }

  toggleError() {
    this.error = !this.error;
  }
}
