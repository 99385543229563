<div
  class="Card"
  [ngClass]="{
    'Card--Stretch': config?.stretch,
    'Card--Flat': config?.ignorePadding,
    'Card--MaxHeight': config?.maxHeight,
  }"
>
  <div class="Card__Header" *ngIf="!config?.hideHeader">
    <div class="Card__Title">
      <ng-content select="[title]"></ng-content>
    </div>

    <div class="Card__Action">
      <ng-content select="[header-action]"></ng-content>
    </div>
  </div>

  <div class="Card__Header" *ngIf="!config?.hideSubHeader">
    <div class="Card__Subheader">
      <ng-content select="[subheader]"></ng-content>
    </div>
  </div>

  <div class="Card__Body">
    <ng-content select="[body]"></ng-content>
  </div>

  <!-- Duplicate card body using body-group template preserved to maintain existing functionality -->
  <div class="Card__Body">
    <ng-content select="[body-group]"></ng-content>
  </div>

  <div
    class="Card__Footer"
    *ngIf="!config?.hideFooter"
    [ngClass]="{ 'Card__Footer--IsStatic': config?.static }"
  >
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
