import { HttpClient } from '@angular/common/http';
import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {
  ActivatedRoute,
  Router,
  UrlSerializer,
  UrlTree,
} from '@angular/router';
import OktaSignIn from '@okta/okta-signin-widget';
import { FormControlService, Option } from '@zipari/design-system';
import {
  AllControlsConfiguration,
  controlTypes,
} from '@zipari/shared-ds-util-form';
import { CXCaptureService, WindowService } from '@zipari/web-services';
import { getValue, stringBuilder } from '@zipari/web-utils';
import { Observable, Subscription } from 'rxjs';
import posthog from 'posthog-js';
import { mergeMap } from 'rxjs/operators';
import { validCXEvents } from '../../constants/analytics';
import { AuthService, ConfigService } from '../../services';
import { AnalyticsService } from '../../services/analytics.service';
import {
  ZipBackendErrorResponse,
  ZipEndpointService,
} from '../../services/zip-endpoint.service';
import { GlobalConfig } from '../../../app.constants';
import { manageAccessRequestDetailConstant } from '../../../provider-portal/templates/manage-access-request-detail/manage-access-request-detail.constant';
import {
  AuthCardOptions,
  AuthenticationConfig,
  AuthenticationLayouts,
  AuthenticationPages,
  BannerOptions,
  defaultErrorMessages,
  RouteAfterSuccessConfig,
  StepOptions,
  validConfigs,
} from './authentication.constants';
import {
  AssociatedTaxIds,
  ReplaceTaxId,
  RequestAccessPayload,
  RequestAccessResponse,
} from './model/request-access';
import { PayloadService } from './service/payload.service';
import { RequestAccessDataService } from './service/request-access-data.service';

export enum RuleRegEx {
  MIN_LENGTH = '.{8,}',
  UPPERCASE = '.*[A-Z]',
  LOWERCASE = '.*[a-z]',
  ONE_NUMBER = '.*[0-9]',
  SPECIAL_CHAR = '.*[-+_!@#$%^&*., ?]',
}

export interface ForgotPasswordPayload {
  user_name: string;
  next?: string;
}

export interface ResetPasswordPayload {
  next?: string;
  password: string;
  confirm_password?: string;
  password_confirm?: string;
  user_name: string;
}

@Component({
  selector: 'authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements DoCheck, OnInit {
  @Output() registerCompleted = new EventEmitter();
  @Output() completeStep = new EventEmitter();
  AuthenticationLayouts = AuthenticationLayouts;
  config: AuthenticationConfig = {};
  formGroup: UntypedFormArray = new UntypedFormArray([]);
  busy: Promise<any> | Subscription;
  errorMessage: string[];
  workflowId: string | number;
  success: boolean;
  token: string;
  previousPage: string;
  widget;
  authConfig;
  activeCard;
  activeCardIdx;
  showAuthCard = true;
  finalFormGroup: UntypedFormGroup;
  requestPayload: RequestAccessPayload = <RequestAccessPayload>{};
  public globalConfig: GlobalConfig;
  public associatedTaxIds: string[] | Option[];
  public showReplacementUsersModal: boolean;
  public associatedTaxIds$: Observable<string[]>;
  public verifyTaxIds: string[];
  public selectedAssociatedTaxIds: Option[];
  public replaceTaxIdsPayload: ReplaceTaxId[];
  public enableReplacementUserWithTaxId: boolean;

  constructor(
    private urlSerializer: UrlSerializer,
    public http: HttpClient,
    public zipEndpointService: ZipEndpointService,
    public authService: AuthService,
    private cx: CXCaptureService,
    public configService: ConfigService,
    public route: ActivatedRoute,
    public router: Router,
    public analyticsService: AnalyticsService,
    private formControlService: FormControlService,
    private payloadService: PayloadService,
    private requestAccessDataService: RequestAccessDataService,
    public window: WindowService,
  ) {}

  @Input()
  set layout(layout: AuthenticationLayouts) {
    this.config.layout = layout;
  }

  @Input()
  set page(page: AuthenticationPages) {
    this.config.page = page;
  }

  @Input()
  set layoutOptions(layoutOptions: StepOptions | BannerOptions) {
    this.config.layoutOptions = layoutOptions;
  }

  @Input()
  set authCardOptions(authCardOptions: AuthCardOptions) {
    this.config.authCardOptions = authCardOptions;
  }

  @Input()
  set routeAfterSuccessConfig(
    routeAfterSuccessConfig: RouteAfterSuccessConfig,
  ) {
    this.config.routeAfterSuccessConfig = routeAfterSuccessConfig;
  }

  public get disableButton(): boolean {
    return (
      getValue(this.config, 'routeAfterSuccessConfig.disableAfterSuccess') &&
      this.success
    );
  }

  ngOnInit(): void {
    // tries to retrieve data from a route if added as a route
    this.retrieveDataForPage();
    // initializes page if needed
    this.initializePage();

    this.activeCardIdx = 0;
    this.activeCard =
      this.config.authCardOptions?.cardViews[this.activeCardIdx];

    this.config.authCardOptions?.cardViews.map((card, idx: number) => {
      if (card.form) this.setupFormControls(card.form.controls, idx);
    });
    this.globalConfig = this.configService.getPageConfig<GlobalConfig>(
      manageAccessRequestDetailConstant.global,
    );
    this.enableReplacementUserWithTaxId =
      this.globalConfig?.toggleFeatures.enableReplacementUserWithTaxId;
  }

  /** core functionality */
  initializePage(): void {
    this.token = this.route.snapshot.queryParams?.token;
    this.workflowId = this.route.snapshot.queryParams?.workflowId;

    // if (this.token)
    //     this.navigateToAuthenticationRoute('reset-password');

    if (
      this.config.page === AuthenticationPages.register &&
      this.route.snapshot.queryParams.fromEmail
    ) {
      this.router.navigate([
        this.configService.appRoute,
        AuthenticationPages.login,
      ]);
    }
  }

  ngDoCheck(): void {
    if (this.config.page !== this.previousPage) {
      this.previousPage = this.config.page;

      const dictionary_attributes = {
        eventLabel: 'auth-page-viewed',
        pageName: `${
          this.config.page.charAt(0).toUpperCase() + this.config.page.slice(1)
        } Page`,
      };

      this.analyticsService.sendEvent(validCXEvents.virtualPageView, {
        ...dictionary_attributes,
      });
    }
  }

  checkIDP(): void {
    if (this.config.idp === 'okta') {
      const idp = this.config.okta;

      this.widget = new OktaSignIn({
        authParams: {
          issuer: 'https://dev-823880.okta.com/oauth2/default',
          pkce: false,
          responseType: ['code'],
          scopes: ['openid', 'offline_access'],
        },
        baseUrl: 'https://dev-823880.okta.com',
        clientId: '0oa1cyh7gm0HQ8sDz4x7',
        redirectUri:
          'https://providers-democare.dev.zipari.net/provider-portal/oauth/',
        // authParams: this.config.okta.authParams,
        // baseUrl: "https://dev-823880.okta.com",
        // clientId: '0oa1cyh7gm0HQ8sDz4x7',
        // i18n: this.config.okta.i18n,
        // pkce: false,
        // redirectUri: 'https://members-democare.dev.zipari.net/oauth/"',
      });
      this.toggleWidget();
    }
  }

  toggleWidget(): void {
    this.widget.renderEl({
      el: '#widget-container',
    });
  }

  handleLinkClicked(event?): void {
    // handle specific link clicking scenarios
    // if you need to handle a new page having a link clicked just create a new case in the switch statement
    switch (this.config.page) {
      case 'login':
        this.handleLoginLinkClicked(event);
        break;
      case 'register':
        this.handleRegisterLinkClicked(event);
        break;
      case 'previousCard':
        break;
    }
    switch (event.prop) {
      case 'previousCard':
        this.handleRequestAccessLinkClicked(event);
        break;
      case 'login':
        this.router.navigateByUrl('/authentication');
        break;
      case 'register':
        this.router.navigateByUrl('/registration');
        break;
      case 'password':
        this.router.navigateByUrl('/forgot-password');
        break;
      case 'extLogin':
        this.window.nativeWindow.location.href = this.config.externalLoginUrl;
    }
  }

  handleButtonClicked(event?): void {
    // handle specific button clicking scenarios per page
    switch (this.config.page) {
      case 'reset-password':
        this.handleResetPasswordButtonClicked();
        break;
      case 'forgot-password':
        this.handleForgotPasswordButtonClicked();
        break;
      case 'register':
        this.handleRegisterButtonClicked(event);
        break;
      case 'request-access':
        this.handleRequestAccessButtonClicked(event);
        break;
      case 'login':
        this.handleLoginButtonClicked();
        break;
      case 'terms-and-conditions':
        this.handleAcceptTermsConditionsButtonClicked();
        break;
    }
  }

  /** core functionality */

  /** page specific logic */
  handleResetPasswordButtonClicked(): void {
    // const payload = { ...this.formGroup.value, token: this.token };
    const payload: ResetPasswordPayload = {
      ...this.formGroup.value[0],
      token: this.token,
    };

    this.busy = this.authService.resetPassword(payload).subscribe(
      (response: any) => {
        this.success = true;
        this.handleAuthSuccess(response);
      },
      (error: ZipBackendErrorResponse) => {
        const successStatus = 200;

        if (error.status === successStatus) {
          this.success = true;
          this.handleAuthSuccess({});
        } else {
          this.success = false;
          this.errorMessage = this.zipEndpointService.handleErrorMessages(
            error,
            'Failed to reset password! Please try again later',
          );
          console.error(error);
        }
      },
    );
  }

  handleForgotPasswordButtonClicked(): void {
    const payload: ForgotPasswordPayload = this.formGroup.value[0];
    const next: string = this.router.url.replace(
      'forgot-password',
      'reset-password',
    );

    this.busy = this.authService.sendForgotPasswordEmail(payload).subscribe(
      (response: any) => {
        this.success = true;
        this.handleAuthSuccess(response);
      },
      (error: any) => {
        window.scroll(0, 0);
        this.errorMessage = this.zipEndpointService.handleErrorMessages(
          error,
          'Something went wrong, please try again.',
        );
        console.error(error);
      },
    );
  }

  handleRegisterButtonClicked(ev): void {
    const timeout = 1000;

    if (!ev?.action || ev?.action === 'submit') {
      /*
       * ERHO: TODO: Finish creating payload. Check with Malka/Alex about how to get the following:
       *        email_address, phone_number, group_id, role, role_group_id, access_request_id
       */
      // This correctly handles adding fromEmail queryParam
      const nextUrlTree: UrlTree = this.router.parseUrl(this.router.url);

      nextUrlTree.queryParams.fromEmail = 'true';
      const nextUrl = this.urlSerializer.serialize(nextUrlTree);

      const registerPayload: any = {
        ...this.formGroup.value[0],
        token: this.route.snapshot.queryParams?.token,
      };

      this.busy = this.authService.register(registerPayload).subscribe(
        (response: any) => {
          window.scroll(0, 0);
          this.registerCompleted.emit(this.formGroup.value.email);

          this.success = true;

          this.handleAuthSuccess(response);
          this.router.navigate(['/authentication']);
        },
        (error: any) => {
          window.scroll(0, 0);
          this.errorMessage = this.zipEndpointService.handleErrorMessages(
            error,
            'Failed to register, please try again.',
          );
          console.error(error);
        },
      );
    } else if (ev?.action === 'next') {
      this.showAuthCard = false;
      setTimeout(() => {
        this.showAuthCard = true;
        this.activeCardIdx += 1;
        this.activeCard =
          this.config.authCardOptions.cardViews[this.activeCardIdx];
      }, timeout);
    }
  }

  handleLoginButtonClicked(): void {
    this.busy = this.authService
      .login(this.formGroup.controls[0].value)
      .subscribe(
        (response: any) => {
          this.errorMessage = null;
          // remove fromemail query param after login if exists
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              fromEmail: null,
            },
            queryParamsHandling: 'merge',
          });

          this.busy = new Promise<void>((resolve, reject) =>
            this.authService
              .getUser({})
              .then((app_user: any) =>
                this.configService
                  .initializeDBConfigWithLocalOverride(this.configService.app, {
                    setConfig: true,
                    makeAPICall: true,
                  })
                  .then(() => {
                    this.retrieveDataForPage(true);
                    this.authService.setLoggedInUser(app_user);
                    if (app_user) {
                      posthog.identify(app_user.id);
                    }

                    this.completeStep.emit();
                    this.success = true;
                    this.handleAuthSuccess(response);
                    resolve();
                  }),
              )
              .catch((error: ZipBackendErrorResponse) => {
                this.errorMessage = this.zipEndpointService.handleErrorMessages(
                  error,
                  defaultErrorMessages[this.config.page],
                );
                console.error(error);
                reject(error);
              }),
          );
        },
        (error: ZipBackendErrorResponse) => {
          this.errorMessage = this.zipEndpointService.handleErrorMessages(
            error,
            defaultErrorMessages[this.config.page],
          );
          console.error(error);
        },
      );
  }

  handleRequestAccessButtonClicked(event?: AssociatedTaxIds): void {
    this.showAuthCard = true;
    if (
      this.activeCard.index ===
      this.config?.authCardOptions?.cardViews.length - 2
    ) {
      if (event?.tax_ids) {
        if (this.enableReplacementUserWithTaxId) {
          this.verifyAssociatedTaxIds(event);
        } else {
          this.submitRequestAccess(event);
        }
      }
    } else {
      this.activeCard =
        this.config.authCardOptions?.cardViews[this.activeCard.index + 1];
      this.activeCardIdx += 1;
    }
  }

  verifyAssociatedTaxIds(verifyAssociatedTaxIds: AssociatedTaxIds): void {
    this.requestAccessDataService
      .verifyTaxIds(verifyAssociatedTaxIds.tax_ids)
      .subscribe({
        next: (associatedTaxIds: AssociatedTaxIds) => {
          this.verifyTaxIds = verifyAssociatedTaxIds.tax_ids;

          if (associatedTaxIds.updatedTaxIds?.length > 0) {
            this.showReplacementUsersModal = true;
            this.associatedTaxIds = associatedTaxIds.updatedTaxIds;
          } else {
            this.submitRequestAccess(verifyAssociatedTaxIds);
          }
        },
        error: (error: ZipBackendErrorResponse) => {
          this.success = false;
          this.errorMessage = this.zipEndpointService.handleErrorMessages(
            error,
            defaultErrorMessages[this.config.page],
          );
        },
      });
  }

  setReplaceTaxIdsPayload(): void {
    this.replaceTaxIdsPayload = this.verifyTaxIds?.map((taxId: string) => ({
      tax_id: taxId,
      replacement_requested: this.isReplacementRequested(taxId),
    }));
  }

  isReplacementRequested(taxId: string): boolean {
    return (
      this.selectedAssociatedTaxIds?.length > 0 &&
      this.selectedAssociatedTaxIds.some(
        (selectedAssociatedTaxId) => selectedAssociatedTaxId.value === taxId,
      )
    );
  }

  submitRequestAccess(event?: AssociatedTaxIds): void {
    if (this.enableReplacementUserWithTaxId) {
      this.setReplaceTaxIdsPayload();
    }

    const requestAccessPayload: RequestAccessPayload =
      this.payloadService.getRequestAccessPayload(
        this.formGroup.value,
        event,
        this.enableReplacementUserWithTaxId,
        this.replaceTaxIdsPayload,
      );

    this.requestAccessDataService
      .sendRequestAccess(requestAccessPayload)
      .subscribe({
        next: (response: RequestAccessResponse) => {
          this.success = true;
          this.activeCard =
            this.config.authCardOptions.cardViews[this.activeCard.index + 1];
          this.activeCardIdx += 1;
          this.errorMessage = [''];
        },
        error: (error: ZipBackendErrorResponse) => {
          this.success = false;
          this.errorMessage = this.zipEndpointService.handleErrorMessages(
            error,
            defaultErrorMessages[this.config.page],
          );
        },
      });
  }

  handleReplacementTaxIds(taxIds: Option[]): void {
    this.selectedAssociatedTaxIds = taxIds;
  }

  closeModel(): void {
    this.showReplacementUsersModal = false;
  }

  handleRegisterLinkClicked(event): void {
    switch (event.prop) {
      case 'login':
        this.navigateToAuthenticationRoute('login');
        break;
      case 'previousCard':
        const timeout = 1000;

        this.showAuthCard = false;
        setTimeout(() => {
          this.activeCard =
            this.config.authCardOptions.cardViews[this.activeCardIdx];
          this.showAuthCard = true;
        }, timeout);
        break;
    }
  }

  handleLoginLinkClicked(event): void {
    switch (event.prop) {
      case 'member-linking':
        this.router.navigate(['member-portal', 'member-linking']);
        break;
      case 'register':
        this.navigateToAuthenticationRoute('register');
        break;
      case 'password':
        this.navigateToAuthenticationRoute('forgot-password');
        break;
      case 'login':
        this.navigateToAuthenticationRoute('authentication');
        break;
      case 'request-access':
        this.navigateToRequestAccessRoute('request-access');
        break;
    }
  }

  async handleAcceptTermsConditionsButtonClicked(): Promise<void> {
    try {
      const response = await this.http
        .put('api/user/', { accepted_terms: 'true' })
        .pipe(mergeMap(() => this.authService.getUserFromApi()))
        .toPromise();

      this.retrieveDataForPage(true);
      this.authService.setLoggedInUser(response);
      this.completeStep.emit();
      this.success = true;
      this.handleAuthSuccess(response);
    } catch (error) {
      this.errorMessage = this.zipEndpointService.handleErrorMessages(
        error,
        'Something went wrong, please try again.',
      );
    }
  }

  /*
   * Also handles going back a card I guess...
   */
  handleRequestAccessLinkClicked(event): void {
    this.showAuthCard = false;
    setTimeout(() => {
      this.activeCard =
        this.config.authCardOptions.cardViews[this.activeCardIdx - 1];
      this.activeCardIdx -= 1;
      this.showAuthCard = true;
    });
  }

  /** page specific logic */

  /** utility functions */
  navigateToAuthenticationRoute(page): void {
    // let route = this.formatAuthenticationRoute(page);
    let route: string = page;

    if (this.route.snapshot.queryParams.id) {
      route = `${route}/${this.route.snapshot.queryParams.id}`;
    }
    this.router.navigate([route], { queryParamsHandling: 'merge' });
  }

  navigateToRequestAccessRoute(page): void {
    this.router.navigate(['/request-access']);
  }

  retrieveDataForPage(force = false): void {
    this.retrieveDataFromRoute(this.route);
    this.retrieveDataFromConfig(force);
  }

  retrieveDataFromConfig(force: boolean): void {
    const routeFromConfig = this.configService.getPageConfig(this.config.page);

    if (routeFromConfig) {
      const keys: string[] = Object.keys(routeFromConfig);

      keys.forEach((key: string) => {
        if (routeFromConfig[key] && (!this.config[key] || force)) {
          this.config[key] = routeFromConfig[key];
        }
      });
    }

    this.globalConfig = this.configService.getPageConfig(
      manageAccessRequestDetailConstant.global,
    );
  }

  retrieveDataFromRoute(route: ActivatedRoute): void {
    const data = route.snapshot.data;

    validConfigs.forEach((key: string) => {
      if (data[key] && !this.config[key]) this.config[key] = data[key];
    });
  }

  routeAfterSuccess(id = null): void {
    const routes = [this.config.routeAfterSuccessConfig.route];

    if (id) routes.push(String(id));

    this.router.navigate(routes);
  }

  handleRedirect(url: string): void {
    if (url.includes('http')) this.window.nativeWindow.location.assign(url);
    else this.router.navigate([url]);
  }

  handleAuthSuccess(response): void {
    if (this.authService.replayPath && this.authService.replayPath !== '/') {
      this.router.navigate([this.authService.replayPath]);

      this.authService.replayPath = null;
    } else if (this.config.routeAfterSuccessConfig) {
      if (this.config.routeAfterSuccessConfig.route) {
        this.routeAfterSuccess();
      }

      // this code is ran when register is completed on shopping
      if (this.config.routeAfterSuccessConfig.disableAfterSuccess) {
        // add the email to the success title
        this.config.authCardOptions.successTitle = stringBuilder(
          this.config.authCardOptions.successTitle,
          {
            email: this.formGroup.value.email,
          },
        );

        this.config.authCardOptions.form.controls =
          this.config.authCardOptions.form.controls.map(
            (formConfig: AllControlsConfiguration) => {
              formConfig.type = controlTypes.password;
              formConfig['canToggle'] = false;
              formConfig.isDisabled = true;

              return formConfig;
            },
          );
      }
    } else if (response['redirect_path'] && response['redirect_path'] !== '/') {
      this.handleRedirect(response['redirect_path']);
    }
  }

  setupFormControls(controls, idx: number): void {
    const tempFormGroup: UntypedFormGroup = new UntypedFormGroup({});

    controls.forEach((control: AllControlsConfiguration) => {
      this.createFormControl(control, idx, tempFormGroup);
      if (control.focus && control.focus === 'complex_password') {
        const passwordCtrl: AbstractControl = tempFormGroup.get('password');

        if (control.focusDisplay) {
          this.setPasswordSubscription(control, passwordCtrl);
        }
      }
    });
    this.formGroup.push(tempFormGroup);
  }

  createFormControl(config, idx: number, form): void {
    const control: AbstractControl = new UntypedFormControl('', []);
    const newFormControl = Object.assign(config, {
      control: control,
    });

    this.formControlService.addControlToFormGroup(form, newFormControl);
  }

  setPasswordSubscription(config, form: AbstractControl): void {
    form.valueChanges.subscribe((val: string) => {
      this.checkRuleValue(val, config);
    });
  }

  checkRuleValue(val: string, config): void {
    config.focusDisplay.rules.forEach(
      (rule: { value: unknown; type: string }) => {
        const regex = new RegExp(RuleRegEx[rule.type]);

        rule.value = regex.test(val);
      },
    );
  }

  /** utility functions */
}
