export const AdditionalDetailsFormControlProps = {
  effectiveDate: 'effective_date',
  notes: 'notes',
};

export const SiteLocationDetailsContextKeys = {
  changeRequestNotes: 'change_request_notes',
  changeRequestEffectiveDate: 'change_request_effective_date',
  facilityType: 'facility_type',
};
