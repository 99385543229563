import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpProgressEvent,
  HttpResponse,
} from '@angular/common/http';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormControlOptionConfiguration } from '@zipari/shared-ds-util-form';
import { APIService } from '@zipari/web-services';
import {
  FileUploadInputs,
  FormControlService,
  FormGroupComponent,
} from '@zipari/design-system';
import { getValue } from '@zipari/web-utils';
import { BehaviorSubject, Subscription, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { slideRight } from '../../animations';
import { objectToArray } from '../../utilities/object';
import { AttachmentMetaData } from '../../templates/messages/messages.model';

@Component({
  selector: 'add-or-edit-modal',
  templateUrl: './add-or-edit-modal.component.html',
  styleUrls: ['./add-or-edit-modal.component.scss'],

  animations: [slideRight],
})
export class AddOrEditModalComponent
  implements OnInit, OnChanges, AfterViewChecked
{
  @Input() successResponse;
  @Input() errorResponse;
  @Input() viewProfile;
  @Input() addRowForm = new UntypedFormGroup({});
  @Input() openAccordionIdx: number;
  @Input() data: any = {};
  @Input() mainConfig: any;
  @ViewChild(FormGroupComponent) formGroup: FormGroupComponent;

  @Output() formBuilt = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() saveSuccess = new EventEmitter();
  @Output() continue = new EventEmitter();
  @Output() fileMetaData = new EventEmitter();
  modalType: string;
  hasConfirmModal = false;
  activeWorkflow: any;
  busy: Subscription;
  activeWorkflowIdx: number;
  selectedRows: any[];
  requiredSelectedRowSelected: boolean;
  selectedRadio: any = null;
  calls$ = new BehaviorSubject<boolean>(false);
  hasDropdown = false;
  loaded = false;
  notification: any = {
    messageHeading: 'Error',
    message: 'congfig2',
    type: 'error',
    actionIcon: 'close',
  };
  calls: any = new Array<any>();
  error = false;
  removeAttachment$ = new Subject<number>();
  _config: any;
  attributes;
  formReady: boolean;
  dropdownReady = false;
  view: 'form' | 'success' | 'error' = 'form';
  controls: any[] = [];

  get currentSelectedRows(): any {
    return this.selectedRows;
  }

  // Decorated set definitions
  @Input() set currentSelectedRows(selectedRows: any) {
    if (selectedRows) this.selectedRows = selectedRows;
  }

  get config(): any {
    return this._config;
  }

  @Input() set config(config: any) {
    this._config = config;
    if (config?.cancelConfirm) this.hasConfirmModal = true;
  }
  @Input() set errorMesssage(message: any) {
    if (message) {
      this.notification.message = message;
      this.error = true;
    }
  }

  constructor(
    private api: APIService,
    private cdr: ChangeDetectorRef,
    private formControlService: FormControlService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.buildWorkflow();
  }

  ngOnChanges(): void {
    if (this.successResponse) {
      // ERHO: TODO: do this properly
      if (this.config.confirmOnly) {
        this.view = 'success';
      }
      // add the option to exit directly on click of the 'save' button
      if (this.config.saveConfirm) {
        this.view = 'success';
        this.setAttributes();
      } else {
        // avoid emitting in change detection cycle
        setTimeout(() => {
          this.cancel.emit();
        });
      }
    } else if (this.errorResponse) {
      this.view = 'error';
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  handleSelectedRows(rows): void {
    this.selectedRows = rows;
  }

  selectedRadioRow(row): void {
    /**
     * ERHo: Set is_primary for row.
     * This is bad... Needs Refactor
     **/
    if (Array.isArray(this.selectedRows)) {
      this.selectedRows.map((selectedRow) => {
        selectedRow.row.is_primary = false;
      });
    }
    row['is_primary'] = true;
    this.selectedRadio = row;
    this.requiredSelectedRowSelected = true;
  }

  buildWorkflow(): void {
    this.config.workflow = objectToArray(this.config.workflow);
    this.activeWorkflowIdx = 0;
    this.activeWorkflow = this.config.workflow[this.activeWorkflowIdx];
    this.config.workflow.map((flow: any) => {
      if (flow.form?.groups) this.buildRows(flow);
    });
  }

  buildRows(target: any): void {
    target.form.groups = objectToArray(target.form.groups);

    target.form.groups.map((row: any, index) => {
      if (row.sections) {
        row.sections = objectToArray(row.sections);
        row.sections.map((section: any) => {
          if (section.controls) {
            section.controls = objectToArray(section.controls);
            this.buildAddRowForm(section.controls);
            this.controls[index] = section.controls;
          }
          // flyout table filter add from url
          if (section?.table?.filters) {
            const filters: any[] = section?.table?.filters;
            let hasNoEndpoints = true;

            for (const filter of filters) {
              if (filter.type === 'select' && filter.apiEndpoint) {
                hasNoEndpoints = false;
                this.busy = this.api
                  .get(filter.apiEndpoint)
                  .subscribe((resp: any) => {
                    filter.options = this.populateDropdown(
                      resp,
                      filter.displayProp,
                      filter.jsonValue,
                    );
                    this.loaded = true;
                  });
              }
            }
            if (hasNoEndpoints) this.loaded = true;
          }
        });
      }
    });
    if (!this.hasDropdown) this.dropdownReady = true;
    this.formReady = true;
  }

  // flatten attributes from config for display
  setAttributes() {
    const attributes = Array.isArray(this.config?.attributes)
      ? this.config?.attributes
      : this.config?.attributes?.attributes;

    this.attributes = attributes.reduce((array, attr) => {
      const title = attr.title;
      const value = getValue(this.successResponse, attr.prop);

      if (title || value) {
        array.push({ title, value, label: attr.label });
      }

      return array;
    }, []);
  }

  bannerClicked(event: any) {
    this.error = false;
  }

  buildAddRowForm(target) {
    // Need to make all the api calls first before adding them to control groups
    // It is a race condition, the control gets added before call finishes.
    // We have to make all the calls, wait for all the responses then add them to control group, when calls are made
    target.map((item, index) => {
      if (item.apiEndpoint && item.labelProp) {
        this.calls[index] = false;
        this.hasDropdown = true;
        this.api.get(item.apiEndpoint).subscribe((resp: any) => {
          if (item.type === 'dropdown') {
            item.options = this.populateDropdown(
              resp,
              item.labelProp,
              item.valueProp,
            );
            this.calls[index] = true; // call completed
            this.calls$.next(true);
          }
        });
      }
    });
    this.calls$.subscribe((value: any) => {
      if (value && this.calls.every(Boolean)) {
        this.mapForm(target);
        this.dropdownReady = true;
        this.formBuilt.emit();
      }
    });
    if (this.calls.length === 0) {
      this.mapForm(target);
      this.formBuilt.emit();
    }
  }

  mapForm(target: any) {
    target.map((item: any) => {
      if (item.defaultProp) {
        const defaultValue = getValue(this.data, item.defaultProp);

        item['default'] = defaultValue;
      }
      if (item.defaultStartProp) {
        const defaultStartValue = getValue(this.data, item.defaultStartProp);

        item['start'] = defaultStartValue;
      }
      if (item.defaultEndProp) {
        const defaultEndValue = getValue(this.data, item.defaultEndProp);

        item['end'] = defaultEndValue;
      }
      this.formControlService.addControlToFormGroup(this.addRowForm, item);
    });
  }

  populateDropdown(
    resp: any,
    labelProp: string,
    valueProp: string,
  ): FormControlOptionConfiguration[] {
    const options: FormControlOptionConfiguration[] = [];

    resp.results.forEach((record: any, index: number) => {
      if (valueProp) {
        options.push({
          label: record[labelProp],
          value: record[valueProp],
        });
      } else {
        options.push({
          label: record[labelProp],
          value: record,
        });
      }
    });

    return options;
  }

  handleBtnClickWithDisable(config: any, disableFlag: boolean) {
    if (disableFlag) return;
    this.handleBtnClick(config);
  }

  handleBtnClick(config): void {
    switch (config.action) {
      case 'cancelModal':
        this.cancel.emit();
        break;
      case 'nextWorkflowStep':
        if (this.addRowForm.valid) {
          this.activeWorkflowIdx += 1;
          this.activeWorkflow = this.config.workflow[this.activeWorkflowIdx];
        } else {
          const controls = this.addRowForm.controls;

          Object.keys(controls).forEach((c) => {
            controls[c].markAsTouched();
          });
        }
        break;
      case 'submit':
        this.onSave(config);
        break;
      case 'selectPropForJSONSubmission':
        this.onSelect();
        break;
      case 'navToInternal':
        this.router.navigate(['provider-portal/messages']);
        break;
      default:
        break;
    }
  }

  @HostListener('document:keydown.escape')
  onCancel() {
    this.cancel.emit();
  }

  // if form !valid highlight required/invalid fields
  onSave(config?): void {
    if (config.endpoint) {
      this.formReady = false;
      this.api.post(config.endpoint, this.addRowForm.value).subscribe(
        (resp: any) => {
          this.view = 'success';
          this.activeWorkflowIdx += 1;
          this.activeWorkflow = this.config.workflow[this.activeWorkflowIdx];
          this.formReady = true;
          this.saveSuccess.emit({
            key: this.activeWorkflow.saveKey,
            selectedItems: this.selectedRows,
            formGroup: this.addRowForm.value,
          });
        },
        (error: any) => {
          this.formReady = true;
          this.view = 'error';
        },
      );
    }

    if (this.addRowForm.valid) {
      this.save.emit({
        key: this.activeWorkflow.saveKey,
        selectedItems: this.selectedRows,
        formGroup: this.addRowForm.value,
      });
    } else {
      const controls = this.addRowForm.controls;

      Object.keys(controls).forEach((c) => {
        controls[c].markAsTouched();
      });
    }
  }

  onSelect() {
    this.save.emit({
      prop: this.config.prop,
      selectedRows: this.selectedRows,
      validation: this.activeWorkflow.form.validation,
      selectedRadio: this.selectedRadio,
    });
  }

  onContinue() {
    this.continue.emit(this.successResponse.id);
  }

  onRetry() {
    this.errorResponse = null;
    this.view = 'form';
  }

  remove(index: number) {
    if (index > -1) {
      this.selectedRows.splice(index, 1);
    }
  }

  /*
    ERHO: when data changed. Need this for now to force change detection
     */
  dataChanged(ev): void {
    // console.log('data change event', ev);
  }

  getObjectProperty(obj, key) {
    return key.split('.').reduce(function (result, prop) {
      return result[prop];
    }, obj);
  }

  onFileUploaded(event: { config: object; event: File[] }): void {
    let postSub: Subscription;

    // eslint-disable-next-line prefer-const
    postSub = this.api
      .post(
        this.attachmentFormControl()?.endpoint ||
          `/api/provider-portal/documents/`,
        this.initFileFormData(event),
        {
          reportProgress: true,
          observe: 'events',
        },
      )
      .subscribe(
        (response: HttpEvent<AttachmentMetaData>) => {
          this.listenToRemoveAttachment$DuringPost(postSub, event);

          if (response.type === HttpEventType.UploadProgress) {
            this.handlePostProgress(response);
          }

          if (response instanceof HttpResponse) {
            this.handlePostComplete(response);
          }
        },
        (error: HttpErrorResponse) => {
          this.handlePostError(error);
        },
      );
  }

  initFileFormData(event: { config: object; event: File[] }): FormData {
    const file = event.event[event.event.length - 1];
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('file_name', file.name);
    formData.append('file_type', file.type);
    formData.append('document_category', this.config.fileCategory || file.name);
    formData.append('document_name', file.name);

    return formData;
  }

  listenToRemoveAttachment$DuringPost(
    postSubscription: Subscription,
    event: { config: object; event: File[] },
  ) {
    this.removeAttachment$.pipe(first()).subscribe((removeIndex: number) => {
      if (removeIndex === event.event.length - 1) {
        postSubscription?.unsubscribe();
        this.enableFileUploadIfBelowMaximum();
        if (event.event.length > 1) {
          /**
           * Removing the 2nd or 3rd attachment during the upload process will
           * overwrite other attachment’s progress bar number.
           * JWANG TODO: modify file uploader so each file should have its own progress bar number,
           * no more progress conflicts/overwriting.
           */
          this.formGroup.fileUploadInputs.progress = 100;
        }
      }
    });
  }

  handlePostProgress(response: HttpProgressEvent): void {
    if (!this.formGroup.fileUploadInputs) {
      (this.formGroup.fileUploadInputs as Partial<FileUploadInputs>) = {};
    }
    this.formGroup.fileUploadInputs.progress = (
      (response.loaded / response.total) *
      100
    ).toFixed(0);
    this.formGroup.fileUploadInputs.uploadErrorMessage = '';
    this.formGroup.fileUploadInputs.disabled = false;
    this.formGroup.fileUploadInputs.showCloseIcon = true;
  }

  handlePostComplete(response: HttpResponse<AttachmentMetaData>): void {
    if (!this.addRowForm.get('attachment_ids')?.value) {
      this.addRowForm.get('attachment_ids')?.setValue([]);
    }
    const attachmentFormValue = this.addRowForm.get('attachment_ids')?.value;

    attachmentFormValue.push(response.body?.id);
    this.addRowForm.get('attachment_ids')?.setValue(attachmentFormValue);
    this.enableFileUploadIfBelowMaximum();
    if (this.formGroup?.fileUploadInputs?.showCloseIcon) {
      this.formGroup.fileUploadInputs.showCloseIcon = true;
    }
    this.fileMetaData.emit(response.body);
  }

  handlePostError(response: HttpErrorResponse): void {
    if (!this.formGroup.fileUploadInputs) {
      (this.formGroup.fileUploadInputs as Partial<FileUploadInputs>) = {};
    }
    this.formGroup.fileUploadInputs.uploadErrorMessage =
      response.error?.errors?.[0]?.user_error || response.message;
    this.formGroup.fileUploadInputs.showCloseIcon = true;
    this.enableFileUploadIfBelowMaximum();
  }

  onFileRemoved(event: { config: object; event: number }): void {
    const attachmentFormValue: number[] =
      this.addRowForm.get('attachment_ids')?.value;

    this.api
      .delete(
        `/api/provider-portal/documents/${attachmentFormValue[event.event]}`,
      )
      .subscribe((resp: {}) => {});
    attachmentFormValue?.splice(event.event, 1);
    this.addRowForm.get('attachment_ids')?.setValue(attachmentFormValue);
    this.removeAttachment$.next(event.event);
  }

  onPendingAttachments(event: {
    config: object;
    event: UntypedFormArray;
  }): void {
    if (!this.formGroup.fileUploadInputs) {
      (this.formGroup.fileUploadInputs as Partial<FileUploadInputs>) = {};
    }
    this.formGroup.fileUploadInputs.fileAttachment = event.event;
  }

  removeAttachmentByIndex(removeIndex: number): void {
    this.formGroup.fileUploadInputs.fileAttachment.removeAt(removeIndex);
    this.removeAttachment$.next(removeIndex);
    this.enableFileUploadIfBelowMaximum();
  }

  enableFileUploadIfBelowMaximum(): void {
    if (
      this.formGroup.fileUploadInputs?.fileAttachment?.length <
      this.attachmentFormControl()?.fileAttachmentLimit
    ) {
      this.formGroup.fileUploadInputs.disabled = false;
    }
  }

  getDisabledButtonStatus(bool: boolean): boolean {
    return bool;
  }

  attachmentFormControl(): any {
    let attachmentFormControl;

    this.activeWorkflow.form?.groups?.find((group: any) =>
      group.sections?.find(
        (section: any) =>
          (attachmentFormControl = section.controls?.find(
            (control: any) => control.prop === 'attachment_ids',
          )),
      ),
    );

    return attachmentFormControl;
  }

  // refactored from template
  getButtonStatus() {
    // if table requires selection and there is no seleted value, return true to disable immediately
    if (
      this.activeWorkflow?.form?.footerButtons?.buttonRight
        ?.requiredTableSelection &&
      !this.requiredSelectedRowSelected
    ) {
      return true;
    }
    if (this.activeWorkflow?.form?.footerButtons?.buttonRight?.noFormRequired) {
      // i have no clue what this is but adding it to meet logical partity - seems to be used in MSG Center
      // if the form is not required, return false to enable button???
      return false;
    }
    if (!this.setDisabledValidations(this.addRowForm)) return true; // if the form is not valid, return true immediately;

    return false;
  }

  setDisabledValidations(control: any): boolean {
    if (!control.valid) {
      let returnBool = true;
      const controls = control.controls;

      if (controls) {
        Object.keys(controls).forEach((c) => {
          if (!controls[c].valid) {
            if (!controls[c].pristine) controls[c].markAsTouched();
            returnBool = false;
          }
        });
      }

      return returnBool;
    }

    return true;
  }
}
