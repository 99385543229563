import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { messagesEndpoints, messagesBaseUrl } from './message/message.constant';
@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  public unreadCount$ = new Subject<number>();

  constructor(private http: HttpClient) {}

  bulkArchive(messageIds: { ids: string[] }): Observable<object> {
    return this.http.post(messagesEndpoints.bulkArchiveUrl, messageIds);
  }

  bulkUnarchive(messageIds: { ids: string[] }): Observable<object> {
    return this.http.post(messagesEndpoints.bulkUnarchiveUrl, messageIds);
  }

  unreadCount(): Observable<object> {
    return this.http.get(messagesEndpoints.unreadCount).pipe(
      tap((payload: { unread_count: number }) => {
        this.unreadCount$.next(payload?.unread_count);
      }),
    );
  }
}
