import {
  defaultAddDocumentationButton,
  defaultFileUpload,
} from '../constants/file-uploader.constant';
import { UploadDocumentConfig } from '../models/shared/FileUploader.model';

export const getDefaultFileUploaderConfig = (
  uploadDocumentConfig: UploadDocumentConfig,
): UploadDocumentConfig =>
  uploadDocumentConfig.addDocumentationButton && uploadDocumentConfig.fileUpload
    ? uploadDocumentConfig
    : {
        ...uploadDocumentConfig,
        addDocumentationButton: defaultAddDocumentationButton,
        fileUpload: defaultFileUpload,
      };
