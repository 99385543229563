/* eslint-disable no-shadow */
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { cloneObject, getValue, stringBuilder } from '@zipari/web-utils';

export const appDisplayNames = {
  zshop: 'Shopping Portal',
  'employer-portal': 'Employer Portal',
  'member-portal': 'Member Portal',
  'provider-portal': 'provider Portal',
  'broker-portal': 'Broker Portal',
};

export enum formatTypes {
  SUCCESS_ERROR = 'SUCCESS_ERROR',
  FULL_NAME = 'FULL_NAME',
  ADDRESS = 'ADDRESS',
  CURRENCY = 'CURRENCY',
  DATE = 'DATE',
  LINK = 'LINK',
  LIST = 'LIST',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  STATUS = 'STATUS',
  LICENSE_ACTIVE = 'LICENSE_ACTIVE',
  SNAKE_CASE_TO_TITLE = 'SNAKE_CASE_TO_TITLE',
  ID = 'ID',
  ACTIONS = 'ACTIONS',
  PERCENT = 'PERCENT',
  YES_NO = 'YES_NO',
  CREDIT_CARD = 'CREDIT_CARD',
  BANK_OR_CHECK = 'BANK_OR_CHECK',
  PAYMENT_FREQUENCY = 'PAYMENT_FREQUENCY',
  TAXID = 'TAXID',
  APP_DISPLAY_NAME = 'APP_DISPLAY_NAME',
  SSN = 'SSN',
}

export const defaultCurrencyOptions = {
  locale: 'en-US',
  digitsInfo: '1.2-2',
  currencyCode: 'USD',
  display: 'symbol',
};

export const defaultDateOptions = {
  format: 'MM/dd/yyyy',
};

@Injectable({
  providedIn: 'root',
})
export class FormattingService {
  constructor(
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
  ) {}

  restructureValueBasedOnFormat(initialValue, config) {
    let value;

    switch (config.format) {
      case formatTypes.APP_DISPLAY_NAME:
        const displayNameMapping = cloneObject(appDisplayNames);

        if (!initialValue || typeof initialValue !== 'string') {
          return '';
        }

        // if a different mapping is provided then use that over the default display name mapping
        if (config.formatOptions) {
          Object.assign(displayNameMapping, config.formatOptions);
        }

        value = displayNameMapping[initialValue] || initialValue;
        break;
      case formatTypes.PERCENT:
        if (
          typeof initialValue === 'string' &&
          initialValue.indexOf('%') >= 0
        ) {
          value = initialValue;
          break;
        }

        if (!initialValue || typeof initialValue !== 'string') {
          return '';
        }

        value = `${initialValue}%`;
        break;
      case formatTypes.ID:
        if (
          typeof initialValue === 'string' &&
          initialValue.indexOf('#') >= 0
        ) {
          value = initialValue;
          break;
        }

        if (!initialValue || typeof initialValue !== 'string') {
          return '';
        }

        value = `#${initialValue}`;
        break;
      case formatTypes.SNAKE_CASE_TO_TITLE:
        if (!initialValue || typeof initialValue !== 'string') {
          return '';
        }

        value = initialValue
          .split('_')
          .map(function (item) {
            return item.charAt(0).toUpperCase() + item.substring(1);
          })
          .join(' ');
        break;
      case formatTypes.FULL_NAME:
        if (typeof initialValue !== 'object') {
          value = initialValue;
        } else {
          if (config.formatOptions) {
            value = stringBuilder(config.formatOptions.template, initialValue);
          } else {
            value = `${initialValue.first_name || ''} ${
              initialValue.last_name || ''
            }`;
          }

          value = value.trim();
        }
        break;
      case formatTypes.ADDRESS:
        if (typeof initialValue !== 'object') {
          return initialValue;
        }

        if (config.formatOptions) {
          value = stringBuilder(config.formatOptions.template, initialValue);
        } else {
          // attempt to collect values from address given
          initialValue.address1 =
            initialValue.address1 || initialValue.street_name_1 || '';
          initialValue.address2 =
            initialValue.address2 || initialValue.street_name_2 || '';

          // added for extra formatting of address
          if (initialValue.address2) {
            initialValue.address2 = ` ${initialValue.address2}`;
          }
          initialValue.city = initialValue.city || initialValue.city_name || '';
          initialValue.county =
            initialValue.county_name ||
            initialValue.county_display ||
            initialValue.county ||
            '';
          initialValue.state =
            initialValue.state_name ||
            initialValue.state ||
            initialValue.state_code ||
            '';
          initialValue.zip =
            initialValue.zip ||
            initialValue.zip_code ||
            initialValue.zipcode ||
            '';

          // just in case county is an object make sure to try to grab the name
          if (typeof initialValue.county === 'object') {
            initialValue.county =
              initialValue.county.county ||
              initialValue.county.county_name ||
              initialValue.county.county_display ||
              '';
          }

          if (initialValue.county) {
            value = stringBuilder(
              '${address1}${address2} ${city} ${county}, ${state} ${zip}',
              initialValue,
            );
          } else {
            value = stringBuilder(
              '${address1}${address2} ${city}, ${state} ${zip}',
              initialValue,
            );
          }
        }
        break;
      case formatTypes.CURRENCY:
        if (
          typeof initialValue === 'string' &&
          initialValue.indexOf('$') >= 0
        ) {
          value = initialValue;
        } else {
          if (typeof initialValue === 'string') {
            try {
              initialValue = Number.parseFloat(initialValue);
            } catch (err) {
              throw new Error('Currency must be a number');
            }
          }

          value = this.currencyPipe.transform(
            initialValue,
            config.formatOptions && config.formatOptions.currencyCode
              ? config.formatOptions.currencyCode
              : defaultCurrencyOptions.currencyCode,
            config.formatOptions && config.formatOptions.display
              ? config.formatOptions.display
              : defaultCurrencyOptions.display,
            config.formatOptions && config.formatOptions.digitsInfo
              ? config.formatOptions.digitsInfo
              : defaultCurrencyOptions.digitsInfo,
            config.formatOptions && config.formatOptions.locale
              ? config.formatOptions.locale
              : defaultCurrencyOptions.locale,
          );
        }

        break;
      case formatTypes.DATE:
        if (!initialValue || initialValue === 'null') {
          value = '';
        } else {
          value = this.datePipe.transform(
            initialValue,
            config.formatOptions && config.formatOptions.format
              ? config.formatOptions.format
              : defaultDateOptions.format,
          );
        }
        break;
      case formatTypes.LIST:
        const mapping = (config.formatOptions || {}).mapping;

        if (mapping) {
          value = initialValue.map((item: any) => getValue(item, mapping));
        } else {
          value = initialValue;
        }
        break;
      case formatTypes.YES_NO:
        if (typeof initialValue === 'boolean') {
          value = initialValue ? 'Yes' : 'No';
        } else if (
          typeof initialValue === 'string' &&
          (initialValue === 'true' || initialValue === 'false')
        ) {
          value = initialValue === 'true' ? 'Yes' : 'No';
        } else if (initialValue === '') {
          value = 'No';
        } else {
          value = initialValue;
        }
        break;
      case formatTypes.LICENSE_ACTIVE:
        value =
          initialValue === 'a' || initialValue === 'A' ? 'Active' : 'Inactive';
        break;
      case formatTypes.STATUS:
        const statusProp =
          config.formatOptions && config.formatOptions.statusProp
            ? config.formatOptions.statusProp
            : 'is_active';

        value = getValue(initialValue, statusProp) ? 'Active' : 'Inactive';
        break;
      case formatTypes.BANK_OR_CHECK:
        if (typeof initialValue === 'string') {
          value =
            initialValue === 'bank' || initialValue === 'Automatic Bank Draft'
              ? 'Automatic Bank Draft'
              : 'Check/Money Order';
        }
        break;
      case formatTypes.PAYMENT_FREQUENCY:
        if (typeof initialValue === 'string') {
          value =
            initialValue === 'one' || initialValue === 'One Time'
              ? 'One Time'
              : initialValue === 'recurring'
                ? 'Recurring'
                : initialValue;
        }
        break;
      case formatTypes.PHONE:
        if (
          typeof initialValue === 'string' &&
          initialValue.indexOf('-') === -1
        ) {
          value = `${initialValue.substring(0, 3)}-${initialValue.substring(
            3,
            6,
          )}-${initialValue.substring(6, 10)}`;
        } else {
          value = initialValue;
        }
        break;
      case formatTypes.TAXID:
        if (
          typeof initialValue === 'string' &&
          initialValue.indexOf('-') === -1
        ) {
          value = `${initialValue.substring(0, 2)}-${initialValue.substring(
            2,
            9,
          )}`;
        } else {
          value = initialValue;
        }
        break;
      case formatTypes.CREDIT_CARD:
        if (typeof initialValue === 'number') {
          initialValue = initialValue.toString();
        }
        if (initialValue.indexOf('xxxx ') >= 0) {
          value = initialValue;
        } else {
          const displayedNum = initialValue.substr(-4);
          let hiddenNums = '';

          for (let i = 1; i < initialValue.length - 3; i++) {
            hiddenNums += 'x';
            if (i % 4 === 0 && i !== 0) {
              hiddenNums += ' ';
            }
          }

          value = `${hiddenNums} ${displayedNum}`;
        }

        break;
      case formatTypes.SSN:
        if (typeof initialValue === 'number') {
          initialValue = initialValue.toString();
        } else if (typeof initialValue !== 'string') {
          value = '';
          break;
        }

        if (initialValue.length >= 4) {
          value = `xxx-xx-${initialValue.substring(initialValue.length - 4)}`;
        } else {
          value = initialValue;
        }
        break;
      case formatTypes.LINK:
      case formatTypes.ACTIONS:
      default:
        value = initialValue;
        break;
    }

    if (value === null || value === undefined || value === 'null') {
      value = '';
    }

    return value;
  }

  formatConfigValueAsTemplate(config: any, context: any) {
    const newConfig = cloneObject(config);
    // get value for field

    if (newConfig.value && typeof newConfig.value === 'string') {
      if (newConfig.value.indexOf('getValue:') >= 0) {
        const prop = newConfig.value.split('getValue:')[1];

        newConfig.value = getValue(context, prop);
      } else {
        newConfig.value = stringBuilder(newConfig.value, context);
      }
    }
    // get value of conditional flag (show/hide field based on dependency)
    if (newConfig.conditional && newConfig.conditional.value) {
      if (newConfig.conditional.value.indexOf('getValue:') >= 0) {
        const prop = newConfig.conditional.value.split('getValue:')[1];

        newConfig.conditional.value = getValue(context, prop);
      } else {
        newConfig.conditional.value = stringBuilder(
          newConfig.conditional.value,
          context,
        );
      }
    }

    return newConfig;
  }
}
