import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ExpandingArticleConfig } from '../../../../../notes/expanding-article/expanding-article.model';
import {
  CellClickEvent,
  ColumnConfig,
  ConditionalAction,
  columnFormats,
} from '../../../models/column.model';
import { TableService } from '../../../services/table.service';
import { BusinessRulesService } from './../../../../../shared/services/business-rules.service';

@Component({
  selector: 'table-generic-cell',
  templateUrl: './generic-cell.component.html',
  styleUrls: ['./generic-cell.component.scss'],
})
export class GenericCellComponent implements OnInit, OnChanges {
  @Output() cellClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() cellActionClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkboxChecked: EventEmitter<any> = new EventEmitter<any>();

  @Input() column: ColumnConfig;
  subscription: Subscription;

  @Input()
  set data(data) {
    // if the original format config was an array of formats, reinstate it to format the value
    if (this.column['originalFormat']) {
      this.column.format = this.column['originalFormat'];
    }

    this._cellValue = this.ztService.getValueIncludingFormat(data, this.column);

    // use the last format in an array of formats to render the cell
    if (Array.isArray(this.column.format)) {
      this.column['originalFormat'] = [...this.column.format];
      this.column.format = this.column.format.pop();
    }

    this.context = { col: this.column, val: this._cellValue, row: data };
  }

  context;
  _cellValue: any = '';
  cellFormats = columnFormats;
  showConditionalValue = false;
  radioChecked = false;
  public showConditionalAction = false;
  filteredActions: string[];
  expandingArticleConfig: ExpandingArticleConfig;
  constructor(
    public ztService: TableService,
    private businessRulesService: BusinessRulesService,
  ) {}

  ngOnInit() {
    this.subscription = this.ztService.selectedRadioRowSubject.subscribe(
      (row: any) => {
        if (row != this.context.row && this.radioChecked) {
          this.radioChecked = false;
        }
      },
    );

    if (this.column.format === columnFormats.FILTERED_ACTIONS) {
      this.filterConditionalActionsList();
    }

    this.expandingArticleConfig = {
      lineHeight: '36',
      expandText: 'Show More',
      shrinkText: 'Show Less',
      lines: 4,
    };

    if (this.ztService.preFillRadio) {
      if (
        this.ztService.preFillRadio.id === this.context.row.id &&
        this.ztService.preFillRadio.code === this.context.row.code
      ) {
        this.ztService.selectedRadioRowSubject.next(this.context.row);
        this.checkboxChecked.emit(this.context.row);
        this.radioChecked = !this.radioChecked;
      }
    }
  }

  /** Conditionally display actions (eg: filetypes) by parsing the array of objs and filtering against conditionalMatchProp */
  filterConditionalActionsList() {
    const arrayProp = this.column.conditionalArrayProp;
    const matchProp = this.column.conditionalMatchProp;
    this.filteredActions = this.context.col.actions.filter((action) =>
      this.context.row[arrayProp]?.find((item) =>
        item[matchProp]?.includes(action),
      ),
    );
  }

  ngOnChanges() {
    if (this.column.format === this.cellFormats.CONDITIONAL_ACTION) {
      if (this.column.conditions) {
        this.setShowConditionalAction();
      } else if (this.column.conditionalAction) {
        this.setShowConditionalValue();
      }
    }
  }

  public handleCellClicked(cellClickEvent: CellClickEvent): void {
    this.cellClicked.emit({
      context: cellClickEvent.context,
      action: cellClickEvent.action.icon || cellClickEvent.action,
    });
  }

  private setShowConditionalValue() {
    // If there are multiple properties for which conditions should be checked, this will check that all conditions are true
    // i.e. this is not an `or` check, will always be an `and` check
    if (Array.isArray(this.column.conditionalAction.conditionProp)) {
      let conditionBoolean = true;
      // for loop used instead of foreach so that a break can be applied when a condition is false
      for (
        let index = 0;
        index < this.column.conditionalAction.conditionProp.length;
        index++
      ) {
        const prop = this.column.conditionalAction.conditionProp[index];
        const condition = this.context.row[prop];
        const conditionValueIsIncluded =
          this.column.conditionalAction.conditionValues.includes(condition);
        conditionBoolean = conditionValueIsIncluded && conditionBoolean;
        if (!conditionBoolean) {
          // If any of the conditions are false, break and do not check further conditions
          break;
        }
      }
      this.showConditionalValue = conditionBoolean;
    } else {
      // If only one property needs to be checked
      const condition =
        this.context.row[this.column.conditionalAction.conditionProp];
      this.showConditionalValue =
        this.column.conditionalAction.conditionValues.includes(condition);
    }
  }

  private setShowConditionalAction() {
    this.showConditionalAction =
      this.businessRulesService.retrieveResultFromBusinessRule(
        this.column.conditions,
        this.context.row,
      );
  }

  get value() {
    if (this._cellValue === 'undefined' || this._cellValue === 'null') {
      return '';
    }

    return this._cellValue || '';
  }

  radio(event) {
    this.radioChecked = !this.radioChecked;
    if (event.target.checked) {
      this.ztService.selectedRadioRowSubject.next(this.context.row);
      this.checkboxChecked.emit(this.context.row);
    }
  }

  getCSSClasses(column) {
    let customCSSClass: string;
    if (column.customOptions) {
      customCSSClass = column.customOptions['customCSSClass'];
    }

    return {
      [customCSSClass]: customCSSClass,
    };
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
