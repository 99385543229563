/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ButtonConfig } from '@zipari/shared-ds-util-button';
import {
  controlTypes,
  formControlDirections,
} from '@zipari/shared-ds-util-form';
import {
  FormControlService,
  UpdateOnOptions,
} from '../../notes/form-control/form-control.service';
import { FormControlValidatorsService } from '../../notes/form-control/shared/validators/validators.service';
import { CustomEndpoint } from '../../notes/form-control/typeahead/control-typeahead.model';
import { fade, slideUp } from '../../shared/animations';
import { FormControlOptionsParams } from '../../shared/models';
import {
  BusinessRulesService,
  ValidActions,
} from '../../shared/services/business-rules.service';
import { formatGridColumns } from '../../shared/utils/css-grid';
import {
  cloneObject,
  cloneObjectIfNotExtensible,
  isInEnum,
} from '../../shared/utils/object';
import { FileUploadInputs } from './../file-upload/file-uploader.constants';
import { customFormElements } from './custom-form-element/custom-form-element.constants';
import { checkInputsForText } from '../../design-system.helper';

@Component({
  selector: 'form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss'],
  animations: [fade, slideUp],
})
export class FormGroupComponent implements OnInit, DoCheck, OnChanges {
  @Output() formCreated = new EventEmitter<any>(true);
  @Output() inputChanged = new EventEmitter<any>();
  @Output() linkClicked = new EventEmitter<any>();
  @Output() iconClicked = new EventEmitter<any>();
  @Output() iconRightClicked = new EventEmitter<any>();
  @Output() selected = new EventEmitter<any>();
  @Output() submitClicked = new EventEmitter<any>();
  @Output() clearClicked = new EventEmitter<any>();
  @Output() typeaheadClearClicked = new EventEmitter<any>();
  @Output() fileUploaded = new EventEmitter<any>();
  @Output() fileRemoved = new EventEmitter<any>();
  @Output() pendingAttachments = new EventEmitter<any>();

  /** allows you to override the updateOn option in reactive elements.
   * Documentation: https://angular.io/api/forms/AbstractControl#updateOn
   */
  @Input() overrideUpdateOn: UpdateOnOptions = UpdateOnOptions.change;

  @Input() context;
  @Input() form = new UntypedFormGroup({});
  @Input() registeredComponents;
  @Input() disableEndpointOptions: boolean;
  @Input() selectedOptions: any[];
  @Input() submit: ButtonConfig;
  @Input() clear: ButtonConfig;
  @Input() animate: boolean;
  @Input() customEndpoint: CustomEndpoint;
  @Input() formControlOptionsParams: FormControlOptionsParams[];
  @Input() rulesContext = {};
  @Input() cloneConfig = false;

  configurations: any[];
  directionVal;
  fileUploadInputs: FileUploadInputs;

  constructor(
    private cdr: ChangeDetectorRef,
    public businessRulesService: BusinessRulesService,
    public formControlService: FormControlService,
    public formControlValidatorsService: FormControlValidatorsService,
  ) {}

  public get configs() {
    return this.configurations;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('configs')
  public set configs(config: any[]) {
    if (Object.isExtensible(config)) {
      this.configurations = config;
    } else {
      this.configurations = cloneObject(config);
    }
  }

  public get direction() {
    return this.directionVal;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('direction')
  public set direction(direction) {
    if (isInEnum(formControlDirections, direction)) {
      this.directionVal = direction;
    }
  }

  public get formOptions() {
    return {
      context: this.context ? this.context : {},
      updateOn: this.overrideUpdateOn,
    };
  }

  ngOnInit() {
    if (!this.configs) {
      throw new Error('You must provide a config');
    }
    const [configs, form] = checkInputsForText(
      [this.configs, this.form],
      new UntypedFormGroup({}),
    );

    this.configs = configs as any[];
    this.form = form as UntypedFormGroup;

    // generates correct form structure from configs that were provided
    this.determineCustomElementAndAddFormElements(this.configs);

    // run conditionals to hide or show particular form controls based on inputs that can be configurable from the
    // BE admin console in the same structure that the BE business rules runs them
    this.handleConditionalsOnControls();

    // this is important to avoid issues with lifecycle hooks when waiting to use the forms
    this.handleFormCreated(this.configs);
  }

  ngOnChanges(change) {
    if (change['configs'] && this.cloneConfig) {
      this.configs = cloneObject(this.configs);
    }
  }

  handleFormCreated(event, config = null) {
    /* istanbul ignore next */
    if (config) {
      this.handleWhoseCoveredFormCreated(config);
    }

    this.formCreated.emit(event);
  }

  hideFormControls(value: any) {
    this.configs = this.configs.map((config) => {
      const conditional = config.conditional;

      // backwards compatibility to allow for all conditionals within forms to be the "HIDE" action
      if (
        (conditional && typeof conditional === 'object') ||
        (config.conditions && !config.actions)
      ) {
        config.actions = [{ name: ValidActions.HIDE }];
      }

      // Add the rules context from the input to the form values
      // to be used as context when handling the conditional
      const conditionalContext = {
        ...this.rulesContext,
        ...value,
      };

      return this.businessRulesService.handleConditional(
        config,
        conditionalContext,
        {
          ignoreConditionalCallback: (internalConfig) =>
            this.form.get(internalConfig.prop),
        },
      );
    });

    this.configs.forEach((config) => {
      // when hidden null out the value associated with the form control
      // Perform these checks for each of the date controls in the date range
      if (config.type === 'dateRange') {
        const topLevelControl: UntypedFormGroup = this.form.get(
          config.prop,
        ) as UntypedFormGroup;
        // when the form is not yet fully initiated, controls will be missing

        if (!topLevelControl?.controls) {
          return;
        }
        Object.keys(topLevelControl.controls).forEach((controlName) => {
          this.updateValidators(topLevelControl.controls[controlName], config);
        });
      } else {
        this.updateValidators(this.form.get(config.prop), config);
      }
    });
  }

  updateValidators(formEl: AbstractControl, config: any) {
    if (!formEl) return;
    if (config.hidden) {
      const defaultValue: any = config.type === 'checkbox' ? [] : null;

      if (config.type === 'formGroupArray') {
        const formArr = formEl as UntypedFormArray;

        formArr.controls.forEach((_, index) => {
          formArr.removeAt(index);
        });
      } else if (config.type === 'group') {
        // (Jackd) formEl in this case gets nulled out and there's nothing to reset if
        // formEl.reset gets executed in the next loop causing an error. Returning will allow us to safely exit out.
        return;
      } else {
        formEl.reset(defaultValue, { emitEvent: false });
      }
      formEl.setErrors(null);
      formEl['noErrors'] = true;
    } else if (config.required) {
      const currentValidatorsConfig = config.validators || [];
      const currentValidators =
        this.formControlValidatorsService.getFormGroupValidators(
          currentValidatorsConfig,
        );
      const requiredValidator =
        this.formControlValidatorsService.getFormControlValidator('required');

      formEl.setValidators([...currentValidators, requiredValidator]);
      formEl.updateValueAndValidity({ emitEvent: false });
    } else if (config.notRequired) {
      let currentValidatorsConfig = config.validators || [];

      currentValidatorsConfig = currentValidatorsConfig.filter(
        (validator) => validator !== 'required',
      );
      const currentValidators =
        this.formControlValidatorsService.getFormGroupValidators(
          currentValidatorsConfig,
        );

      formEl.setValidators(currentValidators);
      formEl.updateValueAndValidity({ emitEvent: false });
    } else if (config.useOverrideValidators) {
      config.originalValidators =
        config.originalValidators || cloneObject(config.validators);
      config.validators = config.overrideValidators || [];

      const newValidators =
        this.formControlValidatorsService.getFormControlValidators(
          config,
          this.form,
        );

      formEl.setValidators(newValidators);
      formEl.updateValueAndValidity({ emitEvent: false });
    } else if (!config.useOverrideValidators && config.originalValidators) {
      // set the validators to the original validators
      config.validators = config.originalValidators;

      const originalValidators =
        this.formControlValidatorsService.getFormControlValidators(
          config,
          this.form,
        );

      formEl.setValidators(originalValidators);
      formEl.updateValueAndValidity({ emitEvent: false });
    } else {
      formEl.updateValueAndValidity({ emitEvent: false });
      formEl['noErrors'] = false;
    }
  }

  getSelectedIndex(controlIndex): number {
    let index = 0;

    if (this.selectedOptions) {
      const control = this.selectedOptions.filter(
        (option) => option.control === controlIndex,
      );

      if (control.length > 0) {
        index = this.selectedOptions
          .filter((option) => option.control === controlIndex)
          .map((c) => c.index)[0];
      }
    }

    return index;
  }

  // Handles keeping errors from hidden controls from affecting form group validity
  // NOTE: Please don't keep using this ngDoCheck... although this particular change is fairly minimal... this can be
  // really dangerous if people aren't careful
  ngDoCheck(): void {
    if (this.form) {
      Object.keys(this.form.controls)
        .map((controlKey) => this.form.get(controlKey))
        .filter((control: UntypedFormControl) => control)
        .forEach((control: UntypedFormControl) => {
          if (control['noErrors']) {
            control.setErrors(null);
          }
        });
    }
  }

  public submitForm() {
    this.submitClicked.emit(this.form.value);
  }

  public clearForm() {
    this.clearClicked.emit();
    this.typeaheadClearClicked.emit();
  }

  public updateOptions(prop: string, options: any) {
    this.configs = this.configs.map((config) => {
      if (config.prop === prop) {
        return {
          ...config,
          options,
        };
      }

      return config;
    });

    this.cdr.detectChanges();
  }

  public formatGridColumns(columns) {
    return formatGridColumns(columns);
  }

  public handleIconRightClicked() {
    this.iconRightClicked.emit();
  }

  /** Sets up conditionals to check whether or not a particular control should be shown or hidden */
  private handleConditionalsOnControls() {
    this.hideFormControls(this.form.value);

    this.form.valueChanges.subscribe((value) => {
      this.hideFormControls(value);
      this.inputChanged.emit(value);
    });
  }

  private determineCustomElementAndAddFormElements(configs) {
    // This should be pure but we have use cases for needing to change the options allowed in the dropdown
    configs.forEach((controlConfig) => {
      if (isInEnum(controlTypes, controlConfig.type)) {
        this.handleDefaultFormElementLogic(controlConfig);
      } else {
        this.handleCustomFormElementLogic(controlConfig);
      }

      return controlConfig;
    });
  }

  private handleDefaultFormElementLogic(controlConfig) {
    // The "onlyIf" config here is used by at least shopping for a dynamic form
    if (!controlConfig.onlyIf && !controlConfig?.hideControl) {
      this.formControlService.addControlToFormGroup(
        this.form,
        controlConfig,
        this.formOptions,
      );
    }

    controlConfig.customFormElement = false;
  }

  /* istanbul ignore next */
  private handleCustomFormElementLogic(controlConfig) {
    controlConfig = cloneObjectIfNotExtensible(controlConfig);

    if (controlConfig.type === 'whoseCovered') {
      this.formControlService.addFormGroupToFormGroup(
        this.form,
        controlConfig,
        this.formOptions,
      );
    }

    if (
      controlConfig.type === customFormElements.inputNA ||
      controlConfig.type === customFormElements.formGroupArray ||
      controlConfig.type === customFormElements.richText
    ) {
      this.formControlService.addControlToFormGroup(
        this.form,
        controlConfig,
        this.formOptions,
      );
    }

    controlConfig.customFormElement = true;
  }

  /* istanbul ignore next */
  private handleWhoseCoveredFormCreated(config) {
    if (
      config.type === customFormElements.whoseCovered &&
      this.context[config.prop]
    ) {
      this.form.get(config.prop).patchValue(this.context[config.prop]);

      const subscriber = this.form.get('whoseCovered.subscriber');

      subscriber.patchValue(this.context.subscriber);

      const spouse = this.form.get('whoseCovered.spouse');

      if (spouse) {
        spouse.patchValue(this.context.spouse);
      }

      const deps = this.form.get('whoseCovered.dependents');

      if (deps) {
        this.context.dependents.forEach((_, ind) => {
          deps[ind].patchValue(this.context.dependents[ind]);
        });
      }

      this.form.get(config.prop).patchValue(this.context[config.prop]);
    }
  }
}
