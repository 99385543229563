import {
  UserEditFormValue,
  Group,
} from '../../../shared/models/shared/UserPayloadModels/UserEditModel.model';
import { OktaGroupPayload } from './managePortalUsersCreateUser.model';

export const getOktaGroupPayload = (
  allowMultipleGroup: boolean,
  payload: UserEditFormValue,
): OktaGroupPayload[] =>
  allowMultipleGroup
    ? payload?.multiple_group?.map((group: Group) => ({ id: group }))
    : [{ id: payload.group_id }];

export const getAuth0GroupPayload = (
  allowMultipleGroup: boolean,
  payload: UserEditFormValue,
): Group[] | string[] =>
  allowMultipleGroup ? payload.multiple_group : [`${payload.group_id}`];
