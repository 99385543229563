import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { AllControlsConfiguration } from '@zipari/shared-ds-util-form';
export enum AuthenticationLayouts {
  step = 'step',
  banner = 'banner',
}

export enum AuthenticationPages {
  login = 'login',
  register = 'register',
  forgotPassword = 'forgot-password',
  resetPassword = 'reset-password',
  requestAccess = 'request-access',
  previousCard = 'previousCard',
  termsAndConditions = 'terms-and-conditions',
}

export class StepOptions {}

export class BannerOptions {
  heroImg: string;
  header?: any;
  sections: any[];
  isCustomAligned?: boolean;
}

export class AuthCardFooterLinkOptions {
  label: string;
  prop: string;
}

export class AuthCardFooterOptions {
  link: AuthCardFooterLinkOptions;
  button: ButtonConfig;
}

export class AuthCardOptions {
  title: string;
  subtitle: string;
  cardViews: AuthCardViewConfig[];
  form?: {
    controls: AllControlsConfiguration[];
  };
  footer: AuthCardFooterOptions;
  successTitle?: string;
  successSubtitle?: string;
  page?: string;
  isCustomAligned?: boolean;
}

export class AuthCardViewConfig {
  cardName?: string;
  taxIdNotExistErrorMsg?: string;
  taxIdAssociatedWithProviderAdmin?: string;
  apiForTaxIdValidation?: string;
  maxAdminCountLimit?: number;
  maxErrorMsgLength?: number;
  form?: {
    controls: AllControlsConfiguration[];
  };
  footer: AuthCardFooterOptions;
  index?: number;
  type?: string;
  title?: string;
  subtitle?: string;
  textContent?: {
    content?: string;
  };
}

export class RouteAfterSuccessConfig {
  route?: string;
  id?: any;
  checkApplication?: boolean;
  disableAfterSuccess?: boolean;
}

export class AuthenticationConfig {
  authCardOptions?: AuthCardOptions;
  endpoint?: string;
  externalLoginUrl?: string;
  idp?: string;
  layout?: AuthenticationLayouts;
  layoutOptions?: StepOptions | BannerOptions;
  okta?: OktaConfig;
  page?: AuthenticationPages;
  routeAfterSuccessConfig?: RouteAfterSuccessConfig;
}

export class OktaConfig {
  authParams: AuthParamsConfig;
  baseUrl: string;
  clientId: string;
  i18n: any;
  redirectUri: string;
}

export class AuthParamsConfig {
  issuer: string;
  pkce: boolean;
  responseType: string[];
  scopes: string[];
}

export const validConfigs = [
  'page',
  'layout',
  'layoutOptions',
  'authCardOptions',
  'routeAfterSuccessConfig',
];

export const authenticationRoutes = {
  login: '${app}/login',
  register: '${app}/register',
  'forgot-password': '${app}/forgot-password',
  'reset-password': '${app}/reset-password',
};

export const defaultErrorMessages = {
  login: 'Failed to login, please try again',
};

export const AuthIdp = 'auth0';

export const externalUrl = 'https://www.google.com';

export const errorMessage = 'Something went wrong, please try again.';
// eslint-disable-next-line zipari-custom/no-mock-data-in-deliverable-code
export const requestAccessTaxIdsMock = { tax_ids: ['1234'] };

export const authenticationPageRoutes = {
  login: '/login',
  register: '/register',
  registration: '/registration',
  authentication: '/authentication',
  dashboard: '/dashboard',
  requestAccess: '/request-access',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-passowrd',
};

// eslint-disable-next-line zipari-custom/no-mock-data-in-deliverable-code
export const activeCardIdxMock = 3;
