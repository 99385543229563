import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableConfig } from '../../../models/table.model';
import { TableService } from '../../../services/table.service';
import { ColumnConfig } from './../../../models/column.model';

@Component({
  selector: 'table-generic-row',
  templateUrl: './generic-row.component.html',
  styleUrls: ['./generic-row.component.scss'],
})
export class GenericRowComponent implements OnInit {
  @Input() columns: ColumnConfig[];
  @Input() data: any;
  @Input() options: TableConfig;
  @Input() detailView;
  @Input() grouping;
  @Output() rowCellClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() actionClicked: EventEmitter<any> = new EventEmitter<any>();

  selected;

  constructor(public ztService: TableService) {}

  ngOnInit() {
    this.setSelected();
    this.ztService.selectedRowSubject.subscribe(() => {
      this.setSelected();
    });
  }

  setSelected() {
    this.selected =
      this.ztService.zipTableOptions.selection.enabled &&
      this.ztService.checkIfRowSelected(this.data);
  }

  handleClick() {
    if (
      this.ztService.zipTableOptions.selection.enabled &&
      !this.ztService.zipTableOptions.selection.multiple
    ) {
      this.rowClicked.emit();
    }
  }

  markRowAsSelected(event) {
    if (event.target.checked) {
      this.ztService.selectedRow = [
        ...this.ztService.selectedRow,
        ...[{ row: this.data }],
      ];
      this.rowCellClicked.emit(this.data);
    } else {
      const ind = this.ztService.findSelectedRowIndex(this.data);

      this.ztService.selectedRow = [
        ...this.ztService.selectedRow.slice(0, ind),
        ...this.ztService.selectedRow.slice(ind + 1),
      ];
    }

    this.ztService.selectedRowSubject.next(undefined);
  }

  setCheckboxCheckedForRadio(val) {
    if (val) {
      const ind = this.ztService.findSelectedRowIndex(val);

      if (ind === -1) {
        this.ztService.selectedRow = [
          ...this.ztService.selectedRow.filter((obj) => obj.row.id !== val.id),
          ...[{ row: val }],
        ];
        this.ztService.selectedRow.forEach((obj, indx) => {
          obj.ind = indx;
        });
        this.ztService.selectedRow.sort((ele1, ele2) =>
          ele1.row.index > ele2.row.index ? 1 : -1,
        );
        this.rowCellClicked.emit(val);
      }
    }

    this.ztService.selectedRowSubject.next(void 0);
  }

  getCSSClasses(column: ColumnConfig) {
    const customCSSClass = column.customOptions?.['customCSSClass'];

    return {
      [customCSSClass]: customCSSClass,
    };
  }
}
