import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalSiteWarningModalComponent } from './external-site-warning-modal.component';
import { ButtonModule } from '../../notes/button/button.module';
import { ModalModule } from '../../notes/modal/modal.module';
import { DigitalDataModule } from './../../shared/modules/digital-data/digital-data.module';

@NgModule({
  declarations: [ExternalSiteWarningModalComponent],
  exports: [ExternalSiteWarningModalComponent],
  imports: [CommonModule, ButtonModule, DigitalDataModule, ModalModule],
})
export class ExternalSiteWarningModule {}
