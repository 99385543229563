import { Component } from '@angular/core';

import { accordion, fade, slideLeft } from '../../../animations';

import { NavigationService } from '../navigation.service';

@Component({
  selector: 'mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  animations: [accordion, fade, slideLeft],
})
export class MobileMenuComponent {
  toolbarMenuOpen = false;

  constructor(public navigationService: NavigationService) {}
}
