import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ViewChildren, QueryList } from '@angular/core';
import { objectToArray } from '../../../../shared/utilities/object';
import { ProvisionerColumnComponent } from '../column/provisioner-column.component';
import { ProvisionerService } from './provisioner.service';

export interface SelectionEvent {
  column: any;
  row: any;
  param: any;
  data: any;
  formData: any;
}
@Component({
  styleUrls: ['./provisioner.component.scss'],
  selector: 'provisioner',
  templateUrl: './provisioner.component.html',
})
export class ProvisionerComponent implements OnInit, OnDestroy {
  @Input() config: any;
  @Input() bootStrapId: string;

  @ViewChildren('columns')
  provisionerColumns: QueryList<ProvisionerColumnComponent>;

  columns: any[];
  lastClickedColumn = -1;
  showFooter$ = new BehaviorSubject<boolean>(true);
  selected: any;
  component: ProvisionerColumnComponent;

  constructor(private provisioner: ProvisionerService) {}

  ngOnInit(): void {
    if (this.config) {
      this.columns = objectToArray(this.config.columns);
      this.provisioner.initClicked(new Array<any>(this.columns.length));
      this.config.columns = this.columns;
      this.provisioner.setConfig(this.config);
      this.columns.map((item: any) => {
        item.bootStrapId = this.bootStrapId;
      });
    }
  }

  cellSearched(column: number) {
    setTimeout(() => {
      // reset everyone else after
      for (let i = column + 1; i < this.columns.length; i++) {
        if (this.provisionerColumns.toArray()[i]) {
          this.provisioner.resetClicked(i);
          this.columns[i].bootStrapId = null;
          this.provisionerColumns.toArray()[i].clearData();
          this.provisionerColumns.toArray()[i].unclickColumn();
          this.provisionerColumns.toArray()[i].unselectColumn();
        }
      }
    });
  }

  cellClicked(event: SelectionEvent) {
    // asyncronously load hte next column
    setTimeout(() => {
      this.lastClickedColumn = event.column;
      // reset everyone else
      for (let i = event.column + 1; i < this.columns.length; i++) {
        if (this.provisionerColumns.toArray()[i]) {
          this.provisioner.resetClicked(i);
          this.columns[i].bootStrapId = null;
          this.provisionerColumns.toArray()[i].clearData();
          this.provisionerColumns.toArray()[i].unclickColumn();
          this.provisionerColumns.toArray()[i].unselectColumn();
        }
      }
      // unless we are on the last column
      if (!(this.columns.length - 1 === event.column)) {
        // boostrap the next column
        this.columns[
          Math.min(this.columns.length - 1, event.column + 1)
        ].bootStrapId = event.param;
      }
    });
  }

  cellSelected(event: SelectionEvent) {
    this.provisioner.addSelection(event);
    // load the next columns only if needed
    if (this.checkSelectionValue(event)) {
      this.provisioner.enableColumns(this.config, event.column);
    } else {
      for (
        let i = event.column + 1;
        i < this.provisionerColumns.toArray().length;
        i++
      ) {
        // this.provisioner.resetClicked(i);
        // this.provisionerColumns.toArray()[i].clearData();
        // this.provisionerColumns.toArray()[i].unclickColumn();
        this.provisionerColumns.toArray()[i].unselectColumn();
      }
      const election: any =
        event.formData[this.config.columns[event.column].onlyLoadWhen.prop];

      this.provisioner.disableColumns(event.column, election);
      this.provisioner.deleteAllChildren(event);
    }
  }

  checkSelectionValue(event) {
    if (this.config?.columns[event.column]?.onlyLoadWhen) {
      const election: any =
        event.formData[this.config.columns[event.column].onlyLoadWhen.prop];

      if (election === this.config.columns[event.column].onlyLoadWhen.value) {
        return true;
      }

      return false;
    }

    return true;
  }

  ngOnDestroy(): void {
    if (this.showFooter$) {
      this.showFooter$.unsubscribe();
    }
  }
}
