<form [zipBusy]="busy" [formGroup]="group">
  <div class="form-control__label__wrapper">
    <div class="left">
      <label class="form-control__label t-bold">
        <p class="t-data t-bold">{{ config?.label }}</p>
        <p
          *ngIf="config.validators && config?.label"
          class="t-data t-bold spaced-star"
        >
          {{ '*' }}
        </p>
      </label>
      <label class="form-control__label">
        <p [innerHTML]="config?.subheader" class="subheader"></p>
      </label>
    </div>
    <div
      *ngIf="group.get(config.prop).value"
      class="right isCursor bottom-line"
      (click)="remove()"
    >
      <p class="remove">Remove</p>
    </div>
  </div>

  <div *ngIf="group.get(config.prop).value">
    <div
      class="left"
      *ngIf="
        config.prop === 'billing_provider_location' &&
        getButtonValue === 'flyoutOnBillingProvider'
      "
    >
      <label
        *ngIf="
          config[getButtonValue].displayFields.name &&
          group.get(config.prop).value
        "
        class="form-control__label"
      >
        <p class="t-data t-bold">
          {{
            group.get(config.prop).value[
              config[getButtonValue].displayFields.name
            ]['full_name']
          }}
        </p>
      </label>
      <p
        *ngIf="showOutOfNetworkStatusPill"
        class="OutOfNetwork__StatusPill Pending"
      >
        {{ outOfNetwork?.outOfNetworkLabel }}
      </p>
    </div>

    <div
      class="left"
      *ngIf="
        config.prop === 'billing_provider_location' &&
        getButtonValue === 'flyoutOnBillingFacility'
      "
    >
      <label
        *ngIf="
          config[getButtonValue].displayFields.name &&
          group.get(config.prop)?.value
        "
        class="form-control__label"
      >
        <p class="t-data t-bold">
          {{
            (group.get(config.prop)?.value)[
              config[getButtonValue].displayFields.name
            ]
          }}
        </p>
      </label>
      <p
        *ngIf="showOutOfNetworkStatusPill"
        class="OutOfNetwork__StatusPill Pending"
      >
        {{ outOfNetwork?.outOfNetworkLabel }}
      </p>
    </div>

    <!-- load this from config -->
    <div
      class="right"
      *ngIf="
        config.prop === 'billing_provider_location' &&
        getButtonValue === 'flyoutOnBillingProvider'
      "
    >
      <label *ngIf="group.get(config.prop).value" class="form-control__label">
        <p class="spacing t-data t-bold">Address:</p>
        <p
          *ngIf="
            !(group.get(config.prop)?.value)[
              config[getButtonValue].displayFields.address
            ].length;
            else multilocation
          "
        >
          {{ noSpace }}
        </p>
        <ng-template #multilocation>
          <p *ngIf="!addressLocation?.length">{{ noSpace }}</p>
          <ng-container *ngFor="let multiLocation of addressLocation">
            <div
              class="address"
              *ngIf="
                multiLocation.address?.id === group.get('multi_location').value
              "
            >
              {{ multiLocation.address['street_name_1'] }}<br />
              {{ multiLocation.address['street_name_2'] }}<br />
              {{ multiLocation.address['city_name'] }},
              {{ multiLocation.address['state'] }}
              {{ multiLocation.address['zip_code'] }}
            </div>
          </ng-container>
        </ng-template>
      </label>
      <label
        *ngIf="
          config[getButtonValue].displayFields.group_npi &&
          group.get(config.prop).value
        "
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">Group NPI:</p>
        <p>
          {{
            group.get(config.prop).value[
              config[getButtonValue].displayFields.group_npi
            ] || noSpace
          }}
        </p>
      </label>
      <label
        *ngIf="
          config[getButtonValue].displayFields.id_number &&
          group.get(config.prop).value
        "
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">ID Number:</p>
        <p>
          {{
            group.get(config.prop).value[
              config[getButtonValue].displayFields.id_number
            ] || noSpace
          }}
        </p>
      </label>
      <label
        *ngIf="
          config[getButtonValue].displayFields.specialty &&
          group.get(config.prop).value
        "
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">Primary Specialty:</p>
        <p
          *ngIf="
            group.get(config.prop).value[
              config[getButtonValue].displayFields.specialty
            ]
          "
        >
          {{
            group.get(config.prop).value[
              config[getButtonValue].displayFields.specialty
            ][0]['name']
          }}
        </p>
        <p
          *ngIf="
            !group.get(config.prop).value[
              config[getButtonValue].displayFields.specialty
            ]
          "
        >
          {{ noSpace }}
        </p>
      </label>
      <label
        *ngIf="
          config[getButtonValue].displayFields.phone &&
          group.get(config.prop).value
        "
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">Phone Number:</p>
        <p>
          {{
            group.get(config.prop).value[
              config[getButtonValue].displayFields.phone
            ] || noSpace
          }}
        </p>
      </label>
    </div>

    <div
      class="right"
      *ngIf="
        config.prop === 'billing_provider_location' &&
        getButtonValue === 'flyoutOnBillingFacility'
      "
    >
      <label
        *ngIf="
          config[getButtonValue].displayFields.address &&
          group.get(config.prop)?.value
        "
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">Address:</p>
        <p
          *ngIf="
            !(group.get(config.prop)?.value)[
              config[getButtonValue].displayFields.address
            ]
          "
        >
          {{ noSpace }}
        </p>
        <div
          class="address"
          *ngIf="
            (group.get(config.prop)?.value)[
              config[getButtonValue].displayFields.address
            ]
          "
        >
          {{
            (group.get(config.prop)?.value)[
              config[getButtonValue].displayFields.address
            ]['street_name_1']
          }}<br />
          {{
            (group.get(config.prop)?.value)[
              config[getButtonValue].displayFields.address
            ]['street_name_2']
          }}<br />
          {{
            (group.get(config.prop)?.value)[
              config[getButtonValue].displayFields.address
            ]['city_name']
          }},
          {{
            (group.get(config.prop)?.value)[
              config[getButtonValue].displayFields.address
            ]['state']
          }}
          {{
            (group.get(config.prop)?.value)[
              config[getButtonValue].displayFields.address
            ]['zip_code']
          }}
        </div>
      </label>
      <label
        *ngIf="
          config[getButtonValue].displayFields.group_npi &&
          group.get(config.prop)?.value
        "
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">Group NPI:</p>
        <p>
          {{
            (group.get(config.prop)?.value)[
              config[getButtonValue].displayFields.group_npi
            ] || noSpace
          }}
        </p>
      </label>
      <label
        *ngIf="
          config[getButtonValue].displayFields.id_number &&
          group.get(config.prop)?.value
        "
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">ID Number:</p>
        <p>
          {{
            (group.get(config.prop)?.value)[
              config[getButtonValue].displayFields.id_number
            ] || noSpace
          }}
        </p>
      </label>
      <label
        *ngIf="
          config[getButtonValue].displayFields.specialty &&
          group.get(config.prop)?.value
        "
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">Primary Specialty:</p>
        <p
          *ngIf="
            (group.get(config.prop)?.value)[
              config[getButtonValue].displayFields.specialty
            ]?.length
          "
        >
          {{
            (group.get(config.prop)?.value)[
              config[getButtonValue].displayFields.specialty
            ][0]['name']
          }}
        </p>
        <p
          *ngIf="
            !(group.get(config.prop)?.value)[
              config[getButtonValue].displayFields.specialty
            ]?.length
          "
        >
          {{ noSpace }}
        </p>
      </label>
    </div>
  </div>
  <div *ngIf="!group.get(config.prop).value" class="left isCursor">
    <zip-button
      *ngIf="state === 'edit'"
      [config]="config?.billingProviderButton"
      (zipButtonClicked)="toggleProviderBillingPopup()"
    >
    </zip-button>
  </div>

  <div
    *ngIf="
      group.get(config.prop)?.invalid &&
      (group.get(config.prop)?.dirty || group.get(config.prop)?.touched)
    "
  >
    <div class="error" *ngIf="group.get(config.prop).errors">
      <icon
        name="warning"
        style="height: 1.125rem; padding-left: 1rem; padding-right: 0.5rem"
        ><i
          class="icon icon--font ng-star-inserted icon--small"
          style="color: red"
        >
          warning
        </i>
      </icon>
      <p class="t-caption t-error">
        {{ config.errorMessage || 'Please enter a valid input.' }}
      </p>
    </div>
  </div>
</form>

<!-- model popup to show provider/Facility button on popup  -->
<modal
  #errorFlyout
  *ngIf="showProviderBillingPopup"
  [config]="control?.providerBillingModal"
  [condition]="showProviderBillingPopup"
  (cancel)="toggleProviderBillingPopup()"
>
  <div body class="popup-container">
    <div>
      <zip-button
        *ngIf="state === 'edit'"
        [config]="control?.providerBillingModal.providerButton"
        (zipButtonClicked)="
          toggleProviderFlyout(control?.providerBillingModal.providerButton.id)
        "
      >
      </zip-button>
    </div>
    <div>
      <zip-button
        *ngIf="state === 'edit'"
        [config]="control?.providerBillingModal.locationButton"
        (zipButtonClicked)="
          toggleProviderFlyout(control?.providerBillingModal.locationButton.id)
        "
      >
      </zip-button>
    </div>
  </div>
</modal>

<modal
  #flyout
  *ngIf="showModal"
  [config]="config[getButtonValue]"
  [condition]="showModal"
  (cancel)="toggleFlyout()"
>
  <div body>
    <div class="Header-Associate">
      <p
        *ngIf="config[getButtonValue] && config[getButtonValue]['subheader']"
        [innerHTML]="config[getButtonValue]['subheader']"
      ></p>
    </div>
    <zip-table
      *ngIf="loaded"
      (selectedRadioRow)="selectedRadioRow($event)"
      [options]="config[getButtonValue].table"
    >
    </zip-table>
  </div>
  <div footer>
    <zip-button
      [disabled]="!selectedItem"
      level="high"
      [content]="config[getButtonValue]['submitButton']"
      (click)="addSelectedProvider()"
    ></zip-button>
  </div>
</modal>

<!-- model popup to show provider multiple location on popup  -->
<modal
  *ngIf="showMultiLocationPopup"
  [config]="control?.providerMultiLocationModal"
  [condition]="showMultiLocationPopup"
  class="OutOfNetworkModal"
>
  <div body>
    <div class="header-associate">
      <p
        *ngIf="
          control?.providerMultiLocationModal &&
          control?.providerMultiLocationModal.subheader
        "
      >
        {{ control?.providerMultiLocationModal.subheader }}
      </p>
    </div>
    <div class="multi-address">
      <form-control
        [group]="group"
        [config]="{
          prop: 'multi_location',
          type: 'radio',
          options: locationOptions,
        }"
        [control]="group.get('multi_location')"
      ></form-control>
    </div>
  </div>
  <div footer>
    <zip-button
      [disabled]="!selectedAddress"
      level="high"
      [content]="control.providerMultiLocationModal.submitButton"
      (click)="SelectAddress()"
    ></zip-button>
  </div>
</modal>

<modal
  *ngIf="outOfNetwork?.isOutOfNetworkEnabled && showOutOfNetworkModal"
  [config]="outOfNetwork?.outOfNetworkModal?.outOfNetworkModalBody"
  [condition]="outOfNetwork?.isOutOfNetworkEnabled && showOutOfNetworkModal"
  (cancel)="closeOutOfNetworkModal()"
  class="OutOfNetworkModal"
>
  <div body>
    <div class="header-associate">
      <p>
        {{ outOfNetwork.outOfNetworkModal?.outOfNetworkModalBody?.description }}
      </p>
    </div>
  </div>
  <div footer class="OutOfNetworkModal__Footer">
    <zip-button
      [config]="outOfNetwork.outOfNetworkModal?.footer?.cancelButton"
      (click)="closeOutOfNetworkModal()"
    ></zip-button>
    <zip-button
      [config]="outOfNetwork.outOfNetworkModal?.footer?.confirmButton"
      (click)="confirm()"
    ></zip-button>
  </div>
</modal>
