<ul *ngIf="children?.length">
  <li class="column-container" *ngFor="let child of children">
    <div
      [ngStyle]="{
        'min-width': 'calc(' + width + 'rem/' + numberOfColumns + ')',
      }"
      class="leftCell"
    >
      <div *ngIf="child?.selectionEvent">
        <div class="prop-list" *ngFor="let prop of display_field_prop_list">
          {{ (child?.selectionEvent?.data)[prop] }}
        </div>
        <div
          class="subheader"
          *ngFor="let field of child?.selectionEvent?.formData | keyvalue"
        >
          {{ form_fields_label_map[field.value] }}
        </div>
      </div>
      <div *ngIf="!child?.selectionEvent">
        <div class="prop-list" *ngFor="let prop of display_field_prop_list">
          {{ child[prop] }}
        </div>
        <div class="subheader" *ngIf="child.full_access">
          {{ form_fields_label_map['full_access'] }}
        </div>
        <div class="subheader" *ngIf="!child.full_access">
          {{ form_fields_label_map['managed'] }}
        </div>
      </div>
    </div>
    <row-item
      class="rightCell"
      [mode]="mode"
      [numberOfColumns]="numberOfColumns"
      [form_fields_label_map]="form_fields_label_map"
      [display_field_prop_list]="display_field_prop_list"
      [key]="key"
      [children]="child[key]"
    ></row-item>
  </li>
</ul>
