import { Component, Input, OnInit } from '@angular/core';

import { ProgressBar } from './progress-bar.model';
import { checkInputsForText } from '../../design-system.helper';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  @Input() data: ProgressBar;

  ngOnInit(): void {
    const [data] = checkInputsForText([this.data]);

    this.data = data;
  }
}
