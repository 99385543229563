import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlSerializer,
  UrlTree,
} from '@angular/router';

import { User } from '../../models/shared/User.model';
import { ConfigService } from '../../services';
import { AuthService } from '../../services/auth.service';
import { GUARDS } from '../guards.constant';
import { PayerAdminGuard } from '../payer-admin-guard/payer-admin-guard';
import { ProviderAdminGuard } from '../provider-admin-guard/provider-admin.guard';

@Injectable({
  providedIn: 'root',
})
export class MasterGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
    private configService: ConfigService,
    private urlSerializer: UrlSerializer,
  ) {}

  private next: ActivatedRouteSnapshot;
  private state: RouterStateSnapshot;

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    this.next = next;
    this.state = state;

    const config: any = this.configService.getPageConfig('global');
    const authConfig: any = this.configService.getPageConfig('auth');

    const user: User = await this.authorizeUser();

    if (!user) {
      if (
        config?.availableUnauthenticatedRoutes?.length > 0 &&
        config?.availableUnauthenticatedRoutes?.includes(this.state.url)
      ) {
        return true;
      } else {
        if (config.idp?.type === 'external') {
          window.open(config.idp.url, '_self');

          return false;
        }

        if (authConfig.idp === 'auth0') {
          window.open(authConfig.auth0.loginUrl, '_self');

          return false;
        }
      }

      /*
       * ERHO: TODO: handle this in a cleaner way
       */
      const urlTree: UrlTree = this.router.parseUrl(this.state.url);

      if (
        this.state.url === '/authentication' ||
        this.state.url === '/registration' ||
        this.state.url ===
          `/registration?token=${this.next.queryParams.token}` ||
        this.state.url ===
          `/registration/?token=${this.next.queryParams.token}` ||
        this.state.url ===
          `/reset-password?token=${this.next.queryParams.token}` ||
        this.state.url ===
          `/reset-password/?token=${this.next.queryParams.token}` ||
        this.state.url === '/forgot-password' ||
        this.state.url === '/reset-password'
      ) {
        return true;
      } else if (
        this.state.url.includes('dashboard') ||
        this.state.url.includes('/authentication')
      ) {
        this.router.navigate(['/authentication'], {
          queryParams: this.next.queryParams,
        });
      } else {
        await this.router.navigate(['authentication'], {
          queryParams: this.next.queryParams,
        });
      }
    } else if (user) {
      if (this.state.url.includes('authentication')) {
        await this.router.navigate(['/dashboard']);
      } else if (this.state.url === '/request-access') {
        await this.router.navigate(['/provider-portal/dashboard']);
      }
    }

    if (!next.data) {
      Promise.resolve(true);

      return;
    }

    /** array of strings denoting available guards to be used **/
    if (!next.data.trigger.guards || !next.data.trigger.guards.length) {
      Promise.resolve(true);

      return;
    }

    return this.executeGuards();
  }

  async authorizeUser(): Promise<any> {
    if (this.authService.loggedInUser) return true;
    else {
      return this.authService.getUser({}).then(async (app_user: User) => {
        if (app_user) this.authService.setLoggedInUser(app_user);

        return app_user;
      });
    }
  }

  /** Checks guard index for target guard to be used **/
  /** Will raise guard index to target following guard **/
  private executeGuards(guardIndex = 0): Promise<boolean> {
    return this.activateGuard(this.next.data.trigger.guards[guardIndex])
      .then(() => {
        if (guardIndex < this.next.data.trigger.guards.length - 1) {
          return this.executeGuards(guardIndex + 1);
        } else return Promise.resolve(true);
      })
      .catch(() => Promise.reject(false));
  }

  /** Uses switch case to create new instance of the target guard **/
  /** Returns boolean Promise to return back up the pipeline **/
  private activateGuard(guardKey: string): Promise<boolean> {
    let guard: PayerAdminGuard | ProviderAdminGuard;

    switch (guardKey) {
      case GUARDS.PAYERADMINGUARD:
        guard = new PayerAdminGuard(
          this.authService,
          this.router,
          this.configService,
        );
        break;
      case GUARDS.PROVIDERADMINGUARD:
        guard = new ProviderAdminGuard(
          this.authService,
          this.router,
          this.configService,
        );
        break;
      default:
        break;
    }

    return guard.canActivate(this.next, this.state);
  }
}
