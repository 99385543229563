import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { StatusType } from '@zipari/shared-ds-util-status';
import { FormattingService } from '../../shared/services/formatting.service';
import { getValue } from '../../shared/utils/get-value';
import { checkInputsForText } from '../../design-system.helper';

import {
  BadgeConfig,
  BadgeSizes,
  BadgeTruncationLengths,
  BadgeTypes,
} from './badge.constants';

@Component({
  selector: 'badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit, OnChanges {
  @Input() config: BadgeConfig;
  @Input() context: object;

  @Output() linkClicked = new EventEmitter();
  @Output() actionClicked = new EventEmitter();

  badgeConfig: BadgeConfig;
  statusType = StatusType;
  badgeTypes = BadgeTypes;
  badgeSizes = BadgeSizes;
  largeTruncateSize = BadgeTruncationLengths.large;
  extraLargeTruncateSize = BadgeTruncationLengths.extraLarge;

  constructor(private formattingService: FormattingService) {}

  ngOnInit(): void {
    const [config] = checkInputsForText([this.config]);

    this.config = config;
    this.badgeConfig = new BadgeConfig(this.config);

    if (this.context && this.badgeConfig.prop && !this.badgeConfig.value) {
      this.badgeConfig.value = this.getFormatedValue(
        this.context,
        this.badgeConfig,
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.config?.currentValue && changes?.config?.previousValue) {
      const { status, value, label, link, size } = changes.config.currentValue;
      const {
        status: prevStatus,
        value: prevValue,
        label: prevLabel,
        link: prevLink,
        size: prevSize,
      } = changes.config.previousValue;

      status !== prevStatus ? (this.badgeConfig.status = status) : null;
      value !== prevValue ? (this.badgeConfig.value = value) : null;
      label !== prevLabel ? (this.badgeConfig.label = label) : null;
      link !== prevLink ? (this.badgeConfig.link = link) : null;
      size !== prevSize ? (this.badgeConfig.size = size) : null;
    }
  }

  getFormatedValue(context: unknown, badgeConfig: unknown): string {
    const unformattedValue = getValue(context, badgeConfig['prop']);
    let returnValue = unformattedValue;

    if (badgeConfig['format'] && badgeConfig['format'] !== 'TEXT') {
      returnValue = this.formattingService.restructureValueBasedOnFormat(
        unformattedValue,
        badgeConfig,
      );
    }

    return returnValue;
  }
  getIconFill(): string {
    const status = this.badgeConfig?.status;

    if (status) {
      switch (status) {
        case StatusType.SUCCESS:
        case StatusType.ERROR:
          return status;
        case StatusType.PENDING:
          return 'warn';
        case StatusType.INFO:
          return 'primary';
        case StatusType.EXPIRED:
          return 'gray--shadow';
        default:
          break;
      }
    } else {
      return 'badge-action__color';
    }
  }

  onLinkClick(): void {
    this.linkClicked.emit(this.badgeConfig.link);
  }

  onActionClick(action): void {
    this.actionClicked.emit(action);
  }
}
