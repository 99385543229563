import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { map, Observable, Subject, takeUntil, tap } from 'rxjs';
import {
  BUTTON_TYPE,
  BUTTON_TYPE_NAME,
} from '../../site-location-attestation.constant';
import SiteLocationAttestationEventService from '../../site-location-attestation.event.service';
import { SiteLocation } from '../../site-locations-attestation.model';

@Component({
  selector: 'attestation-dynamic-button',
  templateUrl: './attestation-dynamic-button.component.html',
})
export class AttestationDynamicButtonComponent implements OnInit, OnDestroy {
  buttonConfig: ButtonConfig;
  public disableAttestation$: Observable<boolean> =
    this.siteLocationAttestationEventService.selectedSiteLocations$.pipe(
      map(
        (locations: SiteLocation[]) =>
          locations.length === 0 &&
          this.buttonConfig[BUTTON_TYPE] === BUTTON_TYPE_NAME.APPROVAL,
      ),
    );
  private destroy$ = new Subject();

  constructor(
    private siteLocationAttestationEventService: SiteLocationAttestationEventService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.subscribeToApplyDynamicButtonConfig();
  }

  handleButtonClick(): void {
    switch (this.buttonConfig[BUTTON_TYPE]) {
      case BUTTON_TYPE_NAME.APPROVAL: {
        this.siteLocationAttestationEventService.emitDynamicButtonClickEvent(
          BUTTON_TYPE_NAME.APPROVAL,
        );
        break;
      }
      case BUTTON_TYPE_NAME.CONFIRM: {
        this.navigateHome();
        break;
      }
      case BUTTON_TYPE_NAME.PRACTITIONER: {
        this.navigateToPractitioners();
        break;
      }
      case BUTTON_TYPE_NAME.SUBMIT: {
        this.siteLocationAttestationEventService.emitDynamicButtonClickEvent(
          BUTTON_TYPE_NAME.SUBMIT,
        );
        break;
      }
      default: {
        break;
      }
    }
  }

  public subscribeToApplyDynamicButtonConfig(): void {
    this.siteLocationAttestationEventService.dynamicButtonConfig$
      .pipe(
        takeUntil(this.destroy$),
        tap((buttonConfig) => {
          this.buttonConfig = buttonConfig;
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  public navigateHome(): void {
    this.router.navigate(['/provider-portal/provider-admin/dashboard']);
  }

  public navigateToPractitioners(): void {
    this.route.firstChild?.paramMap.subscribe((params: Params) => {
      const id = params.get('id');

      this.router.navigate([`locations/${id}/practitioners`], {
        relativeTo: this.route,
      });
    });
  }
}
