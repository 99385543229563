import { StatusType } from '@zipari/shared-ds-util-status';
import { IconConfig } from '../icon/icon.model';

export enum BadgeSizes {
  small = 'small',
  large = 'large',
}

/** @deprecated */
export enum BadgeTypes {
  flat = 'flat',
  actions = 'actions',
  priority = 'priority',
  success = 'success',
  error = 'error',
  info = 'info',
}

const largeTruncateSize = 7;
const extraLargeTruncateSize = 18;

export enum BadgeTruncationLengths {
  large = largeTruncateSize,
  extraLarge = extraLargeTruncateSize,
}

export class BadgeConfig {
  label?: string;
  value?: string | number;
  prop?: string;
  icon?: IconConfig;
  iconName?: string;
  centerContent?: boolean;
  actions?: any[];
  isCustomTemplate?: boolean;
  isFlat?: boolean;
  link?: string;
  size?: BadgeSizes = BadgeSizes.small;
  status?: StatusType;
  statusLabel?: string;
  valueFormat?: string;
  truncateLong?: boolean;
  /** @deprecated */
  type?: BadgeTypes;

  constructor(options = {}) {
    Object.assign(this, options);

    // backwards compatibility for configurations using deprecated `type`
    switch (this.type) {
      case BadgeTypes.flat:
        this.isFlat = true;
        break;
      case BadgeTypes.priority:
        this.status = StatusType.PENDING;
        break;
      case BadgeTypes.success:
      case BadgeTypes.error:
      case BadgeTypes.info:
        this.status = StatusType[this.type.toUpperCase()];
        break;
    }
  }
}
