import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  animationDirectionDefaults,
  ModalConfig,
  ModalDirections,
  ModalSizes,
  ModalTitleDirections,
  ModalTypes,
} from '@zipari/shared-ds-util-modal';
import { fadeToMaskOpacity } from '../../shared/animations/fade';
import { slide } from '../../shared/animations/slide';
import { cloneObject } from '../../shared/utils/object';
import { determineIconFill, listenToTabKeydown } from './modal.helper';
import { checkInputsForText } from '../../design-system.helper';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [fadeToMaskOpacity, slide],
})
export class ModalComponent implements OnDestroy {
  @Input() elementThatOpenedTheModal: string;
  @Input() set condition(showModal: boolean) {
    this.internalCondition = showModal;

    // TODO: Test disabling background when modal is open
    if (showModal) {
      this.hidePageScrollHandler();
    } else {
      const halfSecond = 500;

      this.showPageScroll();
      // TODO: figure out how to tie into a life-cycle hook that will fire later.
      // NgOnChanges fires too much to use for this
      setTimeout(() => this.returnFocusToSourceElement(), halfSecond);
    }
  }
  @Input()
  public set config(config: ModalConfig) {
    const [updatedConfig] = checkInputsForText([config]);

    this.privateConfig = new ModalConfig(updatedConfig);

    if (this.condition) {
      this.hidePageScrollHandler();
    }
  }
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  // autofocuses to modal body to switch accessibility context
  @ViewChild('body', { static: false })
  set body(element: ElementRef<HTMLElement>) {
    if (element) {
      element.nativeElement.focus();
    }
  }

  @ViewChild('modalContainer', { static: false })
  set modalContainer(_element: ElementRef<HTMLElement>) {
    this.setFocusToModal();
  }

  get condition(): boolean {
    return this.internalCondition;
  }
  public modalDirections = ModalDirections;
  public modalSizes = ModalSizes;
  public modalTitleDirections = ModalTitleDirections;
  public modalTypes = ModalTypes;
  public animationDirectionDefaults = animationDirectionDefaults;
  private privateConfig: ModalConfig;
  private internalCondition: boolean;
  public modalTitle = 'Modal';

  public nonModalNodes;
  public previousElement: HTMLElement;

  public get config(): ModalConfig {
    return cloneObject(this.privateConfig);
  }

  /**
   * Use a combination of style & type to determine the right var color.
   * default values are in assets/styles/base/_root.scss
   */
  public get iconFill() {
    return determineIconFill(this.config.altStyle, this.config.type);
  }

  constructor(
    @Inject(DOCUMENT) private document,
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {
    this.nonModalNodes = this.document.querySelectorAll(
      'body *:not(.Modal):not([tabindex="-1"])',
    );
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    _event: KeyboardEvent,
  ) {
    this.removeRestoreAppliedTabIndex();
    this.cancel.emit();
  }

  handleCancel() {
    this.cancel.emit();
  }

  handleMaskClick() {
    if (this.config.clickOutside) {
      this.handleCancel();
    }
  }

  setFocusToModal(): void {
    const focusableElements =
      'button, a, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = this.document?.querySelector('.Modal'); // select the modal by it's class
    const firstFocusableElement = modal?.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
    const modalNodes = Array.from(document.querySelectorAll('.Modal *'));

    if (this.condition) {
      if (this.nonModalNodes) {
        this.nonModalNodes.forEach((node) => {
          if (!modalNodes.includes(node)) {
            // save the previous tabindex state so we can restore it on close
            node._prevTabindex = node.getAttribute('tabindex');
            node.setAttribute('tabindex', -1);
          }
        });
      }

      listenToTabKeydown(focusableElements, this.elementRef);
    }
    if (firstFocusableElement) firstFocusableElement.focus();
  }

  removeRestoreAppliedTabIndex(): void {
    // restore or remove tabindex from nodes
    if (this.nonModalNodes) {
      this.nonModalNodes.forEach((node) => {
        if (node._prevTabindex) {
          node.setAttribute('tabindex', node._prevTabindex);
          setTimeout(() => {
            node._prevTabindex = null;
          });
        } else {
          node.removeAttribute('tabindex');
        }
      });
    }
  }

  ngOnDestroy() {
    this.showPageScroll();
    this.removeRestoreAppliedTabIndex();
    this.returnFocusToSourceElement();
    // this.enableBodyPointerEvents();
  }

  private hidePageScrollHandler() {
    if (!this.previousElement) {
      this.previousElement = document.activeElement as HTMLElement;
    }
    if (this.privateConfig?.hidePageScroll) {
      this.hidePageScroll();
    }
  }

  private hidePageScroll() {
    this.renderer.setStyle(this.document.body, 'overflow', 'hidden');
    this.renderer.setStyle(
      this.document.body,
      'paddingRight',
      `${this.getScrollbarWidth()}px`,
    );
  }

  // When modal is closed, restore focus to the element that triggered it.
  private returnFocusToSourceElement() {
    if (!this.elementThatOpenedTheModal) return;

    const elementToReceiveFocus: HTMLElement = document.getElementById(
      this.elementThatOpenedTheModal,
    );

    elementToReceiveFocus?.focus();
  }

  private showPageScroll() {
    if (this.previousElement) {
      setTimeout(() => {
        this.previousElement.focus();
        this.previousElement = null;
      });
    }
    this.renderer.setStyle(this.document.body, 'overflow', 'auto');
    this.renderer.setStyle(this.document.body, 'paddingRight', '');
  }

  private getScrollbarWidth() {
    const container = this.document.createElement('div');
    container.style.visibility = 'hidden';
    container.style.overflow = 'scroll';
    this.document.body.appendChild(container);

    const scrollbarWidth = container.offsetWidth - container.clientWidth;

    container.parentNode.removeChild(container);

    return scrollbarWidth;
  }
}
