import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormExclusionStore {
  private exclusions = new BehaviorSubject<string[]>([]);

  get exclusions$(): Observable<string[]> {
    return this.exclusions.asObservable();
  }

  get _exclusions(): string[] {
    return this.exclusions.getValue();
  }

  public addExclusions(newExclusions: string[]) {
    this.exclusions.next([...newExclusions, ...this._exclusions]);
  }

  public removeExclusions(remExclusions: string[]) {
    const _exclusions = this._exclusions.filter(
      (exc) => !remExclusions.some((remExc) => exc === remExc),
    );
    this.exclusions.next(_exclusions);
  }
}
