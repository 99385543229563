import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';

import { controlTypes, TextConfiguration } from '@zipari/shared-ds-util-form';
import { validMasks } from '@zipari/shared-ds-util-format';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { MaskDirective } from '../../../shared/directives/mask.directive';
import { isValidMonthDate } from '../../../shared/utils/dates';
import { checkInputsForText } from '../../../design-system.helper';

export const browser = {
  safari: 'Safari',
  firefox: 'Firefox',
  edge: 'MS-Edge',
  chrome: 'Chrome',
  ie: 'IE',
};

@Component({
  selector: 'control-text',
  templateUrl: './text.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaskDirective),
      multi: true,
    },
  ],
})
export class ControlTextComponent implements OnInit, OnDestroy {
  @Input() config: TextConfiguration;
  @Input() autocomplete: any;
  @Input() control: any;
  @Input() customInputTemplate: TemplateRef<any>;
  @Output() iconRightClicked = new EventEmitter<any>();
  @Output() inputChanged = new EventEmitter<any>();
  @Output() focusEvent = new EventEmitter<any>();
  @Output() focusOutEvent = new EventEmitter<any>();

  @ViewChild('input') input;

  dateStub: Subscription;

  constructor(public deviceService: DeviceDetectorService) {}

  ngOnInit(): void {
    const [config] = checkInputsForText([this.config]);

    this.config = config;
    this.config.isDisabled && this.control.disable();
    this.handleBrowserSpecificMasks();
    this.autocomplete = this.autocomplete || this.config.autocomplete;
  }

  onInputChange() {
    this.inputChanged.next(this.control.value);
  }

  /** handle browser specific date issues */
  handleBrowserSpecificMasks() {
    if (this.config.type === controlTypes.month && this.isMonthNotSupported) {
      this.configureMonthMask();
    }

    if (
      [controlTypes.date, controlTypes.datePicker].includes(this.config.type) &&
      this.isDateNotSupported
    ) {
      this.configureDateMask();
    }
  }

  ngOnDestroy() {
    this.dateStub?.unsubscribe();
  }

  private configureMonthMask(): void {
    const exp_date_length = 7;

    this.config = {
      mask: validMasks.month,
      maxLength: exp_date_length,
      placeholder: 'YYYY-MM',
      ...this.config,
      type: controlTypes.text,
    };

    this.dateStub = this.control.valueChanges.subscribe((val) => {
      if (val.length === exp_date_length) {
        try {
          const validDate = isValidMonthDate(val);

          if (!validDate) {
            this.control.patchValue('');
          }
        } catch (err) {
          this.control.patchValue('');
        }
      }
    });
  }

  private configureDateMask(): void {
    const date_length = 10;
    const defaulDateMask: string =
      this.config.type === controlTypes.datePicker ? validMasks.date : '';

    this.config = {
      ...this.config,
      placeholder: this.config.placeholder || 'mm/dd/yyyy',
      iconRight: this.config.iconRight || 'calendar',
      mask: this.config.fallbackMask || defaulDateMask,
      maxLength: this.config.maxLength || date_length,
    };
    // the patching value is removing the validators and hence commented the code. The form will not be valid until date control is invalid even without patching value.
    // this.dateStub = this.control.valueChanges.subscribe((val: string) => {
    //   if (val?.length === date_length) {
    //     try {
    //       const validDate = isValidDate(val);

    //       if (!validDate) {
    //         this.control.patchValue('');
    //       }
    //     } catch (err) {
    //       this.control.patchValue('');
    //     }
    //   }
    // });
  }

  get checkEdgeForDate(): boolean {
    const edge_17 = 17;
    const browser_ver = parseFloat(this.deviceService.browser_version);

    return this.deviceService.browser === browser.edge && browser_ver < edge_17;
  }

  get checkFirefoxForDate(): boolean {
    return this.deviceService.browser === browser.firefox;
  }

  get isMonthNotSupported(): boolean {
    return (
      this.checkFirefoxForDate ||
      this.checkEdgeForDate ||
      this.deviceService.browser === browser.safari ||
      this.deviceService.browser === browser.ie
    );
  }

  get isDateNotSupported(): boolean {
    return [browser.chrome, browser.firefox, browser.safari].includes(
      this.deviceService.browser,
    );
  }

  private usesRequiredIfValidator(validators) {
    return validators?.find((validator) => validator.name === 'requiredIf');
  }

  disableIfNotRequired(sourceControl: UntypedFormControl): boolean {
    const requiredIfValidator = this.usesRequiredIfValidator(
      this.config.validators,
    );

    if (requiredIfValidator) {
      const { requiredIfPropExpectedValue, requiredIfProp } =
        requiredIfValidator.dependency;
      const controlToCheck = this.control.parent?.controls[requiredIfProp];
      const valueToCheck = controlToCheck?.value;
      const comparisonValue =
        typeof requiredIfPropExpectedValue === 'boolean'
          ? !!valueToCheck
          : valueToCheck;

      // disable field if required value not present
      const shouldDisable = Array.isArray(comparisonValue)
        ? !comparisonValue.includes(requiredIfPropExpectedValue)
        : comparisonValue !== requiredIfPropExpectedValue;

      if (shouldDisable) {
        sourceControl.reset();
      }

      sourceControl.updateValueAndValidity();

      return shouldDisable;
    } else {
      return false;
    }
  }
}
