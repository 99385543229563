import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneObject } from '@zipari/web-utils';

import { Observable } from 'rxjs';

@Injectable()
export class DocumentsService {
  // TODO: this is a hack
  // fix: remove this entire fileupload stuff form this repo
  // and use the prebuilt file upload component from design-system
  documentCategory = '';

  constructor(private http: HttpClient) {}

  /**
   * fetch list of all documents for the current webuser
   * @param endpoint document services api endpoint
   */
  getAllDocuments(endpoint: string): Observable<any> {
    return this.http.get<any>(endpoint);
  }

  /**
   * upload document to document services
   *
   * please note the restriction on `documentData` meta data json (optionally) passed into this function:
   * https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Using_XMLHttpRequest#Submitting_forms_and_uploading_files
   *
   * @param endpoint document services api endpoint
   * @param file document to upload
   * @param documentData associated document meta data as json; may not be >1 level deep; all fields will be stringified
   */
  uploadFile(
    endpoint: string,
    file: File,
    documentData?: any,
  ): Observable<any> {
    const formData = new FormData();

    if (documentData) {
      for (const item in documentData) {
        if (documentData.hasOwnProperty(item)) {
          /**
           * warning: properties at depth >1 will result in stringified "[object Object]" values
           */
          formData.append(item, documentData[item]);
        }
      }
    }
    formData.append('file', file);
    if (this.documentCategory?.length) {
      formData.append('document_category', cloneObject(this.documentCategory));
      this.documentCategory = '';
    }
    const request = new HttpRequest('POST', endpoint, formData, {
      reportProgress: true,
    });

    return this.http.request<any>(request);
  }

  setDocumentCategory(category: string) {
    this.documentCategory = category;
  }

  /**
   * delete document from document services
   * @param endpoint document services api endpoint
   * @param documentId id of document
   */
  deleteFile(endpoint: string, documentId) {
    const url = `${endpoint}${documentId}/`;

    return this.http.delete<any>(url);
  }
}
