import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  ButtonConfig,
  ButtonLevels,
  ButtonSizes,
  SubNavPositions,
} from '@zipari/shared-ds-util-button';

@Component({
  selector: 'zip-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input() disabled = false;
  @Input() ariaDescription: string;
  @Input() buttonOption: string;
  @Input() loading = false;

  /** @deprecated */
  @Input() level: ButtonLevels;

  /** @deprecated */
  @Input() content: string;

  @Input() menuPosition: string;

  /** @deprecated */
  @Input() icon: string; // Really ought to define this so we're not just passing strings

  @Input() config: ButtonConfig;

  @Output() zipButtonClicked: EventEmitter<any> = new EventEmitter();

  @Output() customMethod: EventEmitter<any> = new EventEmitter();

  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  public ButtonLevels = ButtonLevels;

  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  public ButtonSizes = ButtonSizes;

  public SubNavPositions = SubNavPositions;

  public buttonSubnavShows = false;
  public isHovering = false;
  public isFocused = false;

  public get isDisabled() {
    return this.disabled || this.config?.disabled;
  }

  public get iconOnly() {
    return !this.config.level && !this.config.content && this.config.icon;
  }

  public get iconFill() {
    return `btn-${this.config.level}__icon--resting`;
  }

  public get innerHtmlContent() {
    return this.buttonOption
      ? this.config.options && this.config.options[this.buttonOption]
        ? this.config.options[this.buttonOption]
        : this.config.content
      : this.config.content;
  }

  ngOnInit() {
    // `ButtonConfig` constructor assigns it's own properties to `this`.
    this.config = this.initButton();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { currentValue } = changes.config || {};

    if (currentValue) {
      const updatedConfig =
        typeof currentValue === 'string'
          ? JSON.parse(currentValue)
          : currentValue;

      this.config = this.initButton(updatedConfig);
    }
  }

  customEventMethod({ event: event, context: context }) {
    // if a `customMethod` has been set on the component, emit event on that method.
    if (this.customMethod.observers.length) {
      // passing `event`and component `context` so can access component data in customMethod.
      this.customMethod.emit({ event: event, context: context });
    }
  }

  onClick(event, source = 'button') {
    this.customEventMethod({ event: event, context: this });

    if (source === 'button' && this.config.subnav) {
      this.toggleButtonSubnavShows();
    } else {
      if (!this.isDisabled) {
        this.zipButtonClicked.emit(event);
      }
    }
  }

  public initButton(config: ButtonConfig = this.config || {}) {
    if (typeof this.level !== 'undefined') {
      config.level = this.level;
    }

    if (typeof this.content !== 'undefined') {
      config.content = this.content;
    }

    if (typeof this.icon !== 'undefined') {
      config.icon = this.icon;
    }

    return new ButtonConfig(config);
  }

  public toggleButtonSubnavShows() {
    this.buttonSubnavShows = !this.buttonSubnavShows;
  }
}
