<ng-container *ngIf="!!navigationService.config.toolbar">
  <div class="bar-container">
    <div class="NavIcons toolbar">
      <div class="toolbar__icons" *ngIf="isLoggedIn">
        <ng-container
          *ngFor="let icon of navigationService.config.toolbar; let i = index"
        >
          <ng-container *ngIf="!icon.items; else iconWithItems">
            <a
              class="NavIcon__wrapper"
              [routerLink]="icon.route"
              [target]="icon.target === 'tab' ? '_blank' : '_self'"
              (click)="handleIconClick(icon)"
            >
              <div class="u-flex u-alignCenter u-maxY u-relative u-row-reverse">
                <icon
                  class="NavIcon__icon"
                  [name]="icon.icon"
                  [config]="{
                    type: 'pointer',
                    fill: 'nav-icon-color',
                  }"
                >
                </icon>
                <span
                  *ngIf="
                    showNotificationsIndicator &&
                    icon.count_api === 'notifications'
                  "
                  class="toolbar__notifications-indicator"
                >
                  <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="25" cy="25" r="25" fill="#E10B30" />
                  </svg>
                </span>
                <span
                  *ngIf="showMessagesIndicator && icon.count_api === 'messages'"
                  class="toolbar__notifications-indicator"
                >
                  <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="25" cy="25" r="25" fill="#E10B30" />
                  </svg>
                </span>
              </div>
            </a>
          </ng-container>

          <ng-template #iconWithItems>
            <div
              class="NavIcon__wrapper"
              tabindex="0"
              (click)="handleIconClick(icon)"
              (keydown.space)="handleIconClick(icon)"
            >
              <div class="u-flex u-alignCenter u-maxY">
                <icon
                  class="NavIcon__icon"
                  [name]="icon.icon"
                  [config]="{
                    type: 'pointer',
                    fill: 'nav-icon-color',
                  }"
                >
                </icon>

                <p class="NavIcon__label">{{ icon.label }}</p>
              </div>

              <ng-container *ngIf="icon.isOpen">
                <div class="NavIcon__menu">
                  <div class="NavIcon__option" *ngFor="let item of icon.items">
                    <a
                      tabindex="0"
                      class="u-flex"
                      [routerLink]="item.route"
                      [target]="item.target === 'tab' ? '_blank' : '_self'"
                      (click)="handleIconClick(item)"
                      (keydown.enter)="handleIconClick(item)"
                      *ngIf="item.route !== '/logout'; else logoutItem"
                    >
                      <p class="NavIcon__title">
                        {{ item.label }}
                      </p>
                    </a>

                    <ng-template #logoutItem>
                      <p
                        class="NavIcon__title"
                        tabindex="0"
                        (click)="onLogoutClick()"
                        (keydown.enter)="onLogoutClick()"
                      >
                        {{ item.label }}
                      </p>
                    </ng-template>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
