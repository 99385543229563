import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { getValue } from '@zipari/web-utils';
import { APIService } from '@zipari/web-services';
import { FormControlService } from '@zipari/design-system';
import * as _ from 'lodash';
import { isEmpty } from 'lodash';
import { forkJoin, Observable } from 'rxjs';

import { ConfigService, DocumentsService } from '../../../shared/services';
import { CX_CALLS } from '../../../shared/constants/cx-calls.constant';
import { objectToArray } from '../../../shared/utilities/object';
import Policy from '../../../shared/models/shared/Policy.model';
import Member from '../../../shared/models/shared/Member.model';
import { ApiListResponse } from '../../../shared/models/shared/ApiListResponse.model';
import { GenericServices } from '../../shared/services/generic.service';
import { ProviderUpdateToolService } from '../provider-update-tool/provider-update-tool.service';
import {
  ZipBackendErrorResponse,
  ZipEndpointService,
} from '../../../shared/services/zip-endpoint.service';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { defaultFileUploaderRestrictions } from '../../../shared/constants/file-uploader.constant';
import { UploadDocumentConfig } from '../../../shared/models/shared/FileUploader.model';
import {
  OutNetworkStatus,
  OutOfNetworkProps,
} from '../transfer-of-care/transfer-of-care-details.constants';
import {
  CONTROL_KEYS,
  NoValue,
  ProcedureCodeCardKey,
  ProviderDisplayFields,
  ProvidersKeys,
  ProviderType,
} from '../authorization-submission/authorization-submission.constants';
import {
  camelCase,
  toCamel,
} from '../../../shared/utilities/case-conversion.utils';
import authorizationService from '../transfer-of-care/transfer-of-care-detail.model';
import { AuthCardArray } from '../authorization-submission/authorization-submission.model';
import {
  EditAuthCardsProp,
  editAuthConstants,
  uploadDocument,
} from './edit-authorization.constants';
import {
  Config,
  ControlOption,
  DropDownOptionConfig,
  RowDiagnosis,
  UploadDocument,
} from './edit-authorization.model';
import { DocumentUploadService } from '../claim-appeal/services/document-upload.service';
import { getDefaultFileUploaderConfig } from '../../../shared/helpers/file-uploader.helper';

@Component({
  selector: 'edit-authorization',
  templateUrl: './edit-authorization.component.html',
  styleUrls: ['./edit-authorization.component.scss'],
})
export class EditAuthorizationComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
  id: number;
  config: Config;
  context: { member: Member; coverage?: any } = { member: null };
  state: 'edit' | 'review' | 'confirm' = 'edit';
  formGroup = new UntypedFormGroup({});
  controls: Map<any, any> = new Map<any, any>();
  payload: any = {};
  busy: any[] = [];
  response: any;
  error = false;
  loaded = false;
  noSpace = 'n/a';
  diagnosisDetails: RowDiagnosis[];
  errorInDocumentUpload = false;
  uploadedDocs = [];
  jsonSubmissionObject = {};
  currentDocumentType: string;
  cardArray: AuthCardArray[] = [];
  showAlert = false;
  enableCopyButton = false;
  _: any = _;
  rowData: any;
  errorMessage: string[];
  readonly CONTROL_KEYS = CONTROL_KEYS;
  displayFieldsData: { label: any; value: any }[];
  documentContext: UntypedFormArray;
  defaultFileUploaderRestrictions = defaultFileUploaderRestrictions;

  constructor(
    private route: ActivatedRoute,
    public configService: ConfigService,
    public genericServices: GenericServices,
    private formControlService: FormControlService,
    public router: Router,
    private api: APIService,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private location: Location,
    private el: ElementRef,
    public mp: ProviderUpdateToolService,
    private docService: DocumentsService,
    public zipEndpointService: ZipEndpointService,
    public analyticsService: AnalyticsService,
    public documentUploadService: DocumentUploadService,
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.genericServices.showFooter$.next(false);
    });
    this.id = this.route.snapshot.params.id;
    this.rowData = JSON.parse(localStorage.getItem('row'));
    if (this.rowData?.services?.length > 1) {
      this.rowData?.services.sort((a, b) => a.cost - b.cost);
    }
    this.config = this.configService.getPageConfig('edit-authorization');

    // delete the practitioner object if requesting provider support both site location and practitioner.
    if (this.config.allowLocationAsRequestingProvider) {
      delete this.config.cards['requesting_provider_card'];
    } else {
      delete this.config.cards['site-location-and-practitioner-provider'];
    }

    if (this.config) {
      for (const cardKey of _.keys(this.config?.cards)) {
        for (const controlKey of _.keys(
          this.config?.cards[cardKey]?.controls,
        )) {
          this.config.cards[cardKey].controls = Object.keys(
            this.config?.cards[cardKey]?.controls,
          )
            .sort((a, b) =>
              this.config?.cards[cardKey]?.controls[a]['controlOrder'] >
              this.config?.cards[cardKey]?.controls[b]['controlOrder']
                ? 1
                : -1,
            )
            .reduce((result, key) => {
              result[key] = this.config.cards[cardKey].controls[key];

              return result;
            }, {});
          for (const [key, value] of Object.entries(
            this.config?.cards[cardKey]?.controls,
          )) {
            if (
              controlKey &&
              key.indexOf(controlKey) !== -1 &&
              key.indexOf('flyout') !== -1 &&
              controlKey.indexOf('flyout') === -1
            ) {
              Object.assign(this.config?.cards[cardKey]?.controls[controlKey], {
                flyout: value,
              });
              this.config.cards[cardKey].controls[
                controlKey
              ].flyout.table.columns = objectToArray(value['table'].columns);
              this.config.cards[cardKey].controls[
                controlKey
              ].flyout.table.filters = objectToArray(value['table'].filters);
              delete this.config?.cards[cardKey]?.controls[key];
            }
          }
        }
      }
      this.config.cards = Object.keys(this.config.cards)
        .sort((a, b) =>
          this.config?.cards[a]['cardOrder'] >
          this.config?.cards[b]['cardOrder']
            ? 1
            : -1,
        )
        .reduce((result, key) => {
          result[key] = this.config.cards[key];

          return result;
        }, {});
    }
    this.noSpace = this.config?.noSpace;
    this.buildPanel();
    this.buildForms();
    this.preloadDocs(this.rowData?.documents);
  }

  ngAfterViewInit() {
    this.formGroup
      .get('document_type')
      ?.valueChanges.subscribe((changedValueOrIndex: any) => {
        this.currentDocumentType = changedValueOrIndex;
        if (this.currentDocumentType?.length) {
          this.docService.setDocumentCategory(this.currentDocumentType);
        }
      });

    if (this.documentUploadService.fileAttachments?.length > 0) {
      this.setFilesFromAPI(this.documentUploadService.fileAttachments);
    }
  }

  shouldAddRequestingProviderSiteLocationAndPractitioner(
    key: string,
  ): boolean | undefined {
    if (
      key?.toLowerCase() ===
      CONTROL_KEYS.site_location_and_practitioner_provider?.toLowerCase()
    ) {
      return this.config.allowLocationAsRequestingProvider;
    }

    return true;
  }

  formatOption(result, optionFormat) {
    let label = '';

    optionFormat?.map((format: any) => {
      label += `${getValue(result, format)}`;
    });

    return { label: label, value: { label: label, ...result } };
  }

  fillEditData(formGroup, row) {
    for (const cardKey of _.keys(this.config?.cards)) {
      if (
        this.config?.cards[cardKey]?.type !== 'dynamicCard' ||
        this.config?.cards[cardKey]?.type === undefined
      ) {
        for (const controlKey of _.keys(
          this.config?.cards[cardKey]?.controls,
        )) {
          if (this.config?.cards[cardKey]?.controls[controlKey]?.apiProp) {
            const getKeyValue = this.getObjectProperty(
              row,
              this.config?.cards[cardKey]?.controls[controlKey].apiProp,
            );

            if (getKeyValue) {
              formGroup?.controls[
                this.config?.cards[cardKey]?.controls[controlKey].prop
              ]?.setValue(getKeyValue);
            }
            if (
              this.config?.cards[cardKey]?.controls[controlKey]?.apiProp ===
              editAuthConstants.diagnosis_codes
            ) {
              const allDiagnoses = this.getObjectProperty(
                row,
                this.config?.cards[cardKey]?.controls[controlKey].apiProp1,
              );
              let primaryDiagnoses;

              if (allDiagnoses) {
                primaryDiagnoses = allDiagnoses.filter(
                  (element: any) => element.is_primary === true,
                );
              }

              this.diagnosisDetails = [];
              getKeyValue?.forEach((element, i) => {
                element.index = i;
                if (primaryDiagnoses) {
                  element.is_primary =
                    element.id === primaryDiagnoses[0].diagnosis_id;
                }
                this.diagnosisDetails.push({ row: element });
              });
            }
          }
        }
      } else if (this.config?.cards[cardKey]?.type === 'dynamicCard') {
        for (let i = 0; i < this.rowData?.services?.length; i++) {
          for (const controlKey of _.keys(
            this.cardArray[i]?.card_to_load?.controls,
          )) {
            if (
              this.cardArray[i]?.card_to_load?.controls[controlKey]?.apiProp
            ) {
              const apiProp = this.cardArray[i].card_to_load?.controls[
                controlKey
              ]?.apiProp.includes('services')
                ? this.cardArray[i]?.card_to_load?.controls[controlKey]?.apiProp
                    .split('.')
                    .join(`.${i}.`)
                : this.cardArray[i]?.card_to_load?.controls[controlKey]
                    ?.apiProp;
              const getKeyValue = this.getObjectProperty(this.rowData, apiProp);

              if (getKeyValue) {
                this.cardArray[i].group_to_load?.controls[
                  this.cardArray[i]?.card_to_load?.controls[controlKey].prop
                ]?.setValue(getKeyValue);
              }
            }
          }
        }
      }
    }
  }

  buildPanel(): void {
    const memberApi: Observable<Member> = this.api.get(
      this.setMemberId(this.config.endpoints.member),
    );
    const coverageApi: Observable<ApiListResponse<Policy>> = this.api.get(
      this.setMemberId(this.config.endpoints.coverage),
    );

    this.busy.push(
      forkJoin([memberApi, coverageApi]).subscribe(([member, coverage]) => {
        this.context.member = member;
        this.context.coverage = coverage.results[0]?.product_coverages[0];
        if (
          this.context?.member?.additional_fields
            ?.enrolled_medicare_savings_program
        ) {
          this.context.member.additional_fields.enrolled_medicare_savings_program =
            ' ';
        }
        this.loaded = true;
      }),
    );
  }

  setMemberId(target: string): string {
    return target.replace('${member_number}', this.rowData?.member_id);
  }

  setAuthId(target: string): string {
    return target.replace('${id}', this.rowData?.id);
  }

  checkShowOnTrueValues(attr): boolean {
    let targetDataExists: boolean;
    let value: string | number;

    if (attr.showIfTrue) {
      value = this.getValue(this.context, attr);
      targetDataExists = value?.toString()?.length > 0;
    } else return false;

    return targetDataExists;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  getValue(data: object, attr, val?: boolean): string | number {
    const value: string | number = val
      ? getValue(data, attr.value)
      : getValue(data, attr.prop);

    return value;
  }

  buildForms() {
    for (const cardKey of _.keys(this.config?.cards)) {
      if (
        this.config?.cards[cardKey]?.type !== 'dynamicCard' ||
        this.config?.cards[cardKey]?.type === undefined
      ) {
        for (const controlKey of _.keys(
          this.config?.cards[cardKey]?.controls,
        )) {
          if (controlKey === editAuthConstants.controlType) {
            this.formGroup
              .get(
                this.config?.cards[cardKey]?.controls[controlKey]?.ifThisProp,
              )
              ?.valueChanges.subscribe((val: any) => {
                this.controls.set(
                  this.config?.cards[cardKey]?.controls[controlKey]?.prop,
                  this.config?.cards[cardKey]?.controls[controlKey],
                );
                const control: AbstractControl = new UntypedFormControl('', []);
                const newFormControl = Object.assign(
                  this.config?.cards[cardKey]?.controls[controlKey],
                  {
                    control: control,
                  },
                );

                this.endPointData(
                  this.config.cards[cardKey].controls[controlKey],
                  `?authorization_type=${val}`,
                );
                this.formControlService.addControlToFormGroup(
                  this.formGroup,
                  newFormControl,
                );
              });
          } else {
            this.controls.set(
              this.config?.cards[cardKey]?.controls[controlKey]?.prop,
              this.config?.cards[cardKey]?.controls[controlKey],
            );
            const control: AbstractControl = new UntypedFormControl('', []);
            const newFormControl = Object.assign(
              this.config?.cards[cardKey]?.controls[controlKey],
              {
                control: control,
              },
            );

            this.formControlService.addControlToFormGroup(
              this.formGroup,
              newFormControl,
            );
            this.endPointData(
              this.config?.cards[cardKey]?.controls[controlKey],
              null,
              cardKey,
            );
          }
        }
      } else if (this.config?.cards[cardKey]?.type === 'dynamicCard') {
        this.formGroup
          .get(this.config?.cards[cardKey]?.ifThisProp)
          ?.valueChanges.subscribe((val: any) => {
            if (this.config?.cards[cardKey]?.loadCardFor === val) {
              this.cardArray = [];
              const dynamicControls: Map<any, any> = new Map<any, any>();
              const dynamicFormGroup = new UntypedFormGroup({});

              for (const controlKey of _.keys(
                this.config?.cards[cardKey]?.controls,
              )) {
                dynamicControls.set(
                  this.config?.cards[cardKey]?.controls[controlKey]?.prop,
                  this.config?.cards[cardKey]?.controls[controlKey],
                );
                const control: AbstractControl = new UntypedFormControl('', []);
                const newFormControl = Object.assign(
                  this.config?.cards[cardKey]?.controls[controlKey],
                  {
                    control: control,
                  },
                );

                this.formControlService.addControlToFormGroup(
                  dynamicFormGroup,
                  newFormControl,
                );
                this.endPointData(
                  this.config?.cards[cardKey]?.controls[controlKey],
                );
              }
              this.cardArray.push({
                card_to_load: this.config?.cards[cardKey],
                group_to_load: dynamicFormGroup,
                controls_to_load: dynamicControls,
              });

              if (this.rowData?.services?.length > 1) {
                for (
                  let index = 1;
                  index < this.rowData?.services?.length;
                  index++
                ) {
                  this.addCard(
                    this.config?.cards[cardKey]?.addButton?.addCardFor,
                    index,
                    '',
                    'addForEdit',
                  );
                }
              }
            }
          });
      }
    }

    this.fillEditData(this.formGroup, this.rowData);
  }

  switchTriggered(index) {
    const tempCardArr = [...this.cardArray];

    this.cardArray = [];
    tempCardArr?.map((item: any, i: number) => {
      if (i !== index) {
        item?.group_to_load?.get('is_primary')?.setValue(false);
      }
      this.cardArray.push({ ...item });
    });
  }

  cardToLoadValues(card): boolean {
    let targetDataExists: boolean;

    if (card.loadCardFor === this.formGroup.get(card.ifThisProp)?.value) {
      targetDataExists = this.cardArray.length > 0;
    } else return false;

    return targetDataExists;
  }

  addCard(id: any, index: number, addKey: string, isEditCard?) {
    for (const cardKey of _.keys(this.config?.cards)) {
      if (this.config?.cards[cardKey]?.loadCardFor === id && !addKey) {
        const dynamicControls: Map<any, any> = new Map<any, any>();
        const dynamicFormGroup = new UntypedFormGroup({});

        for (const controlKey of _.keys(this.config?.cards[cardKey].controls)) {
          if (!isEditCard) {
            this.config.cards[cardKey].controls[controlKey].isDisabled = false;
          }
          if (controlKey === ProcedureCodeCardKey) {
            this.config.cards[cardKey].controls[controlKey].validators =
              this.cardArray[0]?.card_to_load?.controls[controlKey]?.validators;
          }
          dynamicControls.set(
            this.config?.cards[cardKey]?.controls[controlKey]?.prop,
            this.config?.cards[cardKey]?.controls[controlKey],
          );
          const control: AbstractControl = new UntypedFormControl('', []);
          const newFormControl = Object.assign(
            this.config?.cards[cardKey]?.controls[controlKey],
            {
              control: control,
            },
          );

          this.formControlService.addControlToFormGroup(
            dynamicFormGroup,
            newFormControl,
          );
          this.endPointData(this.config?.cards[cardKey]?.controls[controlKey]);

          if (
            this.config?.cards[cardKey]?.controls[controlKey]?.prop ===
              'is_primary' &&
            this.rowData?.services?.length < 1
          ) {
            if (!this.checkForPrimaryCardAvailable()) {
              dynamicFormGroup
                .get(this.config?.cards[cardKey]?.controls[controlKey]?.prop)
                ?.setValue(true);
            }
          }
        }
        this.cardArray.push({
          card_to_load: this.config?.cards[cardKey],
          group_to_load: dynamicFormGroup,
          controls_to_load: dynamicControls,
          service: 'new_service',
        });
      } else if (this.config?.cards[cardKey]?.duplicateCard === id && addKey) {
        const duplicateFormGroup = _.cloneDeep(
          this.cardArray[index]?.group_to_load,
        );

        duplicateFormGroup.get('is_primary')?.setValue(false);
        const duplicateControls = this.cardArray[index].controls_to_load;

        for (const controlKey of _.keys(
          this.config?.cards[cardKey]?.controls,
        )) {
          if (!isEditCard) {
            this.config.cards[cardKey].controls[controlKey].isDisabled = false;
          }
          const control: AbstractControl = new UntypedFormControl('', []);
          const newFormControl = Object.assign(
            this.config?.cards[cardKey]?.controls[controlKey],
            {
              control: control,
            },
          );
        }
        this.cardArray.push({
          card_to_load: this.config?.cards[cardKey],
          group_to_load: duplicateFormGroup,
          controls_to_load: duplicateControls,
          service: 'copy_service',
        });
      }
    }
  }

  /**
   * Traverse the cardArray and return true if any of the card is assigned is_primary = true, else return false
   */
  checkForPrimaryCardAvailable(): boolean {
    let isPrimary = false;

    this.cardArray?.forEach((card: any) => {
      if (card.group_to_load.value.is_primary) {
        isPrimary = true;
      }
    });

    return isPrimary ? true : false;
  }

  checkFormField(index) {
    const group = this.cardArray[index].group_to_load;

    if (
      Object.values(group.value).find(
        (value: any) => value !== '' && value !== null,
      )
    ) {
      this.enableCopyButton = true;
    } else {
      this.enableCopyButton = false;
    }

    return this.enableCopyButton;
  }

  checkLimit(limit): boolean {
    if (limit) {
      if (
        limit ===
        this.cardArray.reduce(
          (i, obj) => i + Number(obj.service === 'new_service'),
          0,
        )
      ) {
        return false;
      } else return true;
    } else {
      return true;
    }
  }

  deleteCard(index) {
    if (this.cardArray[index]?.group_to_load?.value?.is_primary) {
      this.showAlert = true;
    } else {
      this.showAlert = false;
    }
    this.cardArray.splice(index, 1);

    const temArray = [...this.cardArray];

    this.cardArray = [];
    temArray?.map((item: any, ind: number) => {
      this.cardArray.push({ ...item });
      if (index === ind) {
        item?.group_to_load?.controls?.is_primary?.patchValue(true);
      }
    });
  }

  getState(updateVal) {
    this.state = updateVal;
  }

  endPointData(control, queryParam?: string, cardKey?: string) {
    if (
      control &&
      control?.outOfNetworkApiEndpoint &&
      this.config?.outOfNetwork?.isOutOfNetworkEnabled
    ) {
      const apiEndPoint = this.buildEndPoint(
        control.outOfNetworkApiEndpoint,
        queryParam,
      );

      this.setDropdownOptions(control, apiEndPoint, cardKey);
    } else if (control && control?.apiEndpoint) {
      const apiEndPoint = this.buildEndPoint(control.apiEndpoint, queryParam);

      this.setDropdownOptions(control, apiEndPoint, cardKey);
    }
  }

  setDropdownOptions(control, apiEndPoint: string, cardKey: string) {
    this.busy.push(
      this.api.get(apiEndPoint).subscribe((response: any) => {
        if (control.prop === EditAuthCardsProp.requestingProviderId) {
          const providerData = response.results.find(
            (requestingProvider) =>
              requestingProvider.id === this.rowData?.[control.prop],
          );

          this.formGroup?.controls[
            this.config?.cards[cardKey]?.controls[control.apiProp].prop
          ]?.setValue(providerData);
        }

        if (
          control.prop === EditAuthCardsProp.servicingFacilityId ||
          control.prop === EditAuthCardsProp.servicingProvider
        ) {
          const servicesData = this.rowData?.services.map(
            (service: authorizationService) => {
              const serviceId = service[control.prop]?.id;
              const facilityId =
                service[EditAuthCardsProp.servicingFacilityLocation]?.id;

              return response.results.find(
                (serviceProvider: any) =>
                  serviceProvider.id === serviceId ||
                  serviceProvider.id === facilityId,
              );
            },
          );

          this.cardArray.map((cardArrayItem, index: number) => {
            const serviceProp =
              cardArrayItem?.card_to_load?.controls[control?.prop]?.prop;
            const serviceFacilityProp =
              cardArrayItem?.card_to_load?.controls[
                EditAuthCardsProp.servicingFacility
              ]?.prop;

            cardArrayItem.group_to_load?.controls[
              serviceProp || serviceFacilityProp
            ]?.setValue(servicesData[index]);
          });
        }
        if (control?.optionFormat) {
          let obj;
          const dropdownArray = [];
          const options = [];

          response.results?.map(
            (dropdownOption: DropDownOptionConfig, index: number) => {
              let thing = '';

              control.optionFormat.map((format: any) => {
                thing += `${getValue(dropdownOption, format)}`;
              });
              obj = {
                label: thing,
              };
              dropdownArray.push({ ...obj, ...dropdownOption });
            },
          );
          control.options = this.populateDropdown(
            options,
            dropdownArray,
            control?.dropdownDisplayProp,
          );
        } else {
          const options = [];

          control.options = this.populateDropdown(
            options,
            response?.results,
            control.dropdownDisplayProp,
          );
          if (control.addCustomValueInDropDown) {
            const customOption = {
              label: control.addCustomValueInDropDown,
              value: {
                code: '',
                description: control.addCustomValueInDropDown,
                id: null,
                name: '',
              },
            };

            control.options.push(customOption);
          }
        }
        this.preFillOptions(control);
      }),
    );
  }
  public buildEndPoint(apiUrl: string, queryParam?: string): string {
    return queryParam ? `${apiUrl}${queryParam}` : apiUrl;
  }

  showOutOfNetworkStatusPill(key: string, controlKey: string): boolean {
    const isOutOfNetworkEnabled =
      this.config?.outOfNetwork?.isOutOfNetworkEnabled;
    const formGroupValue = this.formGroup.get(
      this.config?.cards[key]?.controls[controlKey]?.prop,
    )?.value;

    const isInNetworkStatus = formGroupValue?.member_network?.is_in_network;

    return isOutOfNetworkEnabled && isInNetworkStatus === false;
  }

  preFillOptions(control) {
    if (control?.type === 'dropdown' && control?.apiProp) {
      const getKeyValue = this.getObjectProperty(this.rowData, control.apiProp);

      if (getKeyValue) {
        const option = control?.optionFormat
          ? this.formatOption(getKeyValue, control?.optionFormat)?.value
          : getKeyValue;

        control?.options?.map((element: ControlOption) => {
          if (_.isEqual(element.value, option)) {
            this.formGroup.controls[control.prop]?.setValue(element.value);
          }
        });
      }
    }
  }

  print(event): void {
    window.print();
  }

  cancel(event: any): void {
    if (this.genericServices.previousURL.includes('provider-portal')) {
      this.location.back();
      localStorage.removeItem('row');
    }
  }

  selectedDiagnosisDetails(event) {
    this.diagnosisDetails = event;
  }

  scrollTo(event: any, cxKey?: string): void {
    this.back(null);
    if (cxKey) {
      const cxObj =
        event === 'diagnosis'
          ? {
              diagnosis: this.diagnosisDetails?.map(
                (element: any) => element?.row?.description,
              ),
            }
          : event === 'provider_info'
            ? {
                requesting_provider:
                  this.formGroup.value?.requesting_provider_id?.name?.full_name,
              }
            : {};

      this.analyticsService.sendEvent(cxKey, cxObj);
    }

    setTimeout(() => {
      const itemToScrollTo = document.getElementById(event);
      // null check to ensure that the element actually exists

      if (itemToScrollTo) {
        itemToScrollTo.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  public callEvent(index: number): void {
    const cxObj = {
      facility: this.getCXValue('servicing_facility_id', index, 'name'),
      servicing_provider: this.getCXValue(
        'servicing_provider',
        index,
        'name',
        'full_name',
      ),
    };

    this.analyticsService.sendEvent(
      CX_CALLS.pp_authorization_review_edit_initiated.event_key,
      cxObj,
    );
  }

  getCXValue(cxKey, index?: number, objKey = null, innerVal?: string): string {
    const attributeKeyVal = innerVal
      ? this.cardArray[index]?.group_to_load?.value[cxKey][objKey]?.[innerVal]
      : this.cardArray[index]?.group_to_load?.value[cxKey][objKey];

    return attributeKeyVal ? attributeKeyVal : '';
  }

  populateDropdown(options: any[], results: any[], prop: string) {
    for (const item of results) {
      options.push({
        label: this.getObjectProperty(item, prop),
        value: item,
      });
    }

    return options;
  }

  getObjectProperty(obj, key) {
    if (key === undefined || key === null) return;

    return key.split('.')?.reduce(function (result, inner_key) {
      if (!result) return;
      if (result[inner_key] !== null) return result[inner_key];
      else {
        result[inner_key] = null;

        return null;
      }
    }, obj);
  }

  getDropdownDisplayValue(prop: string): string | object {
    const options: any[] = this.controls.get(prop).options;
    const option: any = options.find((item: any) =>
      _.isEqual(item.value, this.formGroup.get(prop)?.value),
    );

    if (option) return option.label ? option.label : option;

    return this.noSpace;
  }

  back(event: any): void {
    if (this.state === 'review') this.state = 'edit';
  }

  checkValidation(): boolean {
    let lengthFlag = false;

    for (let i = 0; i < this.cardArray.length; i++) {
      if (this.formGroup.valid && this.cardArray[i]['group_to_load'].valid) {
        if (i !== this.cardArray.length - 1) continue;
        lengthFlag = true;
      } else {
        this.formGroup.markAllAsTouched();
        this.cardArray[i]['group_to_load'].markAllAsTouched();
        lengthFlag = false;
        setTimeout(() => {
          this.genericServices.scrollToFirstInvalidControl(this.el);
        });
      }
    }

    return lengthFlag;
  }

  review(): void {
    if (this.formGroup.value?.requesting_provider) {
      this.updateDisplayFields(this.formGroup.value?.requesting_provider);
    }
    if (this.checkValidation()) {
      if (this.state === 'edit') this.state = 'review';
      window.scrollTo(0, 0);
    } else {
      this.formGroup.markAllAsTouched();
      setTimeout(() => {
        this.genericServices.scrollToFirstInvalidControl(this.el);
      });
    }
  }

  updateDisplayFields(providerDetail): void {
    let updatedDisplayFields: [string, string][] = [];

    if (providerDetail) {
      providerDetail.providerId &&
        (updatedDisplayFields = Object.entries(
          this.config.cards['site-location-and-practitioner-provider']
            ?.controls['provider-flyout'].displayFields,
        ));
      providerDetail.facilityId &&
        (updatedDisplayFields = Object.entries(
          this.config.cards['site-location-and-practitioner-provider']
            ?.controls['facility-flyout'].displayFields,
        ));
    }

    if (updatedDisplayFields.length > 0) {
      this.displayFieldsData = updatedDisplayFields.map((key) => ({
        label: ProviderDisplayFields[`${camelCase(key[0])}`],
        value: getValue(providerDetail, `${camelCase(key[1])}`)
          ? getValue(providerDetail, `${camelCase(key[1])}`)
          : NoValue,
      }));
    }
  }

  setFieldIfExists(obj: any, field_name: string) {
    if (obj && (typeof obj === 'number' || !isEmpty(obj))) {
      this.payload[field_name] = obj;
    }
  }

  setField(obj: any, field_name: string) {
    this.payload[field_name] = obj;
  }

  setNestedFieldIfExists(obj: any, outter_field: string, inner_field: string) {
    if (obj && !isEmpty(obj)) {
      if (!this.payload[outter_field]) this.payload[outter_field] = {};
      this.payload[outter_field][inner_field] = obj;
    }
  }

  setDiagnosis() {
    const diagnosis: any[] = [];
    let is_primary = false;

    this.diagnosisDetails?.forEach((element: any) => {
      is_primary = element.row.is_primary ? element.row.is_primary : false;
      diagnosis.push({
        diagnosis_id: element?.row?.id,
        is_primary: is_primary,
      });
    });

    return diagnosis;
  }

  buildPayload() {
    const name = {
      first_name: this.formGroup.value?.submitted_by_name,
      last_name: '',
      middle_name: '',
      prefix: '',
      suffix: '',
      full_name: this.formGroup.value?.submitted_by_name,
    };
    const phone = {
      phone_number: parseInt(this.formGroup.value?.submitted_by_phone, 10),
    };

    const serviceArray: any[] = [];

    if (this.cardArray.length > 0) {
      for (let i = 0; i < this.cardArray.length; i++) {
        this.setFieldIfExists('', 'received_date');
        this.setFieldIfExists(
          this.cardArray[0].group_to_load?.value
            .confirmed_length_of_stay_admit_date,
          'confirmed_length_of_stay_admit_date ',
        );
        this.setFieldIfExists(
          this.cardArray[0].group_to_load?.value
            .confirmed_length_of_stay_discharge_date,
          'confirmed_length_of_stay_discharge_date',
        );
        const groupToLoadValue = this.cardArray[i]?.group_to_load?.value;

        const servicingProviderNetworkStatus = this.config.outOfNetwork
          ?.isOutOfNetworkEnabled
          ? groupToLoadValue?.servicing_provider?.hasOwnProperty(
              'member_network',
            )
            ? groupToLoadValue?.servicing_provider?.member_network
                ?.network_status || 'in'
            : this.rowData?.services
              ? this.rowData?.services[i]?.servicing_provider_network_status
              : null
          : null;

        const servicingFacilityNetworkStatus = this.config.outOfNetwork
          ?.isOutOfNetworkEnabled
          ? groupToLoadValue?.servicing_facility_id?.hasOwnProperty(
              'member_network',
            )
            ? groupToLoadValue?.servicing_facility_id?.member_network
                ?.network_status || 'in'
            : this.rowData?.services?.[i]?.servicing_facility_network_status
          : null;

        const {
          servicing_provider,
          servicing_facility_id,
          unit_type,
          requested_units,
          procedure_id,
          is_primary,
          cost,
          frequency,
          effective_date,
          termination_date,
        } = this.cardArray[i]?.group_to_load?.value || {};

        const {
          length_of_stay_admit_date,
          length_of_stay_discharge_date,
          length_of_stay_notes,
          length_of_stay_requested_days,
        } = this.cardArray[0]?.group_to_load?.value || {};

        const obj = {
          length_of_stay_admit_date,
          length_of_stay_discharge_date,
          length_of_stay_notes,
          length_of_stay_requested_days,
          servicing_provider_id: servicing_provider?.id,
          servicing_facility_location_id: servicing_facility_id?.id,
          unit_type,
          quantity: requested_units,
          procedure_id: procedure_id?.id,
          is_primary: is_primary ? is_primary : false,
          cost,
          frequency,
          effective_date,
          termination_date,
          servicing_provider_network_status:
            servicingProviderNetworkStatus ||
            this.rowData?.services?.[i]?.servicing_provider_network_status ||
            'in',
          servicing_facility_network_status:
            servicingFacilityNetworkStatus ||
            this.rowData?.services?.[i]?.servicing_facility_network_status ||
            'in',
        };

        serviceArray.push(obj);
      }
    }

    const requestingProviderNetworkStatus = this.config.outOfNetwork
      ?.isOutOfNetworkEnabled
      ? this.formGroup.value?.requesting_provider_id?.hasOwnProperty(
          'member_network',
        )
        ? this.formGroup.value?.requesting_provider_id?.member_network
            ?.network_status || 'in'
        : this.rowData?.requesting_provider_network_status
      : null;

    this.setFieldIfExists(this.rowData?.member_id, 'member_id');

    if (this.config?.allowLocationAsRequestingProvider) {
      const networkStatus =
        this.formGroup.value?.requesting_provider?.memberNetwork?.networkStatus;

      if (
        this.formGroup.value?.requesting_provider?.providerType ===
        ProviderType.provider
      ) {
        this.setFieldIfExists(
          this.formGroup.value?.requesting_provider.id,
          'requesting_provider_id',
        );
        this.setField(
          networkStatus ? networkStatus : null,
          'requesting_provider_network_status',
        );
        this.setField(null, 'requesting_facility_location_id');
        this.setField(null, 'requesting_facility_location_name');
        this.setField(null, 'requesting_facility_location_network_status');
      } else if (
        this.formGroup.value.requesting_provider?.providerType ===
        ProviderType.siteLocation
      ) {
        this.setFieldIfExists(
          this.formGroup.value?.requesting_provider.id,
          'requesting_facility_location_id',
        );
        this.setFieldIfExists(
          this.formGroup.value?.requesting_provider.name,
          'requesting_facility_location_name',
        );
        this.setField(
          networkStatus ? networkStatus : null,
          'requesting_facility_location_network_status',
        );
        this.setField(null, 'requesting_provider_id');
      }
    } else {
      this.setFieldIfExists(
        this.formGroup.value?.requesting_provider_id?.id,
        'requesting_provider_id',
      );
      this.setFieldIfExists(
        requestingProviderNetworkStatus,
        'requesting_provider_network_status',
      );
    }
    this.setFieldIfExists(this.setDiagnosis(), 'diagnoses');
    if (this.formGroup.value?.service_category) {
      this.setFieldIfExists(
        parseInt(this.formGroup.value?.service_category?.id, 10),
        'service_category_id',
      );
    }
    this.setFieldIfExists(this.formGroup.value?.priority, 'priority');
    this.setField(this.getDocumentIds(), uploadDocument.documentIds);
    this.setFieldIfExists(name, 'submitted_by_name');
    this.setFieldIfExists(phone, 'submitted_by_phone');
    this.setFieldIfExists(
      this.formGroup.value?.submitted_by_email,
      'submitted_by_email',
    );
    this.setField(serviceArray, 'services');
    this.setFieldIfExists(this.rowData?.status_reason, 'status_reason');
    this.setFieldIfExists(this.formGroup.value?.edit_reason, 'edit_reason');
    this.setFieldIfExists(
      this.formGroup.value?.additional_edit_reason,
      'edit_reason_additional_text',
    );
  }

  submit(): void {
    this.buildPayload();
    this.busy.push(
      this.api
        .patch(this.setAuthId(this.config.endpoints.submitApi), this.payload)
        .subscribe(
          (resp: any) => {
            this.response = resp;
            if (this.state === 'review') this.state = 'confirm';
            this.analyticsService.sendEvent(
              CX_CALLS.pp_authorization_edit_edited.event_key,
            );
          },
          (error: ZipBackendErrorResponse) => {
            this.toggleError();
            this.errorMessage = this.zipEndpointService.handleErrorMessages(
              error,
              editAuthConstants.defaultErrorMessages,
            );
          },
        ),
    );
  }

  getDefaultReviewBox(control: any) {
    if (control.type === 'date') {
      if (
        this.formGroup.get(control.prop) &&
        this.formGroup.get(control.prop).value
      ) {
        const value: any = this.formGroup.get(control.prop).value;
        const dateObj = new Date(`${value}T00:00:00`);

        return new Intl.DateTimeFormat('en-US').format(dateObj);
      } else {
        return this.noSpace;
      }
    }

    return this.formGroup.get(control.prop)
      ? this.formGroup.get(control.prop).value
        ? this.formGroup.get(control.prop).value
        : this.noSpace
      : this.noSpace;
  }

  preloadDocs(documents): void {
    const files = documents?.map((file: UploadDocument) => ({
      ...file,
      name: file?.file_name,
      size: file?.file_size,
      type: file?.file_type,
      lastModifiedDate: null,
      id: file?.id,
    }));

    if (files) {
      this.setFilesFromAPI(files);
      this.documentUploadService.uploadedFiles = [...files];
    }
    this.errorInDocumentUpload = false;
  }

  toggleError() {
    this.error = !this.error;
  }

  onClick(event) {
    this.showAlert = !this.showAlert;
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.genericServices.showFooter$.next(true);
    });
  }

  areProvidersOutOfNetwork(): boolean {
    const outOfNetworkProvider = ProvidersKeys.find((provider: string) => {
      if (provider === OutOfNetworkProps[toCamel(provider)]) {
        return this.cardArray.some(
          (cardItem: any) =>
            cardItem?.group_to_load?.value[provider]?.member_network
              ?.is_in_network === false,
        );
      } else {
        return (
          this.formGroup.value[provider]?.member_network?.is_in_network ===
          false
        );
      }
    });

    return !!outOfNetworkProvider;
  }

  showOutOfNetworkStatus(): boolean {
    return (
      this.config.outOfNetwork.isOutOfNetworkEnabled &&
      (this.rowData?.requesting_provider_network_status === OutNetworkStatus ||
        this.rowData?.requesting_facility_location_network_status ===
          OutNetworkStatus)
    );
  }

  fileUploaded(fileList: File[]): void {
    const documentType = this.formGroup?.get(
      uploadDocument.uploadDocumentType,
    )?.value;
    const uploadDocumentConfig =
      this.config?.cards.supporting_documentation_card?.controls?.upload_doc;
    const documentUploadUrl =
      uploadDocumentConfig?.documentUploadUrl ||
      uploadDocumentConfig?.documentCreate;

    if (documentUploadUrl) {
      this.documentUploadService.uploadFile(
        fileList,
        documentUploadUrl,
        documentType?.value,
      );
    }
  }

  removeFile(fileId: number): void {
    this.documentUploadService.removeFile(fileId);
    this.documentUploadService.uploadCompleted$.next(false);
  }

  public setFilesFromAPI(filesList: File[]): void {
    this.documentContext = new UntypedFormArray(
      filesList?.map((file: File) => new UntypedFormControl(file)),
    );
  }

  public getDocumentIds(): number[] {
    return (
      this.documentUploadService.uploadedFiles.map(
        (uploadDocument) => uploadDocument.id,
      ) || []
    );
  }

  public getDefaultUploadDocumentConfig(
    uploadDocumentConfig: UploadDocumentConfig,
  ): UploadDocumentConfig {
    return getDefaultFileUploaderConfig(uploadDocumentConfig);
  }
}
