import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, finalize, map, takeUntil } from 'rxjs';
import { MessageBannerConfig } from '@zipari/shared-ds-util-messages';
import { UserRole } from '../../../../../app.constants';
import { CX_CALLS } from '../../../../../shared/constants/cx-calls.constant';
import { ApiListResponse } from '../../../../../shared/models/shared/ApiListResponse.model';
import { AuthService, ConfigService } from '../../../../../shared/services';
import { AnalyticsService } from '../../../../../shared/services/analytics.service';
import SiteAttestationDataService from '../site-location-attestation-data.service';
import { SITELOCATION_CONSTANTS } from '../site-location-attestation.constant';
import {
  CxEngagementResponse,
  SiteLocationsAlertConfig,
} from '../site-locations-attestation.model';

@Component({
  selector: 'site-locations-alert',
  templateUrl: './site-locations-alert.component.html',
  styleUrls: ['./site-locations-alert.component.scss'],
})
export class SiteLocationsAlertComponent implements OnInit, OnDestroy {
  config: SiteLocationsAlertConfig;
  response$: Observable<ApiListResponse<CxEngagementResponse>>;
  alerts$: Observable<MessageBannerConfig[]>;
  public isLoading = false;
  private destroy = new Subject();

  constructor(
    private configService: ConfigService,
    private analyticsService: AnalyticsService,
    private siteAttestationDataService: SiteAttestationDataService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    const siteLocationConfig = this.configService.getPageConfig(
      SITELOCATION_CONSTANTS.siteLocationsAttestation,
    );

    if (siteLocationConfig) {
      this.config =
        siteLocationConfig[SITELOCATION_CONSTANTS.siteLocationsAlert];
    }
    if (this.isProviderAdmin()) {
      this.setSiteLocationAlertConfig();
    }
  }

  isProviderAdmin(): boolean {
    return this.authService.userRole === UserRole.providerAdmin;
  }

  setSiteLocationAlertConfig(): void {
    this.isLoading = true;
    this.alerts$ = this.siteAttestationDataService
      .getCxEngagement(this.config)
      .pipe(
        map((engagementRecommendation: ApiListResponse<CxEngagementResponse>) =>
          this.getAlertsConfig(engagementRecommendation.results),
        ),
        takeUntil(this.destroy),
        finalize(() => {
          this.isLoading = false;
        }),
      );
  }

  getAlertsConfig(
    engagementRecommendation: CxEngagementResponse[],
  ): MessageBannerConfig[] {
    return engagementRecommendation.map(
      (recommendation: CxEngagementResponse) => {
        const { alertTypeMapping, alertConfig } = this.config;
        const templateKey = recommendation.engagement.template.key;
        const alertType = Object.keys(alertTypeMapping).find(
          (key: string) => alertTypeMapping[key] === templateKey,
        );

        const bannerConfigKey = Object.keys(alertConfig).find(
          (key: string) =>
            alertConfig[key][SITELOCATION_CONSTANTS.type] === alertType,
        );

        let messageBannerConfig: MessageBannerConfig =
          alertType && bannerConfigKey
            ? alertConfig[bannerConfigKey]
            : alertConfig.warningBanner;

        messageBannerConfig = {
          ...messageBannerConfig,
          message: recommendation.engagement.data.notification_title,
        };

        return messageBannerConfig;
      },
    );
  }

  closeMessageBanner(): void {
    this.analyticsService.sendEvent(
      CX_CALLS.pp_attestation_alert_closed.event_key,
    );
    this.setSiteLocationAlertConfig();
  }

  ngOnDestroy(): void {
    this.destroy.next(void 0);
    this.destroy.complete();
  }
}
