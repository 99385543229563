import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { stringBuilder } from '@zipari/web-utils';
import { Subscription } from 'rxjs';
import { APIService } from '@zipari/web-services';
import { ProviderUpdateToolService } from '../../../provider-update-tool/provider-update-tool.service';
import { AnalyticsService } from '../../../../../shared/services/analytics.service';

@Component({
  selector: 'custom-card',
  templateUrl: './custom-card.component.html',
  styleUrls: ['./custom-card.component.scss'],
})
export class CustomCardComponent implements OnInit {
  @Input() config: any = {};
  busy: Subscription;
  results: any[] = [];
  columns: any[] = [];
  currentPage = 1;
  pageLength = 10;
  totalResultsCount = 0;
  error = false;
  noSpace = 'n/a';

  constructor(
    private fb: UntypedFormBuilder,
    private api: APIService,
    public mp: ProviderUpdateToolService,
    private router: Router,
    public analyticsService: AnalyticsService,
  ) {}

  get paginatorConfig() {
    const resultsPerPage = this.pageLength;
    const startingPageNumber: number =
      this.currentPage * resultsPerPage - resultsPerPage + 1;
    const endingPageNumber: number =
      startingPageNumber <= this.totalResultsCount - resultsPerPage
        ? startingPageNumber + resultsPerPage - 1
        : this.totalResultsCount;
    const paginationConfig = {
      rowsPerPage: resultsPerPage,
      showingStart: startingPageNumber,
      showingEnd: endingPageNumber,
      currentPage: this.currentPage,
      totalPages: Math.ceil(this.totalResultsCount / resultsPerPage),
      totalResults: this.totalResultsCount,
    };

    return paginationConfig;
  }

  goToPage(page: any) {
    this.currentPage = page;
    this.updateResults(page);
  }

  ngOnInit() {
    this.config.columns.forEach((item: any) => {
      this.columns.push(item);
    });
    if (this.config?.noSpace) this.noSpace = this.config?.noSpace;
    if (this.config?.apiEndpoint) {
      if (this.config.pageSize) this.pageLength = this.config.pageSize;
      this.updateResults();
    }
  }
  updateResults(page = 1) {
    const url: string =
      this.config.apiEndpoint.slice(-1) === '/'
        ? `${this.config.apiEndpoint}?page=${page}&page_size=${this.pageLength}`
        : `${this.config.apiEndpoint}&page=${page}&page_size=${this.pageLength}`;

    this.busy = this.api.get(url).subscribe(
      (response: any) => {
        this.results = response.results;
        this.totalResultsCount = response['count'];
        if (!this.results.length) this.error = true;
      },
      (err: Error) => {
        this.error = true;
      },
    );
  }

  getObjectProperty(obj, key) {
    const templateMatcher = /(\${)([a-zA-Z0-9\/._]+)(})/g;
    const matches = key.match(templateMatcher);

    if (matches) return stringBuilder(key, obj);
    else {
      return key.split('.').reduce(function (result, _key) {
        return result[_key];
      }, obj);
    }
  }

  buttonClicked(redirectTO) {
    this.router.navigate([redirectTO]);
  }

  setActiveRow(row, routeProp) {
    const baseRoute = routeProp?.routeToProp.slice(
      0,
      routeProp?.routeToProp.indexOf('$'),
    );
    const propToReplace = routeProp?.routeToProp.match(/\{([^}]+)\}/);
    const uniqueIdentifierKey = this.getObjectProperty(row, propToReplace[1]);

    localStorage.setItem('member_id', row?.member_id);
    this.router.navigate([baseRoute, uniqueIdentifierKey]);
    routeProp?.cx_event_key
      ? this.analyticsService.sendEvent(routeProp?.cx_event_key)
      : '';
  }
}
