<div class="u-relative">
  <div
    *ngIf="config.description"
    class="FormGroupArray__Description t-data t-bold"
  >
    {{ config.description }}
  </div>

  <ng-template #noGroups>
    <error-empty *ngIf="config.errorEmpty" [config]="config.errorEmpty">
    </error-empty>
    <zip-button
      *ngIf="config.showAddButtonWhenNoGroups"
      [config]="config.addButton"
      (zipButtonClicked)="addGroup()"
    ></zip-button>
  </ng-template>
  <ng-container *ngIf="formArray.controls?.length > 0; else noGroups">
    <div *ngIf="config.showFormWithAccordion; else cardView">
      <form-group-array-accordion-content
        [formArray]="formArray"
        [config]="config"
        [context]="context"
        [openedAccordionIndex]="openedAccordionIndex"
        (addDuplicateClicked)="addDuplicate($event)"
        (removeGroupClicked)="removeGroup($event)"
      >
      </form-group-array-accordion-content>
    </div>
    <ng-template #cardView>
      <form-group-array-content
        [formArray]="formArray"
        [config]="config"
        [context]="context"
        (addDuplicateClicked)="addDuplicate($event)"
        (removeGroupClicked)="removeGroup($event)"
      >
      </form-group-array-content>
    </ng-template>
    <form-group-array-footer
      [config]="config"
      [formArray]="formArray"
      [context]="context"
      (addFormGroupClicked)="addGroup()"
    ></form-group-array-footer>
  </ng-container>
</div>
