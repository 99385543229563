import { Component, Input, OnInit } from '@angular/core';
import linkifyStr from 'linkifyjs/string';
import { Subscription } from 'rxjs';
import { APIService } from '@zipari/web-services';
import { ProviderUpdateToolService } from '../../../templates/provider-update-tool/provider-update-tool.service';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { OutOfNetworkConfig } from '../../../templates/authorization-submission/authorization-submission.model';

@Component({
  selector: 'flyout-dropdown',
  templateUrl: './flyout-dropdown.component.html',
  styleUrls: ['./flyout-dropdown.component.scss'],
})
export class FlyoutDropdownComponent implements OnInit {
  @Input() config: any;
  @Input() group: any;
  @Input() outOfNetwork: OutOfNetworkConfig;
  showModal = false;
  selectedItem: any = null;
  busy: Subscription;
  loaded = false;
  noSpace = 'n/a';
  showOutOfNetworkModal = false;

  constructor(
    public mp: ProviderUpdateToolService,
    private api: APIService,
    public analyticsService: AnalyticsService,
  ) {}

  get showOutOfNetworkStatusPill(): boolean {
    const groupValue = this.group?.get(this.config?.prop)?.value;
    const isOutOfNetworkEnabled = this.outOfNetwork?.isOutOfNetworkEnabled;
    const isInNetworkStatus = groupValue?.member_network?.is_in_network;

    return isOutOfNetworkEnabled && isInNetworkStatus === false;
  }

  ngOnInit() {
    if (this.config?.subheader) {
      const options = {
        /* … */
      };

      this.config.subheader = linkifyStr(this.config.subheader, options);
    }
    if (this.config?.noSpace) this.noSpace = this.config?.noSpace;
    if (this.config?.flyout?.table?.filters) {
      const filters: any[] = this.config?.flyout?.table?.filters;
      let hasNoEndpoints = true;

      for (const filter of filters) {
        if (filter.type === 'select' && filter.apiEndpoint) {
          hasNoEndpoints = false;
          this.busy = this.api
            .get(filter.apiEndpoint)
            .subscribe((resp: any) => {
              filter.options = this.populateDropdown(
                resp,
                filter.displayProp,
                filter.jsonValue,
              );
              this.loaded = true;
            });
        }
      }
      if (hasNoEndpoints) this.loaded = true;
    }

    if (
      this.config?.flyout?.table &&
      this.outOfNetwork?.isOutOfNetworkEnabled
    ) {
      if (this.config.flyout.table.outOfNetworkApiEndpoint) {
        this.config.flyout.table = {
          ...this.config.flyout.table,
          endpoint: this.config.flyout.table.outOfNetworkApiEndpoint,
        };
      }
    } else if (this.config?.flyout?.table) {
      Object.keys(this.config.flyout.table?.columns).forEach((item: string) => {
        if (
          this.config.flyout.table.columns[item]?.prop?.includes(
            'is_in_network',
          )
        ) {
          delete this.config.flyout.table.columns[`${item}`];
        }
      });
    }
  }

  selectedProvider(provider: any): void {
    if (provider?.member_network?.is_in_network === false) {
      this.selectedItem = provider;
      this.showOutOfNetworkModal = true;
    }
  }

  populateDropdown(resp: any, labelProp: string, valueProp: string) {
    const options = [];

    if (resp.results && Array.isArray(resp.results)) {
      resp.results.forEach(function (record, index) {
        if (valueProp) {
          options.push({
            label: record[labelProp],
            value: record[valueProp],
          });
        } else {
          options.push({
            label: record[labelProp],
            value: record,
          });
        }
      });
    }

    return options;
  }

  toggleFlyout() {
    this.showModal = !this.showModal;
    this.selectedItem = null;
  }

  remove(cxKey?: string): void {
    if (cxKey) {
      const cxObj = {
        procedure: this.group?.get(this.config?.prop).value?.code,
      };

      this.analyticsService.sendEvent(cxKey, cxObj);
    }
    this.group?.get(this.config?.prop).reset();
  }

  selectedRadioRow(event: any) {
    this.selectedItem = event;
  }

  add() {
    if (
      this.outOfNetwork?.isOutOfNetworkEnabled &&
      this.selectedItem?.member_network?.is_in_network === false
    ) {
      this.showOutOfNetworkModal = true;
    } else if (this.selectedItem) {
      this.group?.get(this.config.prop).setValue(this.selectedItem);
      this.toggleFlyout();
    }
  }

  confirm(): void {
    this.group?.get(this.config.prop).setValue(this.selectedItem);
    this.showModal = false;
    this.showOutOfNetworkModal = false;
    this.selectedItem = null;
  }

  closeOutOfNetworkModal(): void {
    this.showOutOfNetworkModal = false;
    this.group?.get(this.config.prop).setValue(null);
  }

  isServicingReferringProvider(): boolean {
    return [
      'requesting_provider_id',
      'servicing_provider',
      'servicing_provider_id',
      'referring_provider_id',
    ].includes(this.config.prop);
  }

  isRequired(): boolean {
    return (
      this.config &&
      this.config.validators &&
      this.config.validators.find(
        (validator) =>
          validator === 'required' ||
          (validator.name && validator.name === 'required'),
      )
    );
  }
}
