/* eslint-disable arrow-parens */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { AllControlsConfiguration } from '@zipari/shared-ds-util-form';
import { APIService } from '@zipari/web-services';
import * as moment from 'moment';
import { identity, isEmpty, pickBy } from 'lodash';

import { toCamel } from '../../../../../shared/utilities/case-conversion.utils';
import { ConfigService } from '../../../../../shared/services';
import { GenericServices } from '../../../../shared/services/generic.service';
import { stateOptions } from '../../controls/address.constants';
import { LocationHoursComponent } from '../../controls/location-hours/location-hours.component';
import { ProviderUpdateToolService } from '../../provider-update-tool.service';
import {
  PhoneNumbers,
  PhoneNumbersModel,
} from '../../../../../shared/models/shared/ManageSiteLocation';
import { AnalyticsService } from '../../../../../shared/services/analytics.service';
import { PROVIDER_IDENTIFIERS } from '../../site-locations-attestation/site-location-attestation.constant';
import { ProviderIdentifierPayload } from '../../site-locations-attestation/site-locations-attestation.model';

@Component({
  styleUrls: ['./manage-site-location-add.component.scss'],
  selector: 'manage-site-location-add',
  templateUrl: './manage-site-location-add.component.html',
})
export class ManageSiteLocationAddComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @ViewChild(LocationHoursComponent) locationHours: LocationHoursComponent;

  busy: any[] = [];
  config: any;
  state: 'one' | 'two' | 'three' | 'four' | 'five' = 'one';
  addState: 'add' | 'review' = 'add';
  stepOneFields = new UntypedFormGroup({});
  stepTwoFields = new UntypedFormGroup({});
  stepThreeFields = new UntypedFormGroup({});
  location_hours_form = new UntypedFormGroup({});
  showModal = false;
  selectedRows: any[] = [];
  associateFields: any[] = [];
  taxIdNpiMap: Map<any, any> = new Map<any, any>();
  taxIdIdMap: Map<any, any> = new Map<any, any>();
  npiTaxIdIndex: Map<any, any> = new Map<any, any>();
  createResponse: any;
  enabled: any[] = [];
  associatedFacility: any = null;
  associateResponse: any = {};
  error = false;
  taxIdSelected: Subscription;
  npiSelected: Subscription;

  constructor(
    private api: APIService,
    private el: ElementRef,
    private router: Router,
    private fb: UntypedFormBuilder,
    private location: Location,
    public configService: ConfigService,
    private cdr: ChangeDetectorRef,
    public mp: ProviderUpdateToolService,
    public genericServices: GenericServices,
    public analyticsService: AnalyticsService,
  ) {}

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.genericServices.showFooter$.next(false);
    });
    this.config = this.configService.getPageConfig('site-locations');
    const editArrayFields = {};
    const facility_list: Observable<any> = this.api.get(
      this.config?.endpoints?.tax_id_list,
    );

    if (this.config?.createSteps) {
      for (const [, _value] of Object.entries(this.config?.createSteps)) {
        const value: any = _value;

        if (value.sections) {
          for (const section of value.sections) {
            if (section.array_controls) {
              for (const control of section.array_controls) {
                const validators: any[] = this.mp.getValidators(
                  control.validatorType,
                );

                if (control.required) validators.push(Validators.required);
                editArrayFields[control.prop] = this.fb.array([
                  this.fb.group({ selection: ['', validators] }),
                ]);
              }
            }
            if (section.controls) {
              for (const control of section.controls) {
                if (control.apiEndpoint) {
                  this.busy.push(
                    this.api.get(control.apiEndpoint).subscribe((resp: any) => {
                      control.options = this.populateDropdown(
                        new Array<any>(),
                        resp.results,
                        control.dropdownDisplayProp,
                      );
                    }),
                  );
                }
                if (control.dataType === 'tax_id') {
                  this.busy.push(
                    facility_list.subscribe(
                      (resp: any) => {
                        for (const item of resp.results) {
                          let npiList: Set<any> = new Set<any>();
                          const tax_id: any = this.getObjectProperty(
                            item,
                            control.dropdownDisplayProp,
                          );
                          const id: any = this.getObjectProperty(item, 'id');
                          const npi: any = this.getObjectProperty(item, 'npi');

                          this.npiTaxIdIndex.set(`${tax_id}|${npi}`, id);
                          this.taxIdIdMap.set(tax_id, id);
                          if (this.taxIdNpiMap.get(tax_id)) {
                            npiList = this.taxIdNpiMap.get(tax_id);
                            npiList.add(npi);
                          } else {
                            npiList.add(npi);
                            this.taxIdNpiMap.set(tax_id, npiList);
                          }
                        }
                        const options: any[] = new Array<any>();

                        for (const item of this.taxIdNpiMap.keys()) {
                          options.push({
                            label: item,
                            value: item,
                          });
                        }
                        control.options = options;
                        if (this.getControl(control.enableProp)) {
                          this.getControl(control.enableProp).disable();
                        }
                        if (this.getControl(control.enableProp2)) {
                          this.getControl(control.enableProp2).disable();
                          this.turnOffField(
                            section.controls,
                            control.enableProp2,
                            true,
                          );
                        }
                        const npiControl: any = section.controls.find(
                          (item: any) => item.prop === control.enableProp,
                        );

                        npiControl.options = [];
                        this.taxIdSelected = this.getControl(
                          control.prop,
                        ).valueChanges.subscribe((taxIdSelected: any) => {
                          if (taxIdSelected) {
                            if (this.getControl(control.enableProp).disabled) {
                              this.getControl(control.enableProp).enable();
                            }
                            // eslint-disable-next-line prettier/prettier
                            const getOptions: any[] = Array.from(
                              this.taxIdNpiMap.get(taxIdSelected),
                            ).map((itemValue: any) => ({
                              label: itemValue,
                              value: itemValue,
                            }));

                            if (this.getControl(control.enableProp2)) {
                              getOptions.unshift({
                                label: 'Custom Entry',
                                value: 'custom',
                              });
                            }
                            section.controls.find(
                              (item: any) => item.prop === control.enableProp,
                            ).options = getOptions;
                            if (this.getControl('npi')) {
                              this.getControl('npi').reset();
                            }
                            if (this.getControl('npi_input')) {
                              this.getControl('npi_input').reset();
                            }
                          }
                        });

                        this.npiSelected = this.getControl(
                          control.enableProp,
                        ).valueChanges.subscribe((npiSelected: any) => {
                          if (this.getControl(control.enableProp2)) {
                            if (
                              npiSelected === 'custom' &&
                              this.getControl(control.enableProp2).disabled
                            ) {
                              this.turnOffField(
                                section.controls,
                                control.enableProp2,
                                false,
                              );
                              this.getControl(control.enableProp2).enable();
                            } else if (
                              this.getControl(control.enableProp2).enabled
                            ) {
                              this.turnOffField(
                                section.controls,
                                control.enableProp2,
                                true,
                              );
                              this.getControl(control.enableProp2).disable();
                            }
                          }
                        });

                        if (options?.length === 1) {
                          this.getControl(control.prop).setValue(
                            options[0].value,
                          );
                        }
                      },
                      (error) => {
                        control.options = [];
                        this.toggleError();
                      },
                    ),
                  );
                }
                if (
                  control.type === 'basicAddress' &&
                  control.cityStateZipConfig?.length
                ) {
                  const stateControl = control.cityStateZipConfig.find(
                    (item) => item.prop === 'state',
                  );

                  if (stateControl) {
                    stateControl.options = stateOptions;
                  }
                }
              }
            }
          }
        }
      }
    }
    if (this.config?.associateFlyout?.controls) {
      for (const control of this.config?.associateFlyout?.controls) {
        if (control.apiEndpoint) {
          this.busy.push(
            this.api.get(control.apiEndpoint).subscribe((resp: any) => {
              control.options = this.populateDropdown(
                new Array<any>(),
                resp.results,
                control.dropdownDisplayProp,
              );
            }),
          );
        }
      }
    }

    this.mp.setFormGroup('addLocation', this.fb.group(editArrayFields));
  }

  turnOffField(controls, control, boolVal) {
    control = controls.find((item: any) => control === item.prop);
    control.hidden = boolVal;
  }

  cancel(): void {
    if (this.genericServices.previousURL?.includes('provider-portal')) {
      this.location.back();
    }
  }

  return(): void {
    this.router.navigate(['provider-portal/site-locations/']);
  }

  back(): void {
    if (this.state === 'two') {
      this.state = 'one';
      this.associatedFacility = null;
      if (this.getControl('tax_id')) this.getControl('tax_id')?.reset();
      if (this.getControl('npi')) this.getControl('npi')?.reset();
      if (this.getControl('npi_input')) this.getControl('npi_input')?.reset();
      if (this.getControl('npi')) this.getControl('npi')?.disable();
    } else if (this.state === 'three') this.state = 'two';
    else if (this.state === 'four') this.state = 'three';
  }

  checkLocations() {
    if (this.locationHours) {
      return this.locationHours.validate();
    } else return true;
  }

  continue(): void {
    const first: boolean = this.checkLocations();
    const second: boolean = this.getSectionFormValid(this.state);

    if (first && second) {
      if (this.getSectionForm(this.state)?.value) {
        window.scrollTo(0, 0);
        if (this.state === 'one') {
          // create facility if that succeeds then move forward, else error
          this.createFacilityIfNeeded();
        } else if (this.state === 'two') {
          this.state = 'three';
          this.enabled = this.locationHours.getEnabled();
        } else if (this.state === 'three') this.state = 'four';
      }
    } else {
      this.getSectionForm(this.state)?.markAllAsTouched();
      this.mp.getFormGroup('addLocation')?.markAllAsTouched();
      setTimeout(() => {
        this.genericServices.scrollToFirstInvalidControl(this.el);
      });
    }
  }

  onExit() {
    const enabled: any[] = this.locationHours
      ? this.locationHours.getEnabled()
      : this.enabled;
    const days: any[] = [];

    Object.entries(this.location_hours_form.value).forEach((value: any) => {
      if (enabled?.find((item: any) => item === value[0])) {
        days.push({
          opening_time: value[1]['start'],
          day: value[0],
          closing_time: value[1]['end'],
        });
      }
    });
    if (this.config?.createSteps) {
      for (const [, _value] of Object.entries(this.config?.createSteps)) {
        const value: any = _value;

        if (value.sections) {
          for (const section of value.sections) {
            if (section.controls && section?.type === 'location_hours') {
              for (const control of section.controls) {
                if (
                  !days.find((item: any) => {
                    if ((item.day as string)?.toLowerCase() === control.prop) {
                      control['start'] = item.opening_time;
                      control['end'] = item.closing_time;

                      return true;
                    } else return false;
                  })
                ) {
                  delete control['start'];
                  delete control['end'];
                }
              }
            }
          }
        }
      }
    }
  }

  values: any = {};
  postInit() {
    if (this.config?.createSteps) {
      for (const [_key, _value] of Object.entries(this.config?.createSteps)) {
        const key: any = _key;

        if (this.state === _key) {
          const value: any = _value;

          if (value.sections) {
            for (const section of value.sections) {
              if (section.controls) {
                for (const control of section.controls) {
                  const configControls: any = section.controls;

                  if (
                    control.conditionalRequired &&
                    this.getControl(control.prop)
                  ) {
                    const formControl: any = this.getControl(control.prop);

                    if (formControl.controls) {
                      Object.values(formControl.controls).forEach(
                        (_control) => {
                          this.updateValue(control, _control, configControls);
                        },
                      );
                    } else this.updateValue(control, null, configControls);
                  }
                  if (control.enableOnTrue) {
                    if (this.stepTwoFields.controls[control.enableOnTrue]) {
                      this.stepTwoFields.controls[
                        control.enableOnTrue
                      ].disable();
                      if (this.stepTwoFields.controls[control.prop]) {
                        this.stepTwoFields.controls[
                          control.prop
                        ].valueChanges.subscribe((controlvalue: any) => {
                          if (controlvalue) {
                            this.stepTwoFields.controls[
                              control.enableOnTrue
                            ].enable();
                          } else {
                            this.stepTwoFields.controls[
                              control.enableOnTrue
                            ].reset();
                            this.stepTwoFields.controls[
                              control.enableOnTrue
                            ].disable();
                          }
                        });
                      }
                    }
                  }
                  if (control.defaultPrepop) {
                    let defaultValue = this.getControl(
                      control.defaultPrepop,
                    )?.value;

                    if (
                      defaultValue === 'custom' &&
                      control.defaultPrepop === 'npi'
                    ) {
                      defaultValue = this.getControl('npi_input')?.value;
                    }
                    if (this.getActiveControl(this.state, control.prop)) {
                      this.getActiveControl(this.state, control.prop)?.setValue(
                        defaultValue,
                      );
                    }
                    control['default'] = defaultValue;
                  }
                  if (control.disableControls) {
                    this.stepTwoFields.controls[
                      control.disableControls
                    ]?.disable();
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  updateValue(control: any, formControl: any, configControls: any) {
    if (!formControl) formControl = this.getControl(control.prop);
    formControl.valueChanges.subscribe((value: any) => {
      if (this.values[control.prop] !== value && value?.length) {
        this.values[control.prop] = value;
        this.applyValidators(control);
      } else if (
        this.values[control.prop] !== value &&
        (!value || !value?.length)
      ) {
        this.values[control.prop] = value;
        this.removeValidators(control, configControls);
      }
    });
  }

  applyValidators(control: any) {
    for (const toset of control.conditionalRequired) {
      const _control: any = this.getControl(toset);

      if (_control?.controls) {
        Object.keys(_control.controls).forEach((key) => {
          const subcontrol = _control.controls[key];
          // these are hard coded even in design system:

          if (key !== 'street_name_2' && subcontrol) {
            subcontrol.setValidators([Validators.required]);
            subcontrol.updateValueAndValidity();
          }
        });
      } else {
        _control?.setValidators([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(10),
        ]);
        _control?.updateValueAndValidity();
      }
    }
  }

  removeValidators(control: any, configControls: any) {
    for (const toset of control.conditionalRequired) {
      // eslint-disable-next-line no-shadow
      const _control: any = this.getControl(toset);
      const checkBeforeRemoving: any[] = configControls.map(
        (item) => item.prop,
      );
      let needsUnset = true;

      if (checkBeforeRemoving) {
        for (const check of checkBeforeRemoving) {
          const controlIfEmpty: any = this.getControl(check);

          if (controlIfEmpty?.controls) {
            Object.keys(controlIfEmpty.controls).forEach((key) => {
              const subcontrol = controlIfEmpty.controls[key];

              if (subcontrol?.value?.length) {
                needsUnset = false;
              }
            });
          } else {
            if (controlIfEmpty?.value?.length) {
              needsUnset = false;
            }
          }
        }
      }
      if (needsUnset) {
        if (_control?.controls) {
          Object.keys(_control.controls).forEach((key) => {
            const subcontrol = _control.controls[key];

            subcontrol?.setValidators([]);
            subcontrol?.updateValueAndValidity();
          });
        } else {
          _control?.setValidators([]);
          _control?.updateValueAndValidity();
        }
      }
    }
  }

  createFacilityIfNeeded() {
    const simpleValuesPageOne: any = this.getSectionForm('one')?.value;

    if (simpleValuesPageOne.npi_input) {
      // create facility
      const payload: any = {
        tax_id: simpleValuesPageOne.tax_id,
        npi: simpleValuesPageOne.npi_input,
      };
      const facilities: Observable<any> = this.api.post(
        this.config.endpoints.tax_id_list,
        payload,
      );

      this.busy.push(
        facilities.subscribe(
          (response) => {
            this.associatedFacility = response.id;
            this.state = 'two';
          },
          () => {
            this.toggleError();
          },
        ),
      );
      // wait
      // store
    } else {
      // use parent
      this.associatedFacility = this.npiTaxIdIndex.get(
        `${simpleValuesPageOne.tax_id}|${simpleValuesPageOne.npi}`,
      );
      this.state = 'two';
    }
  }

  getUniqueComplexValuesDetail(
    complexValuesArray: any,
    key: string,
    field: string,
  ) {
    let getComplexValues;
    const fieldValue = {
      fax: 'fax',
      taxonomy: 'taxonomy',
      affiliated_hospital: 'affiliated_hospital',
      phone: 'phone',
    };

    if (field === fieldValue.phone) {
      getComplexValues = complexValuesArray
        .map((item) => {
          if (item.selection) {
            return { phone_number: item.selection, extension: 1 };
          }
        })
        .filter(this.notEmpty);
    } else if (field === fieldValue.affiliated_hospital) {
      getComplexValues = complexValuesArray
        .map((item) => {
          if (item.selection) return item.selection.id;
        })
        .filter(this.notEmpty);
    } else {
      getComplexValues = complexValuesArray
        .map((item) => {
          if (item.selection) return item.selection;
        })
        .filter(this.notEmpty);
    }

    if (
      field === fieldValue.fax ||
      field === fieldValue.taxonomy ||
      field === fieldValue.affiliated_hospital
    ) {
      return getComplexValues.filter((item, i, arr) => arr.indexOf(item) === i);
    } else {
      return [
        ...new Map(getComplexValues.map((item) => [item[key], item])).values(),
      ];
    }
  }

  submit() {
    if (this.getSectionFormValid(this.state)) {
      if (this.getSectionForm(this.state)?.value) {
        const simpleValuesPageOne: any = this.getSectionForm('one')?.value;
        const simpleValuesPageTwo: any = this.getSectionForm('two')?.value;
        const simpleValuesPageThree: any = this.getSectionForm('three')?.value;
        const complexValues: any = this.mp.getFormGroup('addLocation').value;
        const locationValues: any = this.location_hours_form.value;

        let payload: any = {
          tax_id: simpleValuesPageOne.tax_id,
          npi: simpleValuesPageOne.npi_input
            ? simpleValuesPageOne.npi_input
            : simpleValuesPageOne.npi,
          facility_locations: [],
        };

        let facility_location: any = {
          name: simpleValuesPageTwo.facility_name,
          type: simpleValuesPageTwo.facility_type,
          address: {
            city_name: simpleValuesPageTwo.facility_address?.city_name,
            state: simpleValuesPageTwo.facility_address?.state,
            street_name_1: simpleValuesPageTwo.facility_address?.street_name_1,
            street_name_2: simpleValuesPageTwo.facility_address?.street_name_2,
            zip_code: simpleValuesPageTwo.facility_address?.zip_code,
          },
          is_primary: simpleValuesPageTwo.is_primary,
          website_url: simpleValuesPageTwo.website_url,
          tax_id: simpleValuesPageOne.tax_id,
          effective_date: simpleValuesPageTwo.effective_date
            ? simpleValuesPageTwo.effective_date
            : simpleValuesPageThree.effective_date,
          termination_date: simpleValuesPageTwo.termination_date
            ? simpleValuesPageTwo.termination_date
            : simpleValuesPageThree.termination_date,
          npi: simpleValuesPageOne.npi_input
            ? simpleValuesPageOne.npi_input
            : simpleValuesPageOne.npi,
          // "state"
          // TODO: In a rush for ucare sit date, refactor this and use pregnancyform as reference
          medicare_id: simpleValuesPageTwo.medicare_id,
          identifiers: this.getProviderIdentifiers(simpleValuesPageTwo),
          specialties: complexValues.specialties
            ? this.getUniqueComplexValuesDetail(
                complexValues.specialties,
                'id',
                'specialties',
              )
            : null,
          phone_numbers: complexValues.phone_numbers
            ? this.getUniqueComplexValuesDetail(
                complexValues.phone_numbers,
                'phone_number',
                'phone',
              )
            : null,
          fax_numbers: complexValues.fax_numbers
            ? this.getUniqueComplexValuesDetail(
                complexValues.fax_numbers,
                'selection',
                'fax',
              )
            : null,
          taxonomy_codes: complexValues.taxonomy_codes
            ? this.getUniqueComplexValuesDetail(
                complexValues.taxonomy_codes,
                'selection',
                'taxonomy',
              )
            : null,
          languages: complexValues.languages
            ? this.getUniqueComplexValuesDetail(
                complexValues.languages,
                'code',
                'language',
              )
            : null,
          restrictions: complexValues.restrictions
            ? this.getUniqueComplexValuesDetail(
                complexValues.restrictions,
                'id',
                'restrictions',
              )
            : null,
          services: complexValues.services
            ? this.getUniqueComplexValuesDetail(
                complexValues.services,
                'code',
                'services',
              )
            : null,
          affiliated_hospital_facility_ids: complexValues.facility_list
            ? this.getUniqueComplexValuesDetail(
                complexValues.facility_list,
                'id',
                'affiliated_hospital',
              )
            : null,
          operating_hours: this.setOperatingHours(locationValues),
          billing_details: this.setBillingDetails(simpleValuesPageThree),
          third_part_billing_details: this.setThirdPartyBilling(
            simpleValuesPageThree,
          ),
          location_contacts: this.setLocationContacts(
            simpleValuesPageTwo,
            complexValues,
          ),
          administration_attributes: this.setAdminAttrib(simpleValuesPageTwo),
          facility_id: this.associatedFacility,
          number: simpleValuesPageTwo.number,
          healthcare_system: this.setCaresystem(),
        };

        payload = JSON.parse(JSON.stringify(payload));
        facility_location = JSON.parse(JSON.stringify(facility_location));
        if (facility_location && facility_location['address']) {
          Object.keys(facility_location['address']).forEach(
            (key) =>
              (facility_location['address'][key] === null ||
                facility_location['address'][key] === undefined) &&
              delete facility_location['address'][key],
          );
        }

        const cleanedObject = pickBy(facility_location, identity);
        const _cleanedObject = pickBy(cleanedObject, this.isEmptyArray);
        const __cleanedObject = pickBy(_cleanedObject, this.notEmpty);

        if (__cleanedObject && __cleanedObject['address']) {
          __cleanedObject['address']['address_type'] = 'physical';
        }
        payload['facility_locations'].push(__cleanedObject);

        const enabled: any[] = this.locationHours
          ? this.locationHours.getEnabled()
          : this.enabled;
        const hours: any[] = [];

        if (
          payload.facility_locations &&
          payload.facility_locations[0].operating_hours
        ) {
          Object.entries(this.location_hours_form.value).forEach((value) => {
            if (enabled.find((item) => item === value[0])) {
              hours.push({
                opening_time: moment(value[1]['start'], 'HH:mm').format(
                  'hh:mm a',
                ),
                day: value[0],
                closing_time: moment(value[1]['end'], 'HH:mm').format(
                  'hh:mm a',
                ),
              });
            }
          });
          payload.facility_locations[0].operating_hours = hours;
        }
        if (
          payload.facility_locations &&
          payload.facility_locations[0].billing_details
        ) {
          payload.facility_locations[0].billing_information = [];
          const billing_details: any = {
            tax_id: payload.facility_locations[0].billing_details.tax_id,
            name: payload.facility_locations[0].billing_details.name,
            phone_number:
              payload.facility_locations[0].billing_details.phone_number,
            address: {
              street_name_1:
                payload.facility_locations[0].billing_details.address
                  ?.street_name_1,
              street_name_2:
                payload.facility_locations[0].billing_details.address
                  ?.street_name_2,
              city_name:
                payload.facility_locations[0].billing_details.address
                  ?.city_name,
              zip_code:
                payload.facility_locations[0].billing_details.address?.zip_code,
              state:
                payload.facility_locations[0].billing_details.address?.state,
              address_type: 'physical',
            },
            npi: payload.facility_locations[0].billing_details.npi,
            is_third_party: false,
          };

          if (billing_details && billing_details['address']) {
            Object.keys(billing_details['address']).forEach(
              (key) =>
                (billing_details['address'][key] === null ||
                  billing_details['address'][key] === undefined) &&
                delete billing_details['address'][key],
            );
          }
          payload.facility_locations[0].billing_information.push(
            pickBy(billing_details, this.isEmptyArray),
          );
          delete payload.facility_locations[0]['billing_details'];
        }
        if (
          payload.facility_locations &&
          payload.facility_locations[0].third_part_billing_details
        ) {
          if (!payload.facility_locations[0].billing_information) {
            payload.facility_locations[0].billing_information = [];
          }
          const third_part_billing_details: any = {
            name: payload.facility_locations[0].third_part_billing_details.name,
            phone_number:
              payload.facility_locations[0].third_part_billing_details
                .phone_number,
            address: {
              street_name_1:
                payload.facility_locations[0].third_part_billing_details.address
                  ?.street_name_1,
              street_name_2:
                payload.facility_locations[0].third_part_billing_details.address
                  ?.street_name_2,
              city_name:
                payload.facility_locations[0].third_part_billing_details.address
                  ?.city_name,
              zip_code:
                payload.facility_locations[0].third_part_billing_details.address
                  ?.zip_code,
              state:
                payload.facility_locations[0].third_part_billing_details.address
                  ?.state,
              address_type: 'physical',
            },
            effective_date:
              payload.facility_locations[0].third_part_billing_details
                .effective_date,
            fax_number:
              payload.facility_locations[0].third_part_billing_details
                .fax_number,
          };

          if (
            third_part_billing_details &&
            third_part_billing_details['address']
          ) {
            Object.keys(third_part_billing_details['address']).forEach(
              (key) =>
                (third_part_billing_details['address'][key] === null ||
                  third_part_billing_details['address'][key] === undefined) &&
                delete third_part_billing_details['address'][key],
            );
          }
          const _thirdParty: any = pickBy(
            third_part_billing_details,
            this.isEmptyArray,
          );

          _thirdParty['is_third_party'] = true;
          payload.facility_locations[0].billing_information.push(_thirdParty);
          delete payload.facility_locations[0]['third_part_billing_details'];
        }
        if (payload.facility_locations) {
          payload.facility_locations[0].is_primary =
            simpleValuesPageTwo.is_primary ? true : false;
          const facility_locations: Observable<any> = this.api.post(
            this.config.endpoints.facility_locations,
            payload.facility_locations[0],
          );

          this.busy.push(
            facility_locations.subscribe(
              (response) => {
                this.createResponse = response;
                this.state = 'four';
              },
              () => {
                this.toggleError();
              },
            ),
          );
        }
        const cxPayload = {
          name: facility_location?.name,
          npi: payload?.npi,
          specialty: facility_location?.specialties?.[0]?.name,
          tax_id: payload?.tax_id,
        };

        this.analyticsService.sendEvent(
          this.config.buttons.submitButton.cx_event_key,
          cxPayload,
        );
      }
    } else {
      this.getSectionForm(this.state)?.markAllAsTouched();
      this.mp.getFormGroup('addLocation')?.markAllAsTouched();
      setTimeout(() => {
        this.genericServices.scrollToFirstInvalidControl(this.el);
      });
    }
  }

  getProviderIdentifiers(formValue: object): ProviderIdentifierPayload[] {
    return this.config.createSteps.two.sections[0]?.controls
      .filter(
        (control: AllControlsConfiguration) =>
          control?.prop === PROVIDER_IDENTIFIERS[toCamel(control.prop)],
      )
      .map((control: AllControlsConfiguration) => ({
        identifier: formValue[control?.prop],
        type: control?.label,
      }));
  }

  setOperatingHours(form: any) {
    const enabled: any[] = this.locationHours
      ? this.locationHours.getEnabled()
      : this.enabled;
    const returnOb: any = {
      friday: form['friday']
        ? `${moment(form['friday'].start, 'HH:mm').format(
            'hh:mm a',
          )} to ${moment(form['friday'].end, 'HH:mm').format('hh:mm a')}`
        : null,
      monday: form['monday']
        ? `${moment(form['monday'].start, 'HH:mm').format(
            'hh:mm a',
          )} to ${moment(form['monday'].end, 'HH:mm').format('hh:mm a')}`
        : null,
      saturday: form['saturday']
        ? `${moment(form['saturday'].start, 'HH:mm').format(
            'hh:mm a',
          )} to ${moment(form['saturday'].end, 'HH:mm').format('hh:mm a')}`
        : null,
      sunday: form['sunday']
        ? `${moment(form['sunday'].start, 'HH:mm').format(
            'hh:mm a',
          )} to ${moment(form['sunday'].end, 'HH:mm').format('hh:mm a')}`
        : null,
      thursday: form['thursday']
        ? `${moment(form['thursday'].start, 'HH:mm').format(
            'hh:mm a',
          )} to ${moment(form['thursday'].end, 'HH:mm').format('hh:mm a')}`
        : null,
      tuesday: form['tuesday']
        ? `${moment(form['tuesday'].start, 'HH:mm').format(
            'hh:mm a',
          )} to ${moment(form['tuesday'].end, 'HH:mm').format('hh:mm a')}`
        : null,
      wednesday: form['wednesday']
        ? `${moment(form['wednesday'].start, 'HH:mm').format(
            'hh:mm a',
          )} to ${moment(form['wednesday'].end, 'HH:mm').format('hh:mm a')}`
        : null,
    };

    Object.keys(returnOb).forEach((item) => {
      if (!enabled.find((_item) => _item === item)) {
        delete returnOb[item];
      }
    });

    return pickBy(returnOb, identity);
  }

  setCaresystem() {
    let healthcare_system: any = null;
    const care_system_control: any = this.getControl('care_system');

    if (care_system_control?.value) {
      healthcare_system = {
        name: care_system_control.value,
      };
    }

    return healthcare_system;
  }

  setBillingDetails(data: any) {
    const returnOb: any = {
      address: {},
    };

    if (data.address3?.city_name) {
      returnOb['address']['city_name'] = data.address3.city_name;
    }
    if (data.address3?.state) {
      returnOb['address']['state'] = data.address3.state;
    }
    if (data.address3?.street_name_1) {
      returnOb['address']['street_name_1'] = data.address3.street_name_1;
    }
    if (data.address3?.street_name_2) {
      returnOb['address']['street_name_2'] = data.address3.street_name_2;
    }
    if (data.address3?.zip_code) {
      returnOb['address']['zip_code'] = data.address3.zip_code;
    }
    if (data.billing_details_name) returnOb['name'] = data.billing_details_name;
    if (data.billing_details_npi) returnOb['npi'] = data.billing_details_npi;
    if (data.billing_details_phone_number) {
      returnOb['phone_number'] = data.billing_details_phone_number;
    }
    if (data.billing_details_tax_id) {
      returnOb['tax_id'] = data.billing_details_tax_id;
    }
    if (returnOb) {
      Object.keys(returnOb).forEach(
        (key) => returnOb[key] === undefined && delete returnOb[key],
      );
    }
    if (returnOb && returnOb['address']) {
      Object.keys(returnOb['address']).forEach(
        (key) =>
          (returnOb['address'][key] === null ||
            returnOb['address'][key] === undefined) &&
          delete returnOb['address'][key],
      );
    }

    return pickBy(returnOb, this.isEmptyArray);
  }

  setThirdPartyBilling(data: any) {
    const returnOb: any = {
      address: {},
    };

    if (data?.address4?.city_name) {
      returnOb['address']['city_name'] = data.address4.city_name;
    }
    if (data?.address4?.state) {
      returnOb['address']['state'] = data.address4.state;
    }
    if (data?.address4?.street_name_1) {
      returnOb['address']['street_name_1'] = data.address4.street_name_1;
    }
    if (data?.address4?.street_name_2) {
      returnOb['address']['street_name_2'] = data.address4.street_name_2;
    }
    if (data?.address4?.zip_code) {
      returnOb['address']['zip_code'] = data.address4.zip_code;
    }
    if (data?.third_part_billing_details_name) {
      returnOb['name'] = data.third_part_billing_details_name;
    }
    if (data?.third_part_billing_details_effective_date) {
      returnOb['effective_date'] =
        data.third_part_billing_details_effective_date;
    }
    if (data?.third_part_billing_details_phone_number) {
      returnOb['phone_number'] = data.third_part_billing_details_phone_number;
    }
    if (data?.third_part_billing_details_fax_number) {
      returnOb['fax_number'] = data.third_part_billing_details_fax_number;
    }
    if (returnOb) {
      Object.keys(returnOb).forEach(
        (key) => returnOb[key] === undefined && delete returnOb[key],
      );
    }
    if (returnOb && returnOb['address']) {
      Object.keys(returnOb['address']).forEach(
        (key) =>
          (returnOb['address'][key] === null ||
            returnOb['address'][key] === undefined) &&
          delete returnOb['address'][key],
      );
    }

    return pickBy(returnOb, this.isEmptyArray);
  }

  setAdminAttrib(data: any) {
    const returnOb: any = {};

    if (
      data['administration_attributes.accepting_new_patients'] === false ||
      data['administration_attributes.accepting_new_patients'] === true
    ) {
      returnOb['accepting_new_patients'] =
        data['administration_attributes.accepting_new_patients'];
    }
    if (
      data['administration_attributes.display_in_directory'] === false ||
      data['administration_attributes.display_in_directory'] === true
    ) {
      returnOb['display_in_directory'] =
        data['administration_attributes.display_in_directory'];
    }
    if (
      data['administration_attributes.is_visiting_clinic'] === false ||
      data['administration_attributes.is_visiting_clinic'] === true
    ) {
      returnOb['is_visiting_clinic'] =
        data['administration_attributes.is_visiting_clinic'];
    }
    if (
      data[
        'administration_attributes.has_mobility_impaired_accessible_equipment'
      ] === false ||
      data[
        'administration_attributes.has_mobility_impaired_accessible_equipment'
      ] === true
    ) {
      returnOb['has_mobility_impaired_accessible_equipment'] =
        data[
          'administration_attributes.has_mobility_impaired_accessible_equipment'
        ];
    }
    if (
      data['administration_attributes.is_primary_clinic'] === false ||
      data['administration_attributes.is_primary_clinic'] === true
    ) {
      returnOb['is_primary_clinic'] =
        data['administration_attributes.is_primary_clinic'];
    }
    if (
      data[
        'administration_attributes.is_cultural_competency_training_complete'
      ] === false ||
      data[
        'administration_attributes.is_cultural_competency_training_complete'
      ] === true
    ) {
      returnOb['is_cultural_competency_training_complete'] =
        data[
          'administration_attributes.is_cultural_competency_training_complete'
        ];
    }
    if (
      data[
        'administration_attributes.cultural_competency_training_completion_date'
      ]
    ) {
      returnOb['cultural_competency_training_completion_date'] =
        data[
          'administration_attributes.cultural_competency_training_completion_date'
        ];
    }
    if (
      data['administration_attributes.is_mobility_impaired_accessible'] ===
        false ||
      data['administration_attributes.is_mobility_impaired_accessible'] === true
    ) {
      returnOb['is_mobility_impaired_accessible'] =
        data['administration_attributes.is_mobility_impaired_accessible'];
    }
    if (
      data[
        'administration_attributes.has_mobility_impaired_accessible_exam_room'
      ] === false ||
      data[
        'administration_attributes.has_mobility_impaired_accessible_exam_room'
      ] === true
    ) {
      returnOb['has_mobility_impaired_accessible_exam_room'] =
        data[
          'administration_attributes.has_mobility_impaired_accessible_exam_room'
        ];
    }

    if (returnOb) {
      Object.keys(returnOb).forEach(
        (key) => returnOb[key] === undefined && delete returnOb[key],
      );
    }

    return returnOb;
  }

  setLocationContacts(data: any, moreData: any) {
    const returnOb: any = {
      name: {},
    };
    const address: any = {};

    if (data.address2?.city_name) {
      address['city_name'] = data.address2.city_name;
    }
    if (data.address2?.state) address['state'] = data.address2.state;
    if (data.address2?.street_name_1) {
      address['street_name_1'] = data.address2.street_name_1;
    }
    if (data.address2?.street_name_2) {
      address['street_name_2'] = data.address2.street_name_2;
    }
    if (data.address2?.zip_code) address['zip_code'] = data.address2.zip_code;
    // eslint-disable-next-line prettier/prettier
    if (
      moreData?.location_contact_phone_numbers &&
      this.isEmptyArray(
        this.getLocationPhoneNumbers(moreData.location_contact_phone_numbers),
      )
    ) {
      // eslint-disable-next-line prettier/prettier
      returnOb['phone_numbers'] = moreData.location_contact_phone_numbers
        .map((item: PhoneNumbers) => {
          if (item.selection) {
            return { phone_number: item.selection, extension: 1 };
          }
        })
        .filter(this.notEmpty);
    }
    if (data.location_contact_full_name) {
      returnOb['name']['full_name'] = data.location_contact_full_name;
    }
    if (data.location_contact_prefix) {
      returnOb['name']['prefix'] = data.location_contact_prefix;
    }
    if (data.location_contact_email_address) {
      returnOb['email_address'] = data.location_contact_email_address;
    }
    if (data.location_contact_fax) returnOb['fax'] = data.location_contact_fax;

    if (returnOb && returnOb['name']) {
      Object.keys(returnOb['name']).forEach(
        (key: any) =>
          (returnOb['name'][key] === null ||
            returnOb['name'][key] === undefined) &&
          delete returnOb['name'][key],
      );
    }
    if (Object.keys(address).length) {
      Object.keys(address).forEach(
        (key: any) =>
          (address[key] === null || address[key] === undefined) &&
          delete address[key],
      );
      address['address_type'] = 'physical';
      returnOb['addresses'] = [address];
    }
    if (returnOb) {
      Object.keys(returnOb).forEach(
        (key) => returnOb[key] === undefined && delete returnOb[key],
      );
    }
    const retObj = pickBy(returnOb, this.isEmptyArray);

    if (isEmpty(retObj)) return null;

    return [retObj];
  }

  notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }

  getLocationPhoneNumbers(phoneNumbers: PhoneNumbers[]): PhoneNumbersModel[] {
    return phoneNumbers
      .map((item: PhoneNumbers) => {
        if (item.selection) {
          return { phone_number: item.selection, extension: 1 };
        }
      })
      .filter(this.notEmpty);
  }

  isEmptyArray(array: any) {
    if (Array.isArray(array) && array.length === 0) {
      return false;
    }
    if (array && Object.keys(array).length === 0) {
      return false;
    }
    if (array === null || array === undefined) {
      return false;
    }

    return true;
  }

  handleSelectedRows(rows: any): void {
    this.selectedRows = rows;
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }

  toggleModalInitiate() {
    this.analyticsService.sendEvent(
      this.config?.createSteps?.four?.cx_event_key,
    );
    this.toggleModal();
  }

  resetAssociatedFields() {
    this.associateFields = [];
  }

  cancelModal() {
    this.resetAssociatedFields();
    this.addState = 'add';
    this.toggleModal();
  }

  toggleError() {
    this.error = !this.error;
  }

  checkAssociated() {
    let value = true;

    this.associateFields.forEach((item, index) => {
      // eslint-disable-next-line no-empty
      if (item.valid) {
      } else {
        value = false;
        const controls = item.controls;

        Object.keys(controls).forEach((c) => controls[c].markAsTouched());
      }
    });

    return value;
  }

  isDisabled() {
    let dontShow = false;

    dontShow = this.setDisabledValidations(this.associateFields, dontShow);

    return dontShow;
  }

  setDisabledValidations(control: any, returnBool: boolean): boolean {
    control.length > 0 &&
      control.forEach((item, index) => {
        if (!item.valid) {
          const controls = item.controls;

          if (controls) {
            Object.keys(controls).forEach((c: any) => {
              if (!controls[c].valid) {
                if (controls[c].controls) {
                  Object.keys(controls[c].controls).forEach((cc) => {
                    returnBool = this.setDisabledValidations(
                      controls[c].controls[cc],
                      returnBool,
                    );
                  });
                } else {
                  if (!controls[c].pristine) {
                    controls[c].markAsTouched();
                  }
                  returnBool = true;
                }
              }
            });
          }
        }
      });

    return returnBool;
  }

  getAssociatedFields(index: number) {
    if (!this.associateFields[index]) {
      this.associateFields[index] = new UntypedFormGroup({});
    }

    return this.associateFields[index];
  }

  getAssociatedFieldValue(index: number) {
    return this.associateFields[index]?.value;
  }

  submitAddAssociated() {
    if (!this.checkAssociated()) return;
    const facility_location_providers: any[] = [];

    this.selectedRows.forEach((row, index) => {
      const rowFields: any = this.getAssociatedFieldValue(index);
      const _payload: any = {
        facility_location_id: this.createResponse?.transaction_id
          ? this.createResponse.transaction_id
          : this.createResponse?.id,
        provider_id: row.row?.id,
        accepting_new_patients: rowFields?.accepting_new_patients
          ? true
          : false,
        can_display_in_directory: rowFields?.display_in_directory
          ? true
          : false,
        specialties: [rowFields?.specialty],
      };

      if (rowFields?.effective_date) {
        _payload['effective_date'] = rowFields.effective_date;
      }
      if (rowFields?.notes) _payload['last_updated_notes'] = rowFields.notes;
      facility_location_providers.push(_payload);
    });

    const payload: any = {
      should_fail_on_error: false,
      operation_type: 'create',
      facility_location_providers: facility_location_providers,
    };

    this.busy.push(
      this.api.post(this.config.endpoints.batch, payload).subscribe(
        (res) => {
          this.associateResponse = res;
          this.state = 'five';
          this.toggleModal();
        },
        (err) => {
          this.toggleModal();
          this.toggleError();
        },
      ),
    );
  }

  reviewAssociated(): void {
    if (this.selectedRows.length === 0) {
      return;
    }
    this.addState = 'review';
  }

  populateDropdown(options: any[], results: any[], prop: string) {
    for (const item of results) {
      options.push({
        label: this.getObjectProperty(item, prop),
        value: item,
      });
    }

    return options;
  }

  getSectionFormValid(state: string) {
    if (state === 'one') {
      return this.stepOneFields.valid;
    } else if (state === 'two') {
      return (
        this.stepTwoFields.valid && this.mp.getFormGroup('addLocation')?.valid
      );
    } else if (state === 'three') {
      return this.stepThreeFields.valid;
    }
  }

  getSectionForm(state: string) {
    if (state === 'one') {
      return this.stepOneFields;
    } else if (state === 'two') {
      return this.stepTwoFields;
    } else if (state === 'three') {
      return this.stepThreeFields;
    }
  }

  getObjectProperty(obj, keys) {
    if (!obj) return '';
    if (!keys) return '';

    return keys.split('.').reduce(function (result, key) {
      return result[key];
    }, obj);
  }

  getControl(prop: string) {
    const states: string[] = ['one', 'two', 'three', 'four', 'five'];

    for (const state of states) {
      if (this.getSectionForm(state)?.get(prop)) {
        return this.getSectionForm(state).get(prop);
      } else continue;
    }
  }

  getActiveControl(state: string, prop: string) {
    if (this.getSectionForm(state)?.get(prop)) {
      return this.getSectionForm(state).get(prop);
    } else return null;
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.genericServices.showFooter$.next(true);
    });
  }
}
