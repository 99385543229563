import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { GLOBAL_URLS } from '../../../app-urls.constant';
import { AuthIdp } from '../../modules/authentication/authentication.constants';
import { ConfigService } from '../../services';

import { AuthService, UserRoles } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PayerAdminGuard {
  private next: ActivatedRouteSnapshot;
  private state: RouterStateSnapshot;
  private user;
  constructor(
    private authService: AuthService,
    private router: Router,
    private configService: ConfigService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    this.state = state;
    this.next = next;

    this.user = this.authService.loggedInUser;
    const authConfig: any = this.configService.getPageConfig('auth');

    return new Promise((resolve) => {
      // eslint-disable-next-line array-callback-return
      this.user.roles.map((target: UserRoles) => {
        if (target.name === 'Payer Admin') {
          if (
            state.url.includes('payer-admin') ||
            (state.url.includes(GLOBAL_URLS.TERMSANDCONDITIONS) &&
              !this.user?.app_user_data?.accepted_terms &&
              authConfig.idp === AuthIdp)
          ) {
            resolve(true);
          } else {
            this.router.navigate(['provider-portal/payer-admin/dashboard']);
            resolve(false);
          }
        } else resolve(false);
      });
      resolve(false);
    });
  }
}
