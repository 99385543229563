import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  NotificationConfig,
  NotificationModel,
} from '../notifications.constant';
import { NotificationsService } from '../notifications.service';
import { accordion } from '../../../animations';

@Component({
  selector: 'notifications-allow-paging',
  templateUrl: './notifications-allow-paging.component.html',
  styleUrls: ['../notifications.component.scss'],
  animations: [accordion],
})
export class NotificationsAllowPagingComponent implements OnInit {
  @Input() notifications: NotificationModel[];
  @Input() config: NotificationConfig;
  @Input() icon: any;

  notificationStart = 0;
  showTimeStamp: boolean;

  constructor(
    private notificationsService: NotificationsService,
    public router: Router,
  ) {}

  ngOnInit() {
    this.showTimeStamp = this.config?.showTimeStamp ?? true;
  }

  toggle(notification: NotificationModel) {
    notification.open = !notification.open;

    if (!notification.is_read && notification.open) {
      this.notificationsService.registerNotificationAsRead(notification.id);
      notification.is_read = true;
      this.notificationsService.notifications = this.notifications;
    }
  }

  linkClicked(url) {
    if (url.includes('http')) {
      window.open(url, '_blank');
    } else {
      this.router.navigate([url]);
    }
  }
}
