import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LetDirective, PushPipe } from '@ngrx/component';
import {
  BadgeModule,
  ButtonModule,
  CardModule,
  FormControlModule,
  FormGroupModule,
  ModelAttributeModule,
  TableModule,
} from '@zipari/design-system';

import { FileUploaderModule } from '../../modules';
import { AddNewModalModule } from '../../modules/add-row-modal/add-new-modal.module';
import { ZipBusyModule } from '../../modules/zip-busy';

import { GenericWorkflowCardComponent } from './generic-workflow-card.component';
import { GenericWorkflowComponent } from './generic-workflow.component';

@NgModule({
  declarations: [GenericWorkflowComponent, GenericWorkflowCardComponent],
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    AddNewModalModule,
    ZipBusyModule,
    ModelAttributeModule,
    FormControlModule,
    FormGroupModule,
    TableModule,
    CardModule,
    LetDirective,
    PushPipe,
    ModelAttributeModule,
    BadgeModule,
    FileUploaderModule,
  ],
})
export class GenericWorkflowModule {}
