import { Component, OnInit } from '@angular/core';
import { ProviderPortalService } from '../../../provider-portal.service';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { DocumentCenterService } from '../document-center.service';
import { ConfigService } from '../../../../shared/services';
import { ResourceTabConfig } from '../documentsConfig.model';
import { camelToSnakeCase } from '../../../../shared/utilities/case-conversion.utils';
import { SquidexModuleConstants } from '../../../provider-portal.constant';
import { SquidexConfig } from '../../../../shared/modules/cms';
@Component({
  selector: 'resouces',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
})
export class ResourcesComponent implements OnInit {
  config: ResourceTabConfig;
  constructor(
    private documentCenterService: DocumentCenterService,
    public analyticsService: AnalyticsService,
    private providerPortalService: ProviderPortalService,
    private configService: ConfigService,
  ) {}

  ngOnInit(): void {
    const squidexConfig =
      this.configService.getPageConfig<any>('global')?.contentManagementSystem
        ?.squidex;

    this.setResourceTabConfig(squidexConfig);
  }

  setResourceTabConfig(squidexConfig: SquidexConfig): void {
    if (squidexConfig?.enabled && squidexConfig?.enableForModule?.resources) {
      this.applySquidexContent();
    } else {
      this.config = this.documentCenterService.tabsConfig;
    }
  }

  handleLinkClick(field): void {
    field = camelToSnakeCase(field);
    if (field.cell_click_cx_event_key) {
      this.analyticsService.sendEvent(field.cell_click_cx_event_key);
    }
    window.open(field.url, '_blank');
  }

  private applySquidexContent(): void {
    this.providerPortalService
      .getSquidexContent(SquidexModuleConstants.resources)
      .subscribe((squidexResourcesConfig: ResourceTabConfig) => {
        this.config = squidexResourcesConfig;
      });
  }
}
