import { Injectable } from '@angular/core';
import { camelToSnakeCase } from '../../../shared/utilities/case-conversion.utils';
import {
  UserEditPayload,
  ContactInfo,
  PracticeInfo,
  UserEditFormValue,
  Group,
} from '../../../shared/models/shared/UserPayloadModels/UserEditModel.model';
import { createUserConstants } from '../manage-portal-users-create-user/manage-portal-users-create-user.constants';

@Injectable({
  providedIn: 'root',
})
export class FormatRequestPayloadService {
  public getEditUserProfilePayload(
    editUserProfilePayload: UserEditFormValue,
    allowMultipleGroupsSelection: boolean,
    idp?: string,
  ): UserEditPayload {
    let editUserGroups: Group[] = [];
    const editUserProfileRequestPayload: UserEditPayload = {
      contactInformation: this.getEditUserProfileContactPayload(
        editUserProfilePayload,
      ),
      practiceInformation: this.getEditUserProfilePracticePayload(
        editUserProfilePayload,
      ),
      phoneNumber: editUserProfilePayload?.phone_number,
      phoneExtension: editUserProfilePayload?.phone_extension,
      address1: editUserProfilePayload?.address_1,
      address2: editUserProfilePayload?.address_2,
      city: editUserProfilePayload?.city,
      state: editUserProfilePayload?.state,
      zipCode: editUserProfilePayload?.zip_code,
      jobTitle: editUserProfilePayload?.job_title,
      organization: editUserProfilePayload?.organization,
      firstName: editUserProfilePayload?.first_name,
      lastName: editUserProfilePayload?.last_name,
      role: editUserProfilePayload?.role,
    };

    if (
      editUserProfilePayload?.groups ||
      editUserProfilePayload?.multiple_group
    ) {
      editUserGroups = allowMultipleGroupsSelection
        ? editUserProfilePayload.multiple_group
        : editUserProfilePayload.groups;

      editUserProfileRequestPayload['groups'] = editUserGroups;
    }

    return camelToSnakeCase(editUserProfileRequestPayload);
  }

  private getEditUserProfileContactPayload(
    editUserProfilePayload: UserEditFormValue,
  ): ContactInfo {
    const editUserProfileContactRequestPayload: ContactInfo = {
      firstName: editUserProfilePayload?.contact_first_name,
      lastName: editUserProfilePayload?.contact_last_name,
      email: editUserProfilePayload?.contact_email,
      phoneNumber: editUserProfilePayload?.contact_phone_number,
      phoneExtension: editUserProfilePayload?.contact_phone_extension,
      fax: editUserProfilePayload?.contact_fax,
    };

    if (editUserProfileContactRequestPayload.firstName !== undefined) {
      return editUserProfileContactRequestPayload;
    }
  }

  private getEditUserProfilePracticePayload(
    editUserProfilePayload: UserEditFormValue,
  ): PracticeInfo {
    const editUserProfilePracticeRequestPayload: PracticeInfo = {
      practiceName: editUserProfilePayload?.practice_name,
      practiceSpecialty: editUserProfilePayload?.practice_specialty,
      officeManager: editUserProfilePayload?.office_manager,
      department: editUserProfilePayload?.department,
      phoneNumber: editUserProfilePayload?.practice_phone_number,
      phoneExtension: editUserProfilePayload?.practice_phone_extension,
      email: editUserProfilePayload?.practice_email,
      address1: editUserProfilePayload?.practice_address_1,
      address2: editUserProfilePayload?.practice_address_2,
      city: editUserProfilePayload?.practice_city,
      state: editUserProfilePayload?.practice_state,
      zipCode: editUserProfilePayload?.practice_zip_code,
      taxIds: editUserProfilePayload?.practice_tax_ids,
      npi: editUserProfilePayload?.practice_npi,
      claimNumber: editUserProfilePayload?.practice_claim_number,
    };

    if (editUserProfilePracticeRequestPayload.practiceName !== undefined) {
      return editUserProfilePracticeRequestPayload;
    }
  }
}
