import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { objectToArray } from '../../../../shared/utilities/object';

@Component({
  styleUrls: ['./header-cell.component.scss'],
  selector: 'header-cell',
  templateUrl: './header-cell.component.html',
})
export class HeaderCellComponent implements OnInit, OnDestroy {
  @Input() state: 'inactive' | 'active' = 'active';
  @Input() title = 'untitled';
  @Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();

  _searchBar: any;
  subs: Subscription | undefined;
  formGroup = new UntypedFormGroup({});

  get searchBar() {
    return this._searchBar;
  }

  constructor(private formControlService: FormControlService) {}

  @Input() set searchBar(searchBar: any) {
    if (searchBar) {
      this._searchBar = objectToArray(searchBar);
      this.formControlService.addControlToFormGroup(
        this.formGroup,
        this.searchBar[0],
      );
    }
  }

  ngOnInit(): void {
    this.subs = this.formGroup
      .get(this.searchBar[0].prop)
      ?.valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((event: any) => {
        if (typeof event === 'string' || event instanceof String) {
          this.filterEvent.emit({ prop: this.searchBar[0].prop, text: event });
        }
      });
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
}
