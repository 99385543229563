import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  AfterViewInit,
  AfterViewChecked,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
  AbstractControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControlService, Option } from '@zipari/design-system';
import { getValue } from '@zipari/web-utils';
import { APIService } from '@zipari/web-services';
import { isEmpty } from 'lodash';
import { forkJoin, Observable } from 'rxjs';

import { AnalyticsService } from '../../../shared/services/analytics.service';
import { GenericServices } from '../../shared/services/generic.service';
import { ApiListResponse } from '../../../shared/models/shared/ApiListResponse.model';
import Member from '../../../shared/models/shared/Member.model';
import Policy from '../../../shared/models/shared/Policy.model';
import { ConfigService, FormattingService } from '../../../shared/services';
import { objectToArray } from '../../../shared/utilities/object';
import { ProviderUpdateToolService } from '../provider-update-tool/provider-update-tool.service';
import {
  pregnancyRiskAssesmentErrorMessage,
  alternateAddressPropNames,
  first_options,
  second_options,
  third_options,
  tobaccoUse,
} from './pregnancy-risk-assesment.constants';
import { CX_CALLS } from '../../../shared/constants/cx-calls.constant';

@Component({
  styleUrls: ['./pregnancy-risk-assesment.component.scss'],
  selector: 'pregnancy-risk-assesment',
  templateUrl: './pregnancy-risk-assesment.component.html',
})
export class PregnancyRiskAssesmentComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
  id: any;
  config: any = {};
  context: { member: Member; coverage?: any } = { member: null };
  state: 'edit' | 'review' | 'confirm' = 'edit';
  formGroup = new UntypedFormGroup({});
  phoneFormGroup = new UntypedFormGroup({});
  controls: Map<any, any> = new Map<any, any>();
  payload: any = {};
  risks: any[] = [];
  busy: any[] = [];
  response: any;
  error = false;
  nonDependentEndpoints: string[];
  nonDependentEndpointStatus: boolean[] = [];
  loaded = false;
  noSpace = 'n/a';
  member: any;
  medical_risks: any;
  social_risks: any;
  other_risks: any;
  selectedMemberId: any;
  public errorMessage: string = pregnancyRiskAssesmentErrorMessage;

  constructor(
    private route: ActivatedRoute,
    public configService: ConfigService,
    public genericServices: GenericServices,
    private formControlService: FormControlService,
    public router: Router,
    public api: APIService,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private location: Location,
    private el: ElementRef,
    private formattingService: FormattingService,
    public mp: ProviderUpdateToolService,
    public analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.genericServices.showFooter$.next(false);
    });
    this.id = this.route.snapshot.params.id;
    this.config = this.configService.getPageConfig('pregnancy-risk-assessment');
    this.noSpace = this.config.noSpace;
    this.formateConfig(this.config);
    this.buildPanel();
    this.buildForms();
    this.selectedMemberId = this.id;
    // this.genericServices.currentMemberId.subscribe((id: any) => (this.selectedMemberId = id));
  }

  buildPanel(): void {
    const member: Observable<Member> = this.api.get(
      this.setMemberId(this.config.endpoints.member),
    );
    const coverageApi: Observable<ApiListResponse<Policy>> = this.api.get(
      this.setMemberId(this.config.endpoints.coverage),
    );

    this.busy.push(
      forkJoin([member, coverageApi]).subscribe(([currentMember, coverage]) => {
        this.context.member = currentMember;
        this.member = currentMember;
        if (coverage.results?.[0]) {
          this.context.coverage = coverage.results[0].product_coverages[0];
        }
        if (
          this.context?.member?.additional_fields
            ?.enrolled_medicare_savings_program
        ) {
          this.context.member.additional_fields.enrolled_medicare_savings_program =
            ' ';
        }
        this.formatDates(this.context);
        this.loaded = true;
      }),
    );
  }

  formateConfig(config) {
    config.cards = objectToArray(config.cards);
    config.cards.map((item, index) => {
      Object.keys(item.controls).map((controlkey: any) => {
        for (const [key, value] of Object.entries(item.controls)) {
          if (
            controlkey &&
            key.indexOf(controlkey) !== -1 &&
            key.indexOf('flyout') !== -1 &&
            controlkey.indexOf('flyout') === -1
          ) {
            Object.assign(config?.cards[index]?.controls[controlkey], {
              flyout: value,
            });
            delete config?.cards[index]?.controls[key];
          }
        }
      });
      config.cards[index].controls = objectToArray(item.controls);
      config.cards[index].controls.sort((a, b) =>
        a.controlOrder > b.controlOrder ? 1 : -1,
      );
    });
    config.cards.sort((a, b) => (a.cardOrder > b.cardOrder ? 1 : -1));
  }

  setMemberId(target: string): string {
    return target.replace('${member_number}', this.id);
  }

  formatDates(context: any): void {
    Object.keys(context).forEach((key: string) => {
      if (!!context[key] && typeof context[key] === 'object') {
        this.formatDates(context[key]);

        return;
      }
      if (key.includes('date')) {
        context[key] = this.formatDate(context[key]);
      }
    });
  }

  formatDate(date: string): string {
    // formats string date of 2020-12-31 format to 12/31/2020 (MM/DD/YYY)
    const transformedDate: string =
      this.formattingService.restructureValueBasedOnFormat(date, {
        format: 'DATE',
      });

    return transformedDate;
  }

  checkShowOnTrueValues(attr): boolean {
    let targetDataExists = false;
    let value: string | number;

    if (attr.showIfTrue) {
      const regProp = new RegExp('{([^}]+)}', 'g');
      const prop = regProp.exec(attr.value);

      if (prop && prop[1]) {
        value = getValue(this.context, prop[1]);
        targetDataExists = value.toString().length > 0;
      }
    }

    return targetDataExists;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    const options: any = [];

    if (this.config?.cards) {
      this.config.cards.forEach((card: any) => {
        const control: any = card.controls.find(
          (cardControl: any) => cardControl.prop === 'weeks_of_gestation',
        );

        if (control) {
          control.options = [];
          this.formGroup.get(control.prop).disable();
        }
      });
    }
  }

  getValue(data: object, attr, val?: boolean): string | number {
    return val ? getValue(data, attr.value) : getValue(data, attr.prop);
  }

  buildForms() {
    for (const card of this.config?.cards) {
      for (const control of card.controls) {
        this.controls.set(control.prop, control);
        if (control.prop === 'phone_numbers') {
          const control_key: string = control.prop;
          const key_obj = {};
          const phoneValidators: any[] = this.mp.getValidators('phone');

          if (control.required) {
            phoneValidators.push(Validators.required);
          }
          const formGroup: UntypedFormGroup = this.fb.group({
            phone_number: ['', phoneValidators],
            type: ['', this.mp.phoneConditionallyRequiredValidator],
          });

          formGroup.get('phone_number')?.valueChanges.subscribe(() => {
            formGroup.get('type')?.updateValueAndValidity();
          });
          key_obj[control_key] = this.fb.array([formGroup]);
          this.phoneFormGroup = this.fb.group(key_obj);
          continue;
        }
        const key_control: AbstractControl = new UntypedFormControl('', []);
        const newFormControl = Object.assign(control, {
          control: key_control,
        });

        if (control.apiEndpoint) {
          this.busy.push(
            this.api.get(control.apiEndpoint).subscribe((resp: any) => {
              control.options = this.populateDropdown(
                new Array<any>(),
                resp.results,
                control.dropdownDisplayProp,
              );
            }),
          );
        }

        this.formControlService.addControlToFormGroup(
          this.formGroup,
          newFormControl,
        );

        // trimester dropdown
        // change the options for weeks_of_gestation depending on trimester selected
        if (control.prop === 'current_trimester') {
          this.formGroup
            .get(control.prop)
            ?.valueChanges.subscribe((trimesterSelected: any) => {
              const weeksOfGestationControl =
                this.formGroup.get('weeks_of_gestation');

              if (weeksOfGestationControl) {
                switch (trimesterSelected) {
                  case 'first':
                    if (this.formGroup.get(control.prop)) {
                      weeksOfGestationControl.enable();
                      card.controls.find(
                        (item: any) => item.prop === 'weeks_of_gestation',
                      ).options = first_options;
                    }
                    break;
                  case 'second':
                    if (this.formGroup.get(control.prop)) {
                      weeksOfGestationControl.enable();
                      card.controls.find(
                        (item: any) => item.prop === 'weeks_of_gestation',
                      ).options = second_options;
                    }
                    break;
                  case 'third':
                    if (this.formGroup.get(control.prop)) {
                      weeksOfGestationControl.enable();
                      card.controls.find(
                        (item: any) => item.prop === 'weeks_of_gestation',
                      ).options = third_options;
                    }
                    break;
                  default:
                    if (this.formGroup.get(control.prop)) {
                      weeksOfGestationControl.disable();
                      card.controls.find(
                        (item: any) => item.prop === 'weeks_of_gestation',
                      ).options = [];
                    }
                }
              }
            });
        }
        const tobaccoProp = control.controls?.find(
          (control) => control.type === tobaccoUse.controlType,
        );

        this.risks = this.risks.concat(tobaccoProp?.options);

        if (
          control.prop === 'medical_risks' ||
          control.prop === 'social_risks' ||
          control.prop === 'other_risks'
        ) {
          this.risks = this.risks.concat(control.options);
          switch (control.prop) {
            case 'medical_risks':
              this.medical_risks = control.options;
              break;
            case 'social_risks':
              this.social_risks = control.options;
              break;
            case 'other_risks':
              this.other_risks = control.options;
              break;
          }
        }
      }
    }
  }

  postInit() {
    for (const card of this.config?.cards) {
      for (const control of card.controls) {
        if (control.enableOnNo?.length) {
          control.enableOnNo.forEach((element: any) => {
            if (this.formGroup.controls[element]) {
              this.formGroup.controls[element].disable();
              if (this.formGroup.controls[control.prop]) {
                this.formGroup.controls[control.prop].valueChanges.subscribe(
                  (value: any) => {
                    if (!value) {
                      this.formGroup.controls[element].enable();
                    } else {
                      this.formGroup.controls[element].reset();
                      this.formGroup.controls[element].disable();
                    }
                  },
                );
              }
            }
          });
        }
      }
    }
  }

  print(event): void {
    window.print();
  }

  cancel(): void {
    if (this.genericServices.previousURL.includes('provider-portal')) {
      this.location.back();
    }
  }

  modelAttrLinkClicked(ev): void {
    if (ev.url) {
      window.open(getValue(this.context, ev.url), '_blank').focus();
    }
  }

  scrollTo(event: any): void {
    this.back(null);
    this.analyticsService.sendEvent(
      CX_CALLS.pp_pregnancy_form_edited.event_key,
    );
    setTimeout(() => {
      const itemToScrollTo = document.getElementById(event);
      // null check to ensure that the element actually exists

      if (itemToScrollTo) {
        itemToScrollTo.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  getPdfURL(context: any, prop: any) {
    window.open(
      this.genericServices.getObjectProperty(context, prop),
      '_blank',
    );
  }

  checkForPdf(context: any, prop: any) {
    if (!prop) return false;
    if (!this.genericServices.getObjectProperty(context, prop)) return false;

    return true;
  }

  populateDropdown(options: any[], results: any[], prop: string) {
    for (const item of results) {
      options.push({
        label: this.getObjectProperty(item, prop),
        value: item,
      });
    }

    return options;
  }

  getObjectProperty(obj, key) {
    if (!obj || !key) return '';

    return key.split('.').reduce(function (result, inner_key) {
      if (!result) return '';

      return result[inner_key];
    }, obj);
  }

  getDropdownDisplayValue(prop: string): string {
    const options: any[] = this.controls.get(prop)?.options;
    const option: any = options?.find(
      (item: any) => item.value === this.formGroup.get(prop)?.value,
    );

    if (option) {
      return option.label;
    }

    return this.noSpace;
  }

  getCheckboxValues(prop: string, checkboxOptions?: Option[]): string[] {
    const options: Option[] =
      prop === tobaccoUse.tobaccoUseProp
        ? checkboxOptions
        : this.controls.get(prop)?.options;
    const selectedOptions: string[] = this.formGroup.get(prop)?.value;
    const returnArray: string[] = (selectedOptions || [])
      .map((item: string) => {
        const key_item: Option = options?.find(
          (inside_item: Option) => inside_item.value === item,
        );

        return key_item ? key_item.label : item;
      })
      .filter(Boolean);

    return returnArray;
  }

  back(event: any): void {
    if (this.state === 'review') this.state = 'edit';
  }

  review(): void {
    if (this.formGroup.valid && this.phoneFormGroup.valid) {
      if (this.state === 'edit') this.state = 'review';
      this.analyticsService.sendEvent(
        this.config.footer.buttons.buttonReview.cx_event_key,
      );
      window.scrollTo(0, 0);
    } else {
      this.formGroup.markAllAsTouched();
      this.phoneFormGroup.markAllAsTouched();
      setTimeout(() => {
        this.genericServices.scrollToFirstInvalidControl(this.el);
      });
    }
  }

  setFieldIfExists(obj: any, field_name: string) {
    if (obj && (typeof obj === 'number' || !isEmpty(obj))) {
      this.payload[field_name] = obj;
    }
  }

  setField(obj: any, field_name: string) {
    this.payload[field_name] = obj;
  }

  setNestedFieldIfExists(obj: any, outter_field: string, inner_field: string) {
    if (obj && !isEmpty(obj)) {
      if (!this.payload[outter_field]) this.payload[outter_field] = {};
      this.payload[outter_field][inner_field] = obj;
    }
  }

  setPhoneIfExists(field_name: string) {
    const phones: any[] = [];

    if (this.phoneFormGroup.value?.phone_numbers) {
      this.phoneFormGroup.value?.phone_numbers.forEach((element: any) => {
        if (element.phone_number.length) {
          phones.push({
            phone_number: element.phone_number,
            type: element.type,
          });
        }
      });
    }
    this.setFieldIfExists(phones, field_name);
  }

  addRisks(obj: any, risks: any[], type: string) {
    if (obj && !isEmpty(obj)) {
      obj.forEach((element, index) => {
        if (type === tobaccoUse.tobaccoType && element) {
          risks.push({
            id: element,
            description: this.risks.find(
              (item: Option) => item?.value === element,
            )?.label,
            type: type,
          });
        } else if (element) {
          if (index !== obj.length - 1) {
            risks.push({
              id: element,
              description: this.risks.find(
                (item: Option) => item?.value === element,
              )?.label,
              type: type,
            });
          } else {
            const risk: any = this[`${type}_risks`].find(
              (item: any) => item.value === 'other',
            );

            risks.push({
              id: risk ? risk.otherValue : 'other',
              description: 'Other',
              type: type,
              detail: [
                {
                  question_id: risk ? risk.questionId : 'other',
                  response: element,
                },
              ],
            });
          }
        }
      });
    }
  }

  setRisks(field_name: string) {
    const risks: any[] = [];

    this.addRisks(this.formGroup.value.medical_risks, risks, 'medical');
    this.addRisks(this.formGroup.value.social_risks, risks, 'social');
    this.addRisks(this.formGroup.value.other_risks, risks, 'other');
    this.addRisks(
      this.formGroup.value?.tobacco_options,
      risks,
      tobaccoUse.tobaccoType,
    );
    this.setFieldIfExists(risks, field_name);
  }

  setCompletedByPhone(phone_number: any) {
    const phone: any = {};

    if (phone_number) {
      phone['phone_number'] = phone_number;
      phone['extension'] = '1';
      phone['is_primary'] = true;
      phone['type'] = 'work';
      this.setNestedFieldIfExists(phone, 'completed_by', 'phone');
    }
  }

  buildPayload(): void {
    const { value } = this.formGroup;
    const addressProps = {
      street_name_1: 'streetName1',
      street_name_2: 'streetName2',
      city_name: 'city',
      state: 'state',
      zip_code: 'zipCode',
    };

    this.payload = {};
    // card 1:
    this.setFieldIfExists(
      this.formGroup.value?.provider_clinic?.id,
      'servicing_facility_location_id',
    );
    this.setNestedFieldIfExists(
      this.formGroup.value?.obstetrician_name,
      'obstetrician_name',
      'full_name',
    );
    this.setFieldIfExists(this.formGroup.value?.referral_type, 'referral_type');
    this.setFieldIfExists(this.formGroup.value?.visit_type, 'visit_type');
    this.setFieldIfExists(this.selectedMemberId, 'member_id');
    // card 2:
    this.setFieldIfExists(
      this.formGroup.value?.member_primary_language?.name,
      'member_primary_language',
    );
    this.setPhoneIfExists('member_phones');
    // card 3:
    Object.entries(addressProps).forEach(([key, propName]) => {
      const fieldValue = value[key];

      this.setNestedFieldIfExists(
        fieldValue,
        alternateAddressPropNames.alternateAddress,
        alternateAddressPropNames[propName],
      );
    });
    // card 4:
    this.setField(
      this.formGroup.value?.is_first_time_pregnancy,
      'is_first_time_pregnancy',
    );
    this.setFieldIfExists(
      this.formGroup.value?.previous_pregnancy_count,
      'previous_pregnancy_count',
    );
    this.setFieldIfExists(
      this.formGroup.value?.previous_live_birth_count,
      'previous_live_birth_count',
    );
    this.setFieldIfExists(
      this.formGroup.value?.current_trimester,
      'current_trimester',
    );
    this.setFieldIfExists(
      this.formGroup.value?.weeks_of_gestation,
      'weeks_of_gestation',
    );
    this.setFieldIfExists(
      this.formGroup.value?.estimated_date_of_delivery,
      'estimated_date_of_delivery',
    );
    this.setNestedFieldIfExists(
      this.formGroup.value?.primary_provider,
      'primary_obstetrician_name',
      'full_name',
    );
    this.setFieldIfExists(
      this.formGroup.value?.assessment_of_pregnancy_risk,
      'assessment_of_pregnancy_risk',
    );
    // card 5:
    this.setRisks('risks');
    // card 6:
    this.setFieldIfExists(this.formGroup.value?.comments, 'comments');
    // completion_date
    this.setFieldIfExists(
      new Date().toISOString().split('T')[0],
      'completion_date',
    );
    this.setCompletedBy();
    this.setNestedFieldIfExists(
      this.formGroup.value?.email,
      'completed_by',
      'email',
    );
    this.setCompletedByPhone(this.formGroup.value?.phone);
    // this.setNestedFieldIfExists(this.formGroup.value?.phone, "completed_by", "phone");
  }

  setCompletedBy() {
    let completed_by: any = null;

    if (this.formGroup.value?.completed_by) {
      completed_by = {
        full_name: this.formGroup.value?.completed_by,
      };
    }
    this.setNestedFieldIfExists(completed_by, 'completed_by', 'name');
  }

  getPhoneNumbers(prop: any) {
    return this.phoneFormGroup.get(prop)?.value;
  }

  submit(): void {
    this.buildPayload();
    const cxPayload = {
      referral_type: this.payload.referral_type,
      visit_type: this.payload.visit_type,
      ob_history: '',
      previous_pregnancy: this.payload.previous_pregnancy_count,
      live_births: this.payload.previous_live_birth_count,
      current_trimester: this.payload.current_trimester,
      gestation: this.payload.weeks_of_gestation,
      medical_risks: this.formGroup.value.medical_risks
        .filter((item: any) => item !== undefined || item !== null)
        .toString(),
      social_risks: this.formGroup.value.social_risks
        .filter((item: any) => item !== undefined || item !== null)
        .toString(),
      other_risks: this.formGroup.value.other_risks
        .filter((item: any) => item !== undefined || item !== null)
        .toString(),
    };

    this.busy.push(
      this.api.post(this.config.endpoints.submitApi, this.payload).subscribe(
        (resp: any) => {
          this.response = resp;
          if (this.state === 'review') this.state = 'confirm';
        },
        (err: any) => {
          this.toggleError();
        },
      ),
    );
    this.analyticsService.sendEvent(
      this.config.footer.buttons.buttonSubmit.cx_event_key,
      cxPayload,
    );
  }

  getDefaultReviewBox(control: any) {
    if (control.type === 'date') {
      if (
        this.formGroup.get(control.prop) &&
        this.formGroup.get(control.prop).value
      ) {
        const value: any = this.formGroup.get(control.prop).value;
        const dateObj = new Date(`${value}T00:00:00`);

        return new Intl.DateTimeFormat('en-US').format(dateObj);
      } else {
        return this.noSpace;
      }
    }

    return this.formGroup.get(control.prop)
      ? this.formGroup.get(control.prop).value
        ? this.formGroup.get(control.prop).value
        : this.noSpace
      : this.noSpace;
  }

  toggleError() {
    this.error = !this.error;
  }

  showError(card, control): boolean {
    const { tobaccoUseProp, formGroup, tobaccoUseCardId } = tobaccoUse;

    return (
      card.id === tobaccoUseCardId &&
      control.type === formGroup &&
      this.formGroup.get(tobaccoUseProp)?.invalid &&
      (this.formGroup?.get(tobaccoUseProp)?.dirty ||
        this.formGroup?.get(tobaccoUseProp)?.touched)
    );
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.genericServices.showFooter$.next(true);
    });
  }
}
