import { finalize } from 'rxjs/operators';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from '@zipari/web-services';
import { Subscription } from 'rxjs';

import Message from '../../../models/shared/Message.model';
import { ConfigService } from '../../../services';
import { CX_CALLS } from '../../../constants/cx-calls.constant';
import { AnalyticsService } from '../../../services/analytics.service';
import { MessagesService } from '../messages.service';
import { AddOrEditModalComponent } from '../../../modules/add-row-modal/add-or-edit-modal.component';
import { AttachmentMetaData } from '../messages.model';
import { MessagePageConfig } from './message.constant';
import { fade } from '../../../../provider-portal/shared/animations/fade';

@Component({
  selector: 'message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  animations: [fade],
  encapsulation: ViewEncapsulation.None,
})
export class MessageComponent implements OnInit {
  config: MessagePageConfig;
  message: Message;
  busy: Subscription;
  showMessageField: boolean;
  formGroup: UntypedFormGroup;
  messageId: string;
  attachmentMetaData: AttachmentMetaData[] = [];
  showAttachmentModal = false;
  showConfirmationModal: boolean;
  addSuccessResponse: any;
  addErrorResponse: any;
  @ViewChild(AddOrEditModalComponent) attachmentModal: AddOrEditModalComponent;

  constructor(
    private configService: ConfigService,
    private router: Router,
    private apiService: APIService,
    private route: ActivatedRoute,
    private messagesService: MessagesService,
    public analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.config = this.configService.getPageConfig('message');
    this.messageId = this.route.snapshot.params.id;
    this.getMessage();
    this.initForm();
  }

  getMessage(): void {
    if (this.config?.endpoint) {
      this.busy = this.apiService
        .get(`${this.config.endpoint}${this.messageId}/`)
        .pipe(finalize(() => this.messagesService.unreadCount().subscribe()))
        .subscribe((resp: Message) => {
          this.message = resp;
        });
    }
  }

  initForm(): void {
    this.formGroup = new UntypedFormGroup({
      content: new UntypedFormControl('', [Validators.required]),
    });
    if (this.config?.reply?.replyForm?.attachment_ids) {
      this.formGroup.addControl('attachment_ids', new UntypedFormControl());
      this.formGroup
        ?.get('attachment_ids')
        .valueChanges.subscribe((attachmentIds: number[]) => {
          this.filterOutAttachmentMetaDataByIds(attachmentIds);
        });
    }
  }

  sendMessage(): void {
    const payload: Message = {
      ...this.formGroup?.value,
      subject: this.message?.subject,
      category: this.message?.category?.toLowerCase(),
    };

    this.busy = this.apiService
      .post(
        `/api/provider-portal/message-center/threads/${this.messageId}/messages/`,
        payload,
      )
      .subscribe((resp: Message) => {
        this.getMessage();
        this.formGroup?.reset();
        this.showMessageField = false;
        this.analyticsService.sendEvent(
          CX_CALLS.pp_message_reply_sent.event_key,
        );
        this.openConfirmationModal();
      });
  }

  cancelMessage(): void {
    this.showMessageField = false;
    this.formGroup?.get('content')?.setValue('');
  }

  toggleView(target: Message, event: MouseEvent): void {
    if (!target.isOpen) {
      this.analyticsService.sendEvent(CX_CALLS.pp_message_viewed.event_key, {
        message_category: this.message?.category,
        date_created: target.date,
      });
    }
    if (!(event.target instanceof HTMLAnchorElement)) {
      target.isOpen = !target.isOpen;
    }
  }

  openReply(): void {
    this.showMessageField = true;
  }

  routeBack(): void {
    this.router.navigate(['/provider-portal/messages']);
  }

  openAttachmentModal(): void {
    this.showAttachmentModal = true;
  }

  openConfirmationModal(): void {
    this.showConfirmationModal = true;
  }

  closeConfirmationModal(): void {
    this.showConfirmationModal = false;
  }

  closeModal(): void {
    this.showAttachmentModal = false;
    this.showConfirmationModal = false;
  }

  onFileMetaData(event: AttachmentMetaData): void {
    this.attachmentMetaData.push(event);
    this.analyticsService.sendEvent(
      CX_CALLS.pp_message_document_attached.event_key,
    );
  }

  filterOutAttachmentMetaDataByIds(attachmentIds: number[]): void {
    this.attachmentMetaData = this.attachmentMetaData.filter(
      (attachmentMetaDatum: AttachmentMetaData) =>
        attachmentIds.find(
          (attachmentId: number) => attachmentMetaDatum.id === attachmentId,
        ),
    );
  }

  onRemoveAttachment(attachment: AttachmentMetaData): void {
    const attachmentFormValue = this.formGroup?.get('attachment_ids')?.value;
    const removeIndex = attachmentFormValue?.indexOf(attachment.id);

    attachmentFormValue?.splice(removeIndex, 1);
    this.attachmentModal?.removeAttachmentByIndex(removeIndex);
    this.formGroup?.get('attachment_ids')?.setValue(attachmentFormValue);
  }
}
