import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AllControlsConfiguration } from '@zipari/shared-ds-util-form';
import { SuspendUserConstants } from '../../manage-portal-users-details.constant';
import { SuspendUserModalConfig } from '../../manage-portal-users-details.model';

@Component({
  selector: 'suspend-user-modal',
  templateUrl: './suspend-user-modal.component.html',
})
export class SuspendUserModalComponent {
  @Input() config: SuspendUserModalConfig;
  @Input() showSuspensionTypeModal: boolean;
  @Input() suspensionTypeModalForm: UntypedFormGroup;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  public onFormCreated(): void {
    const suspensionTypeFormControl =
      this.config?.userSuspensionTypeForm?.controls.find(
        (control: AllControlsConfiguration) =>
          control.prop === SuspendUserConstants.suspendTypeProp,
      );

    if (suspensionTypeFormControl) {
      this.suspensionTypeModalForm
        ?.get(suspensionTypeFormControl.prop)
        ?.patchValue(suspensionTypeFormControl.defaultValue);
    }
  }

  suspensionTypeSelected(): void {
    this.submitButtonClicked.emit();
  }

  closeUserSuspensionTypeModal(): void {
    this.closeModal.emit();
  }
}
