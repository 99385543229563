import { ElementRef, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GenericServices {
  public selectedMemberId: any = null;
  public subscriptions: Subscription[] = [];
  showFooter$ = new BehaviorSubject<boolean>(true);
  _previousURL = '';
  _currentURL = '';
  private messageSource = new BehaviorSubject('');

  constructor(public router: Router) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this._previousURL = event.url;
      });
    // Next line does nothing. Delete once confirmed
    // this.selectedMemberId = this.selectedMemberId;
  }

  get showOrHideFooter() {
    return this.showFooter$.asObservable();
  }

  get previousURL() {
    return this._previousURL;
  }

  get currentURL() {
    return this._currentURL;
  }

  public changeSelectedMemberId(id: string) {
    this.messageSource.next(id);
  }

  public scrollToFirstInvalidControl(el: ElementRef) {
    let firstInvalidControl: HTMLElement =
      el.nativeElement.querySelector('div .is-invalid');

    if (!firstInvalidControl) {
      firstInvalidControl = el.nativeElement.querySelector('div .error');
    }
    // if (!firstInvalidControl) firstInvalidControl = this.el.nativeElement.querySelector("div .is-invalid");
    if (firstInvalidControl) {
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: 'smooth',
      });

      fromEvent(window, 'scroll')
        .pipe(debounceTime(100))
        .subscribe(() => {
          firstInvalidControl.focus();
        });
    } else {
      window.scrollTo(0, 0);
    }
  }

  public format(value: any) {
    const date = moment(value, 'YYYY-MM-DD');

    if (date.isValid()) return date.format('MM/DD/YYYY');
    if (typeof value === 'boolean') {
      if (value) return 'True';
      else return 'False';
    }

    return value;
  }

  public formatControl(control: any, row: any) {
    const value = control.value;
    const date = moment(value, 'YYYY-MM-DD');

    if (date.isValid() && row?.type === 'date') {
      return date.format('MM/DD/YYYY');
    }
    if (row?.type === 'switch' && !control?.value) return 'No';
    if (typeof value === 'boolean') {
      if (value) return 'Yes';
      else return 'No';
    }

    return value;
  }

  getObjectProperty(obj, key) {
    return key.split('.').reduce(function (result, inner_key) {
      if (!result) return;

      return result[inner_key];
    }, obj);
  }

  sortObjectsByPriority(obj): any {
    const order: any = [];
    const res = {};

    Object.keys(obj).forEach((key) => (order[obj[key]['priority'] - 1] = key));
    order.forEach((key) => {
      res[key] = obj[key];
    });

    return res;
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 150;

    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }
}
