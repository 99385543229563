import { UntypedFormGroup } from '@angular/forms';
import { SelectionEvent } from './provisioner.component';
import {
  ProvisionAccessType,
  ProvisionEventAttributeKey,
  ProvisionType,
} from './provisioner.constant';

export interface TreeNode {
  [key: string]: any;
  row?: any;
  column?: any;
  selectionId?: any;
  selectionEvent?: SelectionEvent;
  children?: TreeNode[];
}

export interface ExitTreeNode {
  access_type: any;
  external_id: any;
}

interface ItemData {
  group: UntypedFormGroup;
  data: any;
  column: any;
  config: any;
  row: any;
  onlyLoadWhen: any;
}

export class ProvisionerService {
  id: any;
  firstName: any;
  lastName: any;
  itemData: Map<any, ItemData[]> = new Map<any, ItemData[]>(); // column_id map
  clicked: any[]; // column_id map - do not init this. tells you the id of the selection given a column index
  clickedElections: any[]; // same as above but holds the whole object
  rootNode: TreeNode = {
    children: new Array<TreeNode>(),
  }; // array of tree nodes - builds a directed graph using the column cells
  public config: any;

  public clearSelections(): void {
    this.itemData = new Map<any, ItemData[]>();
    this.rootNode = {
      children: new Array<TreeNode>(),
    }; // Array of tree nodes - builds a directed graph using the column cells
  }

  public setConfig(config: any): void {
    this.config = config;
  }

  public setUserId(id: any): void {
    this.id = id;
  }

  public clearUserId(): void {
    this.id = null;
  }

  public getUserId() {
    return this.id;
  }

  public setUserFirstName(event: any): void {
    this.firstName = event;
  }

  public setUserLastName(event: any): void {
    this.lastName = event;
  }

  public getUserName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  public getApiCommonUniqueField() {
    return this.config?.apiCommonUniqueField;
  }

  public getTreeNode() {
    return this.rootNode;
  }

  public addFormGroup(
    group: any,
    data: any,
    column: any,
    row: any,
    config: any,
    onlyLoadWhen: any,
  ): void {
    if (!this.itemData.get(column)) {
      this.itemData.set(column, new Array<ItemData>());
    }
    const columnFormGroups: ItemData[] = this.itemData.get(column);
    const itemData: ItemData = {
      group: group,
      data: data,
      column: column,
      row: row,
      config: config,
      onlyLoadWhen: onlyLoadWhen,
    };

    columnFormGroups.push(itemData);
  }

  public addSelection(selection: SelectionEvent): void {
    // idempotent
    // build and maintain a tree for the view component
    // any time a selection is added, if that selection is in the first column, it is a root node
    // build a treeNode object and and have it adopt a selection as a child
    if (selection.column === 0) this.addChildToNode(this.rootNode, selection);
    // if a selection is added taht is not a root node
    // used the currently clicked lineage to determine where to insert the node
    // it is safe to assume that selection is only allowed when lineage is valid
    else {
      let currentNode: TreeNode = this.rootNode;
      const index: number = selection.column;
      let previousNode: TreeNode = null;

      for (let i = 0; i < index; i++) {
        // start at the root
        previousNode = currentNode;
        currentNode = this.findSelectionInChildren(
          currentNode,
          this.clicked[i],
        );
        // no currentNode means a partial tree modification
        // we need to build a lineage the tree using parents
        if (!currentNode) {
          this.addChildToNode(previousNode, this.clickedElections[i]);
          currentNode = this.findSelectionInChildren(
            previousNode,
            this.clicked[i],
          );
        }
        if (i === index - 1) {
          // found the immediate parent
          // idempotent add
          this.addChildToNode(currentNode, selection);
        }
      }
    }
  }

  public getFlatSelections() {
    const selections = [];
    const treeNodes: TreeNode[] = JSON.parse(
      JSON.stringify(this.rootNode.children),
    );
    const stack: TreeNode[] = treeNodes;
    let current: any;

    while ((current = stack.shift())) {
      if (current.selectionEvent?.formData) {
        selections.push({
          access_type: current.selectionEvent.formData.access_type,
          external_id: current.selectionEvent.data.external_id,
          type: current.selectionEvent.data.type,
        });
      }
      if (current.children?.length) {
        for (const childIndex in current.children) {
          stack.push(current.children[childIndex]);
        }
      }
    }

    return {
      eventAttributes: this.getEventAttributes(selections),
      flatSelections: selections?.map((selection: any) => {
        delete selection.type;

        return selection;
      }),
    };
  }

  getEventAttributes(selections: any[]): object {
    const dictionaryAttributes = {};

    selections.forEach((selection: any) => {
      switch (selection.type) {
        case ProvisionType.groupNPI:
          {
            if (selection.access_type === ProvisionAccessType.fullAccess) {
              dictionaryAttributes[
                ProvisionEventAttributeKey.groupNpiFullAccess
              ] = this.setAccessIds(
                dictionaryAttributes[
                  ProvisionEventAttributeKey.groupNpiFullAccess
                ],
                selection.external_id,
              );
            } else if (
              selection.access_type === ProvisionAccessType.managedAccess
            ) {
              dictionaryAttributes[
                ProvisionEventAttributeKey.groupNpiManagedAccess
              ] = this.setAccessIds(
                dictionaryAttributes[
                  ProvisionEventAttributeKey.groupNpiManagedAccess
                ],
                selection.external_id,
              );
            }
          }
          break;
        case ProvisionType.facility:
          {
            if (selection.access_type === ProvisionAccessType.fullAccess) {
              dictionaryAttributes[ProvisionEventAttributeKey.taxidFullAccess] =
                this.setAccessIds(
                  dictionaryAttributes[
                    ProvisionEventAttributeKey.taxidFullAccess
                  ],
                  selection.external_id,
                );
            } else if (
              selection.access_type === ProvisionAccessType.managedAccess
            ) {
              dictionaryAttributes[
                ProvisionEventAttributeKey.taxIdManagedAccess
              ] = this.setAccessIds(
                dictionaryAttributes[
                  ProvisionEventAttributeKey.taxIdManagedAccess
                ],
                selection.external_id,
              );
            }
          }
          break;
        case ProvisionType.location:
          {
            if (selection.access_type === ProvisionAccessType.fullAccess) {
              dictionaryAttributes[
                ProvisionEventAttributeKey.locationFullAccess
              ] = this.setAccessIds(
                dictionaryAttributes[
                  ProvisionEventAttributeKey.locationFullAccess
                ],
                selection.external_id,
              );
            }
          }
          break;
      }
    });

    return dictionaryAttributes;
  }

  setAccessIds(accessTypeObj: any, externalId: string): object {
    return accessTypeObj ? [...accessTypeObj, externalId] : [externalId];
  }

  public deleteAllChildren(selection: SelectionEvent): void {
    let currentNode: TreeNode = this.rootNode;
    const index: number = selection.column;

    for (let i = 0; i <= index; i++) {
      // start at the root
      const previousNode: TreeNode = currentNode;

      currentNode = this.findSelectionInChildren(currentNode, this.clicked[i]);
      if (!currentNode) {
        currentNode = this.findSelectionInChildren(
          previousNode,
          selection.data[this.getApiCommonUniqueField()],
        );
      }
      if (i === index) {
        // found the selection
        const _parent: TreeNode = currentNode;

        if (_parent) {
          this._deleteAllChildSelections(_parent);
          _parent.children = new Array<TreeNode>();
          break;
        }
      }
    }
  }

  addChildToNode(parent: TreeNode, selection: SelectionEvent): void {
    const isAlreadyAdded: TreeNode = this.findSelectionInChildren(
      parent,
      selection?.data[this.getApiCommonUniqueField()],
    );

    if (!isAlreadyAdded) {
      const rootNode: TreeNode = {
        row: selection?.row,
        column: selection?.column,
        selectionId: selection?.data[this.getApiCommonUniqueField()],
        selectionEvent: selection,
        children: new Array<TreeNode>(),
      };

      parent?.children.push(rootNode);
    } else {
      isAlreadyAdded.selectionEvent = selection;
    }
  }

  findSelectionInChildren(parent: TreeNode, idToLookFor: any): TreeNode {
    return parent?.children.find(
      (item: TreeNode) => item.selectionId === idToLookFor,
    );
  }

  public findSelectionInColumn(column: number): SelectionEvent {
    const id: any = this.clicked[column];

    // search through tree while matching column and id
    let currentNode: TreeNode = this.getTreeNode();

    for (let i = 0; i < column; i++) {
      currentNode = this.findSelectionInChildren(currentNode, this.clicked[i]);
      if (!currentNode) return null;
    }
    currentNode = this.findSelectionInChildren(currentNode, id);
    if (!currentNode) return null;

    return currentNode.selectionEvent;
  }

  public enableColumns(config: any, eventColumn: any): void {
    for (let i: number = eventColumn; i < this.clicked.length; i++) {
      if (this.findSelectionInColumn(i)) {
        const _selection: SelectionEvent = this.findSelectionInColumn(i);

        if (this.checkSelectionValue(config, _selection)) {
          this._enableColumnsWithParentId(
            i,
            _selection.data[this.getApiCommonUniqueField()],
          );
        }
      } else if (this.clickedElections[i]) {
        const preSelection: any = this.clickedElections[i];

        if (this.checkPreSelectionValue(config, preSelection)) {
          this._enableColumns(i);
        }
      } else break;
    }
  }

  // We need to get selection from previous column so pass parent id
  public _enableColumnsWithParentId(i: number, parentId: number): void {
    const items: any[] = this.itemData.get(i + 1);

    if (items) {
      for (const item of items) {
        const controls = item.group.controls;
        const config = item.config;

        for (const formControl of config.form_fields) {
          const prop = formControl.prop;
          let valueToSet: any = this.checkIfSelectionExistsReturnProp(
            i,
            parentId,
            prop,
          );

          if (valueToSet) {
            if (
              !formControl.options.find(
                (option: { value: any }) => option.value === valueToSet,
              )
            ) {
              valueToSet = item.data[prop];
            }
          } else {
            valueToSet = item.data[prop];
          }
          item.group.get(prop)?.setValue(valueToSet, { onlySelf: true });
        }
        Object.keys(controls).map((c: any) =>
          controls[c].enable({ emitEvent: false, onlySelf: true }),
        );
      }
    }
  }

  public _enableColumns(i: number): void {
    const items: any[] = this.itemData.get(i + 1);

    for (const item of items) {
      const controls = item.group.controls;
      const id = item.data[this.getApiCommonUniqueField()];
      const config = item.config;

      for (const formControl of config.form_fields) {
        const prop = formControl.prop;
        let valueToSet: any = this.checkIfSelectionExistsReturnProp(
          i,
          id,
          prop,
        );

        if (!valueToSet) valueToSet = item.data[prop];
        item.group.get(prop)?.setValue(valueToSet, { onlySelf: true });
      }
      Object.keys(controls).map((c: any) =>
        controls[c].enable({ emitEvent: false, onlySelf: true }),
      );
    }
  }

  public disableColumns(column: number, election: any): void {
    for (let i: number = column; i < this.clicked.length; i++) {
      const items: any[] = this.itemData.get(i + 1);

      if (items) {
        for (const item of items) {
          const controls = item.group.controls;

          Object.keys(controls).map((c: any) => {
            controls[c].setValue(election, {
              emitEvent: false,
              onlySelf: true,
            });
            controls[c].disable({ emitEvent: false, onlySelf: true });
          });
        }
      }
    }
  }

  checkSelectionValue(config: any, selection: SelectionEvent): boolean {
    if (config?.columns[selection.column]?.onlyLoadWhen) {
      if (!selection.formData) return false;
      const election: any =
        selection.formData[config.columns[selection.column].onlyLoadWhen.prop];

      if (election === config.columns[selection.column].onlyLoadWhen.value) {
        return true;
      }

      return false;
    }

    return true;
  }

  checkPreSelectionValue(config: any, selection: SelectionEvent): boolean {
    if (config?.columns[selection.column]?.onlyLoadWhen) {
      if (!selection.data) return false;
      const election: any =
        selection.data[config.columns[selection.column].onlyLoadWhen.prop];

      if (election === config.columns[selection.column].onlyLoadWhen.value) {
        return true;
      }

      return false;
    }

    return true;
  }

  public checkColumnDisableStatus(
    index: number,
    onlyLoadWhen: any = null,
  ): boolean {
    // returns true if you should enable the column

    if (onlyLoadWhen) {
      for (let i = 0; i < index; i++) {
        if (this.clicked[i]) {
          const selection: any = this.findSelectionInColumn(i);

          if (selection && selection.formData) {
            const election: any = selection.formData[onlyLoadWhen.prop];

            if (!(election === onlyLoadWhen.value)) return false;
            else continue;
          }

          const preSelection: any = this.clickedElections[i];

          if (preSelection) {
            const election: any = preSelection.data[onlyLoadWhen.prop];

            if (!(election === onlyLoadWhen.value)) return false;
            else continue;
          }
        }
      }
    }

    return true;
  }

  public getColumnDisableStatus(index: number, onlyLoadWhen: any = null): any {
    if (onlyLoadWhen) {
      for (let i = 0; i < index; i++) {
        if (this.clicked[i]) {
          const selection: any = this.findSelectionInColumn(i);

          if (selection && selection.formData) {
            const election: any = selection.formData[onlyLoadWhen.prop];

            if (!(election === onlyLoadWhen.value)) return election;
            else continue;
          }

          const preSelection: any = this.clickedElections[i];

          if (preSelection) {
            const election: any = preSelection.data[onlyLoadWhen.prop];

            if (!(election === onlyLoadWhen.value)) return election;
            else continue;
          }
        }
      }
    }

    return null;
  }

  public checkIfSelectionExistsReturnProp(
    column: number,
    id: any,
    prop: any,
  ): boolean {
    let currentNode: TreeNode = this.getTreeNode();

    if (this.clicked[0] === -1) {
      currentNode = this.findSelectionInChildren(currentNode, id);
    } else {
      for (let i = 0; i < column; i++) {
        currentNode = this.findSelectionInChildren(
          currentNode,
          this.clicked[i],
        );
        if (!currentNode) return false;
      }
      currentNode = this.findSelectionInChildren(currentNode, id);
    }
    if (!currentNode) return false; // no selection

    const selection: SelectionEvent = currentNode.selectionEvent;

    if (!selection.formData) return false;
    if (selection?.data[this.getApiCommonUniqueField()] === id) {
      return selection.formData[prop];
    }

    return false;
  }

  public setClicked(event: any): void {
    this.clicked[event.column] = event.data[this.getApiCommonUniqueField()];
    this.clickedElections[event.column] = event;
  }

  public initClicked(event: any): void {
    this.clicked = event;
    this.clickedElections = new Array<any>(this.clicked.length);
    for (let i = 0; i < this.clicked.length; i++) {
      this.clicked[i] = -1;
      this.clickedElections[i] = null;
    }
  }

  public resetClicked(index: number): void {
    this.clicked[index] = -1;
    this.clickedElections[index] = null;
  }

  private _deleteAllChildSelections(parent: TreeNode): void {
    let children: TreeNode[] = parent?.children;

    while (children?.length) {
      const _node: TreeNode = children.pop();

      if (_node.children) {
        children = children.concat(_node.children);
      }
    }
  }
}
