import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormControlModule, PortalFooterModule } from '@zipari/design-system';
import { NavigationModule } from '../shared/modules/navigation/navigation.module';
import { FormattingService } from '../shared/services';
import { ZipEndpointService } from '../shared/services/zip-endpoint.service';
import {
  DetailModule,
  ListModule,
  MessagesModule,
  NotificationsModule,
} from '../shared/templates';
import { GenericWorkflowModule } from '../shared/templates/generic-workflow/generic-workflow.module';
import { MessageModule } from '../shared/templates/messages/message/message.module';
import { ZipBusyModule } from '../shared/modules/zip-busy/zip-busy.module';
import { SquidexModule } from '../shared/modules/cms';
import { AuthenticationModule } from '../shared/modules/authentication/authentication.module';
import { ProviderPortalRoutingModule } from './provider-portal-routing.module';
import { ProviderPortalComponent } from './provider-portal.component';
import { GenericServices } from './shared/services/generic.service';
import { SitemapComponent } from './sitemap.component';
import { BenefitLineModule } from './templates/benefit-line/benefit-line.module';
import { ClaimDetailModule } from './templates/claim-detail/claim-detail.module';
import { ClaimLineDetailModule } from './templates/claim-line-detail/claim-line-detail.module';
import { DashboardModule } from './templates/dashboard/dashboard.module';
import { DocumentCenterModule } from './templates/document-center/document-center.module';
import { EligibilityUsersModule } from './templates/eligibility/eligibility-users.module';
import { EligibilityUserDetailModule } from './templates/eligibility/user-detail/eligibility-user-detail.module';
import { ManageAccessRequestDetailModule } from './templates/manage-access-request-detail/manage-access-request-detail.module';
import { ManageAccessRequestsModule } from './templates/manage-access-requests/manage-access-requests.module';
import { ManagePortalUsersCreateUserModule } from './templates/manage-portal-users-create-user/manage-portal-users-create-user.module';
import { ManagePortalUsersDetailModule } from './templates/manage-portal-users-detail/manage-portal-users-detail.module';
import { PatientDetailModule } from './templates/patient-detail/patient-detail.module';
import { PortalUsersModule } from './templates/portal-users/portal-users.module';
import { PortalUserDetailModule } from './templates/portal-users/user-detail/portal-user-detail.module';
import { PregnancyRiskAssesmentModule } from './templates/pregnancy-risk-assesment/pregnancy-risk-assesment.module';
import { ProviderUpdateToolModule } from './templates/provider-update-tool/provider-update-tool.module';
import { SettingsModule } from './templates/settings/settings.module';
import { TransferOfCareDetailModule } from './templates/transfer-of-care/transfer-of-care-detail.module';
import { ProvisionerColumnModule } from './templates/user-provisioning/column/provisioner-column.module';
import { EditUserProvisioningModule } from './templates/user-provisioning/edit/edit-user-provisioning.module';
import { ProvisionerModule } from './templates/user-provisioning/provisioner/provisioner.module';
import { ReferralSubmissionModule } from './templates/referral-submission/referral-submission.module';
import { AuthorizationSubmissionModule } from './templates/authorization-submission/authorization-submission.module';
import { NewDashboardModule } from './templates/new-dashboard/new-dashboard.module';
import { ClaimSubmissionModule } from './templates/claim-submission/claim-submission.module';
import { ProviderGroupDetailModule } from './templates/provider-group-detail/provider-group-detail.module';
import { ManagePortalUsersModule } from './templates/manage-portal-users/manage-portal-users.module';
import { ProviderGroupsModule } from './templates/provider-groups/provider-groups.module';
import { EditAuthorizationModule } from './templates/edit-authorization/edit-authorization.module';
import { ProviderAdminsModule } from './templates/provider-admins/provider-admins.module';
import { DocumentCenterWrapperModule } from './templates/document-center-wrapper/document-center-wrapper.module';

@NgModule({
  imports: [
    CommonModule,
    AuthenticationModule,
    ProviderPortalRoutingModule,
    ListModule.forRoot(),
    DetailModule,
    DocumentCenterModule,
    NavigationModule,
    ClaimDetailModule,
    DashboardModule,
    SettingsModule,
    MessagesModule,
    MessageModule,
    NotificationsModule,
    PortalUserDetailModule,
    EligibilityUserDetailModule,
    EligibilityUsersModule,
    PortalUsersModule,
    FormControlModule,
    PatientDetailModule,
    ClaimLineDetailModule,
    BenefitLineModule,
    DocumentCenterModule,
    ZipBusyModule,
    PortalFooterModule,
    GenericWorkflowModule,
    TransferOfCareDetailModule,
    ProvisionerColumnModule,
    EditUserProvisioningModule,
    ProvisionerModule,
    ManagePortalUsersCreateUserModule,
    ProviderUpdateToolModule,
    ManagePortalUsersDetailModule,
    ManageAccessRequestsModule,
    ManageAccessRequestDetailModule,
    PregnancyRiskAssesmentModule,
    ReferralSubmissionModule,
    AuthorizationSubmissionModule,
    NewDashboardModule,
    ClaimSubmissionModule,
    ProviderGroupDetailModule,
    ManagePortalUsersModule,
    ProviderGroupsModule,
    EditAuthorizationModule,
    SquidexModule,
    ProviderAdminsModule,
    DocumentCenterWrapperModule,
  ],
  declarations: [ProviderPortalComponent, SitemapComponent],
  providers: [
    ZipEndpointService,
    CurrencyPipe,
    FormattingService,
    GenericServices,
  ],
})
export class ProviderPortalModule {}
