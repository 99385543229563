<ng-container *ngIf="control && config">
  <textarea
    [attr.aria-label]="config.ariaLabel"
    [attr.placeholder]="config.placeholder ? config.placeholder : null"
    [attr.readonly]="config?.readonly ? config?.readonly : null"
    [attr.disabled]="config?.isDisabled ? config?.isDisabled : null"
    [attr.type]="config.type"
    [id]="config.id || config.prop"
    class="form-control form-control--textarea"
    [ngClass]="{ 'form-control--textarea__resize': config.resize }"
    ngDefaultControl
    [name]="config.prop"
    [maxlength]="config.maxLength || ''"
    [formControl]="control"
    [attr.data-test-id]="
      'input_' + (config.id || config.prop || config.ariaLabel)
    "
    [attr.autocomplete]="config?.autocomplete || config.prop"
    (input)="checkMaxLength($event)"
  >
  </textarea>

  <span
    *ngIf="
      config.remainingCount && config.maxLength > 0 && control.value?.length
    "
    class="form-control-textarea__remaining-characters"
    [ngClass]="{
      'form-control-textarea__remaining-characters--warning':
        maximumCharactersNumberReached,
    }"
    [attr.data-test-id]="
      'input_' +
      (config.id || config.prop || config.ariaLabel) +
      'warning-message'
    "
  >
    {{ config.maxLength - control.value.length }} characters remaining
  </span>

  @if (config.clearTextButton) {
    <zip-button
      class="FormControl__TextAreaClearButton"
      data-test-id="clearTextButton"
      [config]="config.clearTextButton"
      (zipButtonClicked)="control.reset()"
    ></zip-button>
  }
</ng-container>
