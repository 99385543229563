<ng-container *ngIf="!isNavHidden && navHasLoaded">
  <toolbar
    *ngIf="!!navigationService.config?.toolbar && isLoggedIn"
    [data]="data"
    [isLoggedIn]="isLoggedIn"
    [config]="navigationService.config.toolbar"
  ></toolbar>

  <mobile-menu></mobile-menu>

  <nav
    class="nav"
    [ngClass]="{ 'nav--alt': !!navigationService.config?.navAlt }"
  >
    <div class="container">
      <div class="sub-container u-flex">
        <div
          *ngIf="navigationService.config?.showHamburger"
          class="nav__toggle"
        >
          <i
            class="icon icon--medium icon--pointer"
            (click)="navigationService.toggleMobileMenu()"
          >
            hamburger
          </i>
        </div>

        <a
          (click)="logoClicked()"
          class="nav__logo"
          [ngClass]="{ notLogged: !isLoggedIn }"
          tabindex="0"
          [attr.aria-label]="navigationService.config?.alt"
        >
        </a>

        <section
          class="nav__links"
          *ngIf="isLoggedIn && navigationService.showNavLinks"
        >
          <div
            class="nav__link"
            [ngClass]="{
              'is-open': !!link.items && !!link.open,
              'is-active': navigationService.isActiveRoute(link.route),
            }"
            *ngFor="let link of navigationService.config?.links"
          >
            <ng-container *ngIf="!!link.route">
              <a
                class="t-data"
                (click)="navigationService.selectNavLink($event, link)"
                [routerLink]="link.route"
                [queryParams]="queryParams"
              >
                {{ link.label }}
              </a>
            </ng-container>

            <ng-container *ngIf="!!link.link">
              <a class="t-data" [href]="link.link" target="_blank">
                {{ link.label }}
              </a>
            </ng-container>

            <ng-container *ngIf="!!link.items">
              <div
                class="u-flex"
                (click)="navigationService.selectNavLink($event, link)"
                (keydown.enter)="navigationService.selectNavLink($event, link)"
                (keydown.esc)="closeLink(link)"
                tabindex="0"
              >
                <p class="t-data">{{ link.label }}</p>

                <i
                  class="icon icon--medium"
                  [ngClass]="{ 'icon--rotate180': !!link.open }"
                >
                  chevron_down
                </i>
              </div>
            </ng-container>

            <div
              class="nav__link__menu"
              (libOutsideClick)="closeLink(link)"
              (keydown.esc)="closeLink(link)"
              *ngIf="!!link.items && !!link.open"
            >
              <div *ngFor="let item of link.items">
                <div *ngIf="!!item.route">
                  <a
                    class="nav__link__menu__item"
                    [routerLink]="item.route"
                    (click)="closeLink(link)"
                    [queryParams]="queryParams"
                  >
                    <i class="icon icon--medium">{{ item.icon }}</i>

                    <div>
                      <p class="t-data">{{ item.label }}</p>
                      <p class="t-data t-alt">{{ item.description }}</p>
                    </div>
                  </a>
                </div>
                <div *ngIf="!!item.externalLink">
                  <a
                    class="nav__link__menu__item"
                    href="{{ item.externalLink }}{{
                      queryParams.member_id
                    }}/cxengine/"
                    target="_blank"
                  >
                    <i class="icon icon--medium">{{ item.icon }}</i>

                    <div>
                      <p class="t-data">{{ item.label }}</p>
                      <p class="t-data t-alt">{{ item.description }}</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          class="nav__icons nav__icons--mobile"
          *ngIf="navigationService.config?.mobileIcons"
        >
          <a
            class="nav__link"
            [ngClass]="{
              'is-active': navigationService.isActiveRoute(tool.route),
            }"
            [routerLink]="tool.route"
            (click)="navigationService.selectNavLink($event, tool)"
            *ngFor="let tool of navigationService.config.mobileIcons"
          >
            <i class="icon icon--medium">{{
              !!navigationService.isActiveRoute(tool.route)
                ? tool.icon + '_active'
                : tool.icon
            }}</i>
          </a>
        </section>

        <section
          class="nav__icons nav__icons--desktop"
          *ngIf="navigationService.config?.desktopIcons"
        >
          <a
            class="nav__link"
            [ngClass]="{
              'is-active': navigationService.isActiveRoute(tool.route),
            }"
            [routerLink]="tool.route"
            (click)="navigationService.selectNavLink($event, tool)"
            *ngFor="let tool of navigationService.config.desktopIcons"
          >
            <i class="icon icon--medium">{{
              !!navigationService.isActiveRoute(tool.route)
                ? tool.icon + '_active'
                : tool.icon
            }}</i>
            <span
              *ngIf="
                showNotificationsIndicator && tool.icon === 'notifications'
              "
              class="mp-navigation__notifications-indicator"
            >
              <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                <circle cx="25" cy="25" r="25" fill="#E10B30" />
              </svg>
            </span>
          </a>
        </section>

        <section
          class="u-flex u-alignCenter u-justifyEnd u-fillRemaining"
          *ngIf="!!navigationService.config?.buttons"
        >
          <div class="button__group">
            <ng-container
              *ngFor="let button of navigationService.config.buttons"
            >
              <ng-container *ngIf="button.type === 'auth'; else defaultButton">
                <zip-button
                  class="nav__icons--desktop"
                  (zipButtonClicked)="handleLogout()"
                  *ngIf="
                    authService.loggedInUser &&
                      authService.loggedInUser.app_user_data;
                    else logIn
                  "
                  [level]="button.level"
                  [content]="button.logout || 'Log Out'"
                >
                </zip-button>

                <ng-template #logIn>
                  <zip-button
                    class="nav__icons--desktop"
                    (zipButtonClicked)="handleRegister()"
                    [level]="button.level || 'medium'"
                    [content]="button.register || 'Register'"
                  >
                  </zip-button>

                  <zip-button
                    class="nav__icons--desktop"
                    (zipButtonClicked)="handleLogin()"
                    [level]="button.level"
                    [content]="button.login || 'Log In'"
                  >
                  </zip-button>
                </ng-template>
              </ng-container>

              <ng-template #defaultButton>
                <ng-container *ngIf="button.onClick">
                  <zip-button
                    (zipButtonClicked)="onNavButtonClick(button.onClick)"
                    [level]="button.level"
                    [content]="button.content"
                  >
                  </zip-button>
                </ng-container>

                <a *ngIf="button.link" [href]="button.link" target="_blank">
                  <zip-button [level]="button.level" [content]="button.content">
                  </zip-button>
                </a>
                <a *ngIf="button.route" [routerLink]="button.route">
                  <zip-button [level]="button.level" [content]="button.content">
                  </zip-button>
                </a>
              </ng-template>
            </ng-container>
          </div>
        </section>
        <div
          class="nav__links dashboard__link"
          *ngIf="!navigationService.showNavLinks"
        >
          <div
            *ngIf="router.url !== '/provider-portal/dashboard'"
            (click)="logoClicked()"
            class="nav__link"
          >
            <i class="icon icon--medium">exit</i>
            <a class="t-data"> Back to Dashboard</a>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <article *ngIf="modalOpen" @fade class="modal__mask"></article>

  <section
    *ngIf="modalOpen"
    @slideRight
    class="modal custom--modal modal--panel contact--modal"
  >
    <header class="modal__header">
      <div class="u-flex u-alignCenter u-maxX">
        <p class="t-data t-bold">Contact Us</p>

        <i class="icon icon--medium icon--pointer" (click)="modalClose()">
          close
        </i>
      </div>
    </header>

    <section class="modal__body">
      <h4 class="t-bold">{{ contactConfigs?.title }}</h4>

      <div class="section--title">
        <i class="icon icon--medium t-bold">phone</i>
        <h6 class="t-bold">{{ contactConfigs?.subtitle }}</h6>
      </div>

      <model-attribute
        *ngFor="let config of contactConfigs?.sections"
        [config]="config"
      >
      </model-attribute>

      <div class="section--title" *ngIf="brokerAssistanceConfigs">
        <i class="icon icon--medium t-bold">person</i>
        <h6 class="t-bold">Broker Assistance</h6>
      </div>

      <model-attribute
        *ngFor="let config of brokerAssistanceConfigs"
        [config]="config"
      >
      </model-attribute>
    </section>
  </section>
</ng-container>
