import { distinctUntilChanged, filter } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Prompt } from '@zipari/design-system';
import { TableConfig } from '@zipari/design-system';
import { cloneObject } from '@zipari/web-utils';
import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { TableService } from '@zipari/design-system';
import { finalize, catchError } from 'rxjs/operators';
import { FormControlOptionConfiguration } from '@zipari/shared-ds-util-form';
import { slideRight } from '../../animations';
import Message from '../../models/shared/Message.model';
import { AnalyticsService } from '../../services/analytics.service';
import { ListComponent } from '../list/list.component';
import { ConfigService } from '../../services/config.service';
import { MessagesConfig, AttachmentMetaData } from './messages.model';
import { MessagesService } from './messages.service';
import { CX_CALLS } from '../../constants/cx-calls.constant';

@Component({
  selector: 'messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  animations: [slideRight],
  encapsulation: ViewEncapsulation.None,
})
export class MessagesComponent implements OnInit, OnDestroy {
  @ViewChild(ListComponent) list: ListComponent;
  dataTransformed = false;
  messagesJustArchived = false;
  config: MessagesConfig;
  tableConfig: TableConfig;
  mailboxSelection$: BehaviorSubject<string> = new BehaviorSubject(null);
  mailboxSelectionSub: Subscription;
  tableService: TableService;

  constructor(
    private configService: ConfigService,
    private messagesService: MessagesService,
    public analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.config = this.configService.getPageConfig('messages');
    this.initMailboxCxEventListener();
  }

  initMailboxCxEventListener(): void {
    this.mailboxSelectionSub = this.mailboxSelection$
      .pipe(
        filter((mailboxSelection: string) => !!mailboxSelection),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.fireMailboxCxEvent();
      });
  }

  tableDataChanged(messages: Message[]): void {
    this.tableService = this.list?.table?.ztService;
    this.tableConfig = this.tableService?.zipTableOptions;
    this.mailboxSelection$.next(this.tableService?.currentFilters?.[0]?.value);

    this.redirectToPageOneIfNoDataAfterArchive();

    this.applyDataTransformation(messages);

    this.removeCheckboxesIfInSent();
    this.hideOrShowArchiveBtn();
    this.tableService?.setupOptions(this.tableConfig);
    this.messagesJustArchived = false;
  }

  fireMailboxCxEvent() {
    const currentMailbox: FormControlOptionConfiguration =
      this.tableService.currentFilters[0].options?.find(
        (dropdownOption: FormControlOptionConfiguration) =>
          dropdownOption?.value === this.mailboxSelection$?.value,
      );

    this.analyticsService.sendEvent(
      CX_CALLS[currentMailbox?.['cx']]?.event_key,
    );
  }

  redirectToPageOneIfNoDataAfterArchive(): void {
    if (
      this.tableService?.currentData?.length === 0 &&
      this.messagesJustArchived
    ) {
      setTimeout(() => {
        this.tableConfig.paging.pageIndex = 0;
        this.tableService.setupOptions(this.tableConfig);
        this.tableService.setPage();
      });
    }
  }

  applyDataTransformation(messages: Message[]): void {
    if (this.config?.table?.dataTransform && !this.dataTransformed) {
      this.dataTransformed = true;
      // changing page number, filters, etc., should re-trigger data transformation
      setTimeout(() => {
        this.dataTransformed = false;
      });

      if (this.config.table.dataTransform.stripHtml?.length > 0) {
        messages = this.stripHtml(messages);
      }
      if (this.config.table.dataTransform.attachmentIcon?.length > 0) {
        messages = this.applyAttachmentIcon(messages);
      }
      if (this.tableService) {
        this.tableService.currentData = messages;
      }
    }
  }

  removeCheckboxesIfInSent(): void {
    if (this.tableConfig) {
      this.tableConfig.selection = this.isMailbox('sent')
        ? null
        : this.config.table.selection;
    }
  }

  hideOrShowArchiveBtn(): void {
    this.tableConfig?.selection?.editHeaderConfig?.headerButtons?.forEach(
      (button: { key: string; isHidden: boolean }) => {
        if (button.key === 'unarchive') {
          button.isHidden = !this.isMailbox('archive');
        }
        if (button.key === 'archive') {
          button.isHidden = this.isMailbox('archive');
        }
      },
    );
  }

  stripHtml(messages: Message[]): Message[] {
    const transformedMessages = messages.map((message: Message) => {
      const newData = cloneObject(message);

      this.config?.table.dataTransform.stripHtml.forEach((key: string) => {
        newData[key] = newData[key]
          // remove escape characters
          // ?.replace(/(\\x00|\\n|\\r|\\|'|"|\\x1a)/g, '')
          // remove html entities
          ?.replace(/&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-f]{1,6});/gi, '')
          ?.replace(/<br ??>/g, ' ')
          // remove html tags
          ?.replace(/<[^>]*>/g, '');
      });

      return newData;
    });

    return transformedMessages;
  }

  applyAttachmentIcon(messages: Message[]): Message[] {
    const transformedMessages = messages.map((message: Message) => {
      if (message.has_attachment) {
        (message.has_attachment as unknown as string) = `<div><i class=\"icon icon--medium\">attachment</i></div>`;
      } else (message.has_attachment as unknown as string) = ' ';

      return message;
    });

    return transformedMessages;
  }

  isMailbox(mailboxName: string): boolean {
    return this.mailboxSelection$.value?.includes(mailboxName);
  }

  archiveMessage(selectedTableRows): void {
    const selectedMessageIds = selectedTableRows.map(
      (row: { ind: number; row: Message }) => row.row?.id,
    );
    const archivePayload = { ids: selectedMessageIds };

    if (this.isMailbox('inbox')) this.bulkArchive(archivePayload);
    else if (this.isMailbox('archive')) this.bulkUnarchive(archivePayload);
  }

  bulkArchive(archivePayload): void {
    this.list.busy = this.messagesService
      .bulkArchive(archivePayload)
      .pipe(
        /* eslint-disable arrow-parens */
        catchError((error) => {
          this.tableService.showPrompt = true;
          (this.tableService.prompt as Partial<Prompt>) = {
            key: 'archiveError',
          };
          throw error;
        }),
        finalize(() => {
          this.messagesService.unreadCount()?.subscribe();
          this.list.table?.refresh();
        }),
      )
      .subscribe(() => {
        this.tableService.showPrompt = true;
        this.messagesJustArchived = true;
        (this.tableService.prompt as Partial<Prompt>) = {
          key: 'archiveSuccess',
        };
        this.analyticsService.sendEvent(CX_CALLS.pp_message_archived.event_key);
      });
  }

  bulkUnarchive(archivePayload): void {
    this.list.busy = this.messagesService
      .bulkUnarchive(archivePayload)
      .pipe(
        /* eslint-disable arrow-parens */
        catchError((error) => {
          this.tableService.showPrompt = true;
          (this.tableService.prompt as Partial<Prompt>) = {
            key: 'unarchiveError',
          };
          throw error;
        }),
        finalize(() => {
          this.messagesService.unreadCount()?.subscribe();
          this.list.table?.refresh();
        }),
      )
      .subscribe(() => {
        this.tableService.showPrompt = true;
        this.messagesJustArchived = true;
        (this.tableService.prompt as Partial<Prompt>) = {
          key: 'unarchiveSuccess',
        };
        this.analyticsService.sendEvent(
          CX_CALLS.pp_message_unarchived.event_key,
        );
      });
  }

  onSendNewMessage(newMessageEvent: {
    key: string;
    selectedItems: object;
    formGroup: {
      category?: string;
      subject?: string;
      content: string;
      attachment_ids?: number[];
    };
  }): void {
    this.analyticsService.sendEvent(CX_CALLS.pp_new_message_created.event_key, {
      messsage_category: newMessageEvent?.formGroup?.category,
    });
    this.list?.table?.refresh();
  }

  onFileMetaData(event: AttachmentMetaData): void {
    this.analyticsService.sendEvent(
      CX_CALLS.pp_message_document_attached.event_key,
    );
  }

  ngOnDestroy(): void {
    this.mailboxSelectionSub?.unsubscribe();
  }
}
