import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import linkifyStr from 'linkifyjs/string';
import { Subscription } from 'rxjs';
import {
  Diagnosis,
  RowDiagnosis,
} from '../../../templates/edit-authorization/edit-authorization.model';
import { APIService } from '@zipari/web-services';

@Component({
  selector: 'flyout-diagnosis',
  templateUrl: './flyout-diagnosis.component.html',
  styleUrls: ['./flyout-diagnosis.component.scss'],
})
export class FlyoutDiagnosisComponent implements OnInit, OnChanges {
  @Input() config: any;
  @Input() group: UntypedFormGroup;
  @Input() state: string;
  @Input() diagnosesData: Diagnosis[];
  @Output() diagnosisDetails: EventEmitter<any> = new EventEmitter<any>();

  showModal = false;
  selectedItem: any = null;
  selectedRowItem: any = null;
  busy: Subscription;
  loaded = false;
  noSpace = 'n/a';

  constructor(private api: APIService) {}

  ngOnInit() {
    if (this.config?.subheader) {
      const options = {
        /* … */
      };

      this.config.subheader = linkifyStr(this.config.subheader, options);
    }
    if (this.config?.noSpace) this.noSpace = this.config?.noSpace;
    if (this.config?.flyout?.table?.filters) {
      const filters: any[] = this.config?.flyout?.table?.filters;
      let hasNoEndpoints = true;

      for (const filter of filters) {
        if (filter?.type === 'select' && filter?.apiEndpoint) {
          hasNoEndpoints = false;
          this.busy = this.api
            .get(filter?.apiEndpoint)
            .subscribe((resp: any) => {
              filter.options = this.populateDropdown(
                resp,
                filter?.displayProp,
                filter?.jsonValue,
              );
              this.loaded = true;
            });
        }
      }
      if (hasNoEndpoints) this.loaded = true;
    }
  }

  ngOnChanges() {
    if (this.diagnosesData?.length > 0) {
      this.selectedRowItem = this.diagnosesData;
    }
  }

  populateDropdown(resp: any, labelProp: string, valueProp: string) {
    const options = [];

    resp?.results?.forEach(function (record) {
      if (valueProp) {
        options.push({
          label: record[labelProp],
          value: record[valueProp],
        });
      } else {
        options.push({
          label: record[labelProp],
          value: record,
        });
      }
    });

    return options;
  }

  toggleFlyout() {
    this.showModal = !this.showModal;
    this.selectedItem = null;
  }

  openDiagnosisFlyout() {
    this.showModal = !this.showModal;
  }

  remove() {
    this.group?.get(this.config?.prop)?.reset();
  }

  removeDiagnosisAtPropAndIndex(prop, index) {
    if (this.selectedRowItem && Array.isArray(this.selectedRowItem)) {
      this.selectedRowItem.forEach((value, rowIndex) => {
        if (value.row?.id === prop.id) this.selectedRowItem.splice(rowIndex, 1);
      });
    }
    this.diagnosisDetails.emit(this.selectedRowItem);
  }

  selectedRadioRow(row: any) {
    if (Array.isArray(this.selectedRowItem)) {
      this.selectedRowItem.map((rowItem: any) => {
        if (rowItem.row) {
          rowItem.row.is_primary = false;
        }
      });
    }
    row['is_primary'] = true;
    this.selectedItem = row;
  }

  handleSelectedRows(event: any) {
    this.selectedRowItem = event;
    this.diagnosisDetails?.emit(this.selectedRowItem);
  }

  checkTableFormat(): boolean {
    let isTableHasRadio = false;

    if (this.config) {
      for (const obj of this.config?.flyout?.table?.columns) {
        if (obj.format === 'RADIO') {
          isTableHasRadio = true;
          break;
        } else {
          isTableHasRadio = false;
        }
      }
    }

    return isTableHasRadio;
  }

  add() {
    if (this.checkTableFormat()) {
      this.group?.get(this.config?.prop).setValue(this.selectedItem);
    } else {
      this.group?.get(this.config?.prop).setValue(this.selectedRowItem);
    }
    this.toggleFlyout();
  }

  isDisabled(): boolean {
    const isRadioRowSelected =
      this.checkTableFormat() &&
      this.selectedItem &&
      this.selectedRowItem?.length > 0;
    const isRowSelected =
      !this.checkTableFormat() &&
      !this.selectedItem &&
      this.selectedRowItem?.length > 0;
    const isRowHasIsPrimary = this.selectedRowItem
      ?.map((itemRow: RowDiagnosis) => itemRow?.row?.is_primary)
      .find((val: boolean) => (val ? true : false));

    if (isRadioRowSelected) {
      return false;
    } else if (isRowHasIsPrimary) {
      return false;
    } else if (isRowSelected) {
      return false;
    } else return true;
  }
}
