/* eslint-disable @nx/enforce-module-boundaries */
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  DocumentCenterEventService,
  DocumentEvent,
  DocumentEventType,
} from '@zipari/feat-document-center';
import { Subscription } from 'rxjs';
import { AnalyticsService } from '../../../shared/services/analytics.service';

@Component({
  selector: 'document-center-wrapper',
  templateUrl: './document-center-wrapper.component.html',
  styleUrls: ['./document-center-wrapper.component.css'],
})
export class DocumentCenterWrapperComponent implements OnInit, OnDestroy {
  eventSubscription!: Subscription;

  constructor(
    private documentCenterEventService: DocumentCenterEventService,
    public analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.analyticsService.sendEvent(`pp_document-center-list_viewed`);
    this.eventSubscription = this.documentCenterEventService
      .getEventType()
      .subscribe((event: DocumentEvent) => {
        if (event) {
          this.captureEvents(event);
        }
      });
  }

  captureEvents(event: DocumentEvent): void {
    switch (event.type) {
      case DocumentEventType.documentTabSelected: {
        if (event.activeTab === 'policy-guidelines') {
          this.analyticsService.sendEvent(
            `pp_document-policies-and-guidelines_viewed`,
          );
        } else {
          this.analyticsService.sendEvent(
            `pp_document-${event.activeTab}_viewed`,
          );
        }
        break;
      }
      case DocumentEventType.documentViewed: {
        this.sendDocumentViewedEvent(event);
        break;
      }
      case DocumentEventType.filtersApplied: {
        this.analyticsService.sendEvent(`pp_document_searched`);
        break;
      }
    }
  }

  sendDocumentViewedEvent(event: DocumentEvent): void {
    const documentDetails = event.documentDetails;

    if (documentDetails) {
      const dictionaryAttribute = {
        document_category: documentDetails.category,
        document_name: documentDetails.name,
        file_type: documentDetails.type,
      };

      this.analyticsService.sendEvent(
        'pp_document_viewed',
        dictionaryAttribute,
      );
    } else {
      this.analyticsService.sendEvent('pp_document-resources_viewed');
    }
  }

  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }
}
