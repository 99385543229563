import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { concatMap, filter, map, takeUntil, tap } from 'rxjs/operators';
import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { ConfigService } from '../../../../../shared/services';
import { GenericServices } from '../../../../shared/services/generic.service';
import SiteAttestationDataService from '../site-location-attestation-data.service';
import SiteLocationAttestationEventService from '../site-location-attestation.event.service';
import {
  AttestedSiteLocationDetail,
  SiteLocation,
  SiteLocationAttestationConfig,
  SiteLocationAttestationParams,
  UserInformation,
} from '../site-locations-attestation.model';
import { BUTTON_TYPE_NAME } from '../site-location-attestation.constant';
import { getCurrentDate } from '../../../../../shared/utilities/dates';
import { ReviewPractitionersService } from '../services/review-practitioner.service';
@Component({
  selector: 'attestation-wrapper',
  templateUrl: './attestation-wrapper.component.html',
  styleUrls: ['./attestation-wrapper.component.scss'],
})
export class AttestationWrapperComponent implements OnInit, OnDestroy {
  public config: {
    approveButton: ButtonConfig;
    cancelButton: ButtonConfig;
  };
  public buttonConfig: ButtonConfig;
  pageName: string;
  private destroy$ = new Subject();

  constructor(
    public genericServices: GenericServices,
    private configService: ConfigService,
    private siteLocationAttestationEventService: SiteLocationAttestationEventService,
    private siteAttestationDataService: SiteAttestationDataService,
    public router: Router,
    public reviewPractitionersService: ReviewPractitionersService,
  ) {}

  ngOnInit(): void {
    this.genericServices.showFooter$.next(false);
    const siteLocationAttestationConfig: SiteLocationAttestationConfig =
      this.configService.getPageConfig('site-locations-attestation');

    if (siteLocationAttestationConfig) {
      this.config = siteLocationAttestationConfig.buttons;
    }

    this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) {
        this.pageName = event?.snapshot?.data?.pageName;
      }
    });

    this.submitSiteAttestationListener();
    this.submitPractitionerListener();
  }

  public submitSiteAttestationListener() {
    let shouldProcessToAttestation = false;

    this.siteLocationAttestationEventService.dynamicButtonClickEvent$
      .pipe(
        takeUntil(this.destroy$),
        tap((dynamicButtonType) => {
          shouldProcessToAttestation =
            dynamicButtonType === BUTTON_TYPE_NAME.APPROVAL;
        }),
        filter(
          (dynamicButtonType) =>
            dynamicButtonType === BUTTON_TYPE_NAME.APPROVAL,
        ),
        concatMap(
          (buttonType) =>
            buttonType &&
            this.siteLocationAttestationEventService.selectedSiteLocations$.pipe(
              takeUntil(this.destroy$),
              map((locations: SiteLocation[]) => this.loadLocations(locations)),
            ),
        ),
        concatMap(
          (siteLocationAttestationParams: SiteLocationAttestationParams[]) =>
            this.siteAttestationDataService
              .submitSiteLocationAttestation(siteLocationAttestationParams)
              .pipe(
                takeUntil(this.destroy$),
                tap(
                  (attestationConfirmedResponse: {
                    locations: AttestedSiteLocationDetail;
                    user: UserInformation;
                  }) => {
                    shouldProcessToAttestation &&
                      this.navigateToConfirmation(attestationConfirmedResponse);
                  },
                ),
              ),
        ),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.genericServices.showFooter$.next(true);
    this.destroy$.next(true);
  }

  navigateToPreviousPage(): void {
    history.back();
  }

  public loadLocations(
    locations: SiteLocation[],
  ): SiteLocationAttestationParams[] {
    const siteLocationAttestationParams: SiteLocationAttestationParams[] = [];

    locations.forEach((location: SiteLocation) => {
      siteLocationAttestationParams.push({
        facility_location_id: location.id,
        id: location.id,
        last_attestation_date: getCurrentDate(),
      });
    });

    return siteLocationAttestationParams;
  }

  public submitPractitionerListener(): void {
    this.siteLocationAttestationEventService.dynamicButtonClickEvent$
      .pipe(
        takeUntil(this.destroy$),
        filter(
          (dynamicButtonType) => dynamicButtonType === BUTTON_TYPE_NAME.SUBMIT,
        ),
        concatMap((_) =>
          this.siteAttestationDataService.getSiteLocationPractitioners(),
        ),
      )
      .subscribe((submitPractitionerEventDetails) => {
        this.reviewPractitionersService.captureSubmitPractitionerEvent(
          submitPractitionerEventDetails,
        );
      });
  }

  private navigateToConfirmation(attestationConfirmedResponse: {
    locations: AttestedSiteLocationDetail;
    user: UserInformation;
  }): void {
    this.router.navigate(
      ['/provider-portal/provider-admin/attestation/confirmation'],
      {
        state: attestationConfirmedResponse,
      },
    );
  }
}
