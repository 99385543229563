<article class="layout--detail" [zipBusy]="busy">
  <article class="layout--detail__content" *ngIf="!!dataLoaded">
    <aside class="layout--detail__aside">
      <ng-container *ngFor="let section of config.sidebar?.sections">
        <div [ngSwitch]="section.format">
          <header class="layout--detail__aside__header" *ngIf="section.label">
            <h6 role="heading" aria-level="1">{{ section.label }}</h6>
          </header>

          <section
            class="patient-detail__aside__attrs"
            *ngSwitchCase="'attribute-list'"
          >
            <div
              class="patient-detail__aside__model-attributes"
              *ngFor="let attribute of section.attributes"
            >
              <model-attribute
                [context]="context"
                [config]="attribute"
                direction="column"
              ></model-attribute>
            </div>
          </section>
        </div>
      </ng-container>
    </aside>

    <main class="layout--detail__main">
      <header class="layout--detail__main__breadcrumb">
        <zip-button
          [config]="config['backButton']"
          (zipButtonClicked)="routeBack()"
        ></zip-button>
      </header>
      <!-- ERHO: TODO ==> Fix this later to be from config -->
      <section
        class="patient-detail_patient-name layout--list__header"
        *ngIf="context"
      >
        <h3 class="layout--detail__main--header">
          {{ context.name }}

          <p
            class="layout-detail__header--pill"
            [ngClass]="{
              active: groupStatus === 'active',
              inactive: groupStatus === 'Suspended',
            }"
          >
            {{ groupStatus | titlecase }}
          </p>
        </h3>

        <div class="patient-detail__action-btn" *ngIf="actionBtn">
          <zip-button
            [config]="actionBtn"
            (zipButtonClicked)="navigateSubNav($event)"
          ></zip-button>
        </div>
      </section>

      <section class="layout--detail__main__section">
        <tabs
          (tabSelected)="handleTabSelected($event)"
          [selectedIndex]="selectedTab"
          [tabTemplates]="[userDetails]"
        >
        </tabs>
      </section>
    </main>
  </article>
</article>

<ng-template #userDetails>
  <div *ngIf="dataLoaded" class="tab-body">
    <div
      *ngFor="
        let section of (config?.tabs?.tabConfigs)[0]?.sections;
        let i = index
      "
    >
      <header
        *ngIf="section.header"
        class="tab-view__header"
        [ngClass]="setHeaderCSS(section.headerEditIcon)"
        (click)="
          openEditModalIfNeeded($event, section.headerEditIcon, section, i)
        "
      >
        <div *ngIf="section.headerIcon">
          <i
            class="header-icon-position"
            [ngStyle]="section.headerIcon.styles"
            [ngClass]="section.headerIcon.classString"
            >{{ section.headerIcon.name }}
          </i>
        </div>
        <h6 class="tab-view__header__text t-bold">
          {{ section.header }}
        </h6>
        <div
          *ngIf="section.headerEditIcon && !section.headerEditIcon.disabled"
          (click)="openEditModal(section, i)"
        >
          <i
            class="header-edit-icon-position icon icon--medium"
            [ngStyle]="section.headerEditIcon.styles"
            >edit
          </i>
        </div>
      </header>
      <div class="tab-view__three-column">
        <div class="three-column__attrs" *ngFor="let item of section.fields">
          <model-attribute
            [context]="context"
            [config]="item"
          ></model-attribute>
        </div>
      </div>
      <br />
      <br />
    </div>
  </div>
</ng-template>

<modal
  #editFlyout
  *ngIf="editModal"
  [config]="(config?.tabs?.tabConfigs)[currentTab]?.editFlyout"
  [condition]="editModal"
  (cancel)="toggleEdit()"
>
  <div body>
    <div
      *ngIf="(config?.tabs?.tabConfigs)[currentTab]?.editFlyout?.subheader"
      class="header-associate"
    >
      <p>
        {{ (config?.tabs?.tabConfigs)[currentTab]?.editFlyout?.subheader }}
      </p>
    </div>
    <div
      class="form-group"
      *ngFor="
        let group of (config?.tabs?.tabConfigs)[currentTab]?.editFlyout
          .formFields;
        let idx = index
      "
    >
      <div *ngIf="group.controls">
        <form-group
          [configs]="group.controls"
          [form]="editFields"
          [context]="context"
        >
        </form-group>
      </div>
    </div>
  </div>
  <div footer>
    <zip-button
      [disabled]="isDisabled() || !this.editFields.valid"
      level="high"
      [content]="
        (config?.tabs?.tabConfigs)[currentTab]?.editFlyout.submitButton
      "
      (click)="submitModal($event)"
    ></zip-button>
  </div>
</modal>

<modal
  #confirmModal
  *ngIf="(showActivateGroupModal || showSuspendGroupModal) && activeModal"
  [config]="activeModal"
  [condition]="showActivateGroupModal || showSuspendGroupModal"
  (cancel)="cancelModal()"
>
  <div body>
    <div [innerHtml]="activeModal?.message"></div>
  </div>
  <div footer class="modal-footer footer-container">
    <zip-button
      class="footer-container__cancel"
      [config]="activeModal?.footer?.cancel"
      (zipButtonClicked)="cancelModal()"
    ></zip-button>
    <zip-button
      class="footer-container__confirm"
      [config]="activeModal?.footer?.confirm"
      (zipButtonClicked)="confirmButtonClicked()"
    ></zip-button>
  </div>
</modal>
<modal
  #errorFlyout
  *ngIf="error"
  [config]="config?.errorModal"
  [condition]="error"
  (cancel)="toggleError()"
>
  <div body id="errorMessage">
    {{ editError === '' ? config?.errorModal.message : editError }}
  </div>
</modal>
