import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../../../../shared/services';
import { GenericServices } from '../../../../shared/services/generic.service';
import {
  CheckListItem,
  SuspendUserConfig,
} from '../../manage-portal-users-details.model';
import {
  SuspendUserConstants,
  UserSuspensionType,
} from '../../manage-portal-users-details.constant';
import SuspendUserEventService from '../../services/suspend-user-event.service';
import SuspendUserService from '../../services/suspend-user.service';
import { SuspendUserDataService } from '../../services/suspend-user-data.service';
@Component({
  selector: 'suspend-user',
  templateUrl: './suspend-user.component.html',
  styleUrls: ['./suspend-user.component.scss'],
})
export class SuspendUserComponent implements OnInit, OnDestroy {
  config: SuspendUserConfig;
  state: string;
  activeStepIndex = 0;
  idpId: string;
  showErrorModal = false;
  errorMessage: string;
  suspendUserSubscription: Subscription;

  constructor(
    private configService: ConfigService,
    public genericServices: GenericServices,
    private suspendUserEventService: SuspendUserEventService,
    private location: Location,
    public suspendUserService: SuspendUserService,
    public suspendUserDataService: SuspendUserDataService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.config = this.configService.getPageConfig(
      SuspendUserConstants.managePortalUserDetail,
    )?.[SuspendUserConstants.suspendUser];
    this.genericServices.showFooter$.next(false);
    this.idpId = this.route.snapshot?.params?.idp_id;
    this.setInitialStep();
  }

  setInitialStep(): void {
    let initialStepProp;

    if (
      this.suspendUserService.suspensionType === UserSuspensionType.PARTIALLY
    ) {
      const suspendSteps = this.config?.suspendUserSteps?.attributes;

      initialStepProp = suspendSteps[0]?.prop;
    } else {
      this.suspendUserSubscription =
        this.suspendUserDataService.suspendUser$.subscribe((suspend) => {
          if (suspend) {
            this.suspendUserService.selectedGroups =
              this.suspendUserService.groups;
            this.suspendUserService.selectedTaxIds =
              this.suspendUserService.taxIds;
            this.suspendUser();
          }
        });
    }
    if (initialStepProp) {
      this.state = initialStepProp;
      this.suspendUserEventService.emitDynamicButtonConfig(
        this.config[this.state].nextButton,
      );
    }
  }

  handleNextStep(): void {
    const suspendSteps = this.config?.suspendUserSteps?.attributes;

    if (this.activeStepIndex < suspendSteps.length - 1) {
      if (this.state === SuspendUserConstants.review) {
        this.suspendUser();
      } else {
        this.moveToNextStep();
      }
    } else {
      this.navigateToPreviousPage();
    }
  }

  moveToNextStep(): void {
    const suspendSteps = this.config?.suspendUserSteps?.attributes;
    const currentStepIndex = suspendSteps.findIndex(
      (step) => step.prop === this.state,
    );

    if (
      this.suspendUserService.suspensionType === UserSuspensionType.ENTIRELY
    ) {
      this.activeStepIndex = suspendSteps.length - 1;
    } else {
      this.activeStepIndex = currentStepIndex ? currentStepIndex + 1 : 1;
    }
    const stepProp = suspendSteps[this.activeStepIndex]?.prop;

    if (stepProp) this.state = stepProp;
  }

  suspendUser(): void {
    const groups = this.suspendUserService.selectedGroups?.map((group) => ({
      id: group.value?.['id'],
    }));
    const tax_ids = this.suspendUserService.selectedTaxIds?.map((group) => ({
      id: group.value?.['externalId'],
    }));
    const userDetails = { groups, tax_ids };
    const endpoint = this.config.endpoint?.replace('${idp_id}', this.idpId);

    this.suspendUserDataService
      .suspendUser(endpoint, userDetails)
      .subscribe(this.moveToNextStep.bind(this), (error) => {
        this.displayModalAndErrorMessage(error.error?.errors[0]?.user_error);
      });
  }

  displayModalAndErrorMessage(errorMessage: string): void {
    this.showErrorModal = true;
    this.errorMessage = errorMessage;
  }

  errorModalSubmitClicked(): void {
    this.showErrorModal = false;
  }

  navigateToPreviousPage(): void {
    this.location.back();
  }

  editStep(stepKey: string): void {
    this.state = stepKey;
  }

  handleSelectedGroups(groups: CheckListItem[]): void {
    this.suspendUserService.selectedGroups = groups;
  }

  handleSelectedTaxIds(taxIds: CheckListItem[]): void {
    this.suspendUserService.selectedTaxIds = taxIds;
  }

  ngOnDestroy(): void {
    this.suspendUserSubscription?.unsubscribe();
    this.suspendUserService.selectedGroups = [];
    this.suspendUserService.selectedTaxIds = [];
  }
}
