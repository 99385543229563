import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ButtonModule } from '../../notes/button/button.module';
import { ExpandingArticleModule } from '../../notes/expanding-article/expanding-article.module';
import { IconModule } from '../../notes/icon/icon.module';
import { SpinnerModule } from '../../notes/spinner/spinner.module';
import { StatusModule } from '../../notes/status/status.module';
import { ZipBusyModule } from '../../shared/modules/zip-busy/zip-busy.module';
// Components
import { GenericCellComponent } from './components/cells/generic/generic-cell.component';
import { HeaderCellComponent } from './components/cells/header/header-cell.component';
import { SummaryCellComponent } from './components/cells/summary/summary-cell.component';
import { TableFilterModule } from './components/filters/table-filter.module';
import { FooterComponent } from './components/footer/footer.component';
import { TableHeaderActionComponent } from './components/header-actions/table-header-action.component';
import { TablePromptComponent } from './components/prompts/table-prompt.component';

import { GenericRowComponent } from './components/rows/generic-row/generic-row.component';
import { GroupHeaderRowComponent } from './components/rows/group-header-row/group-header-row.component';
import { HeaderRowComponent } from './components/rows/header-row/header-row.component';
import { SummaryRowComponent } from './components/rows/summary-row/summary-row.component';
import { TableComponent } from './components/table.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ButtonModule,
    IconModule,
    StatusModule,
    TableFilterModule,
    SpinnerModule,
    ZipBusyModule,
    ExpandingArticleModule,
  ],
  exports: [TableComponent],
  declarations: [
    TableComponent,
    FooterComponent,
    TableHeaderActionComponent,
    GenericRowComponent,
    HeaderRowComponent,
    GenericCellComponent,
    HeaderCellComponent,
    SummaryCellComponent,
    GroupHeaderRowComponent,
    SummaryRowComponent,
    TablePromptComponent,
  ],
})
export class TableModule {}
