import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ZipTable2 } from '../../../models/zip-table.model';
import { ZipTable2Service } from '../../../services/zip-table2.service';

@Component({
  selector: 'zip-table-generic-row-2',
  templateUrl: './generic-row.component.html',
})
export class GenericRowComponent implements OnInit {
  @Input() columns;
  @Input() data: any;
  @Input() options: ZipTable2;
  @Input() detailView;
  @Input() grouping;
  @Output() cellClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowClicked: EventEmitter<any> = new EventEmitter<any>();

  selected;

  constructor(public ztService: ZipTable2Service) {}

  ngOnInit() {
    this.ztService.selectedRowSubject.subscribe((selection) => {
      this.selected =
        this.ztService.zipTableOptions.selection.enabled &&
        this.ztService.checkIfRowSelected(this.data);
    });
  }

  handleClick() {
    if (
      this.ztService.zipTableOptions.selection.enabled &&
      !this.ztService.zipTableOptions.selection.multiple
    ) {
      this.rowClicked.emit();
    }
  }

  markRowAsSelected(event) {
    if (event.target.checked) {
      this.ztService.selectedRow.push({ row: this.data });
      this.cellClicked.emit(this.data);
    } else {
      const ind = this.ztService.findSelectedRowIndex(this.data);

      this.ztService.selectedRow.splice(ind, 1);
    }

    this.ztService.selectedRowSubject.next(null);
  }
}
