<form [formGroup]="providerUpdateToolService.getFormGroup(key)">
  <div [formArrayName]="config.prop">
    <div
      *ngFor="let item of formArray.controls; let index = index"
      [formGroupName]="index"
    >
      <div class="form-container">
        <div class="nestedForm" [ngClass]="{ editNestedForm: isEditState }">
          <form-group
            (formCreated)="formCreated()"
            [form]="item"
            [configs]="stateDeaFormArrayGroup[index]['controls']"
            [context]="contextData[index]"
          >
          </form-group>
        </div>

        <div class="actionIcons">
          <span
            *ngIf="1 === getMaxSize()"
            class="material-icons mat-spaced-icon isHidden"
          >
            remove_circle_outline
          </span>
          <span
            *ngIf="getMaxSize() > 1"
            (click)="deleteArrayItem(index)"
            class="material-icons mat-spaced-icon"
          >
            remove_circle_outline
          </span>
          <span (click)="addArrayItem()" class="material-icons mat-spaced-icon">
            add_circle_outline
          </span>
        </div>
      </div>
    </div>
  </div>
</form>

<modal
  *ngIf="showMsg && stateDeaInfoModal"
  [config]="stateDeaInfoModal.config"
  [condition]="showMsg"
  (cancel)="onCloseClick()"
>
  <div body>{{ stateDeaInfoModal.config.description }}</div>
</modal>
