import { Injectable } from '@angular/core';
import {
  ClinicalReveiwIndicationContext,
  ClinicalReviewStatus,
} from '../../../templates/transfer-of-care/transfer-of-care-detail.model';

@Injectable({
  providedIn: 'root',
})
export class MCGDataParserService {
  tempClinicalReviewTabData: ClinicalReveiwIndicationContext[] = [];

  parseMCGData(
    clinicalReveiwIndication: ClinicalReveiwIndicationContext[],
  ): ClinicalReveiwIndicationContext[] {
    if (clinicalReveiwIndication?.length === 0) return [];

    const updatedClinicalReviewTabData = clinicalReveiwIndication.map(
      (indicationItem: ClinicalReveiwIndicationContext) =>
        this.updateParentIndicationSelectedStatus(indicationItem),
    );

    return this.filterClinicalReview(updatedClinicalReviewTabData);
  }

  updateParentIndicationSelectedStatus(
    indicationItem: ClinicalReveiwIndicationContext,
  ): ClinicalReveiwIndicationContext {
    let updatedParentIndicationItem: ClinicalReveiwIndicationContext =
      indicationItem;

    if (indicationItem.indication) {
      const isAnyNestedIndicationSelected = indicationItem.indication.some(
        (indication) =>
          indication.selected.toLowerCase() === ClinicalReviewStatus.selected,
      );

      indicationItem.selected = `${isAnyNestedIndicationSelected}`;

      const updatedIndication = indicationItem.indication.map(
        (subItem: ClinicalReveiwIndicationContext) =>
          this.updateParentIndicationSelectedStatus(subItem),
      );

      updatedParentIndicationItem = {
        ...indicationItem,
        indication: updatedIndication,
      };
    }

    return updatedParentIndicationItem;
  }

  filterClinicalReview(
    clinicalReveiwIndication: ClinicalReveiwIndicationContext[],
  ) {
    if (clinicalReveiwIndication?.length > 0) {
      this.tempClinicalReviewTabData = clinicalReveiwIndication
        .filter(
          (indicationItem: ClinicalReveiwIndicationContext) =>
            indicationItem.selected.toLowerCase() ===
            ClinicalReviewStatus.selected,
        )
        .map((indicationItem: ClinicalReveiwIndicationContext) =>
          this.filterClinicalReveiwIndication(indicationItem),
        );
    } else {
      this.tempClinicalReviewTabData = [];
    }

    return this.tempClinicalReviewTabData;
  }

  filterClinicalReveiwIndication(
    indicationItem: ClinicalReveiwIndicationContext,
  ) {
    if (indicationItem.indication) {
      const filteredIndications = indicationItem.indication
        .filter(
          (nestedIndication: ClinicalReveiwIndicationContext) =>
            nestedIndication.selected.toLowerCase() ===
            ClinicalReviewStatus.selected,
        )
        .map((nestedIndication: ClinicalReveiwIndicationContext) =>
          this.filterClinicalReveiwIndication(nestedIndication),
        );

      return {
        ...indicationItem,
        indication: filteredIndications,
      };
    } else {
      return { content: indicationItem?.content };
    }
  }
}
