import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  AbstractControl,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormControlService } from '@zipari/design-system';

import * as _ from 'lodash';
import { OutOfNetworkConfig } from '../../../authorization-submission/authorization-submission.model';

@Component({
  selector: 'service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.scss'],
})
export class ServiceDetailsComponent implements OnInit {
  @Input() dynamicGroupArray;
  @Input() config: any = {};
  @Input() group: UntypedFormGroup;
  @Input() state: string;
  @Input() configNoSpace: string;
  @Input() controls: Map<any, any>;
  @Input() outOfNetwork: OutOfNetworkConfig;
  @Output() updatedState = new EventEmitter<string>();
  @Output() switchTriggered = new EventEmitter<any>();
  @Input() index;
  id: any;
  busy: any[] = [];
  noSpace = 'n/a';
  _: any = _;

  constructor(
    private formControlService: FormControlService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    if (this.configNoSpace) this.noSpace = this.configNoSpace;
    this.id = this.route.snapshot.params.id;
  }

  addControl(config) {
    const dynamicControlGroup = new UntypedFormGroup({});

    for (const control in config) {
      if (config[control].dyanmicControl) {
        for (const dyanControls of config[control].dyanmicControl) {
          const new_control: AbstractControl = new UntypedFormControl('', []);
          const newFormControl = Object.assign(dyanControls, {
            control: new_control,
          });

          this.formControlService.addControlToFormGroup(
            dynamicControlGroup,
            newFormControl,
          );
        }
      }
    }
    this.dynamicGroupArray.push(dynamicControlGroup);
  }

  deleteArrayItem(index) {
    if (this.dynamicGroupArray.length > 1) {
      this.dynamicGroupArray.splice(index, 1);
    }
  }

  setMemberId(target: string): string {
    return target.replace('${member_number}', this.id);
  }

  getDropdownDisplayValue(prop: string, group): string {
    const options: any[] = this.controls?.get(prop)?.options;
    const option = options?.find(
      (item: any) => item.value === group.get(prop).value,
    );

    if (option) return option.label;

    return this.noSpace;
  }

  getDefaultReviewBox(control: any, group: any) {
    if (control.type === 'date') {
      if (group.get(control.prop) && group.get(control.prop).value) {
        const value: any = group.get(control.prop).value;
        const dateObj = new Date(`${value}T00:00:00`);

        return new Intl.DateTimeFormat('en-US').format(dateObj);
      } else {
        return this.noSpace;
      }
    }

    return group.get(control.prop)
      ? group.get(control.prop).value
        ? group.get(control.prop).value
        : this.noSpace
      : this.noSpace;
  }

  back(event: any): void {
    if (this.state === 'review') {
      this.state = 'edit';
      this.updatedState.emit(this.state);
    }
  }

  scrollTo(event: any): void {
    this.back(null);
    setTimeout(() => {
      const itemToScrollTo = document.getElementById(event);
      // null check to ensure that the element actually exists

      if (itemToScrollTo) {
        itemToScrollTo.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  showOutOfNetworkStatusPill(controlKey: string): boolean {
    const networkStatusProp = this.config['controls'][controlKey].prop;
    const isInNetworkValue =
      this.group.get(networkStatusProp)?.value?.member_network?.is_in_network;

    return (
      this.outOfNetwork?.isOutOfNetworkEnabled && isInNetworkValue === false
    );
  }
}
