import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { TableConfig } from '@zipari/design-system';
import {
  HeaderIconConfig,
  ProgressBarConfig,
  UniqueSectionKeyValueConfig,
} from '../../../shared/models/TabsConfig.model';
import { ObjectStatuses } from '@zipari/shared-ds-util-status';

export class PatientDetailConfig {
  conditionals: any;
  endpoints: any; //  Talk to Eric How to handle it
  actionButton: ButtonConfig;
  backButton: any;
  tabs: PatientDetailTabConfig[];
  mainContainer: MainContainerConfig;
  tabConfigs: any;
  sidePanel: any;
  uniqueIdentifier: string;
  statuses?: ObjectStatuses;
}

export class PatientDetailTabConfig {
  label: string;
  priority: number;
  prop: string;
  targetConfigKey: string;
  url: string;
  cx_event_key: string;
}

export interface BenefitsTabConfig {
  table?: TableConfig;
  tableDataKey?: string;
  tableTitle?: string;
  endpoint?: string;
  description: string;
}

export interface DataTableTabConfig {
  targetRouteObj: any;
  table?: TableConfig;
  tableDataKey?: string;
  tableTitle?: string;
  endpoint?: string;
  description: string;
  cx_event_key?: string;
}

export interface IdCardsTabConfig {
  endpoint: string;
  documentApiEndpoint: string;
  filters: any;
  idCards: any;
  description: string;
}

export interface AccumulatorTabConfig {
  description: string;
  filters: any;
  sections: SectionConfig[];
}

export class SectionConfig {
  format: string;
  header?: string;
  headerIcon?: HeaderIconConfig;
  progressBars?: ProgressBarConfig[];
  progressBarData?: string;
  targetData: string;
  uniqueSectionKeyValue: UniqueSectionKeyValueConfig;
}

export class AttributeConfig {
  label: string;
  value: string;
  dataAsLabel: boolean;
  type?: string;
  additionalAttrs: any;
  showIfTrue: boolean;
}

export class MainContainerConfig {
  errorHandling: unknown;
}

export const PATIENT_DETAIL_URLS = {
  BENEFITS: 'benefits',
  ACCUMULATORS: 'accumulators',
  CLAIMS: 'claims',
  AUTHORIZATIONS: 'authorizations',
  PREGNANCY_FORM: 'pregnancy-form',
  REFERRALS: 'referrals',
  ELIGIBLILITY_HISTORY: 'eligibility-history',
  IDCARDS: 'id-cards',
};
