export const deaNumberProp = 'dea_number';
export const providerNumbersProp = 'provider_numbers';

export const AdditionalDetailsFormControlProps = {
  effectiveDate: 'effective_date',
  notes: 'notes',
};

export const PractitionerDetailsContextKeys = {
  changeRequestNotes: 'change_request_notes',
  changeRequestEffectiveDate: 'change_request_effective_date',
};
