import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { cloneObject, stringBuilder } from '@zipari/web-utils';
import { WindowService, APIService } from '@zipari/web-services';

import { GenericServices } from '../../../provider-portal/shared/services/generic.service';
import { validCXEvents } from '../../constants/analytics';
import { AuthIdp } from '../authentication/authentication.constants';
import { AuthService, ConfigService } from '../../services';
import { NotificationsService } from '../../templates/notifications/notifications.service';
import { fade, slideRight } from '../../animations';
import { AnalyticsService } from '../../services/analytics.service';
import { BrokerAssistanceService } from '../../services/broker-assistance.service';
import { MessagesService } from '../../templates/messages/messages.service';
import { brokerAssistanceConfigsFromConstantFile } from './navigation.constants';
import { NavigationService } from './navigation.service';
import { NavigationConfiguration } from './navigation.model';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [fade, slideRight],
})
export class NavigationComponent implements OnInit {
  public navConfig: any;
  public isLoggedIn = false;
  public queryParams;
  public modalOpen;
  public contactConfigs;
  public brokerAssistanceConfigs;
  navHasLoaded = false;
  public unreadCount;
  public newNotifications;
  public showNotificationsIndicator = false;
  public data = {
    openMenuItem: '',
    target: '',
  };

  public routes = {
    zshop: 'shopping',
  };
  authConfig;

  @Output() query = new EventEmitter<string>();

  constructor(
    private api: APIService,
    public brokerAssistanceService: BrokerAssistanceService,
    public authService: AuthService,
    public navigationService: NavigationService,
    public router: Router,
    private notificationsService: NotificationsService,
    public genericServices: GenericServices,
    private route: ActivatedRoute,
    public analyticsService: AnalyticsService,
    public configService: ConfigService,
    private messagesService: MessagesService,
    private windowService: WindowService,
  ) {}

  newConfigCheck;
  brokerSub;
  brokerAssistanceInfoCheck;

  ngOnInit(): void {
    /*   when a user is logging in with the url they from their email like this
     *    http://dtc3-fhp.local.zipari.net:8000/shopping/individual/48776?fromEmail=true,
     *    configService.getPageConfigs tries to grab navigation configs on a parent level so it becomes undefined
     */
    const configs: NavigationConfiguration =
      this.configService.getPageConfig('navigation') ||
      this.configService.configs?.navigation;

    this.authConfig = this.configService.getPageConfig('auth');
    this.navConfig = configs?.navigationItems;
    // conditionally remove items from teh config if needed:
    this.preparseConfig(configs?.navigationItems);

    this.notificationsService.retrieveLatestNotifications();
    this.messagesService.unreadCount().subscribe();

    this.notificationsService.newNotifications.subscribe(
      (notifications: any) => {
        const unreadNotifications: any[] = [];

        notifications.forEach((notification: any) => {
          if (notification.is_read === false) {
            unreadNotifications.push('unread_notification');
          }
        });

        this.showNotificationsIndicator = unreadNotifications.length > 0;
      },
    );

    if (configs && configs.contact_us) {
      this.contactConfigs = cloneObject(configs.contact_us);

      if (
        configs &&
        configs.contact_us &&
        configs.contact_us.brokerAssistanceConfigs
      ) {
        this.brokerAssistanceInfoCheck = true;
      }
    }

    const brokerAssistanceConfigs: any = this.brokerAssistanceInfoCheck
      ? cloneObject(configs.contact_us.brokerAssistanceConfigs)
      : brokerAssistanceConfigsFromConstantFile;

    this.authService.appUserData().subscribe((response: any) => {
      if (response?.accepted_terms || !(this.authConfig?.idp === AuthIdp)) {
        this.isLoggedIn = !!response;
      }
    });
    this.queryParams = this.route.snapshot.queryParams;

    this.newConfigCheck = this.router.events.subscribe((event: any) => {
      if (
        event instanceof NavigationEnd &&
        this.configService.marketSegmentIsValidInApp(this.route.snapshot)
      ) {
        this.navigationService.initConfig(this.navConfig);
      }
    });

    this.brokerSub = this.brokerAssistanceService.assistingBroker$.subscribe(
      (val: any) => {
        if (val) {
          this.brokerAssistanceConfigs = brokerAssistanceConfigs.map(
            (config: any) => {
              const newConfig = { ...config };

              newConfig.value = stringBuilder(newConfig.value, val);

              return newConfig;
            },
          );
        }
      },
    );
  }

  public get isNavHidden(): boolean {
    return this.navigationService.getNavHidden();
  }

  removeFromConfig(links: any[]) {
    for (let i = links.length - 1; i >= 0; i--) {
      if (links[i]?.checkParNonPar) {
        links.splice(links.indexOf(links[i]), 1);
      }
      if (links[i]?.items) {
        for (let j = links[i].items.length - 1; j >= 0; j--) {
          // for (let sublink of links[i].items) {
          if (links[i].items[j].checkParNonPar) {
            links[i].items.splice(links[i].items.indexOf(links[i].items[j]), 1);
          }
        }
      }
    }
  }

  preparseConfig(config: any): void {
    this.authService.appUserData().subscribe((response: any) => {
      if (!response) {
        this.loadNavigation();

        return;
      }
      let memberIdToUse = '';

      for (const link of config.links) {
        // check if menu items or submenu items have par/nonpar flag
        if (link.checkParNonPar) {
          memberIdToUse = this.getObjectProperty(response, link.checkParNonPar);
        }
        if (link.items) {
          for (const item of link.items) {
            if (item.checkParNonPar) {
              memberIdToUse = this.getObjectProperty(
                response,
                item.checkParNonPar,
              );
            }
          }
        }
      }
      // if par/nonpar flag exists, make the call and remove the object dependingly
      // on any error, enable the nav, and remove the objects;
      if (memberIdToUse && this.navConfig.parNonParCheckEndpoint) {
        let url = this.navConfig.parNonParCheckEndpoint;

        url = url.replace('${id}', memberIdToUse);
        this.genericServices.subscriptions.push(
          this.api.get(url).subscribe(
            (resp: any) => {
              if (!resp.is_participating) this.removeFromConfig(config.links);
              this.loadNavigation();
            },
            (error: any) => {
              this.loadNavigation();
              this.removeFromConfig(config.links);
            },
          ),
        );
      } else if (typeof memberIdToUse !== 'string') {
        this.removeFromConfig(config.links);
        this.loadNavigation();
      } else {
        this.loadNavigation();
      }
    });
  }

  loadNavigation() {
    this.navHasLoaded = true;
    this.navigationService.initConfig(this.navConfig);
  }

  getObjectProperty(obj, key) {
    return key.split('.').reduce(function (result, prop) {
      return result[prop];
    }, obj);
  }

  onNavButtonClick(): void {
    this.modalOpen = !this.modalOpen;

    // dispatch analytics
    if (this.modalOpen) {
      this.analyticsService.sendEvent(validCXEvents['contact-us_viewed']);
    }
  }

  search(query: string) {
    this.query.emit(query);
  }

  closeLink(link): void {
    link.open = false;
  }

  handleLogin(): void {
    const globalConfig: any = this.configService.getPageConfig('global');

    if (globalConfig.externalLogin) {
      this.windowService.nativeWindow.location.assign(
        globalConfig.externalLogin,
      );
    } else {
      this.router.navigate([this.configService.appRoute, 'login']);
    }
  }

  handleLogout(): void {
    const windowLocation = this.windowService.nativeWindow.location;

    windowLocation.assign(
      `/logout?next=${windowLocation.origin}/${this.configService.appRoute}/login`,
    );
  }

  handleRegister(): void {
    this.router.navigate([this.configService.appRoute, 'register']);
  }

  modalClose(): void {
    this.modalOpen = false;
  }

  logoClicked(): void {
    const route = [this.configService.appRoute];

    if (this.configService.activeRoute) {
      route.push(this.configService.activeRoute);
    }

    if (this.configService.activeRouteSegmentForLogo) {
      route.push(this.configService.activeRouteSegmentForLogo);
    }

    this.router.navigate(route);
  }
}
