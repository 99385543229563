import { NgModule } from '@angular/core';
import { IconModule } from '@zipari/design-system';
import { ErrorMessageComponent } from './error-message.component';

@NgModule({
  declarations: [ErrorMessageComponent],
  imports: [IconModule],
  exports: [ErrorMessageComponent],
})
export class ErrorMessageModule {}
