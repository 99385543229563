import { Component, Input, OnInit } from '@angular/core';
import { ErrorEmptyConfig } from '@zipari/shared-ds-util-form';
import { checkInputsForText } from '../../design-system.helper';

@Component({
  selector: 'error-empty',
  templateUrl: './error-empty.component.html',
  styleUrls: ['./error-empty.component.scss'],
})
export class ErrorEmptyComponent implements OnInit {
  @Input() config: ErrorEmptyConfig;

  ngOnInit(): void {
    const [config] = checkInputsForText([this.config]);

    this.config = config;
  }
}
