import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APIService } from '@zipari/web-services';

import { AnalyticsService } from '../../../shared/services/analytics.service';
import { CX_CALLS } from '../../../shared/constants/cx-calls.constant';
import { AuthService, ConfigService } from '../../../shared/services';
import { GenericServices } from '../../shared/services/generic.service';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  config;
  dashboardHasLoaded = false;

  constructor(
    private api: APIService,
    private configService: ConfigService,
    private router: Router,
    public genericServices: GenericServices,
    public authService: AuthService,
    public analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.config = this.configService.getPageConfig('dashboard');
    this.preparseConfig(this.config);
  }

  preparseConfig(config: any): any {
    this.authService.appUserData().subscribe((response: any) => {
      if (!response) {
        this.loadDashboard();

        return;
      }
      let memberIdToUse = '';

      if (config.tiles) {
        // eslint-disable-next-line prefer-const
        for (let tile of config.tiles) {
          // check if menu items or submenu items have par/nonpar flag
          if (tile?.checkParNonPar) {
            memberIdToUse = this.getObjectProperty(
              response,
              tile.checkParNonPar,
            );
          }
        }
      }
      // if par/nonpar flag exists, make the call and remove the object dependingly
      // on any error, enable the nav, and remove the objects;
      if (memberIdToUse && this.config.endpoints) {
        let url = this.config.endpoints.parNonParCheck;

        url = url.replace('${id}', memberIdToUse);
        this.genericServices.subscriptions.push(
          this.api.get(url).subscribe(
            (resp: any) => {
              if (!resp.is_participating) this.removeFromConfig(config.tiles);
              this.loadDashboard();
            },
            (error: any) => {
              this.loadDashboard();
              this.removeFromConfig(config.tiles);
            },
          ),
        );
      } else if (typeof memberIdToUse !== 'string') {
        this.removeFromConfig(config.tiles);
        this.loadDashboard();
      } else {
        this.loadDashboard();
      }
    });
  }

  removeFromConfig(tiles: any[]) {
    for (let i = tiles.length - 1; i >= 0; i--) {
      if (tiles[i]?.checkParNonPar) {
        tiles.splice(tiles.indexOf(tiles[i]), 1);
      }
    }
  }

  loadDashboard() {
    this.dashboardHasLoaded = true;
  }

  navigate(item): void {
    if (CX_CALLS[item.event_key]) {
      this.analyticsService.sendEvent(CX_CALLS[item.event_key].event_key);
    }

    if (item.isExternal) {
      window.open(item.link, '_blank');
    } else {
      this.router.navigate([item.link]);
    }
  }

  getObjectProperty(obj, key) {
    return key.split('.').reduce(function (result, prop) {
      return result[prop];
    }, obj);
  }
}
