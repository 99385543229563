<link [attr.href]="typeface" rel="stylesheet" />

<router-outlet>
  @if (isAppLoading) {
    <div class="loader__mask">
      <div class="loader"></div>
    </div>
  }
</router-outlet>

@if (globalConfig?.timeout?.timeoutWarningModal) {
  <timeout-modal> </timeout-modal>
}
