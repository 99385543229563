import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { Subscription } from 'rxjs';
import { APIService } from '@zipari/web-services';
import { ProviderUpdateToolService } from '../../../provider-update-tool/provider-update-tool.service';

@Component({
  selector: 'pregnancy-form-list',
  templateUrl: './pregnancy-form-list.component.html',
  styleUrls: ['./pregnancy-form-list.component.scss'],
})
export class PregnancyFormListComponent implements OnInit {
  @Input() config: any = {};
  busy: Subscription;
  results: any[] = [];
  columns: any[] = [];
  hasPdf: any = false;
  currentPage = 1;
  pageLength = 10;
  totalResultsCount = 0;
  error = false;
  noSpace = 'n/a';

  constructor(
    private fb: UntypedFormBuilder,
    private api: APIService,
    public mp: ProviderUpdateToolService,
  ) {}

  get paginatorConfig() {
    const resultsPerPage = this.pageLength;
    const startingPageNumber: number =
      this.currentPage * resultsPerPage - resultsPerPage + 1;
    const endingPageNumber: number =
      startingPageNumber <= this.totalResultsCount - resultsPerPage
        ? startingPageNumber + resultsPerPage - 1
        : this.totalResultsCount;
    const paginationConfig = {
      rowsPerPage: resultsPerPage,
      showingStart: startingPageNumber,
      showingEnd: endingPageNumber,
      currentPage: this.currentPage,
      totalPages: Math.ceil(this.totalResultsCount / resultsPerPage),
      totalResults: this.totalResultsCount,
    };

    return paginationConfig;
  }

  goToPage(page: any) {
    this.currentPage = page;
    this.updateResults(page);
  }

  ngOnInit() {
    this.config.columns?.forEach((item: any) => {
      if (item.type === 'pdf') {
        this.hasPdf = item;
      } else {
        this.columns.push(item);
      }
    });
    if (this.config?.noSpace) this.noSpace = this.config?.noSpace;
    if (this.config?.apiEndpoint) {
      if (this.config.pageSize) this.pageLength = this.config.pageSize;
      this.updateResults();
    }
  }
  updateResults(page = 1) {
    const url: string =
      this.config.apiEndpoint.slice(-1) === '/'
        ? `${this.config.apiEndpoint}?page=${page}&page_size=${this.pageLength}`
        : `${this.config.apiEndpoint}&page=${page}&page_size=${this.pageLength}`;

    this.busy = this.api.get(url).subscribe(
      (response: any) => {
        this.results = response.results;
        this.totalResultsCount = response['count'];
        if (!this.results?.length) this.error = true;
      },
      (err: any) => {
        this.error = true;
      },
    );
  }

  getPDFLink(prop: any, event: MouseEvent) {
    if (typeof prop === 'string') {
      // clicking on it should stop folding the expanded message, which is the parent of AttachmentPillComponent
      event?.stopPropagation();

      return window.open(`/api/documents/${prop}/`);
    }
  }

  getObjectProperty(obj, key) {
    return key.split('.').reduce(function (result, new_key) {
      return result[new_key];
    }, obj);
  }
}
