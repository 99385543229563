<div class="modal custom--modal modal--small" @fade *ngIf="!!toolbarMenuOpen">
  <header class="modal__header">
    <p class="t-data t-bold">{{ navigationService.config.toolbar.label }}</p>
  </header>

  <main class="modal__body">
    <div
      class="mobile-menu__option"
      [ngClass]="{ 'is-active': i === navigationService.activeRouteIdx }"
      *ngFor="
        let route of navigationService.config.toolbar.routes;
        let i = index
      "
      (click)="
        navigationService.navigateSelected(route, i); toolbarMenuOpen = false
      "
    >
      <i class="icon icon--medium">
        {{
          i === navigationService.activeRouteIdx
            ? route.icon + '_active'
            : route.icon
        }}
      </i>
      <p class="t-data">{{ route.label }}</p>

      <div class="u-fillRemaining u-flex u-justifyEnd">
        <i class="icon icon--medium icon--pointer">{{
          i === navigationService.activeRouteIdx ? 'radio_active' : 'radio'
        }}</i>
      </div>
    </div>
  </main>
</div>

<section
  class="modal__mask"
  @fade
  *ngIf="!!navigationService.mobileMenuOpen"
></section>

<menu
  class="mobile-menu"
  [ngClass]="{
    'mobile-menu--alt':
      !!navigationService.config.navAlt || !!navigationService.config.toolbar,
    'is-toolbar-open': !!toolbarMenuOpen,
  }"
  @slideLeft
  *ngIf="!!navigationService.mobileMenuOpen"
>
  <header class="mobile-menu__header">
    <i
      class="icon icon--medium icon--pointer"
      (click)="navigationService.toggleMobileMenu()"
    >
      close
    </i>
    <p class="t-data t-bold">Menu</p>
  </header>

  <main class="mobile-menu__body">
    <section
      class="mobile-menu__toolbar"
      *ngIf="!!navigationService.config.toolbar"
    >
      <div class="mobile-menu__item">
        <p class="t-data t-bold">{{ navigationService.activeRoute.label }}</p>

        <div class="u-fillRemaining u-flex u-justifyEnd">
          <i
            class="icon icon--medium icon--pointer"
            (click)="toolbarMenuOpen = true"
          >
            edit
          </i>
        </div>
      </div>
    </section>

    <ng-container *ngIf="navigationService.config.menu.sections">
      <section
        class="mobile-menu__section"
        *ngFor="let section of navigationService.config.menu.sections"
      >
        <div
          class="mobile-menu__item"
          [ngClass]="{
            'is-open': !!link.items && !!link.open,
            'is-active': navigationService.isActiveRoute(link.route),
          }"
          *ngFor="let link of section"
          (click)="navigationService.selectNavLink($event, link)"
        >
          <!--
                    <div class="mobile-menu__item__icon">
                        <i class="icon icon--medium">{{!!navigationService.isActiveRoute(link.route) ? link.icon + '_active' : link.icon}}</i>
                    </div>
                    -->

          <ng-container *ngIf="!!link.route">
            <p class="t-data">
              {{ link.label }}
            </p>
          </ng-container>

          <ng-container *ngIf="!!link.link">
            <a class="t-data" [href]="link.link" target="_blank">
              {{ link.label }}
            </a>
          </ng-container>

          <div class="u-flex u-fillRemaining" *ngIf="!!link.items">
            <div class="u-fillRemaining">
              <p class="t-data">{{ link.label }}</p>
            </div>

            <i
              class="icon icon--medium"
              [ngClass]="{ 'icon--rotate180': !!link.open }"
            >
              chevron_down
            </i>
          </div>

          <div
            *ngIf="!!link.items && !!link.open"
            @accordion
            class="mobile-menu__subitems"
          >
            <div class="mobile-menu__subitem" *ngFor="let item of link.items">
              <a
                class="t-data"
                [routerLink]="item.route"
                (click)="navigationService.mobileMenuOpen = false"
              >
                {{ item.label }}
              </a>
            </div>
          </div>
        </div>
      </section>

      <footer
        class="mobile-menu__footer"
        *ngIf="!!navigationService.config.menu.footer"
      >
        <div class="mobile-menu__item">
          <div class="mobile-menu__item__icon">
            <i class="icon icon--medium">{{
              navigationService.config.menu.footer.icon
            }}</i>
          </div>

          <p class="t-data">{{ navigationService.config.menu.footer.label }}</p>
        </div>
      </footer>
    </ng-container>
  </main>
</menu>
