import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  concat,
  forkJoin,
  from,
  map,
  of,
  shareReplay,
  switchMap,
  throwError,
  toArray,
} from 'rxjs';
import { camelCase } from '@zipari/shared-util-common';
import { FormattingService } from '@zipari/design-system';
import { cloneObject } from '@zipari/web-utils';
import { APIService } from '@zipari/web-services';

import {
  ClaimAppealDetail,
  ContactInformation,
  DisputeResponse,
  MemberInformation,
  RequestorInformation,
  ServiceInfo,
  QuestionsResponses,
  ClaimAppealDetailConfig,
} from '../../../shared/models/shared/ClaimAppealDetail.model';
import Claim from '../../../shared/models/shared/Claim.model';
import { DropdownOptions } from '../claim-appeal/claim-redetermination/claim-redetermination.model';
import { ClaimAppealType } from '../claim-detail/claim-detail.constant';
import { ApiListResponse } from '../../../shared/models/shared/ApiListResponse.model';
import Policy from '../../../shared/models/shared/Policy.model';
import { AttachmentMetaData } from '../../../shared/models/shared/Document.model';
import { documentApiEndpoint } from '../../../app.constants';
import { AppealConstant } from '../claim-appeal/claim-appeal.constants';
import {
  ClaimAppealQuestionProps,
  QuestionReasonOfLateFiling,
} from './claim-appeal-detail.constants';

@Injectable({
  providedIn: 'root',
})
export class ClaimAppealDetailDataService {
  claimAppealDetail$: Observable<ClaimAppealDetail>;
  selectedClaimAppealType: string;
  disputeTypes: DropdownOptions[];
  claimAppealDetailsLoaded$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  claimNumber: string;

  constructor(
    private apiService: APIService,
    private formattingService: FormattingService,
  ) {}

  getClaimAppealDetail(
    claimNumber: string | number,
    appealId: string,
    config: ClaimAppealDetailConfig,
    memberId: number,
  ): void {
    const updatedClaimAppealEndpoint =
      config.endpoints.claimAppealDetail.replace(
        '${claimNumber}',
        claimNumber.toString(),
      );
    const claimAppealApi$: Observable<ClaimAppealDetail> = this.apiService.get(
      updatedClaimAppealEndpoint.replace('${appealId}', appealId),
    );

    const coverageApi$: Observable<ApiListResponse<Policy>> =
      this.apiService.get(
        config.endpoints.coverage.replace('${memberId}', memberId.toString()),
      );

    this.claimAppealDetail$ = forkJoin([claimAppealApi$, coverageApi$]).pipe(
      shareReplay({ refCount: true }),
      map(([claimAppealresponse, coverageApiResponse]) => {
        let claimAppeal: ClaimAppealDetail = camelCase(claimAppealresponse);

        if (typeof claimAppeal.billingProvider.name === 'object') {
          claimAppeal = {
            ...claimAppeal,
            billingProvider: {
              ...claimAppeal.billingProvider,
              name: claimAppeal.billingProvider.name.fullName,
            },
          };
        }
        claimAppeal = this.updateDisputeType(claimAppeal);
        claimAppeal.reasonOfLateFilling = this.getQuestionResponse(
          claimAppeal.questionsResponses,
          QuestionReasonOfLateFiling,
        );
        claimAppeal.additionalInfo = this.getQuestionResponse(
          claimAppeal.questionsResponses,
          ClaimAppealQuestionProps.additionalInformation,
        );
        claimAppeal.notAgreeReason = this.getQuestionResponse(
          claimAppeal.questionsResponses,
          ClaimAppealQuestionProps.notAgreeReason,
        );
        this.updateMemberInformation(claimAppeal, coverageApiResponse);
        this.claimAppealDetailsLoaded$.next(true);

        return claimAppeal;
      }),
      catchError((error) => throwError(() => new error(error))),
    );
  }

  public getClaim(claimNumber: string | number): Observable<Claim> {
    return this.apiService.get(`/api/provider-portal/claims/${claimNumber}/`);
  }

  formatDate(date: string): string {
    return this.formattingService.restructureValueBasedOnFormat(date, {
      format: 'DATE',
    });
  }

  updateMemberInformation(
    claimAppeal: ClaimAppealDetail,
    coverageApiResponse: ApiListResponse<Policy>,
  ): ClaimAppealDetail {
    const coverage = camelCase(coverageApiResponse);

    const productCoverage = coverage?.results[0]?.productCoverages[0];
    const memberCoverages = productCoverage?.memberCoverages;

    if (memberCoverages?.length > 0) {
      const memberCoverage = memberCoverages[0];

      claimAppeal.memberInformation = {
        ...claimAppeal.memberInformation,
        externalPlanName: productCoverage?.externalPlanName,
        coveragePeriod: `${this.formatDate(
          memberCoverage.effectiveDate,
        )} - ${this.formatDate(memberCoverage.terminationDate)}`,
      };
    }

    return claimAppeal;
  }

  updateDisputeType(claimAppeal: ClaimAppealDetail): ClaimAppealDetail {
    const claimAppealDetail = cloneObject(claimAppeal);

    if (
      claimAppealDetail.disputeTypeOther &&
      claimAppealDetail.disputeType === AppealConstant.disputeTypeOther
    ) {
      claimAppealDetail.disputeType = claimAppealDetail.disputeTypeOther;
    } else {
      claimAppealDetail.disputeType = this.getDisputeTypeLabel(
        claimAppealDetail.disputeType,
      );
    }

    return claimAppealDetail;
  }

  getDisputeResponse(): Observable<DisputeResponse> {
    return this.claimAppealDetail$?.pipe(
      map(
        ({
          disputeType,
          disputeReason,
          status,
          statusReason,
          originalDecisionDate: dateOfInitialDetermination,
          disputeLevel: levelOfDispute,
          claimLine: { typeOfServiceDescription: serviceAppealed },
          reasonOfLateFilling,
          priorLevelFiledReason,
          additionalInfo,
          notAgreeReason,
          decisionDate: dateOfResponse,
        }) => ({
          disputeType,
          disputeReason,
          status,
          statusReason,
          dateOfInitialDetermination,
          levelOfDispute: ClaimAppealType[levelOfDispute],
          serviceAppealed,
          reasonOfLateFilling,
          priorLevelFiledReason,
          additionalInfo,
          notAgreeReason,
          dateOfResponse,
        }),
      ),
    );
  }

  getDisputeTypeLabel(disputeTypeValue: string): string {
    return (
      this.disputeTypes?.find(
        (option) =>
          typeof option.value === 'string' && option.value === disputeTypeValue,
      )?.label || '--'
    );
  }

  getQuestionResponse(
    questionsResponses: QuestionsResponses[],
    questionProp: string,
  ): string {
    return (
      questionsResponses?.find(
        (question) => question.questionId === questionProp,
      )?.response || '--'
    );
  }

  getMember(): Observable<MemberInformation> {
    return this.claimAppealDetail$?.pipe(
      map(
        ({
          memberInformation: { memberDisplayIdentifier },
          memberInformation: { medicareNumber },
          memberInformation: { medicaidNumber },
          memberInformation: { coveragePeriod },
          memberInformation: { externalPlanName },
        }) => ({
          memberDisplayIdentifier,
          medicareNumber,
          medicaidNumber,
          coveragePeriod,
          externalPlanName,
        }),
      ),
    );
  }

  getService(): Observable<ServiceInfo> {
    return this.claimAppealDetail$?.pipe(
      map(
        ({
          billingProvider: { name, npi },
          claimLine: {
            lineNumber: claimLineNumber,
            serviceFromDate: datOfService,
            authorizationNumber: authorizationNumber,
            paidAmount: amountPaid,
          },
          claimLine: { procedures: procedures },
          claimLine: { diagnosesInformation: diagnosesInformation },
        }) => ({
          servicingProvider: {
            name,
            npi,
          },
          serviceInformation: {
            claimLineNumber,
            datOfService,
            authorizationNumber,
            amountPaid,
            claimNumber: this.claimNumber,
          },
          procedures,
          diagnosesInformation,
        }),
      ),
    );
  }

  getRequestor(): Observable<RequestorInformation> {
    return this.claimAppealDetail$.pipe(
      map(
        ({
          requestorInformation: { name },
          requestorInformation: { emailAddress },
          requestorInformation: { phoneNumbers },
        }) => ({
          name,
          emailAddress,
          phoneNumbers,
        }),
      ),
    );
  }

  getContact(): Observable<ContactInformation> {
    return this.claimAppealDetail$.pipe(
      map(
        ({
          contactInformation: { name },
          contactInformation: { emailAddress },
          contactInformation: { phoneNumbers },
        }) => ({
          name,
          emailAddress,
          phoneNumbers,
        }),
      ),
    );
  }

  getClaimAppealSidePanelInfo(): Observable<AttachmentMetaData[]> {
    return this.claimAppealDetail$?.pipe(
      switchMap(({ documentIds }) => {
        const documentDetailObservables = documentIds.map((documentId) =>
          from(this.getDocumentDetailById(documentId)),
        );

        return concat(...documentDetailObservables).pipe(toArray());
      }),
    );
  }

  getDocumentDetailById(id: number): Observable<AttachmentMetaData> {
    return this.apiService.get(`${documentApiEndpoint}${id}/`).pipe(
      catchError((error) => {
        console.error(error);

        return of();
      }),
    );
  }
}
