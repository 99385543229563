import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

const { version } = require('../../../../../../package.json');

@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandlerService implements ErrorHandler {
  constructor() {
    Sentry.init({
      dsn: 'https://9f03310db4ed4aecb56c3533fc03328e@sentry.zipari.net/31',
      release: version,
      denyUrls: [/local\.zipari/],
      integrations: [Sentry.breadcrumbsIntegration({ console: false })],
    });
  }

  handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}
