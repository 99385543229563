import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import {
  Days,
  SITELOCATION_CONSTANTS,
} from '../../site-locations-attestation/site-location-attestation.constant';
import {
  AffiliatedHospitalFacility,
  LocationContact,
  OperatingHour,
  OperatingHourDays,
  PhoneNumber,
  SiteLocation,
  SiteLocationsProfileConfig,
  SiteLocationsProfileEditConfig,
} from '../../site-locations-attestation/site-locations-attestation.model';
import { SiteLocationsProfileEditService } from './site-locations-profile-edit.service';
import { SiteLocationsProfileHttpService } from './site-locations-profille-http.service';

@Injectable({
  providedIn: 'root',
})
export class SiteLocationsProfileService {
  constructor(
    private siteLocationsProfileEditService: SiteLocationsProfileEditService,
    private siteLocationsProfileHttpService: SiteLocationsProfileHttpService,
  ) {}

  updateContextOperatingHours(siteLocationDetail: SiteLocation): object {
    const operatingHours: OperatingHourDays = {};

    Days.forEach((day) => {
      operatingHours[day] = this.findHours(siteLocationDetail, day);
    });

    return operatingHours;
  }

  findHours(facilityLocationDetails: SiteLocation, day: string): string {
    let facilityLocationTime = '';

    facilityLocationDetails.operatingHours.find(
      (operatingHour: OperatingHour) => {
        if ((operatingHour.day as string)?.toLowerCase() === day) {
          facilityLocationTime = `${
            operatingHour.openingTime?.length
              ? operatingHour.openingTime
              : SITELOCATION_CONSTANTS.invalidDate
          } to ${
            operatingHour.closingTime?.length
              ? operatingHour.closingTime
              : SITELOCATION_CONSTANTS.invalidDate
          }`;
        }
      },
    );

    return facilityLocationTime;
  }

  updateContextLocationContact(
    siteLocationDetail: SiteLocation,
    editProfileFlyoutConfig: SiteLocationsProfileEditConfig,
    context,
  ): void {
    let locationContact: LocationContact | undefined = {};

    locationContact = siteLocationDetail.locationContacts.find(
      (_locationContact: LocationContact) => _locationContact.isPrimary,
    );

    if (locationContact === undefined) {
      locationContact = siteLocationDetail.locationContacts[0];
    }
    if (locationContact) {
      siteLocationDetail.locationContacts.push(locationContact);

      const phoneNumbers: string[] = [];

      if (locationContact?.phoneNumbers) {
        locationContact.phoneNumbers.forEach((phoneNumber: PhoneNumber) => {
          phoneNumbers.push(phoneNumber.phoneNumber);
        });
        (locationContact.phoneNumbers as unknown) = phoneNumbers;
      }
    }
    if (locationContact?.addresses) {
      context.contactAddress = locationContact.addresses[0];
    }
    if (!locationContact) {
      locationContact = {};
    }
    context.locationContact = locationContact;
    this.siteLocationsProfileEditService.updateEditFlyoutConfig(
      context,
      editProfileFlyoutConfig,
    );
  }

  getMultipleAffiliatedHospitalFacility(
    siteLocationDetail: SiteLocation,
    config: SiteLocationsProfileConfig,
  ) {
    return this.siteLocationsProfileHttpService.getMultipleAffiliatedHospitalFacility(
      config,
      siteLocationDetail.affiliatedHospitalFacilityIds,
    );
  }

  async updateFacilityList(
    siteLocationDetail: SiteLocation,
    config: SiteLocationsProfileConfig,
  ): Promise<string[]> {
    try {
      const affiliatedHospitalFacilityName: string[] = [];

      if (siteLocationDetail?.affiliatedHospitalFacilityIds?.length) {
        const hospitalFacilities = await lastValueFrom(
          this.getMultipleAffiliatedHospitalFacility(
            siteLocationDetail,
            config,
          ),
        );

        hospitalFacilities.forEach(
          (hospitalFacility: AffiliatedHospitalFacility) => {
            affiliatedHospitalFacilityName.push(
              hospitalFacility?.name
                ? hospitalFacility.name
                : SITELOCATION_CONSTANTS.invalidName,
            );
          },
        );
      }

      return affiliatedHospitalFacilityName;
    } catch (error) {
      return [SITELOCATION_CONSTANTS.invalidName];
    }
  }
}
