import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Subscription } from 'rxjs';
import { DatePickerConfiguration } from '@zipari/shared-ds-util-form';
import { DatePickerService } from './date-picker.service';

@Component({
  selector: 'zip-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnChanges, OnDestroy {
  @Input() config: DatePickerConfiguration;
  @Input() control: UntypedFormControl;
  @Input() dateRangeProp: string;
  @Output() dateChanged: EventEmitter<MatDatepickerInputEvent<Date>> =
    new EventEmitter();

  public dummyControl: UntypedFormControl = new UntypedFormControl();
  public minDate$ = this.datePickerService.minDate$;
  public maxDate$ = this.datePickerService.maxDate$;
  public iconFill = { fill: 'icon-color' };

  private controlChangeSubscription: Subscription;

  constructor(private datePickerService: DatePickerService) {}

  ngOnChanges({ control, config }: SimpleChanges): void {
    if (control.currentValue !== control.previousValue) {
      this.controlChangeHandler();
    }

    if (config.currentValue !== config.previousValue) {
      const { readonly, isDisabled } = config.currentValue;

      this.iconFill.fill =
        readonly || isDisabled ? 'btn-disabled__text' : 'icon-color';
      this.setDateLimits();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeControlChanges();
  }

  // As of this writing, Zipari APIs prefer YYYY-MM-DD format so
  // Perform the conversion when the date is changed on the passed in control.
  convertToISO(type: string, event: MatDatepickerInputEvent<Date>): void {
    let transformedValue;

    if (type === 'input') {
      transformedValue =
        event.value === null && this.dummyControl.errors === null
          ? null
          : this.dummyControl.invalid
            ? this.dummyControl.errors.matDatepickerParse.text
            : this.transformValue(event.value);
      this.updateControlValue(transformedValue);
    } else {
      transformedValue = event?.value;
    }

    this.dateChanged.emit(event);
  }

  private updateControlValue(newValue: Date | null): void {
    this.control.setValue(newValue, { emitEvent: false });
    this.control.markAsDirty();
  }

  private transformValue(value: Date | string): string {
    const numberOfExtraChars = 10;
    const valueAsDate = new Date(value);
    let correctedDate: Date;

    if (typeof valueAsDate === 'object' && valueAsDate?.getMonth) {
      correctedDate = new Date(
        valueAsDate.getFullYear(),
        valueAsDate.getMonth(),
        valueAsDate.getDate(),
        valueAsDate.getHours(),
        valueAsDate.getMinutes() - valueAsDate.getTimezoneOffset(),
      );
    }

    return (
      correctedDate.toISOString().slice(0, numberOfExtraChars) ||
      value.toString()
    );
  }

  private setValue(newVal: string): void {
    this.dummyControl.setValue(newVal, { emitEvent: false });
  }

  private unsubscribeControlChanges(): void {
    if (this.controlChangeSubscription) {
      this.controlChangeSubscription.unsubscribe();
      this.controlChangeSubscription = null;
    }
  }

  private controlChangeHandler(): void {
    this.setValue(this.control.value);
    this.unsubscribeControlChanges();
    this.controlChangeSubscription = this.control.valueChanges.subscribe(
      (newVal) => {
        this.setValue(newVal);
      },
    );
  }

  private setDateLimits(): void {
    this.datePickerService.setMaxDate(
      this.datePickerService.checkDateType(this.config.max, 'max'),
    );

    this.datePickerService.setMinDate(
      this.datePickerService.checkDateType(this.config.min, 'min'),
    );
  }
}
