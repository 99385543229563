import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WindowService } from '../../shared/services/window.service';
import { checkInputsForText } from '../../design-system.helper';
import { ColumnLineTypes, GridLayoutRow } from './grid-layout.model';

@Component({
  selector: 'grid-layout',
  templateUrl: './grid-layout.component.html',
  styleUrls: ['./grid-layout.component.scss'],
})
export class GridLayoutComponent implements OnInit {
  @Input() config: any;
  @Output() onButtonClick = new EventEmitter();

  types = ColumnLineTypes;
  rows: GridLayoutRow[];

  constructor(private window: WindowService) {}

  ngOnInit() {
    const [config] = checkInputsForText([this.config]);

    this.config = config;
    this.rows = Object.assign([], this.config.rows);
  }

  buttonClick(lineConfig) {
    if (lineConfig.route) {
      this.window.nativeWindow.open(lineConfig.route, '_blank');
    } else {
      this.onButtonClick.emit();
    }
  }
}
