import { getValue } from '@zipari/web-utils';
import { identity, pickBy } from 'lodash';
import {
  StateDeaContext,
  StateDeaPayload,
} from '../../practitioners/add-new/manage-practitioner-add.constant';
import { providerNumbersProp } from '../../practitioners/details/manage-practitioner-detail.constant';
import { SITELOCATION_CONSTANTS } from '../../site-locations-attestation/site-location-attestation.constant';
import {
  SiteLocationsEditFormConfiguration,
  SubmitPractitionerEventAttributesConfig,
} from '../../site-locations-attestation/site-locations-attestation.model';

export const setControlProps = (
  control: SiteLocationsEditFormConfiguration,
  context: object,
): SiteLocationsEditFormConfiguration => {
  if (control.defaultProp) {
    const defaultValue = getValue(context, control.defaultProp);

    control[SITELOCATION_CONSTANTS.default] = defaultValue;
  }
  if (control.defaultStartProp) {
    const defaultStartValue = getValue(context, control.defaultStartProp);

    control[SITELOCATION_CONSTANTS.start] = defaultStartValue;
  }
  if (control.defaultEndProp) {
    const defaultEndValue = getValue(context, control.defaultEndProp);

    control[SITELOCATION_CONSTANTS.end] = defaultEndValue;
  }

  return control;
};

export const populateDropdown = (results: any[], prop: string): any[] =>
  results.map((item) => ({ label: getValue(item, prop), value: item }));

const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue =>
  value !== null && value !== undefined;

export const getControlSelectedValue = (
  control: object[],
): object[] | string[] =>
  control
    .map((item: any) => {
      if (item.selection) return item.selection;
    })
    .filter(notEmpty);

export const getDictionaryAttributesOfSubmitPractitioners = (
  submitPractitionerEventDetails: SubmitPractitionerEventAttributesConfig,
) => {
  const {
    emailAddress: user_email,
    firstName: user_firstname,
    lastName: user_lastname,
  } = submitPractitionerEventDetails.user;

  return {
    user_email,
    user_firstname,
    user_lastname,
    'practitioner-submission_date':
      submitPractitionerEventDetails.user.dateSubmitted,
    'practitioner-submission_time':
      submitPractitionerEventDetails.user.timeSubmitted,
    tax_id: submitPractitionerEventDetails.practitioners
      .map((practitioner) => practitioner.npi)
      .toString(),
    practitioner_name: submitPractitionerEventDetails.practitioners
      .map((practitioner) => practitioner.practitioner)
      .toString(),
  };
};

export const setProviderNumbers = (formValue: any): StateDeaPayload[] =>
  formValue.StateDeaFormGroupArray.filter(
    (group: StateDeaContext) => group.state,
  ).map((group: StateDeaContext) => ({
    id: '',
    number: group.deaGroup?.number,
    state_code: group.state,
    state_id: null,
    state_id_type: 'DEA',
  }));

export const PractitionerPayload = (
  editFormValue,
  additionalDetailsForm,
): object => {
  const {
    middle_name,
    last_name,
    first_name,
    prefix,
    ssn,
    birth_date,
    gender,
    npi,
    effective_date,
    license_number,
    number,
  } = editFormValue;

  const payload = {
    name: {
      middle_name: middle_name,
      last_name: last_name,
      first_name: first_name,
      prefix: prefix,
    },
    ssn: ssn,
    birth_date: birth_date,
    gender: gender,
    languages: editFormValue?.languages
      ? getControlSelectedValue(editFormValue.languages)
      : null,
    npi: npi,
    degrees: editFormValue?.degrees
      ? getControlSelectedValue(editFormValue.degrees)
      : null,
    specialties: editFormValue?.specialties
      ? getControlSelectedValue(editFormValue.specialties)
      : null,
    taxonomy_codes: editFormValue?.taxonomy_codes
      ? getControlSelectedValue(editFormValue.taxonomy_codes)
      : null,
    dea_number: editFormValue?.dea,
    effective_date: effective_date,
    license_number: license_number,
    number: number,
    change_request_effective_date: additionalDetailsForm?.value?.effective_date,
    change_request_notes: additionalDetailsForm?.value?.notes,
  };

  if (editFormValue?.StateDeaFormGroupArray) {
    payload[providerNumbersProp] = setProviderNumbers(editFormValue);
  }

  return pickBy(payload, identity);
};
