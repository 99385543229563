import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormArray,
  Validators,
} from '@angular/forms';
import { ProviderUpdateToolService } from '../../provider-update-tool.service';

@Component({
  selector: 'input-array',
  templateUrl: './input-array.component.html',
  styleUrls: ['./input-array.component.scss'],
})
export class InputArrayComponent implements OnInit {
  @Input() config: any;
  @Input() key: string;
  @Output() inputArrayCreated = new EventEmitter<any>();

  constructor(
    private fb: UntypedFormBuilder,
    public mp: ProviderUpdateToolService,
  ) {}

  ngOnInit() {
    if (!this.getInitState) {
      // if no state, add values
      this.setInitState('opened');
      if (Array.isArray(this.contextData)) {
        this.contextData.forEach((element, index) => {
          if (index === 0) {
            const formGroup: any = this.formArray.at(0) as UntypedFormGroup;

            if (formGroup) formGroup.controls['selection'].setValue(element);
          } else {
            const val: any = this.fb.group({ selection: '' });

            val.controls['selection'].setValue(element);
            this.formArray.push(val);
          }
        });
      }
    } // if opened but not edited, set values
    else if (this.getInitState === 'opened') {
      if (Array.isArray(this.contextData)) {
        this.contextData.forEach((element, index) => {
          const formGroup: any = this.formArray.at(index) as UntypedFormGroup;

          if (formGroup) formGroup.controls['selection'].setValue(element);
        });
      }
    }

    this.onInputArrayCreated();
  }

  onInputArrayCreated(): void {
    this.inputArrayCreated.emit();
  }

  getObjectProperty(obj, keys) {
    return keys.split('.').reduce(function (result, key) {
      return result[key];
    }, obj);
  }

  get formArray() {
    return this.mp
      .getFormGroup(this.key)
      .get(this.config.prop) as UntypedFormArray;
  }

  get contextData(): any[] {
    return this.mp.getContextData(this.key)[this.config.prop];
  }

  get getInitState() {
    if (!this.mp.getInitState(this.key)) return true;

    return this.mp.getInitState(this.key)[this.config.prop];
  }

  setInitState(state: string) {
    this.mp.getInitState(this.key)[this.config.prop] = state;
  }

  addArrayItem() {
    const validators: any[] = this.mp.getValidators(this.config.validatorType);

    if (this.config.required) {
      validators.push(Validators.required);
    }
    this.formArray.push(this.fb.group({ selection: ['', validators] }));
  }

  deleteArrayItem(index) {
    this.formArray.removeAt(index);
  }

  getMaxSize() {
    return this.formArray.length;
  }

  public getJSONValue() {
    return JSON.stringify(this.mp.getFormGroup(this.key).value);
  }
}
