/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { identity, pickBy } from 'lodash';
import { GenericServices } from '../../../../shared/services/generic.service';
import { ProviderUpdateToolService } from '../../provider-update-tool.service';
import { APIService } from '@zipari/web-services';
import { ConfigService } from '../../../../../shared/services/config.service';
import { AnalyticsService } from '../../../../../shared/services/analytics.service';

import {
  StateDeaContext,
  StateDeaPayload,
} from './manage-practitioner-add.constant';

@Component({
  styleUrls: ['./manage-practitioner-add.component.scss'],
  selector: 'manage-practitioner-add',
  templateUrl: './manage-practitioner-add.component.html',
})
export class ManagePractitionerAddComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  busy: Subscription;
  config: any;
  state: 'one' | 'two' | 'three' | 'four' = 'one';
  addState: 'add' | 'review';
  submitCheckFields = new UntypedFormGroup({});
  profileCheckFields = new UntypedFormGroup({});
  showModal = false;
  selectedRows: any[] = [];
  providerExists = false;
  error = false;
  associateFields: any[] = [];
  createResponse: any;
  associateResponse: any;

  constructor(
    private api: APIService,
    private fb: UntypedFormBuilder,
    private location: Location,
    private cdr: ChangeDetectorRef,
    public configService: ConfigService,
    private el: ElementRef,
    public mp: ProviderUpdateToolService,
    public genericServices: GenericServices,
    public analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.genericServices.showFooter$.next(false);
    });
    this.config = this.configService.getPageConfig('practitioners');

    const editArrayFields = {};

    if (this.config?.createSteps?.two?.sections) {
      for (const section of this.config?.createSteps?.two?.sections) {
        if (section.additionalDetails?.controls) {
          for (const control of section.additionalDetails?.controls) {
            if (control.type === 'formGroupArray') {
              const group = new UntypedFormGroup({});

              editArrayFields[control.prop] = this.fb.array([group]);
            } else {
              const validators: any[] = this.mp.getValidators(
                control.validatorType,
              );

              if (control.required) validators.push(Validators.required);
              editArrayFields[control.prop] = this.fb.array([
                this.fb.group({ selection: ['', validators] }),
              ]);
            }
          }
        }
        if (section.details?.controls) {
          for (const control of section.details.controls) {
            if (control.apiEndpoint) {
              this.busy = this.api
                .get(control.apiEndpoint)
                .subscribe((resp: any) => {
                  control.options = this.populateDropdown(
                    new Array<any>(),
                    resp.results,
                    control.dropdownDisplayProp,
                  );
                });
            }
          }
        }
      }
    }

    if (this.config?.associateFlyout?.controls) {
      for (const control of this.config?.associateFlyout?.controls) {
        if (control.apiEndpoint) {
          this.busy = this.api
            .get(control.apiEndpoint)
            .subscribe((resp: any) => {
              control.options = this.populateDropdown(
                new Array<any>(),
                resp.results,
                control.dropdownDisplayProp,
              );
            });
        }
      }
    }

    this.mp.setFormGroup('addPractitioner', this.fb.group(editArrayFields));
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  submitCheck() {
    if (this.submitCheckFields.valid) {
      if (this.submitCheckFields?.value) {
        const values: any = this.submitCheckFields?.value;
        const first_name: string = values.first_name;
        const last_name: string = values.last_name;
        const npi: string = values.npi;
        // eslint-disable-next-line max-len
        const url = `api/provider-portal/update-tool/providers/?provider_last_name=${last_name}&provider_first_name=${first_name}&npi=${npi}`;

        this.busy = this.api.get(url).subscribe(
          (response: any) => {
            if (!response.results?.length) {
              this.moveForward();
            } else this.toggleBack();
          },
          (error: any) => {
            // hack for UCARE, shouldn't affect anyone else
            // if the error code is 405, and we have a valid user_error message that says no practitioner found
            // if the error code is 404, we have empty/null result
            if (error.status === 405 || error.status === 404) {
              this.moveForward();
            } else this.toggleError();
          },
        );
      }
    } else {
      const controls = this.submitCheckFields.controls;

      Object.keys(controls).forEach((c: any) => {
        controls[c].markAsTouched();
      });
      setTimeout(() => {
        this.genericServices.scrollToFirstInvalidControl(this.el);
      });
    }
  }

  moveForward() {
    this.state = 'two';
    for (const section of this.config?.createSteps?.two?.sections) {
      if (section.details?.controls) {
        for (const control of section.details.controls) {
          if (control.defaultPrepop) {
            const defaultValue = this.submitCheckFields.get(
              control.defaultPrepop,
            )?.value;

            control['default'] = defaultValue;
          }
        }
      }
    }
  }

  toggleBack() {
    this.providerExists = !this.providerExists;
  }

  toggleError() {
    this.error = !this.error;
  }

  back(): void {
    if (this.genericServices.previousURL.includes('provider-portal')) {
      this.location.back();
    }
  }

  submit() {
    if (
      this.profileCheckFields.valid &&
      this.mp.getFormGroup('addPractitioner')?.valid
    ) {
      let cxCaptureObj = null;

      if (this.profileCheckFields?.value) {
        const simpleValues: any = this.profileCheckFields.value;
        const complexValues: any =
          this.mp.getFormGroup('addPractitioner')?.value;

        let payload: any = {
          name: {
            middle_name: simpleValues.middle_name,
            last_name: simpleValues.last_name,
            first_name: simpleValues.first_name,
            full_name: `${simpleValues.first_name}  ${simpleValues.last_name}`,
            prefix: simpleValues.title,
          },
          practicing_in_states: [
            {
              id: null,
              number: null,
              state_code: null,
              state_id: simpleValues.number,
            },
          ],
          ssn: simpleValues.ssn,
          birth_date: simpleValues.date,
          effective_date: simpleValues.effective_date,
          termination_date: simpleValues.termination_date,
          gender: simpleValues.gender,
          languages: complexValues.languages
            ? complexValues.languages
                .map((item: any) => {
                  if (item.selection) return item.selection;
                })
                .filter(this.notEmpty)
            : null,
          npi: simpleValues.npi,
          degrees: complexValues.degrees
            ? complexValues.degrees
                .map((item: any) => {
                  if (item.selection) return item.selection;
                })
                .filter(this.notEmpty)
            : null,
          specialties: complexValues.specialties
            ? complexValues.specialties
                .map((item: any) => {
                  if (item.selection) return item.selection;
                })
                .filter(this.notEmpty)
            : null,
          taxonomy_codes: complexValues.taxonomy
            ? complexValues.taxonomy
                .map((item: any) => {
                  if (item.selection) return item.selection;
                })
                .filter(this.notEmpty)
            : null,
          dea_number: simpleValues.dea,
          license_number: simpleValues.license,
          number: simpleValues.state_id,
          provider_numbers: this.setProviderNumbers(complexValues),
        };

        cxCaptureObj = {
          name: `${simpleValues.first_name} ${simpleValues.last_name}`,
          npi: simpleValues.npi,
          degree: complexValues.degrees
            ? complexValues.degrees
                .map((item: any) => {
                  if (item.selection) return item.selection.degree_name;
                })
                .toString()
            : null,
          specialty: complexValues.specialties
            ? complexValues.specialties
                .map((item: any) => {
                  if (item.selection) return item.selection.name;
                })
                .toString()
            : null,
          tax_id: complexValues.taxonomy
            ? complexValues.taxonomy
                .map((item: any) => {
                  if (item.selection) return item.selection;
                })
                .toString()
            : null,
        };
        payload = JSON.parse(JSON.stringify(payload));
        const cleanedObject = pickBy(payload, identity);
        const _cleanedObject = pickBy(cleanedObject, this.isArrayNotEmpty);
        const __cleanedObject = pickBy(_cleanedObject, this.notEmpty);

        this.cleanObj(__cleanedObject, 'name');
        this.busy = this.api
          .post(`api/provider-portal/update-tool/providers/`, __cleanedObject)
          .subscribe(
            (response: any) => {
              this.createResponse = response;
              this.state = 'three';
            },
            () => {
              this.toggleError();
            },
          );
      }
      this.analyticsService.sendEvent(
        this.config.buttons.savebutton.cx_event_key,
        cxCaptureObj,
      );
    } else {
      this.profileCheckFields.markAllAsTouched();
      this.mp.getFormGroup('addPractitioner')?.markAllAsTouched();
      setTimeout(() => {
        this.genericServices.scrollToFirstInvalidControl(this.el);
      });
    }
  }

  cleanObj(obj: any, keyString: any) {
    if (obj && obj[keyString]) {
      Object.keys(obj[keyString]).forEach(
        (key: string) =>
          (obj[keyString][key] === null ||
            obj[keyString][key] === undefined ||
            obj[keyString][key] === '') &&
          delete obj[keyString][key],
      );
    }
  }

  isArrayNotEmpty(array: any) {
    if (Array.isArray(array) && array.length === 0) {
      return false;
    }
    if (array && Object.keys(array).length === 0) {
      return false;
    }
    if (array === null || array === undefined) {
      return false;
    }

    return true;
  }

  notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }

  handleSelectedRows(rows: any): void {
    this.selectedRows = rows;
  }

  resetAssociatedFields() {
    this.associateFields = [];
  }

  toggleModal() {
    this.resetAssociatedFields();
    this.showModal = !this.showModal;
    if (this.addState !== 'add') {
      this.addState = 'add';
      this.selectedRows = [];
    }
  }

  toggleModalInitiate() {
    this.toggleModal();
    this.analyticsService.sendEvent(this.config.createSteps.three.cx_event_key);
  }

  checkAssociated() {
    let value = true;

    this.associateFields.forEach((item, index) => {
      // eslint-disable-next-line no-empty
      if (item.valid) {
      } else {
        value = false;
        const controls = item.controls;

        Object.keys(controls).forEach((c: any) => controls[c].markAsTouched());
      }
    });

    return value;
  }

  getAssociatedFields(index: number) {
    if (!this.associateFields[index]) {
      this.associateFields[index] = new UntypedFormGroup({});
    }

    return this.associateFields[index];
  }

  getAssociatedFieldValue(index: number) {
    return this.associateFields[index]?.value;
  }

  submitAddAssociated() {
    // if (this.associateFields.valid) {
    // if (this.associateFields?.value) {

    if (!this.checkAssociated()) return;
    const facility_location_providers: any[] = [];

    this.selectedRows.forEach((row, index) => {
      const rowFields: any = this.getAssociatedFieldValue(index);
      const _payload: any = {
        facility_location_id: row.row?.id,
        provider_id: this.createResponse?.transaction_id
          ? this.createResponse.transaction_id
          : this.createResponse?.id,
        accepting_new_patients: rowFields?.accepting_new_patients
          ? true
          : false,
        can_display_in_directory: rowFields?.display_in_directory
          ? true
          : false,
        specialties: [rowFields?.specialty],
      };

      if (rowFields?.effective_date) {
        _payload['effective_date'] = rowFields.effective_date;
      }
      if (rowFields?.notes) _payload['last_updated_notes'] = rowFields.notes;
      facility_location_providers.push(_payload);
    });

    const payload: any = {
      should_fail_on_error: false,
      operation_type: 'create',
      facility_location_providers: facility_location_providers,
    };

    this.busy = this.api.post(this.config.endpoints.batch, payload).subscribe(
      (res: any) => {
        this.associateResponse = res;
        this.state = 'four';
        this.toggleModal();
      },
      () => {
        this.toggleModal();
        this.toggleError();
      },
    );
  }

  reviewAssociated(): void {
    if (this.selectedRows.length === 0) {
      // this.toggleError();
      return;
    }
    this.addState = 'review';
  }

  populateDropdown(options: any[], results: any[], prop: string) {
    for (const item of results) {
      options.push({
        label: this.getObjectProperty(item, prop),
        value: item,
      });
    }

    return options;
  }

  isDisabled() {
    let dontShow = false;

    dontShow = this.setDisabledValidations(this.associateFields, dontShow);

    return dontShow;
  }

  setDisabledValidations(control: any, returnBool: boolean): boolean {
    control.forEach((item, index) => {
      if (!item.valid) {
        const controls = item.controls;

        if (controls) {
          Object.keys(controls).forEach((c: any) => {
            if (!controls[c].valid) {
              if (controls[c].controls) {
                Object.keys(controls[c].controls).forEach((cc: any) => {
                  returnBool = this.setDisabledValidations(
                    controls[c].controls[cc],
                    returnBool,
                  );
                });
              } else {
                if (!controls[c].pristine) {
                  controls[c].markAsTouched();
                }
              }
            }
          });
        }
        returnBool = true;
      }
    });

    return returnBool;
  }

  getObjectProperty(obj, keys) {
    if (!obj) return '';
    if (!keys) return '';

    return keys.split('.').reduce(function (result, key) {
      return result[key];
    }, obj);
  }

  setProviderNumbers(formValue: any): StateDeaPayload[] {
    const providerNumbers = formValue.StateDeaFormGroupArray?.map(
      (group: StateDeaContext) => ({
        id: '',
        number: group.deaGroup?.number,
        state_code: group.state,
        state_id_type: 'DEA',
        state_id: '',
      }),
    );

    return providerNumbers;
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.genericServices.showFooter$.next(true);
    });
  }
}
