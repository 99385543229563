<div class="table__row u-relative">
  @if (columns && data) {
    @for (column of columns; track column; let i = $index) {
      <table-summary-cell
        class="table__cell"
        [column]="column"
        [index]="i"
        [data]="getTotalValue(data, column)"
      >
      </table-summary-cell>
    }
  }
</div>
