import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaxIdPayload } from './auth-card.modal';

@Injectable({
  providedIn: 'root',
})
export default class AuthCardService {
  constructor(public httpClient: HttpClient) {}

  public validateTaxIdPayload(
    postApi: string,
    taxId: string,
  ): Observable<TaxIdPayload> {
    return this.httpClient.post(postApi, {
      tax_id: taxId,
    });
  }
}
