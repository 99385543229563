import { PortalUser } from '../../../shared/models/shared/PortalUser.model';

export const mockPortalUser: PortalUser = {
  id: 85251,
  email_address: 'Fatima_Haag@yahoo.com',
  email_verified: false,
  is_active: true,
  last_login: '07/23/2019',
  data: {
    title: 'Customer Communications Executive',
    permissions: ['None', 'Some'],
  },
  roles: [{ name: 'User' }],
  first_name: 'Sid',
  last_name: 'Little',
  phone_number: '(503) 239-0807 x3734',
  user_name: 'Shane.Green41',
  accepted_terms: ['voluptatem', 'praesentium', 'facilis'],
  broker_id: 7485,
  member_id: 22249,
};
