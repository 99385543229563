<div class="tab-view">
  <section>
    <section *ngIf="activeTab?.header" class="tab--header--buttons">
      <ng-container *ngFor="let button of activeTab?.header?.buttons">
        <zip-button
          [config]="button.config"
          (zipButtonClicked)="onButtonClick(button.route)"
        ></zip-button>
      </ng-container>
    </section>
    <div *ngIf="activeTab?.subheader" class="column-subheader">
      {{ activeTab?.subheader }}
    </div>
    <ng-container *ngFor="let filter of tabFilters; let i = index">
      <div class="tab-view__filters">
        <form-control
          [group]="formGroup"
          [control]="formGroup.get(tabFilters[i].prop)"
          [config]="tabFilters[i]"
          direction="filter"
        >
        </form-control>
      </div>
    </ng-container>
    <div
      class="tab-view__content"
      *ngIf="
        (data && !activeTab?.explicitDataIdentifier) ||
        (activeTab?.explicitDataIdentifier && explicitData)
      "
    >
      <div
        class="tab-view_tab"
        *ngFor="let section of activeTab?.sections; let i = index"
      >
        <div
          class="tab-view__content__no-data"
          *ngIf="!data[section.targetData] && section.targetData"
        >
          <p class="t-data">{{ section.noDataMessage || 'No Data Found' }}</p>
        </div>

        <div *ngIf="data[section.targetData] || !section.targetData">
          <div class="tab-view__tab__switch" [ngSwitch]="section.format">
            <header
              *ngIf="section?.header"
              class="tab-view__header"
              [ngClass]="setHeaderCSS(section?.headerEditIcon)"
              (click)="
                openModalIfNeeded($event, section?.headerEditIcon, section, i)
              "
            >
              <div *ngIf="section?.headerIcon">
                <i
                  class="header-icon-position"
                  [ngStyle]="section?.headerIcon?.styles"
                  [ngClass]="section?.headerIcon?.classString"
                  >{{ section?.headerIcon?.name }}
                </i>
              </div>
              <h6 class="tab-view__header__text">
                {{ section?.header }}
              </h6>
              <div
                *ngIf="section?.headerEditIcon"
                (click)="openModal(section, i)"
              >
                <i
                  class="header-edit-icon-position icon icon--medium"
                  [ngStyle]="section?.headerEditIcon?.styles"
                  >edit
                </i>
              </div>
            </header>

            <div *ngSwitchCase="'accordion'">
              <div
                *ngIf="section.accordion && !section.accordion.layoutFromConfig"
              >
                <div *ngFor="let accordion of section.fields; let i = index">
                  <accordion
                    (toggle)="toggleAccordion($event)"
                    [header]="section['accordion']?.header + ' ' + (i + 1)"
                    [card]="false"
                    [content]="accordionTemplate"
                  ></accordion>

                  <ng-template #accordionTemplate>
                    <div class="tab-view__three-column">
                      <div
                        class="three-column__attrs"
                        *ngFor="let item of accordion; let idx = index"
                      >
                        <model-attribute
                          [context]="
                            data[section['accordion'].targetDataObj][i]
                          "
                          [config]="
                            formatFieldConfig(
                              item,
                              data[section['accordion'].targetDataObj][i]
                            )
                          "
                        ></model-attribute>
                        <p
                          *ngIf="
                            item?.formatOptions?.statusProp &&
                            outOfNetwork?.isOutOfNetworkEnabled &&
                            showOutOfNetworkBadge(
                              item?.formatOptions?.statusProp,
                              data[section['accordion'].targetDataObj][i]
                            )
                          "
                          class="OutOfNetwork__StatusPill Pending"
                        >
                          {{ outOfNetwork?.outOfNetworkLabel }}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>

              <div *ngIf="section.accordions">
                <div *ngFor="let accordion of section.accordions">
                  <accordion
                    (toggle)="toggleAccordion($event)"
                    [header]="accordion?.header"
                    [card]="false"
                    [content]="accordionsTemplate"
                  ></accordion>

                  <ng-template #accordionsTemplate>
                    <div
                      class="accordion-content"
                      *ngFor="let field of accordion.accordionFields"
                    >
                      <div class="t-bold link-item">
                        <div
                          class="link-item__title"
                          (click)="handleLinkClick(field)"
                        >
                          <i
                            *ngIf="field.icon"
                            class="icon icon--medium link-item__icon"
                            [ngStyle]=""
                            [ngClass]=""
                            >{{ field.icon }}
                          </i>
                          <p class="t-bold link">{{ field.title }}</p>
                        </div>
                        <div class="link-item__desc">
                          <p [innerHTML]="field.description"></p>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>

            <div
              class="tab-view__three-column"
              *ngSwitchCase="'threeColumnGrid'"
            >
              <div
                class="three-column__attrs"
                *ngFor="let item of section.fields"
              >
                <model-attribute
                  [context]="data"
                  [config]="item"
                ></model-attribute>
                <p
                  *ngIf="
                    item?.formatOptions?.statusProp &&
                    outOfNetwork?.isOutOfNetworkEnabled &&
                    showOutOfNetworkBadge(item?.formatOptions?.statusProp, data)
                  "
                  class="OutOfNetwork__StatusPill Pending"
                >
                  {{ outOfNetwork?.outOfNetworkLabel }}
                </p>
              </div>
            </div>

            <div class="tab-view__two-column" *ngSwitchCase="'twoColumnGrid'">
              <div
                class="two-column__attrs"
                *ngFor="let item of section.fields"
              >
                <model-attribute
                  [context]="data"
                  [config]="item"
                  direction="row_equal"
                ></model-attribute>
              </div>
            </div>

            <div class="tab-view__list" *ngSwitchCase="'clinical_review_list'">
              <ul class="tab_list_item">
                <ng-container *ngFor="let listItem of data[section.targetData]">
                  <ng-container
                    *ngIf="listItem?.guidelineTitle; else ClinicalReviewList"
                  >
                    <header class="Clinical__Review__Header">
                      <h6
                        class="Clinical__Review__Header__Text"
                        [innerHTML]="listItem.guidelineTitle"
                      ></h6>
                    </header>
                  </ng-container>
                  <ng-template #ClinicalReviewList>
                    <clinical-review-list
                      [listItem]="listItem"
                    ></clinical-review-list>
                  </ng-template>
                </ng-container>
              </ul>

              <ng-container *ngIf="data[section.targetData].length <= 0">
                <p class="t-data">{{ section.noDataMessage }}</p>
              </ng-container>
            </div>

            <div class="tab-view__table-container" *ngSwitchCase="'table'">
              <zip-button
                *ngIf="section?.headerActionButtonConfig"
                class="header-action-button-positioning"
                [config]="section?.headerActionButtonConfig"
                (zipButtonClicked)="onButtonClick(section)"
              >
              </zip-button>
              <zip-table
                [options]="section.table"
                [data]="targetTableLocation[section.targetLocation]"
                (cellClicked)="navigateToDetail($event)"
                (deleteClicked)="deleteClicked($event)"
                (selectedRows)="handleRowSelection($event)"
              >
              </zip-table>
            </div>

            <div class="tab-view__two-column" *ngSwitchCase="'note'">
              <div *ngFor="let item of data.notes">
                <div class="note_heading">
                  {{ modifyHeadingContent(item.note_type) }}
                </div>
                <div class="note_content">
                  {{ item.note_content }}
                </div>
              </div>
            </div>

            <div *ngSwitchCase="'progressBar'" class="progress-bar__view">
              <div *ngIf="data[section.targetData]">
                <div
                  *ngFor="
                    let bar of section.progressBars | filter: tabFilters[0]
                  "
                >
                  <div class="progress-bar">
                    <progress-bar [data]="bar"></progress-bar>
                  </div>
                </div>
              </div>
            </div>
            <div *ngSwitchCase="'idCards'">
              <id-cards [config]="section"></id-cards>
            </div>
            <div *ngSwitchCase="'provisioning'">
              <div class="viewer-container">
                <viewer-provisioning
                  [config]="section.viewProvisioner"
                ></viewer-provisioning>
              </div>
            </div>

            <div
              *ngSwitchCase="'pregnancy_form'"
              (preInit)="preInit(section.table)"
            >
              <div>
                <pregnancy-form-list
                  [config]="section.table"
                ></pregnancy-form-list>
              </div>
            </div>
            <div *ngSwitchCase="'datagrid'">
              <datagrid
                *ngIf="section?.datagrid"
                [config]="section.datagrid"
                [data]="data.access_requested"
              ></datagrid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <add-or-edit-modal
    #add_new_flyout
    *ngIf="config && showModal"
    [openAccordionIdx]="openAccordionIdx"
    [viewProfile]="true"
    [currentSelectedRows]="selectedRows"
    [config]="activeTab"
    [data]="data"
    (cancel)="onCancelClick()"
    (save)="onSaveNewUserClick($event)"
  >
  </add-or-edit-modal>
  <add-or-edit-modal
    #delete_flyout
    *ngIf="deleteConfig && showDeleteModal"
    [openAccordionIdx]="openFlyouts"
    [viewProfile]="true"
    [currentSelectedRows]="selectedRows"
    [config]="deleteConfig"
    [data]="data"
    (cancel)="onCancelClick()"
    (save)="onSaveNewUserClick($event)"
  >
  </add-or-edit-modal>
</div>
