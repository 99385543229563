import { Component, Input } from '@angular/core';
import { ColumnConfig } from '../../../models/column.model';
import { FormattingService } from '../../../../../shared/services/formatting.service';

@Component({
  selector: 'table-summary-cell',
  templateUrl: './summary-cell.component.html',
  styleUrls: ['./summary-cell.component.scss'],
})
export class SummaryCellComponent {
  @Input() index: number;
  @Input() column: ColumnConfig;
  @Input()
  set data(data) {
    this._cellValue =
      this.index === 0
        ? 'Total'
        : this.formattingService.restructureValueBasedOnFormat(
            data,
            this.column,
          );
  }
  _cellValue: any = '';

  constructor(public formattingService: FormattingService) {}
}
