import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { APIService } from '@zipari/web-services';
import { CX_CALLS } from '../../../../../shared/constants/cx-calls.constant';
import { ProviderUpdateToolService } from '../../../provider-update-tool/provider-update-tool.service';
import { AnalyticsService } from '../../../../../shared/services/analytics.service';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input() config: any = {};
  @Input() data: any = {};
  @Output() callNotification = new EventEmitter();

  busy: Subscription;
  error = false;
  noSpace = 'n/a';
  response: any;
  messageConfig: any;
  confirm = false;

  constructor(
    private fb: UntypedFormBuilder,
    private api: APIService,
    public mp: ProviderUpdateToolService,
    public analyticsService: AnalyticsService,
  ) {}

  ngOnInit() {
    this.formateData(this.data);
  }

  formateData(data) {
    const config = this.config?.notification?.getNotification;

    if (Object.values(config?.matchingKey).includes(data.tags[0])) {
      for (const [key, value] of Object.entries(config?.matchingKey)) {
        if (data.tags[0] === value) {
          const messageConfig = config.messageBanner[key];
          const title = this.getObjectProperty(
            data,
            messageConfig.messageHeading.match(/{([^}]*)}/)[1],
          );
          const body = this.getObjectProperty(
            data,
            messageConfig.message.match(/{([^}]*)}/)[1],
          );

          this.messageConfig = {
            ...messageConfig,
            messageHeading: title,
            message: body,
          };
        }
      }
    } else {
      const messageConfig = config.messageBanner['default'];
      const title = this.getObjectProperty(
        data,
        messageConfig.messageHeading.match(/{([^}]*)}/)[1],
      );
      const body = this.getObjectProperty(
        data,
        messageConfig.message.match(/{([^}]*)}/)[1],
      );

      this.messageConfig = {
        ...messageConfig,
        messageHeading: title,
        message: body,
      };
    }

    this.messageConfig
      ? this.analyticsService.sendEvent(CX_CALLS.pp_alert_displayed.event_key)
      : '';
  }

  getObjectProperty(obj, key) {
    return key.split('.').reduce(function (result, _key) {
      return result[_key];
    }, obj);
  }
  confirmToDelete() {
    this.confirm = !this.confirm;
  }

  confirmClicked(confirmation) {
    if (confirmation) {
      this.deleteNotification(this.data);
      this.analyticsService.sendEvent(CX_CALLS.pp_alert_deleted.event_key);
      this.confirmToDelete();
    } else {
      this.confirmToDelete();
    }
  }

  deleteNotification(data) {
    const configUrl = this.config?.notification?.removeNotification?.endpoint;
    const baseUrl = configUrl.slice(0, configUrl.indexOf('$'));
    const id = this.getObjectProperty(data, configUrl.match(/{([^}]*)}/)[1]);
    const apiUrl = baseUrl + id;

    if (apiUrl) {
      this.busy = this.api.delete(apiUrl).subscribe(
        (resp: any) => {
          this.analyticsService.sendEvent(CX_CALLS.pp_alert_deleted.event_key);
          this.callNotification.emit(this.config);
        },
        (err: any) => {
          this.toggleError();
        },
      );
    }
  }

  toggleError() {
    this.error = !this.error;
  }
}
