import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ZipTable2 } from '../../models';

const DEFAULT_NUMBER_OF_PAGE_BUTTONS = 5;

@Component({
  selector: 'zip-table-footer-2',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnChanges, OnInit {
  /** The length of the total number of items that are being paginated. Defaulted to 0. */
  @Input() length: number;

  /** How many buttons should be shown (in pagination area) */
  @Input() numberOfButtonsToShow = DEFAULT_NUMBER_OF_PAGE_BUTTONS;

  /** All of the options specific to the footer */
  @Input() options: ZipTable2;

  /** The current page the table is on */
  @Input() currentPage: number;
  @Output() pageSelected = new EventEmitter<any>();

  /** Event emitted when the paginator changes the page size or page index. */
  @Output() page: EventEmitter<any> = new EventEmitter();

  /** An array with all of the pages for display */
  pagesAsArray;

  setCurrentPage(page: number) {
    this.pageSelected.emit(page);
  }

  ngOnInit() {
    this.setCurrentPage(this.options.paging.pageIndex + 1);
    this.setCurrentlyVisibleButtons();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('length' in changes) {
      this.setCurrentlyVisibleButtons();
    }
  }

  /** Handles setting the right visible buttons. (keep the active button in the middle and then pad the left and right with however many there should be) */
  setCurrentlyVisibleButtons() {
    if (this.options.footer.areNumberedButtonsPresent) {
      const numberOfPages = this.getNumberOfPages();

      if (numberOfPages <= this.numberOfButtonsToShow) {
        this.pagesAsArray = this.getArrayOfPageNumberLength();
      } else {
        let startPage = Math.max(
          this.currentPage - Math.floor(this.numberOfButtonsToShow / 2),
          1,
        );
        let endPage = startPage + this.numberOfButtonsToShow - 1;

        // Adjust if limit is exceeded
        if (endPage > numberOfPages) {
          endPage = numberOfPages;
          startPage = endPage - this.numberOfButtonsToShow + 1;
        }
        this.pagesAsArray = this.getArrayOfPageNumberLength(
          startPage,
          this.numberOfButtonsToShow,
        );
      }
    }
  }

  /** Advances to the next page if it exists. */
  nextPage(): void {
    if (!this.hasNextPage()) {
      return;
    }
    this.options.paging.pageIndex++;
    this.setCurrentPage(this.options.paging.pageIndex + 1);
    this.setCurrentlyVisibleButtons();
    this._emitPageEvent();
  }

  /** Move back to the previous page if it exists. */
  previousPage(): void {
    if (!this.hasPreviousPage()) {
      return;
    }
    this.options.paging.pageIndex--;
    this.setCurrentPage(this.options.paging.pageIndex + 1);
    this.setCurrentlyVisibleButtons();
    this._emitPageEvent();
  }

  /** Go to a specified page in the table.
   * @param pageIndex - which page to jump to
   * */
  goToPage(pageIndex) {
    if (pageIndex > this.getNumberOfPages()) {
      return;
    }
    this.options.paging.pageIndex = pageIndex - 1;
    this.setCurrentPage(this.options.paging.pageIndex + 1);
    this.setCurrentlyVisibleButtons();
    this._emitPageEvent();
  }

  /** Move to the first page if not already there. */
  firstPage(): void {
    // hasPreviousPage being false implies at the start
    if (!this.hasPreviousPage()) {
      return;
    }
    this.options.paging.pageIndex = 0;
    this.setCurrentPage(this.options.paging.pageIndex + 1);
    this.setCurrentlyVisibleButtons();
    this._emitPageEvent();
  }

  /** Move to the last page if not already there. */
  lastPage(): void {
    // hasNextPage being false implies at the end
    if (!this.hasNextPage()) {
      return;
    }
    this.options.paging.pageIndex = this.getNumberOfPages() - 1;
    this.setCurrentPage(this.options.paging.pageIndex + 1);
    this.setCurrentlyVisibleButtons();
    this._emitPageEvent();
  }

  /** Whether there is a previous page. */
  hasPreviousPage(): boolean {
    return (
      this.options.paging.pageIndex >= 1 && this.options.paging.pageSize !== 0
    );
  }

  /** Whether there is a next page. */
  hasNextPage(): boolean {
    const numberOfPages = this.getNumberOfPages() - 1;

    return (
      this.options.paging.pageIndex < numberOfPages &&
      this.options.paging.pageSize !== 0
    );
  }

  /** Calculate the number of pages */
  getNumberOfPages(): number {
    return Math.ceil(this.length / this.options.paging.pageSize);
  }

  /** get how many pages there are */
  getArrayOfPageNumberLength(start?: number, windowSize?: number) {
    const numberOfPages = this.getNumberOfPages();

    return Array.apply(
      null,
      Array(windowSize ? windowSize : numberOfPages),
    ).map(function (_, index) {
      if (start) {
        return index + start;
      } else {
        return index + 1;
      }
    });
  }

  /** A label for the range of items within the current page and the length of the whole list. */
  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 of ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex}`;
  };

  /** Emits an event notifying that a change of the paginator's properties has been triggered. */
  private _emitPageEvent() {
    this.page.emit({
      pageIndex: this.options.paging.pageIndex,
      pageSize: this.options.paging.pageSize,
      length: this.length,
    });
  }
}
