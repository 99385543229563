import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AnalyticsService } from '../../services/analytics.service';

import { CX_CALLS } from '../../constants/cx-calls.constant';
import { ConfigService } from '../../services';
import { NavItem, Toolbar } from './navigation.configuration';
import { WindowService } from '@zipari/web-services';

const validNavigationSegments = [
  'individual',
  'medicare',
  'small-group',
  'member-360',
];

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public buttonClicked = new Subject();
  public toolbarConfig: Toolbar;

  // make config type from NavigationConfig to any Because ngFor only supports binding to iterables such as array
  public config: any;
  public navigationConfig: any;

  public mobileMenuOpen = false;

  public activeRoute: NavItem;
  public activeRouteIdx = 0;

  public isNavHidden = false;

  // ERHO: TODO: make this an observable for components to subscribe to to watch for changes
  public showNavLinks = true;

  constructor(
    private configService: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    public analyticsService: AnalyticsService,
    private windowService: WindowService,
  ) {}

  public getNavHidden(): boolean {
    return this.isNavHidden;
  }

  public setNavHidden(value: boolean): void {
    this.isNavHidden = value;
  }

  initConfig(config: any): void {
    // TODO test additional conditions
    this.toolbarConfig = this.configService.configs['global']['toolbar'];
    this.navigationConfig = this.configService.configs['global']['navigation'];

    if (this.toolbarConfig) {
      const routeSegment: string = this.router.parseUrl(this.router.url).root
        .children.primary?.segments[1]?.path;

      if (validNavigationSegments.includes(routeSegment)) {
        this.toolbarConfig.routes.forEach((route, index) => {
          if (route.prop === routeSegment) {
            this.selectRoute(route, index);
          }
        });
      } else {
        const globalConfig: any = this.configService.getPageConfig('global');

        this.config = globalConfig?.navigation;
      }
    } else {
      this.config = config; // this.configService.configs['navigation'];
    }
    /* eslint-disable no-prototype-builtins */
    if (!this.config?.hasOwnProperty('showHamburger')) {
      if (this.config) this.config['showHamburger'] = true;
    }
  }

  setConfig(): void {
    if (this.toolbarConfig) {
      this.config =
        this.configService.configs[this.configService.activeRoute]?.[
          'navigation'
        ];
      if (this.config) this.config.toolbar = this.toolbarConfig;
    } else {
      this.config = this.configService.configs['navigation'];
    }
  }

  selectNavLink(event, link): void {
    event.stopPropagation();

    if (CX_CALLS[link.cx_event_key]) {
      this.analyticsService.sendEvent(CX_CALLS[link.cx_event_key].event_key);
    }

    if (link.route) {
      this.mobileMenuOpen = false;

      this.router.navigate([link.route]);
    }

    /*
     * ERHO: TODO: Consider using an external URL redirect for routing
     */
    if (link.externalUrl) {
      this.windowService.nativeWindow.open(link.externalUrl, '_blank');
    }

    if (link.items) {
      if (!link.open) {
        this.config?.links?.forEach((_link: any) => {
          _link.open = false;
        });
      }
      link.open = !link.open;
    }

    /** Handle Django routes **/
    if (link.isDjangoRoute) {
      this.mobileMenuOpen = false;
      this.windowService.nativeWindow.location.assign(link.djangoRoute);
    }
  }

  navigateSelected(route, index): void {
    this.router.navigate([route.route]);
    this.selectRoute(route, index);
  }

  selectRoute(route, index): void {
    this.activeRoute = route;
    this.activeRouteIdx = index;

    this.configService.activeRoute = route.prop;

    if (
      !!this.navigationConfig &&
      !!this.navigationConfig.routeSegmentForLogo
    ) {
      this.configService.activeRouteSegmentForLogo =
        this.navigationConfig.routeSegmentForLogo;
    }

    this.setConfig();
  }

  isActiveRoute(route): boolean {
    const urlToCheck = this.router.url.includes(route)
      ? this.router.url
      : route;

    return this.router.isActive(urlToCheck, {
      paths: 'exact',
      queryParams: 'exact',
      matrixParams: 'ignored',
      fragment: 'ignored',
    });
  }

  toggleMobileMenu(): void {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }

  toggleShowNavLinks(bool: boolean): void {
    this.showNavLinks = bool;
  }
}
