import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'add-labels-notes-modal',
  templateUrl: './add-labels-notes-modal.component.html',
  styleUrls: ['./add-labels-notes-modal.component.scss'],
})
export class AddLabelsNotesModalComponent {
  @Input() addingLabelsNotes = false;
  @Output() addingLabelsNotesChange: EventEmitter<boolean> = new EventEmitter();
  @Output() addLabelsClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() addNotesClicked: EventEmitter<boolean> = new EventEmitter();

  cancel() {
    this.addingLabelsNotes = false;
    this.addingLabelsNotesChange.emit(false);
  }

  handleAddLabels() {
    this.addLabelsClicked.emit(true);
  }

  handleAddNotes() {
    this.addNotesClicked.emit(true);
  }
}
