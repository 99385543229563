import { ModelAttributeConfig } from '@zipari/shared-ds-util-configuration';

export function setFormatOption(
  attributes: ModelAttributeConfig[],
  prop: string,
  key: string,
): any {
  attributes?.forEach((attribute: any, index: number) => {
    if (attribute?.prop === prop) {
      attributes[index] = {
        ...attribute,
        formatOptions: {
          mapping: key,
        },
      };
    }
  });
}
