import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TableComponent, ColumnConfig } from '@zipari/design-system';
import { APIService } from '@zipari/web-services';
import { lastValueFrom, Subject, takeUntil, tap } from 'rxjs';
import { isEqual } from 'lodash';

import { CX_CALLS } from '../../../../../shared/constants/cx-calls.constant';
import { AnalyticsService } from '../../../../../shared/services/analytics.service';
import { ConfigService } from '../../../../../shared/services';
import { SiteLocationsProfileHttpService } from '../../shared/services/site-locations-profille-http.service';
import {
  BUTTON_TYPE,
  BUTTON_TYPE_NAME,
  SITELOCATION_CONSTANTS,
} from '../site-location-attestation.constant';
import SiteLocationAttestationEventService from '../site-location-attestation.event.service';
import {
  AdditionalDetailsModalConfig,
  ConfirmationResponse,
  Practitioner,
  PractitionerDetails,
  ReviewPractitionersConfig,
} from '../site-locations-attestation.model';
import { getCurrentDate } from '../../../../../shared/utilities/dates';
import SiteAttestationDataService from '../site-location-attestation-data.service';
import { ProviderUpdateToolService } from '../../provider-update-tool.service';
import { PractitionersEditService } from '../../shared/services/practitioners-edit.service';
import { camelCase } from '../../../../../shared/utilities/case-conversion.utils';
@Component({
  selector: 'practitioners-list',
  templateUrl: './practitioners-list.component.html',
  styleUrls: ['./practitioners-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PractitionersListComponent implements OnInit, OnDestroy {
  @ViewChild(TableComponent) practitionersTable: TableComponent;
  config: ReviewPractitionersConfig;
  id: string;
  showTable: boolean;
  showTerminateModal: boolean;
  terminatePractitionersList: { row: Practitioner }[];
  terminateDetailsForm = new UntypedFormGroup({});
  error = false;
  isTerminationFormSubmitted = false;
  showEditModal = false;
  showAdditionalDetailsModal = false;
  openAccordionIdx = -1;
  editPractitionerForm = new UntypedFormGroup({});
  additionalDetailsForm: UntypedFormGroup = new UntypedFormGroup({});
  payload = {};
  selectedPractitionerId: string;
  hasEditFormControlsChanged = false;
  editFormInitialValue;
  practitionersDetails: PractitionerDetails | ConfirmationResponse;
  siteLocationMessage: string;
  private destroy$ = new Subject();

  constructor(
    private configService: ConfigService,
    private route: ActivatedRoute,
    private siteLocationsProfileHttpService: SiteLocationsProfileHttpService,
    private siteLocationAttestationEventService: SiteLocationAttestationEventService,
    public analyticsService: AnalyticsService,
    private apiService: APIService,
    private siteAttestationDataService: SiteAttestationDataService,
    public providerUpdateToolService: ProviderUpdateToolService,
    private practitionersEditService: PractitionersEditService,
  ) {}

  get additionalDetailsModalConfig() {
    return this.config?.editPractitioner?.additionalDetailsModal;
  }

  ngOnInit(): void {
    const pageName = this.route.snapshot?.data[SITELOCATION_CONSTANTS.pageName];
    const siteLocationConfig = this.configService.getPageConfig(
      SITELOCATION_CONSTANTS.siteLocationsAttestation,
    );

    this.config = siteLocationConfig ? siteLocationConfig[pageName] : {};
    this.id = this.route.snapshot?.params?.id;
    if (this.config) {
      this.config.submitButton[BUTTON_TYPE] = BUTTON_TYPE_NAME.SUBMIT;
      this.siteLocationAttestationEventService.emitDynamicButtonConfig(
        this.config.submitButton,
      );
    }
    this.analyticsService.sendEvent(
      CX_CALLS.pp_practitioners_reviewed.event_key,
    );
    this.updateEndpoint();
  }

  updateEndpoint(): void {
    if (this.config?.table?.endpoint) {
      const updatedEndpoint = this.siteLocationsProfileHttpService.setParameter(
        this.config.table.endpoint,
        'id',
        this.id,
      );

      this.config.table.endpoint = `${updatedEndpoint}?is_terminated=false`;
      this.showTable = true;
    }
  }

  terminatePractitionerClicked(
    selectedPractitioners: { row: Practitioner }[],
  ): void {
    this.showTerminateModal = true;
    this.terminatePractitionersList = selectedPractitioners;
  }

  formCreated(): void {
    this.terminateDetailsForm
      .get(SITELOCATION_CONSTANTS.deactivationDate)
      ?.setValue(getCurrentDate());
  }

  handleModalCancel(): void {
    this.showTerminateModal = false;
    this.isTerminationFormSubmitted = false;
  }

  removePractitioner(index: number): void {
    this.terminatePractitionersList.splice(index, 1);
  }

  isTerminateDetailsFormInvalid(): boolean {
    return !(
      this.terminateDetailsForm.valid && this.terminatePractitionersList?.length
    );
  }

  async terminatePractitioner(): Promise<void> {
    if (this.isTerminateDetailsFormInvalid()) return;
    if (this.terminateDetailsForm?.value) {
      const payload =
        this.siteAttestationDataService.createTerminatePractitionerPayload(
          this.terminatePractitionersList,
          this.terminateDetailsForm,
        );

      try {
        await lastValueFrom(
          this.apiService.post(
            this.config.terminatePractitioner.endpoint,
            payload,
          ),
        );
        this.practitionersTable.unselectAllRows();
        this.practitionersTable.refresh();
        this.isTerminationFormSubmitted = true;
      } catch (error) {
        this.handleModalCancel();
        this.toggleError();
      }
    }
  }

  toggleError(): void {
    this.error = !this.error;
  }

  toggleEdit(): void {
    this.showEditModal = !this.showEditModal;
    if (!this.showEditModal) {
      this.editFormInitialValue = null;
    }
  }

  async editPractitionerClicked(event: {
    context: { row: Practitioner; col: ColumnConfig };
    action: string;
  }): Promise<void> {
    if (event?.action?.toLowerCase() === 'edit') {
      this.selectedPractitionerId = event.context?.row?.provider_id;
      const practitionerDetails = await lastValueFrom(
        this.siteAttestationDataService.getPractitionersDetail(
          event.context?.row?.provider_id,
          this.config,
        ),
      );
      const updatedPractitionerDetails = camelCase(practitionerDetails);

      this.providerUpdateToolService.setFormGroup(
        SITELOCATION_CONSTANTS.editPractitionerForm,
        this.editPractitionerForm,
      );
      this.config =
        this.practitionersEditService.updateEditPractitionerControls(
          this.config,
          updatedPractitionerDetails,
        );
      this.showEditModal = true;
    }
  }

  editSubmit(additionalDetailsModalConfig: AdditionalDetailsModalConfig): void {
    if (additionalDetailsModalConfig?.additionalDetailsForm) {
      if (
        additionalDetailsModalConfig.enableEffectiveDate ||
        additionalDetailsModalConfig.enableNotes
      ) {
        this.showAdditionalDetailsModal = true;
        this.showEditModal = false;
      } else {
        this.editPractitioner();
      }
    } else {
      this.editPractitioner();
    }
  }

  filterAdditionalDetails(additionalDetails: any[], prop: string): any[] {
    return additionalDetails?.filter((control: any) => control.prop !== prop);
  }

  cancelEdit(): void {
    this.showAdditionalDetailsModal = false;
    this.showEditModal = true;
  }

  async editPractitioner(): Promise<void> {
    this.setEditPractitionerPayload();
    if (!this.isEditPractitionerDisabled()) {
      try {
        const editResponse = await lastValueFrom(
          this.apiService.patch(
            `${this.config.editPractitioner?.endpoint}${this.selectedPractitionerId}/`,
            this.payload,
          ),
        );

        this.practitionersDetails = camelCase(editResponse);
        this.siteLocationMessage =
          this.practitionersDetails[
            this.config?.confirmationLocationProp
          ]?.toString();

        this.practitionersTable.refresh();
        this.hasEditFormControlsChanged = false;
        if (this.additionalDetailsForm) {
          this.additionalDetailsForm.reset();
        }
        this.showAdditionalDetailsModal = false;
      } catch (error) {
        this.toggleError();
        this.toggleEdit();
        this.showAdditionalDetailsModal = false;
      }
    }
  }

  setEditPractitionerPayload(): void {
    this.payload = this.practitionersEditService.setEditPractitionerPayload(
      this.additionalDetailsForm,
    );
    this.payload['id'] = this.selectedPractitionerId;
  }

  editDropdownControlsCreated(): void {
    if (!this.editFormInitialValue) {
      this.editFormInitialValue = {
        ...this.providerUpdateToolService.getFormGroup(
          SITELOCATION_CONSTANTS.editPractitionerForm,
        )?.value,
      };
      this.hasEditFormControlsChanged = false;
    }
    this.openAccordionIdx = 0;

    const editFormGroup = this.providerUpdateToolService.getFormGroup(
      SITELOCATION_CONSTANTS.editPractitionerForm,
    );

    if (editFormGroup) {
      editFormGroup.valueChanges
        .pipe(
          takeUntil(this.destroy$),
          tap((value) => {
            if (this.editFormInitialValue) {
              this.hasEditFormControlsChanged =
                Object.keys(this.editFormInitialValue).some(
                  (key) => !isEqual(value[key], this.editFormInitialValue[key]),
                ) && editFormGroup.dirty;
            }
          }),
        )
        .subscribe();
    }
  }

  isEditPractitionerDisabled(): boolean {
    return (
      !this.hasEditFormControlsChanged ||
      !this.providerUpdateToolService.getFormGroup(
        SITELOCATION_CONSTANTS.editPractitionerForm,
      )?.valid
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
