import { Injectable } from '@angular/core';
import { cloneObject, isEmptyObj } from '@zipari/web-utils';

import Plan from '../models/shared/Plan.model';

import { validGAEvents } from '../constants/analytics';

// eslint-disable-next-line no-shadow
export enum GAEcommerceKeys {
  productClick = 'productClick',
  addToCart = 'addToCart',
  removeFromCart = 'removeFromCart',
  productImpressions = 'productImpressions',
  checkout = 'checkout',
}

// model pulled from
// https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#ecommerce-data
export class productFieldObject {
  id: string;
  name: string;
  brand?: string;
  category?: string;
  variant?: string;
  price?: number;
  quantity?: number = 1;
  coupon?: string;
  position?: number;
  list?: string;

  constructor(options = {}) {
    Object.assign(this, options);
  }
}

// model pulled from
// https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#ecommerce-data
// and https://developers.google.com/tag-manager/enhanced-ecommerce#product-clicks
export class productObj {
  id: string;
  name: string;
  list?: string;
  brand?: string;
  category?: string;
  variant?: string;
  position?: number;
  price?: number;

  constructor(options = {}) {
    Object.assign(this, options);
  }
}

export class GAEventOptions {
  eventCategory: string;
  eventAction: string;
  eventLabel: string = null;
  eventValue: any = null;
  hitType = 'event';

  constructor(options) {
    if (options) {
      const newOptions = cloneObject(options);

      if (newOptions.eventLabel) {
        this.eventLabel = newOptions.eventLabel;
      } else {
        console.warn('No key provided for GA');
      }

      if (newOptions.dictionary_attributes.eventCategory) {
        this.eventCategory = newOptions.dictionary_attributes.eventCategory;
        delete newOptions.dictionary_attributes.eventCategory;
      } else {
        this.eventCategory = this.eventLabel;
      }

      if (newOptions.dictionary_attributes.eventAction) {
        this.eventAction = newOptions.dictionary_attributes.eventAction;
        delete newOptions.dictionary_attributes.eventAction;
      } else {
        this.eventAction = this.eventLabel;
      }

      this.eventValue = 0;
    } else {
      console.warn('No options provided for GA call');
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  shoppingCart: productFieldObject[] = [];

  public emitGAEvent(key: validGAEvents, dictionary_attributes: any = {}) {
    if (window['dataLayer']) {
      const clonedDictionaryAttributes = cloneObject(dictionary_attributes);

      if (
        key === validGAEvents.virtualPageView &&
        clonedDictionaryAttributes.plans &&
        !isEmptyObj(clonedDictionaryAttributes.plans)
      ) {
        clonedDictionaryAttributes['products'] = this.formatProductObj(
          clonedDictionaryAttributes.plans,
        );
      }

      if (GAEcommerceKeys[key]) {
        let ecommerce = this.formatEcommerceEvent(key, dictionary_attributes);

        // we have to make a deep copy of the ecommerce object because having it as a JS object doesn't come up
        // correctly in GA
        ecommerce = cloneObject(ecommerce);

        dictionary_attributes = { context: dictionary_attributes.context };
        Object.assign(dictionary_attributes, ecommerce);

        Object.assign(clonedDictionaryAttributes, {
          context: dictionary_attributes.context,
        });
        Object.assign(clonedDictionaryAttributes, ecommerce);

        const zipariBusinessObjectsToRemoveFromDictionaryAttributes: string[] =
          ['plans'];

        // eslint-disable-next-line no-shadow
        zipariBusinessObjectsToRemoveFromDictionaryAttributes.forEach(
          (key: string) => {
            if (clonedDictionaryAttributes[key]) {
              delete clonedDictionaryAttributes[key];
            }
          },
        );
      }

      const finalDatalayerData = {
        event: key,
        ...clonedDictionaryAttributes,
      };

      window['dataLayer'].push(finalDatalayerData);
    }

    if (typeof gtag !== 'undefined') {
      gtag('event', key, dictionary_attributes);
    }

    if (typeof ga !== 'undefined') {
      const formattedOptions = new GAEventOptions({
        eventLabel: key,
        dictionary_attributes: dictionary_attributes,
      });

      ga('send', formattedOptions);
    }
  }

  public formatEcommerceEvent(eventKey: string, context: any) {
    const ecommerceStructure = {
      ecommerce: {},
    };

    switch (eventKey) {
      case GAEcommerceKeys.removeFromCart:
        ecommerceStructure.ecommerce['remove'] =
          this.formatAddRemoveEvent(context);
        break;
      case GAEcommerceKeys.addToCart:
        ecommerceStructure.ecommerce['add'] =
          this.formatAddRemoveEvent(context);
        break;
      case GAEcommerceKeys.productClick:
        ecommerceStructure.ecommerce['click'] = {};
        ecommerceStructure.ecommerce['click'].products = this.formatProductObj([
          context,
        ]);
        break;
      case GAEcommerceKeys.productImpressions:
        ecommerceStructure.ecommerce['impressions'] = this.formatProductObj(
          context.plans,
        );
        break;
      case GAEcommerceKeys.checkout:
        ecommerceStructure.ecommerce['checkout'] = {};
        ecommerceStructure.ecommerce['checkout'].products =
          this.formatProductObj(context.plans);
        break;
    }

    return ecommerceStructure;
  }

  public formatProductObj(plans: any): productObj[] {
    if (Array.isArray(plans)) {
      return plans.map(
        (currentPlan: Plan) =>
          new productObj(this.productObjFromPlan(currentPlan)),
      );
    } else {
      return Object.keys(plans).map((planType: string) => {
        const currentPlan: Plan = plans[planType];

        return this.productObjFromPlan(currentPlan);
      });
    }
  }

  private productObjFromPlan(plan: Plan): productObj {
    return cloneObject(
      new productObj({
        id: plan.external_id,
        name: plan.display_name,
        category: plan.hmo ? 'HMO' : 'PPO',
        list: this.determinePlanType(plan.plan_type),
        price: plan.price,
        position: plan.index,
      }),
    );
  }

  formatAddRemoveEvent(plan: Plan) {
    return {
      actionField: this.retrieveActionField(plan.plan_type),
      products: this.formatProductObj([plan]),
    };
  }

  retrieveActionField(plan_type) {
    return {
      list: this.determinePlanType(plan_type),
    };
  }

  determinePlanType(planType: string): string {
    switch (planType) {
      case 'bundled':
        return 'Bundled';
      case 'medical':
        return 'Medical Plans';
      case 'dental':
        return 'Dental Plans';
      case 'vision':
        return 'Vision Plans';
    }
  }
}
