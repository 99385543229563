import { Component, Input, OnInit } from '@angular/core';
import linkifyStr from 'linkifyjs/string';

import { Subscription } from 'rxjs';

import { APIService } from '@zipari/web-services';
import { OutOfNetworkConfig } from '../../../authorization-submission/authorization-submission.model';
import { ProviderUpdateToolService } from '../../../provider-update-tool/provider-update-tool.service';

@Component({
  selector: 'flyout-dropdown',
  templateUrl: './flyout-dropdown.component.html',
  styleUrls: ['./flyout-dropdown.component.scss'],
})
export class FlyoutDropdownComponent implements OnInit {
  @Input() config: any;
  @Input() group: any;
  @Input() openOnClick: boolean;
  @Input() configNoSpace: string;
  @Input() outOfNetwork: OutOfNetworkConfig;

  showModal = false;
  selectedItem: any = null;
  busy: Subscription;
  loaded = false;
  noSpace = 'n/a';
  showOutOfNetworkModal: boolean;
  constructor(
    public mp: ProviderUpdateToolService,
    private api: APIService,
  ) {}

  get showOutOfNetworkStatusPill(): boolean {
    return (
      this.outOfNetwork?.isOutOfNetworkEnabled &&
      this.group?.get(this.config?.prop).value?.member_network
        ?.is_in_network === false
    );
  }

  ngOnInit() {
    if (this.openOnClick) {
      this.toggleFlyout();
    }
    if (this.config?.subheader) {
      const options = {
        /* … */
      };

      this.config.subheader = linkifyStr(this.config.subheader, options);
    }
    if (this.configNoSpace) this.noSpace = this.configNoSpace;
    if (this.config?.flyout?.table?.filters) {
      const filters: any[] = this.config?.flyout?.table?.filters;
      let hasNoEndpoints = true;

      for (const filter of filters) {
        if (filter.type === 'select' && filter.apiEndpoint) {
          hasNoEndpoints = false;
          this.busy = this.api
            .get(filter.apiEndpoint)
            .subscribe((resp: any) => {
              filter.options = this.populateDropdown(
                resp,
                filter.displayProp,
                filter.jsonValue,
              );
              this.loaded = true;
            });
        }
      }
      if (hasNoEndpoints) this.loaded = true;
    }
    const flyoutConfig = this.config?.flyout;
    const shouldUpdateEndpoint =
      flyoutConfig?.table &&
      this.outOfNetwork?.isOutOfNetworkEnabled &&
      flyoutConfig?.table.outOfNetworkApiEndpoint;

    if (shouldUpdateEndpoint) {
      this.config.flyout.table = {
        ...this.config.flyout.table,
        endpoint: this.config.flyout.table.outOfNetworkApiEndpoint,
      };
    }
  }

  populateDropdown(resp: any, labelProp: string, valueProp: string) {
    const options = [];

    resp.results.forEach(function (record, index) {
      if (valueProp) {
        options.push({
          label: record[labelProp],
          value: record[valueProp],
        });
      } else {
        options.push({
          label: record[labelProp],
          value: record,
        });
      }
    });

    return options;
  }

  toggleFlyout() {
    this.showModal = !this.showModal;
    this.selectedItem = null;
  }

  remove() {
    this.group?.get(this.config?.prop)?.reset();
  }

  selectedRadioRow(event: any) {
    this.selectedItem = event;
  }

  add() {
    const shouldShowOutOfNetworkModal =
      this.outOfNetwork?.isOutOfNetworkEnabled &&
      this.selectedItem?.member_network?.is_in_network === false;

    if (shouldShowOutOfNetworkModal) {
      this.showOutOfNetworkModal = true;
    } else if (this.selectedItem) {
      this.group?.get(this.config.prop).setValue(this.selectedItem);
      this.toggleFlyout();
    }
  }
  selectedProvider(provider: any): void {
    if (provider?.member_network?.is_in_network === false) {
      this.selectedItem = provider;
      this.showOutOfNetworkModal = true;
    }
  }
  confirm(): void {
    this.group?.get(this.config?.prop).setValue(this.selectedItem);
    this.showModal = false;
    this.showOutOfNetworkModal = false;
    this.selectedItem = null;
  }

  closeOutOfNetworkModal(): void {
    this.showOutOfNetworkModal = false;
    this.group?.get(this.config?.prop).setValue(null);
  }
}
