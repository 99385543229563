import { formatTypes } from '../../../services/formatting.service';

export type CurrencyDisplay =
  | 'code'
  | 'symbol'
  | 'symbol-narrow'
  | string
  | boolean;

export enum DateDisplay {
  short,
  medium,
  long,
  full,
  shortDate,
  mediumDate,
  longDate,
  fullDate,
}

export class CellTypeFullNameFormatOption {
  nameTemplate?: string;
}

export class CellTypeAddressFormatOption {
  addressTemplate?: string;
}

export class CellTypeDateCurrencyFormatOption {
  currencyCode?: string;
  display: CurrencyDisplay = 'symbol';
  digitsInfo?: string;
  locale?: string;
}
export class CellTypeListFormatOption {
  mapping?: string;
}

export class CellTypeDateFormatOption {
  format: DateDisplay = DateDisplay.mediumDate;
}

export class Column2 {
  accessibilityMessage?: string;
  format: formatTypes;
  formatOptions?:
    | CellTypeFullNameFormatOption
    | CellTypeAddressFormatOption
    | CellTypeDateCurrencyFormatOption
    | CellTypeDateFormatOption;

  /**
   * String property that points to the data object that we are using for this column
   * can be deeply nested into the object with . delimination
   *
   * e.g:
   * prop value to see propValue2
   * {prop: {sub_prop: [propValue1, propValue2]}}
   * prop: 'prop.sub_prop.1'
   */
  prop: string;

  /**
   * String property that provides a static value that all cells within this column will contain.
   */
  text?: string;

  /**
   * Name of the column to be displayed in the header cell
   */
  name: string;

  /**
   * Relative width of the column
   */
  width?: number = 1;

  /**
   * priority this column should have in relation to other columns
   */
  priority?: number = 0;
  /**
   * Tooltip to display for this specific column
   */
  tooltip?: string;

  /*
   * Component to inject for proper display of non-trivial data
   */
  customComponentName?: string;

  /*
   * Map of custom options to pass in to component
   */
  customOptions?: any;

  /*
   * Determines whether this field can be sorted upon
   */
  sortable = true;

  /*
   * Icon to display next to cell value
   */
  icon: string;

  /*
   * Actions to display within a table
   */
  actions?: [];

  /** a prop to use for specifically sorting */
  sortByProp: string;

  /** Flag to determine success vs error text */
  successText?: string;

  /** INTERNAL USE: the original index within the columns array */
  originalIndex?;

  /** INTERNAL USE: A fake header that is used to do some displaying */
  mockProp?;

  /** INTERNAL USE: A fake header that is used to do some displaying */
  mockLabel?;

  constructor(options: any, originalIndex?) {
    Object.assign(this, options);
    this.originalIndex = originalIndex;
  }
}
