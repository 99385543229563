import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { controlTypes } from '@zipari/shared-ds-util-form';
import { AuthService } from '../../../../../shared/services';
import { AppealConstant, ClaimAppealProps } from '../../claim-appeal.constants';
import {
  populateDropdown,
  prePopulateFormFields,
} from '../../claim-appeal.helper';
import {
  ControlConfiguration,
  EntryCardConfig,
} from '../../claim-redetermination/claim-redetermination.model';
import ClaimAppealDataService from '../../services/claim-appeal-data.service';
import ClaimAppealEventService from '../../services/claim-appeal-event.service';

@Component({
  selector: 'entry-form',
  templateUrl: './entry-form.component.html',
  styleUrls: ['./entry-form.component.scss'],
})
export class EntryFormComponent implements OnInit {
  @Input() config: EntryCardConfig;
  @Input() claimId: string;
  @Input() appealForm: UntypedFormGroup;
  @Input() hasReturnedFromReviewPage: boolean;

  facilityNameControl: ControlConfiguration;
  providerNameControl: ControlConfiguration[] = [];

  constructor(
    public formControlService: FormControlService,
    private claimAppealEventService: ClaimAppealEventService,
    private authService: AuthService,
    private claimAppealDataService: ClaimAppealDataService,
  ) {}

  ngOnInit(): void {
    if (this.config && !this.hasReturnedFromReviewPage) {
      this.setFormControls();
    }

    this.emitDynamicButtonConfig();
  }

  setFormControls(): void {
    const { billingProviderInformation, providerInformation } =
      ClaimAppealProps;

    Object.keys(this.config.cards)?.forEach((card: string) => {
      this.config.cards[card]['form']?.['controls']?.forEach(
        (control: ControlConfiguration) => {
          if (
            card === billingProviderInformation &&
            control?.[providerInformation]
          ) {
            this.formControlService.addControlToFormGroup(
              this.appealForm,
              control[providerInformation],
            );
          } else {
            this.updateCardControls(control);
          }
          prePopulateFormFields(
            this.authService.loggedInUser.app_user_data,
            this.appealForm,
          );
        },
      );
    });
  }

  updateCardControls(control: ControlConfiguration): void {
    const {
      facilityName,
      providerFirstName,
      providerLastName,
      reasonOfLateFiling,
      service,
      disputeTypeOther,
    } = ClaimAppealProps;

    if (![reasonOfLateFiling, disputeTypeOther].includes(control.prop)) {
      if (control.prop === service) {
        this.updateServiceOptions(control);
      } else if (
        [facilityName, providerFirstName, providerLastName].includes(
          control.prop,
        )
      ) {
        control.prop === facilityName
          ? (this.facilityNameControl = control)
          : this.providerNameControl.push(control);
      } else {
        if (
          control.options &&
          control.prop !== ClaimAppealProps.sameAsRequestor &&
          control.type !== controlTypes.radio
        ) {
          control.options = populateDropdown(
            control.options,
            AppealConstant.label,
          );
        }
        this.formControlService.addControlToFormGroup(this.appealForm, control);
      }
    }
  }

  emitDynamicButtonConfig(): void {
    if (this.config) {
      this.claimAppealEventService.emitDynamicButtonConfig(
        this.config.nextButton,
      );
    }
  }

  updateServiceOptions(control: ControlConfiguration): void {
    this.claimAppealDataService
      .getClaimLines(this.claimId)
      .subscribe((claimLines) => {
        control.options = populateDropdown(
          claimLines,
          AppealConstant.typeOfServiceDescription,
        );
        this.formControlService.addControlToFormGroup(this.appealForm, control);
      });
  }
}
