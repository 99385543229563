import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AttachmentMetaData } from '../../../../shared/models/shared/Document.model';
import { ClaimAppealProps } from '../claim-appeal.constants';

@Injectable({
  providedIn: 'root',
})
export class DocumentUploadService {
  uploadedFiles: AttachmentMetaData[] = [];
  uploadStarted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  uploadProgress$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  uploadCompleted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  uploadError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  uploadAttachmentSubscription: Subscription;
  fileAttachments: File[] = [];

  constructor(private http: HttpClient) {}

  uploadFile(
    fileList: File[],
    documentUploadUrl: string,
    documentCategory: string,
  ) {
    this.uploadAttachmentSubscription = this.uploadAttachments(
      fileList,
      documentUploadUrl,
      documentCategory,
    )?.subscribe({
      next: (response: HttpEvent<AttachmentMetaData>) => {
        this.getActionFromHttpEvent(response);
        this.uploadError$.next(false);
      },
      error: (error) => {
        this.uploadError$.next(true);

        return error;
      },
    });
  }

  uploadAttachments(
    fileList: File[],
    documentUploadUrl: string,
    documentCategory: string,
  ): Observable<HttpEvent<AttachmentMetaData>> {
    const lastUploadedFile = [fileList[fileList.length - 1]];

    const formData = new FormData();

    formData.append('file', lastUploadedFile[0]);
    if (documentCategory) {
      formData.append(ClaimAppealProps.documentCategory, documentCategory);
    }

    return this.http.request(
      new HttpRequest('POST', `${documentUploadUrl}`, formData, {
        reportProgress: true,
      }),
    );
  }

  getActionFromHttpEvent(event: HttpEvent<AttachmentMetaData>): void {
    const successStatus = 200;
    const percentageConverter = 100;

    switch (event.type) {
      case HttpEventType.Sent: {
        this.uploadStarted$.next(true);
        break;
      }
      case HttpEventType.UploadProgress: {
        if (event.total) {
          this.uploadProgress$.next(
            Math.round((percentageConverter * event.loaded) / event.total),
          );
        }
        break;
      }
      case HttpEventType.ResponseHeader:
      case HttpEventType.Response: {
        if (event.status === successStatus) {
          if (event['body']) {
            this.uploadedFiles.push(event['body']);
            this.uploadError$.next(false);
            this.uploadCompleted$.next(true);
          }
        } else {
          this.uploadError$.next(true);
        }
        break;
      }
      default: {
        this.uploadCompleted$.next(true);
      }
    }
  }

  removeFile(fileId: number): void {
    this.uploadedFiles.splice(fileId, 1);
    this.fileAttachments.splice(fileId, 1);
  }
}
