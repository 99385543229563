import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import {
  getPaginatorConfig,
  PaginatorConfiguration,
} from '@zipari/design-system';
import { determineSortOrder } from '@zipari/shared-util-common';
import { ErrorEmptyConfig } from '@zipari/shared-ds-util-form';
import { APIResponse } from '@zipari/web-services';
import { catchError, Observable, tap, throwError } from 'rxjs';

import {
  DatagridRowSelectedEvent,
  ProviderAdmin,
  ProviderAdminListConfig,
  ProviderAdminSearchParams,
} from '../provider-admins.model';
import { ProviderAdminListService } from '../services/provider-admins-list.service';
import {
  PROVIDER_LIST_CONSTANTS,
  InitialValueProviderAdminSearchParams,
} from '../provider-admins.constants';
import { ProviderPortalManageUserDetails } from '../../../../app.constants';
import { ManagePortalUsersDetailConstant } from '../../manage-portal-users-detail/manage-portal-users-details.constant';

@Component({
  selector: 'provider-admins-list',
  templateUrl: './provider-admins-list.component.html',
  styleUrls: ['./provider-admins-list.component.scss'],
})
export class ProviderAdminsListComponent implements OnInit {
  @Input() config: ProviderAdminListConfig;
  pageSize: number = PROVIDER_LIST_CONSTANTS.defaultPageSize;
  paginator: PaginatorConfiguration;
  currentPage = 1; // initialize the view on the first page
  public showError = false;
  errorMessageConfig: ErrorEmptyConfig;
  sortKey: string;
  adminList$: Observable<APIResponse<ProviderAdmin>>;
  filterForm: UntypedFormGroup = new UntypedFormGroup({});
  providerAdminSearchParams: ProviderAdminSearchParams =
    InitialValueProviderAdminSearchParams;

  constructor(
    private providerAdminListService: ProviderAdminListService,
    private router: Router,
  ) {}

  get disableSearch(): boolean {
    return !(
      this.filterForm.controls.taxId?.value ||
      this.filterForm.controls.emailAddress?.value
    );
  }

  ngOnInit(): void {
    this.pageSize = this.config?.pageSize;
    this.paginator = getPaginatorConfig({}, this.pageSize, this.currentPage, 0);
    this.getProviderAdmins();
  }

  getProviderAdmins(
    providerAdminSearchParams: ProviderAdminSearchParams = InitialValueProviderAdminSearchParams,
  ): void {
    this.paginator = { ...this.paginator, currentPage: this.currentPage };
    this.adminList$ = this.providerAdminListService
      .getProviderAdminList(
        this.config?.endpoint,
        this.paginator,
        providerAdminSearchParams,
        this.sortKey,
      )
      .pipe(
        tap((admin) => {
          this.paginator = getPaginatorConfig(
            {},
            this.pageSize,
            this.currentPage,
            admin.count,
          );
        }),
        catchError((error) => {
          const errorMessage = error.error?.errors[0]?.user_error;
          const isDataNotFound =
            error.status === 404 &&
            errorMessage &&
            typeof errorMessage === 'string' &&
            errorMessage.includes('No match was found');

          this.showError = true;
          if (isDataNotFound) {
            this.errorMessageConfig = this.config.noDataMessage;
          } else {
            this.config.errorMessage.description =
              errorMessage || PROVIDER_LIST_CONSTANTS.defaultErrorMessage;
            this.errorMessageConfig = this.config.errorMessage;
          }

          return throwError(() => new Error(errorMessage));
        }),
      );
  }

  setCurrentPage(page: number): void {
    const { taxId, emailAddress } = this.filterForm.value;

    this.currentPage = page;
    this.getProviderAdmins({ taxId, emailAddress });
  }

  sortProviderAdmins(columnProp: string): void {
    this.currentPage = 1;
    this.sortKey = determineSortOrder(this.sortKey, columnProp);
    this.getProviderAdmins();
  }

  searchProviderAdmin(): void {
    const { taxId, emailAddress } = this.filterForm.value;

    this.currentPage = 1;
    this.getProviderAdmins({ taxId, emailAddress });
  }

  handleInputChanged(): void {
    const { taxId, emailAddress } = this.filterForm.value;

    if (!taxId && !emailAddress && this.disableSearch) {
      this.searchProviderAdmin();
    }
  }

  navigateToDetail(row: DatagridRowSelectedEvent): void {
    if (row.uniqueCellProp === PROVIDER_LIST_CONSTANTS.firstName) {
      const targetRoute = `provider-portal/payer-admin/manage-portal-users/detail/${row.id}`;

      this.router.navigate([targetRoute]);
      localStorage.setItem(
        ManagePortalUsersDetailConstant.userNaviagteTo,
        ProviderPortalManageUserDetails.providerSearchAdmin,
      );
    }
  }
}
