import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewChildren,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { QueryList } from '@angular/core';
import { APIService } from '@zipari/web-services';
import { HeaderCellComponent } from '../header-cell/header-cell.component';
import { ItemCellComponent } from '../item-cell/item-cell.component';
import { ProvisionerService } from '../provisioner/provisioner.service';

interface ProvisionerColumnConfig {
  title: string;
  apiEndpoint: string;
  queryFieldName?: string;
  noResultsMessage: string;
  apiCommonUniqueField?: boolean;
  prop: any;
  value: any;
  errorMessage: string;
  exitQueryParam: string;
  search_form: any;
  display_fields: any;
  form_fields: any;
  loadOnStartUp: boolean;
  clickingEnabled?: boolean;
  totalSize: number;
}

interface Result {
  id: any;
  clicked: boolean;
  selected: boolean;
}

@Component({
  styleUrls: ['./provisioner-column.component.scss'],
  selector: 'provisioner-column',
  templateUrl: './provisioner-column.component.html',
})
export class ProvisionerColumnComponent implements OnInit, OnDestroy {
  @Input() column: number;
  @Input() config: ProvisionerColumnConfig;
  @Output() cellClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() cellSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() cellSearched: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(HeaderCellComponent) table: HeaderCellComponent;
  @ViewChildren('cells') items: QueryList<ItemCellComponent>;

  _bootStrapId: string;
  _state: string;
  apiEndpoint = '';
  api_call: Subscription;
  size: number;
  results: Result[];
  displayingCurrently: Result[];
  noData = false;
  noResultsMessage = 'No Data returned.';
  errorMessage = 'Something went wrong.';
  error = false;
  previousClick = -1;
  searching = false;
  card: any;
  onlyLoadWhen: any;

  constructor(
    private api: APIService,
    private provisioner: ProvisionerService,
  ) {}

  get bootStrapId() {
    return this._bootStrapId;
  }
  get state() {
    return this._state;
  }
  get setItemState() {
    return this.displayingCurrently?.length > 0 ? 'active' : 'inactive';
  }

  get setHeaderState() {
    return this.results?.length > 0 || this.searching ? 'active' : 'inactive';
  }

  // Decorated set definitions
  @Input() set bootStrapId(bootStrapId: string) {
    if (bootStrapId) {
      this._bootStrapId = bootStrapId;
    }
    if (this.apiEndpoint && bootStrapId) {
      this.refreshColumn(this.config.queryFieldName);
    }
  }
  @Input() set state(state: string) {
    this._state = 'active';
    if (state === 'inactive') {
      this.clearData();
    }
  }

  ngOnInit(): void {
    if (this.config) {
      // settings - check interace above
      this.apiEndpoint = this.config.apiEndpoint;
      this.noResultsMessage = this.config.noResultsMessage
        ? this.config.noResultsMessage
        : this.noResultsMessage;
      this.errorMessage = this.config.errorMessage
        ? this.config.errorMessage
        : this.errorMessage;
      this.size = this.config.totalSize;
      if (this.config.loadOnStartUp && this.bootStrapId) {
        this.refreshColumn(this.config.queryFieldName);
      }
      this.onlyLoadWhen = {
        prop: this.config.prop,
        value: this.config.value,
      };
      this.card = {
        display_fields: this.config.display_fields,
        form_fields: this.config.form_fields,
      };
    }
  }

  refreshColumn(queryParam = '', search = null) {
    if (this.apiEndpoint.includes('${user_name}')) {
      this.apiEndpoint = this.apiEndpoint.replace(
        '${user_name}',
        this.provisioner.getUserId(),
      );
    }

    let url = `${this.apiEndpoint}?page=1&page_size=${this.size}`;

    if (queryParam.length > 0) url += `&${queryParam}=${this.bootStrapId}`;
    this.searching = false;

    if (search) {
      this.searching = true;
      url += `&search=${search}`;
    }

    this.api_call = this.api.get(url)?.subscribe(
      (res: any) => {
        this.state = 'active';
        this.displayingCurrently = res.results;
        this.results = res.results;
        if (this.results.length === 0) this.noData = true;
        else this.noData = false;
        this.error = false;
      },
      (err: any) => {
        this.state = 'inactive';
        this.error = true;
      },
    );
  }

  filterResults(event: any) {
    this.previousClick = -1;
    this.refreshColumn(this.config.queryFieldName, event.text?.toLowerCase());
    this.cellSearched.emit(this.column);
  }

  clickedCell(row: number) {
    const currentClick: number = row;
    const payload: any = {
      column: this.column,
      row: row,
      data: this.displayingCurrently[row],
      param: this.displayingCurrently[row][this.config.exitQueryParam],
    };
    // only allow selection for cells that have an active tree lineage

    this.provisioner.setClicked(payload);

    // same cell is clicked no action is needed so return back
    if (this.previousClick === currentClick) {
      return;
    }

    // if its not the first click
    if (
      this.previousClick !== -1 &&
      this.displayingCurrently[this.previousClick]
    ) {
      // unclick the last clicked cell
      this.displayingCurrently[this.previousClick].clicked = false;
    }

    // if we have a valid clicked item
    if (currentClick > -1) {
      // record the click
      this.previousClick = currentClick;
      // mark the newly clicked item as clicked
      this.displayingCurrently[currentClick].clicked = true;
    }

    // let the parent component what was clicked, so it can load the next column
    this.cellClicked.emit(payload);
  }

  selectedCell(event: any) {
    event['column'] = this.column;
    this.cellSelected.emit(event);
  }

  selectCell(event: any) {
    this.displayingCurrently[event.row].selected = true;
  }

  ngOnDestroy() {
    if (this.api_call) {
      this.api_call.unsubscribe();
    }
  }

  public unselectColumn() {
    for (let i = 0; i < this.items.length; i++) {
      if (this.items.toArray()[i]) {
        this.items.toArray()[i].unselectCell();
      }
    }
  }

  public unclickColumn() {
    this.displayingCurrently.map((item: any) => {
      item.clicked = false;
    });
  }

  public clearData(): void {
    this.displayingCurrently = [];
    this.results = [];
    this.previousClick = -1;
    this.bootStrapId = null;
  }
}
