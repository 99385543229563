<article class="modal custom--modal modal--small" @fade>
  <header *ngIf="config.header" class="modal__header t-data">
    {{ config.header }}
  </header>

  <section class="modal__body t-data">
    {{ config.body | stringBuilder: context }}
  </section>

  <footer class="modal__footer">
    <p
      *ngIf="config.cancel"
      class="t-link t-data t-bold"
      (click)="cancel.next(null)"
    >
      Cancel
    </p>
    <p
      *ngIf="config.confirm"
      class="t-link t-data t-bold"
      (click)="confirm.next(null)"
    >
      {{ config.confirm }}
    </p>
  </footer>
</article>
