import { EventEmitter, Output, Component } from '@angular/core';
import { fade } from '../../../../shared/animations/fade';
import { TableService } from '../../services/table.service';

@Component({
  selector: 'table-header-actions',
  templateUrl: './table-header-action.component.html',
  styleUrls: ['./table-header-action.component.scss'],
  animations: [fade],
})
export class TableHeaderActionComponent {
  showMobileActionMenu: boolean;

  @Output() actionButtonClicked: EventEmitter<unknown> =
    new EventEmitter<unknown>();

  constructor(public ztService: TableService) {}

  public showAsyncExport(): boolean {
    const { paging, exportConfig } = this.ztService.zipTableOptions || {};

    return paging?.count > exportConfig?.exportAsyncLimit;
  }
}
