import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { cloneObject } from '@zipari/web-utils';
import { ZipTable2 } from '../../../models/zip-table.model';
import { Column2 } from '../../../models/column.model';
import { ZipTable2Service } from '../../../services/zip-table2.service';

@Component({
  selector: 'zip-table-group-header-row-2',
  templateUrl: './group-header-row.component.html',
})
export class GroupHeaderRowComponent {
  _columns = [];
  originalCols;

  // on resize make sure to reset the columns again
  @HostListener('window:resize')
  onWindowResize(): void {
    this.setColumns(this.cloneObject(this.originalCols));
  }

  @Input() data: any;
  @Input() options: ZipTable2;
  @Input() detailView;
  @Input() expanded;

  @Input() set columns(cols: Column2[]) {
    this.originalCols = this.cloneObject(cols);
    this.setColumns(this.cloneObject(cols));
  }

  setColumns(cols) {
    // in this specific case... we need to make sure that the grouping value gets displayed properly
    if (
      this.ztService.groupingEnabled &&
      !this.ztService.columnProps[this.options.grouping.prop]
    ) {
      // loop through the columns and add the grouping value to the next available column that isnt a rollup
      // the 'mockProp' will get used over using the normal prop if it exists
      // make sure to do this in a map so that it creates a new array and doesn't mutate the other cells' stuff
      let propAdded = false;

      cols = cols.map((col) => {
        if (!propAdded && !this.options.grouping.rollup[col.prop]) {
          propAdded = true;
          col.mockProp = this.options.grouping.prop;
          // add a label for the mock prop
          col.mockLabel = `<span class='mock-label'>${this.options.grouping.groupPropConfig.name}:</span> <span class='mock-label--value'>\${value}</span>`;
        }

        return col;
      });
    }

    if (cols && cols.length > 0) {
      this._columns = cols;
    } else {
      this._columns = [];
    }
  }

  @Input() grouping;
  @Output() groupClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(public ztService: ZipTable2Service) {}

  private cloneObject(obj, justCopyProps = null) {
    return cloneObject(obj, justCopyProps);
  }
}
