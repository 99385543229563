import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { OutOfNetworkConfig } from '../../../templates/authorization-submission/authorization-submission.model';

@Component({
  selector: 'service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.scss'],
})
export class ServiceDetailsComponent {
  @Input() config: any = {};
  @Input() group: UntypedFormGroup;
  @Input() state: string;
  @Input() controls: Map<any, any>;
  @Input() outOfNetwork: OutOfNetworkConfig;
  @Output() updatedState = new EventEmitter<string>();
  @Output() switchTriggered = new EventEmitter<any>();
  @Output() callCxEvent = new EventEmitter<number>();
  @Input() index;
  _: any = _;
  noSpace = 'n/a';

  showOutOfNetworkStatusPill(controlKey: string): boolean {
    const groupValue = this.group.get(
      this.config['controls'][controlKey].prop,
    )?.value;
    const isOutOfNetworkEnabled = this.outOfNetwork?.isOutOfNetworkEnabled;
    const isInNetworkStatus = groupValue?.member_network?.is_in_network;

    return isOutOfNetworkEnabled && isInNetworkStatus === false;
  }

  getDropdownDisplayValue(prop: string, group): string {
    const options: any[] = this.controls.get(prop).options;
    const option: any = options.find(
      (item: any) => item.value === group.get(prop).value,
    );

    if (option) return option.label;

    return this.noSpace;
  }

  getDefaultReviewBox(control: any, group: any) {
    if (control.type === 'date') {
      if (group.get(control.prop) && group.get(control.prop).value) {
        const value: any = group.get(control.prop).value;
        const dateObj = new Date(`${value}T00:00:00`);

        return new Intl.DateTimeFormat('en-US').format(dateObj);
      } else {
        return this.noSpace;
      }
    }

    return group.get(control.prop)
      ? group.get(control.prop).value
        ? group.get(control.prop).value
        : this.noSpace
      : this.noSpace;
  }

  back(_event: any): void {
    if (this.state === 'review') {
      this.state = 'edit';
      this.updatedState.emit(this.state);
    }
  }

  scrollTo(event: any, index: number): void {
    this.back(null);
    setTimeout(() => {
      const itemToScrollTo = document.getElementById(event);
      // null check to ensure that the element actually exists

      if (itemToScrollTo) {
        itemToScrollTo.scrollIntoView({ behavior: 'smooth' });
      }
    });
    this.callCxEvent.emit(index);
  }
}
