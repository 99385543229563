import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import OktaSignIn from '@okta/okta-signin-widget';
import { FormControlConfiguration } from '@zipari/shared-ds-util-form';
import { FormControlService } from '@zipari/design-system';
import { stateOptions } from '../../../constants/state.constants';
import { GlobalConfig } from '../../../../app.constants';
import { ConfigService } from '../../../services';
import {
  AuthCardOptions,
  AuthCardViewConfig,
} from '../authentication.constants';
import AuthCardService from './auth-card.service';
import { TaxIdConfig, TaxIdPayload } from './auth-card.modal';
import { authCardData } from './auth-card.constant';

@Component({
  selector: 'auth-card',
  templateUrl: './auth-card.component.html',
  styleUrls: ['./auth-card.component.scss'],
})
export class AuthCardComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() config: AuthCardOptions;
  @Input() errorMessage: string[];
  @Input() success: boolean;
  @Input() disableButton: boolean;
  @Input() idp: string;
  @Input() okta;
  @Input() activeCard;
  @Input() authenticationType: string;
  @Input() mainForm: UntypedFormGroup;
  @Output() linkClicked: EventEmitter<any> = new EventEmitter();
  @Output() buttonClicked: EventEmitter<any> = new EventEmitter();
  @Output() formGroupChange: EventEmitter<any> = new EventEmitter();

  widget;
  multiDataArry: string[] = [];
  public showError = false;
  public globalConfig: GlobalConfig;
  public enableValidationForTaxId: boolean;
  public errorMessageForTaxId: string;
  public addTaxIdCardConfig: AuthCardViewConfig;

  constructor(
    public route: ActivatedRoute,
    public formControlService: FormControlService,
    public changeDetectorRef: ChangeDetectorRef,
    public configService: ConfigService,
    public authCardService: AuthCardService,
  ) {}

  ngOnInit(): void {
    this.checkIDP();

    // To add state options from constant file
    const state = { state: 'state' };

    for (const control of this.activeCard?.form?.controls) {
      if (control?.prop === state.state) {
        control.options = stateOptions;
      }
    }

    this.globalConfig = this.configService.getPageConfig<GlobalConfig>(
      authCardData.global,
    );
    this.enableValidationForTaxId =
      this.globalConfig?.toggleFeatures?.enableValidationForTaxId;

    this.addTaxIdCardConfig = this.config?.cardViews?.find(
      (cardConfig: AuthCardViewConfig) =>
        cardConfig.cardName === authCardData.cardName,
    );
  }

  checkIDP(): void {
    if (this.idp === 'okta') {
      this.widget = new OktaSignIn({
        authParams: {
          issuer: 'https://dev-823880.okta.com/oauth2/default',
          pkce: false,
          responseType: ['code'],
          scopes: ['openid', 'offline_access'],
        },
        baseUrl: 'https://dev-823880.okta.com',
        clientId: '0oa1cyh7gm0HQ8sDz4x7',
        redirectUri: 'http://providers-democare.local.zipari.net:8000/callback',
        i18n: {
          en: {
            'primaryauth.title': 'Login',
          },
        },
      });
      this.toggleWidget();
    }
  }

  toggleWidget(): void {
    this.widget.renderEl({ el: '#widget-container' });
  }

  getFormConfigByProp(prop: string): FormControlConfiguration {
    return this.activeCard.form.controls.find(
      (item: FormControlConfiguration) => item.prop === prop,
    );
  }

  isFormFieldValid(config: TaxIdConfig, taxId: FormControl): boolean {
    return (
      this.formGroup.status === authCardData.status &&
      taxId.value &&
      this.multiDataArry.length < config.maxTaxIdCount
    );
  }

  formButtonClicked(config: TaxIdConfig, taxId?: FormControl): void {
    this.showError = false;
    if (!this.isFormFieldValid(config, taxId)) {
      this.formGroup.controls[authCardData.taxIdProp].markAsTouched();

      return;
    }

    this.enableValidationForTaxId
      ? this.validateTaxId(taxId.value)
      : this.addValidTaxId(taxId.value);
  }

  validateTaxId(taxId: string): void {
    this.authCardService
      .validateTaxIdPayload(
        this.addTaxIdCardConfig.apiForTaxIdValidation,
        taxId,
      )
      .subscribe({
        next: (response: TaxIdPayload) => {
          this.showError = false;
          this.handleTaxIdValidationResponse(response, taxId);
        },
        error: () => {
          this.showError = true;
          this.showErrorMessage(this.addTaxIdCardConfig?.taxIdNotExistErrorMsg);
        },
      });
  }

  handleTaxIdValidationResponse(
    taxIdStatus: TaxIdPayload,
    taxId: string,
  ): void {
    const { taxIdAssociatedWithProviderAdmin, taxIdNotExistErrorMsg } =
      this.addTaxIdCardConfig;
    const { status, admin_count } = taxIdStatus;
    const notFoundStatus = authCardData.notFoundStatus;
    const maxAdminCountLimit = this.addTaxIdCardConfig?.maxAdminCountLimit;
    const errorMessage =
      status === notFoundStatus
        ? taxIdNotExistErrorMsg
        : admin_count > maxAdminCountLimit
          ? taxIdAssociatedWithProviderAdmin
          : null;

    errorMessage
      ? this.showErrorMessage(errorMessage)
      : this.addValidTaxId(taxId);
  }

  addValidTaxId(taxId: string): void {
    this.multiDataArry.push(taxId);
    this.resetTaxIdField();
    this.changeDetectorRef.detectChanges();
  }

  showErrorMessage(errorMessage: string): void {
    this.showError = true;
    const maxErrorMsgLength = this.addTaxIdCardConfig?.maxErrorMsgLength;

    this.errorMessageForTaxId =
      errorMessage.length > maxErrorMsgLength
        ? errorMessage.substring(0, maxErrorMsgLength)
        : errorMessage;
    this.resetTaxIdField();
  }

  resetTaxIdField(): void {
    this.formGroup.controls.tax_id.setValue(authCardData.emptyString);
  }

  removeMultiDataItem(idx): void {
    this.multiDataArry.splice(idx, 1);
    this.changeDetectorRef.detectChanges();
  }

  handleButtonClicked(): void {
    if (this.formGroup) {
      if (this.formGroup.valid) {
        if (
          this.config.page === 'request-access' &&
          this.activeCard.index === this.config?.cardViews.length - 2 &&
          this.multiDataArry.length > 0
        ) {
          this.buttonClicked.emit({ tax_ids: this.multiDataArry });
        } else this.buttonClicked.emit(this.activeCard.footer?.button);
      } else {
        const controls: { [p: string]: AbstractControl } =
          this.formGroup.controls;

        Object.keys(controls).forEach((c) => {
          controls[c].markAsTouched();
        });
      }
    } else if (this.activeCard.type === 'text') {
      this.buttonClicked.emit(this.activeCard.footer?.button);
    }
    this.showError = false;
  }

  // returns if the next/submit button is disabled or not
  isButtonDisabled(): boolean {
    const formGroup = this.activeCard?.form;

    if (
      this.activeCard.index === this.config?.cardViews.length - 2 &&
      formGroup?.controls?.length &&
      formGroup?.controls[0]?.markAsRequired
    ) {
      if (this.multiDataArry.length === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return this.mainForm?.controls[this.activeCard?.index]?.invalid;
    }
  }

  formCreated(): void {
    this.formGroup
      .get('accepted_terms')
      ?.setValue(this.activeCard?.termsAndConditions);
  }
}
