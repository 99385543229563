import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom, map, Observable } from 'rxjs';
import { getPaginatorConfig } from '@zipari/design-system';
import { deepCompare } from '@zipari/web-utils';
import { APIService } from '@zipari/web-services';
import { identity, pickBy } from 'lodash';
import {
  ConfigService,
  FormattingService,
} from '../../../../../shared/services';
import {
  AttributeConfig,
  SiteLocation,
  SiteLocationsListConfig,
  SiteLocationsListResponse,
} from '../site-locations-attestation.model';
import {
  BUTTON_TYPE,
  BUTTON_TYPE_NAME,
  SITELOCATION_CONSTANTS,
} from '../site-location-attestation.constant';
import { getQuarterFirstDay } from '../../../../../shared/utilities/dates';
import SiteLocationAttestationEventService from '../site-location-attestation.event.service';
import { AnalyticsService } from '../../../../../shared/services/analytics.service';
import { CX_CALLS } from '../../../../../shared/constants/cx-calls.constant';
@Component({
  selector: 'site-locations-list',
  templateUrl: './site-locations-list.component.html',
  styleUrls: ['./site-locations-list.component.scss'],
})
export class SiteLocationsListComponent implements OnInit, OnDestroy {
  config: SiteLocationsListConfig;
  filterForm: UntypedFormGroup = new UntypedFormGroup({});
  locations: SiteLocationsListResponse;
  selectedRows: SiteLocation[] = [];
  selectedPageRows: SiteLocation[] = [];
  pageSize: number;
  paginator;
  currentPage = 1;
  filterParams;
  showError = false;
  loading = false;
  errorMessage: string;
  allLocationsSelected = false;

  constructor(
    private configService: ConfigService,
    private route: ActivatedRoute,
    private api: APIService,
    private formattingService: FormattingService,
    private siteLocationAttestationEventService: SiteLocationAttestationEventService,
    public analyticsService: AnalyticsService,
  ) {}

  get selectIcon(): string {
    return this.areAllRowsSelected()
      ? SITELOCATION_CONSTANTS.checkboxActiveIcon
      : this.selectedRows.length > 0
        ? SITELOCATION_CONSTANTS.checkboxAllIcon
        : SITELOCATION_CONSTANTS.checkboxIcon;
  }

  get cardsListHeader(): string {
    if (this.selectedRows.length > 0) {
      return `${this.selectedRows.length} ${SITELOCATION_CONSTANTS.cardDynamicHeader}`;
    } else {
      return this.config?.selectAllCardsHeader || '';
    }
  }

  ngOnInit(): void {
    const pageName = this.route.snapshot?.data['pageName'];
    const siteLocationConfig = this.configService.getPageConfig(
      'site-locations-attestation',
    );

    this.config = siteLocationConfig ? siteLocationConfig[pageName] : {};
    if (this.config?.approveButton) {
      this.config.approveButton[BUTTON_TYPE] = BUTTON_TYPE_NAME.APPROVAL;
      this.siteLocationAttestationEventService.emitDynamicButtonConfig(
        this.config.approveButton,
      );
      this.siteLocationAttestationEventService.emitSelectedSiteLocations(
        this.selectedRows,
      );
    }
    this.analyticsService.sendEvent(
      CX_CALLS.pp_site_location_attestation_viewed.event_key,
    );
    this.pageSize = this.config?.pageSize;
    this.setSpecialtiesFormatOption();
    this.getLocations(true);
  }

  setSpecialtiesFormatOption(): void {
    const attributes = this.config?.locationCard?.details?.attributes;

    attributes?.forEach((attribute: AttributeConfig, index: number) => {
      if (attribute.prop === SITELOCATION_CONSTANTS.specialtiesProp) {
        attributes[index] = {
          ...attribute,
          formatOptions: {
            mapping: SITELOCATION_CONSTANTS.specialtyMappingKey,
          },
        };
      }
    });
  }

  toggleAllRows(): void {
    const areAllCurrentPageRowsSelected = this.selectedPageRows.every(
      (location: SiteLocation) =>
        this.selectedRows.some((row: SiteLocation) => row.id === location.id),
    );

    if (areAllCurrentPageRowsSelected) {
      (this.selectedPageRows || []).forEach((row: SiteLocation) => {
        this.selectedRows = this.selectedRows.filter(
          (selectedLocation: SiteLocation) => selectedLocation.id !== row.id,
        );
      });
    } else {
      (this.selectedPageRows || []).forEach((row: SiteLocation) => {
        if (
          !this.selectedRows.find(
            (selectedLocation: SiteLocation) => selectedLocation.id === row.id,
          )
        ) {
          this.selectedRows = [...this.selectedRows, ...[row]];
        }
      });
    }
    this.allLocationsSelected = !this.allLocationsSelected;
    this.siteLocationAttestationEventService.emitSelectedSiteLocations(
      this.selectedRows,
    );
  }

  toggleRow(row: SiteLocation): void {
    !this.isRowSelected(row)
      ? this.selectedRows.push(row)
      : this.selectedRows.splice(this.findSelectedRowIndex(row), 1);
    this.siteLocationAttestationEventService.emitSelectedSiteLocations(
      this.selectedRows,
    );
  }

  isRowSelected(row: SiteLocation): boolean {
    return !!this.selectedRows.find((selectedRow: SiteLocation) =>
      deepCompare(selectedRow, row, { expanded: true }),
    );
  }

  findSelectedRowIndex(row: SiteLocation): number {
    return this.selectedRows.findIndex((selectedRow: SiteLocation) =>
      deepCompare(selectedRow, row, { expanded: true }),
    );
  }

  areAllRowsSelected(): boolean {
    return (this.selectedPageRows || []).every((row: SiteLocation) =>
      this.selectedRows.includes(row),
    );
  }

  async getLocations(isInitialCall = false): Promise<void> {
    this.loading = true;
    try {
      const locations = await lastValueFrom(this.getSiteLocations());

      this.loading = false;
      this.showError = false;
      this.locations = locations;
      if (isInitialCall) {
        this.siteLocationAttestationEventService.totalSiteLocationCount =
          this.locations.count;
      }
      this.selectedPageRows = this.locations.results;
      this.paginator = getPaginatorConfig(
        {},
        this.pageSize,
        this.currentPage,
        locations.count,
      );
    } catch (error) {
      this.showError = true;
      this.loading = false;
      const apiError = error.error?.errors[0]?.user_error;

      if (apiError && typeof apiError === 'string') {
        this.errorMessage = apiError;
      } else {
        this.errorMessage = this.config.noDataMessage
          ? this.config.noDataMessage
          : SITELOCATION_CONSTANTS.defaultErrorMessage;
      }
    }
  }

  getSiteLocations(): Observable<SiteLocationsListResponse> {
    return this.api
      .get(this.config?.endpoint, this.getLocationsParams())
      .pipe(map((response: any) => response));
  }

  getLocationsParams(): object {
    let params = {
      last_attestation_date_lt:
        this.formattingService.restructureValueBasedOnFormat(
          getQuarterFirstDay(),
          {
            formatOptions: { format: 'yyyy-MM-dd' },
            format: 'DATE',
          },
        ),
      page: this.currentPage,
      page_size: this.pageSize,
    };

    if (this.filterParams) {
      params = { ...params, ...this.filterParams };
    }

    return params;
  }

  setCurrentPage(page: number): void {
    this.currentPage = page;
    this.getLocations();
  }

  search(): void {
    this.filterParams = pickBy(this.filterForm.value, identity);
    this.getLocations();
    this.selectedRows = [];
  }

  ngOnDestroy(): void {
    this.siteLocationAttestationEventService.totalSiteLocationCount = 0;
  }
}
