import { Injectable } from '@angular/core';
import { stringBuilder } from '@zipari/web-utils';
import { ProgressBarConfig } from '../models/TabsConfig.model';

@Injectable({
  providedIn: 'root',
})
export class ProgressBarFormattingService {
  public formatProgressBarConfig(
    config: ProgressBarConfig[],
    data: any,
    target: string,
    uniqueSectionTarget,
  ): ProgressBarConfig[] {
    const percentageMultiplier = 100;
    const modifiedConfig: ProgressBarConfig[] = [];

    data?.map((item: any, idx: number) => {
      if (item[uniqueSectionTarget.key] === uniqueSectionTarget.value) {
        const tempConfigCopy: ProgressBarConfig = { ...config[0] };

        // PP-1427: we are no longer looking inside the accumulator object - MemberBenefitsList 10.64.1
        // https://confluence.zipari.net/pages/viewpage.action?spaceKey=PPP&title=Member+Details%3A+Costs+FIG+v3.5
        const used: number = parseFloat(
          this.getObjectProperty(item, tempConfigCopy.targetFieldLeft),
        );
        const remaining: number = parseFloat(
          this.getObjectProperty(item, tempConfigCopy.targetFieldRight),
        );
        const total: number = parseFloat(
          this.getObjectProperty(item, tempConfigCopy.targetFieldTotal),
        );

        tempConfigCopy.label = stringBuilder(tempConfigCopy.label, item);
        tempConfigCopy.labelLeft = this.formatLeftLabel(
          tempConfigCopy.labelLeft,
          item,
          used,
          tempConfigCopy.targetFieldLeft,
        );
        tempConfigCopy.labelRight = this.formatRightLabel(
          tempConfigCopy.labelRight,
          item,
          remaining,
          tempConfigCopy.targetFieldRight,
        );

        tempConfigCopy.value =
          ((used ? used : 0.0) / total) * percentageMultiplier;
        tempConfigCopy.data = item;
        if (!modifiedConfig[idx]) modifiedConfig.push(tempConfigCopy);
        else {
          modifiedConfig[idx] = {
            ...modifiedConfig[idx],
            ...tempConfigCopy,
          };
        }
      }
    });

    return modifiedConfig;
  }

  public getObjectProperty(obj, keys) {
    if (!obj || !keys) return '';

    return keys?.split('.').reduce(function (result, key) {
      if (!result) return '';

      return result[key];
    }, obj);
  }

  public sortData(data: any[]): any {
    return data.sort((a, b) => {
      if (a.accumulator_type && b.accumulator_type) {
        const textA: string = a.accumulator_type.toLowerCase();
        const textB: string = b.accumulator_type.toLowerCase();

        return textA < textB ? -1 : textA > textB ? 1 : 0;
      }
    });
  }

  // commented since no reference found.
  // private getTargetType(type: string, targetData: any): FieldConfig {
  //     return targetData.find((item: any) => item.type === type);
  // }

  public formatLeftLabel(
    target: string,
    data,
    used: number,
    field: string,
  ): string {
    // check if the label has valid data
    if (used) return stringBuilder(target, data);
    else {
      data[field] = 0.0;

      return stringBuilder(target, data);
    }
  }

  public formatRightLabel(
    target: string,
    data,
    remaining: number,
    field: string,
  ): string {
    // When there is no data in remaiming simply display -
    if (isNaN(remaining)) {
      data[field] = ' - ';
    }
    // When we get just zero it, we need to put 0.0 to get zero displyed in UI
    if (remaining === 0) {
      data[field] = 0.0;
    }

    return stringBuilder(target, data);
  }
}
