import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export const checkInputsForText = <Type>(
  args: Type[],
  formType?: UntypedFormControl | UntypedFormGroup,
): Type[] =>
  args.map((arg, index) => {
    if (typeof arg === 'string') {
      const parsedInput = JSON.parse(arg);

      if (formType && index !== 0) {
        const updatedForm =
          formType instanceof UntypedFormControl
            ? new UntypedFormControl('', [])
            : new UntypedFormGroup({});

        return updatedForm;
      }

      return parsedInput;
    }

    return arg;
  });
