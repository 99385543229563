import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { cloneObject, deepCompare } from '@zipari/web-utils';
import { TableFilter } from './table-filter.models';

@Component({
  selector: 'table-filters',
  templateUrl: './table-filter.component.html',
})
export class TableFilterComponent implements OnChanges, OnInit {
  @Input() options: any;
  @Input() filters: TableFilter[];
  @Output() value: EventEmitter<TableFilter[]> = new EventEmitter<
    TableFilter[]
  >();
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const enterButtonKeyCode = 13;

    if (event.which === enterButtonKeyCode) {
      this.cleanAndSubmitForm();
    }
  }

  zipFilters;
  formGroup: UntypedFormGroup;
  formGroupSubscription: Subscription;
  prevFilters;
  filterSub: Subscription;
  setupFilter = new Subject<TableFilter[]>();
  debounceTime = 250;
  filtersSame = true;
  showFilterButton = false;

  ngOnInit() {
    this.showFilterButton = this.options && this.options.button;
  }

  ngOnChanges(changes) {
    this.showFilterButton = this.options && this.options.button;

    // checks to see if the filters have actually changed or if we have just added the 'value' onto the object
    // if things have changed then setup the filters to work properly
    // IMPORTANT: make sure that we use the clone object function on the previous filters otherwise funky stuff is
    // going to happen
    if ('filters' in changes && changes.filters.currentValue) {
      if (
        !this.prevFilters ||
        (this.prevFilters &&
          !deepCompare(this.prevFilters, changes.filters.currentValue, {
            value: true,
          }))
      ) {
        this.prevFilters = changes.filters.currentValue;
        this.setupFilters(cloneObject(this.prevFilters));
      }
    }

    if (!this.showFilterButton) {
      const tenthOfASecond = 100;

      this.formGroupSubscription = this.formGroup.valueChanges
        .pipe(debounceTime(tenthOfASecond))
        .subscribe(() => {
          this.cleanAndSubmitForm();
        });

      this.filterSub = this.setupFilter
        .pipe(debounceTime(this.debounceTime))
        .subscribe((newFilters: TableFilter[]) => {
          this.value.emit(newFilters);
        });
    }
  }

  setupFilters(filters) {
    this.formGroup = new UntypedFormGroup({});
    this.zipFilters = filters.map(this.createZipFormControl.bind(this));

    if (!this.showFilterButton) {
      this.formGroup.valueChanges.subscribe(() => {
        this.cleanAndSubmitForm();
      });
    }

    this.filtersSame = filters.every(
      (filter) => filter.prop === filters[0].prop,
    );
  }

  cleanAndSubmitForm() {
    const newFilters = this.zipFilters.map((filter) => {
      filter.value = filter.control.value;

      return filter;
    });

    this.setupFilter.next(newFilters);
  }

  createZipFormControl(config) {
    // special backwards compatible things
    if (config.type === 'searchField') {
      config.type = 'text';
    }
    if (config.type === 'checkbox' || config.type === 'checkbox') {
      config.type = 'boolean';
    }
    if (
      (config.type === 'select' || config.type === 'select') &&
      config.options
    ) {
      config.type = 'dropdown';
      config.options = config.options.map((option) => {
        option['title'] = option.name;

        return option;
      });
    }
    const control = new UntypedFormControl('', []);
    const newZipFormControl = Object.assign(config, {
      prop: config.key || config.prop,
      key: config.key || config.prop,
      label: config.label || config.text,
      controlNameFull: true,
      control: control,
    });

    newZipFormControl.controlProp = newZipFormControl.key.split('.').pop();
    this.formGroup.addControl(newZipFormControl.controlProp, control);

    return newZipFormControl;
  }

  applyFilters() {
    this.cleanAndSubmitForm();

    this.filterSub = this.setupFilter.subscribe((newFilters: TableFilter[]) => {
      this.value.emit(newFilters);
    });
  }

  resetFilters() {
    this.formGroup.reset();

    this.cleanAndSubmitForm();
  }
}
