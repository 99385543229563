<article [zipBusy]="busy" class="centered-base_page">
  <div class="centered-base_page__content">
    <aside class="panel" *ngIf="config?.sidebar?.sections">
      <section *ngIf="loaded">
        <ng-container *ngFor="let section of config.sidebar?.sections">
          <div class="section" [ngSwitch]="section.format">
            <header class="sidebar_header">
              <h6 role="heading" aria-level="1">{{ section.label }}</h6>
            </header>
            <section
              class="patient-detail__aside__attrs"
              *ngSwitchCase="'attribute-list'"
            >
              <div *ngFor="let attribute of section.attributes">
                <model-attribute
                  *ngIf="
                    (!attribute.dataAsLabel && !attribute.showIfTrue) ||
                    (attribute.showIfTrue && checkShowOnTrueValues(attribute))
                  "
                  [context]="context"
                  [config]="attribute"
                  direction="column"
                ></model-attribute>
                <div *ngIf="attribute.dataAsLabel">
                  <label>{{ getValue(context, attribute) }}</label>
                </div>
                <div *ngFor="let nestedAttr of attribute.additionalAttrs">
                  <model-attribute
                    [context]="context"
                    [config]="nestedAttr"
                  ></model-attribute>
                </div>
              </div>
            </section>
          </div>
        </ng-container>
      </section>
    </aside>
    <div class="main-container">
      <div class="provisioner">
        <div
          *ngIf="config?.header && state === 'edit'"
          class="column-header"
          role="heading"
          aria-level="1"
        >
          {{ config?.header }}
        </div>
        <div
          *ngIf="config?.reviewHeader && state === 'review'"
          class="column-header"
          role="heading"
          aria-level="1"
        >
          {{ config?.reviewHeader }}
        </div>
        <div
          *ngIf="config?.confirmationHeader && state === 'confirm'"
          class="column-header"
          role="heading"
          aria-level="1"
        >
          {{ config?.confirmationHeader }}
        </div>
        <div
          *ngIf="config?.subheader && state === 'edit'"
          class="column-subheader"
        >
          {{ config?.subheader }}
        </div>
        <div
          *ngIf="config?.reviewSubheader && state === 'review'"
          class="column-subheader"
        >
          {{ config?.reviewSubheader }}
        </div>
        <div
          *ngIf="config?.confirmationMessage && state === 'confirm'"
          class="column-subheader"
        >
          {{ config?.confirmationMessage }}
        </div>
        <div
          *ngIf="state === 'confirm' || state === 'review'"
          class="column-subheader"
        >
          <div
            class="messageBanner"
            *ngIf="
              config?.outOfNetwork?.messageBanner &&
              config?.outOfNetwork?.isOutOfNetworkEnabled &&
              areProvidersOutOfNetwork()
            "
          >
            <message-banner
              [config]="config?.outOfNetwork?.messageBanner"
            ></message-banner>
          </div>
        </div>
        <div class="badge-container" *ngIf="state === 'confirm'">
          <div class="badge-spacer">
            <badge [config]="config.badge">
              <div class="badge-body">
                <div class="badge-icon badge-text__center">
                  <i class="icon icon--product icon--large">care</i>
                </div>
                <div class="badge-header">
                  <p class="t-bold card-header badge-text__center">
                    {{ response?.id || noSpace }}
                  </p>
                </div>
                <div class="badge-subheader badge-text__center">
                  <p class="card-subheader">Transaction ID</p>
                </div>
              </div>
            </badge>
          </div>
          <div class="badge-spacer">
            <badge [config]="config.badge">
              <div class="badge-body">
                <div class="badge-icon badge-text__center">
                  <i class="icon icon--product icon--large">clipboard</i>
                </div>
                <div class="badge-header">
                  <p class="t-bold card-header badge-text__center">
                    {{ response?.status || 'Completed' }}
                  </p>
                </div>
                <div class="badge-subheader badge-text__center">
                  <p class="card-subheader">Transaction Status</p>
                </div>
              </div>
            </badge>
          </div>
          <div class="badge-spacer">
            <badge [config]="config.badge">
              <div class="badge-body">
                <div class="badge-icon badge-text__center">
                  <i class="icon icon--product icon--large">profile_product</i>
                </div>
                <div class="badge-header">
                  <p class="t-bold card-header badge-text__center">
                    {{ context?.member?.name?.full_name || noSpace }}
                  </p>
                </div>
                <div class="badge-subheader badge-text__center">
                  <p class="card-subheader">Patient</p>
                </div>
              </div>
            </badge>
          </div>
        </div>
        <div *ngIf="state === 'confirm'" class="button-container">
          <zip-button
            [config]="config.printButton"
            (zipButtonClicked)="print()"
          ></zip-button>
        </div>
        <div class="column-container" *ngIf="config">
          <div *ngFor="let key of _.keys(config['cards'])">
            <card
              id="{{ config.cards[key].id }}"
              class="cardholder card item skinny-badge"
            >
              <div title class="card-header card-edit">
                <div class="left">
                  {{ config.cards[key].header }}
                </div>
                <div
                  *ngIf="state === 'review'"
                  class="right"
                  (click)="scrollTo(config.cards[key].id)"
                >
                  <i class="icon icon--medium">edit</i>
                </div>
              </div>
              <div *ngIf="config.cards[key]?.subheader" subheader>
                <div class="t-data address">
                  {{ config.cards[key]?.subheader }}
                </div>
              </div>
              <div body>
                <div class="title-border"></div>
                <ng-container
                  *ngFor="
                    let controlKey of _.keys((config?.cards)[key]?.controls)
                  "
                >
                  <div
                    [style.width]="
                      controls.get(
                        ((config?.cards)[key]?.controls)[controlKey].prop
                      )?.width
                        ? controls.get(
                            ((config?.cards)[key]?.controls)[controlKey].prop
                          ).width
                        : '100%'
                    "
                  >
                    <div [hidden]="state !== 'edit'">
                      <ng-container
                        [ngSwitch]="
                          ((config?.cards)[key]?.controls)[controlKey]?.type
                        "
                      >
                        <flyout-dropdown
                          *ngSwitchCase="'flyout'"
                          [group]="formGroup"
                          [outOfNetwork]="config.outOfNetwork"
                          [config]="
                            ((config?.cards)[key]?.controls)[controlKey]
                          "
                          [configNoSpace]="config?.noSpace"
                        ></flyout-dropdown>
                        <flyout-diagnosis
                          *ngSwitchCase="'flyout-diagnosis'"
                          [group]="formGroup"
                          [config]="
                            ((config?.cards)[key]?.controls)[controlKey]
                          "
                          [state]="state"
                          (diagnosisDetails)="selectedDiagnosisDetails($event)"
                        ></flyout-diagnosis>
                        <p *ngSwitchCase="'showOnConfirmation'"></p>
                        <form-control
                          *ngSwitchDefault
                          [group]="formGroup"
                          [config]="
                            ((config?.cards)[key]?.controls)[controlKey]
                          "
                          [control]="
                            formGroup.get(
                              ((config?.cards)[key]?.controls)[controlKey]?.prop
                            )
                          "
                        ></form-control>

                        <div *ngSwitchCase="'referral-documents'">
                          <!-- This is for document upload -->
                          <file-uploader
                            class="EntryFormCard__FileUploader"
                            *ngIf="state !== 'confirm'"
                            [restrictions]="
                              ((config?.cards)[key]?.controls)[controlKey]
                                ?.restriction || defaultFileUploaderRestrictions
                            "
                            [showCloseIcon]="
                              ((config?.cards)[key]?.controls)[controlKey]
                                ?.showCloseIcon ?? true
                            "
                            [disabled]="!formGroup.get('document_type')?.value"
                            [errorMessageConfig]="
                              ((config?.cards)[key]?.controls)[controlKey]
                                ?.errorMessage
                            "
                            [fileAttachment]="documentContext"
                            [progress]="
                              documentUploadService.uploadProgress$ | async
                            "
                            [uploadErrorMessage]="
                              documentUploadService.uploadError$ | async
                            "
                            [config]="
                              getDefaultUploadDocumentConfig(
                                ((config?.cards)[key]?.controls)[controlKey]
                              )
                            "
                            (fileUploaded)="fileUploaded($event)"
                            (fileRemoved)="removeFile($event)"
                          >
                          </file-uploader>
                        </div>
                      </ng-container>
                    </div>
                    <div class="review-box" *ngIf="state !== 'edit'">
                      <p
                        class="t-data t-bold"
                        *ngIf="
                          controls.get(
                            ((config?.cards)[key]?.controls)[controlKey]?.prop
                          )?.prop !== 'status' &&
                          controls.get(
                            ((config?.cards)[key]?.controls)[controlKey]?.prop
                          )?.prop !== 'status_reason' &&
                          controls.get(
                            ((config?.cards)[key]?.controls)[controlKey]?.prop
                          )?.prop !== 'date_received'
                        "
                      >
                        {{
                          controls.get(
                            ((config?.cards)[key]?.controls)[controlKey]?.prop
                          )?.label
                        }}
                      </p>
                      <p
                        class="t-data t-bold"
                        *ngIf="
                          controls.get(
                            ((config?.cards)[key]?.controls)[controlKey]?.prop
                          )?.type === 'showOnConfirmation' &&
                          state === 'confirm'
                        "
                      >
                        {{
                          controls.get(
                            ((config?.cards)[key]?.controls)[controlKey]?.prop
                          )?.label
                        }}
                      </p>
                      <!-- <p class="t-data t-bold">{{ controls.get(config?.cards[key]?.controls[controlKey]?.prop)?.label }}</p> -->
                      <div
                        [ngSwitch]="
                          controls.get(
                            ((config?.cards)[key]?.controls)[controlKey]?.prop
                          )?.type
                        "
                      >
                        <div *ngSwitchCase="'dropdown'">
                          {{
                            getDropdownDisplayValue(
                              ((config?.cards)[key]?.controls)[controlKey]?.prop
                            )
                          }}
                        </div>
                        <div *ngSwitchCase="'toggle'">
                          {{
                            getDropdownDisplayValue(
                              ((config?.cards)[key]?.controls)[controlKey]?.prop
                            )
                          }}
                        </div>
                        <div *ngSwitchCase="'radio'">
                          {{
                            getDropdownDisplayValue(
                              ((config?.cards)[key]?.controls)[controlKey]?.prop
                            )
                          }}
                        </div>
                        <div *ngSwitchCase="'flyout-diagnosis'">
                          <!-- This is for diagnosis -->
                          <table class="generic_procedure-diagosis-table">
                            <tr
                              class="generic_procedure-diagosis-table_row"
                              *ngFor="
                                let item of diagnosisDetails;
                                let indexOfelement = index
                              "
                            >
                              <td>
                                <b>{{ item.row.code }}</b>
                              </td>
                              <td>
                                <div class="diagnosis-table-item-entry">
                                  <div>
                                    {{
                                      item.row.short_description ||
                                        item.row.description
                                    }}
                                  </div>
                                  <div
                                    class="isPrimaryPill"
                                    *ngIf="item.row.is_primary === true"
                                  >
                                    Primary
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>

                        <div *ngSwitchCase="'referral-documents'">
                          <!-- This is for document upload -->
                          <div
                            class="generic-card-summary_one-line-value"
                            *ngIf="state === 'review'"
                          >
                            <!-- <b>{{currentDocumentType}} </b> -->
                          </div>
                          <div
                            *ngFor="
                              let document of documentUploadService.uploadedFiles;
                              let idx = index
                            "
                            class="documents"
                          >
                            <div class="document">
                              <div class="document--icon">
                                <i class="icon icon--medium">pdf</i>
                              </div>
                              <div class="document--name">
                                <p class="t-bold document--name__main">
                                  {{ document.file_name }}
                                </p>
                                <p class="document--name__file">
                                  {{ document.file_name }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div *ngSwitchCase="'flyout'" class="root">
                          <!-- This is for procedure code -->
                          <div
                            *ngIf="
                              controls.get(
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.prop
                              )?.prop === 'procedure_id'
                            "
                          >
                            <table class="generic_procedure-diagosis-table">
                              <tr class="generic_procedure-diagosis-table_row">
                                <td>
                                  <p class="t-data t-bold">
                                    {{
                                      formGroup.get(
                                        ((config?.cards)[key]?.controls)[
                                          controlKey
                                        ]?.prop
                                      )?.value?.code || noSpace
                                    }}
                                  </p>
                                </td>
                                <td>
                                  <div class="diagnosis-table-item-entry">
                                    <div>
                                      <p class="t-data">
                                        {{
                                          formGroup.get(
                                            ((config?.cards)[key]?.controls)[
                                              controlKey
                                            ]?.prop
                                          )?.value?.description || noSpace
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>

                          <div
                            *ngIf="
                              controls.get(
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.prop
                              )?.prop === 'servicing_facility_id'
                            "
                            class="left-flyout"
                          >
                            <label
                              *ngIf="
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.displayFields.name &&
                                formGroup.get(
                                  ((config?.cards)[key]?.controls)[controlKey]
                                    ?.prop
                                )?.value
                              "
                              class="form-control__label"
                            >
                              <p class="t-data t-bold">
                                {{
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  )?.value?.name || noSpace
                                }}
                              </p>
                            </label>
                            <p
                              *ngIf="
                                showOutOfNetworkStatusPill(key, controlKey)
                              "
                              class="OutOfNetwork__StatusPill Pending"
                            >
                              {{ config?.outOfNetwork?.outOfNetworkLabel }}
                            </p>
                          </div>
                          <div
                            *ngIf="
                              controls.get(
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.prop
                              )?.prop === 'referring_provider_id' ||
                              controls.get(
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.prop
                              )?.prop === 'servicing_provider_id'
                            "
                            class="left-flyout"
                          >
                            <label
                              *ngIf="
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.displayFields.name &&
                                formGroup.get(
                                  ((config?.cards)[key]?.controls)[controlKey]
                                    ?.prop
                                )?.value
                              "
                              class="form-control__label"
                            >
                              <p class="t-data t-bold">
                                {{
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  )?.value?.name.full_name || noSpace
                                }}
                              </p>
                            </label>
                            <p
                              *ngIf="
                                showOutOfNetworkStatusPill(key, controlKey)
                              "
                              class="OutOfNetwork__StatusPill Pending"
                            >
                              {{ config?.outOfNetwork?.outOfNetworkLabel }}
                            </p>
                          </div>
                          <!-- load this from config -->
                          <div
                            *ngIf="
                              controls.get(
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.prop
                              )?.prop === 'servicing_facility_id'
                            "
                            class="right-flyout"
                          >
                            <label
                              *ngIf="
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.displayFields.address
                              "
                              class="form-control__label"
                            >
                              <p class="spacing t-data t-bold">Address:</p>
                              <p
                                *ngIf="
                                  !formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['address']
                                "
                              >
                                {{ noSpace }}
                              </p>
                              <div
                                class="address"
                                *ngIf="
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['address']
                                "
                              >
                                {{
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['address']['street_name_1']
                                }}<br />
                                {{
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['address']['street_name_2']
                                }}<br />
                                {{
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['address']['city_name']
                                }},
                                {{
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['address']['state']
                                }}
                                {{
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['address']['zip_code']
                                }}
                              </div>
                            </label>
                            <label
                              *ngIf="
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.displayFields.group_npi
                              "
                              class="form-control__label"
                            >
                              <p class="spacing t-data t-bold">Group NPI:</p>
                              <p>
                                {{
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['group_npi'] || noSpace
                                }}
                              </p>
                            </label>
                            <label
                              *ngIf="
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.displayFields.id_number
                              "
                              class="form-control__label"
                            >
                              <p class="spacing t-data t-bold">ID Number:</p>
                              <p>
                                {{
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['number'] || noSpace
                                }}
                              </p>
                            </label>
                            <label
                              *ngIf="
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.displayFields.specialty
                              "
                              class="form-control__label"
                            >
                              <p class="spacing t-data t-bold">
                                Primary Specialty:
                              </p>
                              <p
                                *ngIf="
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['specialties'].length
                                "
                              >
                                {{
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['specialties'][0]['name']
                                }}
                              </p>
                              <p
                                *ngIf="
                                  !formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['specialties']?.length
                                "
                              >
                                {{ noSpace }}
                              </p>
                            </label>
                          </div>

                          <div
                            *ngIf="
                              controls.get(
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.prop
                              )?.prop === 'referring_provider_id' ||
                              controls.get(
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.prop
                              )?.prop === 'servicing_provider_id'
                            "
                            class="right-flyout"
                          >
                            <label
                              *ngIf="
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.displayFields.address
                              "
                              class="form-control__label"
                            >
                              <p class="spacing t-data t-bold">Address:</p>
                              <p
                                *ngIf="
                                  !formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['provider_locations']?.length
                                "
                              >
                                {{ noSpace }}
                              </p>
                              <div
                                class="address"
                                *ngIf="
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['provider_locations'].length
                                "
                              >
                                {{
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['provider_locations'][0]['address'][
                                    'street_name_1'
                                  ]
                                }}<br />
                                {{
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['provider_locations'][0]['address'][
                                    'street_name_2'
                                  ]
                                }}<br />
                                {{
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['provider_locations'][0]['address'][
                                    'city_name'
                                  ]
                                }},
                                {{
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['provider_locations'][0]['address'][
                                    'state'
                                  ]
                                }}
                                {{
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['provider_locations'][0]['address'][
                                    'zip_code'
                                  ]
                                }}
                              </div>
                            </label>
                            <label
                              *ngIf="
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.displayFields.group_npi
                              "
                              class="form-control__label"
                            >
                              <p class="spacing t-data t-bold">NPI:</p>
                              <p>
                                {{
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['npi'] || noSpace
                                }}
                              </p>
                            </label>
                            <label
                              *ngIf="
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.displayFields.id_number
                              "
                              class="form-control__label"
                            >
                              <p class="spacing t-data t-bold">ID Number:</p>
                              <p>
                                {{
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['number'] || noSpace
                                }}
                              </p>
                            </label>
                            <label
                              *ngIf="
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.displayFields.specialty
                              "
                              class="form-control__label"
                            >
                              <p class="spacing t-data t-bold">
                                Primary Specialty:
                              </p>
                              <p
                                *ngIf="
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['specialties'].length
                                "
                              >
                                {{
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['specialties'][0]['name']
                                }}
                              </p>
                              <p
                                *ngIf="
                                  !formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  ).value['specialties']?.length
                                "
                              >
                                {{ noSpace }}
                              </p>
                            </label>
                          </div>
                        </div>

                        <!-- <div class="checkbox-grid" *ngSwitchCase="'checkbox'">
                                                    <div *ngFor="let line of getCheckboxValues(config?.cards[key]?.controls[controlKey]?.prop)">
                                                        - {{ line || noSpace }}
                                                    </div>
                                                    <div *ngIf="getCheckboxValues(config?.cards[key]?.controls[controlKey]?.prop).length === 0">
                                                        n/a
                                                    </div>
                                                </div> -->
                        <div *ngSwitchCase="'showOnConfirmation'">
                          <div *ngIf="state === 'confirm'">
                            <label
                              class="form-control__label"
                              *ngIf="
                                controls.get(
                                  ((config?.cards)[key]?.controls)[controlKey]
                                    ?.prop
                                )?.prop === 'status'
                              "
                            >
                              <p class="t-data">
                                {{ this.response?.status || noSpace }}
                              </p>
                            </label>
                            <label
                              class="form-control__label"
                              *ngIf="
                                controls.get(
                                  ((config?.cards)[key]?.controls)[controlKey]
                                    ?.prop
                                )?.prop === 'status_reason'
                              "
                            >
                              <p class="t-data">
                                {{ this.response?.status_reason || noSpace }}
                              </p>
                            </label>
                            <label
                              class="form-control__label"
                              *ngIf="
                                controls.get(
                                  ((config?.cards)[key]?.controls)[controlKey]
                                    ?.prop
                                )?.prop === 'date_received'
                              "
                            >
                              <p class="t-data">
                                {{
                                  this.response?.status_updated_date || noSpace
                                }}
                              </p>
                            </label>
                          </div>
                        </div>
                        <div *ngSwitchDefault>
                          {{
                            getDefaultReviewBox(
                              ((config?.cards)[key]?.controls)[controlKey]
                            ) || noSpace
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="centered-base_page__always_on_top">
    <zip-button
      *ngIf="state === 'edit'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonCancel"
      (zipButtonClicked)="cancel()"
    >
    </zip-button>
    <zip-button
      *ngIf="state === 'confirm'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonReturn"
      (zipButtonClicked)="cancel()"
    >
    </zip-button>
    <zip-button
      *ngIf="state === 'edit'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonReview"
      (zipButtonClicked)="review()"
    >
    </zip-button>
    <zip-button
      *ngIf="state === 'review'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonBack"
      (zipButtonClicked)="back($event)"
    >
    </zip-button>
    <zip-button
      *ngIf="state === 'review'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonSubmit"
      (zipButtonClicked)="submit($event)"
    >
    </zip-button>
  </div>
</article>

<modal
  #errorFlyout
  *ngIf="error"
  [config]="config?.errorModal"
  [condition]="error"
  (cancel)="toggleError()"
>
  <div body>{{ config?.errorModal.message }}</div>
</modal>
