import { Component, Input } from '@angular/core';
import { SpinnerSizes } from './spinner.model';

@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() size: SpinnerSizes;
  @Input() flexCenter: boolean;
  @Input() asOverlay = false;

  sizes = SpinnerSizes;
}
