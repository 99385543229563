<div
  class="model-attribute"
  *ngIf="
    (!isHidden && !config.hideIfZeroOrNull) ||
    (config.hideIfZeroOrNull && valueIsGreaterThanZero())
  "
>
  <div class="u-flex">
    <icon
      class="model-attribute__icon"
      *ngIf="config.icon"
      [name]="config.icon"
    >
    </icon>

    <div
      tabindex="0"
      class="model-attribute__wrapper"
      [ngClass]="{
        'model-attribute__wrapper--row':
          direction === 'row' || direction === 'row_equal',
      }"
    >
      <div
        *ngIf="
          !isLabelExcludedForThisFormat && !isLabelExcludedForThisComponent
        "
        class="model-attribute__label u-flex"
        [ngClass]="{ 'u-fillRemaining': direction === 'row_equal' }"
      >
        <h5
          role="heading"
          aria-level="1"
          class="t-caption model-attribute__Label"
          [innerHTML]="label"
          [ngClass]="{
            't-bold':
              direction === 'column' ||
              direction === 'row_equal' ||
              direction === 'row',
          }"
          [attr.for]="label + ':' + value"
          [attr.aria-labelledby]="config.ariaLabel || config.label"
        ></h5>

        <tooltip
          class="model-attribute__tooltip"
          [config]="config.tooltip"
          data-test-id="modelAttributeTooltip"
        ></tooltip>
      </div>

      <div
        class="model-attribute__value u-fillRemaining"
        *ngIf="!config.componentType; else displayComponentType"
      >
        <ng-container [ngSwitch]="displayType">
          <ng-container *ngSwitchCase="displayTypes.ADDRESSES">
            <div class="u-wrap model-attribute__multi-address">
              <div
                class="multi-address__wrapper"
                *ngFor="let addressValue of value; let idx = index"
              >
                <ng-container
                  *ngIf="
                    addressValue &&
                      addressValue.length > 1 &&
                      getAddressLabel(context, idx);
                    let label
                  "
                >
                  <h3
                    *ngIf="config.typeProp && config.useConfigAddressLabel"
                    class="model-attribute__label__address model-attribute__Label"
                    [attr.for]="'address-' + idx"
                  >
                    {{ label }}
                    <tooltip
                      class="model-attribute__tooltip"
                      [config]="config.tooltip"
                      data-test-id="modelAttributeTooltipForAddress"
                    ></tooltip>
                  </h3>
                  <p
                    [attr.id]="'address-' + idx"
                    *ngIf="addressValue"
                    [innerHTML]="addressValue"
                  ></p>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="displayTypes.BOOLEAN_STRING_MAP">
            <p
              [attr.id]="label + ':' + value"
              *ngIf="value"
              [innerHTML]="config.truthyValue"
            ></p>
            <p
              [attr.id]="label + ':' + value"
              *ngIf="!value"
              [innerHTML]="config.falsyValue"
            ></p>
          </ng-container>

          <ng-container *ngSwitchCase="displayTypes.EMAIL">
            <a
              [attr.id]="label + ':' + value"
              [ngClass]="{
                't-link': !config?.formatOptions?.noLink,
                't-regular': config?.formatOptions?.noBold,
              }"
              [href]="'mailto: ' + value"
              [innerHTML]="value"
              (click)="onLinkClicked()"
              role="link"
            >
            </a>
          </ng-container>

          <ng-container *ngSwitchCase="displayTypes.EXTERNAL_LINK">
            <a
              class="t-link"
              [ngClass]="{ 't-bold': !label }"
              [attr.id]="label + ':' + value"
              [attr.target]="config.target ? config.target : '_blank'"
              [href]="route || value"
              [innerHTML]="text || value"
              (click)="onLinkClicked()"
              role="link"
            >
            </a>
          </ng-container>

          <ng-container *ngSwitchCase="displayTypes.LINK">
            <a
              tabindex="0"
              class="t-link"
              [attr.id]="label + ':' + value"
              [ngClass]="{
                't-bold': !label || direction === 'row_equal',
              }"
              (click)="onLinkClicked()"
              [innerHTML]="text || value"
              role="link"
            >
            </a>
          </ng-container>

          <ng-container *ngSwitchCase="displayTypes.LIST">
            <ul
              tabindex="0"
              class="model-attribute__list"
              *ngIf="valueIsList; else noListValues"
            >
              <li *ngFor="let val of value" [attr.id]="label + ':' + value">
                <ng-container [ngSwitch]="displaySubtype">
                  <ng-container *ngSwitchCase="displayTypes.PHONE">
                    <a
                      class="t-link"
                      [attr.href]="'tel: ' + val"
                      [innerHTML]="val"
                      (click)="onLinkClicked()"
                      role="link"
                    ></a>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <p>{{ val }}</p>
                  </ng-container>
                </ng-container>
              </li>
            </ul>
            <ng-template #noListValues>
              <ul tabindex="0" class="model-attribute__list">
                <li>
                  <p tabindex="0">
                    {{ config?.noValueMessage || 'n/a' }}
                  </p>
                </li>
              </ul>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchCase="displayTypes.PHONE_AND_TYPE">
            <ng-container
              *ngIf="!value || value === config.noValueMessage; else phoneList"
            >
              <p [attr.id]="label + ':' + value" [innerHTML]="value"></p>
            </ng-container>
            <ng-template #phoneList>
              <ul
                class="model-attribute__list"
                *ngFor="let phone_number_and_type of value"
                [attr.id]="label + ':' + value"
              >
                <li>
                  <a
                    tabindex="0"
                    [ngClass]="{
                      't-link': !config?.formatOptions?.noLink,
                      't-regular': config?.formatOptions?.noBold,
                    }"
                    [attr.href]="
                      config?.formatOptions?.noLink ? null : 'tel: ' + value
                    "
                    (click)="onLinkClicked()"
                    role="link"
                  >
                    {{ phone_number_and_type }}
                  </a>
                </li>
              </ul>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchCase="displayTypes.PHONE">
            <a
              tabindex="0"
              [ngClass]="{
                't-link': !config?.formatOptions?.noLink,
                't-regular': config?.formatOptions?.noBold,
              }"
              [attr.id]="label + ':' + value"
              [attr.href]="
                config?.formatOptions?.noLink ? null : 'tel: ' + value
              "
              (click)="onLinkClicked()"
              [innerHTML]="value"
              role="link"
            ></a>
          </ng-container>

          <ng-container *ngSwitchCase="displayTypes.STATUS">
            <status
              [attr.id]="label + ':' + value"
              [config]="{
                label: value,
                statuses: config.statuses,
              }"
            >
            </status>
          </ng-container>

          <ng-container *ngSwitchCase="displayTypes.VALUE_MAP">
            <p [attr.id]="label + ':' + value" [innerHTML]="value"></p>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <p
              [attr.id]="label + ':' + value"
              [innerHTML]="'&#8203;' + value"
            ></p>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #displayComponentType>
  <ng-container [ngSwitch]="config.componentType">
    <ng-container *ngSwitchCase="componentTypes.badge">
      <badge [config]="badgeConfig" [context]="context"></badge>
    </ng-container>
  </ng-container>
</ng-template>
