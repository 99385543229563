import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModelAttributeModule, SpinnerModule } from '@zipari/design-system';
import InfoListComponent from './info-list.component';

@NgModule({
  declarations: [InfoListComponent],
  exports: [InfoListComponent],
  imports: [CommonModule, ModelAttributeModule, SpinnerModule],
})
export class InfoListModule {}
