<article>
  <div class="Practitioners" *ngIf="config">
    <div class="Practitioners__HeaderContainer">
      <h4
        class="t-bold Practitioners__HeaderContainer--title"
        [innerHTML]="config.title"
      ></h4>
      <p [innerHTML]="config.subTitle"></p>
      <ng-container *ngIf="siteLocationMessage?.length > 0">
        <site-location-message
          [successHeader]="config?.successHeader"
          [siteLocationMessage]="siteLocationMessage"
        ></site-location-message>
      </ng-container>
    </div>
    <div *ngIf="showTable">
      <zip-table
        [options]="config.table"
        (deleteClicked)="terminatePractitionerClicked($event)"
        (cellClicked)="editPractitionerClicked($event)"
      >
      </zip-table>
    </div>
  </div>
</article>

<modal
  *ngIf="
    showTerminateModal && config.terminatePractitioner?.modalConfig;
    let modalConfig
  "
  [config]="modalConfig"
  [condition]="showTerminateModal"
  (cancel)="handleModalCancel()"
>
  <div body>
    <div *ngIf="modalConfig.description" class="TerminateModal__Description">
      <p [innerHTML]="modalConfig.description"></p>
    </div>
    <div>
      <accordion
        [icon]="
          config.terminatePractitioner.selectedPractitioners?.accordionConfig
            ?.icon
        "
        [isOpen]="true"
        [header]="
          config.terminatePractitioner.selectedPractitioners?.accordionConfig
            ?.header
        "
        [content]="selectedList"
      ></accordion>
      <accordion
        [icon]="
          config.terminatePractitioner.terminationDetails?.accordionConfig?.icon
        "
        [isOpen]="true"
        [header]="
          config.terminatePractitioner.terminationDetails?.accordionConfig
            ?.header
        "
        [content]="TerminationForm"
      ></accordion>
    </div>

    <ng-template #selectedList>
      <div *ngFor="let item of terminatePractitionersList; let index = index">
        <div class="TerminateModal__SelectedPractitioner">
          <div class="TerminateModal__SelectedPractitioner--leftPanel">
            <h6 class="t-bold" [innerHTML]="item.row?.provider_full_name"></h6>
            <p
              *ngIf="!isTerminationFormSubmitted"
              class="t-bold t-link"
              (click)="removePractitioner(index)"
              [innerHTML]="'Remove'"
            ></p>
          </div>
          <div class="TerminateModal__SelectedPractitioner--rightPanel">
            <div
              *ngFor="
                let attribute of config?.terminatePractitioner
                  ?.selectedPractitioners?.details?.attributes;
                let index = index
              "
            >
              <model-attribute
                [context]="item.row"
                [config]="attribute"
              ></model-attribute>
              <br />
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #TerminationForm>
      <div *ngIf="!isTerminationFormSubmitted">
        <form-group
          [configs]="
            config?.terminatePractitioner?.terminationDetails?.form?.controls
          "
          [form]="terminateDetailsForm"
          (formCreated)="formCreated()"
        ></form-group>
      </div>
      <div *ngIf="isTerminationFormSubmitted">
        <div
          *ngFor="
            let control of config?.terminatePractitioner?.terminationDetails
              ?.form?.controls
          "
        >
          <p class="t-data t-bold" [innerHTML]="control.label"></p>
          <p
            class="t-data"
            [innerHTML]="terminateDetailsForm?.get(control.prop)?.value"
          ></p>
          <br />
        </div>
      </div>
    </ng-template>
  </div>
  <div footer>
    <zip-button
      *ngIf="!isTerminationFormSubmitted"
      [config]="config?.terminatePractitioner?.footer?.submitButton"
      (zipButtonClicked)="terminatePractitioner()"
      [disabled]="isTerminateDetailsFormInvalid()"
    ></zip-button>
    <zip-button
      *ngIf="isTerminationFormSubmitted"
      [config]="config?.terminatePractitioner?.footer?.backButton"
      (zipButtonClicked)="handleModalCancel($event)"
    ></zip-button>
  </div>
</modal>

<modal
  *ngIf="showEditModal && config.editPractitioner?.modal; let editModalConfig"
  [config]="editModalConfig"
  [condition]="showEditModal"
  (cancel)="toggleEdit()"
>
  <div body>
    <div
      *ngIf="editModalConfig.description"
      class="TerminateModal__Description"
    >
      <p [innerHtml]="editModalConfig.description"></p>
    </div>
    <div
      *ngFor="
        let group of config.editPractitioner?.sections | keyvalue;
        let idx = index
      "
    >
      <header>
        <accordion
          [isOpen]="idx === openAccordionIdx || openAccordionIdx === -1"
          [header]="group.value.header"
          [content]="contentTemplate"
        ></accordion>
      </header>
      <ng-template #contentTemplate>
        <form-group
          [configs]="group.value.details.controls"
          [form]="
            providerUpdateToolService.getFormGroup('editPractitionerForm')
          "
        >
        </form-group>
        <div *ngFor="let control of group.value?.additionalDetails?.controls">
          <div [ngSwitch]="control.type">
            <input-array
              [key]="'editPractitionerForm'"
              [config]="control"
              *ngSwitchCase="'input'"
            ></input-array>
            <dropdown-array
              [key]="'editPractitionerForm'"
              [config]="control"
              *ngSwitchCase="'dropdown'"
              (formCreated)="editDropdownControlsCreated()"
            ></dropdown-array>
            <controls-group-array
              [key]="'editPractitionerForm'"
              [config]="control"
              *ngSwitchCase="'formGroupArray'"
              [stateDeaInfoModal]="group.stateDeaInfoModal"
            ></controls-group-array>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div footer>
    <zip-button
      [disabled]="isEditPractitionerDisabled()"
      [config]="config.editPractitioner?.footer?.submitButton"
      (zipButtonClicked)="editSubmit(additionalDetailsModalConfig)"
    ></zip-button>
  </div>
</modal>

<modal
  #errorFlyout
  *ngIf="error"
  [config]="config?.errorModal"
  [condition]="error"
  (cancel)="toggleError()"
>
  <div body><p [innerHTML]="config?.errorModal?.message"></p></div>
</modal>

<pp-additional-detail-modal
  *ngIf="showAdditionalDetailsModal"
  [config]="additionalDetailsModalConfig"
  [showAdditionalDetailsModal]="showAdditionalDetailsModal"
  [additionalDetailsForm]="additionalDetailsForm"
  (closeModal)="cancelEdit()"
  (submitClicked)="editPractitioner()"
></pp-additional-detail-modal>
