import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  cloneObject,
  deepCompare,
  getValue,
  stringBuilder,
} from '@zipari/web-utils';
import { FormattingService } from '../../../services/formatting.service';
import { SelectionTypes } from '../components/zip-table2.constants';
import { Column2 } from '../models/column.model';
import { ZipTable2, ZipTablePagedData2 } from '../models/zip-table.model';

const DECIMAL = 10;

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}
@Injectable()
export class ZipTable2Service {
  tableInit = false;
  zipTableOptions: ZipTable2;
  allLocalData;
  dataChanged: Subject<any> = new Subject();
  rowSelected: Subject<any> = new Subject();
  httpClient: HttpClient;
  internalFilteredDataCount;
  expandedGroups = {};
  tableWrapper;
  queryParamsSub;
  currentQueryParams;
  currentFilters;
  selectedRowSubject = new Subject();
  prevEndpoint;
  columnProps;
  detailedProps;
  _currentData;
  loading = false;
  _selectedRow = [];
  public currentPage;

  constructor(
    public route: ActivatedRoute,
    public http: HttpClient,
    public formattingService: FormattingService,
  ) {
    this.httpClient = http;
    this.queryParamsSub = this.route.queryParams.subscribe((params: any) => {
      this.currentQueryParams = params;
      this.filterData();
    });
  }

  public get columns() {
    let cols = this.detailEnabled
      ? this.handleColumnPriority(
          this.zipTableOptions.columns,
          'columns',
          this.allowedColumns,
        )
      : this.zipTableOptions.columns;

    if (this.groupingEnabled && this.zipTableOptions.grouping.showInDetail) {
      const groupProp = this.zipTableOptions.grouping.prop;

      if (this.columnProps && this.columnProps[groupProp]) {
        const groupedCol = this.zipTableOptions.columns.filter(
          (column: any) => column.prop === groupProp,
        );

        cols = cols.filter((column: any) => column.prop !== groupProp);

        return [].concat(groupedCol, cols);
      }
    }

    return cols;
  }

  public get weHaveData() {
    return (
      (this.currentData && this.currentData.length > 0) ||
      (this.groupingEnabled && this.groupedData.length > 0)
    );
  }

  public get showFilters() {
    if (!this.zipTableOptions) {
      return null;
    }

    return (
      this.zipTableOptions.nonQueryFilters &&
      this.zipTableOptions.nonQueryFilters.length > 0
    );
  }

  public get showExport() {
    if (!this.zipTableOptions) {
      return null;
    }

    return getValue(this.zipTableOptions, 'exportConfig.enabled');
  }

  public get showButton() {
    if (!this.zipTableOptions) {
      return null;
    }

    return (
      getValue(this.zipTableOptions, 'button.enabled') ||
      getValue(this.zipTableOptions, 'button.content') ||
      getValue(this.zipTableOptions, 'button.icon')
    );
  }

  public get selectedRow() {
    return this._selectedRow;
  }

  public set selectedRow(selectedRow: any) {
    this._selectedRow = selectedRow;
    this.selectedRowSubject.next(null);
  }

  public get allRowsAreSelected() {
    return (
      this.selectedRow &&
      this.currentData &&
      this.selectedRow.length === this.currentData.length
    );
  }

  public get currentData() {
    return this._currentData;
  }

  public set currentData(data: any) {
    this._currentData = data;
    this.dataChanged.next(data);
  }

  public get groupingEnabled() {
    return getValue(this.zipTableOptions, 'grouping.enabled');
  }

  public get detailEnabled() {
    return getValue(this.zipTableOptions, 'detail.enabled');
  }

  public get groupedData() {
    const finalGroupedData = [];
    const tempGroupedData = {};

    // get the configuration for grouping
    const groupConfig = getValue(this.zipTableOptions, 'grouping');

    if (this.currentData) {
      // group together the current data keyed by the prop
      // do the rollup of the value specified as long as the value they gave is relevant
      this.currentData.forEach((row: any) => {
        const groupVal = getValue(row, groupConfig.prop);

        if (!tempGroupedData[groupVal]) {
          tempGroupedData[groupVal] = {
            rows: [],
            value: groupVal,
            rollups: {},
          };
        }

        if (groupConfig.rollup) {
          for (const rollupKey in groupConfig.rollup) {
            if (!rollupKey) {
              continue;
            }
            if (
              tempGroupedData[groupVal]['rollups'][rollupKey] === null ||
              tempGroupedData[groupVal]['rollups'][rollupKey] === undefined
            ) {
              tempGroupedData[groupVal]['rollups'][rollupKey] = 0;
            }
            try {
              let num;

              if (typeof getValue(row, rollupKey) === 'number') {
                num = getValue(row, rollupKey);
              } else {
                num = Number.parseFloat(getValue(row, rollupKey));
              }
              tempGroupedData[groupVal]['rollups'][rollupKey] += num;
            } catch (err) {
              console.error(err);
            }
          }
        }

        tempGroupedData[groupVal].rows.push(row);
      });

      for (const key in tempGroupedData) {
        if (!key) {
          continue;
        }
        const groupHeaderRow: any = {};

        this.zipTableOptions.columns.forEach((column: any) => {
          let currVal = '';

          if (column.prop === groupConfig.prop) {
            currVal = tempGroupedData[tempGroupedData[key].value].value;
          } else if (groupConfig.rollup[column.prop]) {
            currVal =
              tempGroupedData[tempGroupedData[key].value]['rollups'][
                column.prop
              ];
          }
          groupHeaderRow[column.prop] = currVal;
        });

        const finalGroupObject = {
          groupRow: groupHeaderRow,
          display: tempGroupedData[key].value,
          rollup: tempGroupedData[key].rollup,
          rows: tempGroupedData[key].rows,
        };

        const groupDirection = getValue(this.zipTableOptions, 'grouping.sort');

        if (groupDirection === 'asc') {
          finalGroupedData.push(finalGroupObject);
        } else if (groupDirection === 'desc') {
          finalGroupedData.unshift(finalGroupObject);
        }
      }
    }

    return finalGroupedData;
  }

  public get getCurrentPageCount() {
    if (this.zipTableOptions.endpoint) {
      return (
        this.zipTableOptions.paging.count ||
        (this.allLocalData && this.allLocalData.length)
      );
    } else {
      return (
        this.internalFilteredDataCount ||
        (this.allLocalData && this.allLocalData.length)
      );
    }
  }

  public get allowedColumns() {
    if (this.tableInit) {
      const width = getValue(this.tableWrapper, 'nativeElement.clientWidth');
      const maxWidthPerColumn = 150;
      const howManyColumns = this.zipTableOptions.columns.length;

      if (width > maxWidthPerColumn * howManyColumns) {
        return howManyColumns > this.zipTableOptions.detail.minColumns
          ? howManyColumns
          : this.zipTableOptions.detail.minColumns;
      } else {
        const calculatedRows = Math.floor(width / maxWidthPerColumn);

        return calculatedRows > this.zipTableOptions.detail.minColumns
          ? calculatedRows
          : this.zipTableOptions.detail.minColumns;
      }
    }
  }

  public get allRowsExpanded() {
    let rows;
    let groups;

    if (this.currentData) {
      const expandedRows = this.currentData.filter((row: any) => row.expanded);

      rows = expandedRows.length === this.currentData.length;
    } else {
      return null;
    }

    if (this.groupingEnabled) {
      groups =
        this.groupedData.length === Object.keys(this.expandedGroups).length;
    } else {
      groups = true;
    }

    return rows && groups;
  }

  public get detailColumns() {
    let cols = this.detailEnabled
      ? this.handleColumnPriority(
          this.zipTableOptions.columns,
          'detailed',
          this.allowedColumns,
        )
      : [];

    if (this.groupingEnabled) {
      const groupProp = this.zipTableOptions.grouping.prop;

      if (
        (this.zipTableOptions.grouping.showInDetail &&
          this.detailedProps &&
          !this.detailedProps[groupProp]) ||
        (!this.zipTableOptions.grouping.showInDetail &&
          this.detailedProps &&
          this.detailedProps[this.zipTableOptions.grouping.prop])
      ) {
        cols = cols.filter((column: any) => column.prop !== groupProp);
      }
    }

    return cols;
  }

  /** basic call to retrieve initial data or update the current data based on what was passed into the table
   *  once we take care of keeping the data if its local... handle getting the data utilizing 'setData'
   * @param data - if relevant, the data that was provided to the zip-table
   * */
  retrieveData(init, data?) {
    if (!this.zipTableOptions.endpoint && data) {
      this.allLocalData = data;
    }
    this.filterData();
    this.setData();
  }

  refreshExternalData() {
    this.setData(1, true);
  }

  toggleExpandingGrouping(group) {
    if (!group) {
      return;
    }

    if (this.expandedGroups.hasOwnProperty(group.display)) {
      delete this.expandedGroups[group.display];
    } else {
      this.expandedGroups[group.display] = true;
    }
  }

  public expandRow(ind) {
    this.currentData[ind].expanded = !this.currentData[ind].expanded;

    return this.allRowsExpanded;
  }

  /** Normal functionality is toggling the expanded value unless a specific value is passed in */
  handleExpandingAllRows(specificVal?) {
    // we have to store the result of the getter so that once we do the mapping to one of the items
    // the getter doesnt get called again and give a different value
    const rowsExpanded = this.allRowsExpanded;

    // handle passing in a specific value
    const shouldExpand =
      specificVal === false || specificVal ? specificVal : !rowsExpanded;

    // important that we don't use a map here because we need to make sure we preserve the object equality
    // if we were to make a map, the two objects would then be different and
    // then we wouldn't be able to know the same object is still selected
    this.currentData.forEach((row, ind) => {
      this.currentData[ind].expanded = shouldExpand;
    });

    if (this.groupingEnabled) {
      this.groupedData.forEach((item: any) => {
        if (shouldExpand) {
          this.expandedGroups[item.display] = true;
        } else {
          delete this.expandedGroups[item.display];
        }
      });
    }

    // once were done doing the expanding, we have to make sure we call the getter so that the value updates itself
    // everywhere
    this.allRowsExpanded;
  }

  /** Handles converting the raw options provided to the table into the full options.
   * This includes defaulting any of the values not provided in the configuration to the component.
   * @param options - raw options provided to the table
   * */
  setupOptions(options) {
    if (options) {
      this.zipTableOptions = new ZipTable2(options);
      this.currentQueryParams = this.route.snapshot.queryParams;
    }

    // LOOK AWAY:
    // WE HAVE TO STOP A CHANGE DETECTION PROBLEM AND THIS IS NEEDED
    setTimeout(() => {
      this.tableInit = true;
    }, 0);
  }

  /** helper function to handle setting the current page
   * @param page - if relevant, the new page to go to
   * */
  setPage(page?: ZipTablePagedData2) {
    Object.assign(this.zipTableOptions.paging, page || {});
    this.setData(page);
  }

  /** helper function to handle setting the sorting values
   * @param column {Column2} - which column to sort on
   * @param order - which direction to sort the provided column by
   * */
  sortData(column: Column2, order: 'asc' | 'desc') {
    this.zipTableOptions.sorts = {};
    this.zipTableOptions.sorts[column.sortByProp || column.prop] = order;
    this.setData();
  }

  /** helper function to handle setting the filter values
   * @param filters - the filter object... if a user has put a value into the filter there will be a value prop on
   *     the individual filter
   * */
  filterData(filters?) {
    if (filters) {
      this.currentFilters = filters;
    }
    if (this.zipTableOptions) {
      this.zipTableOptions.paging.pageIndex = 0;
      this.zipTableOptions.filterValues = {};
      if (this.currentFilters) {
        this.currentFilters.forEach((filter: any) => {
          if (
            typeof filter.value !== 'string' ||
            (typeof filter.value === 'string' && filter.value !== '')
          ) {
            this.zipTableOptions.filterValues[filter.prop] = filter.value;
          }
        });
      }

      if (
        this.zipTableOptions.queryFilters &&
        this.zipTableOptions.queryFilters.length > 0
      ) {
        this.zipTableOptions.queryFilters.forEach((filter: any) => {
          const obj = {};

          obj[filter.prop] = this.currentQueryParams[filter.prop];
          if (
            this.currentQueryParams[filter.prop] !== null &&
            this.currentQueryParams[filter.prop] !== undefined
          ) {
            Object.assign(this.zipTableOptions.filterValues, obj);
          }
        });
      }
      this.setData();
    }
  }

  /** helper function to handle whether or not to handle the data by using the endpoint or by using custom filter/sort functionality
   * @param page - if relevant, provide a new page to be used further down
   * */
  setData(page?, breakCache = false) {
    // this means that we are calling things for the first time so see whether or not the table needs to do some
    // initial adjustments before the first call
    if (!this.currentData || !this.tableInit) {
      const sortConfig = getValue(this.zipTableOptions, 'sorting.init');
      const filterConfig = getValue(this.zipTableOptions, 'filters.init');

      if (filterConfig) {
        this.zipTableOptions.filterValues = filterConfig;
      }
      if (sortConfig) {
        this.zipTableOptions.sorts = sortConfig;
      }
    }

    // if we have an endpoint then get the data externally, otherwise handle everything internally
    if (this.zipTableOptions.endpoint) {
      this.getExternalData(page, breakCache);
    } else {
      this.getInternalData(page);
    }
  }

  /** handle getting internal data including filtering, sorting, and then paging
   * @param pageChanges - if relevant, provide a new page to be used
   * */
  getInternalData(pageChanges?) {
    let newObj = cloneObject(this.allLocalData || []);

    let isThisDataInit;

    if (!this.currentData) {
      isThisDataInit = true;
    }

    // filter all of the data for relevant filters
    newObj = this.filterInternal(newObj || []);

    // sort all of the data for relevant sorting
    newObj = this.sortInternal(newObj || []);

    // update the page data based on the new page
    this.zipTableOptions.paging = Object.assign(
      {},
      this.zipTableOptions.paging,
      pageChanges || {},
    );

    const firstIndex = this.zipTableOptions.paging.pageSize
      ? this.zipTableOptions.paging.pageIndex *
        this.zipTableOptions.paging.pageSize
      : 0;
    const secondIndex =
      this.zipTableOptions.paging.pageSize || this.allLocalData.length;

    // create the data that should be displayed for this page
    this.currentData = cloneObject(newObj || [])
      .splice(firstIndex, secondIndex)
      .map((row, ind) => {
        row['index'] = ind;

        return row;
      });

    if (isThisDataInit) {
      this.checkIfTableShouldBeExpandedOnOpen();
      this.checkIfTableShouldPreselectAResult();
    }

    // LOOK AWAY:
    // WE HAVE TO STOP A CHANGE DETECTION PROBLEM AND THIS IS NEEDED
    setTimeout(() => {
      this.selectedRowSubject.next(null);
    });
  }

  /** handle getting external data. Setup the url properly including filtering, sorting, and then paging.
   * @param pageChanges - if relevant, provide a new page to be used
   * */
  getExternalData(pageChanges?, breakCache = false): any {
    // build the query string based on all of the available data
    let queryString = this.zipTableOptions.endpoint;

    queryString += queryString.indexOf('?') > -1 ? '&' : '?';
    queryString += this.buildQueryParamsString(
      Object.assign({}, this.zipTableOptions.paging, pageChanges || {}),
      this.zipTableOptions.filterValues,
      this.zipTableOptions.sorts,
    );

    // attempts to stop the zip table from recalling the same endpoint multiple times without a change in the url
    // this is like a debouncer for the zip table
    let makeCall = true;

    if (this.zipTableOptions.endpointCache) {
      if (this.prevEndpoint === queryString && !breakCache) {
        makeCall = false;
      }
      this.prevEndpoint = queryString;
    }
    if (makeCall) {
      // set the table as busy and then get data from the external source
      this.loading = true;
      this.httpClient
        .get<any>(queryString)
        .pipe(
          map(
            (data: any) =>
              new ZipTablePagedData2(data, this.zipTableOptions.paging),
          ),
        )
        .subscribe(
          (pagedData: ZipTablePagedData2) => {
            this.zipTableOptions.paging.count = pagedData.page.count;
            if (this.zipTableOptions.endpoint) {
              this.zipTableOptions.exportConfig.exportLink = `${queryString}&format=csv`;
            }

            let isThisDataInit;

            if (!this.currentData) {
              isThisDataInit = true;
            }
            this.currentData = (pagedData.data || []).map((row, ind) => {
              row['index'] = ind;

              return row;
            });
            if (isThisDataInit) {
              this.checkIfTableShouldPreselectAResult();
              this.checkIfTableShouldBeExpandedOnOpen();
            }
            this.loading = false;
          },
          (error: any) => {
            console.error(error);
            this.loading = false;
          },
        );
    }
  }

  /** In cases where we can't do a local export because there are too many items, then add the async flag and handle the export */
  getAsyncExport(): any {
    return this.httpClient
      .get<any>(`${this.zipTableOptions.exportConfig.exportLink}&async=true`)
      .subscribe();
  }

  /** handle exporting local data */
  getSynchronousExport() {
    if (
      this.zipTableOptions.endpoint ||
      getValue(this, 'zipTableOptions.exportConfig.exportLink')
    ) {
      window.location.assign(this.zipTableOptions.exportConfig.exportLink);
    } else {
      // go through and loop through all of the data and create the csv string
      let csv = this.allLocalData.map((row: any) => {
        // clean each row by turning it into a string and mapping null values to an empty string
        const cleanedUpRow = this.zipTableOptions.columns.map((item: any) =>
          // stringify each value in the row
          // AND specify how you want to handle null values... were just going to make null values into empty
          // strings
          JSON.stringify(row[item.prop], (key, value) =>
            value === null ? '' : value,
          ),
        );

        return cleanedUpRow.join(',');
      });

      // add the header row by parsing through the columns
      csv.unshift(
        this.zipTableOptions.columns
          .map((item, ind) => {
            // extra logic to change an id field to "identifier"
            // because excel has some weird logic that makes it think its a different type of file
            if (ind === 0 && item.name.toLowerCase() === 'id') {
              return ` ${item.name}`;
            } else {
              // send back the name as the column name...
              // if a name wasnt provided then send the prop
              return item.name || item.prop;
            }
          })
          .join(','),
      );

      // just formatting logic for the csv itself
      csv = csv.join('\r\n');

      // setup a unique file name
      const date = new Date();
      const fileName =
        `${date.getUTCFullYear()}-${date.getUTCMonth()}-${date.getUTCDay()}` +
        `_${date.getUTCHours()}-${date.getUTCMinutes()}-${date.getUTCSeconds()}.csv`;

      // convert the csv string we created into a blog and  give it the correct content type to be used  during
      // download
      const blob = new Blob([csv], { type: 'text/csv;charset=UTF-8;' });

      // if the msSaveBlob function is there then use that
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, fileName);
      } else {
        // otherwise try to download the blob with the anchor tag's download attribute
        const link = document.createElement('a');

        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);

          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          // if that doesnt exist then just assign the current window to the blob so that it forces a
          // download. this is the safest way to do it cross browser
          window.location.assign(URL.createObjectURL(blob));
        }
      }
    }
  }

  /** handle creation of the query params based on the current page, filters, and sorting
   * @param page - representation of the current page that the table is on
   * @param filters - current filters keyed by prop name with the value of the filter
   * @param sorts - current sorts keyed by prop name with the value being the direction of the sort
   */
  public buildQueryParamsString(page, filters: any, sorts: any) {
    let paramString = '';
    // Adding pagination params
    const djangoPageNumber = (this.zipTableOptions.paging.pageIndex || 0) + 1;

    paramString += `page=${djangoPageNumber}&`;
    if (this.zipTableOptions.paging.pageSize) {
      paramString += `page_size=${this.zipTableOptions.paging.pageSize}&`;
    }
    if (sorts && Object.keys(sorts).length > 0) {
      paramString += 'ordering=';
      for (const sort in sorts) {
        if (sorts.hasOwnProperty(sort)) {
          paramString += sorts[sort] === 'asc' ? '' : '-';
          paramString += sort;
        }
      }
      paramString += '&';
    }
    if (filters) {
      // Adding filter params
      for (const filter in filters) {
        if (filters[filter]) {
          paramString += `${filter}=${filters[filter]}&`;
        }
      }
    }

    return paramString.slice(0, -1);
  }

  /** utility function for the table to be able to render a columns value including any pipes that should be used for formatting */
  public getValueIncludingFormat(data, column) {
    // set up relevant variables
    let value;

    // if a text is provided then just use the text as the value
    // otherwise find the value based on the prop provided..
    // then use the pipe to get the new value
    // also do a second pipe if relevant
    if (column.format) {
      const dataFromContext = getValue(data, column.mockProp || column.prop);

      value = this.formattingService.restructureValueBasedOnFormat(
        dataFromContext,
        column,
      );
    } else if (column.text) {
      value = column.text;
    } else {
      value = getValue(data, column.mockProp || column.prop);
    }

    if (column.mockLabel) {
      value = stringBuilder(column.mockLabel, { value: value });
    }

    return value;
  }

  checkIfRowSelected(row) {
    return this.selectedRow.find((selectedRow, ind) =>
      deepCompare(selectedRow.row, row, { expanded: true }),
    );
  }

  findSelectedRowIndex(row) {
    return this.selectedRow.findIndex((selectedRow, ind) =>
      deepCompare(selectedRow.row, row, { expanded: true }),
    );
  }

  unselectAllRows() {
    this.selectedRow = [];
  }

  markAllRowsAsSelected() {
    this.selectedRow = [];

    (this.currentData || []).forEach((row, ind) => {
      this.selectedRow.push({ row, ind });
    });

    this.selectedRowSubject.next(null);
  }

  handleRowSelection(row, ind = null) {
    if (
      this.zipTableOptions.selection.enabled &&
      !this.zipTableOptions.selection.multiple
    ) {
      if (this.selectedRow && this.checkIfRowSelected(row)) {
        this.selectedRow.splice(this.findSelectedRowIndex(row), 1);

        return SelectionTypes.deselected;
      } else {
        this.selectedRow.push({ row, ind });

        return SelectionTypes.selected;
      }
    } else if (
      this.zipTableOptions.selection.enabled &&
      this.zipTableOptions.selection.multiple
    ) {
      if (this.selectedRow && this.checkIfRowSelected(row)) {
        this.selectedRow = [];

        return SelectionTypes.deselected;
      } else {
        this.selectedRow.push({ row, ind });

        return SelectionTypes.selected;
      }
    }

    this.selectedRowSubject.next(null);
  }

  // makes sure that columns with greater priority get kept during a resizing of a table
  // IMPORTANT: THIS WORKS FOR BOTH DETAILED COLUMNS AND NORMAL COLUMNS SO PAY CLOSE ATTENTION BEFORE CHANGING THIS
  private handleColumnPriority(columns, type, allowedColumns) {
    let colCounter = 0;
    let detailedCounter = 0;

    // reset the column props and detailed props
    this.detailedProps = {};
    this.columnProps = {};

    // sort the columns by priority first and then by their original index
    // then filter out by the type that was provided to the function (detailed or column)
    // then resort by the original index so that the user doesnt see strange behavior
    return columns
      .sort((col, col2) => {
        // sort by priority and then by original index
        if (col.priority < col2.priority) {
          return 1;
        } else if (col.priority > col2.priority) {
          return -1;
        } else {
          if (col.originalIndex > col2.originalIndex) {
            return 1;
          } else if (col.originalIndex < col2.originalIndex) {
            return -1;
          } else {
            return 0;
          }
        }
      })
      .filter((col: any) => {
        // filter out the appropriate columns
        if (colCounter < allowedColumns) {
          colCounter++;

          const isColumn = type === 'columns';

          if (isColumn) {
            this.columnProps[col.prop] = true;
          }

          return isColumn;
        } else {
          detailedCounter++;

          const isDetailed = type === 'detailed';

          if (isDetailed) {
            this.detailedProps[col.prop] = true;
          }

          return isDetailed;
        }
      })
      .sort((col1, col2) => {
        // sort back by the original index
        if (col1.originalIndex > col2.originalIndex) {
          return 1;
        } else if (col1.originalIndex < col2.originalIndex) {
          return -1;
        } else {
          return 0;
        }
      });
  }
  /** local function to handle all of the filtering for when there is local data being passed in
   * @param data - the data to filter
   */
  private filterInternal(data) {
    if (this.zipTableOptions.filterValues && this.zipTableOptions.filters) {
      const keys = Object.keys(this.zipTableOptions.filterValues);

      if (keys.length > 0) {
        data = data.filter((ind_data: any) => {
          let shouldKeep = false;

          // using a for loop here so that we can break out if we have a hit that doesnt match
          for (let i = 0; i < keys.length; i++) {
            let numberSuccess = false;
            const key = keys[i];
            const val = getValue(ind_data, key);
            const filterVal = this.zipTableOptions.filterValues[key];

            // attempt to parse the string into a number
            let numberVersionOfStr;

            try {
              let numberVersionOfVal;

              if (typeof val === 'string') {
                numberVersionOfVal = Number.parseInt(val, DECIMAL);
              } else {
                numberVersionOfVal = val;
              }

              numberVersionOfStr = Number.parseInt(filterVal, DECIMAL);
              if (numberVersionOfVal === numberVersionOfStr) {
                shouldKeep = true;
                numberSuccess = true;
              }
            } catch (err) {
              console.error(err);
            }

            if (!numberSuccess) {
              if (
                (typeof val === 'string' && val.indexOf(filterVal) >= 0) ||
                (typeof val === 'number' && val === filterVal)
              ) {
                shouldKeep = true;
              } else {
                shouldKeep = false;
                break;
              }
            }
          }

          if (shouldKeep) {
            return ind_data;
          }
        });
      }
    }

    this.internalFilteredDataCount = data.length;

    return data;
  }

  /** local function to handle all of the sorting for when there is local data being passed in
   * @param data - the data to sort
   */
  private sortInternal(data) {
    if (this.zipTableOptions.sorts) {
      const sortKeys = Object.keys(this.zipTableOptions.sorts);

      data.sort((a, b) => {
        const key = sortKeys[0];

        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          // property doesn't exist on either object
          return 0;
        }
        const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
        const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

        let comparison = 0;

        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }

        return this.zipTableOptions.sorts[key] === 'desc'
          ? comparison * -1
          : comparison;
      });
    }

    return data;
  }

  private checkIfTableShouldPreselectAResult() {
    const selectionConfig = getValue(this.zipTableOptions, 'selection.init');

    if (selectionConfig) {
      const keys = Object.keys(selectionConfig);

      // check whether all of the criteria to look for are already in the url
      if (
        keys.filter((key: any) => this.route.snapshot.queryParams[key]).length >
        0
      ) {
        // check to see which pieces of data meet the selection criteria
        let firstInd;
        let firstData;

        // loop through all the current data and try to find pieces of data that match to be selectable...
        // and select the first one that we find
        for (let i = 0; i < this.currentData.length; i++) {
          const data = this.currentData[i];
          const selectable =
            keys.filter((key: any) => {
              const initVal = getValue(data, key);
              const value =
                typeof initVal === 'number' ? initVal.toString() : initVal;
              const whetherOrNotThisDataHasExpectedQueryPropValues =
                value === this.route.snapshot.queryParams[key];

              if (whetherOrNotThisDataHasExpectedQueryPropValues) {
                firstInd = i;
                firstData = data;
              }

              return whetherOrNotThisDataHasExpectedQueryPropValues;
            }).length === keys.length;

          if (selectable) {
            break;
          }
        }

        // since we have all of the data set this item as selecteds
        if (firstData) {
          const selectedData = { row: firstData, ind: firstInd };

          this.rowSelected.next(selectedData);
        }
      }
    }
  }

  private checkIfTableShouldBeExpandedOnOpen() {
    const startOpen = getValue(this.zipTableOptions, 'detail.init.startOpen');

    if (typeof startOpen === 'boolean') {
      this.handleExpandingAllRows(startOpen);
    } else if (typeof startOpen === 'number') {
      if (this.groupingEnabled) {
        this.toggleExpandingGrouping(this.groupedData[startOpen]);
      } else {
        this.expandRow(startOpen);
      }
    }
  }
}
