import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { schemaId } from '../shared/modules/cms';
import { AuthService, ConfigService, SquidexService } from '../shared/services';
import { SquidexConfig } from '../shared/modules/cms/squidex/model';
import { AnalyticsService } from '../shared/services/analytics.service';
import { SquidexModuleConstants } from './provider-portal.constant';
import { ProviderPortalService } from './provider-portal.service';
import { GenericServices } from './shared/services/generic.service';

@Component({
  selector: 'provider-portal',
  templateUrl: 'provider-portal.component.html',
  styleUrls: ['provider-portal.component.scss'],
})
export class ProviderPortalComponent implements OnInit, OnDestroy {
  public options: any;
  public footer: any;
  /*
        If for some reason you want to disable footer on a certain route
        Push false in the ng-onInit for that component:
            setTimeout(() => { this.genericServices.showFooter$.next(false); });
        Push true in the ng-onDestroy for that component:
            setTimeout(() => { this.genericServices.showFooter$.next(true); });
    */

  public showToolbar: boolean;
  public squidexConfig: SquidexConfig;
  public showFooter$: Observable<boolean>;
  private _routerSub: Subscription;

  constructor(
    private configService: ConfigService,
    public genericServices: GenericServices,
    private router: Router,
    private authService: AuthService,
    public analyticsService: AnalyticsService,
    private squidexService: SquidexService,
    private providerPortalService: ProviderPortalService,
  ) {
    this._routerSub = this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationEnd) {
        this.brodcastCXcaptureEvent(
          router.routerState,
          router.routerState.root,
        );
      }
    });
  }

  onActivate(): void {
    const time = 16;
    const position = 20;
    const scrollToTop = window.setInterval(() => {
      const pos: number = window.pageYOffset;

      if (pos > 0) {
        // how far to scroll on each step
        window.scrollTo(0, pos - position);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, time);
  }

  brodcastCXcaptureEvent(state, parent) {
    if (parent?.snapshot?.data?.event_key) {
      this.analyticsService.sendEvent(parent.snapshot.data.event_key);
    }

    if (state && parent) {
      this.brodcastCXcaptureEvent(state, state.firstChild(parent));
    }
  }

  ngOnInit(): void {
    this.options = this.configService.getPageConfig('navigation');
    this.squidexConfig =
      this.configService.getPageConfig<any>(
        'global',
      )?.contentManagementSystem?.squidex;
    this.showFooter$ = this.genericServices.showOrHideFooter;
    this.showToolbar = !!this.authService.user;

    if (this.squidexConfig?.enabled) {
      this.squidexService.initSquidex();
    }

    if (
      this.squidexConfig?.enabled &&
      this.squidexConfig?.enableForModule?.footer
    ) {
      this.applySquidexContent();
    } else {
      this.footer = this.configService.getPageConfig('footer');
    }
  }

  // Removing schemaId field from squidex config, as its not required in portalFooterConfig.
  updateSquidexConfig(squidexFooterConfig: object): object {
    if (squidexFooterConfig === Object(squidexFooterConfig)) {
      Object.keys(squidexFooterConfig).forEach((item: string) => {
        this.updateSquidexConfig(squidexFooterConfig[item]);
        if (item === schemaId) delete squidexFooterConfig[item];
      });

      return squidexFooterConfig;
    }
  }

  ngOnDestroy() {
    this._routerSub.unsubscribe();
  }

  private applySquidexContent(): void {
    this.providerPortalService
      .getSquidexContent(SquidexModuleConstants.portalFooter)
      .subscribe((squidexFooterConfig: any) => {
        this.footer = this.updateSquidexConfig(squidexFooterConfig);
      });
  }
}
