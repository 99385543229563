<div [zipBusy]="busy">
  <div class="row header-row">
    <div class="row-item row-header">
      <p class="t-bold t-data row-header">
        {{ config?.title }}
      </p>
    </div>
    <div>
      <zip-button
        [config]="config?.button"
        (zipButtonClicked)="setActiveRow(config?.button?.route)"
      ></zip-button>
    </div>
  </div>
  <div>
    <datagrid
      [config]="config?.table"
      [data]="responseData"
      (datagridRowSelected)="setActiveRow($event)"
    ></datagrid>
  </div>
  <footer
    class="dashboard-card__footer"
    [ngClass]="{
      'dashboard-card__footer--justify': config?.footer?.left?.content,
    }"
    *ngIf="config?.footer?.left?.content || config?.footer?.right?.content"
  >
    <ng-container *ngIf="config?.footer?.left?.content">
      <zip-button
        [config]="config?.footer?.left"
        (zipButtonClicked)="setActiveRow(config?.footer?.left?.route)"
      >
      </zip-button>
    </ng-container>

    <ng-container *ngIf="config?.footer?.right?.content">
      <zip-button
        [config]="config?.footer?.right"
        (zipButtonClicked)="setActiveRow(config?.footer?.right?.route)"
      >
      </zip-button>
    </ng-container>
  </footer>
</div>
