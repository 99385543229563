import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIService } from '@zipari/web-services';
import Member from '../../../shared/models/shared/Member.model';
import { ApiListResponse } from '../../../shared/models/shared/ApiListResponse.model';
import Policy from '../../../shared/models/shared/Policy.model';
import Benefit from '../../../shared/models/shared/Benefit.model';
import PlanBenefit from '../../../shared/models/shared/PlanBenefit.model';
import MemberPCP from '../../../shared/models/shared/MemberPCP.model';
import { IdCard } from '../../../shared/models/shared/IdCard.model';
import { PatientDetailContext } from './patient-detail.constant';
import { MemberAlert } from './patient-detail.model';
@Injectable({
  providedIn: 'root',
})
export class PatientDetailService {
  context: PatientDetailContext = new PatientDetailContext();
  tabsConfig: any;

  constructor(private api: APIService) {}

  public getMember(api: string): Observable<Member> {
    return this.api.get(api);
  }

  public getPolicies(api: string): Observable<ApiListResponse<Policy>> {
    return this.api.get(api);
  }

  public getBenefits(api: string): Observable<ApiListResponse<Benefit>> {
    return this.api.get(api);
  }

  public getPlanBenefits(
    api: string,
  ): Observable<ApiListResponse<PlanBenefit>> {
    return this.api.get(api);
  }

  public getMemberPCP(api: string): Observable<ApiListResponse<MemberPCP>> {
    return this.api.get(api);
  }

  public getIdCards(api: string): Observable<ApiListResponse<IdCard>> {
    return this.api.get(api);
  }

  public getMemberAdministrativeAlerts(
    endpoint: string,
  ): Observable<ApiListResponse<MemberAlert>> {
    return this.api.get(endpoint);
  }
}
