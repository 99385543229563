/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TableComponent } from '@zipari/design-system';
import { cloneObject, getValue } from '@zipari/web-utils';
import { AllControlsConfiguration } from '@zipari/shared-ds-util-form';
import { APIService } from '@zipari/web-services';
import { identity, isEqual, pickBy } from 'lodash';
import * as moment from 'moment';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { GenericServices } from '../../../../shared/services/generic.service';
import { ConfigService } from '../../../../../shared/services';
import { CX_CALLS } from '../../../../../shared/constants/cx-calls.constant';
import { stateOptions } from '../../controls/address.constants';
import { LocationHoursComponent } from '../../controls/location-hours/location-hours.component';
import { ProviderUpdateToolService } from '../../provider-update-tool.service';
import { AnalyticsService } from '../../../../../shared/services/analytics.service';
import { PROVIDER_IDENTIFIERS } from '../../site-locations-attestation/site-location-attestation.constant';
import { toCamel } from '../../../../../shared/utilities/case-conversion.utils';
import {
  AdditionalDetailsModalConfig,
  ProviderIdentifierPayload,
} from '../../site-locations-attestation/site-locations-attestation.model';
import { TableCellClickEvent } from '../../../../../shared/models/shared/CellClickEvent.model';
import { setAdditionalDetailPayload } from '../../site-locations-attestation/site-location-attestation.helper';
import { SiteLocationDetailsContextKeys } from './manage-site-location-detail.constant';

@Directive({
  selector: '[postInit]',
})

// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class PostInit implements AfterViewInit {
  @Output() postInit: EventEmitter<any> = new EventEmitter();

  ngAfterViewInit(): void {
    this.postInit.emit();
  }
}

@Component({
  styleUrls: ['./manage-site-location-detail.component.scss'],
  selector: 'manage-site-location-detail',
  templateUrl: './manage-site-location-detail.component.html',
})
export class ManageSiteLocationDetailComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @ViewChild(TableComponent) table: TableComponent;
  @ViewChild(LocationHoursComponent) locationHours: LocationHoursComponent;
  showModal = false;
  facility_location_details_call: Subscription;
  supporting_calls: Subscription;
  config: any;
  id: any;
  error = false;
  context: any;
  loaded: boolean;
  termResponse: any;
  addResponse: any;
  editModal: boolean;
  deleteState: 'edit' | 'review' = 'edit';
  addState: 'add' | 'review' | 'confirm' = 'add';
  associateModal = false;
  selectedTab = 0;
  openAccordionIdx = 0;
  itemsToDelete: any[] = [];
  terminateFlyoutFields = new UntypedFormGroup({});
  editFields = new UntypedFormGroup({});
  associateFields: any[] = [];
  currentTab = 0;
  selectedRows: any[] = [];
  tableReady = false;
  associateFlyoutReady = false;
  location_hours_form = new UntypedFormGroup({});
  operating_hours: any = {};
  dropdowns = false;
  snapshot: any = {};
  payload: any = {};
  payloadExit: any = {};
  hasThirdParty = false;
  hasBilling = false;
  editResponse: any;
  mainLoading = false;
  showAdditionalDetailsModal = false;
  additionalDetailsForm: UntypedFormGroup = new UntypedFormGroup({});
  locationOpenDays: string[] = [];
  constructor(
    private route: ActivatedRoute,
    private el: ElementRef,
    private api: APIService,
    private router: Router,
    private fb: UntypedFormBuilder,
    public configService: ConfigService,
    public providerUpdateToolService: ProviderUpdateToolService,
    private cdr: ChangeDetectorRef,
    public genericServices: GenericServices,
    public analyticsService: AnalyticsService,
  ) {}

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.genericServices.showFooter$.next(false);
    });
    this.config = this.configService.getPageConfig('site-location-detail');
    this.id = this.route.snapshot.params.id;
    this.context = {};
    const tabConfigIdx = 2;

    if (this.config?.tabs?.tabConfigs[tabConfigIdx]?.table?.endpoint) {
      this.config.tabs.tabConfigs[tabConfigIdx].table.endpoint +=
        `/${this.id}/?is_terminated=false&facility_location_id=${this.id};`;
      this.tableReady = true;
    }

    if (this.config?.associateFlyout?.table?.endpoint) {
      this.config.associateFlyout.table.endpoint += `?not_associated_with_facility_location_id=${this.id}`;
      this.associateFlyoutReady = true;
    }
    this.genericServices.subscriptions.push(
      this.api
        .get(
          this.setParameter(
            this.config?.endpoints.facility_location_details,
            'id',
            this.id,
          ),
        )
        .subscribe((response: any) => {
          this.context = cloneObject(response);
          // TODO: refactor, the API make it impossible to not have these
          const specialties: any = [];

          response.specialties.map((item: any) => {
            specialties.push(item.name);
          });
          this.context.specialties = specialties;
          const affiliated_hospital_facility_ids: any = [];

          if (response?.affiliated_hospital_facility_ids?.length) {
            this.genericServices.subscriptions.push(
              this.makeMultipleCalls(
                response.affiliated_hospital_facility_ids,
              ).subscribe((calls: any[]) => {
                calls.forEach((item: any) => {
                  affiliated_hospital_facility_ids.push(
                    item?.name ? item.name : 'Invalid Name',
                  );
                });
                this.context.facility_list = affiliated_hospital_facility_ids;
                this.mainLoading = true;
              }),
            );
          } else {
            this.mainLoading = true;
          }
          this.context.facility_list = affiliated_hospital_facility_ids;
          let phone_numbers: any = [];

          response.phone_numbers.map((item: any) => {
            phone_numbers.push(item?.phone_number);
          });
          response.phone_numbers = phone_numbers;
          this.context.phone_numbers = phone_numbers;
          const restrictions: any = [];

          response.restrictions.map((item: any) => {
            restrictions.push(item.code);
          });
          this.context.restrictions = restrictions;
          const languages: any = [];

          response.languages.map((item: any) => {
            languages.push(item.name);
          });
          this.context.languages = languages;
          const services: any = [];

          response.services.map((item: any) => {
            services.push(item.name);
          });
          this.context.services = services;

          // todo: refactor
          // location_hours:
          // $HH:mm am/pm
          if (response.operating_hours) {
            const operating_hours: any = {};

            operating_hours.friday = this.findHours(response, 'friday');
            operating_hours.saturday = this.findHours(response, 'saturday');
            operating_hours.sunday = this.findHours(response, 'sunday');
            operating_hours.monday = this.findHours(response, 'monday');
            operating_hours.tuesday = this.findHours(response, 'tuesday');
            operating_hours.wednesday = this.findHours(response, 'wednesday');
            operating_hours.thursday = this.findHours(response, 'thursday');
            this.context.operating_hours = operating_hours;
            response.operating_hours.forEach((element: any) => {
              element.opening_time = moment(
                element.opening_time,
                'h:mm a',
              ).format('HH:mm');
              element.closing_time = moment(
                element.closing_time,
                'h:mm a',
              ).format('HH:mm');
              this.context[element.day] = element;
            });
          }

          // billing data
          let billing_details: any = {};

          if (response.billing_information) {
            billing_details = response.billing_information.find(
              (item: any) => item.is_third_party === false,
            );
          }
          this.context.billing_details = billing_details;
          if (!this.context.billing_details) {
            this.context.billing_details = {
              name: '',
              npi: '',
              phone_number: '',
              tax_id: '',
              address: {},
              is_third_party: false,
            };
          } else {
            this.hasBilling = true;
            if (!this.context.billing_details.address) {
              this.context.billing_details.address = {};
            } else {
              const temp: any = this.context.billing_details.address;

              this.context.billing_details.address = {
                street_name_1: temp.street_name_1,
                street_name_2: temp.street_name_2,
                city_name: temp.city_name,
                zip_code: temp.zip_code,
                state: temp.state,
              };
              this.cleanObj(this.context.billing_details, 'address');
            }
          }
          // thirdparty billing
          let third_part_billing_details: any = {};

          if (response.billing_information) {
            third_part_billing_details = response.billing_information.find(
              (item: any) => item.is_third_party === true,
            );
          }
          this.context.third_part_billing_details = third_part_billing_details;
          if (!this.context.third_part_billing_details) {
            this.context.third_part_billing_details = {
              name: '',
              effective_date: '',
              phone_number: '',
              fax_number: '',
              address: {},
              is_third_party: true,
            };
          } else {
            this.hasThirdParty = true;
            if (!this.context.third_part_billing_details.address) {
              this.context.third_part_billing_details.address = {};
            } else {
              const temp: any = this.context.third_part_billing_details.address;

              this.context.third_part_billing_details.address = {
                street_name_1: temp.street_name_1,
                street_name_2: temp.street_name_2,
                city_name: temp.city_name,
                zip_code: temp.zip_code,
                state: temp.state,
              };
              this.cleanObj(this.context.third_part_billing_details, 'address');
            }
          }
          let location_contact: any = {};

          location_contact = response.location_contacts.find(
            (item: any) => item.is_primary === true,
          );
          if (location_contact === undefined) {
            location_contact = response.location_contacts[0];
          }
          if (location_contact) {
            response.location_contact = location_contact;
            // eslint-disable-next-line no-shadow
            phone_numbers = [];
            location_contact.phone_numbers.map((item: any) => {
              phone_numbers.push(item?.phone_number);
            });
            location_contact.phone_numbers = phone_numbers;
          }
          if (location_contact?.addresses) {
            this.context.contact_address = location_contact.addresses[0];
          }
          if (!location_contact) {
            location_contact = {
              name: {},
            };
          }
          this.context.location_contact = location_contact;
          const editArrayFields = {};
          const _context = {};
          const _state = {};

          for (const tab of this.config?.tabs?.tabConfigs) {
            if (tab?.editFlyout) {
              for (const formfield of tab.editFlyout.formFields) {
                if (formfield.array_controls) {
                  for (const control of formfield.array_controls) {
                    editArrayFields[control.prop] = this.fb.array([
                      this.fb.group({
                        selection: [
                          '',
                          this.providerUpdateToolService.getValidators(
                            control.validatorType,
                          ),
                        ],
                      }),
                    ]);
                    if (control.defaultProp) {
                      _context[control.prop] = getValue(
                        response,
                        control.defaultProp,
                      );
                      _state[control.prop] = false;
                    }
                  }
                }
                if (formfield.controls) {
                  for (const control of formfield.controls) {
                    if (
                      control.type === 'basicAddress' &&
                      control.cityStateZipConfig?.length
                    ) {
                      const stateControl = control.cityStateZipConfig.find(
                        (item: any) => item.prop === 'state',
                      );

                      if (stateControl) {
                        stateControl.options = stateOptions;
                      }
                      // loop thru each internal control and set a default based on defaultProp
                      if (control.cityStateZipConfig) {
                        control.cityStateZipConfig.forEach(
                          (formControl: any) => {
                            if (
                              formControl.defaultProp ||
                              formControl.defaultStartProp ||
                              formControl.defaultEndProp
                            ) {
                              this.setControlProps(formControl);
                            }
                          },
                        );
                      }
                      if (control.streetAddressConfig) {
                        control.streetAddressConfig.forEach(
                          (formControl: any) => {
                            if (
                              formControl.defaultProp ||
                              formControl.defaultStartProp ||
                              formControl.defaultEndProp
                            ) {
                              this.setControlProps(formControl);
                            }
                          },
                        );
                      }
                      this.dropdowns = true;
                    } else if (control.apiEndpoint) {
                      this.genericServices.subscriptions.push(
                        this.api
                          .get(control.apiEndpoint)
                          .subscribe((resp: any) => {
                            control.options = this.populateDropdown(
                              new Array<any>(),
                              resp.results,
                              control.dropdownDisplayProp,
                            );
                            this.setControlProps(control);
                            this.dropdowns = true;
                          }),
                      );
                    } else if (
                      control.defaultProp ||
                      control.defaultStartProp ||
                      control.defaultEndProp
                    ) {
                      this.dropdowns = true;
                      this.setControlProps(control);
                    } else {
                      this.dropdowns = true;
                    }
                  }
                }
              }
            }

            this.providerUpdateToolService.setFormGroup(
              'editSiteDetails',
              this.fb.group(editArrayFields),
            );
            this.providerUpdateToolService.setContextData(
              'editSiteDetails',
              _context,
            );
            this.providerUpdateToolService.setInitState(
              'editSiteDetails',
              _state,
            );
            this.loaded = true;

            this.buildSnapshot();
          }
        }),
    );

    for (const control of this.config?.associateFlyout.controls) {
      if (control.apiEndpoint) {
        this.genericServices.subscriptions.push(
          this.api.get(control.apiEndpoint).subscribe((resp: any) => {
            control.options = this.populateDropdown(
              new Array<any>(),
              resp.results,
              control.dropdownDisplayProp,
            );
          }),
        );
      }
      if (
        control.defaultProp ||
        control.defaultStartProp ||
        control.defaultEndProp
      ) {
        this.setControlProps(control);
      }
    }
    this.config?.tabs?.tabConfigs[2]?.delete_modal.controls.forEach(
      (element: any) => {
        if (element.prop === 'deactivation_date') {
          element['default'] = moment(new Date()).format('YYYY-MM-DD');
        }
      },
    );
    this.analyticsService.sendEvent(
      CX_CALLS.pp_add_site_location_viewed.event_key,
    );
    this.filterProfileSectionAttributes();
  }

  findHours(facility_location_details: any, day: any): string {
    let retVal: string = null;

    facility_location_details.operating_hours.find((item: any) => {
      if ((item.day as string)?.toLowerCase() === day) {
        retVal = `${
          item.opening_time?.length ? item.opening_time : 'Invalid date'
        } to ${item.closing_time?.length ? item.closing_time : 'Invalid date'}`;
      }
    });

    return retVal;
  }

  buildSnapshot(): void {
    this.payload = {
      name: this.context.name,
      type: this.context.type,
      address: {
        city_name: this.context.address?.city_name,
        state: this.context.address?.state,
        street_name_1: this.context.address?.street_name_1,
        street_name_2: this.context.address?.street_name_2,
        zip_code: this.context.address?.zip_code,
      },
      contact_address: {
        city_name: this.context.contact_address?.city_name,
        state: this.context.contact_address?.state,
        street_name_1: this.context.contact_address?.street_name_1,
        street_name_2: this.context.contact_address?.street_name_2,
        zip_code: this.context.contact_address?.zip_code,
      },
      is_primary: this.context.is_primary === true,
      website_url:
        this.context.website_url === null ? '' : this.context.website_url,
      tax_id: this.context.tax_id,
      npi: this.context.npi,
      // "state"
      medicare_id:
        this.context.medicare_id === null ? '' : this.context.medicare_id,
      specialties: this.context.specialties,
      phone_numbers: this.context.phone_numbers.map((item: any) => {
        if (item) return { phone_number: item, extension: 1 };
      }),
      fax_numbers: this.context.fax_numbers,
      taxonomy_codes: this.context.taxonomy_codes,
      location_contact: this.context.location_contact,
      languages: this.context.languages,
      restrictions: this.context.restrictions,
      services: this.context.services,
      care_system: this.context.healthcare_system
        ? this.context.healthcare_system.name
        : '',
      affiliated_hospital_facility_ids:
        this.context.affiliated_hospital_facility_ids,
      administration_attributes: this.context.administration_attributes,
      operating_hours: pickBy(this.context.operating_hours, identity),
      billing_details: this.context.billing_details,
      third_part_billing_details: this.context.third_part_billing_details,
      number: this.context.number,
    };
    // if (this.payload["administration_attributes"]?.)
    if (this.payload['administration_attributes']) {
      for (const [key, value] of Object.entries(
        this.payload['administration_attributes'],
      )) {
        if (
          key === 'is_cultural_competency_training_complete' &&
          value !== true &&
          value !== false
        ) {
          this.payload['administration_attributes'][key] = false;
        }
      }
    }
    this.snapshot = JSON.parse(JSON.stringify(this.payload));
    this.cleanObj(this.snapshot, 'address');
    this.cleanObj(this.snapshot, 'contact_address');
  }

  cleanObj(obj: any, keyString: any): void {
    if (obj && obj[keyString]) {
      Object.keys(obj[keyString]).forEach(
        (key: any) =>
          (obj[keyString][key] === null ||
            obj[keyString][key] === undefined ||
            obj[keyString][key] === '') &&
          delete obj[keyString][key],
      );
    }
  }

  clean(obj: any): void {
    if (obj) {
      Object.keys(obj).forEach(
        (key: string) =>
          (obj[key] === null || obj[key] === undefined || obj[key] === '') &&
          delete obj[key],
      );
    }
  }

  setControlProps(control: any): void {
    if (control.defaultProp) {
      const defaultValue = getValue(this.context, control.defaultProp);

      control['default'] = defaultValue;
    }
    if (control.defaultStartProp) {
      const defaultStartValue = getValue(
        this.context,
        control.defaultStartProp,
      );

      control['start'] = defaultStartValue;
    }
    if (control.defaultEndProp) {
      const defaultEndValue = getValue(this.context, control.defaultEndProp);

      control['end'] = defaultEndValue;
    }
  }

  populateDropdown(options: any[], results: any[], prop: string): any {
    for (const item of results) {
      options.push({
        label: this.getObjectProperty(item, prop),
        value: item,
      });
    }

    return options;
  }

  getAssociatedFields(index: number): any {
    if (!this.associateFields[index]) {
      this.associateFields[index] = new UntypedFormGroup({});
    }

    return this.associateFields[index];
  }

  getAssociatedFieldValues(): any[] {
    const returnVal: any[] = [];

    this.associateFields.forEach((item: any) => {
      returnVal.push(item.value);
    });

    return returnVal;
  }

  getAssociatedFieldValue(index: number): any {
    return this.associateFields[index].value;
  }

  get additionalDetailsModalConfig() {
    return this.config?.tabs?.tabConfigs[this.currentTab]
      ?.additionalDetailsModal;
  }

  setParameter(url: string, field: string, value: string): string {
    return url?.replace(`$\{${field}}`, value);
  }

  back(event: any): void {
    this.router.navigate(['provider-portal/site-locations/']);
  }

  handleTabSelected(event: any): void {
    this.currentTab = event;
  }

  setHeaderCSS(headerEditIcon: boolean): any {
    return {
      ['isCursor']: headerEditIcon,
    };
  }

  openEditModalIfNeeded(
    event,
    headerEditIcon: boolean,
    target,
    idx: number,
  ): void {
    if (headerEditIcon) this.openEditModal(target, idx);
    else {
      event.stopPropagation();
    }
  }

  openEditModal(target?: any, idx?: number): void {
    this.editModal = true;
    this.openAccordionIdx = idx;
  }

  remove(index: number): void {
    if (index > -1) {
      this.itemsToDelete.splice(index, 1);
    }
  }

  toggleEdit(): void {
    this.editModal = false;
  }

  handleModalCancel(): void {
    this.showModal = !this.showModal;
  }

  getObjectProperty(obj, keys): any {
    if (!obj) return '';
    if (!keys) return '';

    return keys.split('.').reduce(function (result, key) {
      if (!result) return;

      return result[key];
    }, obj);
  }

  deleteClicked(event: any[]): void {
    this.analyticsService.sendEvent(
      CX_CALLS.pp_add_site_location_practitioner_association_terminated
        .event_key,
    );
    this.terminateFlyoutFields = new UntypedFormGroup({});
    this.deleteState = 'edit';
    if (this.config?.tabs?.tabConfigs[2]?.delete_modal && event.length > 0) {
      this.itemsToDelete = event;
      this.showModal = !this.showModal;
    }
  }

  toggleError(): void {
    this.error = !this.error;
  }

  resetAssociatedFields(): void {
    this.associateFields = [];
  }

  toggleAssociate(): void {
    this.resetAssociatedFields();
    this.associateModal = !this.associateModal;
  }

  navigateToDetail(event: TableCellClickEvent): void {
    if (event.context?.row?.id) {
      this.router.navigate([
        `/provider-portal/practitioners/detail/${event.context.row.provider_id}`,
      ]);
    }
  }

  submitDelete(event: any) {
    if (!this.terminateFlyoutFields.valid || !this.itemsToDelete?.length) {
      return true;
    }
    if (this.terminateFlyoutFields.valid) {
      if (this.terminateFlyoutFields?.value) {
        const facility_location_providers: any[] = [];

        this.itemsToDelete.forEach((item: any) => {
          const _item: any = {};

          _item['termination_date'] =
            this.terminateFlyoutFields?.value.deactivation_date;
          _item['facility_location_id'] = item.row.facility_location_id;
          _item['provider_id'] = item.row.provider_id;
          _item['last_updated_notes'] = this.terminateFlyoutFields?.value.notes;
          _item['id'] = item.row.id;
          facility_location_providers.push(_item);
        });

        const payload: any = {
          should_fail_on_error: false,
          operation_type: 'update',
          facility_location_providers: facility_location_providers,
        };

        this.genericServices.subscriptions.push(
          this.api.post(this.config.endpoints.batch, payload).subscribe(
            (res: any) => {
              this.termResponse = res;
              this.table.unselectAllRows();
              this.table.refresh();
              this.deleteState = 'review';
            },
            () => {
              this.handleModalCancel();
              this.toggleError();
            },
          ),
        );
      }
    }
  }
  filterAdditionalDetails(additionalDetails: any[], prop: string): any[] {
    return additionalDetails
      ? additionalDetails.filter((control: any) => control.prop !== prop)
      : [];
  }

  filterProfileSectionAttributes(): void {
    this.config?.tabs?.tabConfigs[0].sections?.map((element: any) => {
      if (!this.additionalDetailsModalConfig?.enableNotes) {
        element.fields = this.filterAdditionalDetails(
          element.fields,
          SiteLocationDetailsContextKeys.changeRequestNotes,
        );
      }
      if (!this.additionalDetailsModalConfig?.enableEffectiveDate) {
        element.fields = this.filterAdditionalDetails(
          element.fields,
          SiteLocationDetailsContextKeys.changeRequestEffectiveDate,
        );
      }

      return element;
    });
  }

  editSubmit(additionalDetailsModalConfig: AdditionalDetailsModalConfig): void {
    if (
      this.providerUpdateToolService.showModal(additionalDetailsModalConfig)
    ) {
      this.showAdditionalDetailsModal = true;
      this.editModal = false;
    } else {
      this.submit();
    }
  }

  submit() {
    this.showAdditionalDetailsModal = false;
    if (this.isDisabled()) return true;
    this.updatePayload();
    if (this.comparePayloads()) {
      this.payloadExit['id'] = this.id;
      this.genericServices.subscriptions.push(
        this.api
          .patch(
            this.setParameter(
              this.config.endpoints.facility_location_details,
              'id',
              this.id,
            ),
            this.payloadExit,
          )
          .subscribe(
            (res: any) => {
              this.mainLoading = false;
              this.editResponse = res;
              this.ngOnInit();
              this.selectedTab = this.currentTab;
              this.toggleEdit();
              if (this.additionalDetailsForm) {
                this.additionalDetailsForm.reset();
              }
            },
            () => {
              this.toggleError();
              this.toggleEdit();
            },
          ),
      );
    }
  }

  setData(flag: boolean, is_third_party_value, object: any, target: any): any {
    if (flag) {
      object = this.payloadExit.billing_information.find(
        (item: any) => item.is_third_party === is_third_party_value,
      );
    }
    if (target.tax_id) object['tax_id'] = target.tax_id;
    if (target.name) object['name'] = target.name;
    if (target.termination_date) {
      object['termination_date'] = target.termination_date;
    }
    if (target.phone_number) object['phone_number'] = target.phone_number;
    if (Object?.keys(target.address).length) object['address'] = {};
    if (target.address?.street_name_1) {
      object['address']['street_name_1'] = target.address?.street_name_1;
    }
    if (target.address?.street_name_2) {
      object['address']['street_name_2'] = target.address?.street_name_2;
    }
    if (target.address?.city_name) {
      object['address']['city_name'] = target.address?.city_name;
    }
    if (target.address?.zip_code) {
      object['address']['zip_code'] = target.address?.zip_code;
    }
    if (target.address?.state) {
      object['address']['state'] = target.address?.state;
    }
    if (target.npi) object['npi'] = target.npi;
    object['is_third_party'] = target.is_third_party ? true : false;
    if (target.effective_date) object['effective_date'] = target.effective_date;
    if (target.fax_number) object['fax_number'] = target.fax_number;
    if (target.id) object['id'] = target.id;

    return object;
  }

  comparePayloads(): boolean {
    const compareObject = cloneObject(this.payload);
    let returnBool = false;

    if (this.payload.specialties) {
      compareObject.specialties = this.payload.specialties.map(
        (item: any) => item.name,
      );
    }
    if (this.payload.languages) {
      compareObject.languages = this.payload.languages.map(
        (item: any) => item.name,
      );
    }
    if (this.payload.restrictions) {
      compareObject.restrictions = this.payload.restrictions.map(
        (item: any) => item.code,
      );
    }
    if (this.payload.services) {
      compareObject.services = this.payload.services.map(
        (item: any) => item.name,
      );
    }
    if (this.payload.affiliated_hospital_facility_ids) {
      compareObject.affiliated_hospital_facility_ids =
        this.payload.affiliated_hospital_facility_ids.map(
          (item: any) => item.id,
        );
      this.payload.affiliated_hospital_facility_ids =
        compareObject.affiliated_hospital_facility_ids;
    }

    this.payloadExit = {};
    for (const key in compareObject) {
      const compareFrom = compareObject[key];
      const copyFrom = this.payload[key];
      const compareTo = this.snapshot[key];

      if (!isEqual(compareFrom, compareTo)) {
        this.payloadExit[key] = copyFrom;
        returnBool = true;
      }
    }
    // compare inside attribs:

    // post parse:
    this.locationOpenDays = this.locationHours
      ? this.locationHours.getEnabled()
      : this.locationOpenDays;
    const hours: any[] = [];
    const locationValues: any = this.location_hours_form.value;

    if (this.payloadExit.operating_hours) {
      Object.entries(this.location_hours_form.value).forEach((value: any) => {
        // if (enabled.find(item => item === value[0]))
        if (
          this.locationOpenDays.find((item: any) => {
            if (
              item === value[0] &&
              (value[1]['start']?.length || value[1]['end']?.length)
            ) {
              return true;
            } else return false;
          })
        ) {
          const hour: any = {};

          if (value[1]['start']) {
            hour['opening_time'] = moment(value[1]['start'], 'HH:mm').format(
              'hh:mm a',
            );
          }
          if (value[0]) hour['day'] = value[0];
          if (value[1]['end']) {
            hour['closing_time'] = moment(value[1]['end'], 'HH:mm').format(
              'hh:mm a',
            );
          }
          hours.push(hour);
        }
      });
      this.payloadExit.operating_hours = hours;
    } else if (
      this.payloadExit &&
      !this.payloadExit.operating_hours &&
      this.payload &&
      !this.payload.operating_hours &&
      locationValues &&
      Object.keys(locationValues).length &&
      this.snapshot.operating_hours &&
      Object.keys(this.snapshot.operating_hours).length
    ) {
      returnBool = true;
      this.payloadExit.operating_hours = [];
    }

    if (this.payloadExit.location_contact) {
      if (this.payloadExit.contact_address) {
        this.payloadExit.location_contact.addresses = [];
        this.payloadExit.location_contact.addresses.push(
          this.payloadExit['contact_address'],
        );
        delete this.payloadExit['contact_address'];
      }
      if (this.payloadExit.location_contact.phone_numbers) {
        this.payloadExit.location_contact.phone_numbers =
          this.payloadExit.location_contact.phone_numbers.map((item: any) => ({
            phone_number: item,
            extension: 1,
          }));
      }

      this.payloadExit.location_contacts = [];
      this.payloadExit.location_contacts.push(
        this.payloadExit.location_contact,
      );
      delete this.payloadExit['location_contact'];
    } else if (this.payloadExit.contact_address) {
      this.payloadExit.location_contacts = [];
      const _ob: any = this.payload['location_contact'];

      if (_ob) {
        _ob.addresses = [];
        _ob?.addresses.push(this.payloadExit['contact_address']);
      }
      this.payloadExit.location_contacts.push(_ob);
      delete this.payloadExit['contact_address'];
    }
    if (this.payloadExit?.location_contacts?.length) {
      this.payloadExit.location_contacts.forEach((item: any) => {
        if (item?.addresses?.length) {
          item.addresses.forEach((addr: any) => {
            this.clean(addr);
          });
        }
        this.cleanObj(item, 'name');
        this.clean(item);
      });
    }

    this.payloadExit.billing_information = [];
    if (this.hasBilling) {
      this.payloadExit.billing_information.push(this.context.billing_details);
    }
    if (this.hasThirdParty) {
      this.payloadExit.billing_information.push(
        this.context.third_part_billing_details,
      );
    }
    if (this.payloadExit.billing_details) {
      let billing_details: any = {};

      billing_details = this.setData(
        this.hasBilling,
        false,
        billing_details,
        this.payloadExit.billing_details,
      );
      this.cleanObj(billing_details, 'address');
      this.clean(billing_details);
      if (!this.hasBilling) {
        this.payloadExit.billing_information.push(billing_details);
      }
    }
    if (this.payloadExit.third_part_billing_details) {
      let third_part_billing_details: any = {};

      third_part_billing_details = this.setData(
        this.hasThirdParty,
        true,
        third_part_billing_details,
        this.payloadExit.third_part_billing_details,
      );
      this.cleanObj(third_part_billing_details, 'address');
      this.clean(third_part_billing_details);
      if (!this.hasThirdParty) {
        this.payloadExit.billing_information.push(third_part_billing_details);
      }
    }
    if (
      !this.payloadExit.billing_details &&
      !this.payloadExit.third_part_billing_details
    ) {
      delete this.payloadExit['billing_information'];
    }
    if (this.payloadExit.care_system) {
      const healthcare_system: any = {
        name: this.payloadExit.care_system,
      };

      this.payloadExit['healthcare_system'] = healthcare_system;
      delete this.payloadExit['care_system'];
    }
    if (this.payloadExit['billing_details']) {
      delete this.payloadExit['billing_details'];
    }
    if (this.payloadExit['third_part_billing_details']) {
      delete this.payloadExit['third_part_billing_details'];
    }

    return returnBool;
  }

  notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }

  updatePayload(): void {
    const simpleValues: any = this.editFields.value;
    const complexValues: any =
      this.providerUpdateToolService.getFormGroup('editSiteDetails')?.value;
    const locationValues: any = this.location_hours_form?.value;

    let payload: any = {
      name: simpleValues.facility_name,
      type: simpleValues.facility_type,
      address: {
        city_name: simpleValues.facility_address?.city_name,
        state: simpleValues.facility_address?.state,
        street_name_1: simpleValues.facility_address?.street_name_1,
        street_name_2: simpleValues.facility_address?.street_name_2,
        zip_code: simpleValues.facility_address?.zip_code,
      },
      contact_address: {
        city_name: simpleValues.address2?.city_name,
        state: simpleValues.address2?.state,
        street_name_1: simpleValues.address2?.street_name_1,
        street_name_2: simpleValues.address2?.street_name_2,
        zip_code: simpleValues.address2?.zip_code,
      },
      website_url: simpleValues.website_url,
      tax_id: simpleValues.tax_id,
      npi: simpleValues.npi,
      // "state"
      medicare_id: simpleValues.medicare_id,
      specialties: complexValues?.specialties
        ? complexValues.specialties
            .map((item: any) => {
              if (item.selection) return item.selection;
            })
            .filter(this.notEmpty)
        : null,
      phone_numbers: complexValues?.phone_numbers
        ? complexValues.phone_numbers
            .map((item: any) => {
              if (item.selection) {
                return { phone_number: item.selection, extension: 1 };
              }
            })
            .filter(this.notEmpty)
        : null,
      fax_numbers: complexValues?.fax_numbers
        ? complexValues.fax_numbers
            .map((item: any) => {
              if (item.selection) return item.selection;
            })
            .filter(this.notEmpty)
        : null,
      taxonomy_codes: complexValues?.taxonomy_codes
        ? complexValues.taxonomy_codes
            .map((item: any) => {
              if (item.selection) return item.selection;
            })
            .filter(this.notEmpty)
        : null,
      languages: complexValues?.languages
        ? complexValues.languages
            .map((item: any) => {
              if (item.selection) return item.selection;
            })
            .filter(this.notEmpty)
        : null,
      restrictions: complexValues?.restrictions
        ? complexValues.restrictions
            .map((item: any) => {
              if (item.selection) return item.selection;
            })
            .filter(this.notEmpty)
        : null,
      services: complexValues?.services
        ? complexValues.services
            .map((item: any) => {
              if (item.selection) return item.selection;
            })
            .filter(this.notEmpty)
        : null,
      affiliated_hospital_facility_ids: complexValues?.facility_list
        ? complexValues.facility_list
            .map((item: any) => {
              if (item.selection) return item.selection;
            })
            .filter(this.notEmpty)
        : null,
      operating_hours: this.setOperatingHours(locationValues),
      billing_details: this.setBillingDetails(simpleValues),
      care_system: simpleValues.care_system,
      third_part_billing_details: this.setThirdPartyBilling(simpleValues),
      location_contact: this.setLocationContacts(simpleValues, complexValues),
      administration_attributes: this.setAdminAttrib(simpleValues),
      number: simpleValues.number?.length ? simpleValues.number : null,
      change_request_effective_date:
        this.additionalDetailsForm?.value?.effective_date,
      change_request_notes: this.additionalDetailsForm?.value?.notes,
    };

    if (
      this.context?.identifiers &&
      this.context.identifiers[0]?.identifier !==
        simpleValues?.payer_assigned_identifier
    ) {
      payload = {
        ...payload,
        identifiers: this.getProviderIdentifiers(simpleValues),
      };
    }
    payload = setAdditionalDetailPayload(
      payload,
      this.additionalDetailsForm.value,
    );

    payload = JSON.parse(JSON.stringify(payload));
    this.cleanObj(payload, 'address');
    this.cleanObj(payload, 'contact_address');
    const cleanedObject = pickBy(payload, this.isEmptyArray);

    this.payload = cleanedObject;
    if (typeof simpleValues.is_primary === 'boolean') {
      this.payload['is_primary'] = simpleValues.is_primary;
    }
  }

  getProviderIdentifiers(formValue: object): ProviderIdentifierPayload[] {
    return this.config.tabs.tabConfigs[0].editFlyout?.formFields[0].controls
      .filter(
        (control: AllControlsConfiguration) =>
          control.prop === PROVIDER_IDENTIFIERS[toCamel(control.prop)],
      )
      .map((control: AllControlsConfiguration) => ({
        identifier: formValue[control.prop],
        type: control.label,
      }));
  }

  setAdminAttrib(data: any): any {
    const returnOb: any = cloneObject(
      this.snapshot['administration_attributes'],
    );

    if (
      data['administration_attributes.accepting_new_patients'] === false ||
      data['administration_attributes.accepting_new_patients'] === true
    ) {
      returnOb['accepting_new_patients'] =
        data['administration_attributes.accepting_new_patients'];
    }
    if (
      data['administration_attributes.display_in_directory'] === false ||
      data['administration_attributes.display_in_directory'] === true
    ) {
      returnOb['display_in_directory'] =
        data['administration_attributes.display_in_directory'];
    }
    if (
      data['administration_attributes.is_visiting_clinic'] === false ||
      data['administration_attributes.is_visiting_clinic'] === true
    ) {
      returnOb['is_visiting_clinic'] =
        data['administration_attributes.is_visiting_clinic'];
    }
    if (
      data['administration_attributes.is_primary_clinic'] === false ||
      data['administration_attributes.is_primary_clinic'] === true
    ) {
      returnOb['is_primary_clinic'] =
        data['administration_attributes.is_primary_clinic'];
    }
    if (
      data[
        'administration_attributes.has_mobility_impaired_accessible_equipment'
      ] === false ||
      data[
        'administration_attributes.has_mobility_impaired_accessible_equipment'
      ] === true
    ) {
      returnOb['has_mobility_impaired_accessible_equipment'] =
        data[
          'administration_attributes.has_mobility_impaired_accessible_equipment'
        ];
    }
    if (
      data[
        'administration_attributes.is_cultural_competency_training_complete'
      ] === false ||
      data[
        'administration_attributes.is_cultural_competency_training_complete'
      ] === true
    ) {
      returnOb['is_cultural_competency_training_complete'] =
        data[
          'administration_attributes.is_cultural_competency_training_complete'
        ];
    }
    if (
      data[
        'administration_attributes.cultural_competency_training_completion_date'
      ]
    ) {
      returnOb['cultural_competency_training_completion_date'] =
        data[
          'administration_attributes.cultural_competency_training_completion_date'
        ];
    }
    if (
      data['administration_attributes.is_mobility_impaired_accessible'] ===
        false ||
      data['administration_attributes.is_mobility_impaired_accessible'] === true
    ) {
      returnOb['is_mobility_impaired_accessible'] =
        data['administration_attributes.is_mobility_impaired_accessible'];
    }
    if (
      data[
        'administration_attributes.has_mobility_impaired_accessible_exam_room'
      ] === false ||
      data[
        'administration_attributes.has_mobility_impaired_accessible_exam_room'
      ] === true
    ) {
      returnOb['has_mobility_impaired_accessible_exam_room'] =
        data[
          'administration_attributes.has_mobility_impaired_accessible_exam_room'
        ];
    }

    if (returnOb) {
      Object.keys(returnOb).forEach(
        (key: any) => returnOb[key] === undefined && delete returnOb[key],
      );
    }

    return returnOb;
  }

  setLocationContacts(data: any, moreData: any) {
    const returnOb: any = cloneObject(this.snapshot['location_contact']);

    if (
      moreData?.location_contact_phone_numbers &&
      this.isEmptyArray(
        moreData.location_contact_phone_numbers
          .map((item: any) => {
            if (item.selection) return item.selection;
          })
          .filter(this.notEmpty),
      )
    ) {
      returnOb['phone_numbers'] = moreData.location_contact_phone_numbers
        .map((item: any) => {
          if (item.selection) return item.selection;
        })
        .filter(this.notEmpty);
    }
    if (data?.location_contact_full_name) {
      returnOb['name']['full_name'] = data.location_contact_full_name;
    }
    if (data?.location_contact_prefix) {
      returnOb['name']['prefix'] = data.location_contact_prefix;
    }
    if (data?.location_contact_email_address) {
      returnOb['email_address'] = data.location_contact_email_address;
    }
    if (data?.location_contact_fax) returnOb['fax'] = data.location_contact_fax;
    if (returnOb) {
      Object.keys(returnOb['name']).forEach(
        (key: any) =>
          returnOb['name'][key] === undefined && delete returnOb['name'][key],
      );
    }
    if (returnOb) {
      Object.keys(returnOb).forEach(
        (key: any) => returnOb[key] === undefined && delete returnOb[key],
      );
    }

    return returnOb;
  }

  isEmptyArray(array: any): boolean {
    if (Array.isArray(array) && array.length === 0) {
      return false;
    }
    if (array && Object.keys(array).length === 0) {
      return false;
    }
    if (array === null || array === undefined) {
      return false;
    }

    return true;
  }

  setBillingDetails(data: any) {
    const returnOb: any = cloneObject(this.snapshot['billing_details']);

    if (data.address3?.city_name) {
      returnOb['address']['city_name'] = data.address3.city_name;
    }
    if (data.address3?.state) {
      returnOb['address']['state'] = data.address3.state;
    }
    if (data.address3?.street_name_1) {
      returnOb['address']['street_name_1'] = data.address3.street_name_1;
    }
    if (data.address3?.street_name_2) {
      returnOb['address']['street_name_2'] = data.address3.street_name_2;
    }
    if (data.address3?.zip_code) {
      returnOb['address']['zip_code'] = data.address3.zip_code;
    }
    if (data.billing_details_name) returnOb['name'] = data.billing_details_name;
    if (data.billing_details_npi) returnOb['npi'] = data.billing_details_npi;
    if (data.billing_details_phone_number) {
      returnOb['phone_number'] = data.billing_details_phone_number;
    }
    if (data.billing_details_tax_id) {
      returnOb['tax_id'] = data.billing_details_tax_id;
    }
    if (returnOb) {
      Object.keys(returnOb).forEach(
        (key: any) => returnOb[key] === undefined && delete returnOb[key],
      );
    }

    return returnOb;
  }

  setThirdPartyBilling(data: any): any {
    const returnOb: any = cloneObject(
      this.snapshot['third_part_billing_details'],
    );

    if (data.address4?.city_name) {
      returnOb['address']['city_name'] = data.address4.city_name;
    }
    if (data.address4?.state) {
      returnOb['address']['state'] = data.address4.state;
    }
    if (data.address4?.street_name_1) {
      returnOb['address']['street_name_1'] = data.address4.street_name_1;
    }
    if (data.address4?.street_name_2) {
      returnOb['address']['street_name_2'] = data.address4.street_name_2;
    }
    if (data.address4?.zip_code) {
      returnOb['address']['zip_code'] = data.address4.zip_code;
    }
    if (data.third_part_billing_details_name) {
      returnOb['name'] = data.third_part_billing_details_name;
    }
    if (data.third_part_billing_details_effective_date) {
      returnOb['effective_date'] =
        data.third_part_billing_details_effective_date;
    }
    if (data.third_part_billing_details_phone_number) {
      returnOb['phone_number'] = data.third_part_billing_details_phone_number;
    }
    if (data.third_part_billing_details_fax_number) {
      returnOb['fax_number'] = data.third_part_billing_details_fax_number;
    }
    if (returnOb) {
      Object.keys(returnOb).forEach(
        (key: any) => returnOb[key] === undefined && delete returnOb[key],
      );
    }

    return returnOb;
  }

  setOperatingHours(form: any): any {
    this.locationOpenDays = this.locationHours
      ? this.locationHours.getEnabled()
      : this.locationOpenDays;
    const returnOb: any = {
      friday: form['friday']
        ? `${moment(form['friday'].start, 'HH:mm').format(
            'hh:mm a',
          )} to ${moment(form['friday'].end, 'HH:mm').format('hh:mm a')}`
        : null,
      monday: form['monday']
        ? `${moment(form['monday'].start, 'HH:mm').format(
            'hh:mm a',
          )} to ${moment(form['monday'].end, 'HH:mm').format('hh:mm a')}`
        : null,
      saturday: form['saturday']
        ? `${moment(form['saturday'].start, 'HH:mm').format(
            'hh:mm a',
          )} to ${moment(form['saturday'].end, 'HH:mm').format('hh:mm a')}`
        : null,
      sunday: form['sunday']
        ? `${moment(form['sunday'].start, 'HH:mm').format(
            'hh:mm a',
          )} to ${moment(form['sunday'].end, 'HH:mm').format('hh:mm a')}`
        : null,
      thursday: form['thursday']
        ? `${moment(form['thursday'].start, 'HH:mm').format(
            'hh:mm a',
          )} to ${moment(form['thursday'].end, 'HH:mm').format('hh:mm a')}`
        : null,
      tuesday: form['tuesday']
        ? `${moment(form['tuesday'].start, 'HH:mm').format(
            'hh:mm a',
          )} to ${moment(form['tuesday'].end, 'HH:mm').format('hh:mm a')}`
        : null,
      wednesday: form['wednesday']
        ? `${moment(form['wednesday'].start, 'HH:mm').format(
            'hh:mm a',
          )} to ${moment(form['wednesday'].end, 'HH:mm').format('hh:mm a')}`
        : null,
    };

    Object.keys(returnOb).forEach((item: any) => {
      if (!this.locationOpenDays.find((_item: any) => _item === item)) {
        delete returnOb[item];
      }
    });

    return pickBy(returnOb, identity);
  }

  hasAssociateSelections(): boolean {
    return !this.selectedRows.length;
  }

  associateSite(event: any): void {
    this.addState = 'add';
    this.selectedRows = [];
    this.toggleAssociate();
    this.analyticsService.sendEvent(this.config.headerButton.cx_event_key);
  }

  handleSelectedRows(rows: any): void {
    this.selectedRows = rows;
  }

  reviewAssociated(event: any): void {
    if (this.hasAssociateSelections()) return;
    if (this.selectedRows.length === 0) {
      this.toggleError();

      return;
    }
    this.addState = 'review';
  }

  checkAssociated(): boolean {
    let value = true;

    this.associateFields.forEach((item, index) => {
      // eslint-disable-next-line no-empty
      if (item.valid) {
      } else {
        value = false;
        const controls = item.controls;

        Object.keys(controls).forEach((c: any) => controls[c].markAsTouched());
      }
    });

    return value;
  }

  submitAddAssociated(event: any): void {
    if (!this.checkAssociated()) return;

    const facility_location_providers: any[] = [];

    this.selectedRows.forEach((row, index) => {
      const rowFields: any = this.getAssociatedFieldValue(index);
      const _payload: any = {
        facility_location_id: this.id,
        provider_id: row.row.id,
        accepting_new_patients: !!rowFields.accepting_new_patients,
        can_display_in_directory: !!rowFields.display_in_directory,
      };

      if (rowFields.effective_date) {
        _payload['effective_date'] = rowFields.effective_date;
      }
      if (rowFields.notes) _payload['last_updated_notes'] = rowFields.notes;
      facility_location_providers.push(_payload);
    });

    const payload: any = {
      should_fail_on_error: false,
      operation_type: 'create',
      facility_location_providers: facility_location_providers,
    };

    this.genericServices.subscriptions.push(
      this.api.post(this.config.endpoints.batch, payload).subscribe(
        (res: any) => {
          this.addResponse = res;
          this.addState = 'confirm';
          this.table?.refresh();
        },
        () => {
          this.toggleAssociate();
          this.toggleError();
        },
      ),
    );
  }

  makeSingleCall(payload: any): Observable<any> {
    return this.api
      .get(
        this.setParameter(
          this.config?.endpoints.facility_details,
          'facility_id',
          payload,
        ),
      )
      .pipe(map((res: Response) => res as any));
  }

  makeMultipleCalls(payloads: any[]): Observable<any[]> {
    const callObservables = payloads.map((payload: any) =>
      this.makeSingleCall(payload)
        .pipe(map((payloadItem: any) => payloadItem))
        .pipe(catchError((error: any) => of(null))),
    );

    return forkJoin(callObservables);
  }

  /*
   * ERHO: Is this still needed? Don't see it used anywhere
   */
  removeAssociated(index: number): void {
    if (index > -1) {
      this.selectedRows.splice(index, 1);
    }
  }

  isDisabled(): boolean {
    this.updatePayload();
    if (!this.comparePayloads()) return true; // payload hasn't changed, disable button
    const simpleValues: any = this.editFields;
    const complexValues: any =
      this.providerUpdateToolService.getFormGroup('editSiteDetails');
    const locationValues: any = this.location_hours_form;
    let dontShow = false;

    dontShow = this.setDisabledValidations(simpleValues, dontShow);
    dontShow = this.setDisabledValidations(complexValues, dontShow);
    if (dontShow) return true;

    return this.locationHours && !this.locationHours.validate();
  }

  setDisabledValidations(control: any, returnBool: boolean): boolean {
    const controls = control.controls;

    if (controls) {
      Object.keys(controls).forEach((c: any) => {
        if (
          (this.isRequiredField(controls[c]) && !controls[c].valid) ||
          (!controls[c].valid && !controls[c].pristine)
        ) {
          // not valid and has been touched or required
          if (controls[c].controls) {
            Object.keys(controls[c].controls).forEach((cc: any) => {
              returnBool = this.setDisabledValidations(
                controls[c].controls[cc],
                returnBool,
              );
            });
          } else {
            controls[c].markAsTouched();
            returnBool = true;
          }
        }
      });
    }

    return returnBool;
  }

  isRequiredField(control: any) {
    if (!control.validator) {
      return false;
    }

    const validator = control.validator({} as AbstractControl);

    return validator && validator.required;
  }

  isAssociateDisabled(): boolean {
    let dontShow = false;

    dontShow = this.setAssociateDisabledValidations(
      this.associateFields,
      dontShow,
    );

    return dontShow;
  }

  setAssociateDisabledValidations(control: any, returnBool: boolean): boolean {
    control.forEach((item, index) => {
      if (!item.valid) {
        const controls = item.controls;

        if (controls) {
          Object.keys(controls).forEach((c: any) => {
            if (!controls[c].valid) {
              if (controls[c].controls) {
                Object.keys(controls[c].controls).forEach((cc: any) => {
                  returnBool = this.setDisabledValidations(
                    controls[c].controls[cc],
                    returnBool,
                  );
                });
              }
            } else {
              if (!controls[c].pristine) {
                controls[c].markAsTouched();
              }
              returnBool = true;
            }
          });
        }
      }
    });

    return returnBool;
  }

  postInit(): void {
    for (const [, _value] of Object.entries(this.config?.tabs?.tabConfigs)) {
      const currentTab: any = _value;

      if (currentTab?.editFlyout?.formFields) {
        for (const formField of currentTab.editFlyout.formFields) {
          if (formField?.controls) {
            for (const control of formField.controls) {
              if (control.enableOnTrue) {
                if (this.editFields.controls[control.enableOnTrue]) {
                  if (this.editFields.controls[control.prop]) {
                    if (!this.editFields.controls[control.prop].value) {
                      this.editFields.controls[control.enableOnTrue].disable();
                    }
                    this.editFields.controls[
                      control.prop
                    ].valueChanges.subscribe((value: any) => {
                      if (value) {
                        this.editFields.controls[control.enableOnTrue].enable();
                      } else {
                        this.editFields.controls[control.enableOnTrue].reset();
                        this.editFields.controls[control.enableOnTrue].setValue(
                          null,
                        );
                        this.editFields.controls[
                          control.enableOnTrue
                        ].disable();
                      }
                    });
                  }
                }
              }
              if (
                control.apiEndpoint &&
                control.defaultValueMatchField?.length
              ) {
                let defaultValue = {};

                if (this.editFields?.value?.facility_type) {
                  defaultValue = getValue(
                    this.editFields?.value,
                    SiteLocationDetailsContextKeys.facilityType,
                  );
                } else {
                  defaultValue = getValue(this.context, control.defaultProp);
                }

                const matchValue = getValue(
                  defaultValue,
                  control.defaultValueMatchField,
                );
                let valueToSet;

                if (control.options) {
                  valueToSet = control.options.find((option: any) =>
                    this.isIdMatch(
                      option,
                      matchValue,
                      control.defaultValueMatchField,
                    ),
                  );
                }
                if (this.editFields.controls[control.prop] && valueToSet) {
                  this.editFields.controls[control.prop].setValue(
                    valueToSet.value,
                    { emitEvent: false },
                  );
                }
              }
            }
          }
        }
      }
    }
  }

  isIdMatch(option: any, matchValue: any, matchField: any): boolean {
    if (!option || !matchValue || !matchField) return false;
    const optionContent: any = option.value;
    const valueToMatch: any = getValue(optionContent, matchField);

    return valueToMatch === matchValue;
  }

  cancelEdit(): void {
    this.showAdditionalDetailsModal = false;
    this.editModal = true;
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.genericServices.showFooter$.next(true);
    });
  }
}
