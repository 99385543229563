import { Component, Input, OnInit } from '@angular/core';
import { accordion } from '../../shared/animations';
import { ExpandingListConfig } from './expanding-list.constants';
import { checkInputsForText } from '../../design-system.helper';

@Component({
  selector: 'expanding-list',
  templateUrl: './expanding-list.component.html',
  styleUrls: ['./expanding-list.component.scss'],
  animations: [accordion],
})
export class ExpandingListComponent implements OnInit {
  @Input() list: any[];
  @Input() config: ExpandingListConfig;

  buttonIcon: string;
  buttonText = 'Show Less';
  isExpanded = true; // Start true so that showHide can initialize the button
  lineCount: number; // qty of list items to view when not expanded
  listLength: number;
  limit = 0; // dynamic limit on the qty of list items to show

  showHide() {
    const numberOfHidden = this.listLength - this.lineCount;
    const stateText = this.isExpanded ? 'More' : 'Less'; // expanded hasn't been flipped yet

    this.buttonText = `Show ${numberOfHidden} ${stateText}`;
    this.isExpanded = !this.isExpanded;
    this.buttonIcon = this.isExpanded ? 'chevron_up' : 'chevron_down';
    this.limit = this.isExpanded ? this.listLength : this.lineCount;
  }

  ngOnInit(): void {
    const defaultLineCount = 4;
    const [config, list] = checkInputsForText([this.config, this.list]);

    this.config = config as ExpandingListConfig;
    this.list = list as any[];
    if (!this.list) return;

    this.listLength = this.list.length;
    this.lineCount = this.config?.lineCount || defaultLineCount;
    this.showHide();
  }
}
