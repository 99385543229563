import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { Subscription } from 'rxjs';
import { objectToArray } from '../../../../shared/utilities/object';
import { ProvisionerService } from '../provisioner/provisioner.service';

@Component({
  styleUrls: ['./item-cell.component.scss'],
  selector: 'item-cell',
  templateUrl: './item-cell.component.html',
})
export class ItemCellComponent implements OnInit, OnDestroy {
  @Input() state: 'inactive' | 'active' = 'active';
  @Input() selected = false;
  @Input() parentId: string;
  @Input() row: number;
  @Input() onlyLoadWhen: any;
  @Input() data: any = {};
  @Input() column: number;
  @Output() selectedCell: EventEmitter<any> = new EventEmitter<any>();

  _clicked: boolean;
  _config: any = {};
  formGroup = new UntypedFormGroup({});
  formData: any = {};
  subs: Subscription[] = [];

  constructor(
    private formControlService: FormControlService,
    private provisioner: ProvisionerService,
  ) {}

  get clicked() {
    return this._clicked;
  }
  get config() {
    return this._config;
  }

  // Decorated set definitions
  @Input() set clicked(clicked: boolean) {
    if (clicked) this._clicked = clicked;
    else this._clicked = false;
  }
  @Input() set config(config: any) {
    if (config) {
      if (config.display_fields) {
        this._config.display_fields = config.display_fields;
      }
      if (config.form_fields) {
        this._config.form_fields = objectToArray(config.form_fields);
        for (const item of this.config.form_fields) {
          this.formData[item.prop] = {};
          this.formControlService.addControlToFormGroup(this.formGroup, item);
        }
      }
    }
  }

  ngOnInit(): void {
    for (const item of this.config.form_fields) {
      const prop = item.prop;

      if (
        this.column !== 0 &&
        !this.provisioner.checkColumnDisableStatus(
          this.column,
          this.onlyLoadWhen,
        )
      ) {
        this.selected = false;
        this.formGroup
          .get(prop)
          .setValue(
            this.provisioner.getColumnDisableStatus(
              this.column,
              this.onlyLoadWhen,
            ),
            {
              emitEvent: false,
              onlySelf: true,
            },
          );
      }
      const previousColumn = this.column - 1;
      let valueToSet: any;

      if (previousColumn >= 0) {
        valueToSet = this.provisioner.checkIfSelectionExistsReturnProp(
          previousColumn,
          this.parentId,
          prop,
        );
        if (valueToSet) {
          const isOptionsHasValue = item.options.find(
            (option: { value: any }) => option.value === valueToSet,
          );

          if (isOptionsHasValue) {
            this.formGroup
              .get(prop)
              .setValue(valueToSet, { emitEvent: false, onlySelf: true });
            this.selected = true;
          } else {
            this.formGroup.get(prop).setValue(this.data[item.prop], {
              emitEvent: false,
              onlySelf: true,
            });
          }
        } else {
          this.formGroup.get(prop).setValue(this.data[item.prop], {
            emitEvent: false,
            onlySelf: true,
          });
        }
      } else if (
        this.column === 0 ||
        this.provisioner.checkColumnDisableStatus(
          this.column,
          this.onlyLoadWhen,
        )
      ) {
        this.formGroup
          .get(prop)
          .setValue(this.data[item.prop], { emitEvent: false, onlySelf: true });
      }
      this.subs.push(
        this.formGroup.get(prop).valueChanges.subscribe((event: any) => {
          this.selected = true;
          this.formData[item.prop] = event;
          this.selectedCell.emit({
            row: this.row,
            data: this.data,
            formData: this.formData,
          });
        }),
      );
    }
    this.provisioner.addFormGroup(
      this.formGroup,
      this.data,
      this.column,
      this.row,
      this.config,
      this.onlyLoadWhen,
    );
  }

  public unselectCell(): void {
    this.selected = false;
  }

  ngOnDestroy() {
    for (const item of this.subs) {
      item.unsubscribe();
    }
  }
}
