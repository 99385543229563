import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BadgeConfig } from '@zipari/design-system';
import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { APIService } from '@zipari/web-services';
import { getValue } from '@zipari/web-utils';
import _ from 'lodash';
import { noop, Subscription } from 'rxjs';
import { GLOBAL_URLS, PROVIDER_PORTAL_URLS } from '../../../app-urls.constant';
import { ConfigService, FormattingService } from '../../../shared/services';
import { MCGDataParserService } from '../../shared/services/mcg-data-parser/mcg-data-parser.service';
import { objectToArray } from '../../../shared/utilities/object';
import {
  camelCase,
  toCamel,
} from '../../../shared/utilities/case-conversion.utils';
import {
  Authorization,
  Service,
} from '../../../shared/models/shared/Authorization.model';
import Document from '../../../shared/models/shared/Document.model';
import DocumentLink from '../../../shared/models/shared/LinkDocument.model';
import {
  OutNetworkStatus,
  OutOfNetworkProps,
  transferOfCareConstants,
} from './transfer-of-care-details.constants';
import authorizationService, {
  ClinicalReveiwIndicationContext,
  ClinicalReviewGuidelineContext,
  mcgClinicalReview,
} from './transfer-of-care-detail.model';

@Component({
  selector: 'transfer-of-care-detail',
  templateUrl: './transfer-of-care-detail.component.html',
  styleUrls: ['./transfer-of-care-detail.component.scss'],
})
export class TransferOfCareDetailComponent implements OnInit {
  config: any;
  tocID: string | number;
  context: any;
  member_id: any;
  dataLoaded = false;
  busy: Subscription;
  routeBackLabel: string;
  eligibilityView;
  buttonConfig;
  referralView: boolean;
  documents: Document[];
  documentsBaseUrl: string;
  isLoading = false;
  useDummyData: boolean;
  modifiedBadgeConfig: any[] = [];
  _: any = _;
  rowId: number;
  errorPopup = false;
  authorizationSubmissionConfig: any;
  authorizationTypeLabel: string;
  frequencyOptionLable: string;
  clinicalReviewTabData: ClinicalReveiwIndicationContext[] = [];
  sidebar: unknown = {};

  constructor(
    private configService: ConfigService,
    private route: ActivatedRoute,
    private api: APIService,
    private router: Router,
    private formatService: FormattingService,
    private mcgDataParserService: MCGDataParserService,
  ) {}

  ngOnInit(): void {
    this.referralView = 'referral_id' in this.route.snapshot.params;
    this.eligibilityView =
      this.route.snapshot.routeConfig?.path.includes('patient');
    this.config = this.referralView
      ? this.configService.getPageConfig('referral_detail')
      : this.configService.getPageConfig('authorization_detail');

    this.formateConfig(this.config);
    this.authorizationSubmissionConfig = this.configService.getPageConfig(
      'authorization-submission',
    );
    this.documentsBaseUrl = this.config.sidebar.sections.find((section: any) =>
      section.hasOwnProperty('href-base-url'),
    );
    this.tocID = this.referralView
      ? this.route.snapshot.params.referral_id
      : this.route.snapshot.params.auth_id;
    this.getTocDetails();
  }

  formateConfig(config) {
    if (config?.tabs?.tabConfigs) {
      if (!config.isTabsEnable) {
        delete config.tabs.tabConfigs.procedure;
        delete config.tabs.tabConfigs.clinical_review;
        delete config.tabs.labels;

        config.tabs.tabConfigs['sections'] = {
          ...config.tabs.tabConfigs.overview?.sections,
          ...config.tabs.tabConfigs.diagnosis?.sections,
          ...config.tabs.tabConfigs.contact_informations?.sections,
        };
        delete config.tabs.tabConfigs.overview;
        delete config.tabs.tabConfigs.diagnosis;
        delete config.tabs.tabConfigs.contact_informations;

        config.tabs.tabConfigs.sections = objectToArray(
          config.tabs.tabConfigs.sections,
        );
        this.updateTabsSectionsConfig(config.tabs.tabConfigs.sections);
        Object.assign(config.tabs, { tabConfigs: [config.tabs.tabConfigs] });
      } else {
        Object.keys(config?.tabs?.tabConfigs)?.map((tab: any) => {
          if (Array.isArray(config.tabs.labels)) {
            config.tabs.labels[config.tabs.tabConfigs[tab]?.priority] =
              config.tabs.tabConfigs[tab]?.tabLabel;
          }
          if (config.tabs.tabConfigs[config.tabs.tabConfigs[tab]?.priority]) {
            config.tabs.tabConfigs[config.tabs.tabConfigs[tab].priority] =
              config.tabs.tabConfigs[tab];
          }
        });
        config.tabs.tabConfigs = objectToArray(config.tabs.tabConfigs);
        config.tabs.tabConfigs.forEach((item: any) => {
          if (item?.sections) {
            item.sections = objectToArray(item.sections);
            this.updateTabsSectionsConfig(item.sections);
          }
        });
      }
    }
  }

  updateTabsSectionsConfig(sections): void {
    sections.forEach((section, index) => {
      if (!_.isEmpty(section.attributes)) {
        Object.assign(
          sections[index],
          { fields: section.attributes },
          { header: section.label },
        );
        delete sections[index].attributes;
        delete sections[index].label;
      } else if (
        !_.isEmpty(section.accordion) &&
        !_.isEmpty(section.accordion.attributes)
      ) {
        Object.assign(
          sections[index].accordion,
          { accordionFields: section.accordion.attributes },
          { header: section.accordion.label },
        );
        Object.assign(sections[index], { header: section.label });
        delete sections[index].accordion.attributes;
        delete sections[index].accordion.label;
      }
    });
  }

  recentlyViewCall(data): void {
    const payload = {
      member_id: data?.member?.id,
      member_first_name: data?.member_name?.first_name,
      member_last_name: data?.member_name?.last_name,
    };

    this.busy = this.api
      .post(this.config?.recently_viewed_endpoint?.endpoint, payload)
      .subscribe(noop);
  }

  getTocDetails(): void {
    const tocDetailEndpoint = `${this.config.endpoint}${this.tocID}/`;

    this.isLoading = true;
    this.busy = this.api
      .get(tocDetailEndpoint)
      .subscribe((response: Authorization) => {
        this.context = response;
        this.sidebar = this.context.requesting_facility_location
          ? this.config?.sidebarFacility
          : this.config?.sidebar;
        if (this.context?.additional_properties?.length > 0) {
          const mcgClinicalReviewTabData =
            this.context.additional_properties.find(
              (item: { property_dictionary: any; category: string }) =>
                item.category === mcgClinicalReview,
            );
          const guideline: ClinicalReviewGuidelineContext[] = camelCase(
            mcgClinicalReviewTabData?.property_dictionary?.Episode?.Guidelines
              ?.Guideline,
          );

          if (guideline) {
            guideline.forEach(
              (guidelineItem: ClinicalReviewGuidelineContext) => {
                const clinicalReveiwIndication: ClinicalReveiwIndicationContext =
                  guidelineItem?.outlines?.outline?.indications?.indication;
                const guidelineHeader = {
                  guidelineTitle: `${guidelineItem?.orgOtg} - ${guidelineItem?.guidelineName} - (${guidelineItem?.pubCode})`,
                };

                if (clinicalReveiwIndication) {
                  this.clinicalReviewTabData.push(
                    guidelineHeader,
                    ...this.mcgDataParserService.parseMCGData(
                      clinicalReveiwIndication?.indication,
                    ),
                  );
                }
                this.context = {
                  ...this.context,
                  clinical_review_data: this.clinicalReviewTabData,
                };
              },
            );
          }
        }

        if (this.config?.recently_viewed_endpoint?.endpoint) {
          this.recentlyViewCall(this.context);
        }
        this.rowId = response.id;
        this.member_id = response.member_id || response.member;
        this.routeBackLabel = this.eligibilityView
          ? `${this.getVal(
              this.context,
              this.config.backButton.prop1,
            )} ${this.getVal(this.context, this.config.backButton.prop2)}`
          : this.config.backButton.content;
        this.dataLoaded = true;

        this.documents = response.documents_details || response.document_ids;
        this.context['formattedDocumentslist'] = this.context.documents
          ? this.formatDocumentLinkListInput()
          : [];

        const authorizationTypeOptions =
          this.authorizationSubmissionConfig.cards?.authorization_type_card
            .controls?.authorization_type?.options;

        if (authorizationTypeOptions) {
          this.authorizationTypeLabel = this.getDropdownOptionsLable(
            authorizationTypeOptions,
            this.context?.type,
          );
        }

        this.context = {
          ...this.context,
          authorization_type_label: this.authorizationTypeLabel,
        };

        const inpatientFrequencyOptions =
          this.authorizationSubmissionConfig.cards
            ?.length_of_stay_inpatient_card.controls?.frequency?.options;

        if (inpatientFrequencyOptions) {
          this.context.services = this.context?.services?.map(
            (service: authorizationService) => {
              this.frequencyOptionLable = this.getDropdownOptionsLable(
                inpatientFrequencyOptions,
                service?.frequency,
              );

              return (service = {
                ...service,
                frequency_option_label: this.frequencyOptionLable,
              });
            },
          );
        }

        this.isLoading = false;

        this.formatButtonConfig();
        this.formatConfigValue();
        this.setBadgeColour();
        if (!this.referralView) this.setPrimary();
      });
  }

  getDropdownOptionsLable(
    options: { label: string; value: string }[],
    serviceAttribute: string,
  ) {
    return options.find(
      (option: { label: string; value: string }) =>
        option.value === serviceAttribute,
    )?.label;
  }

  /*
   * Searches Authorisation services array to set primaryTOC for side bar
   */
  setPrimary(): void {
    this.context['primaryService'] = this.context.services.find(
      ({ is_primary }: Service) => is_primary,
    );
    if (!this.context['primaryService']) {
      this.context['primaryService'] = this.context.services[0];
    }
  }

  setBadgeColour(): void {
    const badgeStatusNumber = 2;
    // If status is coming in multiple words the replace it by under score so item can be configured through underscore in config.
    const status: string = this.context.claim_status
      ?.toLowerCase()
      .trim()
      .replace(/ /g, '_');
    const statusIndex: number =
      this.modifiedBadgeConfig.findIndex(
        (badge: BadgeConfig) => badge.label?.toLowerCase() === 'status',
      ) || badgeStatusNumber;
    // if status is not in mapping list then leave to default status

    if (
      this.modifiedBadgeConfig[statusIndex].status_mapping &&
      this.modifiedBadgeConfig[statusIndex].status_mapping[status]
    ) {
      this.modifiedBadgeConfig[statusIndex].status =
        this.modifiedBadgeConfig[statusIndex].status_mapping[status];
    }
  }

  formatDocumentLinkListInput(): Document[] | DocumentLink[] {
    let formattedDocumentslist: Document[] | DocumentLink[];

    if (this.context.documents) {
      formattedDocumentslist = this.context.documents.map(
        (document: Document) => {
          const linkFormatObj: DocumentLink = {
            label: document.file_name || document.filename,
            href: `${this.documentsBaseUrl['href-base-url']}${document.id}/`,
            icon: 'pdf',
            cx_event_key: this.config.sidebar.sections.find((e: any) =>
              e.hasOwnProperty('cx_event_key'),
            )['cx_event_key'],
          };

          return linkFormatObj;
        },
      );
    }

    return formattedDocumentslist;
  }

  formatButtonConfig(): void {
    if (this.config.backButton) {
      this.buttonConfig = this.config.backButton;
      this.buttonConfig['content'] = `${this.routeBackLabel}`;
    } else {
      this.buttonConfig = {
        content: `${this.routeBackLabel}`,
        icon: 'chevron_left',
        level: 'text',
      };
    }
  }

  formatConfigValue(): void {
    if (this.config.badges) {
      const keys: string[] = Object.keys(this.config.badges);

      keys.forEach((key: string) => {
        const newBadgeConfig: BadgeConfig =
          this.formatService.formatConfigValueAsTemplate(
            this.config.badges[key],
            this.context,
          );

        this.modifiedBadgeConfig.push(newBadgeConfig);
      });
    }
  }

  calculateHours(time: string): boolean {
    const requestedDate = new Date(this.context?.request_date);
    const currentDate = new Date();
    const splitTime = time.split(':');
    const milliseconds =
      (+splitTime[0] * 60 * 60 + +splitTime[1] * 60 + +splitTime[2]) * 1000;

    return currentDate?.getTime() - requestedDate?.getTime() <= milliseconds
      ? true
      : false;
  }

  routeBack(button: ButtonConfig): void {
    const routeBackPath = this.referralView
      ? PROVIDER_PORTAL_URLS.URLS.REFERRALS
      : GLOBAL_URLS.AUTHORIZATIONS;

    this.eligibilityView
      ? this.router.navigate([
          `/provider-portal/patients/${this.member_id}/${routeBackPath}`,
        ])
      : this.router.navigate([`/provider-portal/${button.route}`]);
  }

  navigateSubNav(config, event): void {
    if (this.context?.additional_properties?.length < 1) {
      this.config.isEditClinicalReviewEnabled = true;
    }
    const redirectIff =
      this.context?.user_have_edit_permission &&
      this.context?.status === transferOfCareConstants?.authStatus &&
      this.config.isEditClinicalReviewEnabled &&
      this.calculateHours(config);

    if (redirectIff) {
      localStorage.setItem('row', JSON.stringify(this.context));
      this.router.navigate([
        `${transferOfCareConstants.editAuthUrl}${this.rowId}/${event.route}`,
      ]);
    } else {
      this.errorPopup = !this.errorPopup;
    }
  }

  onClick() {
    this.errorPopup = !this.errorPopup;
  }

  getVal(data: any, val: string): string | number {
    const value: string | number = getValue(data, val);

    return value;
  }

  showOutOfNetworkBadge(status: string, context): boolean {
    let badgeStatus;

    if (
      status === OutOfNetworkProps[toCamel(status)] &&
      context?.services?.length > 0 &&
      !this.referralView
    ) {
      badgeStatus = context.services[0][status];
    } else {
      badgeStatus = context[status];
    }

    return (
      this.config?.outOfNetwork?.isOutOfNetworkEnabled &&
      badgeStatus === OutNetworkStatus
    );
  }
}
