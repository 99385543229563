import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormattingService, Option } from '@zipari/design-system';
import { APIService } from '@zipari/web-services';
import { camelCase } from '@zipari/shared-util-common';
import { WindowService } from '@zipari/web-services';
import { getValue } from '@zipari/web-utils';
import { ButtonSubnavItem } from '@zipari/shared-ds-util-button';
import { ObjectStatus } from '@zipari/shared-ds-util-status';
import { forkJoin, noop, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { startOfDay } from 'date-fns';
import ProductCoverage from '../../../shared/models/shared/ProductCoverage.model';
import { ApiListResponse } from '../../../shared/models/shared/ApiListResponse.model';
import { ZipBackendError } from '../../../shared/services/zip-endpoint.service';
import Member from '../../../shared/models/shared/Member.model';
import MemberPCP from '../../../shared/models/shared/MemberPCP.model';
import PlanBenefit from '../../../shared/models/shared/PlanBenefit.model';
import Policy from '../../../shared/models/shared/Policy.model';
import { ConfigService } from '../../../shared/services';
import { LoggingService } from '../../../shared/services/logging.service';
import { ErrorModel } from '../../../shared/models/Errors.model';
import Benefit from '../../../shared/models/shared/Benefit.model';
import { GenericServices } from '../../shared/services/generic.service';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { SectionConfig } from '../../../shared/models/TabsConfig.model';
import { GlobalConfig } from '../../../app.constants';
import {
  PatientDetailContext,
  noDataFoundMessage,
  patientDetailConstants,
} from './patient-detail.constant';
import { PatientDetailService } from './patient-detail.service';
import {
  PatientDetailConfig,
  PatientDetailTabConfig,
} from './patientDetailConfig.model';
import { MemberAlert, MemberDetailTabConfig } from './patient-detail.model';
import { getPolicyStatusLabel } from './patient-detail.helper';

@Component({
  selector: 'patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.scss'],
})
export class PatientDetailComponent implements OnInit {
  config: PatientDetailConfig;
  memberId: string;
  dataLoaded: boolean;
  busy: any[] = [];
  showAddReferralButton: boolean;
  nonDependentEndpoints: string[];
  nonDependentEndpointStatus: boolean[] = [];
  pregnancyConditions: any;
  errorHandled = false;
  sidePanelSections: SectionConfig[] = [];
  activeTab: number;
  activeTabObj: PatientDetailTabConfig;
  tabLabels: string[] = [];
  tabsConfig: PatientDetailTabConfig[] = [];
  uniqueMemberId: any;
  alerts$: Observable<string | ApiListResponse<MemberAlert>>;
  noDataFound = '';
  globalConfig: GlobalConfig;
  enableCoverageCheckForPregnancyForm: boolean;
  public memberDisplayIdentifer: string;
  public memberDisplayName: string;

  constructor(
    private api: APIService,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private logger: LoggingService,
    private formattingService: FormattingService,
    private patientDetailService: PatientDetailService,
    public analyticsService: AnalyticsService,
    public genericServices: GenericServices,
    private windowService: WindowService,
  ) {}

  ngOnInit(): void {
    this.config =
      this.configService.getPageConfig<PatientDetailConfig>('patient_detail');
    this.globalConfig = this.configService.getPageConfig<GlobalConfig>(
      patientDetailConstants.globalConfig,
    );
    this.enableCoverageCheckForPregnancyForm =
      this.globalConfig?.toggleFeatures?.enableCoverageCheckForPregnancyForm;
    this.setPregnancyConditions();
    this.memberId = this.route.snapshot.params.id;
    this.showAddReferralButton = false;
    this.nonDependentEndpoints = this.setNonDependentEndpoints();
    this.getPatientDetails();
    this.buildSidePanelSections();
    this.setTabLabels();
    this.uniqueMemberId = localStorage.getItem(patientDetailConstants.memberId);
    this.setCurrentTab();

    if (this.config.endpoints?.member?.cx_event_key) {
      this.analyticsService.sendEvent(
        this.config.endpoints.member.cx_event_key,
      );
    }
  }

  getStatusLabel(status: string): string {
    return getPolicyStatusLabel(status, this.config);
  }

  setTabLabels(): void {
    Object.keys(
      this.genericServices.sortObjectsByPriority(this.config.tabs),
    ).map((tab: any) => {
      this.tabLabels.push(this.config.tabs[tab].label);
      this.tabsConfig.push(this.config.tabs[tab]);
    });
  }

  buildSidePanelSections(): void {
    if (this.config?.sidePanel?.sections) {
      const keys: string[] = Object.keys(this.config.sidePanel.sections);

      keys.forEach((key: string) => {
        this.sidePanelSections.push(this.config.sidePanel.sections[key]);
      });
    }
  }

  tabSelected(ev): void {
    this.activeTab = ev;
    this.router
      .navigate([this.tabsConfig[ev]?.url], { relativeTo: this.route })
      .then((status: boolean) => {
        this.getPatientDetails();
        this.setCurrentTab();
      });
  }

  removeActionButtonIfEmpty() {
    if (!this.config?.actionButton?.subnav?.items?.length) {
      delete this.config.actionButton;
    }
  }

  recentlyViewCall(data): void {
    const payload = {
      member_id: data.member_number,
      member_first_name: data.name.first_name,
      member_last_name: data.name.last_name,
    };

    this.busy.push(
      this.api
        .post(
          this.config.endpoints['recently_viewed_endpoint'].endpoint,
          payload,
        )
        .subscribe(noop),
    );
  }

  getPatientDetails(): void | any {
    const memberApi: Observable<Member> = this.patientDetailService.getMember(
      this.setEndpointFromConfig(this.config.endpoints.member),
    );
    const policyApi: Observable<ApiListResponse<Policy>> =
      this.patientDetailService.getPolicies(
        this.setEndpointFromConfig(this.config.endpoints.coverage),
      );

    const _busy: Subscription = forkJoin([memberApi, policyApi]).subscribe(
      ([member, coverage]) => {
        this.patientDetailService.context.member = member;
        this.memberDisplayIdentifer = member.member_display_identifier;
        this.recentlyViewCall(member);
        this.enableCoverageCheckForPregnancyForm
          ? this.checkAndSetPregnancyConditions(
              member,
              coverage.results[0].policy_status,
            )
          : this.checkAndSetPregnancyConditions(member);
        this.removeActionButtonIfEmpty();
        this.configService.setMember(member);
        this.memberDisplayName = member?.member_name
          ? member.member_name
          : `${member?.name?.first_name || ''} ${member?.name?.last_name || ''}`.trim();

        // filter first  active policy or take first policy
        const filteredPolicy =
          coverage.results?.find(
            (policy: Policy) =>
              policy?.policy_status?.toLocaleLowerCase() === 'active',
          ) || coverage.results[0];

        this.patientDetailService.context.coverage = filteredPolicy;

        this.formatDates(this.patientDetailService.context);
        this.patientDetailService.context.policy_status =
          filteredPolicy?.policy_status;

        /** ERHO: For BH to work
         ** Need to update BH API to support getting plan benefits from /plan_benefits API **/
        if (this.patientDetailService.context.coverage?.plan_information) {
          this.patientDetailService.context.benefits =
            this.patientDetailService.context.coverage?.plan_information?.benefits;
        }

        /*
         * ERHO: Need to hide the QMB field here. Doing this quickly for UCare.
         * Consider moving 'enrolled_medicare_savings_program' to config
         * And handle this differently.
         */
        if (
          this.patientDetailService.context?.member?.additional_fields
            ?.enrolled_medicare_savings_program
        ) {
          this.patientDetailService.context.member.additional_fields.enrolled_medicare_savings_program =
            ' ';
        }

        if (this.nonDependentEndpoints.length) {
          this.nonDependentEndpoints.forEach(
            (endpoint: string, idx: number) => {
              switch (endpoint) {
                case 'pcp':
                  this.getMemberPCP(idx);

                  return;
                case 'benefits':
                  this.getBenefits(idx);

                  return;
                case 'planBenefits':
                  this.getPlanBenefits(idx);

                  return;
              }
            },
          );
        }

        /** ERHO: To prevent page from breaking
         ** API endpoints differ from BH to client
         ** BH will have to be updated to mirror client API specs
         ** planBenefits: /api/enrollment/members/$member_number}/plan_benefits
         ** benefits: /api/enrollment/members/${member_number}/benefits **/
        // if (this.config.endpoints.eligibilityHistory) this.getEligibilityHistory();
      },
      (err: ErrorModel) => {
        this.errorHandled = true;
        this.dataLoaded = true;
      },
    );

    this.busy.unshift(_busy);
    this.getMemberAdministrativeAlerts();
  }

  checkShowOnTrueValues(attr): boolean {
    let targetDataExists = false;
    let value: string | number;

    if (attr.showIfTrue) {
      const regProp = new RegExp('{([^}]+)}', 'g');
      const prop = regProp.exec(attr.value);

      if (prop && prop[1]) {
        value = getValue(this.patientDetailService.context, prop[1]);
        targetDataExists = value.toString().length > 0;
      }
    }

    return targetDataExists;
  }

  setMemberId(target: MemberDetailTabConfig): string {
    let newEndpoint = target.endpoint;
    const { memberNumber, memberUniqueId, memberDisplayIdentifier } =
      patientDetailConstants;
    const endpointTokens: { [placeholder: string]: string } = {
      [memberNumber]: this.memberId,
      [memberUniqueId]: this.uniqueMemberId,
      [memberDisplayIdentifier]: this.memberDisplayIdentifer,
    };

    for (const endpointToken in endpointTokens) {
      if (newEndpoint.includes(endpointToken)) {
        newEndpoint = newEndpoint.replace(
          endpointToken,
          endpointTokens[endpointToken]!,
        );
        break;
      }
    }

    return newEndpoint;
  }

  setEndpointFromConfig(endpointConfig: any): string {
    const regex = new RegExp(/\${([^}]*)}/, 'g');
    let newEndpoint: string;

    if (endpointConfig.usesMemberId) {
      newEndpoint = endpointConfig.endpoint.replace(regex, this.memberId);
    } else {
      newEndpoint = endpointConfig.endpoint.replace(
        regex,
        this.getValue(this.patientDetailService.context, endpointConfig),
      );
    }

    return newEndpoint;
  }

  formatDates(context: PatientDetailContext): void {
    Object.keys(context).forEach((key: string) => {
      if (!!context[key] && typeof context[key] === 'object') {
        this.formatDates(context[key]);

        return;
      }
      if (key.includes('date')) {
        context[key] = this.formatDate(context[key]);
      }
    });
  }

  formatDate(date: string): string {
    // formats string date of 2020-12-31 format to 12/31/2020 (MM/DD/YYY)
    const transformedDate: string =
      this.formattingService.restructureValueBasedOnFormat(date, {
        format: 'DATE',
      });

    return transformedDate;
  }

  getMemberPCP(idx?: number): void {
    this.nonDependentEndpointStatus[idx] = false;
    this.checkAPIStatus();
    const memberId: string = this.setEndpointFromConfig(
      this.config.endpoints.pcp,
    );
    const _busy: Subscription = this.patientDetailService
      .getMemberPCP(memberId)
      .pipe(
        catchError((error: ZipBackendError) => this.handleError(error, idx)),
        finalize(() => {
          this.nonDependentEndpointStatus[idx] = true;
          this.checkAPIStatus();
          this.busy.unshift(_busy);
        }),
      )
      .subscribe((response: ApiListResponse<MemberPCP>) => {
        this.patientDetailService.context.pcp = response.results;
      });
  }

  getBenefits(idx?: number): void {
    this.nonDependentEndpointStatus[idx] = false;
    this.checkAPIStatus();
    const benefitAPI: string = this.setEndpointFromConfig(
      this.config?.endpoints?.benefits,
    );
    const _busy: Subscription = this.patientDetailService
      .getBenefits(benefitAPI)
      .pipe(
        catchError((err: any) => this.handleError(err, idx)),
        finalize(() => {
          this.nonDependentEndpointStatus[idx] = true;
          this.checkAPIStatus();
          this.busy.unshift(_busy);
        }),
      )
      .subscribe((resp: ApiListResponse<Benefit>) => {
        if (resp.results?.length) {
          this.patientDetailService.context.benefits = resp.results;
        } else this.patientDetailService.context.benefits = null;
      });
  }

  getPlanBenefits(idx?: number): void {
    this.nonDependentEndpointStatus[idx] = false;
    this.checkAPIStatus();
    const targetEndpoint: string = this.setEndpointFromConfig(
      this.config.endpoints.planBenefits,
    );
    const _busy: Subscription = this.patientDetailService
      .getPlanBenefits(targetEndpoint)
      .pipe(
        catchError((err: any) => this.handleError(err, idx)),
        finalize(() => {
          this.nonDependentEndpointStatus[idx] = true;
          this.checkAPIStatus();
          this.busy.unshift(_busy);
        }),
      )
      .subscribe((resp: ApiListResponse<PlanBenefit>) => {
        this.patientDetailService.context.planBenefits = resp.results;
      });
  }

  modelAttrLinkClicked(ev): void {
    if (ev.url) {
      window
        .open(getValue(this.patientDetailService.context, ev.url), '_blank')
        .focus();
    }
  }

  routeBack(): void {
    this.router.navigate([`provider-portal/patients`]);
  }

  getPdfURL(context: any, prop: any) {
    this.windowService.nativeWindow.open(
      this.genericServices.getObjectProperty(context, prop),
      '_blank',
    );
  }

  checkForPdf(context: any, prop: any) {
    if (!prop) return false;
    if (!this.genericServices.getObjectProperty(context, prop)) return false;

    return true;
  }

  navigateSubNav(event): void {
    this.router.navigate([
      `provider-portal/patients/${this.memberId}/${event.route}`,
    ]);
  }

  getValue(data: object, attr, val?: boolean): string | number {
    return getValue(data, attr.value);
  }

  checkAPIStatus(): void {
    const containsFalse: boolean =
      this.nonDependentEndpointStatus.includes(false);

    if (!containsFalse) {
      this.dataLoaded = true;
    }
  }

  setNonDependentEndpoints(): string[] {
    const keys: string[] = Object.keys(this.config.endpoints);

    if (keys.indexOf('member') > -1) {
      keys.splice(keys.indexOf('member'), 1);
    }
    if (keys.indexOf('coverage') > -1) {
      keys.splice(keys.indexOf('coverage'), 1);
    }
    if (keys.indexOf('eligibilityHistory') > -1) {
      keys.splice(keys.indexOf('eligibilityHistory'), 1);
    }

    return keys;
  }

  setPregnancyConditions() {
    if (this.config?.conditionals) {
      this.pregnancyConditions = this.config.conditionals.pregnancyTab;
    }
  }

  checkAndSetPregnancyConditions(memberDetails: Member, status?: string): void {
    const { activeStatus, pregnancyForm } = patientDetailConstants;
    const { gender, minAge } = this.pregnancyConditions || {};
    const { gender: memberGender, birth_date: birthDate } = memberDetails;

    const isGenderValid = gender?.toLowerCase() === memberGender?.toLowerCase();
    const isAgeValid =
      minAge && minAge <= this._calculateAge(new Date(birthDate));
    const isActiveCoverage = status === activeStatus;

    const canSubmitPregnancyAssessmentForm = this
      .enableCoverageCheckForPregnancyForm
      ? isGenderValid && isAgeValid && isActiveCoverage
      : isGenderValid && isAgeValid;

    if (!canSubmitPregnancyAssessmentForm) {
      delete this.config.tabs[pregnancyForm];
      this.tabLabels = this.tabLabels.filter(
        (tabLabel: string) => tabLabel !== this.pregnancyConditions?.tabName,
      );
      this.tabsConfig = this.tabsConfig.filter(
        (tabConfig: PatientDetailTabConfig) =>
          tabConfig.label !== this.pregnancyConditions?.tabName,
      );

      if (this.config?.actionButton?.subnav?.items) {
        this.config.actionButton.subnav.items =
          this.config.actionButton.subnav.items.filter(
            (item: ButtonSubnavItem) =>
              item.label !== this.pregnancyConditions?.navName,
          );
      }
    }
  }

  _calculateAge(birthday) {
    // birthday is a date
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch

    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  getMemberAdministrativeAlerts(): void {
    const apiEndpoint =
      this.config.endpoints?.memberAdministrativeAlert?.endpoint?.replace(
        '${member_number}',
        this.memberId,
      );

    this.alerts$ = this.patientDetailService
      .getMemberAdministrativeAlerts(apiEndpoint)
      .pipe(
        tap((alerts: ApiListResponse<MemberAlert>) => {
          if (!alerts?.results?.length) {
            this.noDataFound = noDataFoundMessage;
          }
        }),
        catchError(() => (this.noDataFound = noDataFoundMessage)),
      );
  }

  getAlertLabel(
    alert: MemberAlert,
    attribute: { memberAlerts: Option[] },
  ): string {
    // find alert option from config based on code
    const { displayValue, code } = camelCase(alert);
    const alertFromConfig = attribute?.memberAlerts.find(
      (memberAlert: Option) => memberAlert?.value === code,
    );

    return alertFromConfig?.label || displayValue;
  }

  private getTargetRoute() {
    const segments = this.route.snapshot?.['_routerState']?.url.split('/');

    segments?.shift(); // removes empty segment to match previous urlSegment array

    const nativeWindowLocation = this.windowService.nativeWindow.location;

    if (segments?.length >= 4) {
      return segments[3];
    } else {
      this.configService.appRoute &&
        nativeWindowLocation.assign(
          `${nativeWindowLocation.origin}/${this.configService.appRoute}/dashboard`,
        );
    }
  }

  private handleError(err: any, idx: number) {
    this.logger.error(err);
    this.nonDependentEndpointStatus[idx] = true;
    this.checkAPIStatus();

    return of(err);
  }

  private setCurrentTab() {
    const targetRoute = this.getTargetRoute();

    this.activeTab = this.tabsConfig.indexOf(
      this.tabsConfig.find((target: any) => target.prop === targetRoute),
    );
    this.activeTabObj = this.tabsConfig.find(
      (target: any) => target.prop === targetRoute,
    );
    if (this.activeTabObj?.cx_event_key) {
      this.analyticsService.sendEvent(this.activeTabObj.cx_event_key);
    }
    this.patientDetailService.tabsConfig =
      this.config[this.activeTabObj?.targetConfigKey];
    if (this.patientDetailService.tabsConfig?.table?.endpoint) {
      this.patientDetailService.tabsConfig.table.endpoint = this.setMemberId(
        this.patientDetailService.tabsConfig.table,
      );
    }
  }
}
