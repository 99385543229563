<article [zipBusy]="busy" class="centered-base_page">
  <div class="centered-base_page__content">
    <aside class="panel" *ngIf="config.sidebar?.sections">
      <section *ngIf="loaded">
        <ng-container *ngFor="let section of config.sidebar?.sections">
          <div class="section" [ngSwitch]="section.format">
            <header class="layout--detail__aside__header" *ngIf="section.label">
              <h6 role="heading" aria-level="1">{{ section.label }}</h6>
            </header>

            <section
              class="patient-detail__aside__attrs"
              *ngSwitchCase="'attribute-list'"
            >
              <div
                class="patient-detail__aside__model-attributes"
                *ngFor="let attribute of section.attributes"
              >
                <div *ngIf="!attribute.type">
                  <model-attribute
                    *ngIf="
                      (!attribute.dataAsLabel && !attribute.showIfTrue) ||
                      (attribute.showIfTrue && checkShowOnTrueValues(attribute))
                    "
                    [context]="context"
                    [config]="attribute"
                    (linkClicked)="modelAttrLinkClicked($event)"
                    direction="column"
                  ></model-attribute>
                  <div *ngIf="attribute.dataAsLabel">
                    <label>{{ getValue(context, attribute) }}</label>
                  </div>
                  <div *ngFor="let nestedAttr of attribute.additionalAttrs">
                    <model-attribute
                      [context]="context"
                      [config]="nestedAttr"
                    ></model-attribute>
                  </div>
                </div>
                <div *ngIf="attribute.type === 'pdfLink'">
                  <label> {{ attribute.label }} </label>
                  <a
                    *ngIf="checkForPdf(context, attribute.prop)"
                    (click)="getPdfURL(context, attribute.prop)"
                  >
                    <p class="link">
                      {{
                        genericServices.getObjectProperty(
                          context,
                          attribute.linkText
                        ) || attribute.noValueMessage
                      }}
                    </p>
                  </a>
                  <p *ngIf="!checkForPdf(context, attribute.prop)">
                    {{ attribute.noValueMessage }}
                  </p>
                  <div *ngIf="attribute.dataAsLabel">
                    <label>{{ getValue(context, attribute) }}</label>
                  </div>
                  <div *ngFor="let nestedAttr of attribute.additionalAttrs">
                    <model-attribute
                      [context]="context"
                      [config]="nestedAttr"
                    ></model-attribute>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </ng-container>
      </section>
    </aside>
    <div class="main-container">
      <div class="provisioner">
        <div
          *ngIf="config?.header && state === 'edit'"
          class="column-header"
          role="heading"
          aria-level="1"
        >
          {{ config?.header }}
        </div>
        <div
          *ngIf="config?.reviewHeader && state === 'review'"
          class="column-header"
          role="heading"
          aria-level="1"
        >
          {{ config?.reviewHeader }}
        </div>
        <div
          *ngIf="config?.confirmationHeader && state === 'confirm'"
          class="column-header"
          role="heading"
          aria-level="1"
        >
          {{ config?.confirmationHeader }}
        </div>
        <div
          *ngIf="config?.subheader && state === 'edit'"
          class="column-subheader"
        >
          {{ config?.subheader }}
        </div>
        <div
          *ngIf="config?.reviewSubheader && state === 'review'"
          class="column-subheader"
        >
          {{ config?.reviewSubheader }}
        </div>
        <div
          *ngIf="config?.confirmationMessage && state === 'confirm'"
          class="column-subheader"
        >
          {{ config?.confirmationMessage }}
        </div>
        <div class="badge-container" *ngIf="state === 'confirm'">
          <div class="badge-spacer">
            <badge [config]="config.badge">
              <div class="badge-body">
                <div class="badge-icon badge-text__center">
                  <i class="icon icon--product icon--large">care</i>
                </div>
                <div class="badge-header">
                  <p class="t-bold card-header badge-text__center">
                    {{
                      getObjectProperty(response, config.transactionLocation) ||
                        noSpace
                    }}
                  </p>
                </div>
                <div class="badge-subheader badge-text__center">
                  <p class="card-subheader">Transaction ID</p>
                </div>
              </div>
            </badge>
          </div>
          <div class="badge-spacer">
            <badge [config]="config.badge">
              <div class="badge-body">
                <div class="badge-icon badge-text__center">
                  <i class="icon icon--product icon--large">clipboard</i>
                </div>
                <div class="badge-header">
                  <p class="t-bold card-header badge-text__center">
                    {{
                      getObjectProperty(response, config.statusLocation) ||
                        config?.defaultStatus ||
                        'Completed'
                    }}
                  </p>
                </div>
                <div class="badge-subheader badge-text__center">
                  <p class="card-subheader">Transaction Status</p>
                </div>
              </div>
            </badge>
          </div>
          <div class="badge-spacer">
            <badge [config]="config.badge">
              <div class="badge-body">
                <div class="badge-icon badge-text__center">
                  <i class="icon icon--product icon--large">profile_product</i>
                </div>
                <div class="badge-header">
                  <p class="t-bold card-header badge-text__center">
                    {{
                      getObjectProperty(member, config.patientNameLocation) ||
                        noSpace
                    }}
                  </p>
                </div>
                <div class="badge-subheader badge-text__center">
                  <p class="card-subheader">Patient</p>
                </div>
              </div>
            </badge>
          </div>
        </div>
        <div *ngIf="state === 'confirm'" class="button-container">
          <zip-button
            [config]="config.printButton"
            (zipButtonClicked)="print($event)"
          ></zip-button>
        </div>
        <div class="column-container">
          <div *ngFor="let card of config?.cards">
            <card
              id="{{ card.id }}"
              class="cardholder card item skinny-badge"
              (postInit)="postInit()"
            >
              <div title class="card-header card-edit">
                <div class="left">
                  {{ card.header }}
                </div>
                <div
                  *ngIf="state === 'review'"
                  class="right"
                  (click)="scrollTo(card.id)"
                >
                  <i class="icon icon--medium">edit</i>
                </div>
              </div>
              <div subheader>
                <div class="t-data address">
                  {{ card.subheader }}
                </div>
              </div>
              <div body>
                <div class="title-border"></div>
                <ng-container *ngFor="let control of card?.controls">
                  <div
                    [style.width]="
                      controls.get(control.prop)?.width
                        ? controls.get(control.prop).width
                        : '100%'
                    "
                  >
                    <div [hidden]="state !== 'edit'">
                      <ng-container [ngSwitch]="control.type">
                        <phone-array
                          *ngSwitchCase="'phone_numbers'"
                          [group]="phoneFormGroup"
                          [config]="control"
                        ></phone-array>
                        <flyout-dropdown
                          *ngSwitchCase="'flyout'"
                          [group]="formGroup"
                          [config]="control"
                        ></flyout-dropdown>
                        <form-control
                          *ngSwitchDefault
                          [group]="formGroup"
                          [config]="control"
                          [control]="formGroup.get(control.prop)"
                        ></form-control>
                        <form-group
                          class="FormGroup__Card"
                          *ngSwitchCase="'formGroup'"
                          [configs]="control.controls"
                          [form]="formGroup"
                        ></form-group>
                      </ng-container>
                      <ng-container *ngIf="showError(card, control)">
                        <pp-error-message
                          [errorMessage]="errorMessage"
                        ></pp-error-message>
                      </ng-container>
                    </div>
                    <div class="ReviewCards" *ngIf="state !== 'edit'">
                      <p
                        class="t-data t-bold"
                        *ngIf="
                          controls.get(control.prop)?.type !== 'phone_numbers'
                        "
                      >
                        {{ controls.get(control.prop)?.label }}
                      </p>
                      <div [ngSwitch]="controls.get(control.prop)?.type">
                        <div *ngSwitchCase="'dropdown'">
                          {{ getDropdownDisplayValue(control.prop) }}
                        </div>
                        <div *ngSwitchCase="'toggle'">
                          {{ getDropdownDisplayValue(control.prop) }}
                        </div>
                        <div *ngSwitchCase="'radio'">
                          {{ getDropdownDisplayValue(control.prop) }}
                        </div>
                        <div
                          *ngSwitchCase="'formGroup'"
                          class="FormGroup__Card"
                        >
                          <ng-container
                            *ngFor="let formControl of control.controls"
                          >
                            <pp-pregnancy-form-group
                              [ngStyle]="{ 'grid-area': formControl.grid }"
                              [config]="formControl"
                              [context]="formGroup?.value"
                              [checkboxValues]="
                                formControl.type === 'checkbox'
                                  ? getCheckboxValues(
                                      formControl.prop,
                                      formControl?.options
                                    )
                                  : null
                              "
                            ></pp-pregnancy-form-group>
                          </ng-container>
                        </div>
                        <div *ngSwitchCase="'flyout'" class="root">
                          <div class="left-flyout">
                            <label
                              *ngIf="
                                control.displayFields.name &&
                                formGroup.get(control.prop)?.value
                              "
                              class="form-control__label"
                            >
                              <p class="t-data t-bold">
                                {{
                                  formGroup.get(control.prop)?.value?.name ||
                                    noSpace
                                }}
                              </p>
                            </label>
                          </div>
                          <!-- load this from config -->
                          <div class="left-flyout">
                            <label
                              *ngIf="control.displayFields.address"
                              class="form-control__label"
                            >
                              <p class="spacing t-data t-bold">Address:</p>
                              <p
                                *ngIf="
                                  !formGroup.get(control.prop).value['address']
                                "
                              >
                                {{ noSpace }}
                              </p>
                              <div
                                class="address"
                                *ngIf="
                                  formGroup.get(control.prop).value['address']
                                "
                              >
                                {{
                                  formGroup.get(control.prop).value['address'][
                                    'street_name_1'
                                  ]
                                }}<br
                                  *ngIf="
                                    formGroup.get(control.prop).value[
                                      'address'
                                    ]['street_name_1']
                                  "
                                />
                                {{
                                  formGroup.get(control.prop).value['address'][
                                    'street_name_2'
                                  ]
                                }}<br
                                  *ngIf="
                                    formGroup.get(control.prop).value[
                                      'address'
                                    ]['street_name_2']
                                  "
                                />
                                {{
                                  formGroup.get(control.prop).value['address'][
                                    'city_name'
                                  ]
                                }},
                                {{
                                  formGroup.get(control.prop).value['address'][
                                    'state'
                                  ]
                                }}
                                {{
                                  formGroup.get(control.prop).value['address'][
                                    'zip_code'
                                  ]
                                }}
                              </div>
                            </label>
                            <label
                              *ngIf="control.displayFields.group_npi"
                              class="form-control__label"
                            >
                              <p class="spacing t-data t-bold">Group NPI:</p>
                              <p>
                                {{
                                  formGroup.get(control.prop).value[
                                    'group_npi'
                                  ] || noSpace
                                }}
                              </p>
                            </label>
                            <label
                              *ngIf="control.displayFields.id_number"
                              class="form-control__label"
                            >
                              <p class="spacing t-data t-bold">ID Number:</p>
                              <p>
                                {{
                                  formGroup.get(control.prop).value['number'] ||
                                    noSpace
                                }}
                              </p>
                            </label>
                            <label
                              *ngIf="control.displayFields.specialty"
                              class="form-control__label"
                            >
                              <p class="spacing t-data t-bold">
                                Primary Specialty:
                              </p>
                              <p
                                *ngIf="
                                  formGroup.get(control.prop).value[
                                    'specialties'
                                  ].length
                                "
                              >
                                {{
                                  formGroup.get(control.prop).value[
                                    'specialties'
                                  ][0]['name']
                                }}
                              </p>
                              <p
                                *ngIf="
                                  !formGroup.get(control.prop).value[
                                    'specialties'
                                  ]?.length
                                "
                              >
                                {{ noSpace }}
                              </p>
                            </label>
                          </div>
                        </div>
                        <div *ngSwitchCase="'phone_numbers'">
                          <div class="phone-header">
                            <div class="column-container">
                              <p
                                class="t-data t-bold"
                                [innerHTML]="
                                  controls.get(control.prop)?.label_phone ||
                                  noSpace
                                "
                              ></p>
                            </div>
                            <div class="column-container">
                              <p
                                class="t-data t-bold"
                                [innerHTML]="
                                  controls.get(control.prop)?.label || noSpace
                                "
                              ></p>
                            </div>
                          </div>
                          <ng-container
                            *ngFor="let item of getPhoneNumbers(control.prop)"
                          >
                            <div class="phone-header">
                              <div
                                class="column-container"
                                [innerHTML]="item.phone_number || noSpace"
                              ></div>
                              <div
                                class="column-container"
                                [innerHTML]="item.type || noSpace | titlecase"
                              ></div>
                            </div>
                          </ng-container>
                        </div>
                        <div class="checkbox-grid" *ngSwitchCase="'checkbox'">
                          <div
                            *ngFor="let line of getCheckboxValues(control.prop)"
                          >
                            - {{ line || noSpace }}
                          </div>
                          <div
                            *ngIf="getCheckboxValues(control.prop).length === 0"
                          >
                            n/a
                          </div>
                        </div>
                        <div *ngSwitchDefault>
                          {{ getDefaultReviewBox(control) || noSpace }}
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="centered-base_page__always_on_top">
    <zip-button
      *ngIf="state === 'edit'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonCancel"
      (zipButtonClicked)="cancel()"
    >
    </zip-button>
    <zip-button
      *ngIf="state === 'confirm'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonReturn"
      (zipButtonClicked)="cancel()"
    >
    </zip-button>
    <zip-button
      *ngIf="state === 'edit'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonReview"
      (zipButtonClicked)="review()"
    >
    </zip-button>
    <zip-button
      *ngIf="state === 'review'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonBack"
      (zipButtonClicked)="back($event)"
    >
    </zip-button>
    <zip-button
      *ngIf="state === 'review'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonSubmit"
      (zipButtonClicked)="submit()"
    >
    </zip-button>
  </div>
</article>

<modal
  #errorFlyout
  *ngIf="error"
  [config]="config?.errorModal"
  [condition]="error"
  (cancel)="toggleError()"
>
  <div id="errorMessage" body>{{ config?.errorModal.message }}</div>
</modal>
