import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { Group } from '../../../shared/models/shared/UserPayloadModels/UserEditModel.model';
import { APIResponse, APIService } from '@zipari/web-services';

@Injectable({
  providedIn: 'root',
})
export class ManagePortalUsersDetailHttpService {
  constructor(private api: APIService) {}

  getDropdownList(endpoint: string): Observable<APIResponse<Group>> {
    return this.api
      .get(endpoint)
      .pipe(catchError((error) => throwError(error)));
  }
}
