import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '../button/button.module';
import { IconModule } from '../icon/icon.module';

import { MessageBannerComponent } from './message-banner.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [MessageBannerComponent],
  imports: [CommonModule, ButtonModule, IconModule, HttpClientModule],
  exports: [MessageBannerComponent],
})
export class MessageBannerModule {}
