<article class="layout--detail" [zipBusy]="busy">
  <article class="layout--detail__content" *ngIf="!!dataLoaded">
    <aside class="layout--detail__aside">
      <ng-container>
        <div *ngFor="let section of sidePanelSections">
          <div
            [ngSwitch]="section.format"
            *ngIf="!!dataLoaded && !errorHandled"
          >
            <header class="layout--detail__aside__header" *ngIf="section.label">
              <h6 role="heading" aria-level="1">{{ section.label }}</h6>
            </header>

            <!--                    ERHO: TODO: do this properly by using design system change-->
            <section
              class="patient-detail__aside__attrs"
              *ngSwitchCase="'attribute-list'"
            >
              <div
                class="patient-detail__aside__model-attributes"
                *ngFor="let attribute of section.attributes"
              >
                <!-- Refactor as part of: PP-2497 -->
                <!-- We need to just remove all this nonsense and USE DS like Eric suggested -->
                <!-- I have no clue what this boolean logic does and I am afraid to touch it -->
                <div *ngIf="!attribute.type">
                  <model-attribute
                    *ngIf="
                      (!attribute.dataAsLabel && !attribute.showIfTrue) ||
                      (attribute.showIfTrue && checkShowOnTrueValues(attribute))
                    "
                    [context]="patientDetailService.context"
                    [config]="attribute"
                    (linkClicked)="modelAttrLinkClicked($event)"
                    direction="column"
                  ></model-attribute>
                  <div *ngIf="attribute.dataAsLabel">
                    <label>{{
                      getValue(patientDetailService.context, attribute)
                    }}</label>
                  </div>
                  <div *ngFor="let nestedAttr of attribute.additionalAttrs">
                    <model-attribute
                      [context]="patientDetailService.context"
                      [config]="nestedAttr"
                    ></model-attribute>
                  </div>
                </div>
                <div *ngIf="attribute.type === 'alert-list'">
                  <label [innerHTML]="attribute.label"></label>

                  <ng-container *ngIf="alerts$ | async as alerts; else loading">
                    <p
                      class="t-data"
                      *ngIf="!alerts?.results?.length"
                      [innerHTML]="noDataFound"
                    ></p>
                    <ng-container *ngFor="let alert of alerts?.results">
                      <status
                        *ngIf="getAlertLabel(alert, attribute)"
                        [config]="{
                          label: getAlertLabel(alert, attribute),
                          fitContent: true,
                          whiteSpaceNormal: true,
                        }"
                        [status]="attribute.statusType"
                      ></status>
                    </ng-container>
                  </ng-container>

                  <ng-template #loading>
                    <spinner size="small" flexCenter="true"></spinner>
                  </ng-template>
                </div>
                <div *ngIf="attribute.type === 'pdfLink'">
                  <label> {{ attribute.label }} </label>
                  <a
                    *ngIf="
                      checkForPdf(patientDetailService.context, attribute.prop)
                    "
                    (click)="
                      getPdfURL(patientDetailService.context, attribute.prop)
                    "
                  >
                    <p class="link">
                      {{
                        genericServices.getObjectProperty(
                          patientDetailService.context,
                          attribute.linkText
                        ) || attribute.noValueMessage
                      }}
                    </p>
                  </a>
                  <p
                    *ngIf="
                      !checkForPdf(patientDetailService.context, attribute.prop)
                    "
                  >
                    {{
                      genericServices.getObjectProperty(
                        patientDetailService.context,
                        attribute.linkText
                      ) || attribute.noValueMessage
                    }}
                  </p>
                  <div *ngIf="attribute.dataAsLabel">
                    <label>{{
                      getValue(patientDetailService.context, attribute)
                    }}</label>
                  </div>
                  <div *ngFor="let nestedAttr of attribute.additionalAttrs">
                    <model-attribute
                      [context]="patientDetailService.context"
                      [config]="nestedAttr"
                    ></model-attribute>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div *ngIf="!!errorHandled && section.error">
            <message-banner [config]="section.error.banner"></message-banner>
          </div>
        </div>
      </ng-container>
    </aside>
    <main class="layout--detail__main">
      <header class="layout--detail__main__breadcrumb">
        <zip-button
          [config]="config.backButton"
          (zipButtonClicked)="routeBack()"
        ></zip-button>
      </header>

      <section
        class="patient-detail_patient-name layout--list__header"
        *ngIf="
          !patientDetailService.context?.member?.member_name && !errorHandled
        "
      >
        <h3 class="layout--detail__main--header">
          <span [innerHTML]="memberDisplayName"></span>

          @if (patientDetailService.context.policy_status) {
            <p class="patient-detail--header__pill">
              <status
                [config]="{
                  hideBackground: config?.hideStatusBackground,
                  label: getStatusLabel(
                    patientDetailService.context.policy_status
                  ),
                  statuses: config?.statuses,
                }"
              ></status>
            </p>
          }
        </h3>

        <div
          class="patient-detail__action-btn"
          *ngIf="config.actionButton && !errorHandled"
        >
          <zip-button
            [config]="config.actionButton"
            (zipButtonClicked)="navigateSubNav($event)"
          ></zip-button>
        </div>
      </section>

      <section *ngIf="!!errorHandled">
        <message-banner
          [config]="config.mainContainer.errorHandling.banner"
        ></message-banner>
      </section>

      <section class="layout--detail__main__section">
        <tabs
          pageTabs="true"
          [tabLabels]="tabLabels"
          (tabSelected)="tabSelected($event)"
          [selectedIndex]="activeTab"
        >
        </tabs>
      </section>
      <div class="container">
        <div class="patient-detail__main-container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </main>
  </article>
</article>
