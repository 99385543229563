<p class="ReadMore">
  <span
    class="ReadMore__Text t-data"
    [innerHTML]="text | readmore: pipeOptions"
  ></span>
  <span *ngIf="!clicked && showButton">...</span>

  @if (showButton) {
    <zip-button
      class="ReadMore__Button"
      [config]="{
        level: 'text',
        content: clicked ? 'Read Less' : 'Read More',
      }"
      (zipButtonClicked)="readClicked()"
      (keydown.enter)="readClicked()"
    ></zip-button>
  }
</p>
