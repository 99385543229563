<div class="Container">
  <card [config]="{ hideSubHeader: true }">
    <div title class="Card__Title">
      <div [innerHTML]="control?.label"></div>
      <div
        *ngIf="control?.validators && control?.isRequiredRedAsterisk"
        class="t-data t-bold Spaced__Star"
      >
        *
      </div>
    </div>
    <div body>
      <div class="Title__Border"></div>
      <ng-container *ngIf="groupValue?.length > 0">
        <div
          class="Group__List"
          *ngFor="let item of groupValue"
          let
          indexOfelement="index"
        >
          <div
            class="t-bold t-data"
            [innerHTML]="item?.row?.name || config?.defaultGroupName"
          ></div>
          <zip-button
            *ngIf="config?.removeButton"
            [config]="config.removeButton"
            (zipButtonClicked)="removeGroup(item?.row?.id)"
          ></zip-button>
        </div>
      </ng-container>
      <div
        *ngIf="
          groupValue?.length === 0 ||
          (groupControl?.invalid &&
            (groupControl?.dirty || groupControl?.touched))
        "
      >
        <div
          class="Error"
          *ngIf="groupValue?.length === 0 || groupControl.errors"
        >
          <icon
            [config]="config?.iconConfig"
            [name]="'warning'"
            class="Error__Icon"
          ></icon>
          <p
            class="t-caption t-error"
            [innerHTML]="control?.errorMessage || defaultErrorMessage"
          ></p>
        </div>
      </div>
    </div>
    <zip-button
      footer
      class="Add__Button"
      *ngIf="config?.addButton"
      [config]="config.addButton"
      (zipButtonClicked)="openFlyout()"
    ></zip-button>
  </card>
</div>

<table-flyout
  [showFlyout]="showFlyout"
  [modalConfig]="config?.modalConfig"
  [tableConfig]="config?.groupListTable"
  [preSelectedRows]="groupValue"
  (selectedOptionList)="getSelectedOptionList($event)"
  (closeFlyout)="closeFlyout()"
></table-flyout>
