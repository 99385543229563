<div class="confirmation" *ngIf="state === 'review'">
  <h2 class="t-bold item">{{ config.reviewHeader }}</h2>
</div>
<div class="confirmation" *ngIf="state === 'confirm'">
  <h2 class="t-bold item">Access Rights Updated</h2>
  <card class="card item skinny-badge">
    <div title>
      <i class="icon icon--product icon--large">{{
        config.confirmationScreenIcon
      }}</i>
    </div>
    <div body>
      <h6 class="t-bold">
        {{ config.successMessage }}
      </h6>
    </div>
    <div footer>{{ provisioner.getUserName() }}</div>
  </card>
  <zip-button
    class="item"
    [config]="config.printButton"
    (zipButtonClicked)="print($event)"
  >
  </zip-button>
</div>
<br />
<div class="viewer-container" *ngIf="state === 'review'">
  <viewer-provisioning
    [config]="config.viewProvisioner"
    [ignoreAPI]="true"
    [viewMode]="true"
  >
  </viewer-provisioning>
</div>
<div class="viewer-container" *ngIf="state === 'confirm'">
  <viewer-provisioning [config]="config.viewProvisioner" [viewMode]="true">
  </viewer-provisioning>
</div>
