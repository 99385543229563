import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { randomString } from '@zipari/shared-util-common';
import { ExpandingArticleConfig } from './expanding-article.model';
import { checkInputsForText } from '../../design-system.helper';

@Component({
  selector: 'expanding-article',
  templateUrl: './expanding-article.component.html',
  styleUrls: ['./expanding-article.component.scss'],
})
export class ExpandingArticleComponent implements AfterViewInit, OnInit {
  @Input() config: ExpandingArticleConfig;
  @Input() text: string;
  @Input() isExpanded = false;
  @ViewChild('articleElement') articleElement: ElementRef;
  @ViewChild('articleText') articleText: ElementRef;

  public showLink = true;
  public articleId = `expandedContent-${randomString()}`;
  private clampedText;
  public maxHeight;
  public elementHeight;
  private lineHeight;

  constructor(private renderer: Renderer2) {}

  private setTextProperty = (text) => {
    this.renderer.setProperty(
      this.articleText.nativeElement,
      'innerHTML',
      text,
    );
  };

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
    if (!this.isExpanded) {
      this.clampText();
    } else {
      this.setTextProperty(this.text.trim());
    }
  }

  clampText() {
    const clamp = () => {
      this.setTextProperty(this.clampedText);
    };

    if (this.clampedText !== undefined) {
      clamp();

      return;
    }
    if (
      this.articleElement.nativeElement.getBoundingClientRect().height <=
      this.maxHeight
    ) {
      this.clampedText = this.text;
      clamp();

      return;
    }
    while (
      this.articleElement.nativeElement.getBoundingClientRect().height >
      this.maxHeight
    ) {
      this.clampedText =
        this.clampedText?.slice(0, -1) ||
        this.articleElement.nativeElement.innerHTML;
      clamp();
    }
  }

  ngOnInit() {
    const [config] = checkInputsForText([this.config]);

    this.config = new ExpandingArticleConfig(config);
  }

  ngAfterViewInit() {
    const half = 2;
    const para = this.articleElement.nativeElement.querySelector(
      '.ExpandingArticle__Text',
    );

    this.lineHeight = parseInt(
      this.config.lineHeight || getComputedStyle(para).lineHeight,
      10,
    );
    if (!this.lineHeight) {
      throw new Error(
        "Line height must be explicitly set as numerical value for parent element (can't be 'normal' or 'inherit' etc.)",
      );
    }
    this.maxHeight =
      this.config.lines * this.lineHeight + this.lineHeight / half;
    this.elementHeight =
      this.articleElement.nativeElement.getBoundingClientRect().height;
    if (this.elementHeight < this.maxHeight) {
      this.renderer.setStyle(
        this.articleElement.nativeElement.querySelector(
          '.ExpandingArticle__Ellipsis',
        ),
        'display',
        'none',
      );
    }
    this.isExpanded ? this.setTextProperty(this.text.trim()) : this.clampText();
  }
}
