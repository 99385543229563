<div
  *ngIf="control"
  class="form-control__wrapper"
  [ngClass]="{
    'form-control__wrapper--action': direction === 'action',
    'form-control__wrapper--inline': direction === 'row',
    'form-control__wrapper--row-even': direction === 'row-even',
    'form-control__wrapper--flat':
      direction === 'filter' || direction === 'search',
    'form-control__wrapper--grouping': direction === 'grouping',
  }"
  role="group"
  [attr.aria-labelledby]="config.prop"
>
  <!-- TODO: move to password form-control -->
  <div
    class="form-control__complex-password form-control__complex-password--desktop"
    *ngIf="config.focus && showFocus && config.type === types.password"
  >
    <ng-container
      *ngIf="config.focus === 'complex_password'; else defaultFocus"
    >
      <p
        class="form-control__complex-password__label t-data t-bold"
        [innerHTML]="config?.focusDisplay?.label"
      ></p>
      <p
        class="form-control__complex-password__description t-data"
        [innerHTML]="config?.focusDisplay?.description"
      ></p>

      <div class="form-control__complex-password__rules">
        <div
          class="form-control__complex-password__rule"
          *ngFor="let rule of config?.focusDisplay?.rules"
        >
          <icon
            [name]="rule.value ? 'check_circle' : 'close'"
            [config]="{
              fill: rule.value ? 'success' : 'text--2',
            }"
          >
          </icon>

          <p class="t-data" [innerHTML]="rule.label"></p>
        </div>
      </div>
    </ng-container>

    <ng-template #defaultFocus>
      <p class="t-data" [innerHTML]="config.focus"></p>
    </ng-template>
  </div>

  <ng-container *ngIf="config?.label">
    <div
      class="form-control__label__wrapper"
      [ngClass]="{
        'form-control__label__wrapper--one-line': config?.isLabelOneLine,
      }"
    >
      <label
        class="form-control__label"
        [ngClass]="{
          'no-link': !config.link,
          'form-control__label--one-line': config?.isLabelOneLine,
        }"
        [attr.for]="config.prop || config.name"
        [id]="(config?.id || config?.prop) + '-label'"
      >
        <div
          class="u-flex u-maxX"
          [ngClass]="{
            'u-alignBaseline': !config?.tooltip,
            'u-alignCenter': config?.tooltip,
            'form-control__label--hidden':
              direction === 'grouping' && control.errors && control.touched,
          }"
        >
          <!-- todo: possibly extract this into being used for more than just the required validator... -->
          <h2
            class="t-data"
            [ngClass]="{ 't-bold': direction !== 'grouping' }"
            [innerHTML]="label"
          ></h2>

          <div *ngIf="config?.info">
            <icon
              name="info"
              [config]="{ type: 'link' }"
              (click)="iconClick.emit(config)"
            >
            </icon>
          </div>
        </div>
      </label>
      <span
        *ngIf="
          config | showRequiredAsterisk: isRequired : isRequiredLabelString
        "
        [ngClass]="{
          'form-control__label__asterisk--error': config.isRequiredRedAsterisk,
        }"
        class="form-control__label__asterisk"
      >
        *
      </span>

      <span
        *ngIf="config | showOptionalLabel: isRequired"
        class="form-control__label__optional"
      >
        Optional
      </span>

      <tooltip
        *ngIf="config.tooltip"
        class="form-control__tooltip"
        [config]="{
          description: config.tooltip,
          fill: config.fill ? config.fill : 'icon-color',
          leftAlign: config.tooltipLeftAlign,
        }"
      >
      </tooltip>
    </div>
  </ng-container>

  <div
    class="form-control__label__link"
    [ngClass]="{
      'form-control__label__link--top-right':
        config.linkPosition === nonLabelPositions.topRight,
      'form-control__label__link--bottom-right':
        config.linkPosition === nonLabelPositions.bottomRight,
      'form-control__label__link--bottom-left':
        config.linkPosition === nonLabelPositions.bottomLeft,
    }"
    *ngIf="config.link && direction !== 'row' && direction !== 'action'"
  >
    <a class="t-caption t-link" (click)="linkClick.emit(config?.link)">
      {{ config?.link }}
    </a>
  </div>

  <ng-container *ngIf="config?.description">
    <read-more class="form-control__description" [text]="config?.description">
    </read-more>
  </ng-container>

  <ng-container *ngIf="type !== 'description'" [ngSwitch]="showControl && type">
    <control-radio
      *ngSwitchCase="'radio'"
      [group]="group"
      [control]="control"
      [config]="config"
    >
    </control-radio>

    <control-checkbox
      *ngSwitchCase="'checkbox'"
      [control]="control"
      [config]="config"
    >
    </control-checkbox>

    <single-checkbox
      *ngSwitchCase="'singleCheckbox'"
      [control]="control"
      [config]="config"
    >
    </single-checkbox>

    <control-time
      [ngClass]="{
        'u-fillRemaining': direction === 'row' || direction === 'action',
      }"
      *ngSwitchCase="'time'"
      [control]="control"
      [config]="config"
    >
    </control-time>

    <control-text
      (focusOutEvent)="handleFocusOut()"
      (focusEvent)="handleFocus()"
      (iconRightClicked)="handleIconRighClicked()"
      class="u-maxX"
      [ngClass]="{
        'u-fillRemaining': direction === 'row' || direction === 'action',
      }"
      *ngSwitchCase="'text'"
      [control]="control"
      [config]="config"
    >
    </control-text>

    <control-password
      (focusOutEvent)="handleFocusOut()"
      (focusEvent)="handleFocus()"
      *ngSwitchCase="'password'"
      [canToggle]="canToggle"
      [config]="config"
      [control]="control"
      [ngClass]="{
        'u-fillRemaining': direction === 'row' || direction === 'action',
      }"
    >
    </control-password>

    <ng-container *ngSwitchCase="'date'">
      <ng-container *ngTemplateOutlet="dateControl"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'datePicker'">
      <ng-container *ngTemplateOutlet="dateControl"></ng-container>
    </ng-container>

    <ng-template #dateControl>
      <control-text
        (focusOutEvent)="handleFocusOut()"
        (focusEvent)="handleFocus()"
        (iconRightClicked)="handleIconRighClicked()"
        [ngClass]="{
          'u-fillRemaining': direction === 'row' || direction === 'action',
        }"
        [control]="control"
        [config]="config"
      >
      </control-text>
    </ng-template>

    <control-tel
      (focusOutEvent)="handleFocusOut()"
      (focusEvent)="handleFocus()"
      [ngClass]="{
        'u-fillRemaining': direction === 'row' || direction === 'action',
      }"
      *ngSwitchCase="'tel'"
      [control]="control"
      [config]="config"
    >
    </control-tel>

    <control-dropdown
      [ngClass]="{
        'u-fillRemaining': direction === 'row' || direction === 'action',
      }"
      *ngSwitchCase="'dropdown'"
      [control]="control"
      [config]="config"
      [selectedIndex]="selectedIndex"
    >
    </control-dropdown>

    <states
      [ngClass]="{
        'u-fillRemaining': direction === 'row' || direction === 'action',
      }"
      *ngSwitchCase="'states'"
      [control]="control"
      [config]="config"
    >
    </states>

    <control-switch
      *ngSwitchCase="'switch'"
      [control]="control"
      [config]="config"
    >
    </control-switch>

    <control-toggle
      *ngSwitchCase="'toggle'"
      [control]="control"
      [config]="config"
    >
    </control-toggle>

    <control-textarea
      class="form-control__textarea"
      [ngClass]="{
        'u-fillRemaining': direction === 'row' || direction === 'action',
      }"
      *ngSwitchCase="'textarea'"
      [control]="control"
      [config]="config"
    >
    </control-textarea>

    <control-typeahead
      [ngClass]="{
        'u-fillRemaining': direction === 'row' || direction === 'action',
      }"
      *ngSwitchCase="'typeahead'"
      (selected)="selected.emit($event)"
      (inputChanged)="inputChanged.emit($event)"
      (inputCleared)="typeaheadInputCleared.emit($event)"
      [group]="group"
      [control]="control"
      [config]="config"
      [customEndpoint]="customEndpoint"
    >
    </control-typeahead>

    <control-date-range
      *ngSwitchCase="'dateRange'"
      [group]="group"
      [config]="config"
    >
    </control-date-range>

    <control-time-range
      *ngSwitchCase="'timeRange'"
      [group]="group"
      [config]="config"
    >
    </control-time-range>

    <file-uploader
      *ngSwitchCase="'document'"
      [restrictions]="config"
      [disabled]="fileUploadInputs?.disabled"
      [cancel]="fileUploadInputs?.cancel"
      [progress]="fileUploadInputs?.progress"
      [fileAttachment]="fileUploadInputs?.fileAttachment"
      [uploadErrorMessage]="fileUploadInputs?.uploadErrorMessage"
      [showCloseIcon]="fileUploadInputs?.showCloseIcon"
      (fileUploaded)="fileUploaded.emit($event)"
      (fileRemoved)="fileRemoved.emit($event)"
      (pendingAttachments)="pendingAttachments.emit($event)"
    >
    </file-uploader>

    <control-text
      (focusOutEvent)="handleFocusOut()"
      (focusEvent)="handleFocus()"
      (iconRightClicked)="handleIconRighClicked()"
      [ngClass]="{
        'u-fillRemaining': direction === 'row' || direction === 'action',
      }"
      *ngSwitchDefault
      [customInputTemplate]="customInputTemplate"
      [control]="control"
      [config]="config"
    >
    </control-text>
  </ng-container>

  <div
    class="form-control__label__link form-control__label__link--inline"
    *ngIf="config.link && (direction === 'row' || direction === 'action')"
  >
    <a
      class="t-data t-link"
      [ngClass]="{ 't-bold': direction === 'action' }"
      (click)="linkClick.emit(config?.link)"
    >
      {{ config?.link }}
    </a>
  </div>

  <div
    class="form-control__hint"
    [ngClass]="{
      'form-control__hint--top-right':
        config.hintPosition &&
        config.hintPosition === nonLabelPositions.topRight,
      'form-control__hint--bottom-right':
        config.hintPosition &&
        config.hintPosition === nonLabelPositions.bottomRight,
      'form-control__hint--bottom-left':
        (config.hintPosition &&
          config.hintPosition === nonLabelPositions.bottomLeft) ||
        !config.hintPosition,
    }"
    *ngIf="
      config.hint &&
      ((config.hintPosition &&
        config.hintPosition === nonLabelPositions.topRight) ||
        !invalid)
    "
  >
    <p class="t-caption" [innerHtml]="config.hint"></p>
  </div>

  <ng-content select="[hint]"></ng-content>

  <div
    *ngIf="
      hasControlFocusEvent ? displayedError && hasFocusedOut : displayedError
    "
    class="form-control__error"
    [ngClass]="{ 'is-invalid': invalid }"
  >
    <div class="u-flex">
      <icon
        name="warning"
        [config]="{
          size: 'small',
          fill: 'error',
        }"
      >
      </icon>
      <p
        class="t-caption t-error"
        [innerHTML]="displayedError"
        [id]="'error_' + (config?.id || config?.prop) + '-input'"
      ></p>
    </div>
  </div>
  <div class="u-flex" *ngIf="config.verification?.enabled">
    <icon
      [name]="config.verification.icon"
      [config]="{
        size: 'small',
        fill: config.verification.iconFill,
      }"
    >
    </icon>
    <p
      [attr.aria-label]="config.verification.verificationMessage"
      class="t-caption"
      [innerHTML]="config.verification.verificationMessage"
    ></p>
  </div>

  <div
    class="form-control__complex-password form-control__complex-password--mobile"
    *ngIf="config.focus && showFocus && config.type === types.password"
  >
    <ng-container
      *ngIf="config.focus === 'complex_password'; else defaultFocus"
    >
      <p
        class="form-control__complex-password__label t-data t-bold"
        [innerHTML]="config?.focusDisplay?.label"
      ></p>
      <p
        class="form-control__complex-password__description t-data"
        [innerHTML]="config?.focusDisplay?.description"
      ></p>

      <div class="form-control__complex-password__rules">
        <div
          class="form-control__complex-password__rule"
          *ngFor="let rule of config?.focusDisplay?.rules"
        >
          <icon
            [name]="rule.value ? 'check_circle' : 'close'"
            [config]="{
              fill: rule.value ? 'success' : 'text--2',
            }"
          >
          </icon>

          <p class="t-data" [innerHTML]="rule.label"></p>
        </div>
      </div>
    </ng-container>

    <ng-template #defaultFocus>
      <p class="t-data" [innerHTML]="config.focus"></p>
    </ng-template>
  </div>
</div>
